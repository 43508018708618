import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";

const DataGrid = ({
  columns,
  pagination,
  data,
  title,
  options,
  expandDetails = null,
  conditionalRowStyles = undefined,
  fixedHeight = null,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const dropdownRefFilter = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (dropdownRefFilter.current && !dropdownRefFilter.current.contains(event.target)) {
        setIsFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const rowCount = data.length;
  const rowHeight = 70;
  const headerHeight = 50;
  const maxVisibleRows = 20;
  const calculatedHeight =
    Math.min(rowCount, maxVisibleRows) * rowHeight + headerHeight;

  const customStyles = {
    headCells: {
      style: {
        fontSize: "9px",
        fontWeight: "700",
        backgroundColor: options?.headerBgColor || "white",
        color: options?.headerColor || "black",
        textAlign: "center",
        lineHeight: "2",
        minHeight: "auto",
        justifyContent: "center",
        padding: "2px",
      },
    },
    cells: {
      style: {
        fontSize: "9px",
        padding: "5px",
        justifyContent: "center",
        textAlign: "center",
        lineHeight: "1",
        minHeight: "auto",
        borderBottom: "1px solid #ddd",
      },
    },
  };

  //Colors for columns
  const [modifiedColumns, setModifiedColumns] = useState(
    //const modifiedColumns = columns.map((column) => {
    columns.map((column) => {
      // if (column.name === "STATUM GPA") {
      //   return { ...column, style: { backgroundColor: "#5894f0" } };
      // }
      // else if (column.name === 'EFFICIENY RATIO') {
      //   return { ...column, style: { backgroundColor: '#5894f0' } };
      // } else if (column.name === 'ASSET SIZE ($ M)') {
      //   return { ...column, style: { backgroundColor: '#f6f9fe' } };
      // }
      // else 
      if (column.name === "STATUM RANK") {
        return { ...column, style: { backgroundColor: "#5894f0" }, sortFunction: column.sortFunction || undefined, };
      } else {
        return { ...column, width: "auto", sortFunction: column.sortFunction || undefined,
        };
      }
    })
  );

  const exportToExcel = (type) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: type,
      type: "array",
    });

    const date = new Date();
    const formattedFileName = `${title} -Export-${
      date.getMonth() + 1
    }-${date.getDate()}-${date.getFullYear()}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${formattedFileName}.${type}`);

    setIsDropdownOpen(false);
  };

  const handleFilter = (event, item, originalIndex) => {
    //console.log(modifiedColumns);
    if(event.target.checked){
      // Add object to array at its original index if checked
      setModifiedColumns(prevItems => {
        const newItems = [...prevItems];
        newItems.splice(originalIndex, 0, item);
        return newItems;
      });
    } else {
      // Remove object from array if unchecked
      setModifiedColumns(prevItems => prevItems.filter(obj => obj.name !== item.name));
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="custom-table-header">
        {options?.showHeader ? (
          <>
            <label className="mb-0 ms-2">{options?.headerTitle || ""}</label>

            <div className="flex gap-2">
              {options?.export ? (
                <div className="custom-dropdown" ref={dropdownRef}>
                  <button
                    className="dropdown-toggle"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    Export
                  </button>
                  {isDropdownOpen && (
                    <div className="dropdown-menu">
                      <div onClick={() => exportToExcel("csv")}>
                        <i class="fa-solid fa-file-csv pr-2"></i>CSV
                      </div>
                      <div onClick={() => exportToExcel("xlsx")}>
                        <i class="fa-solid fa-file-excel pr-2"></i>Excel
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              {options?.filter ? (
                <div className="custom-dropdown" ref={dropdownRefFilter}>
                  <button
                    className="dropdown-toggle-column"
                    onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
                  >
                    Column Filter
                  </button>
                  {isFilterDropdownOpen && (
                    <div className="dropdown-menu filter-menu">
                      <ul>
                        {columns?.map((item, index) => {
                          return(<li className="flex items-center mb-1" key={item.name}>
                            <input
                              type="checkbox"
                              id={item.name}
                              checked={modifiedColumns.some(col => col.name === item.name)}
                              onChange={(e) => handleFilter(e, item, index)}
                            />
                            <label htmlFor={item.name}>{item.name}</label>
                          </li>)
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
      <DataTable
        className="dashboard-grid-table"
        columns={modifiedColumns}
        data={data}
        responsive
        direction="auto"
        // striped
        fixedHeader
        fixedHeaderScrollHeight={`${calculatedHeight}px`}
        highlightOnHover
        sortIcon={<FontAwesomeIcon icon={faArrowDown} />}
        dense
        pagination={rowCount > 1}
        persistTableHead
        paginationPerPage={pagination}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        expandableRows={expandDetails !== null}
        expandableRowsComponent={expandDetails}
      />
    </div>
  );
};

export default DataGrid;
