import React, { useContext } from "react";
import { Context } from "../../contextApi/context";
import MyActionsTable from "./tables/MyActionsTable";

const PolicyMyActions = () => {
  const { collapse } = useContext(Context);

  return (
    <>
      <div>
        <div className={collapse ? "content-body" : "content-body-collapse"}>
          <div className="container-fluid metadata">
            <MyActionsTable/>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyMyActions;
