import React from "react";
import "../css/style.css";

function calculateDateOffset(baseDate, offset) {
  const newDate = new Date(baseDate);
  newDate.setDate(newDate.getDate() + offset);
  return newDate;
}

function formatDateToISO(date) {
  return date.toISOString().slice(0, 10);
}

function Weekly({ timeLogWeekly, summaryData, endDate, startDate }) {

  if (!timeLogWeekly || timeLogWeekly.length === 0) {
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <div className="mb-8">
          <img
            src={require("./../images/no-groups-subgroups.png")}
            alt="No time entry"
          />
        </div>
        <p className="text-center">
          <b>
            This Organisation has no Time entry
            <br /> for the selected Date range
          </b>
        </p>
      </div>
    );
  }

  return (
    <div className="mt-5 pt-5 flex-auto overflow-y-auto">
      <div className="min-h-screen p-4 light:bg-zinc-100">
        <div className="overflow-x-auto">
          <table className="min-w-full text-sm divide-y divide-zinc-200 light:divide-zinc-700">
            <thead className="light:bg-zinc-700">
              <tr>
                <th scope="col" className="p-4 text-left font-semibold text-zinc-900 light:text-white border-light border-r"></th>
                {[...Array(7)].map((_, index) => {
                  const currentDate = calculateDateOffset(startDate, index);
                  const day = currentDate.getDate();
                  const month = currentDate.toLocaleString("en-US", { month: "short" });

                  return (
                    <th key={index} scope="col" className="px-6 py-4 text-left font-semibold text-zinc-900 light:text-white border-light border-r">
                      <div className="flex items-center space-x-2" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="text-3xl font-semibold">{day}</div>
                        <div className="flex flex-col">
                          <span className="text-sm font-medium">{currentDate.toLocaleString("en-US", { weekday: "short" })}</span>
                          <span className="text-sm font-medium opacity-30">{month}</span>
                        </div>
                      </div>
                    </th>
                  );
                })}
                <th scope="col" className="px-6 py-4 text-left font-semibold text-zinc-900 light:text-white text-center">Total</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-zinc-200 light:divide-zinc-700">
              {timeLogWeekly.map((item, index) => {
                const filteredDurations = item.date_and_duration
                  ? item.date_and_duration.filter((duration) => {
                      const durationDate = new Date(duration.date);
                      return durationDate >= startDate && durationDate <= endDate;
                    })
                  : [];

                return (
                  <tr key={index}>
                    <td className="p-4 font-medium text-zinc-900 light:text-white border-light border-r">
                      <div className="flex items-center bg-white">
                        <div className="border rounded-lg flex items-center bg-white" style={{ width: "50px", height: "50px", marginRight: "10px" }}>
                          {item.logo_base64 ? (
                            <img src={item.logo_base64} alt="Logo" width="50" height="50" />
                          ) : (
                            <img className="rounded-lg image-logo" height={50} width={50} src={`https://ui-avatars.com/api/?name=Logo&length=1&background=fff&format=svg&color=fff`} alt="Logo" />
                          )}
                        </div>

                        <div>
                          <h1 className="text-lg font-semibold">{item.task_name}</h1>
                          <p className="text-sm text-zinc-600">
                            {item.organization_name} | {item.group_name}
                          </p>
                        </div>
                      </div>
                    </td>
                    {[...Array(7)].map((_, index) => {
                      const currentDate = calculateDateOffset(startDate, index);
                      const dateString = formatDateToISO(currentDate);

                      const durationForDate = filteredDurations.find(
                        (duration) => formatDateToISO(new Date(duration.date)) === dateString
                      );

                      return (
                        <td key={index} className="px-6 py-4 whitespace-nowrap text-zinc-500 light:text-zinc-400 border-light border-r" style={{ textAlign: "center" }}>
                          {durationForDate ? durationForDate.task_duration : ""}
                        </td>
                      );
                    })}
                    <td className="px-6 py-4 whitespace-nowrap text-zinc-500 light:text-zinc-400" style={{ textAlign: "center" }}>
                      {item.total_task_duration.split(".")[0]}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td className="p-4 font-medium text-zinc-900 light:text-white border-light border-r text-center">
                  <b>Total</b>
                </td>
                {[...Array(7)].map((_, index) => {
                  const currentDate = calculateDateOffset(startDate, index);
                  const dateString = formatDateToISO(currentDate);

                  const summaryItem = summaryData.find(
                    (item) => item.date === dateString
                  );

                  return (
                    <td key={index} className="px-6 py-4 whitespace-nowrap text-zinc-500 light:text-zinc-400 border-light border-r" style={{ textAlign: "center" }}>
                      {summaryItem ? summaryItem.total_duration.split(".")[0] : ""}
                    </td>
                  );
                })}
                <td className="px-6 py-4 whitespace-nowrap text-zinc-500 light:text-zinc-400"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Weekly;

