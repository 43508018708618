import React, { useState, useEffect } from "react";
import Status from "../components/Status.js";
import axios from "axios";
import * as FaIcons from "react-icons/fa";

const serverConfig = require("./../../config/server.js");

function DeleteTaskPopup({
  setShowDeleteTask,
  token,
  data,
  selectedOrganization,
  handleLogout,
  refreshTable,
  handleShowAlertModal,
  handleCloseAlertModal,
}) {
  const [status, setStatus] = useState(data.status);
  const [todoPopup, setTodoPopup] = useState(false);

  const [formData, setFormData] = useState({
    ...data,
    grp: data.group.grp,
    status: data?.status?.name,
    issue_type: data?.issue_type?.name,
    issuetsk: data?.parent_issue?.tsk,
  });
  const [collapse, setCollapse] = useState(true);
  const [selectedGrp, setSelectedGrp] = useState(data.group);
  const [assignee, setAssignee] = useState([]);
  const [watcher, setWatcher] = useState([]);
  const [taskName, setTaskName] = useState(data.name);
  const [collapseSelectIssue, setCollapseSelectIssue] = useState(true);

  console.log("FORM DATA : ", formData);

  const handleDeleteTask = (event) => {
    event.preventDefault();
    console.log("FORM DATA : ", formData);

    // Set status to Deleted and make API call after state update
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        status: 'Deleted'
      };

      axios
        .post(serverConfig.api_base_url + "task_update", {
          token: token,
          org: selectedOrganization?.slug,
          tsk: data.tsk,
          items: updatedFormData,
        })
        .then((response) => {
          console.log("task_update response", response);
          if (response.data.status === 200) {
            setFormData({});
            setAssignee([]);
            setWatcher([]);
            refreshTable();
            setShowDeleteTask();
            handleShowAlertModal("Task updated successfully");
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      return updatedFormData;
    });
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (
        !event.target.classList.contains("btn-toggle") &&
        !event.target.classList.contains("organisation-heading") &&
        !event.target.classList.contains("organisation-img") &&
        !event.target.classList.contains("search-input") &&
        !event.target.classList.contains("oragnisationHover") &&
        !event.target.classList.contains("oragnisationHover") &&
        !event.target.classList.contains("dropdown-content")
      ) {
        setCollapse(true);
      }
      if (
        !event.target.classList.contains("btn-toggle-issue") &&
        !event.target.classList.contains("organisation-heading") &&
        !event.target.classList.contains("organisation-img") &&
        !event.target.classList.contains("search-input") &&
        !event.target.classList.contains("oragnisationHover") &&
        !event.target.classList.contains("dropdown-content")
      ) {
        setCollapseSelectIssue(true);
      }
      if (
        !event.target.classList.contains("btn-toggle-status") &&
        !event.target.classList.contains("dropdown-content")
      ) {
        setTodoPopup(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <div className="taskPopUp rounded-xl p-5 relative mx-28 my-5">
      <button
        type="button"
        className="my-2 absolute right-10 top-4 title-text-small-color"
        onClick={() => {
          setShowDeleteTask();
        }}
      >
        <i className="fa-solid fa-xmark "></i>{" "}
      </button>
      <form className="flex" onSubmit={handleDeleteTask}>
        <div className="p-4">
          <div className="flex flex-col">
            <div className="scrollable mb-5 pr-5" style={{ maxHeight: "75vh" }}>
              <div className="flex justify-between items-start gap-2">
                <div className="flex-1">
                  <div className="title text-custom">
                    <div className="flex items-center justify-between">
                      <div>{taskName}</div>
                      <div className="relative pr-2">
                        <button
                          type="button"
                          className="status btn-toggle-status"
                          style={{
                            color: status?.color ? status?.color : "#000000",
                            backgroundColor: status?.color
                              ? status?.color + "20"
                              : "#ffffff",
                            borderColor: status?.color
                              ? status?.color + "90"
                              : "#000000",
                          }}
                          onClick={() => {
                            setTodoPopup(!todoPopup);
                          }}
                          disabled
                        >
                          <div className="brightness-[0.6] btn-toggle-status">
                            {status?.icon
                              ? React.createElement(FaIcons[status?.icon])
                              : React.createElement(FaIcons["FaAngleDown"])}
                          </div>
                          <div className="brightness-[0.6] btn-toggle-status">
                            {status?.name ? status?.name : "Select Status"}
                          </div>
                        </button>
                        {todoPopup && (
                          <Status
                            status={status}
                            setStatus={setStatus}
                            setTodoPopup={setTodoPopup}
                            grpSlug={selectedGrp?.grp}
                            token={token}
                            selectedOrganization={selectedOrganization}
                            formData={formData}
                            setFormData={setFormData}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="text-xs flex items-center mt-1 title-text-small-color">
                    {selectedOrganization?.name} | {data.group.name}
                  </div>
                </div>
              </div>
              <div className="mt-5 mb-5 description">
                <label className="max-w-max">Description</label>
                <div className="whitespace-pre-wrap">
                  {formData.description || data.description}
                </div>
              </div>
              <div className="mt-1 mb-5">
                <label className="max-w-max">Group</label>
                {Object.keys(selectedGrp).length > 0 && (
                  <div className="flex items-center">
                    {selectedGrp.logo_base64 ? (
                      <img
                        src={selectedGrp.logo_base64}
                        alt={selectedGrp.name}
                        width="70"
                        className="border rounded-lg"
                      />
                    ) : (
                      <img
                        className="rounded-lg image-logo"
                        height={70}
                        width={70}
                        src={
                          selectedGrp && selectedGrp.logo
                            ? selectedGrp.logo
                            : `https://ui-avatars.com/api/?name=${
                                selectedGrp.name
                              }&length=1
                      &background=${selectedGrp.default_color.replace(
                        "#",
                        ""
                      )}&format=svg&color=fff`
                        }
                        alt={selectedGrp.name}
                      />
                    )}
                    <div className="ml-3">
                      <div className="text-xl">
                        <b>{selectedGrp.name}</b>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <form>
                <div className="flex-grow overflow-y-auto">
                  <div>
                    <p>
                      <b>Are you sure you want to delete this Task?</b>
                    </p>
                    <p>
                      <b>NOTE : </b>This task will not be deleted permanently.
                      This will only change the status to <b>Deleted</b>.
                    </p>
                  </div>
                </div>
              </form>
            </div>
            {/* bottom button */}
            <div className="ml-auto pr-6">
              <button
                type="button"
                className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                onClick={() => {
                  setShowDeleteTask();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DeleteTaskPopup;
