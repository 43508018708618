import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar.js';
//import { useContext } from 'react';
//import { Context } from '../../contextApi/context';
//import { data } from '../../Asset/Data/taskData'
//import Calender from './Calender';
import axios from 'axios';
import DatePicker from 'react-datepicker';

const serverConfig = require("../../config/server.js");

function ProgressSubOKR({maxDate, minDate, formData, progress, isDraggable, handleLogout, setCloseEditOKR, selectedOrganization, setFormData, newTask, token, handleShowAlertModal, handleCloseAlertModal }) {
  let todayDate=new Date().toISOString().split('T')[0];
  //const { rowId } = useContext(Context);
  //const [startDate, setStartDate] = useState(new Date());
  //const [showCalendar, setShowCalendar] = useState(false);
  const [dueDateReminder, setDueDateReminder] = useState([]);

  const convertDate = (date) => {
            var inputDateStr = date;
            var inputDate = new Date(inputDateStr);
            var monthNames = [
              "Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            var dayOfWeek = inputDate.getDay();

            var day = inputDate.getDate();
            var month = inputDate.getMonth(); // Months are zero-based
            var year = inputDate.getFullYear();

            var outputDateStr = "";

            var dayOfWeekStr;
            switch (dayOfWeek) {
              case 0:
                dayOfWeekStr = "Sun";
                break;
              case 1:
                dayOfWeekStr = "Mon";
                break;
              case 2:
                dayOfWeekStr = "Tue";
                break;
              case 3:
                dayOfWeekStr = "Wed";
                break;
              case 4:
                dayOfWeekStr = "Thu";
                break;
              case 5:
                dayOfWeekStr = "Fri";
                break;
              case 6:
                dayOfWeekStr = "Sat";
                break;
            }

            outputDateStr = dayOfWeekStr + ", " + monthNames[month] + " " + day + ", " + year;

            console.log(outputDateStr); // Output: "Thu, Mar 22, 2024"
            return outputDateStr;
  }

  useEffect(()=>{
    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"due_date_reminder"})
    .then(response=>{
      console.log("due_date_reminder lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("due_date_reminder data:", response.data.items);
          response.data.items.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          setDueDateReminder(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  },[token]);

  return (
    <div className='mt-7'>
       {newTask === 'no' ?
        <>
          <div className='w-full font-semibold text-sm'>
          <div className='progress-estimated mt-1'>Progress Percentage</div>
          <div className='w-full font-semibold text-sm'>
            <ProgressBar isDraggable={isDraggable} progress_value={progress} formData={formData} setFormData={setFormData} />
          </div>
          </div>
        </> : <></>}


      <div className={newTask === 'no' ? 'progress-estimated mt-10 font-semibold' : 'progress-estimated mt-4 font-semibold'}>Start Date:<span className='text-red-500'>*</span></div>
      {formData.start_date < todayDate ? 
      <div className="sum-title mb-4">{convertDate(formData.start_date)}</div> :
       <input type="date" value={formData.start_date} className='date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs' min={minDate} max={maxDate} onChange={(e) => { console.log(new Date().toISOString().substring(0, 10), 'date_'); setFormData({ ...formData, start_date: e.target.value })}} required/>}

      {/* {formData.end_date && <><div className='progress-estimated'>End Date:</div>
      <div className="sum-title mb-3">{formData.end_date}</div></>} */}

<div className={newTask === 'no' ? 'progress-estimated mt-10 font-semibold' : 'progress-estimated mt-4 font-semibold'}>End Date:<span className='text-red-500'>*</span></div>
      {formData.end_date < todayDate ? <div className="sum-title mb-4">{convertDate(formData.end_date)}</div> : <input type="date" value={formData.end_date} className='date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs' min={minDate} max={maxDate} onChange={(e) => setFormData({ ...formData, end_date: e.target.value })} required />}


      <div className='flex flex-col mt-6'>
              <button type="button" className='cancel-btn-popup px-4 py-1 rounded-lg mt-2 text-xs ' onClick={setCloseEditOKR}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-1 rounded-lg mt-2 text-xs'>Save Changes</button>
            </div>

    </div>
  )
}

export default ProgressSubOKR;