import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../contextApi/context";
import FileUploader from "../components/FileUploader";
import Link from "../components/Link";
import Label from "../components/Label";
import Checklist from "../components/Checklist";
import CommentMain from "../components/comment";
import Status from "../components/Status.js";
import AssignessSide from "../components/AssignessSide";
import WatchersSide from "../components/WatchersSide.js";
import Sprint from "../components/Sprint";
import Progress from "../components/Progress";
import Search from "../components/Search";
import Stopwatch from "../components/Stopwatch.js";
import axios from "axios";
import Select from "react-select";
import * as FaIcons from 'react-icons/fa';
import LinkedIssues from '../components/LinkedIssues.js';

const serverConfig = require("./../../config/server.js");

function GroupDropdownCard({
  data,
  selectedGrp,
  setSelectedGrp,
  setCollapse,
  setGroupList,
  grpList,
  setGrpList,
  setFormData,
  formData,
  token, selectedOrganization, handleLogout, handleShowAlertModal, handleCloseAlertModal
}) {
  const [subgroupList, setSubgroupList] = useState([]);
  const { isDarkTheme } = useContext(Context);

  const handleGetSubgroupList = (grp, event) => {
    //console.log(grp, event);
    event.target.classList.toggle("fa-angle-up");
    event.target.classList.toggle("fa-angle-down");
    event.target.closest('.border-b').classList.toggle(isDarkTheme ? 'bg-neutral-600' : 'bg-slate-50');
    if(event.target.closest('.oragnisationHover').nextSibling !== null){
      event.target.closest('.oragnisationHover').nextSibling.classList.toggle("hidden");
    }
    axios.post(serverConfig.api_base_url + "group_list_get",{token:token, org:selectedOrganization?.slug, grp:grp})
    .then(response=>{
      console.log('group_list_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(response.data.items);
          setSubgroupList(response.data.items);
          /* for (let i = 0; i < grpList.length; i++) {
            if (grpList[i].grp === grp) {
              grpList[i].child=response.data.items;
            }
          } */
          function addObjectToNestedArray(array, valueToFind, newObject) {
            for (let item of array) {
              if (item.grp === valueToFind) {
                // Found the item, add the new object to its children
                //item.child.push(newObject);
                item.child=newObject;
                return true; // Return true to stop further searching
              }
              // If the item has children, recursively search in children
              if (item.child && item.child.length > 0) {
                if (addObjectToNestedArray(item.child, valueToFind, newObject)) {
                  return true; // Return true if the object was added in the recursive call
                }
              }
            }
            return false; // Return false if the value is not found
          }

          const valueToFind = grp;
          addObjectToNestedArray(grpList, valueToFind, response.data.items);

          console.log(grpList);
          setGroupList(grpList);
          setGrpList(grpList);
        } else {
          setSubgroupList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (<div className='border-b'>
    <div title={data.name}
      className={"flex gap-2 align-middle justify-between p-2 oragnisationHover " + (selectedGrp?.grp === data?.grp && (isDarkTheme ? 'bg-neutral-900' : 'bg-slate-200'))}
      style={{ alignItems: "center" }}
    >
      <div className='flex gap-2 align-middle dropdown-content' onClick={() => {setSelectedGrp(data); setCollapse(true); setGroupList(grpList); setFormData({...formData, grp:data.grp});}}>
        <div className="dropdown-content">
          {data.logo_base64 === null ? (
            <img
              src={`https://ui-avatars.com/api/?name=${data?.name}
                          &rounded=false&length=1
                          &background=${data.default_color.replace(
                            "#",
                            ""
                          )}&format=svg&color=fff`}
              alt="image"
              className="organisation-img rounded-lg"
            />
          ) : (
            <img
              src={data.logo_base64}
              alt={data.logo_name}
              className="rounded-lg organisation-img"
            />
          )}
        </div>
        <div className="organisation-heading line-clamp-1">{data.name}</div>
      </div>
      <div className="flex min-w-max dropdown-content">
        {selectedGrp?.grp === data?.grp && <><i className="fa-solid fa-check text-xs mr-2 dropdown-content"></i></>}
        <button type="button" onClick={(e) => handleGetSubgroupList(data.grp, e)} className="dropdown-content"><i className="fa-solid fa-angle-down dropdown-content"></i></button>
      </div>
    </div>
    {data.child && data.child.length > 0 && <div className='pl-3'>
      {data.child.map((data)=>{
        return(<GroupDropdownCard data={data} setCollapse={setCollapse} selectedGrp={selectedGrp} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setGrpList={setGrpList} setFormData={setFormData} formData={formData} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
      })}
    </div>}
  </div>);
}

function TaskDropdownCard({data, selectedGrp, selectedIssue, setSelectedIssue, setCollapseSelectIssue, setIssueList, issList, setIssList, setFormData, formData, token, selectedOrganization, handleLogout, handleShowAlertModal, handleCloseAlertModal}){
  const [taskList, setTaskList] = useState([]);
  const { isDarkTheme } = useContext(Context);
  console.log(data);

  const handleGetTaskList = (tsk, event) => {
    //console.log(grp, event);
    console.log(issList);
    event.target.classList.toggle("fa-angle-up");
    event.target.classList.toggle("fa-angle-down");
    event.target.closest('.border-b').classList.toggle(isDarkTheme ? 'bg-neutral-600' : 'bg-slate-50');
    if(event.target.closest('.oragnisationHover').nextSibling !== null){
      event.target.closest('.oragnisationHover').nextSibling.classList.toggle("hidden");
    }
    axios.post(serverConfig.api_base_url + "issue_list_get",{token:token, org:selectedOrganization?.slug, grp:selectedGrp.grp, /* issueTypeSeqNo:data.issue_type.seq_no+1, */ tsk: tsk})
    .then(response=>{
      console.log('issue_list_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(response.data.items);
          setTaskList(response.data.items);
          /* for (let i = 0; i < issList.length; i++) {
            if (issList[i].tsk === tsk) {
              issList[i].child=response.data.items;
            }
          } */

          function addObjectToNestedArray(array, valueToFind, newObject) {
            for (let item of array) {
              if (item.tsk === valueToFind) {
                // Found the item, add the new object to its children
                //item.child.push(newObject);
                item.child=newObject;
                return true; // Return true to stop further searching
              }
              // If the item has children, recursively search in children
              if (item.child && item.child.length > 0) {
                if (addObjectToNestedArray(item.child, valueToFind, newObject)) {
                  return true; // Return true if the object was added in the recursive call
                }
              }
            }
            return false; // Return false if the value is not found
          }

          const valueToFind = tsk;
          addObjectToNestedArray(issList, valueToFind, response.data.items);
          
          console.log(issList);
          setIssueList(issList);
          setIssList(issList);
        } else {
          setTaskList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  return(<div className='border-b'>
    <div className={'flex gap-2 items-center justify-between p-2 oragnisationHover ' + (selectedIssue?.tsk === data?.tsk && (isDarkTheme ? 'bg-neutral-900' : 'bg-slate-200'))} style={{alignItems:'center'}}>
      <div className='flex gap-2 items-center dropdown-content' onClick={() => {setSelectedIssue(data); setCollapseSelectIssue(true); setIssueList(issList); setFormData({...formData, issuetsk:data.tsk});}}>
        <div className="select-icon dropdown-content">
          {data.issue_type ? <span className='icn dropdown-content' style={{color:data.issue_type?.color, backgroundColor: data.issue_type?.color+'20', borderColor: data.issue_type?.color+'90'}}>{React.createElement(FaIcons[data?.issue_type?.icon])}</span> : <></>}
        </div>
        <div className='organisation-heading line-clamp-1'>{data.task_key +' ' +data.name}</div>
      </div>
      <div className="flex min-w-max dropdown-content">
        {selectedIssue?.tsk === data?.tsk && <><i className="fa-solid fa-check text-xs mr-2 dropdown-content"></i></>}
        <button type="button" onClick={(e) => handleGetTaskList(data.tsk, e)} className="dropdown-content"><i className="fa-solid fa-angle-down dropdown-content"></i></button>
      </div>
    </div>
    {data.child && data.child.length > 0 && <div className='pl-3'>
      {data.child.map((data)=>{
        return(<TaskDropdownCard data={data} selectedGrp={selectedGrp} setCollapseSelectIssue={setCollapseSelectIssue} selectedIssue={selectedIssue} setSelectedIssue={setSelectedIssue} setIssueList={setIssueList} issList={issList} setIssList={setIssList} setFormData={setFormData} formData={formData} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
      })}
    </div>}
  </div>)
}

function EditTaskPopup({
  setShowEditTask,
  token,
  data,
  selectedOrganization,
  handleLogout,
  refreshTable,
  handleShowAlertModal,
  handleCloseAlertModal,
}) {
  const childRef = useRef();

  const navigate = useNavigate();
  const{setActiveOption} = useContext(Context);

  const [isPlay, setPlay] = useState(true);
  const [isTimerPrivileged, setIsTimerPrivileged] = useState(false);
  const [isTextAreaDisable, setTextAreaDisable] = useState(true);
  const [status, setStatus] = useState(data.status);
  const [todoPopup, setTodoPopup] = useState(false);
  const [text, setText] = useState("");

  console.log('data', data);

  const [formData, setFormData] = useState({
    ...data,
    grp: data.group.grp,
    status: data.status.name,
    issue_type: data?.issue_type?.cat,
    issuetsk: data?.parent_issue?.tsk
  });
  const [collapse, setCollapse] = useState(true);
  const [grpList, setGrpList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [progress, setProgress] = useState(
    data.progress_percentage ? data.progress_percentage : 0
  );
  const [selectedGrp, setSelectedGrp] = useState(data.group);
  const [assignee, setAssignee] = useState([]);
  const [watcher, setWatcher] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [taskName, setTaskName] = useState(data.name);

  const [issueType, setIssueType] = useState(data.issue_type ? {
    value:data.issue_type.name,
    label:<><span className='icn' style={{color:data.issue_type.color, backgroundColor: data.issue_type.color+'20', borderColor: data.issue_type.color+'90'}}>{React.createElement(FaIcons[data.issue_type?.icon])}</span> {data.issue_type.name}</>,
    cat:data.issue_type.cat
  } : undefined);
  const [issueTypeLookup, setIssueTypeLookup] = useState([]);
  const[collapseSelectIssue, setCollapseSelectIssue]=useState(true);
  const[issList, setIssList] = useState([]);
  const[issueList, setIssueList] = useState([]);
  const[selectedIssue, setSelectedIssue] = useState(data.parent_issue);

  const getAssigneeList = () => {
    axios
      .post(serverConfig.api_base_url + "task_assignee_list_get", {
        token: token,
        org: selectedOrganization?.slug,
        tsk: data.tsk,
      })
      .then((response) => {
        console.log("task_assignee_list_get response", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log(response.data.items);
            setAssignee(response.data.items);

            //setIsTimerPrivileged
            console.log(response.data.items.some((el) => el.usr === data.usr));
            if (response.data.items.some((el) => el.usr === data.usr)) {
              setIsTimerPrivileged(true);
            } else {
              setIsTimerPrivileged(false);
            }
          } else {
            setAssignee([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const getWatcherList = () => {
    axios
      .post(serverConfig.api_base_url + "task_watcher_list_get", {
        token: token,
        org: selectedOrganization?.slug,
        tsk: data.tsk,
      })
      .then((response) => {
        console.log("task_watcher_list_get response", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log(response.data.items);
            setWatcher(response.data.items);
          } else {
            setWatcher([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(selectedGrp);
    axios.post(serverConfig.api_base_url + "issue_list_get",{token:token, org:selectedOrganization?.slug, grp:selectedGrp.grp})
    .then(response=>{
      console.log("issue_list_get:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("issue_list_get data:", response.data.items);
          setIssList(response.data.items);
          setIssueList(response.data.items);
        } else {
          setIssList([]);
          setIssueList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "issue_category_get_list", {
      token: token,
      org: selectedOrganization?.slug,
      grp: selectedGrp.grp
    }).then(response => {
      if (response.data.status === 200) {
        if (response.data.items !== null) {
          //setCategoryList(response.data.items);
          console.log(response.data.items, 'issue_list_res');
          let arr = response.data.items.map((item) => {
            return({
              value:item.name,
              label:<><span className='icn' style={{color:item.color, backgroundColor: item.color+'20', borderColor: item.color+'90'}}>{React.createElement(FaIcons[item?.icon])}</span> {item.name}</>,
              cat: item.cat
            });
          });
          console.log(arr);
          setIssueTypeLookup(arr);
        } else {
          setIssueTypeLookup([]);
        }
      }
    }).catch(err=>console.log("error is",err));
  }, [selectedGrp]);

  useEffect(() => {
    console.log(data.progress_percentage, "progress_bar");
    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);

      axios
        .post(serverConfig.api_base_url + "group_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("group_list_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              setGrpList(response.data.items);
              setGroupList(response.data.items);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      getAssigneeList();
      getWatcherList();
    }
  }, [token, selectedOrganization]);

  console.log("FORM DATA : ", formData);

  const handleUpdateTask = (event) => {
    event.preventDefault();
    console.log("FORM DATA : ", formData);
    axios
      .post(serverConfig.api_base_url + "task_update", {
        token: token,
        org: selectedOrganization?.slug,
        tsk: data.tsk,
        items: formData,
      })
      .then((response) => {
        console.log("task_update response", response);
        if (response.data.status === 200) {
          setFormData({});
          setAssignee([]);
          setWatcher([]);
          refreshTable();
          setShowEditTask();
          handleShowAlertModal("Task updated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleInputChange = (e) => {
    const newName = e.target.closest('.title').querySelector('input[type="text"]').value;
    console.log(newName, taskName);
    setTaskName(newName);
    setFormData({ ...formData, name: newName });
    setIsEditing(false);
  };

  const handleCancelEditTitle = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if(!event.target.classList.contains("btn-toggle") && !event.target.classList.contains('organisation-heading') && !event.target.classList.contains('organisation-img') && !event.target.classList.contains('search-input') && !event.target.classList.contains('oragnisationHover') && !event.target.classList.contains('oragnisationHover') && !event.target.classList.contains('dropdown-content')){
        setCollapse(true);
      }
      if(!event.target.classList.contains("btn-toggle-issue") && !event.target.classList.contains('organisation-heading') && !event.target.classList.contains('organisation-img') && !event.target.classList.contains('search-input') && !event.target.classList.contains('oragnisationHover') && !event.target.classList.contains('dropdown-content')){
        setCollapseSelectIssue(true);
      }
      if(!event.target.classList.contains("btn-toggle-status") && !event.target.classList.contains('dropdown-content')){
        setTodoPopup(false);
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  return (
    <div className="taskPopUp rounded-xl p-5 relative mx-28  my-5 w-3/5">
      <button
        type="button"
        className="my-2 absolute right-10 top-4 title-text-small-color"
        onClick={() => {
          setShowEditTask();
          //childRef.current.doLogTime();
        }}
      >
        <i className="fa-solid fa-xmark "></i>{" "}
      </button>
      <form className="flex" onSubmit={handleUpdateTask}>
        <div className="p-4 pr-1 border-right w-3/4">
          <div className="flex flex-col">
            <div className="scrollable mb-5 pr-5" style={{ maxHeight: "75vh" }}>
              <div className="flex justify-between items-start gap-2">
                <div className="flex-1">
                  <div className="title text-custom">
                    {isEditing ? (<>
                      <input
                        type="text"
                        style={{ padding: "5px" }}
                        defaultValue={taskName}
                        //onChange={handleInputChange}
                        //onBlur={handleSave}
                        autoFocus
                        className="min-w-full"
                      />
                      <div className="flex justify-end -mt-3">
                        <button type="button" className="cancel-btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Cancel" onClick={handleCancelEditTitle}><i className="fa-solid fa-xmark"></i></button>
                        <button type="button" className="btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Ok" onClick={handleInputChange}><i className="fa-solid fa-check"></i></button>
                      </div>
                    </>) : (
                      <div className="flex items-center">
                        <div onClick={() => setIsEditing(true)}>{taskName}</div>
                        <button
                          type="button"
                          onClick={() => setIsEditing(true)}
                          className="ml-1"
                        >
                          <i className="fa-solid fa-pencil text-xs icon-color mx-1 align-middle"></i>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="text-xs flex items-center mt-1 title-text-small-color">
                    {selectedOrganization?.name} | {data.group.name}
                  </div>
                </div>
                {/* <Stopwatch
                  token={token}
                  selectedOrganization={selectedOrganization}
                  taskSlug={formData.tsk}
                  totalDuration={formData.total_duration}
                  handleShowAlertModal={handleShowAlertModal}
                  handleCloseAlertModal={handleCloseAlertModal}
                  ref={childRef}
                  refreshTable={refreshTable}
                  isTimerPrivileged={isTimerPrivileged}
                  setStatus={setStatus}
                  todoData={data.status}
                  setFormData={setFormData}
                  formData={formData}
                /> */}
                {/* <div className='play-pause p-2 rounded-full flex justify-between items-center gap-1 me-2'>
                  <button type='button' className='rounded-full play-pause-button h-10 w-10 flex items-center justify-center  text-white' onClick={() => { setPlay(!isPlay) }}>
                    {isPlay ? <i className="fa-solid fa-play"></i> : <i className="fa-solid fa-pause"></i>}</button>
                  <div className=' font-medium text-sm px-2 text-custom'>00.00.00</div>
                </div> */}
              </div>
              <div className="mt-5 mb-5 description">
                <label className="max-w-max">Description
                  <button
                    type="button"
                    onClick={() => {
                      setTextAreaDisable(!isTextAreaDisable);
                    }}
                  >
                    <i className="fa-solid fa-pencil text-xs icon-color mx-1"></i>
                  </button>
                </label>
                {
                  isTextAreaDisable ? <div className="whitespace-pre-wrap">{formData.description || data.description}</div>
                  :
                  <>
                    <textarea
                      className={
                        "w-full h-32 input-custom rounded-lg px-3 py-2  whitespace-pre-line mt-2 text-xs border border-gray-300 focus:border-gray-300 outline-none"
                      }
                      placeholder={"Add Description"}
                      defaultValue={formData.description || data.description}
                      /* onChange={(e) =>
                        setFormData({ ...formData, description: e.target.value })
                      } */
                      disabled={isTextAreaDisable}
                    />
                    <div className="flex justify-end">
                      <button type="button" className="cancel-btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Cancel" onClick={() => setTextAreaDisable(true)}><i className="fa-solid fa-xmark"></i></button>
                      <button type="button" className="btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Ok" onClick={(e) => {
                        const newValue = e.target.closest('.description').querySelector('textarea').value;
                        //console.log(newName, taskName);
                        //setTaskName(newName);
                        setFormData({ ...formData, description: newValue })
                        setTextAreaDisable(true);
                      }}><i className="fa-solid fa-check"></i></button>
                    </div>
                  </>
                }
                {/* <textarea
                  className={
                    isTextAreaDisable
                      ? "w-full input-custom h-26 rounded-lg 0 p-2 whitespace-pre-line mt-2 text-xs border border-gray-300"
                      : "w-full h-32 input-custom rounded-lg px-3 py-2  whitespace-pre-line mt-2 text-xs border border-gray-300 focus:border-gray-300 outline-none"
                  }
                  placeholder={"Add Description"}
                  value={formData.description || data.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  disabled={isTextAreaDisable}
                /> */}
              </div>
              <div className="mt-1 mb-5">
                {Object.keys(selectedGrp).length > 0 && (
                  <div className="flex items-center">
                    {selectedGrp.logo_base64 ? (
                      <img
                        src={selectedGrp.logo_base64}
                        alt={selectedGrp.name}
                        width="70"
                        className="border rounded-lg"
                      />
                    ) : (
                      <img
                        className="rounded-lg image-logo"
                        height={70}
                        width={70}
                        src={
                          selectedGrp && selectedGrp.logo
                            ? selectedGrp.logo
                            : `https://ui-avatars.com/api/?name=${
                                selectedGrp.name
                              }&length=1
                      &background=${selectedGrp.default_color.replace(
                        "#",
                        ""
                      )}&format=svg&color=fff`
                        }
                        alt={selectedGrp.name}
                      />
                    )}
                    <div className="ml-3">
                      <div className="text-xl">
                        <b>{selectedGrp.name}</b>
                      </div>
                      <div>New Task will be created in this group</div>
                    </div>
                  </div>
                )}
                <div className="relative mt-4 inline-block">
                  <input
                    type="text"
                    className="absolute max-w-full h-full opacity-0"
                    value={selectedGrp.grp}
                    required
                  />
                  <button
                    type="button"
                    className="organisations border rounded-lg py-2 px-4 z-10 relative btn-toggle"
                    style={{ borderBottomWidth: "1px", borderTopWidth: "1px" }}
                    onClick={() => setCollapse(!collapse)}
                  >
                    <b className="btn-toggle">{selectedGrp.grp ? "Change Group" : "Select Group"}</b>
                  </button>{" "}
                  <span className="text-red-500 align-top">*</span>
                  {!collapse && (
                    <div
                      className="organisationDropdownCard"
                      style={{ width: "100%", left: 0 }}
                    >
                      <div className="pt-2 px-2">
                        <Search grpList={grpList} setGroupList={setGroupList} />
                      </div>
                      <div
                        className="organisations py-2 mb-2  h-40 overflow-y-auto"
                        style={{ borderBottom: "none" }}
                      >
                        {groupList.length > 0 &&
                          groupList.map((data) => {
                            return (
                              <GroupDropdownCard
                                data={data}
                                setCollapse={setCollapse}
                                selectedGrp={selectedGrp}
                                setSelectedGrp={setSelectedGrp}
                                setGroupList={setGroupList}
                                grpList={grpList}
                                setGrpList={setGrpList}
                                setFormData={setFormData}
                                formData={formData}
                                token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal}
                              />
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mt-5 mb-5'>
                <label>Parent Issue</label>
                {!selectedGrp.grp && <div className="text-red-500 text-sm">Select Group first</div>}
                {selectedGrp.grp && issList.length === 0 && <div className="text-red-500 text-sm">No Epics to select</div>}
                <div className='relative mt-2'>
                  <button type="button" className='border rounded-lg py-2 px-4 z-10 relative w-full text-start btn-toggle-issue' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => setCollapseSelectIssue(!collapseSelectIssue)}>
                    {selectedIssue && selectedIssue.tsk ? <div className='flex gap-2 items-center btn-toggle-issue'>
                      <div className="select-icon btn-toggle-issue">
                        {selectedIssue.issue_type ? <span className='icn btn-toggle-issue' style={{color:selectedIssue.issue_type.color, backgroundColor: selectedIssue.issue_type.color+'20', borderColor: selectedIssue.issue_type.color+'90'}}>{React.createElement(FaIcons[selectedIssue.issue_type?.icon])}</span> : <></>}
                      </div>
                      <div className='organisation-heading line-clamp-1'>{selectedIssue.task_key +' ' +selectedIssue.name}</div>
                    </div> : 'Select Parent Issue'}
                  </button>
                  {!collapseSelectIssue && <div className='organisationDropdownCard' style={{width: '100%', left: 0}}>
                    <div className='pt-2'>
                      <Search issList={issList} setIssueList={setIssueList} />
                    </div>
                    <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                      {issueList.length > 0 && issueList.map((data)=>{
                        return(<TaskDropdownCard data={data} selectedGrp={selectedGrp} setCollapseSelectIssue={setCollapseSelectIssue} selectedIssue={selectedIssue} setSelectedIssue={setSelectedIssue} setIssueList={setIssueList} issList={issList} setIssList={setIssList} setFormData={setFormData} formData={formData} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
                      })}
                    </div>
                  </div>}
                </div>
              </div>
              <div className='mt-5'>
                <label>Issue Type<span className='text-red-500'>*</span></label>
                {!selectedGrp.grp && <div className="text-red-500 text-sm">Select Group first</div>}
                {selectedGrp.grp && issueTypeLookup.length === 0 && <div className="text-red-500 text-sm">No Issue type to select. Please create one <span className='underline cursor-pointer' onClick={() => {navigate(`/groups/subgroups?${selectedGrp.grp}`,{state:{name:selectedGrp.name, logo:selectedGrp.logo_base64, grp:selectedGrp.grp, bgColor: selectedGrp.default_color.replace('#',''), default_color:selectedGrp.default_color, currentTab: "Settings"}}); setActiveOption('Groups');}}>here</span></div>}
                <Select value={issueType || ""}
                 classNamePrefix="select" className='select-icon' placeholder="Issue Type" options={issueTypeLookup} onChange={(option)=>  {console.log(option); setFormData({ ...formData, issue_type: option.cat}); setIssueType(option);}} required isDisabled={!selectedGrp.grp} />
              </div>
              <div className="mt-2">
                <FileUploader setFormData={setFormData} formData={formData} />
              </div>
              <div className="mt-2">
                <Link setFormData={setFormData} formData={formData} />
              </div>
              <div className="mt-2">
                <Label
                  setFormData={setFormData}
                  formData={formData}
                  taskSlug={formData.tsk}
                  grpSlug={formData.group.grp}
                  token={token}
                  selectedOrganization={selectedOrganization}
                  handleShowAlertModal={handleShowAlertModal}
                  handleCloseAlertModal={handleCloseAlertModal}
                />
              </div>
              <div className="mt-2">
                <Checklist
                  taskSlug={formData.tsk}
                  grpSlug={
                    selectedGrp.grp ? selectedGrp.grp : formData.group.grp
                  }
                  token={token}
                  selectedOrganization={selectedOrganization}
                  handleShowAlertModal={handleShowAlertModal}
                  handleCloseAlertModal={handleCloseAlertModal}
                  getAssigneeList={getAssigneeList}
                />
              </div>
              <div className='mt-2'>
                <LinkedIssues taskSlug={formData.tsk} selectedGrp={selectedGrp} issList={issList} setIssList={setIssList} issueList={issueList} setIssueList={setIssueList} collapseSelectIssue={collapseSelectIssue} setFormData={setFormData} formData={formData} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
              </div>
              <div className="mt-2">
                <CommentMain
                  taskSlug={formData.tsk}
                  token={token}
                  selectedOrganization={selectedOrganization}
                  handleShowAlertModal={handleShowAlertModal}
                  handleCloseAlertModal={handleCloseAlertModal}
                />
              </div>
            </div>

            {/* bottom buttom */}
            <div className="ml-auto">
              <button
                type="button"
                className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                onClick={() => {
                  setShowEditTask();
                  //childRef.current.doLogTime();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
              >
                Update
              </button>
            </div>
          </div>
        </div>

        <div className="p-4 pr-0 w-1/4">
          {/* Content for the 30% wide container */}
          <div className="flex flex-col popup-sidebar">
            <div className="scrollable pr-1" style={{ maxHeight: "75vh" }}>
              {/* status option and popup */}
              <div className="relative w-48">
                <button
                  type="button"
                  className="status btn-toggle-status"
                  style={{
                    color: status.color ? status.color : "#000000",
                    backgroundColor: status.color
                      ? status.color + "20"
                      : "#ffffff",
                    borderColor: status.color
                      ? status.color + "90"
                      : "#000000",
                  }}
                  onClick={() => {
                    setTodoPopup(!todoPopup);
                  }}
                  disabled={selectedGrp === '' || Object.keys(selectedGrp).length === 0}
                >
                  <div className="brightness-[0.6] btn-toggle-status">
                    {status.icon ? React.createElement(FaIcons[status?.icon]) : React.createElement(FaIcons['FaAngleDown'])}
                  </div>
                  <div className="brightness-[0.6] btn-toggle-status">
                    {status.name ? status.name : 'Select Status'}
                  </div>
                </button>
                {todoPopup && (
                  <Status
                    status={status}
                    setStatus={setStatus}
                    setTodoPopup={setTodoPopup}
                    grpSlug={selectedGrp?.grp} token={token} selectedOrganization={selectedOrganization}
                    formData={formData}
                    setFormData={setFormData}
                  />
                )}
              </div>
              {/* assignees options and popup */}
              <AssignessSide
                setFormData={setFormData}
                formData={formData}
                assignee={assignee}
                grpSlug={selectedGrp.grp ? selectedGrp.grp : formData.group.grp}
                token={token}
                selectedOrganization={selectedOrganization}
                handleShowAlertModal={handleShowAlertModal}
                handleCloseAlertModal={handleCloseAlertModal}
              />

              {/* watchers options and popup */}
              <WatchersSide
                setFormData={setFormData}
                formData={formData}
                watcher={watcher}
                grpSlug={selectedGrp.grp ? selectedGrp.grp : formData.group.grp}
                token={token}
                selectedOrganization={selectedOrganization}
                handleShowAlertModal={handleShowAlertModal}
                handleCloseAlertModal={handleCloseAlertModal}
              />

              {/* <Sprint /> */}

              <Progress
                formData={formData}
                progress={progress}
                setFormData={setFormData}
                newTask={"no"}
                token={token}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditTaskPopup;
