import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { Context } from '../../contextApi/context';
import '../css/cat.css';

const serverConfig = require('../../config/server.js');

function AddCategory({ setAddCategory, onAddSuccess }) {
    const { selectedOrganization } = useContext(Context);
    const [category, setCategory] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');

    const [addCategorySuccess, setAddCategorySuccess] = useState(false);
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
    const [formMessage, setFormMessage] = useState({ type: '', message: '' });
    const [tab, setTab] = useState('Add Category');
    const [memberTab, setMemberTab] = useState(0);

    const getToken = () => {
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt })
            .then(response => {
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setCToken(response.data.token);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log('error is', err));
    };

    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
    }, []);

    const showMessage = (message, type = 'info') => {
        setFormMessage({ type, message });
    };

    const submitAddCategory = () => {
       // event.preventDefault();
        if (validateCategory()) {
            const requestData = {
                token: cToken,
                org: selectedOrganization?.slug,
                category: category,
                description: categoryDescription,
            };
            axios
                .post(serverConfig.api_base_url + 'resource_category_create', requestData)
                .then((response) => {
                    console.log('Add category response:', response);
                    if (response.data.status === 200) {
                        setAddCategorySuccess(true);
                        onAddSuccess();
                        showMessage('Category added successfully!', 'success');
                    } else {
                        showMessage(`Failed to add category. ${response.data.message}`, 'error');
                    }

                })
                .catch((err) => {
                    console.log('Error:', err);
                    showMessage('An error occurred. Please try again.', 'error');
                });
        }
    };

    const validateCategory = () => {
        if (category === '') {
            showMessage('Category is required', 'error');
            return false;
        }
        return true;
    };

    return (
        <div className='add-user rounded-3xl py-4 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col items-center modalPopup'>
            <>
                {addCategorySuccess ? (
                    <>
                        <div className='loading-div flex flex-col'>
                            <img src={require(`../images/uploadSuccess.png`)} alt='' className='loading-img' />
                            <h1 className='invite-text'>Successfully added category</h1>
                        </div>
                        <div className='flex flex-row justify-end'>
                            <button
                                type="submit"
                                className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                                onClick={() => { setAddCategory(false); setAddCategorySuccess(false); }}
                            >
                                Done
                            </button>
                        </div>
                    </>
                ) : (
                    <div className='flex items-center gap-2'>
                        <div className='flex flex-col team-label'>
                            <div className="flex space-x-4">
                                <button className={`px-6 py-3 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Add Category'); setMemberTab(0); }}>Add Category</button>
                            </div>
                            <h5 className='mt-4 font-semibold'>Add Category</h5>
                            <div className='flex flex-row gap-2 mt-2'>
                                <div className='flex flex-col gap-1 input-field'>
                                    <label>Category<span className='text-red-500'>*</span></label>
                                    <input
                                        type='text'
                                        name='category'
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                        placeholder='Category'
                                        className='invite-field custom-input py-2 px-2 text-left'
                                        required
                                    />
                                </div>
                                <div className='flex flex-col gap-1 input-field'>
                                    <label>Category Description</label>
                                    <input
                                        type='text'
                                        name='categoryDescription'
                                        value={categoryDescription}
                                        onChange={(e) => setCategoryDescription(e.target.value)}
                                        placeholder='Description'
                                        className='invite-field custom-input py-2 px-2 text-left'
                                        required
                                    />
                                </div>
                            </div>

                            <div className='addform flex '>
                                <button
                                    type='button'
                                    className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium mr-2'
                                    onClick={() => {
                                        console.log('Closing form');
                                        setAddCategory(false);
                                        setAddCategorySuccess(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                                    onClick={() => {
                                        submitAddCategory();
                                        //onAddSuccess(); 
                                    }}
                                >
                                    Add Category
                                </button>

                            </div>
                            <div className='mt-4 flex justify-end'>
                                <div className={formMessage.type === "success" ? "mt-2 text-green-500" : formMessage.type === "error" && "mt-2 text-red-500"}>
                                    {formMessage.message ? formMessage.message : <>&nbsp;</>}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
}

export default AddCategory;
