import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import UserService from '../../services/UserService';
import { Context } from "../../contextApi/context";

const serverConfig = require("../../config/server.js");
const DeleteRecord = ({setDeleteRowClicked, importSlug, templateSlug, modalClosed, setModalClosed}) => {

    const handleLogout = () => {
        sessionStorage.clear();
        UserService.doLogout();
      }

    const {selectedOrganization} = useContext(Context);
    const [deleteReason, setDeleteReason] = useState('');
    const [token, setToken] = useState(null);

      const getToken = () => {
    
    
        console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
        .then(response=>{
          console.log('user_get_token response', response);
          if(response.data.status === 200){
            sessionStorage.setItem('token', response.data.token);
            setToken(response.data.token);
          } else if(response.data.jwt_validation === 'fail') {
            handleLogout();
          }
        })
        .catch(err=>console.log("error is",err));
      }
      
      useEffect(() => {
        console.log(sessionStorage.getItem('token'));
        if(sessionStorage.getItem('token') === null){
          getToken();
        } else {
          setToken(sessionStorage.getItem('token'));
        }
      }, []);

    const handleChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        setDeleteReason(value);

    }


    const handleDelete = () => {
        axios.post(serverConfig.api_base_url + "dwh_import_delete", {

            token: token,
            org: selectedOrganization?.slug,
            imp: importSlug,
            temp: templateSlug,
            deleteReason: deleteReason

        }).then(response=> {
            console.log("dwh_import_delete_response", response);
            if(response.data.status === 200) {
                setModalClosed(!modalClosed);
                setDeleteRowClicked('');
            }
            else if(response.data.status === 301){
                handleLogout();
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    return(
        <>
        <div className='delete-user  rounded-3xl py-4 px-9 relative  mx-28  my-5  text-custom text-sm flex justify-between flex-col items-center'>
        <div className='overflow-y-auto mt-2'>
        <div className='flex flex-col gap-8 mb-4'>

            <div className="dlt-heading">Delete Reason</div>

            <textarea className="dlt-reason" id="reason" name="reason" value={deleteReason} onChange={handleChange} placeholder="Write a reason for delete" rows="6" cols="50"> </textarea>

        </div>

        <div className='flex flex-row justify-end'>
      <button type="submit"  className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setDeleteRowClicked(''); setModalClosed(!modalClosed);}}>Cancel</button>
      <button type="submit"  className='delete-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {handleDelete();}}>Delete</button>
      </div>

        </div>
        <button className='absolute right-6 top-4' onClick={()=>{setDeleteRowClicked('');}}>
        <i class="fa-solid fa-xmark"></i>
        </button>
        </div>        
        </>
    )
}

export default DeleteRecord;