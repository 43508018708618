import React, {useContext} from "react";
import { Context } from "../contextApi/context";
import DatabaseService from "./tables/DatabaseService";

const MapMetadata = () => {
  const {collapse} = useContext(Context);

  return (<>
    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
        <div className="container-fluid metadata">
          <DatabaseService />
        </div>
      </div>
    </div>
  </>);
}

export default MapMetadata;