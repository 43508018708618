
export const permissionsData = [

    {
        permission : 'Create an Organization',
        groupName : 'org' 
    },

    
    {
        permission : 'Edit Organization',
        groupName : 'org' 
    },

    
    {
        permission : 'Archive Organization',
        groupName : 'org' 
    },

    
    {
        permission : 'Restore Organization',
        groupName : 'org' 
    },

    
    {
        permission : 'Leave Organization',
        groupName : 'org' 
    },

    
    {
        permission : 'Delete Organization',
        groupName : 'org' 
    },

    
    {
        permission : 'Billing',
        groupName : 'org' 
    },

    {
        permission : 'View me Dashboard',
        groupName : 'dashboard' 
    },

    
    {
        permission : 'View Team Dashboard',
        groupName : 'dashboard' 
    },

    {
        permission : 'View Organization Members',
        groupName : 'members' 
    },

    
    {
        permission : 'View and Manage Invites',
        groupName : 'members' 
    },

]