import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context.js';
import axios from 'axios';
import { useNavigate } from 'react-router';
import UserService from '../../services/UserService.js';
import "../css/mapmetadata.css";
import Notification from '../../common/notification/Notification.js';

const serverConfig = require("../../config/server.js");

const TemplateGroup = () => {
  const navigate = useNavigate();

  const {selectedOrganization, isDarkTheme, setActiveSubmenuOption} = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  
  const [formData, setFormData] = useState({});
  const [templateGroupList, setTemplateGroupList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [refreshTemplateGroupList, setRefreshTemplateGroupList] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);

      axios.post(serverConfig.api_base_url + "dwh_template_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("dwh_template_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          console.log(items);
          if (items !== null && items !== undefined){
            console.log(response.data.items);
            //console.log(response.data.items.map(el => {delete el.name; return el}));
            console.log(response.data.items.map((el) => {return {'name':el.name, 'template':el.template}}));
            setTemplateList(response.data.items.map((el) => {return {'name':el.name, 'template':el.template}}));
          } else {
            setTemplateList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));

      /* dwh_template_group_list_get */
      axios.post(serverConfig.api_base_url + "dwh_template_group_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("dwh_template_group_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null && items !== undefined){
            console.log(response.data.items);
            console.log(response.data.items.filter(el => el.display_name !== null).map((el) => {return {'display_name':el.display_name, 'templateGrp':el.templateGrp}}));
            setTemplateGroupList(response.data.items.filter(el => el.display_name !== null).map((el) => {return {'display_name':el.display_name, 'templateGrp':el.templateGrp}}));
            let appDataArray = response.data.items;
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              {item.enabled = item.status}
              {item.status = (<div className='flex flex-row'>
                {item.status === 0 ? <div className="bg-bdr bg-bdr-peach">
                  <div><i class="fa-regular fa-user"></i></div><div>Disabled</div>
                  </div> :
                  <div className="bg-bdr bg-bdr-green">
                  <div><i class="fa-regular fa-user"></i></div><div>Enabled</div>
                </div>}
              </div>)}

              let noOfActions = 1 + 1;
              const Actions = () => <>
                <Button title={noOfActions > 2 ? '' : 'Edit'} variant='text' onClick={() => handleShowEditTemplateModal(item)}><i className="fa-solid fa-pencil not-0" aria-hidden="true"></i> {noOfActions > 2 && <span>Edit</span>}</Button>
                <Button title={noOfActions > 2 ? '' : 'Delete'} variant='text' onClick={() => handleShowDeleteTemplateModal(item?.templateGrp, item?.display_name)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> {noOfActions > 2 && <span>Delete</span>}</Button>
              </>;
              {item.actions = (noOfActions > 2 ? <div className="text-right more-options-wrapper">
                <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
                <div className="more-options" style={{display: "none"}}>
                  <Actions />
                </div>
              </div> : <div className="flex items-center justify-end gap-3">
                <Actions />
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setTableData(appData);
          } else {
            setTableData([]);
            setTemplateGroupList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
    
  }, [token, selectedOrganization, refreshTemplateGroupList]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  const sortDown=<i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp=<i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ['Updated', 'Option 2', 'Option 3'];
  const sortingIcons= [sortDown,sortUp];

  const columns = [
    {
      name: '',
      selector: row => row.display_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div className='font-bold text-sm'>Child Group Type</div>,
      selector: row => row.child_group_display_text,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='font-bold text-sm'>Template</div>,
      selector: row => row.template_name,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='font-bold text-sm'>Parent</div>,
      selector: row => row.parent_group,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='font-bold text-sm'>Status</div>,
      selector: row => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='font-bold text-sm'>Actions</div>,
      selector: row => row.actions,
    }
  ];

  const handleEnable = (item, slug, enable) => {
    console.log(console.log(item));
    console.log(formData);
    console.log(enable);
    axios.post(serverConfig.api_base_url + "dwh_template_group_update",{token: token, org:selectedOrganization?.slug, items:{...item, status: enable === 0 ? 0 : 1}, templateGrp:slug})
    .then(response=>{
      console.log('dwh_template_group_update response', response);
      if(response.data.status === 200){
        console.log('success');
        setRefreshTemplateGroupList(!refreshTemplateGroupList);// to refresh template group list after is_enabled is set 0/1
        handleShowAlertModal('Category '+ (enable === 0 ? 'disabled' : 'enabled'));
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  const [createChildGroupTypeOption, setCreateChildGroupTypeOption] = useState("");
  const handleCreateChildGroupTypeOption =(event) => {
    console.log(event.target.value);
    setCreateChildGroupTypeOption(event.target.value);
  }

  //Create
  const [createTemplateModalShow, setCreateTemplateModalShow] = useState(false);
  const handleCloseCreateTemplateModal = () => {
    setCreateTemplateModalShow(false);
    setFormData({});
    setCreateChildGroupTypeOption("");
  }
  const handleShowCreateTemplateModal = () => {
    setCreateTemplateModalShow(true);
  }

  const handleCreateTemplateSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(formData.status, formData.status === 0 ? formData.status : 'n');
    console.log(Object.keys(formData).length);
    console.log(!Object.values(formData).every(o => o === ''));
    if(Object.keys(formData).length > 0 && !Object.values(formData).every(o => o === '')){//if values are not null
      axios.post(serverConfig.api_base_url + "dwh_template_group_create",{
        token:token,
        org:selectedOrganization?.slug,
        items: {...formData, status: formData.status === 0 ? 0 : 1} //take default is_enabled value as 1
      })
      .then(response=>{
        console.log("dwh_template_create:", response);
        if (response.data.status === 200) {
          setFormData({});
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
          handleCloseCreateTemplateModal();
          handleShowAlertModal('Template category created successfully');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    } else {
      handleShowAlertModal("No changes detected", true);
    }
  }

  //Edit
  const [templateSlug, setTemplateSlug] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [editTemplateModalShow, setEditTemplateModalShow] = useState(false);
  const handleCloseEditTemplateModal = () => {
    setEditTemplateModalShow(false);
    setTemplateSlug("");
    setTemplateName("");
    //setFormMessage({});
    setFormData({});
    setCreateChildGroupTypeOption("");
  }
  const handleShowEditTemplateModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditTemplateModalShow(true);
    if(details.child_group_type !== null && details.child_group_type !== undefined){
      setCreateChildGroupTypeOption("Yes");
    } else if(details.template_name){
      setCreateChildGroupTypeOption("No");
    }

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleUpdateTemplate = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_template_group_update",{token: token, org:selectedOrganization?.slug, items:formData, templateGrp:formData.templateGrp})
    .then(response=>{
      console.log("dwh_template_group_update:", response);
      if (response.data.status === 200) {
        handleShowAlertModal('Template Category updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        handleCloseEditTemplateModal();
        setRefreshTemplateGroupList(!refreshTemplateGroupList);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Delete
  const [deleteTemplateModalShow, setDeleteTemplateModalShow] = useState(false);
  const handleCloseDeleteTemplateModal = () => {
    setDeleteTemplateModalShow(false);
    setTemplateSlug("");
    setTemplateName("");
    //setFormMessage({});
    setFormData({});
  }
  const handleShowDeleteTemplateModal = (templateSlug, templateName) => {
    setDeleteTemplateModalShow(true);
    console.log(templateSlug);
    setTemplateSlug(templateSlug);
    setTemplateName(templateName);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleDeleteTemplate = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "dwh_template_group_delete",{token:token, template:templateSlug})
    .then(response=>{
      console.log("dwh_template_group_delete:", response);
      if (response.data.status === 200) {
        handleShowAlertModal('Template Category deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        handleCloseDeleteTemplateModal();
        setRefreshTemplateGroupList(!refreshTemplateGroupList);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        //setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  /* const handleGoToTemplate = (data) => {
    console.log(data);
    console.log({...data, status:data.status.props.children[0]});
    navigate('/template-category/category', {state: {
        details: JSON.stringify({...data, status:data.status.props.children[0]}),
        templateGroupList: templateGroupList,
        templateList: templateList
      }
    });
  } */

  return(
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-mapmetadata mt-4">
        <div className="flex justify-between items-center space-x-4">
          <h1 className='font-semibold text-2xl mt-4 mb-4'>Template Categories</h1>
          {tableData.length > 0 && <div className="flex items-center space-x-4 text-black">
            {/* <div className='flex items-center gap-12 mt-4'>
              <div className="relative">
                <input type="text" placeholder="Search" className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input" />
                <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                  <i className=" text-sm fas fa-search"></i>
                </div>
              </div>
            </div>
            <div className="relative inline-block">
              <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
                <div className='pr-5'>
                  <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <i className="fa-solid fa-chevron-down text-sm"></i> 
              </div>
            </div> */}
            <button type='button' className='sub-grp px-3 py-2' onClick={handleShowCreateTemplateModal}><i className="fa-solid fa-plus mr-2" aria-hidden="true"></i> Create Template Category</button>
          </div>}
        </div>
        {tableData.length > 0 ? <DataTable
          columns={columns}
          data={tableData}
          responsive={true}
          pagination={tableData.length > 10 ? true : false}
          //striped
          highlightOnHover
          //pointerOnHover
          customStyles={{
            rows: {
              style: {
                minHeight: '48px',
                border: '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            }
          }}
          //onRowClicked={(row) => {handleGoToTemplate(row)}}
        /> : <div className='flex flex-col h-3/4 items-center justify-center'>
        <div className='mb-8'><img src={require('./../../Asset/Images/no-records.png')} alt="No records" /></div>
        <p><b>This Organisation has no Template Categories</b></p>
        <p className='text-sm'>Create first Template Category for {selectedOrganization?.name}</p>
        <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={handleShowCreateTemplateModal}>New Template Category</button></div>
      </div>}

        {createTemplateModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Create Template Category</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreateTemplateSubmit}>
              <div className='mt-5 mb-5'>
                <div>
                  {/* <div className='flex flex-col gap-1 text-xs'>
                    <label>Name
                      <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip" className='text-sm'>No whitespace and only lowercase allowed</Tooltip>}>
                        <Button className="ms-2 info-icon">i</Button>
                      </OverlayTrigger>
                    </label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' pattern="[a-z_]*" className='custom-input py-2 px-2 text-left mt-1'/>
                  </div> */}
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Name</label>
                    <input type="text" value={formData.display_name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value.replace(/[^A-Za-z ]+/g, '').replace(/ /g,"_").toLowerCase(), display_name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' />
                  </div>
                  {templateGroupList.length > 0 && <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Parent Group</label>
                    <select value={formData.templateGrp || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, templateGrp: e.target.value})}>
                      <option value="">Select</option>
                      {
                        templateGroupList.map((item, index) => <option value={item.templateGrp} key={index}>{item.display_name}</option>)
                      }
                    </select>
                  </div>}
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Do you want to create a Child Group?</label>
                    <div class="flex items-center my-2">
                      <div class="radio">
                        <label class="flex font-normal mr-4">
                        <input type="radio" name="child_group" value="Yes" onChange={handleCreateChildGroupTypeOption} />
                        <span class="custom-radio"></span>
                        <div class="label-checkbox">Yes</div></label>
                      </div>
                      <div class="radio">
                        <label class="flex font-normal">
                        <input type="radio" name="child_group" value="No" onChange={handleCreateChildGroupTypeOption} />
                          <span class="custom-radio"></span>
                          <div class="label-checkbox">No</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {createChildGroupTypeOption === "Yes" && <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Child Group Type<span className='text-red-500'>*</span></label>
                    <input type="text" value={formData.child_group_type || ""} onChange={(e)=>  setFormData({ ...formData, template:undefined, child_group_type: e.target.value, child_group_display_text: e.target.value && 'Select ' + e.target.value.split(" ").map((word) => (word[0] ? word[0].toUpperCase() : '') + word.substring(1)).join(" ")})} placeholder='Enter Child Group Type Name' className='custom-input py-2 px-2 text-left mt-1' required />
                  </div>}
                  {createChildGroupTypeOption === "No" && <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Template<span className='text-red-500'>*</span></label>
                    {templateList.length === 0 && <span className="text-red-500">Currently there are no Templates. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/template-management"); setActiveSubmenuOption('Template Management');}}>here</span></span>}
                    <select value={formData.template || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, template: e.target.value, child_group_type: undefined, child_group_display_text:undefined})} required>
                      <option value="">Select</option>
                      {templateList.length > 0 &&
                        templateList.map((item, index) => <option value={item.template} key={index}>{item.name}</option>)
                      }
                    </select>
                  </div>}
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Status</label>
                    <div className='flex flex-row mt-2'>
                      Disabled
                      <label class="checkbox-toggle-simple mx-3"><input type="checkbox" defaultChecked={true} onChange={(e) => setFormData({ ...formData, status: e.target.checked ? 1 : 0})} /><span></span></label>
                      Enabled
                    </div>
                  </div>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateTemplateModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>}

        {editTemplateModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2' onSubmit={handleUpdateTemplate}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Edit Template Category</div>
              </div>
      
              <div className='mt-4'>
                {/* <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip" className='text-sm'>No whitespace and only lowercase allowed</Tooltip>}>
                      <Button className="ms-2 info-icon">i</Button>
                    </OverlayTrigger>
                  </label>
                  <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' pattern="[a-z_]*" className='custom-input py-2 px-2 text-left mt-1'/>
                </div> */}
                <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                  <label>Name</label>
                  <input type="text" value={formData.display_name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value.replace(/[^A-Za-z ]+/g, '').replace(/ /g,"_").toLowerCase(), display_name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' />
                </div>
                {templateGroupList.length > 0 && <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                  <label>Parent Group</label>
                  <select value={formData.parentGrp || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, parentGrp: e.target.value})}>
                    <option value="">Select</option>
                    {
                      templateGroupList.map((item, index) => <option value={item.templateGrp} key={index}>{item.display_name}</option>)
                    }
                  </select>
                </div>}
                <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Do you want to create a Child Group?</label>
                  <div class="flex items-center my-2">
                    <div class="radio">
                      <label class="flex font-normal mr-4">
                      <input type="radio" name="child_group" value="Yes" onChange={handleCreateChildGroupTypeOption} defaultChecked={createChildGroupTypeOption === "Yes"} />
                      <span class="custom-radio"></span>
                      <div class="label-checkbox">Yes</div></label>
                    </div>
                    <div class="radio">
                      <label class="flex font-normal">
                      <input type="radio" name="child_group" value="No" onChange={handleCreateChildGroupTypeOption} defaultChecked={createChildGroupTypeOption === "No"} />
                        <span class="custom-radio"></span>
                        <div class="label-checkbox">No</div>
                      </label>
                    </div>
                  </div>
                </div>
                {createChildGroupTypeOption === "Yes" && <>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Child Group Type<span className='text-red-500'>*</span></label>
                    <input type="text" value={formData.child_group_type || ""} onChange={(e)=>  setFormData({ ...formData, template:undefined, child_group_type: e.target.value, child_group_display_text: e.target.value && 'Select ' + e.target.value.split(" ").map((word) => (word[0] ? word[0].toUpperCase() : '') + word.substring(1)).join(" ")})} placeholder='Enter Child Group Type Name' className='custom-input py-2 px-2 text-left mt-1' required />
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Child Group Display Text</label>
                    <div className='py-2 px-2 text-left mt-2'>{formData.child_group_display_text}</div>
                  </div>
                </>}
                {createChildGroupTypeOption === "No" && <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Template<span className='text-red-500'>*</span></label>
                  <select value={formData.template || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, template: e.target.value, child_group_type: undefined, child_group_display_text:undefined})} required>
                    <option value="">Select</option>
                    {templateList.length > 0 &&
                      templateList.map((item, index) => <option value={item.template} key={index}>{item.name}</option>)
                    }
                  </select>
                </div>}
                <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                  <label>Status</label>
                  <div className='flex flex-row mt-3'>
                    Disabled
                    <label class="checkbox-toggle-simple mx-3"><input type="checkbox" defaultChecked={formData.enabled === 1 ? true : false} onChange={(e) => setFormData({ ...formData, status: e.target.checked ? 1 : 0, enabled: e.target.checked ? 1 : 0})} /><span></span></label>
                    Enabled
                  </div>
                </div>
              </div>
            </div>

            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditTemplateModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
            </div>
          </form>
        </div>}

        {deleteTemplateModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleDeleteTemplate}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Template Category</div>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Template Category <b>{templateName}</b> ?</p>
              </div>
            </div>

            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteTemplateModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}

        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
      </div>
    </div>
  );
}

export default TemplateGroup;