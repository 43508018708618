// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-org {
  text-align: center;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  font-weight: 700;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/kz-organization/OrgLanding.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".first-org {\n  text-align: center;\n}\n\n.welcome-container {\n  display: flex;\n  flex-direction: column;\n  /* justify-content: center; */\n  align-items: center;\n  height: 100vh;\n  position: relative;\n  text-align: center;\n  overflow: hidden;\n  font-weight: 700;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
