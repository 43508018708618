import React, { useEffect } from 'react'
import { useState } from 'react'
function Popup({link , setLink, description, setDescription, setInputEntries, inputEntries, setPopup}){
  const addEntry = () => {
    if (link && description) {
      // Create a new entry object
      const newEntry = {
        link,
        description,
      };

      // Add the new entry to the array
      setInputEntries([...inputEntries, newEntry]);

      // Clear input fields
      setPopup(false);
      setLink('');
      setDescription('');
    }
  };

  return(
    <div className='popup p-4 bg-gray-300 mt-3 relative'>
      <div className='text-sm px-1 text-custom title-bdr-btm'>Add New Link</div>
      <div className='text-sm px-1 text-custom'>URL</div>
      <input type="text" className='w-full p-2 rounded-lg link-txt mt-1 text-xs' value={link} onChange={(e) => { setLink(e.target.value) }} placeholder='URL' />
      <div className='mt-2 text-sm px-1 text-custom'>Name</div>
      <input type="text" className='w-full p-2 rounded-lg input-custom description-txt mt-1 text-xs' value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder='Name' />
      <div className="mt-3">
        <button type='button' className='cancel-btn-popup px-4 py-2 rounded-lg text-sm font-medium' onClick={() => { setPopup(false); setLink(''); setDescription('');}}>Cancel</button>
        <button type='button' className='btn-popup px-4 py-2 rounded-lg text-sm  font-medium' onClick={addEntry}>Add</button>
      </div>
    </div>
  )
}

function Link({setFormData, formData}) {
  const[popup, setPopup]= useState(false)
  const[link , setLink]= useState('')
  const[description , setDescription]= useState('')
  const [inputEntries, setInputEntries] = useState(formData.links ? formData.links : []);

  useEffect(() => {
    console.log(inputEntries);
    setFormData({...formData, links:inputEntries});
  }, [inputEntries]);

  return (
    <div className='mt-7'>
      <div className='flex items-center justify-start'>
        <label>Links</label>
        <label className="cursor-pointer">
          <button type='button' className="font-medium inline-block text-gray-500 text-lg mx-2" onClick={() => { setPopup(!popup) }}>
            <i className="fa-solid fa-plus font-normal"></i>
          </button>
        </label>
      </div>
      {popup && <Popup link={link} setLink={setLink} description={description} setDescription={setDescription} inputEntries={inputEntries} setInputEntries={setInputEntries} setPopup={setPopup} />}

      <div className="flex flex-wrap ">
        {inputEntries.length > 0 &&
          inputEntries.map((entry, index) => {
            return (
              <div key={index} className='uploadedFile px-4 py-2  mt-2 mr-2 rounded-2xl' href={entry.Link}>
                <button type='button' className="btn-remove" title="Remove" onClick={() => setInputEntries(inputEntries.filter(el => el.link !== entry.link))}><i className="fa-solid fa-xmark "></i></button>
                <div className='flex items-center justify-center gap-4'>
                  <i class="fa-solid fa-link text-gray-500 text-sm"></i>
                  <div>
                    <div className='filename line-clamp-1 w-20 mx-2'>{entry.description}</div>
                    <div className="fileDetail line-clamp-1 w-20 mx-2">{entry.link}</div>
                  </div>

                  <a href={entry.link} target="_blank">
                    <i class="fa-solid fa-arrow-up-right-from-square  text-gray-500 text-sm"></i>
                  </a>
                </div>

              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Link