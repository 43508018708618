import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../contextApi/context';
import Calender from './Calender';
import Assignees from './Assignees';
import AssigneesTwo from './AssigneesTwo';
import { userData } from '../../Asset/Data/UserData';
import Search from './Search.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const serverConfig = require("./../../config/server.js");

function Checklist({taskSlug, grpSlug, token, selectedOrganization, handleShowAlertModal, handleCloseAlertModal, getAssigneeList}) {
  const navigate = useNavigate();
  const{setActiveOption} = useContext(Context);
  const [popup, setPopup] = useState(false)
  const [checklistData, setChecklistData] = useState([]);
  const [name, setName] = useState([]);
  const [description, setDescription] = useState('');
  const [desc, setDesc] = useState('');
  //const [date, setDueDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date().toISOString().substring(0, 10));
  //const [name, setName] = useState([]);
  const [names, setNames] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [addingNameIndex, setAddingNameIndex] = useState(0);
  const [hours, setHours] = useState(6);
  const [minutes, setMinutes] = useState('00');
  const [assigneesPopup, setAssigneesPopup] = useState(false);
  const [assigneesPopupTwo, setAssigneesPopupTwo] = useState(false);

  const [searchList, setSearchList] = useState([]);
  const [srchList, setSrchList] = useState([]);
  const [checklistMemberList, setChecklistMemberList] = useState([]);
  const [checklistMemberSlugList, setChecklistMemberSlugList] = useState([]);
  const [deleteChecklistConfirmationPopup, setDeleteChecklistConfirmationPopup] = useState(false);

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      getSubtaskList();
    }
  }, [token, selectedOrganization]);

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null && grpSlug !== null && grpSlug !== undefined){
      axios.post(serverConfig.api_base_url + "group_member_list_get",{token: token, org:selectedOrganization?.slug, grp: grpSlug})
      .then(response=>{
        console.log('group_member_list_get response', response);
        console.log('group_member_list_get response items',response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setSearchList(response.data.items);
            setSrchList(response.data.items);
          } else {
            setSearchList([]);
            setSrchList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          setSearchList([]);
          setSrchList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    console.log(checklistData);
    //checklistData.forEach(element => element.checklistMemberList = []);
  }, [token, selectedOrganization, grpSlug]);

  const getSubtaskList = () => {
    axios.post(serverConfig.api_base_url + "subtask_list_get",{token: token, org:selectedOrganization?.slug, tsk: taskSlug})
    .then(response=>{
      console.log('subtask_list_get response', response);
      console.log('subtask_list_get response items',response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setChecklistData(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        setChecklistData([]);
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  function getMonthName(month) {
    const monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"
    ];
    return monthNames[month];
  }
  const handleUpdateSubtask = (data, event) => {
    console.log(event.target.name);
    console.log(checklistData);
    let formData;
    if(event.target.name === "status"){
      formData = [{...data, status: event.target.checked ? "Closed" : "To Do"}];
    } else if(event.target.name === "dueDate"){
      formData = [{...data, dueDate: event.target.value}];
    } else {//description
      console.log(desc)
      formData = [{...data, description: desc}];
    }
    axios.post(serverConfig.api_base_url + "subtask_update", {token:token, org:selectedOrganization?.slug, sbtsk:data.sbtsk, items:formData})
    .then(response=>{
      console.log('subtask_update response', response);
      if(response.data.status === 200){
        console.log("success");
        getSubtaskList();
        setDesc("");
        setEditDescIndex("");
        setShowDescTextarea(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
    //event.preventDefault();
    // Create a copy of the checklistData array
    //const updatedFormData = [...checklistData];

    // Toggle the selected property for the specific data set
    //updatedFormData[index].status = !updatedFormData[index].status;
    /* if(updatedFormData[index].status === 'Closed'){
      updatedFormData[index].status = null;
    } else {
      updatedFormData[index].status = 'Closed';
    } */

    // Update the checklistData state
    //setChecklistData(updatedFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      if(name !== null && name !== undefined && name !== ''){
      /* userData.map((info, index) => {
        info.select = false;
      }) */
      // Create a new data object
      //"checklistMemberList": [{"usr": ["804M3na6pa","nfys2dqTCNzS"]}]}'
      const newData = {
        name,
        description,
        dueDate,
        checklistMemberList: [{usr: checklistMemberSlugList}]
      };

      console.log(newData);
      axios.post(serverConfig.api_base_url + "subtask_create", {token:token, org:selectedOrganization?.slug, grp:grpSlug, tsk: taskSlug, items:newData})
      .then(response=>{
        console.log('subtask_create response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            console.log("Subtask created successfully");
            getSubtaskList();
            getAssigneeList();
            // Clear the form inputs
            setPopup(false);
            setName('');
            setDescription('');
            setDueDate(new Date().toISOString().substring(0, 10));
            setChecklistMemberList([]);
            setChecklistMemberSlugList([]);
            setAssigneesPopup(false);
          }
        } else if(response.data.status === 301){
					handleLogout();
				} else {
					handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
				}
      })
      .catch(err=>console.log("error is",err));

      // Add the new data object to the checklistData array
      //setChecklistData([...checklistData, newData]);
      //console.log([...checklistData, newData]);
    }
  };

  /* const handleAddName = () => {

    if (name) {
      setNames([...names, name]);
      setName('');
    }
  }; */
  const handleAddNametwo = (index, members) => {
    // Set the index of the data set for which we're adding a name
    console.log(index, members)

    setAddingNameIndex(index);
    setAssigneesPopupTwo(!assigneesPopupTwo);
    setChecklistMemberList([]);
    let arr = [];
    if(members){
      members.forEach(el => {
        arr.push(el.usr);
      });
      console.log(arr);
    }
    setChecklistMemberSlugList(arr);
  };

  const[editDescIndex, setEditDescIndex] = useState("");
  const[showDescTextarea, setShowDescTextarea] = useState(false);
  const handleEnableTextarea = (index) => {
    // Set the index of the data set for which we're adding a name
    console.log(index)
    setEditDescIndex("");
    setEditDescIndex(index);
    setShowDescTextarea(true);

  };

  const handleCheckListDeleteConfirmationPopup = () => {
    setDeleteChecklistConfirmationPopup(true);
  };

  const handleCheckListDelete = (data) => {
    console.log(data);
    axios.post(serverConfig.api_base_url + "subtask_delete", {token:token, org:selectedOrganization?.slug, sbtsk:data.sbtsk})
    .then(response=>{
      console.log('subtask_delete response', response);
      if(response.data.status === 200){
        console.log("success");
        setChecklistData([]);
        getSubtaskList();
        setDeleteChecklistConfirmationPopup(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

  };

  const handleAddMember = (data) => {
    console.log(data);
    setChecklistMemberList([...checklistMemberList, {username: data.name, icon: data.icon, usr: data.slug}]);
    setChecklistMemberSlugList([...checklistMemberSlugList, data.slug]);
  }

  const handleRemoveMember = (userSlug) => {
    setChecklistMemberList(checklistMemberList.filter(el => el.usr !== userSlug));
    setChecklistMemberSlugList(checklistMemberSlugList.filter(el => el !== userSlug));
    console.log(checklistMemberSlugList);
  }

  const handleAddMemberTwo = (data, dataSrch) => {
    console.log(data, dataSrch);
    //setChecklistMemberSlugList([...checklistMemberSlugList, dataSrch.slug]);
    //setChecklistMemberList([{username: dataSrch.name, icon: dataSrch.icon, usr: dataSrch.slug}]);
    console.log(checklistMemberSlugList);
    let formData = {...data, checklistMemberList: [{usr: [...checklistMemberSlugList, dataSrch.slug]}]};
    console.log(formData);
    axios.post(serverConfig.api_base_url + "subtask_update", {token:token, org:selectedOrganization?.slug, sbtsk:data.sbtsk, items:formData})
    .then(response=>{
      console.log('subtask_update response', response);
      if(response.data.status === 200){
        console.log("success");
        getSubtaskList();
        getAssigneeList();
        setChecklistMemberSlugList([...checklistMemberSlugList, dataSrch.slug]);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleRemoveMemberTwo = (data, userSlug) => {
    //setChecklistMemberList(checklistMemberList.filter(el => el.usr !== userSlug));
    //setChecklistMemberSlugList(checklistMemberSlugList.filter(el => el !== userSlug));
    //console.log(checklistMemberSlugList);
    console.log(checklistMemberSlugList);
    let formData = {...data, checklistMemberList: [{usr: checklistMemberSlugList.filter(el => el !== userSlug)}]};
    console.log(formData);
    axios.post(serverConfig.api_base_url + "subtask_update", {token:token, org:selectedOrganization?.slug, sbtsk:data.sbtsk, items:formData})
    .then(response=>{
      console.log('subtask_update response', response);
      if(response.data.status === 200){
        console.log("success");
        getSubtaskList();
        getAssigneeList();
        setChecklistMemberSlugList(checklistMemberSlugList.filter(el => el !== userSlug));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <div className='mt-7'>
      <div className='flex items-center justify-start '>
        <label>Checklists</label>
        <label className="cursor-pointer">
          <button type="button" className="font-medium inline-block text-gray-500 text-lg mx-2" onClick={() => { setPopup(!popup) }}>
            <i className="fa-solid fa-plus font-normal"></i>
          </button>
        </label>
      </div>


      {/* popup form to add new information is here */}
      {popup &&
        <div className='popup p-4 bg-gray-300 mt-3 relative'>
          {/* <button type="button" className='absolute top-2 right-2 text-gray-400' onClick={() => { setPopup(false) }}>
            <i className="fa-solid fa-xmark icon-color"></i>
          </button> */}
          <div >
            <div className='text-sm px-1 text-custom'>Name:</div>
            <input type="text" className='w-full p-2 rounded-lg link-txt mt-1 text-xs' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div >
            <div className='text-sm px-1 text-custom'>Description:</div>
            <textarea type="text" className='w-full p-2 rounded-lg link-txt mt-1 text-xs' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>

          <div className='relative mt-4'>
            {/* <label className=' text-sm px-1 text-custom'>
              <div className='flex justify-start gap-2 align-middle '>
                <button type="button" onClick={() => { setShowCalendar(!showCalendar) }}>Add Date and Time <i className="fa-solid fa-calendar-plus"></i></button>
                {showCalendar && <Calender date={date} setDueDate={setDueDate} setShowCalendar={setShowCalendar} hours={hours} setHours={setHours} minutes={minutes} setMinutes={setMinutes} />}
                <div>{date.toDateString()}</div>
              </div>
              <input type="date" className='w-full p-2 rounded-lg text-black  mt-1' value={date} onChange={(e) => setDueDate(e.target.value)} />
            </label> */}
            
            <div className='text-sm px-1 text-custom'>Due Date:</div>
            <input type="date" className='w-full rounded-lg link-txt mt-1 text-xs' min={new Date().toISOString().substring(0, 10)} value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
          </div>

          <div className='relative mt-4'>
            {searchList.length === 0 && <><span className="text-red-500 text-xs">Currently there are no Members. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/groups"); setActiveOption('Groups');}}>here</span></span><br/></>}
            <button type="button" className=' text-sm px-1 mt-1 text-custom' onClick={() => { setAssigneesPopup(!assigneesPopup); setChecklistMemberSlugList([]); setChecklistMemberList([]); }}>
              Add member: <i className="fa-solid fa-user-plus"></i>
            </button>
            {/* {assigneesPopup && <Assignees handleAddName={handleAddName} setName={setName} name={name} names={names} setNames={setNames} />} */}
            {assigneesPopup && <div className='organisationDropdownCard mt-2' style={{width: '100%', left: 0, position: 'unset'}}>
              <div className='px-2 mt-4 w-full'><Search srchList={srchList} setSearchList={setSearchList} /></div>
              <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{borderBottom: 'none'}}>
                {searchList.length > 0 && <ul className='px-1 text-sm'>
                  {searchList.map((data, index)=>{
                    return(<li key={index} className={index === searchList.length-1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? handleRemoveMember(data.slug) : handleAddMember(data)} title={checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? `Remove ${data.name}` : `Add ${data.name}`}>
                      <div>{!data.icon ? <img src={`https://ui-avatars.com/api/?name=${data?.name.split(" ")[0]}+${data?.name.split(" ").length > 1 ? data?.name.split(" ")[data?.name.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`} alt={data?.name} className='rounded-full' width="40" /> : <img src={data.icon} alt={data?.name} className='rounded-full' width="40" />}</div>
                      <div className="ml-4">{data.name}</div>
                      {checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
                    </li>)
                  })}
                </ul>}
              </div>
            </div>}

            {checklistMemberList.length > 0 && <div className='flex assignee-list mt-3'>
              {checklistMemberList.map((item, index) => {
                return(<div title={item?.username}>{!item.icon ? <img src={`https://ui-avatars.com/api/?name=${item?.username.split(" ")[0]}+${item?.username.split(" ").length > 1 ? item?.username.split(" ")[item?.username.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`} alt={item?.username} className='rounded-full' width="40" /> : <img src={item.icon} alt={item?.username} className='rounded-full' width="40" />}</div>)
              })}  
            </div>}
            {/* <input
            type="text"
            className='p-2 rounded-lg text-black text-xs mt-1'
            value={name}
            onChange={(e) => setName(e.target.value)}
          /> */}
            {/* <button type="button" className='mx-1' onClick={handleAddName}>
            <i className="fa-solid fa-user-plus text-custom"></i>
            </button> */}
            { }
          </div>

          <div className='mt-3'>
            <button type='button' className='cancel-btn-popup px-4 py-2 rounded-lg text-sm font-medium' onClick={() => { setPopup(false); setName(''); setDescription(''); setDueDate(new Date().toISOString().substring(0, 10)); setChecklistMemberList([]); setChecklistMemberSlugList([]); setAssigneesPopup(false);}}>Cancel</button>
            <button type="button" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleSubmit}>Add</button>
          </div>
        </div>
      }
      {/* codes to display already existing Checklist */}
      <div className='mt-3 relative'>

        {checklistData.length > 0 && checklistData.map((data, index) => {
          console.log('<><><><><><><><>',checklistData.length);
          let todayDate=new Date().toISOString().split('T')[0];
          return(
            <div key={index} className="flex items-start justify-between gap-5 sidebar-data-todo m-1 py-3 px-2 relative">
              <div className='flex justify-start gap-2 items-start'>
                {/* slelect/unselect toggle button÷ */}
                <div>
                  <input
                    type="checkbox"
                    name="status"
                    defaultChecked={data.status === 'Closed'}
                    onChange={(e) => handleUpdateSubtask(data, e)}
                    className={data.status === 'Closed' ? 'h-4 w-4 bg-btn-teal rounded-sm flex items-center justify-center text-xs mt-0'
                      : 'rounded-sm border-btn-select h-4 w-4 flex items-center justify-center bg-white text-xs mt-0'}
                  />
                </div>
                {/* slelect/unselect toggle button÷ */}

                {/* description of the task */}
                <div className={`ml-2 ${data.status === 'Closed' ? 'flex gap-1 flex-grow line-through sidebar-txt ' : 'flex gap-1 flex-grow sidebar-txt '}`}>
                  <b>{data.name}:</b> 
                  {editDescIndex === index && showDescTextarea ? <div className='flex gap-2'>
                    <textarea className="w-full" name="description" defaultValue={data.description || desc} onChange={(e) => setDesc(e.target.value)}></textarea>
                    <button type='button' name="desc" title="Save" onClick={(e) => handleUpdateSubtask(data, e)}><i className="fa-regular fa-square-check text-sm"></i></button>
                    <button type='button' title="Cancel" onClick={() => {setDesc(""); setEditDescIndex(""); setShowDescTextarea(false);}}><i className="fa-regular fa-rectangle-xmark text-sm"></i></button>
                  </div> : <>{data.description}<button type='button' onClick={() => handleEnableTextarea(index)}><i className="fa-solid fa-pencil text-xs icon-color mx-1"></i></button><button type='button' onClick={() => handleCheckListDeleteConfirmationPopup()}><i className="fas fa-trash-can text-red-500 mx-1"></i></button></>}
                </div>
                {/* description of the task */}
              </div>

              <div className='flex items-center justify-end gap-2'>
                {/* display date selected start */}
                <div className={data.dueDate === 'Today' ? 'dueto-today relative' : (data.dueDate < todayDate ? 'dueto-past relative' : "sidebar-txt relative")}>
                  <div className='table-text-dark'>
                    {data.dueDate && data.dueDate === 'Today' ? 'Today' : (<span>
                    {getMonthName(new Date(data.dueDate).getMonth())} {new Date(data.dueDate).getDate()}
                    </span>)} {(data.dueDate === 'Today' || data.dueDate < todayDate) && <i className="fa-solid fa-triangle-exclamation ml-1"></i>}
                    <input type="date" name="dueDate" className='date-absolute' /* min={new Date().toISOString().substring(0, 10)} */ value={data.dueDate} onChange={(e) => handleUpdateSubtask(data, e)} />
                  </div>
                </div>
                {/* <div className='sidebar-txt relative'>
                  <input type="date" name="dueDate" className='date-absolute' min={new Date().toISOString().substring(0, 10)} value={data.dueDate} onChange={(e) => handleUpdateSubtask(data, e)} />
                  {data.dueDate && (<span>
                    {getMonthName(new Date(data.dueDate).getMonth())} {new Date(data.dueDate).getDate()}
                  </span>)}
                </div> */}
                {/* display date selected end */}

                {/* display names/images of assignees start */}
                {data.checklistMemberList && data.checklistMemberList.length > 0 && <p className='sidebar-txt'>
                  <div className='flex assignee-list'>
                    <div className='flex assignee-list'>
                      {data.checklistMemberList.map((item, index) => {
                        return (<div title={item?.username}>{!item.icon ? <img src={`https://ui-avatars.com/api/?name=${item?.username.split(" ")[0]}+${item?.username.split(" ").length > 1 ? item?.username.split(" ")[item?.username.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`} alt={item?.username} className='rounded-full' width="20" /> : <img src={item.icon} alt={item?.username} className='rounded-full' width="20" />}</div>)
                      })}
                    </div>
                  </div>
                </p>}

                <button
                  type="button"
                  className="text-blue-500"
                  onClick={() => handleAddNametwo(index, data.checklistMemberList)}
                ><i className="fa-solid fa-user-plus text-custom text-xs"></i></button>
                {/* <div>
              {data.names.map((name, nameIndex) => (
                <p key={nameIndex}> {name}</p>
              ))}
            </div>  */}

                {/* <div>
                  <input
                    type="text"
                    placeholder="Enter a name"
                    value={name}
                    onChange={handleNameInputChange}
                    onBlur={handleNameInputBlur}
                    className='rounded-lg px-2 py-2 text-xs input-custom'
                  />
                </div> */}

                

                {/* display names/images of assignees end */}
              </div>

              {index === addingNameIndex && assigneesPopupTwo && <>
                {/* <AssigneesTwo handleNameInputBlur={handleNameInputBlur}
                  handleNameInputChange={handleNameInputChange}
                  name={name} addingNameIndex={addingNameIndex}
                  lastIndex={checklistData.length}
                /> */}
                <div className='organisationDropdownCard mt-2' style={{width: '100%', left: 0, top: '30px'}}>
                  <div className='px-2 mt-4 w-full'><Search srchList={srchList} setSearchList={setSearchList} /></div>
                  <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{borderBottom: 'none'}}>
                    {searchList.length > 0 && <ul className='px-1 text-sm'>
                      {searchList.map((dataSrch, index)=>{
                        return(<li key={index} className={index === searchList.length-1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => data.checklistMemberList && data.checklistMemberList.length > 0 && data.checklistMemberList.find(el => el.usr === dataSrch.slug) ? handleRemoveMemberTwo(data, dataSrch.slug) : handleAddMemberTwo(data, dataSrch)} title={data.checklistMemberList && data.checklistMemberList.length > 0 && data.checklistMemberList.find(el => el.usr === dataSrch.slug) ? `Remove ${dataSrch.name}` : `Add ${dataSrch.name}`}>
                          <div>{!dataSrch.icon ? <img src={`https://ui-avatars.com/api/?name=${dataSrch?.name.split(" ")[0]}+${dataSrch?.name.split(" ").length > 1 ? dataSrch?.name.split(" ")[dataSrch?.name.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`} alt={dataSrch?.name} className='rounded-full' width="40" /> : <img src={dataSrch.icon} alt={dataSrch?.name} className='rounded-full' width="40" />}</div>
                          <div className="ml-4">{dataSrch.name}</div>
                          {data.checklistMemberList && data.checklistMemberList.length > 0 && data.checklistMemberList.find(el => el.usr === dataSrch.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
                        </li>)
                      })}
                    </ul>}
                  </div>
                </div>
              </>}

              {deleteChecklistConfirmationPopup && (
                <div className="overlay">
                    <div className="taskPopUp rounded-xl p-4">
                      <div className="flex flex-col">
                        <div className="scrollable mb-5 pr-5" style={{ maxHeight: "75vh" }}>
                            <div className="flex-grow overflow-y-auto">
                              <div>
                                <p>
                                  <b>Are you sure you want to delete this Checklist?</b>
                                </p>
                              </div>
                            </div>
                        </div>
                        {/* bottom buttom */}
                        <div className="ml-auto pr-6">
                          <button
                            type="button"
                            className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                            onClick={() => {
                              setDeleteChecklistConfirmationPopup(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                            onClick={() => handleCheckListDelete(data)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              )}
            </div>
          )
        })
    }
      </div>
    </div>

  )
}

export default Checklist