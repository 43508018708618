import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Context } from '../../contextApi/context';
import ShowError from './ShowErrors';
import axios from "axios";
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router';

const serverConfig = require("../../config/server.js");


const ImportData = ({setShowImportData, templatePresent}) => {

    const[close, setClose] = useState(true);
    const {selectedOrganization} = useContext(Context);

    const navigate = useNavigate(); 

    const handleLogout = () => {
        UserService.doLogout();
        sessionStorage.clear();
      }
    
      const [isLoading, setIsLoading] = useState(false);
    
      const [DIFormInputs, setDIFormInputs] = useState({});
      const [DIFormDisabled, setDIFormDisabled] = useState(false);
      const [templateSlug, setTemplateSlug] = useState('');
    
      const [filedata, setFiledata] = useState(null);
      const [dataCollectionDate, setDataCollectionDate] = useState(null);
      const [userComments, setUserComments] = useState('');
    
      const [uploadErrorList, setUploadErrorList] = useState([]);
      const [importId, setImportId] = useState(null);
      const [importSlug, setImportSlug] = useState('');
      const [uploadStatus, setUploadStatus] = useState('');
      const [showUploadErrorList, setShowUploadErrorList] = useState(false);
      
      const [templateGroup, setTemplateGroup] = useState([]);
      const [template, setTemplate] = useState(false);
      const [templateInstructions, setTemplateInstructions] = useState(null);
      const [templateParameter, setTemplateParameter] = useState([]);
      const [templateFileData, setTemplateFileData] = useState({});
      const [templateId, setTemplateId] = useState(null);
      const [token, setToken] = useState(null);
      const [file, setFile] = useState('');
      const [errorMessage, setErrorMessage] = useState('');
    
      const getToken = () => {
        //console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
        .then(response=>{
          console.log('user_get_token response', response);
          if(response.data.status === 200){
            sessionStorage.setItem('token', response.data.token);
            setToken(response.data.token);
          } else if(response.data.jwt_validation === 'fail') {
            handleLogout();
          }
        })
        .catch(err=>console.log("error is",err));
      }
      
      useEffect(() => {
        console.log(sessionStorage.getItem('token'));
        if(sessionStorage.getItem('token') === null){
          getToken();
        } else {
          console.log('user_get_token', sessionStorage.getItem('token'));
          setToken(sessionStorage.getItem('token'));
        }
      }, []);
    
      let interval;
      
      useEffect(() => {
        //getImportStatus();
        if(importId !== null && uploadStatus !== null){
          
          console.log(uploadStatus, 'upload_status');
          if(uploadStatus !== 'success' && uploadStatus !== 'failed'){
            interval = setInterval(function() {
              getImportStatus();
            }, 5000);
          }
    
        }
      }, [importId,uploadStatus]);
    
      const getImportStatus = () => {
        console.log('importId', importId);
        console.log('uploadStatus', uploadStatus);
        //import_get_status
        axios.post(serverConfig.api_base_url + "dwh_import_get_status",{token:token, imp:importSlug})
        .then(response=>{
          console.log('import_get_status response', response);
          if(response.data.status === 200){
            setDIFormDisabled(true);
            console.log(response.data.items, 'parsed_response');
                    const parsedResponse = response.data.items;
                    setUploadStatus(parsedResponse?.import_status);
                    console.log(parsedResponse?.import_status, 'status');
            
            if(parsedResponse?.import_status === 'processed') {
              
              console.log(response.data.items, 'res');
              setUploadErrorList(parsedResponse?.import_messages);
              setDIFormDisabled(true);
            }
            else if(response.data.items !== null && parsedResponse?.import_status === 'stg_populated') {
              setUploadErrorList(parsedResponse?.import_messages);
            }
            else if(response.data.items !== null && parsedResponse?.import_status === 'failed'){
              console.log(response.data, 'failed');
              setUploadErrorList(parsedResponse?.import_messages);
              setDIFormDisabled(false);
              handleShowUploadFail(true);
              clearInterval(interval);
              resetDIFormInputs();
            } else if(response.data.items !== null && parsedResponse?.import_status === 'success') {
              console.log(parsedResponse?.import_status , 'SUCCESS STATUS');
              setUploadErrorList(parsedResponse?.import_messages);
              setDIFormDisabled(false);
              handleShowUploadSuccess(true);
              clearInterval(interval);
              resetDIFormInputs();
              
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    
      useEffect(() => {
        if(token !== null && token !== undefined) {
        axios.post(serverConfig.api_base_url + "dwh_template_get_group",{token:token, parentGroupId:null,org:selectedOrganization?.slug})
        .then(response=>{
          console.log('template_get_group response', response);
          if(response.data.status === 200){
            if(response.data.items !== null){
              let respItems = response.data.items;
              console.log(respItems);
              console.log(respItems[0].id);
              if(respItems.id !== null){
                axios.post(serverConfig.api_base_url + "dwh_template_get_group",{token:token, parentGroupId:respItems[0].id, org:selectedOrganization?.slug})
                .then(response=>{
                  console.log('template_get_group response', response);
                  if(response.data.status === 200){
                    if(response.data.items !== null){
                      console.log(response.data.items);
                      var newArr = respItems.map(object => {
                        return {...object, options: response.data.items};
                      });
                      console.log(newArr);
                      setTemplateGroup(newArr);
                    }
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    alert(response.data.message);
                  }
                })
                .catch(err=>console.log("error is",err));
              }
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      } }, [token]);
    
      const handleSelect = (event) => {
        //templateGroup.splice(1);
        let arrIndex = event.target.getAttribute('data-index');
        console.log(arrIndex);
        templateGroup.splice(arrIndex+1);
        let selectedId = event.target.childNodes[event.target.selectedIndex].getAttribute('data-id');
        console.log(selectedId);
        //document.getElementById(selectedId).value = "";
        console.log(event.target.childNodes[event.target.selectedIndex].getAttribute('data-childgrptext'));
        console.log(event.target.id, event.target.value);
        console.log(templateGroup);
    
        //console.log(templateGroup.filter((x) => x.id == event.target.id));
        templateGroup.find(obj => {
          console.log(obj);
          console.log(obj.options);
          const arr = obj.options.filter((x) => x.id == selectedId);
    
          axios.post(serverConfig.api_base_url + "dwh_template_get_group",{token:token, parentGroupId:selectedId,org:selectedOrganization?.slug})
          .then(response=>{
            console.log('template_get_group response', response);
            if(response.data.status === 200){
              if(response.data.items !== null){
                console.log(response.data.items);
    
                var newArr = arr.map(object => {
                  return {...object, options: response.data.items};
                });
                console.log(newArr);
                console.log([...templateGroup, ...newArr]);
                setTemplateGroup([...templateGroup, ...newArr]);
                setTimeout(() => {
                  if(document.getElementById(selectedId)){
                    document.getElementById(selectedId).value = "";
                    setDIFormInputs({});
                  }
                });
    
                console.log(arr);
              }
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
    
          console.log(arr, arr.length);
          if(arr.length > 0){
            console.log(arr[0].template_id);
            setTemplateId(arr[0].template_id);
            setTemplateSlug(arr[0].slug);
            if(arr[0].template_id !== null){
              setTemplate(true);
    
              //template_get_instructions
              axios.post(serverConfig.api_base_url + "dwh_template_get_instructions",{token:token, templateId:arr[0].template_id})
              .then(response=>{
                console.log('template_get_instructions response', response);
                if(response.data.status === 200){
                  if(response.data.item !== null){
                    console.log(response.data.item);
                    setTemplateInstructions(response.data.items);
                  } else {
                    setTemplateInstructions(null);
                  }
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  alert(response.data.message);
                }
              })
              .catch(err=>console.log("error is",err));
    
              //template_get_parameter
              axios.post(serverConfig.api_base_url + "dwh_template_get_parameter",{token:token, templateId:arr[0].template_id})
              .then(response=>{
                console.log('template_get_parameter response', response);
                if(response.data.status === 200){
                  if(response.data.items !== null){
                    console.log(response.data.items);
                    setTemplateParameter(response.data.items);
                  } else {
                    setTemplateParameter([]);
                  }
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  alert(response.data.message);
                }
              })
              .catch(err=>console.log("error is",err));
              
              //template_get_file
              axios.post(serverConfig.api_base_url + "dwh_template_get_file",{token:token, templateId:arr[0].template_id})
              .then(response=>{
                console.log('dwh_template_get_file response', response);
                if(response.data.status === 200){
                  if(response.data.item !== null){
                    console.log(response.data.items);
                    setTemplateFileData(response.data.items);
                  } else {
                    setTemplateFileData({});
                  }
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  alert(response.data.message);
                }
              })
              .catch(err=>console.log("error is",err));
            } else {
              setTemplate(false);
            }
          } else {
            setTemplate(false);
          }
        });
      }
    
      const handleUserComments = (event) => {
        console.log(event);
        const name = event.target.name;
        const value = event.target.value;
        setUserComments(value);
      }
    
      const handleChangeDIFormInputs = (event) => {
        console.log(event);
        const name = event.target.name;
        const value = event.target.value;
    
        if(event.target.type === 'file'){
          var extension = value.split('.').pop();
    
          if(extension=='xls' || extension=='csv' || extension=='xlsx'){
            console.log("File type matched");
            var file = event.target.files[0];
            console.log(file, 'FILE');
            if(file !== undefined) {
              console.log(file?.name, 'file');
              console.log(event.target.files[0])
              setFile(event.target.files[0]);
              setFiledata(file?.name);
              setDIFormInputs(values => ({...values, [name]: file.name}));
            }
          } else {
            alert("Supports only xls, csv, xlsx document format.");
            event.target.value = null;
          }
        } else if(event.target.type === 'date'){
          setDataCollectionDate(value);
        }
      }
    
      const submitDataIngestionForm = (event) => {
        event.preventDefault();
        console.log(DIFormInputs);
        console.log(filedata);
        console.log(dataCollectionDate);
        console.log(userComments);
        if(token)
        axios.post(serverConfig.api_base_url + "dwh_import_initiate",{token:token, filename: DIFormInputs.file, comments:userComments, temp: templateSlug, org: selectedOrganization?.slug})
        .then(async (response) => {
          console.log('import_initiate response', response);
          if(response.data.status === 200){
            setImportId(response.data.imp);
            setImportSlug(response.data.imp)
            const formData = new FormData();
            formData.append('file',file, filedata);
            formData.append('data', `{"token" : ${token}, "slug" : ${response.data.imp}}`);
            console.log(formData);
            setIsLoading(true);
            const resp = await axios.post(serverConfig.japi_base_url + "import_upload", formData, 
            ).then((res) => {
              console.log(res);   
                if(res.data.message === 'success') {
                  axios.post(serverConfig.api_base_url + "dwh_import_run", {token: token, imp:response.data.imp, org: selectedOrganization?.slug})
                  .then(responseTestSP=> {

                    console.log(responseTestSP);
                      if(responseTestSP.data.status === 200) {
                        axios.post(serverConfig.api_base_url + "dwh_import_get_status",{token:token, imp:response.data.imp})
                        .then(response=>{
                          console.log('import_get_status response', response);
                          if(response.data.status === 200){
            
                            console.log(response.data.items, 'parsed_response');
                            const parsedResponse = response.data.items;
                            setUploadStatus(parsedResponse?.import_status);
                            console.log(parsedResponse?.import_status);
            
                            if(parsedResponse?.import_status === 'processed') {
                              
                              console.log(response.data.items?.import_messages, 'res');
                              setUploadErrorList(parsedResponse?.import_messages);
                              setDIFormDisabled(true);
                              //handleShowUploadFail(false);
                            }
                            else if(response.data.items !== null && parsedResponse?.import_status === 'stg_populated') {
                              setUploadErrorList(parsedResponse?.import_messages);
                            }
                            else if(response.data.items !== null && response.data.import_status === 'failed'){
                              console.log(response.data.items);
                              setUploadErrorList(response.data.import_messages);
                              setIsLoading(false);
                              setDIFormDisabled(false);
                              handleShowUploadFail(true);
                              clearInterval(interval);
                            } else if(response.data.items !== null && response.data.import_status === 'success') {
                              console.log('success status', response.data.import_status)
                              setUploadErrorList(response.data.import_messages);
                              setIsLoading(false);
                              setDIFormDisabled(false);
                              handleShowUploadSuccess(true);
                              //resetDIFormInputs();
                            }
            
                          } else if(response.data.status === 301){
                            handleLogout();
                            setIsLoading(false);
                          } else {
                            //alert(response.data.message);
                            setErrorMessage(response.data.message);
                            setIsLoading(false);
                          }
                        })
                        .catch(err=>console.log("error is", err));      
                        }
                        else {
                          setErrorMessage(response.data.message);
                          // alert(response.data.message);
                        }

                  }).catch(err=> {
                    console.log(err);
                  })
  
            .catch(err=>{
              console.log("error is",err);
              setIsLoading(false);
              alert("Server error occured. Please try after sometime.")
            });
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            setErrorMessage(response.data.message);
            // alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
      else if(response.data.status === 301){
        handleLogout();
      }
      else {
        setErrorMessage(response.data.message);
        // alert(response.data.message);
      }
    })
    }
    
      const resetDIFormInputs = () => {
        document.getElementById("uploadData").value = "";
        templateGroup.splice(1);
        document.getElementById('1').value = "";
        setTemplate(false);
        setTemplateInstructions(null);
        setTemplateParameter([]);
        setTemplateFileData({});
        setDIFormInputs({});
        setFiledata(null);
        setDataCollectionDate(null);
        setUserComments(null);
        setUploadErrorList([]);
      }
    
      const backToDIForm = () => {
        //resetDIFormInputs();
        setDIFormDisabled(false);
      }
    
      //modal success
      const [showUploadSuccess, setShowUploadSuccess] = useState(false);
      const handleCloseUploadSuccess = () => setShowUploadSuccess(false);
      const handleShowUploadSuccess = () => setShowUploadSuccess(true);
      //modal Fail
      const [showUploadFail, setShowUploadFail] = useState(false);
      const handleCloseUploadFail = () => setShowUploadFail(false);
      const handleShowUploadFail = () => setShowUploadFail(true);

    return (
        <>
         <div className='import-data  rounded-3xl py-4 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col'>
        <div className={template ? `overflow-scroll` : ``}>


        {isLoading === false ? 
        
        DIFormDisabled || showUploadErrorList === true ?
         <>
         
         <div className='overflow-y-auto '>
      <div className='flex items-center gap-8'>
      
           
    <div className='flex flex-col team-label'>

      <h5 className='mt-4 font-semibold mb-4'>
      {uploadErrorList.length}{uploadErrorList.length >= 50 ? '+' : null} errors found, please correct and re-upload the file
      </h5>

      {uploadErrorList.length > 0 && uploadErrorList.map((item, index) => {
                          return(
                            <>
                            { item?.message_type==='I' || item.message_type==='W' ? 
                            <div className="flex flex-row warning-text mt-2">

                            <img src={require("../assets/success.png")} className='success-image' />
                    
                            <div className="warning">{item?.message}</div>
                    
                          </div> :
                          item?.message_type==='E' ? 
                          
                          <div className="flex flex-row error-text mt-2">

                            <img src={require("../assets/error.png")} height={30}/>
                    
                            <div className="errors">{item?.message}</div>
                    
                          </div>

                          : <></> }
                            </>
                          )})}

      </div>
      </div>
      </div>
         
         </> :



         <>
        <div className='flex flex-row  gap-6'>
      
           
        <div className='flex flex-col team-label'>

            <h5 className='normal'>Data Management</h5>

            <div className="select-file mb-6">Select the type of file in order to view the template for that data</div>

            {templatePresent === false && <span className="text-red-500">Currently there are no templates. Please create one <span className='underline cursor-pointer' onClick={() => navigate("/template-management")}>here</span></span>}
         
            {templateGroup.map((item,index) => {
                        return(
                          
                          <div key={index} className='custom-select mb-6'>
                            <Form.Select data-index={index} id={item.id} name={"select_"+item.id} onChange={handleSelect} defaultValue="" required>
                              {/* <div className='optionsDropdownCard'> */}
                          
                              <option value="" className='custom-options py-2 my-1'>{item.child_group_display_text}</option>
                              {item.options.map((optitem, optindex) => {
                                return(
                                  <option key={optindex} className='custom-options py-2 my-1' data-id={optitem.id} data-childgrptext={optitem.child_group_display_text} value={optitem.name}>{optitem.display_name}</option>
                                )
                              })}
                            
                              {/* </div> */}
                            </Form.Select>
                          </div>
                        )
                      })}
                      {template && <>


                        {templateFileData.format === 'csv' ? <div className='txt-gray'><a href={'data:@file/csv;base64,'+ templateFileData.data} download={templateFileData.name+".csv"} className="template-view" title={templateFileData.name} />View Template</div> : <div className='txt-gray'><a href={'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ templateFileData.data} download={templateFileData.name} className="template-view" title={templateFileData.name}>View Template</a></div>}


                        {/* {Object?.keys(templateFileData)?.length > 0 && <div className='txt-gray'><a href={'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ templateFileData?.data} download={templateFileData?.name}  className="template-view" title={templateFileData?.name}>View Template</a></div>} */}

                        <Form.Group controlId="uploadData" className="upload-data mb-6 mt-1">
                          <Form.Label className='browse-files'>
                            {DIFormInputs.file ? 
                            
                            <><img src={require('../assets/cloud_upload.png')} alt="Upload Data" className="mb-3" /><br/>
                            {DIFormInputs.file}</>
                            
                            : <><img src={require('../assets/cloud_upload.png')} alt="Upload Data" height={95} className="mb-3" /><br/>
                            <p className='files-upload'>Drag &amp; Drop or click here to &nbsp; <p className='template'> Browse</p></p></>}
                          </Form.Label>
                          <Form.Control name="file" type="file" accept=".xls, .csv, .xlsx" filename={DIFormInputs.file || ''} onChange={handleChangeDIFormInputs} required />
                        </Form.Group>

                        <Form.Group controlId="UserComments" className="txt-gray">
                          <Form.Label><b>User Comments:</b><span className='txt-gray'>*</span></Form.Label>
                          <Form.Control className="user-comments mb-6 mt-1" onChange={handleUserComments} name="userComments" type="text" required />
                        </Form.Group>

                        {templateParameter.map((item, index) => {
                          return(
                            <Form.Group className='mb-3 flex flex-col' key={index}>
                              <Form.Label className='date-label'>{item.display_text}: <span className='txt-red'>*</span></Form.Label>
                              <Form.Control className='date' type={item.name} required onChange={handleChangeDIFormInputs} name="dataCollectionDate"></Form.Control>
                              <Form.Text className="text-muted">{item.instruction}</Form.Text>
                            </Form.Group>
                          )
                      })}
                        
                        {/* <p className={fileSizeError ? 'conditions-txt error-txt' : 'conditions-txt txt-blue'}><i><span>*</span>{fileSizeError ? fileSizeError : 'Supports only xls, csv, xlsx document format.'}</i></p> */}
                        {templateInstructions && <div className='txt-gray'>
                          <p className='mt-4 mb-4 normal'><span className='me-3'>*</span>Key instructions while uploading the file</p>
                          <div dangerouslySetInnerHTML={{__html: templateInstructions}}></div>
                          {/* <ul>
                            <li><i>Please upload the data only in the format provided in the template.</i></li>
                            <li><i>There should not be multiple tabs in a single sheet.</i></li>
                            <li><i>Only .xls files are allowed to be uploaded</i></li>
                          </ul> */}
                        </div>}
                      </>}
          
        </div>

        <div className='upload-gif'>
          <img src={require("../assets/upload-img.png")}/>
        </div>
        
        </div>
      </> :
       showUploadFail === true ?

       <div className='uploading-div flex flex-col items-center'>
         <h1 className='mt-4 failed-msg'>File upload failed</h1>
       <div className='flex flex-row gap-4'>
        <div>
           <img src={require(`./../assets/failed-icon.png`)} alt='' className='success-img' />
           <h1 className='mt-4 mb-4 success-text'><b>File upload failed <br/> due to one or more errors. <br/>
           Please rectify and reupload.</b></h1>
        </div>

        <div>
           <div className='list-container'>
           {uploadErrorList.length > 0 && uploadErrorList.map((item, index) => {
                                 return(
                   <>
                   {item?.message_type==='I' || item.message_type==='W' ? 
                   <div className="flex flex-row warning-text mt-2">

                    {/* <img src={require("../assets/success.png")} className='success-image' /> */}
           
                   <div className="warning">{item?.message}</div>
           
                 </div>
                   :
                item?.message_type==='E' ? 
                 
                  <div className="flex flex-row error-text mt-2">

                  {/* <img src={require("../assets/error.png")} height={30}/> */}
           
                  <div className="errors">{item?.message}</div>
           
                  </div>

                  : 

                  
                  <></> }
                    </>
                  )})}  
                  </div>
            </div>
         </div>
         <div className='flex flex-row justify-end done-btn'>
         <button type="submit" className='cancel-btn-popup mt-10 px-4 py-2 rounded-lg text-sm text-medium' onClick={()=>{setShowImportData(false);}}>Back to Upload</button>
         </div>
         </div>

      
      //  <div className='uploading-div flex flex-col items-center'>
      //  <div className='flex flex-col'>
      //      <img src={require(`./../assets/failed-icon.png`)} alt='' className='success-img' />
      //      <h1 className='mt-4 mb-4 success-text'><b>File upload failed due to one or more errors. <br/>
      //      Please rectify and reupload.</b></h1>
      //      <div className='list-container'>
      //      {uploadErrorList.length > 0 && uploadErrorList.map((item, index) => {
      //                            return(
      //              <>
      //              {item?.message_type==='I' || item.message_type==='W' ? 
      //              <div className="flex flex-row warning-text mt-2">

      //               <img src={require("../assets/success.png")} className='success-image' />
           
      //              <div className="warning">{item?.message}</div>
           
      //            </div>
      //              :
      //           item?.message_type==='E' ? 
                 
      //             <div className="flex flex-row error-text mt-2">

      //             <img src={require("../assets/error.png")} height={30}/>
           
      //             <div className="errors">{item?.message}</div>
           
      //             </div>

      //             : 


      //             <></> }
      //               </>
      //             )})}  
      //             </div>
      //    </div>
      //    <div className='flex flex-row justify-end done-btn'>
      //    <button type="submit" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setShowImportData(false);}}>Back to Upload</button>
      //    </div>
      //    </div>

      
      
      : showUploadSuccess === true ?
      <>

                <div className='uploading-div flex flex-col items-center'>
                <h1 className='mt-4 failed-msg'>File upload Success</h1>
                <div className='flex flex-row gap-4'>
                  <div>
                <img src={require(`./../assets/uploadSuccess.png`)} alt='' className='success-img' />
                <h1 className='mt-4 mb-4 success-text'><b>Success!</b></h1>
                </div>
                <div className='list-container'>
                {uploadErrorList.length > 0 ? uploadErrorList.map((item, index) => {
                    return(
                      <div>
                            <>
                            { item?.message_type==='I' || item.message_type==='W' ? 
                            <div className="flex flex-row warning-text mt-2">

                          {/* <img src={require("../assets/success.png")} className='success-image' /> */}
                    
                            <div className="warning">{item?.message}</div>
                    
                          </div>
                            :
                         item?.message_type==='E' ? 
                          
                           <div className="flex flex-row error-text mt-2">

                           {/* <img src={require("../assets/error.png")} height={30}/> */}
                    
                           <div className="errors">{item?.message}</div>
                    
                           </div>

                           : <></> }
                             </>
                             </div>
                           )}) :
                           <>

                           </>} 
                      </div> 
                  </div>
                  <div className='flex flex-row  justify-end done-btn'>
                  <button type="submit" className='cancel-btn-popup mt-10 px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setShowImportData(false);}}>Back to Upload</button>
                  </div>
                  </div>
      
      </>:    
      
      <>
      
      <div className='uploading-div flex flex-col items-center'>
                <div className='flex flex-col'>
                
      {uploadErrorList.length > 0 ?
      <>
      <div className='uploading-div flex flex-col items-center'>
              <h1 className='mt-4 failed-msg'>Uploading File</h1>
              <div className='flex flex-row gap-4'>
              <div>
             <img src={require(`../assets/invitingUser.png`)} alt='' className='success-img' />
             </div>
             <div className='list-container'>
              {uploadErrorList.map((item, index) => {
                          return(
                            <>
                            { item?.message_type==='I' || item.message_type==='W' ? 
                            <div className="flex flex-row warning-text mt-2">

                            {/* <img src={require("../assets/success.png")} className='success-image' /> */}
                    
                            <div className="warning">{item?.message}</div>
                    
                          </div> :
                          item?.message_type==='E' ? 
                          
                          <div className="flex flex-row error-text mt-2">

                            {/* <img src={require("../assets/error.png")} height={30}/> */}
                    
                            <div className="errors">{item?.message}</div>
                    
                          </div>

                          : <></> }
                            </>
                          )})}

                        </div>
                        </div>
                        <div className='mt-4 font-medium'>Note: Closing this window will not terminate the process. You can check the logs from View Log option.</div>
                        </div>

                          </> : <>
                          
                          <div className='loadingg-div flex flex-col'>

                           {errorMessage !== '' ? <p className="error-display">{errorMessage}</p> 
                            : <></> }
                          <img src={require(`../assets/invitingUser.png`)} alt='' className='loading-img' />
                          <h1 className=' mt-8'><b>Your data is uploading...</b></h1>
                          <div className='mt-4 font-medium'>Note: Closing this window will not terminate the process. You can check the logs from View Log option.</div>
                          </div>
                          
                          </> }
                          
                </div>
        </div>
        <div className='flex flex-row justify-center done-btn'>
                  <button type="submit" className='cancel-btn-popup mt-10 px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setShowImportData(false);}}>Back to Upload</button>
                  </div>
      
      </> }
        </div>

        {isLoading === false ?
        <div className='flex flex-row justify-end'>
      <button type="submit"  className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setShowImportData(false);}}>Cancel</button>
      <button type="submit"  className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={submitDataIngestionForm}>Upload Data</button>
      </div>
       : <></> }
       {/* <button className='absolute right-6 top-4' onClick={()=>{setShowImportData(false)}}>
       <i class="fa-solid fa-xmark"></i>
       </button> */}
        </div>
    </>
    )
}

export default ImportData;
