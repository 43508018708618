import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context.js';
import axios from 'axios';
import UserService from '../../services/UserService.js';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AddCategory from '../forms/AddCategory.js';
import DeleteCategory from '../popups/deleteCat.js';
import EditCat from '../popups/editCat.js';
import "../css/userman.css";
import "../css/cat.css";

const serverConfig = require('../../config/server.js');

function ManResTable() {
    const [tableData, setTableData] = useState([]);
    const { selectedOrganization } = useContext(Context);

    const [token, setToken] = useState(null);
    const [addCategory, setAddCategory] = useState(false);
    const [rowToDelete, setRowToDelete] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [editCategory, setEditCategory] = useState(null);
    const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
    const [showOptionsIndex, setShowOptionsIndex] = useState(null);

    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.clear();
        UserService.doLogout();
    };

    const getToken = async () => {
        try {
            let jwt = UserService.getToken();
            const response = await axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt });

            if (response.data.status === 200) {
                sessionStorage.setItem('token', response.data.token);
                setToken(response.data.token);
            } else if (response.data.status >= 400 && response.data.status <= 499) {
                alert(response.data.message);
            } else if (response.data.jwt_validation === 'fail') {
                handleLogout();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getResourceCategories = async () => {
        try {
            const response = await axios.post(serverConfig.api_base_url + 'resource_list', {
                token: token,
                org: selectedOrganization?.slug,
            });

            if (response.data.items !== null) {
                setTableData(response.data.items);
                if (response.data.status === 200) {
                    setTableData(response.data.items);
                } else if (response.data.status === 301) {
                    handleLogout();
                } else {
                    alert(response.data.message);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleShowMoreOptions = (index) => {
        setShowOptionsIndex(index === showOptionsIndex ? null : index);
    };

    const handleShowEditCategoryModal = (row) => {
        setEditCategory(row);
        setShowEditCategoryModal(true);
    };

    const handleShowDeleteCategoryModal = (row) => {
        setRowToDelete(row);
        setShowDeletePopup(true);
    };

    const handleDeleteCategorySuccess = () => {
        getResourceCategories();
        setShowDeletePopup(false);
    };

    const handleAddCategorySuccess = () =>{
        getResourceCategories();
    }

    useEffect(() => {
        const storedToken = sessionStorage.getItem('token');
        if (storedToken === null) {
            getToken();
        } else {
            setToken(storedToken);
        }
    }, []);

    useEffect(() => {
        if (token !== null && token !== undefined) {
            getResourceCategories();
        }
    }, [token]);

   
    const navigateToCategory = (row) => {
        setSelectedCategory(row);
        navigate(`/Category-details`,{state:{category:row.category, cat:row.slug}});
    };

    const columns = [
        {
            name: '',
            cell: (row) => (
                <div className='flex-column flex items-center py-2'>
                    <img
                        src={`https://ui-avatars.com/api/?name=${row.category}&rounded=true&length=1&${row.category ===
                            'Project' ? 'background=9D6EF8' : row.category === 'Departments' ? 'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`}
                        alt='image'
                        height={40}
                        width={40}
                        className='rounded-full'
                    />
                </div>
            ),
            grow: 0,
            sortable: false,
        },
        {
            name: <div className='row-label '>Category</div>,
            cell: (row) => (
                <div flex-column py-2>
                    <div
                        className='text-heading'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigateToCategory(row)}
                    >
                        {row.category}
                    </div>
                </div>
            ),
            sortable: true,
            wrap: true,
        },
        {
            name: <div className='row-label '>Description</div>,
            cell: (row) => (
                <div flex-column py-2>
                        {row.description}

                    </div>
                
            ),
            sortable: true,
            wrap: true,
        },
        {
            name: <div className='row-label '>Count</div>,
            selector: (row) => row.type,
            cell: (row) => <div>{row.count}</div>,
            sortable: true,
            wrap: true,
        },
        {
            name: <div className='row-label'>Actions</div>,
            cell: (row, index) => (
                <div className="text-right more-options-wrapper">
                    <button
                        type="button"
                        onClick={() => handleShowMoreOptions(index)}
                        title="More"
                        className='btn-more-options'
                    >
                        <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                    </button>
                    {showOptionsIndex === index && (
                        <div className="more-options">
                            <Button variant='text' onClick={() => handleShowEditCategoryModal(row)}>
                                <i className="fa-solid fa-pencil not-0" aria-hidden="true"></i>
                                <span>Edit</span>
                            </Button>
                            <Button variant='text' onClick={() => handleShowDeleteCategoryModal(row)}>
                                <i className="fa-solid fa-trash not-0" aria-hidden="true"></i>
                                <span>Delete</span>
                            </Button>
                        </div>
                    )}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className='overflow-x-auto'>
            <div className='rounded-3xl p-3 table-custom mt-4 '>
                {showDeletePopup && (
                    <div className='overlay'>
                        <DeleteCategory
                            setDeleteConfirmation={setShowDeletePopup}
                            rowToDelete={rowToDelete}
                            onDeleteSuccess={handleDeleteCategorySuccess}
                        />
                    </div>
                )}
                {showEditCategoryModal && (
                    <div className='overlay'>
                        <EditCat
                            setEditCategory={setShowEditCategoryModal}
                            rowToEdit={editCategory}
                            onEditSuccess={() => {
                                getResourceCategories();
                                setShowEditCategoryModal(false);
                            }}
                        />
                    </div>
                )}
                {addCategory && (
                    <div className='overlay'>
                        <AddCategory setAddCategory={setAddCategory}
                        onAddSuccess={handleAddCategorySuccess} />
                    </div>
                )}
                {tableData.length > 0 ? (
                    <DataTable
                        title={
                            <div className='table-title rounded flex justify-between items-center'>
                                <div className='flex space-x-4'>
                                    <h5 className='font-semibold'>Manage Resources</h5>
                                </div>
                                <div className='flex items-center space-x-4 text-black '>
                                    <div className='relative flex items-center gap-1'>
                                        <div className='relative'>
                                            <div className='absolute inset-y-5 left-0 pl-3 pb-3.5 flex items-center pointer-events-none'>
                                                <i className='text-sm fas fa-search'></i>
                                            </div>
                                            <input
                                                type='text'
                                                placeholder='Search'
                                                className='w-30 md:w-48 h-2 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300 search-input'
                                            />
                                        </div>
                                    </div>

                                    <div className='relative inline-block'>
                                        <div className='h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center'>
                                            <div className='pr-5'>
                                                <i className='fa-solid fa-arrow-down-short-wide text-sm'></i>
                                            </div>
                                        </div>
                                        <div className='absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none'>
                                            <i className='fa-solid fa-chevron-down text-sm'></i>
                                        </div>
                                    </div>

                                    <button className='sub-grp px-3 py-2' onClick={() => setAddCategory(true)}>
                                        Add Category
                                    </button>
                                </div>
                            </div>
                        }
                        columns={columns}
                        data={tableData}
                        responsive={true}
                        pagination={true}
                    />
                ) : (
                    <div className='flex flex-col h-full items-center justify-center'>
                        <div className='mb-8'><img src={require('../images/noResource.png')} alt="No Resources" /></div>
                        <p><b>This Organisation has no Resources</b></p>
                        <p className='text-sm'>Create the first Resource Category for {selectedOrganization?.name}</p>
                        <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={() => setAddCategory(true)}>Add Resource Category</button></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ManResTable;
