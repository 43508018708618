import Keycloak from "keycloak-js";

//const _kc = new Keycloak('/keycloak.json');
localStorage.removeItem("ssoDetails");//reset
//console.log(JSON.parse(localStorage.getItem("ssoDetails")));

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
var _kc;
const initKeycloak = (onAuthenticatedCallback) => {
  //console.log('init', localStorage.getItem("ssoDetails"));
  _kc = new Keycloak(JSON.parse(localStorage.getItem("ssoDetails")));
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

//const doLogin = _kc.login;

const doLogin = () => {
  //console.log('doLogin', localStorage.getItem("ssoDetails"));
  //console.log(_kc);
  _kc.login({
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
  .then((authenticated) => {
    if (!authenticated) {
      console.log("user is not authenticated..!");
    }
  })
  .catch(console.error);
};

const doLogout = () => {_kc.logout();};

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  {_kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);}

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getWelcomeName = () => _kc.tokenParsed?.given_name;

const hasRole = (roles) => {roles.some((role) => _kc.hasRealmRole(role))};
const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getWelcomeName,
  hasRole,
};

export default UserService;
