import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const serverConfig = require("./../../config/server.js");

const Stopwatch = forwardRef(
  (
    {
      token,
      selectedOrganization,
      taskSlug,
      totalDuration,
      handleShowAlertModal,
      handleCloseAlertModal,
      refreshTable,
      isTimerPrivileged,
      setStatus,
      todoData,
      setFormData,
      formData,
    },
    ref
  ) => {
    // state to store time
    const [time, setTime] = useState(0);

    // state to check stopwatch running or not
    const [isRunning, setIsRunning] = useState(false);

    const [startTime, setStartTime] = useState("");
    //const [endTime, setEndTime] = useState("");

    useEffect(() => {
      let intervalId;
      if (isRunning) {
        // setting time from 0 to 1 every 1 second using javascript setInterval method
        intervalId = setInterval(() => setTime((time) => time + 1), 1000);
      } else {
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }, [isRunning]);

    useEffect(() => {
      if (totalDuration) {
        console.log(totalDuration, totalDuration.split(".")[0]);
        var hms = totalDuration.split(".")[0]; // your input string
        var a = hms.split(":"); // split it at the colons

        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        //var milliseconds = seconds * 1000;
        //var fetchedTime = milliseconds / 9;

        //console.log(fetchedTime);
        setTime(seconds);
      }
    }, [totalDuration]);

    /* const alertUser = (event) => {
    event.preventDefault();
  } */

    const navigate = useNavigate();
    const [finishStatus, setfinishStatus] = useState(false);
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!finishStatus) {
        if (
          window.confirm(
            "The task timer is still running. Please stop the timer to capture the time tracked otherwise the data might be lost. Do you want to go back ?"
          )
        ) {
          setfinishStatus(true);
          // your logic
          navigate(-1);
        } /*  else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      } */
      }
    };

    useEffect(() => {
      if (isRunning) {
        window.history.pushState(null, null, window.location.pathname); //browser back click
        window.addEventListener("popstate", onBackButtonEvent); //browser back click
        window.addEventListener("beforeunload", onBackButtonEvent); //browser refresh / close
        return () => {
          window.removeEventListener("beforeunload", onBackButtonEvent);
          window.removeEventListener("popstate", onBackButtonEvent);
        };
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
        return () => {
          window.removeEventListener("beforeunload", onBackButtonEvent);
          window.removeEventListener("popstate", onBackButtonEvent);
        };
      }
    }, [isRunning]);

    const isClosed = todoData && (todoData.name === "Closed" || todoData.name === "To Do");

    // Hours calculation
    const hours = Math.floor(time / 360000);

    // Minutes calculation
    const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    // Milliseconds calculation
    const milliseconds = time % 100;

    const logTime = () => {
      let time2 = new Date(new Date().toUTCString()).toISOString();
      console.log(time2);
      let endTime =
        time2.split("T")[0] + " " + time2.split("T")[1].substring(0, 8);
      console.log(endTime);
      //console.log('time range', startTime, endTime, hours.toString().padStart(2, "0") +":"+ minutes.toString().padStart(2, "0") +":"+ seconds.toString().padStart(2, "0"));
      console.log("time range", startTime, endTime, formatSeconds(time));

      let formData = {
        start_time: startTime,
        end_time: endTime,
        //duration: hours.toString().padStart(2, "0") +":"+ minutes.toString().padStart(2, "0") +":"+ seconds.toString().padStart(2, "0")
      };

      axios
        .post(serverConfig.api_base_url + "time_log_create", {
          token: token,
          org: selectedOrganization?.slug,
          tsk: taskSlug,
          items: formData,
        })
        .then((response) => {
          console.log("time_log_create response", response);
          if (response.data.status === 200) {
            setStartTime("");
            refreshTable();
            handleShowAlertModal("Time logged successfully");
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    };
    useImperativeHandle(ref, () => ({
      doLogTime() {
        if (startTime !== null && startTime !== undefined && startTime !== "") {
          logTime();
        }
      },
    }));

    console.log(todoData);

    const startAndStop = () => {
      setIsRunning((stateIsRunning) => {
        stateIsRunning = !stateIsRunning;
        if (stateIsRunning) {
          // Change status to 'In Progress' if timer starts from 0 or if status is not 'In Progress'
          /* if (time === 0 || formData.status !== "In Progress") {
            const inProgressStatus = {
              value: "In Progress",
              seq_no: 2,
              attribute1: "fa-regular fa-clock",
              attribute2: "#FF9251",
            }; // Update with actual values
            setStatus(inProgressStatus);
            setFormData({ ...formData, status: "In Progress", start_date: new Date().toISOString().substring(0, 10) });
          } */
          if (time === 0) {
            setFormData({ ...formData, start_date: new Date().toISOString().substring(0, 10) });
          }
    
          // Start the timer
          let time1 = new Date(new Date().toUTCString()).toISOString();
          setStartTime(
            time1.split("T")[0] + " " + time1.split("T")[1].substring(0, 8)
          );
        } else {
          logTime();
        }
    
        return stateIsRunning;
      });
    };

    const formatSeconds = (s) =>
      new Date(s * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];

    // Method to reset timer back to 0
    const reset = () => {
      setTime(0);
    };

    return (
      <>
        {/* <div className="stopwatch-container">
      <p className="stopwatch-time">
        {hours}:{minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}:
        {milliseconds.toString().padStart(2, "0")}
      </p>
      <div className="stopwatch-buttons">
        <button type="button" className="stopwatch-button" onClick={startAndStop}>
          {isRunning ? "Stop" : "Start"}
        </button>
        <button type="button" className="stopwatch-button" onClick={reset}>
          Reset
        </button>
      </div>
    </div> */}
        {isClosed ? (
          <div
            className={
              isRunning
                ? "on play-pause p-2 rounded-full flex justify-between items-center gap-1 me-2"
                : "play-pause p-2 rounded-full flex justify-between items-center gap-1 me-2"
            }
            disabled
          >
            <button
              type="button"
              className="rounded-full play-pause-button h-10 w-10 flex items-center justify-center  text-white"
              onClick={startAndStop}
              disabled
            >
              <i className="fa-solid fa-play"></i>
            </button>
            <div className="font-medium text-sm text-custom">
              {/* <span className="inline-block text-center px-1 w-7">{hours.toString().padStart(2, "0")}</span>:<span className="inline-block text-center px-1 w-7">{minutes.toString().padStart(2, "0")}</span>:<span className="inline-block text-center px-1 w-7">{seconds.toString().padStart(2, "0")}</span> */}
              <span className="inline-block text-center px-1 w-20">
                {formatSeconds(time)}
              </span>
            </div>
          </div>
        ) : (
          <div
            className={
              isRunning
                ? "on play-pause p-2 rounded-full flex justify-between items-center gap-1 me-2"
                : "play-pause p-2 rounded-full flex justify-between items-center gap-1 me-2"
            }
          >
            <button
              type="button"
              className="rounded-full play-pause-button h-10 w-10 flex items-center justify-center  text-white"
              onClick={startAndStop}
              disabled={!isTimerPrivileged}
            >
              {isRunning ? (
                <i className="fa-solid fa-stop"></i>
              ) : (
                <i className="fa-solid fa-play"></i>
              )}
            </button>
            <div className="font-medium text-sm text-custom">
              {/* <span className="inline-block text-center px-1 w-7">{hours.toString().padStart(2, "0")}</span>:<span className="inline-block text-center px-1 w-7">{minutes.toString().padStart(2, "0")}</span>:<span className="inline-block text-center px-1 w-7">{seconds.toString().padStart(2, "0")}</span> */}
              <span className="inline-block text-center px-1 w-20">
                {formatSeconds(time)}
              </span>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default Stopwatch;
