import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { Context } from "../../contextApi/context.js";
import ProgressbarReadOnly from "./ProgressbarReadOnly.js";
import axios from "axios";
import Select from "react-select";
import Search from "./Search.js";
import * as FaIcons from 'react-icons/fa';

const serverConfig = require("../../config/server.js");

function TaskDropdownCard({data, selectedGrp, selectedIssue, setSelectedIssue, setCollapseSelectIssue, setIssueList, issList, setIssList, token, selectedOrganization, handleLogout, handleShowAlertModal, handleCloseAlertModal}){
  const { isDarkTheme } = useContext(Context);
  const [taskList, setTaskList] = useState([]);

  const handleGetTaskList = (tsk, event) => {
    //console.log(grp, event);
    console.log(issList);
    event.target.classList.toggle("fa-angle-up");
    event.target.classList.toggle("fa-angle-down");
    event.target.closest('.border-b').classList.toggle(isDarkTheme ? 'bg-neutral-600' : 'bg-slate-50');
    if(event.target.closest('.oragnisationHover').nextSibling !== null){
      event.target.closest('.oragnisationHover').nextSibling.classList.toggle("hidden");
    }
    axios.post(serverConfig.api_base_url + "issue_list_get",{token:token, org:selectedOrganization?.slug, grp:selectedGrp.grp, /* issueTypeSeqNo:data.issue_type.seq_no+1, */ tsk: tsk})
    .then(response=>{
      console.log('issue_list_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(response.data.items);
          setTaskList(response.data.items);
          /* for (let i = 0; i < issList.length; i++) {
            if (issList[i].tsk === tsk) {
              issList[i].child=response.data.items;
            }
          } */

          function addObjectToNestedArray(array, valueToFind, newObject) {
            for (let item of array) {
              if (item.tsk === valueToFind) {
                // Found the item, add the new object to its children
                //item.child.push(newObject);
                item.child=newObject;
                return true; // Return true to stop further searching
              }
              // If the item has children, recursively search in children
              if (item.child && item.child.length > 0) {
                if (addObjectToNestedArray(item.child, valueToFind, newObject)) {
                  return true; // Return true if the object was added in the recursive call
                }
              }
            }
            return false; // Return false if the value is not found
          }

          const valueToFind = tsk;
          addObjectToNestedArray(issList, valueToFind, response.data.items);

          console.log(issList);
          setIssueList(issList);
          setIssList(issList);
        } else {
          setTaskList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  return(<div className='border-b'>
    <div className={'flex gap-2 items-center justify-between p-2 oragnisationHover ' + (selectedIssue?.tsk === data?.tsk && (isDarkTheme ? 'bg-neutral-900' : 'bg-slate-200'))} style={{alignItems:'center'}}>
      <div className='flex gap-2 items-center' onClick={() => {
        setSelectedIssue(data); setCollapseSelectIssue(true); setIssueList(issList); 
        //setFormData({...formData, linkedissuetsk:data.tsk});
      }}>
        <div className="select-icon">
          {data.issue_type ? <span className='icn dropdown-content' style={{color:data.issue_type?.color, backgroundColor: data.issue_type?.color+'20', borderColor: data.issue_type?.color+'90'}}>{React.createElement(FaIcons[data?.issue_type?.icon])}</span> : <></>}
        </div>
        <div className='organisation-heading line-clamp-1'>{data.task_key +' ' +data.name}</div>
      </div>
      <div className="flex min-w-max">
        {selectedIssue?.tsk === data?.tsk && <><i className="fa-solid fa-check text-xs mr-2"></i></>}
        <button type="button" onClick={(e) => handleGetTaskList(data.tsk, e)}><i className="fa-solid fa-angle-down"></i></button>
      </div>
    </div>
    {data.child && data.child.length > 0 && <div className='pl-3'>
      {data.child.map((data)=>{
        return(<TaskDropdownCard data={data} selectedGrp={selectedGrp} setCollapseSelectIssue={setCollapseSelectIssue} selectedIssue={selectedIssue} setSelectedIssue={setSelectedIssue} setIssueList={setIssueList} issList={issList} setIssList={setIssList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
      })}
    </div>}
  </div>)
}

const RecursiveLinkedIssues = ({formData, taskSlug, selectedGrp, token, selectedOrganization, handleLogout, handleShowAlertModal, handleCloseAlertModal, refreshTable }) => {

  console.log(formData);

  const { isDarkTheme } = useContext(Context);

  const [tableData, setTableData] = useState([]);

  const [addingIssue, setAddingIssue] = useState(false);
  const [relationLookup, setRelationLookup] = useState([]);
  const [collapseSelectLinkedIssue, setCollapseSelectLinkedIssue]=useState(true);
  const [relation, setRelation] = useState({});
  const [selectedLinkedIssue, setSelectedLinkedIssue] = useState({});
  const [collapseEditSelectLinkedIssue, setCollapseEditSelectLinkedIssue]=useState(true);
  const [editRelation, setEditRelation] = useState({});
  const [actualSelectedLinkedIssue, setActualSelectedLinkedIssue] = useState({});
  const [editSelectedLinkedIssue, setEditSelectedLinkedIssue] = useState({});
  const[issList, setIssList] = useState([]);
  const[issueList, setIssueList] = useState([]);

  function Mapme(data) {
    return (
      data && (
        <div className="gap-2 overflow-hidden h-5 flex line-clamp-1 ">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="status"
                style={{
                  backgroundColor: `${item.color_code}30`,
                  borderColor: `${item.color_code}90`,
                }}
              >
                <div className="line-clamp-1">{item.name}</div>
              </div>
            );
          })}
        </div>
      )
    );
  }

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      axios.post(serverConfig.api_base_url + "lookup_list", {token: token, name: "issue_relation"})
      .then((response) => {
        console.log("issue_relation lookup:", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log("issue_relation data:", response.data.items);
            response.data.items.sort(function (a, b) {
              return a.seq_no - b.seq_no;
            });
            let arr = response.data.items.map((item) => {
              return({
                value:item.value,
                label:item.value
              });
            });
            console.log(arr);
            setRelationLookup(arr);
          } else {
            setRelationLookup([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
      
      axios.post(serverConfig.api_base_url + "linked_issue_list_get", {token:token, org:selectedOrganization?.slug, grp: selectedGrp.grp,tsk: taskSlug})
      .then(response=>{
        console.log('linked_issue_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setTableData(response.data.items);
          } else {
            setTableData([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token, selectedOrganization]);

  const columns = [
    {
      selector: (row) => row?.issue_type?.name,
      name: <div className="row-label">Type</div>,
      cell: (row) => (
        <div className="select-icon" title={row?.issue_type?.name}>
          {row.issue_type ? <span className='icn' style={{color:row.issue_type.color, backgroundColor: row.issue_type.color+'20', borderColor: row.issue_type.color+'90'}}>{React.createElement(FaIcons[row.issue_type?.icon])}</span> : <></>}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row) => row?.task_key,
      name: <div className="row-label">Key</div>,
      cell: (row) => (
        <div>{row?.task_key}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row) => row?.name,
      name: <div className="row-label">Name</div>,
      cell: (row) => (
        <div>{row?.name}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label">Status</div>,
      selector: (row) => row?.status,
      cell: (row) => (
        <div
          className="status"
          style={{
            color: row.status?.color && row.status?.color,
            backgroundColor:
              row.status?.color && row.status?.color + "20",
            borderColor:
              row.status?.color && row.status?.color + "90",
          }}
        >
          <div className="brightness-[0.6]">
            {React.createElement(FaIcons[row.status?.icon])}
          </div>
          <div className="brightness-[0.6]">{row.status?.name}</div>
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label">Started/Ended</div>,
      cell: (row) => (
        <div className="table-text-dark">
          {row?.start_date}
          {row?.end_date && <> &#8212; {row?.end_date}</>}
        </div>
      ),
      selector: (row) => row?.start_date,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label">Due Date</div>,
      cell: (row) => {
        let todayDate = new Date().toISOString().split("T")[0];
        return (
          <div
            className={
              row?.due_date === "Today"
                ? "dueto-today"
                : row.due_date < todayDate
                ? "dueto-past"
                : ""
            }
          >
            <div className="table-text-dark">
              {row?.due_date}{" "}
              {row?.due_date === "Today" || row.due_date < todayDate ? (
                <i className="fa-solid fa-triangle-exclamation ml-1"></i>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
      selector: (row) => row?.due_date,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label">Progress Percent</div>,
      cell: (row) => (
        <div style={{ fontSize: 10 }} className="w-full">
          <ProgressbarReadOnly progress_value={row.progress_percentage ? row.progress_percentage : 0}/>
        </div>
      ),
      selector: (row) =>
        row.progress_percentage ? row.progress_percentage : 0,
      sortable: true,
      wrap: true,
      grow: 1.8,
    },
    {
      name: <div className="row-label">Labels</div>,
      cell: (row) => (
        <div className="flex overflow-hidden">{Mapme(row?.labels)}</div>
      ),
      selector: (row) => row.labels,
      sortable: true,
      wrap: true,
    },
    {
      cell: (row) => (
        <>
          <div className="flex items-center justify-end gap-3">
            <button
              title="Edit Linked Issue"
              type="button"
              onClick={() => handleShowEditModal(row)}
            >
              <i className="fa-solid fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              title="Delete Linked Issue"
              type="button"
              onClick={() => handleDeleteIssue(row.issu)}
            >
              <i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i>
            </button>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      grow: 0,
    },
  ];

  //Add Issue
  const [showAddIssue, setShowAddIssue] = useState(false);
  const handleShowAddModal = (data) => {
    axios.post(serverConfig.api_base_url + "issue_list_get",{token:token, org:selectedOrganization?.slug, grp:selectedGrp.grp})
    .then(response=>{
      console.log("issue_list_get:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("issue_list_get data:", response.data.items);
          setIssList(response.data.items);
          setIssueList(response.data.items);
        } else {
          setIssList([]);
          setIssueList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    setShowAddIssue(true);
    console.log(data);
    setEditRelation({label: data.relation, value: data.relation});
    setActualSelectedLinkedIssue(data);
    setEditSelectedLinkedIssue(data);
  }

  const handleCloseAddModal = () => {
    setShowAddIssue(false);
    setRelation({});
    setSelectedLinkedIssue({});
    setIssList([]);
    setIssueList([]);
  }

  const handleAddLinkedIssue = () => {
    console.log(relation);
    console.log(selectedLinkedIssue);

    axios.post(serverConfig.api_base_url + "generate_slug", {length: 30})
    .then(response=>{
      if (response.data.status === 200) {
        console.log(response.data.slug, 'slug');
        console.log([
          ...tableData,
          {
            ...selectedLinkedIssue,
            relation: relation.label,
            issu: response.data.slug
          }
        ]);
        
        /* setFormData({
          ...formData,
          linkedIssues: [
            ...tableData,
            {
              ...selectedLinkedIssue,
              relation: relation.label,
              issu: response.data.slug
            }
          ]
        });
    
        setTableData([
          ...tableData,
          {
            ...selectedLinkedIssue,
            relation: relation.label,
            issu: response.data.slug
          }
        ]);
        setAddingIssue(false);
        setSelectedLinkedIssue({});
        setRelation({}); */

        console.log(formData);

        axios.post(serverConfig.api_base_url + "task_update", {
          token: token,
          org: selectedOrganization?.slug,
          tsk: taskSlug,
          items: {
            ...formData,
            linkedIssues: [
              ...tableData,
              {
                ...selectedLinkedIssue,
                relation: relation.label,
                issu: response.data.slug
              }
            ]
          },
        })
        .then((response) => {
          console.log("task_update response", response);
          if (response.data.status === 200) {
            refreshTable();
            handleCloseAddModal();
            handleShowAlertModal("Linked Issue added successfully");
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
      }
    }).catch((err) => console.log("error is", err));
  }

  //Edit Issue
  const [showEditIssue, setShowEditIssue] = useState(false);
  const handleShowEditModal = (data) => {
    axios.post(serverConfig.api_base_url + "issue_list_get",{token:token, org:selectedOrganization?.slug, grp:selectedGrp.grp})
    .then(response=>{
      console.log("issue_list_get:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("issue_list_get data:", response.data.items);
          setIssList(response.data.items);
          setIssueList(response.data.items);
        } else {
          setIssList([]);
          setIssueList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    setShowEditIssue(true);
    console.log(data);
    setEditRelation({label: data.relation, value: data.relation});
    setActualSelectedLinkedIssue(data);
    setEditSelectedLinkedIssue(data);
  }

  const handleCloseEditModal = () => {
    setShowEditIssue(false);
    setEditRelation({});
    setEditSelectedLinkedIssue({});
    setIssList([]);
    setIssueList([]);
  }

  const handleEditIssue = () => {
    console.log(editRelation, editSelectedLinkedIssue, actualSelectedLinkedIssue);

    let updatedData = {
      ...actualSelectedLinkedIssue,
      ...editSelectedLinkedIssue,
      relation: editRelation.label
    };
    console.log(updatedData);
    
    let result = tableData.map((item) => (item.issu === actualSelectedLinkedIssue.issu ? { ...item, ...updatedData } : item));
    console.log(result);
    /* setFormData({
      ...formData,
      linkedIssues: result
    });
    setTableData(result);
    handleCloseEditModal(); */

    console.log(formData);

    axios.post(serverConfig.api_base_url + "task_update", {
      token: token,
      org: selectedOrganization?.slug,
      tsk: taskSlug,
      items: {
        ...formData,
        linkedIssues: result
      },
    })
    .then((response) => {
      console.log("task_update response", response);
      if (response.data.status === 200) {
        refreshTable();
        handleCloseEditModal();
        handleShowAlertModal("Linked Issue updated successfully");
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if (response.data.status === 301) {
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch((err) => console.log("error is", err));
  }

  //Delete Issue
  const handleDeleteIssue = (issuSlug) => {
    console.log(tableData, issuSlug, formData);

    let arr = tableData;
    setTableData([]);

    setTimeout(() => {
      /* setFormData({
        ...formData,
        linkedIssues: arr.filter(el => el.issu !== issuSlug)
      });
      
      setTableData(arr.filter(el => el.issu !== issuSlug)); */

      axios.post(serverConfig.api_base_url + "task_update", {
        token: token,
        org: selectedOrganization?.slug,
        tsk: taskSlug,
        items: {
          ...formData,
          linkedIssues: arr.filter(el => el.issu !== issuSlug)
        },
      })
      .then((response) => {
        console.log("task_update response", response);
        if (response.data.status === 200) {
          refreshTable();
          handleShowAlertModal("Linked Issue deleted successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
    });
  }

  return (
    <div>
      <div className="overflow-x-auto px-4 ms-2 mt-4 pb-3 table-dbs-sub">
        <div>
          <DataTable
            title={
              <div className=" table-title rounded">
                <div className="flex justify-between items-center">
                  {/* Left side: Three tabs flexed together */}
                  <div className="flex space-x-4">
                    <h4 className='font-bold text-base'>Linked Issues</h4>
                  </div>
                  <span className="text-right">
                    <Button type='button' className='sub-grp px-3 py-2' title="Add Linked Issue" onClick={handleShowAddModal}><i className="fa-solid fa-plus" aria-hidden="true" style={{fontSize: "16px"}}></i></Button>
                  </span>
                </div>
              </div>
            }
            columns={columns} 
            data={tableData}
            //persistTableHead={true}
            expandableRows
            expandableRowsComponent={({ data }) => <RecursiveLinkedIssues formData={{
              ...formData,
              ...data,
              //grp: data.group.grp,
              status: data.status.name,
              issue_type: data?.issue_type?.cat,
              issuetsk: data?.parent_issue?.tsk
            }} taskSlug={data.tsk} selectedGrp={selectedGrp} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} handleLogout={handleLogout} refreshTable={refreshTable} />}
            responsive={true}
            pagination={tableData.length > 10 ? true : false}
            highlightOnHover
            customStyles={{
              table: {
                style: {
                  border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                  borderRadius: '16px 16px 10px 10px',
                },
              },
              headCells: {
                style: {
                  paddingLeft: '7px',
                  paddingRight: '7px',
                },
              },
              rows: {
                style: {
                  minHeight: '48px',
                  border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                  borderRadius: '10px',
                  marginTop: '10px',
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? '#ffffff' : '#000000',
                  backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                  outline: 'none'
                }
              }
            }}
            noDataComponent={<div className='text-xs'>There are no issues to display</div>}
          />
        </div>
      </div>
      {/* {addingIssue && <div>
        <div className="flex items-end gap-2 text-sm">
          <div className="w-1/4">
            <Select classNamePrefix="select" name="relation" placeholder="Relation" options={relationLookup} onChange={(option)=>  {setRelation(option)}} required  />
          </div>
          <div className="flex-1">
            {!selectedGrp.grp && <div className="text-red-500 text-sm">Select Group first</div>}
            {selectedGrp.grp && issList.length === 0 && <div className="text-red-500 text-sm">No Epics to select</div>}
            <div className='relative'>
              <button type="button" className='border rounded-lg py-2 px-4 z-10 relative w-full text-start' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => {setCollapseSelectLinkedIssue(!collapseSelectLinkedIssue); console.log(collapseSelectIssue);}}>
                {selectedLinkedIssue.tsk ? <div className='flex gap-2 items-center'>
                  <div className="select-icon">
                    <i className={selectedLinkedIssue.issue_type.attribute1+' icn'} style={{color:selectedLinkedIssue.issue_type.attribute2, backgroundColor: selectedLinkedIssue.issue_type.attribute2+'20', borderColor: selectedLinkedIssue.issue_type.attribute2+'90'}}></i>
                  </div>
                  <div className='organisation-heading line-clamp-1'>{selectedLinkedIssue.task_key +' ' +selectedLinkedIssue.name}</div>
                </div> : 'Select Linked Issue'}
              </button>
              {!collapseSelectLinkedIssue && <div className='organisationDropdownCard' style={{width: '100%', left: 0}}>
                <div className='pt-2'>
                  <Search issList={issList} setIssueList={setIssueList} />
                </div>
                <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                  {issueList.length > 0 && issueList.map((data)=>{
                    return(
                    <TaskDropdownCard data={data} selectedGrp={selectedGrp} setCollapseSelectIssue={setCollapseSelectLinkedIssue} selectedIssue={selectedLinkedIssue} setSelectedIssue={setSelectedLinkedIssue} setIssueList={setIssueList} issList={issList} setIssList={setIssList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
                    )
                  })}
                </div>
              </div>}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2">
          <button type="button" className="cancel-btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Cancel" onClick={() => setAddingIssue(false)}><i className="fa-solid fa-xmark"></i></button>
          <button type="button" className="btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Ok" onClick={handleAddLinkedIssue} disabled={Object.keys(relation).length === 0 || Object.keys(selectedLinkedIssue).length === 0}><i className="fa-solid fa-check"></i></button>
        </div>
      </div>} */}

      {showAddIssue && <div className='overlay'>
        <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-1/2 w-1/2'>
          <div className=' flex-grow overflow-y-auto '>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Add Linked Issue</div>
            </div>
    
            <div className='mt-4'>
              <div>
                <div className="flex items-end gap-2">
                  <div className="w-1/4">
                    <Select classNamePrefix="select" name="relation" placeholder="Relation" options={relationLookup} onChange={(option)=>  {setRelation(option)}} required  />
                  </div>
                  <div className="flex-1">
                    {!selectedGrp.grp && <div className="text-red-500 text-sm">Select Group first</div>}
                    {selectedGrp.grp && issList.length === 0 && <div className="text-red-500 text-sm">No Epics to select</div>}
                    <div className='relative'>
                      <button type="button" className='border rounded-lg py-2 px-4 z-10 relative w-full text-start' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => {setCollapseSelectLinkedIssue(!collapseSelectLinkedIssue);}}>
                        {selectedLinkedIssue.tsk ? <div className='flex gap-2 items-center'>
                          <div className="select-icon">
                            {selectedLinkedIssue.issue_type ? <span className='icn' style={{color:selectedLinkedIssue.issue_type.color, backgroundColor: selectedLinkedIssue.issue_type.color+'20', borderColor: selectedLinkedIssue.issue_type.color+'90'}}>{React.createElement(FaIcons[selectedLinkedIssue.issue_type?.icon])}</span> : <></>}
                          </div>
                          <div className='organisation-heading line-clamp-1'>{selectedLinkedIssue.task_key +' ' +selectedLinkedIssue.name}</div>
                        </div> : 'Select Linked Issue'}
                      </button>
                      {!collapseSelectLinkedIssue && <div className='organisationDropdownCard' style={{width: '100%', left: 0}}>
                        <div className='pt-2'>
                          <Search issList={issList} setIssueList={setIssueList} />
                        </div>
                        <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                          {issueList.length > 0 && issueList.map((data)=>{
                            return(
                            <TaskDropdownCard data={data} selectedGrp={selectedGrp} setCollapseSelectIssue={setCollapseSelectLinkedIssue} selectedIssue={selectedLinkedIssue} setSelectedIssue={setSelectedLinkedIssue} setIssueList={setIssueList} issList={issList} setIssList={setIssList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
                            )
                          })}
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* bottom buttom */}
          <div className="flex justify-end mt-2">
            <button type="button" className="cancel-btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Cancel" onClick={handleCloseAddModal}>Cancel</button>
            <button type="button" className="btn-popup px-4 py-2 rounded-lg text-xs text-medium" title="Ok" onClick={handleAddLinkedIssue} disabled={Object.keys(relation).length === 0 || Object.keys(selectedLinkedIssue).length === 0}>Save</button>
          </div>
        </div>
      </div>}

      {showEditIssue && <div className='overlay'>
        <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-1/2 w-1/2'>
          <div className=' flex-grow overflow-y-auto '>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Linked Issue</div>
            </div>
    
            <div className='mt-4'>
              <div>
                <div className="flex items-end gap-2">
                  <div className="w-1/4">
                    <Select defaultValue={editRelation} classNamePrefix="select" name="relation" placeholder="Relation" options={relationLookup} onChange={(option)=>  {setEditRelation(option)}} required  />
                  </div>
                  <div className="flex-1">
                    {!selectedGrp.grp && <div className="text-red-500 text-sm">Select Group first</div>}
                    {selectedGrp.grp && issList.length === 0 && <div className="text-red-500 text-sm">No Epics to select</div>}
                    <div className='relative'>
                      <button type="button" className='border rounded-lg py-2 px-4 z-10 relative w-full text-start' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => {setCollapseEditSelectLinkedIssue(!collapseEditSelectLinkedIssue);}}>
                        {editSelectedLinkedIssue.tsk ? <div className='flex gap-2 items-center'>
                          <div className="select-icon">
                            {editSelectedLinkedIssue.issue_type ? <span className='icn' style={{color:editSelectedLinkedIssue.issue_type.color, backgroundColor: editSelectedLinkedIssue.issue_type.color+'20', borderColor: editSelectedLinkedIssue.issue_type.color+'90'}}>{React.createElement(FaIcons[editSelectedLinkedIssue.issue_type?.icon])}</span> : <></>}
                          </div>
                          <div className='organisation-heading line-clamp-1'>{editSelectedLinkedIssue.task_key +' ' +editSelectedLinkedIssue.name}</div>
                        </div> : 'Select Linked Issue'}
                      </button>
                      {!collapseEditSelectLinkedIssue && <div className='organisationDropdownCard' style={{width: '100%', left: 0}}>
                        <div className='pt-2'>
                          <Search issList={issList} setIssueList={setIssueList} />
                        </div>
                        <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                          {issueList.length > 0 && issueList.map((data)=>{
                            return(
                            <TaskDropdownCard data={data} selectedGrp={selectedGrp} setCollapseSelectIssue={setCollapseEditSelectLinkedIssue} selectedIssue={editSelectedLinkedIssue} setSelectedIssue={setEditSelectedLinkedIssue} setIssueList={setIssueList} issList={issList} setIssList={setIssList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
                            )
                          })}
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* bottom buttom */}
          <div className='spacing-div'>
            <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium' onClick={handleCloseEditModal}>Cancel</button>
            <button type="button" className='btn-popup px-4 py-2 rounded-lg text-sm text-medium' onClick={handleEditIssue}>Save</button>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default RecursiveLinkedIssues;