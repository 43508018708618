import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { Context } from '../../contextApi/context.js';
import '../css/cat.css';
import serverConfig from '../../config/server.js';
import Search from '../common/search.js';
import Notification from '../../common/notification/Notification.js';

const AssignToModal = ({ setAssignToModal, rowToAssign }) => {
    const { selectedOrganization } = useContext(Context);
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
    const [assignTo, setAssignTo] = useState('');
    const [userList, setUserList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [showUserModal, setShowUserModal] = useState(false)
    const [assignedUserlist, setAssignedUserList] = useState([]);
    const [assignedGrouplist, setAssignedGroupList] = useState([]);
    const [assignedResourcelist, setAssignedResourceList] = useState([]);

    const [isAddGroupClicked, setIsAddGroupClicked] = useState(false);
    const [isAddMemberClicked, setIsAddMemberClicked] = useState(false);
    const [isAddResourceClicked, setIsAddResourceClicked] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);

    const [tab, setTab] = useState('Assign Resource');
    const [memberTab, setMemberTab] = useState(0);

    const modalRef = useRef(null);

    const getToken = () => {
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt })
            .then(response => {
                console.log('user_get_token response', response);
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setCToken(response.data.token);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log('error is', err));
    };

    const handleCloseAlertModal = () => {
        console.log("closeeee modal")
        setAlertMessage('');
        setShowOkButton(false);
        setShowAlertModal(false);
    };

    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
        console.log("rowToAssign", rowToAssign);
    }, []);

    useEffect(() => {
        console.log("Alerrttt", alertMessage);
        if (alertMessage !== '') {
            setShowAlertModal(true);
        } else {
            setShowAlertModal(false);
        }
    }, [alertMessage]);

    useEffect(() => {
        if (cToken && selectedOrganization?.slug) {
            axios.post(serverConfig.api_base_url + "user_get_list", { token: cToken, org: selectedOrganization?.slug })
                .then(response => {
                    console.log("user_get_list response:", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setUserList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));

            axios.post(serverConfig.api_base_url + "get_resource_list", { token: cToken, org: selectedOrganization?.slug })
                .then(response => {
                    console.log("get_resource_list response:", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setResourceList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));

            axios.post(serverConfig.api_base_url + "group_list_get", { token: cToken, org: selectedOrganization?.slug })
                .then(response => {
                    console.log("group_list_get response:", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        const modifiedGroupList = responseData.items.reduce((acc, item) => {
                            const mainGroup = { name: item.name, slug: item.grp };
                            acc.push(mainGroup);
                            if (item.count_details.subgroups_name) {

                                for (let i = 0; i < item.count_details.subgroups_name.length; i++) {
                                    const subgroup = {
                                        name: item.count_details.subgroups_name[i],
                                        slug: item.count_details.subgroups_slug[i]
                                    };
                                    acc.push(subgroup);
                                }
                            }
                            return acc;
                        }, []);
                        setGroupList(modifiedGroupList);

                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));



            axios.post(serverConfig.api_base_url + "get_resource_group_assignment", { token: cToken, resource_id: rowToAssign.resource_id })
                .then(response => {
                    console.log("get_resource_group_assignment:", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setAssignedGroupList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));
            const filteredGroupList = groupList.filter(group => !assignedGrouplist.some(assignedGroup => assignedGroup.slug === group.slug));
            setUserList(filteredGroupList);

            axios.post(serverConfig.api_base_url + "get_resource_resource_assignment", { token: cToken, resource_id: rowToAssign.resource_id })
                .then(response => {
                    console.log("get_resource_resource_assignment", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setAssignedResourceList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));
            const filteredResourceList = resourceList.filter(resource => !assignedResourcelist.some(assignedResource => assignedResource.slug === resource.slug));
            setUserList(filteredResourceList);

            axios.post(serverConfig.api_base_url + "get_resource_user_assignment", { token: cToken, resource_id: rowToAssign.resource_id })
                .then(response => {
                    console.log("get_resource_user_assignment", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setAssignedUserList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));
            const filteredUserList = userList.filter(user => !assignedUserlist.some(assignedUser => assignedUser.slug === user.slug));
            setUserList(filteredUserList);


        }


    }, [cToken, selectedOrganization]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowGroupModal(false);
                setShowUserModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setIsAddGroupClicked(false);
        setIsAddMemberClicked(false);
        setIsAddResourceClicked(false);
    }, [memberTab]);

    const handleAddUser = (userslug) => {
        axios.post(serverConfig.api_base_url + 'resource_user_assignment_add', {
            token: cToken,
            resource_id: rowToAssign.resource_id,
            user: userslug
        }).then(response => {
            console.log('Resource assigned to user:', response);
            setAlertMessage('Resource assigned to user');
            setShowOkButton(true);
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
            axios.post(serverConfig.api_base_url + "get_resource_user_assignment", { token: cToken, resource_id: rowToAssign.resource_id })
                .then(response => {
                    console.log("get_resource_user_assignment", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setAssignedUserList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                        //setAlertMessage('Invalid response format or missing data');
                    }
                })
                .catch(err => console.log("error is", err));
            
        }).catch(error => {
            console.error('Error assigning resource to user:', error);
            setAlertMessage('Error assigning resource to user');
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);

        });
        setAssignTo('User');
        setShowUserModal(true);
        setShowGroupModal(false);
    };

    const handleAddGroup = (groupslug) => {
        axios.post(serverConfig.api_base_url + 'resource_group_assignment_add', {
            token: cToken,
            resource_id: rowToAssign.resource_id,
            group_slug: groupslug,
        }).then(response => {
            console.log('Resource assigned to group:', response);
            setAlertMessage('Resource assigned to group');
            setShowOkButton(true);
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
            axios.post(serverConfig.api_base_url + "get_resource_group_assignment", { token: cToken, resource_id: rowToAssign.resource_id })
                .then(response => {
                    console.log("get_resource_group_assignment:", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setAssignedGroupList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));
            //setAssignedGroupList(prevList => [...prevList, response.data]); 
            //setGroupList(prevList => prevList.filter(group => group.slug !== groupslug));

        }).catch(error => {
            console.error('Error assigning resource to group:', error);
            setAlertMessage('Error assigning resource to group');
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);

        });
        setAssignTo('Group');
        setShowGroupModal(true);
        setShowUserModal(false);
    };

    const handleAddResource = (resourceid) => {
        axios.post(serverConfig.api_base_url + 'resource_resource_assignment_add', {
            token: cToken,
            resource_id: resourceid,
            resource_assigned_to: rowToAssign.resource_id,
        }).then(response => {
            console.log('Resource assigned to resource:', response);
            setAlertMessage('Resource assigned to another resource');
            setShowOkButton(true);
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
            axios.post(serverConfig.api_base_url + "get_resource_resource_assignment", { token: cToken, resource_id: rowToAssign.resource_id })
                .then(response => {
                    console.log("get_resource_resource_assignment", response);
                    const responseData = response.data;
                    if (responseData && responseData.items) {
                        setAssignedResourceList(responseData.items);
                    } else {
                        console.error("Invalid response format or missing data:", response);
                    }
                })
                .catch(err => console.log("error is", err));
            //setAssignedResourceList(prevList => [...prevList, response.data]); 

        }).catch(error => {
            console.error('Error assigning resource to resource:', error);
            setAlertMessage('Error assigning resource to resource');
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
        });
        setAssignTo('Resource');
        setShowGroupModal(false);
        setShowUserModal(false);
    };

    const handleRemoveUser = (userslug) => {
        axios.post(serverConfig.api_base_url + 'resource_user_assignment_delete', {
            token: cToken,
            resource_id: rowToAssign.resource_id,
            usr: userslug,
        }).then(response => {
            console.log('User deleted from resource:', response);
            setAlertMessage('User deleted from resource');
            setShowOkButton(true);
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
            setAssignedUserList(prevList => prevList.filter(user => user.slug !== userslug));
            //fetchData(); 
        }).catch(error => {
            console.error('Error deleting user from resource:', error);
            setAlertMessage('Error deleting user from resource');
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
        });
    };

    const handleRemoveGroup = (groupslug) => {
        axios.post(serverConfig.api_base_url + 'resource_group_assignment_delete', {
            token: cToken,
            resource_id: rowToAssign.resource_id,
            grp: groupslug,
        }).then(response => {
            console.log('Group deleted from resource:', response);
            setAssignedGroupList(prevList => prevList.filter(group => group.slug !== groupslug));
            setAlertMessage('Group deleted from resource');
            setShowOkButton(true);
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
            //fetchData();
        }).catch(error => {
            console.error('Error deleting group from resource:', error);
            setAlertMessage('Error deleting group from resource');
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
        });
    };

    const handleRemoveResource = (resourceslug) => {
        axios.post(serverConfig.api_base_url + 'resource_resource_assignment_delete', {
            token: cToken,
            resource_id: rowToAssign.resource_id,
            res: resourceslug,
        }).then(response => {
            console.log('Resource deleted from resource:', response);
            setAlertMessage('Resource deleted from resource');
            setShowOkButton(true);
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
            setAssignedResourceList(prevList => prevList.filter(res => res.slug !== resourceslug));
            //fetchData(); 
        }).catch(error => {
            console.error('Error deleting resource from resource:', error);
            setAlertMessage('Error deleting resource from resource');
            setTimeout(() => {
                handleCloseAlertModal();
            }, 6000);
        });
    };


    return (
        <div className='edit-res rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'>
            <div className="flex space-x-4">
                <label className="text-lg">Assign To</label>
            </div>
            <div className='flex-grow'>

                <div className="flex space-x-4">
                    <button className={`px-6 py-3 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Assign Resource'); setMemberTab(0); }}>Member</button>
                    <button className={`px-6 py-3 cursor-pointer ${memberTab === 1 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Assign Resource'); setMemberTab(1); }}>Group</button>
                    <button className={`px-6 py-3 cursor-pointer ${memberTab === 2 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Assign Resource'); setMemberTab(2); }}>Resource</button>
                </div>
                <hr className="my-3" />

                {memberTab === 0 && userList.length > 0 && (
                    <div className='flex-auto flex flex-col'>
                        <div className="flex items-center justify-between my-4 relative">
                            <div>{assignedUserlist.length} Members</div>
                            <div>
                                <button
                                    type="button"
                                    className='border rounded-lg py-2 px-4'
                                    onClick={() => setIsAddMemberClicked(!isAddMemberClicked)}
                                >
                                    <b>Add Member</b>
                                </button>
                                {isAddMemberClicked && (
                                    <div className='organisationDropdownCard' style={{ width: '100%', left: 0 }}>
                                        <div className='px-2 mt-4 w-full'><Search list={userList} setList={setUserList} /></div>
                                        <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{ borderBottom: 'none' }}>
                                            {userList.length > 0 && <ul className='px-1'>
                                                {userList.map((data, index) => {
                                                    return (<li key={index} className={index === userList.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => assignedUserlist.length > 0 && assignedUserlist.find(el => el.slug === data.slug) ? handleRemoveUser(data.slug) : handleAddUser(data.slug)} title={assignedUserlist.length > 0 && assignedUserlist.find(el => el.slug === data.slug) ? `Remove ${data.name}` : `Add ${data.name}`}>
                                                        <div>{<img src={`https://ui-avatars.com/api/?name=${data.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt='User Image' className='rounded-full' width="40" />}</div>
                                                        <div className="ml-4">{data.name}</div>
                                                        {assignedUserlist.length > 0 && assignedUserlist.find(el => el.slug === data.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
                                                    </li>)
                                                })}
                                            </ul>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex-auto">
                            {assignedUserlist.length > 0 && (
                                <ul className='overflow-y-auto px-1'>
                                    {assignedUserlist.map((data, index) => (
                                        <li key={index} className={index === assignedUserlist.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} title={`Remove ${data.name}`}>
                                            <div>{!data.icon ? <img src={`https://ui-avatars.com/api/?name=${data?.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt='User Image' className='rounded-full' width="40" /> : <img src={data.icon} alt='User Image' className='rounded-full' width="40" />}</div>
                                            <div className="ml-4">{data.name}</div>
                                            <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400" onClick={() => handleRemoveUser(data.slug)}></i></span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                )}

                {memberTab === 1 && groupList.length > 0 && (
                    <div className='flex-auto flex flex-col'>
                        <div className="flex items-center justify-between my-4 relative">
                            <div>{assignedGrouplist.length} Groups</div>
                            <div>
                                <button
                                    type="button"
                                    className='border rounded-lg py-2 px-4'
                                    onClick={() => setIsAddGroupClicked(!isAddGroupClicked)}
                                >
                                    <b>Add Group</b>
                                </button>
                                {isAddGroupClicked && (
                                    <div className='organisationDropdownCard' style={{ width: '100%', left: 0 }}>
                                        <div className='px-2 mt-4 w-full'><Search list={groupList} setList={setGroupList} /></div>
                                        <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{ borderBottom: 'none' }}>
                                            {groupList.length > 0 && <ul className='px-1'>
                                                {groupList.map((data, index) => {
                                                    return (<li key={index} className={index === groupList.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => assignedGrouplist.length > 0 && assignedGrouplist.find(el => el.slug === data.slug) ? handleRemoveGroup(data.slug) : handleAddGroup(data.slug)} title={assignedGrouplist.length > 0 && assignedGrouplist.find(el => el.slug === data.slug) ? `Remove ${data.name}` : `Add ${data.name}`}>
                                                        <div>{<img src={`https://ui-avatars.com/api/?name=${data.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt='User Image' className='rounded-full' width="40" />}</div>
                                                        <div className="ml-4">{data.name}</div>
                                                        {assignedGrouplist.length > 0 && assignedGrouplist.find(el => el.slug === data.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
                                                    </li>)
                                                })}
                                            </ul>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex-auto">
                            {assignedGrouplist.length > 0 && (
                                <ul className='overflow-y-auto px-1'>
                                    {assignedGrouplist.map((data, index) => (
                                        <li key={index} className={index === assignedGrouplist.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} title={`Remove ${data.name}`}>
                                            <div>{<img src={`https://ui-avatars.com/api/?name=${data?.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt='User Image' className='rounded-full' width="40" />}</div>
                                            <div className="ml-4">{data.name}</div>
                                            <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400" onClick={() => handleRemoveGroup(data.slug)}></i></span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                )}

                {memberTab === 2 && (
                    <div className='flex-auto flex flex-col'>
                        <div className="flex items-center justify-between my-4 relative">
                            <div>{assignedResourcelist.length} Resources</div>
                            <div>
                                <button
                                    type="button"
                                    className='border rounded-lg py-2 px-4'
                                    onClick={() => setIsAddResourceClicked(!isAddResourceClicked)}
                                >
                                    <b>Add Resource</b>
                                </button>
                                {isAddResourceClicked && (
                                    <div className='organisationDropdownCard' style={{ width: '100%', left: 0 }}>
                                        <div className='px-2 mt-4 w-full'><Search list={resourceList} setList={setResourceList} /></div>
                                        <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{ borderBottom: 'none' }}>
                                            {resourceList.length > 0 && <ul className='px-1'>
                                                {resourceList.map((data, index) => {
                                                    return (<li key={index} className={index === resourceList.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => assignedResourcelist.length > 0 && assignedResourcelist.find(el => el.slug === data.slug) ? handleRemoveResource(data.slug) : handleAddResource(data.id)} title={assignedResourcelist.length > 0 && assignedResourcelist.find(el => el.slug === data.slug) ? `Remove ${data.name}` : `Add ${data.name}`}>
                                                        <div>{<img src={`https://ui-avatars.com/api/?name=${data.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt='User Image' className='rounded-full' width="40" />}</div>
                                                        <div className="ml-4">{data.name}</div>
                                                        {assignedResourcelist.length > 0 && assignedResourcelist.find(el => el.slug === data.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
                                                    </li>)
                                                })}
                                            </ul>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex-auto">
                            {assignedResourcelist.length > 0 && (
                                <ul className='overflow-y-auto px-1'>
                                    {assignedResourcelist.map((data, index) => (
                                        <li key={index} className={index === assignedResourcelist.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} title={`Remove ${data.name}`}>
                                            <div>{<img src={`https://ui-avatars.com/api/?name=${data?.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt='User Image' className='rounded-full' width="40" />}</div>
                                            <div className="ml-4">{data.name}</div>
                                            <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400" onClick={() => handleRemoveResource(data.slug)}></i></span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                )}

            </div>

            <div className='mt-4 flex justify-end'>
                <button
                    type="button"
                    className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                    onClick={() => setAssignToModal(false)}
                >
                    Cancel
                </button>
            </div>
            <div className='mt-4 flex justify-end'>
                {showAlertModal && (
                    <Notification
                        message={alertMessage}
                        notificationType={showOkButton ? 'Success' : 'Error'}
                    />
                )}
            </div>
        </div>
    );

};

export default AssignToModal;
