import React from "react";
import UserService from "../../services/UserService.js";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../contextApi/context.js";
import "../css/okr.css";
import { useState, useEffect } from "react";
import Notification from "../../common/notification/Notification.js";
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ProgressBar from "../components/ProgressBarUpdate.js";

const serverConfig = require("../../config/server.js");

const ChecklistTable = ({kyrSlug}) => {

    const {selectedOrganization} = useContext(Context);
    const [token, setToken] = useState(null);
 
    const [refreshKRList, setRefreshKRList] = useState(false);
    const [checklist, setChecklist] = useState([]);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);
    const handleCloseAlertModal = () => {
      setAlertMessage("");
      setShowOkButton(false);
      setAlertModalShow(false);
    }
    const handleShowAlertModal = (alert_msg, show_ok_button) => {
      setAlertMessage(alert_msg);
      setShowOkButton(show_ok_button);
      setAlertModalShow(true);
    }

    const getToken = () => {
        console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
        .then(response=>{
          console.log('user_get_token response', response);
          if(response.data.status === 200){
            sessionStorage.setItem('token', response.data.token);
            setToken(response.data.token);
          } else if(response.data.status >= 400 && response.data.status <= 499){
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          } else if(response.data.jwt_validation === 'fail') {
            handleLogout();
          }
        })
        .catch(err=>console.log("error is",err));
      }
    
      useEffect(() => {
        console.log(sessionStorage.getItem('token'));
        if(sessionStorage.getItem('token') === null){
          getToken();
        } else {
          setToken(sessionStorage.getItem('token'));
        }
      }, []);

      useEffect(()=> {
        if(token !== null && token !== undefined) {
          keyResultCheckListGet();
        }
      }, [token])

      const keyResultCheckListGet = () => {
        axios.post(serverConfig.api_base_url + "okr_key_result_checklist_list_get", {
            token: token,
            org: selectedOrganization?.slug,
            kyr: kyrSlug
        }).then(response=>{
            if(response.data.status === 200) {
            console.log(response.data.items);
            setChecklist(response.data.items);
            }
            else if(response.data.status === 301){
              handleLogout();
            } else {
              handleShowAlertModal(response.data.message, true);
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
          }).catch(err=> {
            console.log(err);
        })
      }


    return (
        <>
        <div className="overflow-x-auto ps-14 ms-2">
      <div className="table-map-okr table-dbs-sub m-8" style={{marginLeft: "-20px"}}>

      <div className="flex space-x-4">
                <h4 className='font-bold text-base mb-4'>Checklists</h4>
              </div>

      {checklist?.length > 0 && checklist?.map((data, index) => {
          let todayDate=new Date().toISOString().split('T')[0];
          return(
            <div key={index} className="flex items-start justify-between gap-5 sidebar-data-todo m-1 py-3 px-2 relative">
              <div className='flex justify-start gap-2 items-start'>
                {/* slelect/unselect toggle button÷ */}
                <div className="text-xs font-semibold mt-1
                ">
                  #{data?.checklist_id}
                </div>
                {/* slelect/unselect toggle button÷ */}

                {/* description of the task */}
                <div className={`ml-2 ${data.status === 'Closed' ? 'flex gap-1 flex-grow line-through sidebar-txt ' : 'flex gap-1 flex-grow sidebar-txt '}`}>
                  <b>{data.name}:</b> 
                  <>{data.description}
                  {/* <button type='button' onClick={() => {}}><i className="fa-solid fa-pencil text-xs icon-color mx-1"></i></button> */}
                  </>
                </div>
                {/* description of the task */}
              </div>

              <div className='flex items-center justify-end gap-2'>
                {/* display date selected start */}
                <div className={data.due_date === 'Today' ? 'dueto-today relative' : (data.due_date < todayDate ? 'dueto-past relative' : "sidebar-txt relative")}>
                  <div className='table-text-dark'>
                    {data.due_date && data.due_date === 'Today' ? 'Today' : (<span>
                   {data.due_date}
                    </span>)} {(data.due_date === 'Today' || data.due_date < todayDate) && <i className="fa-solid fa-triangle-exclamation ml-1"></i>}
                  
                  </div>
                </div>
           
                <p className='sidebar-txt'>
                {data?.assignee !== null && data?.assignee !== undefined && <div className='flex assignee-list'>
                    <div className='flex assignee-list'>
                     
                      <div> <img src={`https://ui-avatars.com/api/?name=${data?.assignee} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt={data?.asignee} className='rounded-full' width="20" /></div>
                    
                    </div>
                  </div> }
                </p> 

                {/* <button
                  type="button"
                  className="text-blue-500"
                  onClick={() => {}}
                ><i className="fa-solid fa-user-plus text-custom text-xs"></i></button>
                 */}
              </div>

             
            </div>
          )
        })}

        {alertModalShow && <>
                  {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
          </>}
        
      </div>
    </div>
        </>
    )
};

export default ChecklistTable;