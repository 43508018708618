import React, { useState } from "react";
import './../css/slider.css';
import { useEffect } from "react";


const ProgressBar = ({ progress_value, isDraggable, formData, setFormData }) => {

  const [progress, setProgress] = useState(progress_value === null ? 0 : progress_value);

  useEffect(() => {
    setProgress(progress_value);
    console.log(progress_value, 'progress')
  }, [progress_value]);

  useEffect(() => {

    document.getElementById("myinput").oninput = function () {

      var value = (progress - 0) / (100 - 0) * 100;
      console.log(value, 'dragging value')
      this.style.background = 'linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) ' + value + '%, #fff ' + value + '%, white 100%)'
    };

  }, []);

  const handleChange = (e) => {
    document.getElementById("myinput").oninput = function () {
      var value = (this.value - this.min) / (this.max - this.min) * 100;
      console.log(value, 'dragging value')
      this.style.background = 'linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) ' + value + '%, #fff ' + value + '%, white 100%)'
    };
    console.log(e.target.value);
    setProgress(e.target.value);
    setFormData({ ...formData, progress_percentage: e.target.value })
  }

  return (
    <>
      <div class="slidecontainer">
        <div className="progress-value">{progress}%/100%</div>
        <input type="range" id="myinput" className="custom-input-range" min="0" max="100" value={progress} onChange={handleChange} style={isDraggable ? {pointerEvents : "all", background: `linear-gradient(to right, var(--secondary-200) 0%, var(--secondary-200) ${progress}%, rgb(255, 255, 255) ${progress}%, white 100%)`} :{pointerEvents : "none", background: `linear-gradient(to right, var(--secondary-200) 0%, var(--secondary-200) ${progress}%, rgb(255, 255, 255) ${progress}%, white 100%)`}} />
      </div>
    </>
  )
};

export default ProgressBar;