import React from 'react'
import { useEffect,useState, useContext } from 'react';
import axios from 'axios';
import { Context } from '../../contextApi/context.js';
import Notification from '../../common/notification/Notification.js';
import './../css/lead.css';

const serverConfig = require("../../config/server.js");
 
const LeadCaptureForm = () =>{

    const {selectedOrganization, isDarkTheme} = useContext(Context);
    const [formData, setFormData] = useState({});
    const [token, setToken] = useState('');
    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);
    const handleCloseAlertModal = () => {
        setAlertMessage("");
        setShowOkButton(false);
        setAlertModalShow(false);
    }

    const handleShowAlertModal = (alert_msg, show_ok_button) => {
        setAlertMessage(alert_msg);
        setShowOkButton(show_ok_button);
        setAlertModalShow(true);
    }

    const [errors, setErrors] = useState({ email: '', phoneNumber: '' });

    const handleValidationEmail = () => {
      let valid = true;
      let errors = {};
  
      if (!validateEmail(formData.email_id)) {
        errors.email = 'Invalid email format';
        valid = false;
      }
  
      setErrors(errors);
      return valid;
    };

    const handleValidationNumber = () => {
        let valid = true;
        let errors = {};
    
        if (!validatePhoneNumber(formData.mobile_no)) {
          errors.phoneNumber = 'Invalid phone number (must be 10 digits)';
          valid = false;
        }
    
        setErrors(errors);
        return valid;
    };

    const getToken = () => {
        console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
        .then(response=>{
          console.log('user_get_token response', response);
          if(response.data.status === 200){
            sessionStorage.setItem('token', response.data.token);
            setToken(response.data.token);
          } else if(response.data.status >= 400 && response.data.status <= 499){
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          } else if(response.data.jwt_validation === 'fail') {
            handleLogout();
          }
        })
        .catch(err=>console.log("error is",err));
      }
    
      useEffect(() => {
        console.log(sessionStorage.getItem('token'));
        if(sessionStorage.getItem('token') === null){
          getToken();
        } else {
          setToken(sessionStorage.getItem('token'));
        }
      }, []);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\+?[1-9]\d{1,14}$/; // For 10-digit numbers (adjust regex for international formats)
        return phoneRegex.test(phoneNumber);
    };

    const leadCapture = () => {
        if(handleValidationEmail() && handleValidationNumber())
        {
        axios.post(serverConfig.api_base_url + "crm_lead_update", {
            token: token,
            org: selectedOrganization?.slug,
            name: formData?.name,
            email_id: formData?.email_id,
            designation: formData?.designation,
            organization_name: formData?.organization_name,
            mobile_no: formData?.mobile_no,
            notes: formData?.notes
        }).then(response=> {
            console.log(response.data.status);
            if(response.data.status === 200)
            {
                setFormData({});
                handleShowAlertModal('Thanks for your response.');
                setTimeout(() => {
                handleCloseAlertModal();
                }, 6000);
            }
            else 
            {
                handleShowAlertModal(response.data.message, true);
                setTimeout(() => {
                  handleCloseAlertModal();
                }, 6000);
            }
        
        }
    ).catch(err=> {
            console.log(err);
        })
    }
    }

    return (
        <>
        <div className="overflow-x-auto">
        <div className="p-3 table-custom table-dbs table-mapmetadata mt-4 ">
        <div>
          <h1 className='font-semibold text-2xl theader'>Contact Information</h1>
          </div>
          {/* <div className='flex flex col'> */}
          <div className='flex flex-col'>
        <div className="flex items-center justify-between space-x-6 text-black mb-4 mt-2">


            <div className='flex flex-row'>
          <div className='flex flex-col mt-6 ml-16'>

                    <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Full Name</label>
                  <input type="text" 
                  value={formData?.name || ""} 
                  onChange={(e)=>  
                  setFormData({ ...formData, name: e.target.value})
                  } placeholder='Full Name' 
                  style={{width: "600px !important"}}
                  className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>

                <div className='flex flex-col gap-1 mt-4 text-xs'>
                  <label>Company Name</label>
                  <input type="text" 
                  value={formData?.organization_name  || ""} 
                  onChange={(e)=>  
                    setFormData({ ...formData, organization_name: e.target.value})
                    } 
                  style={{width: "600px !important"}}
                  placeholder='Company Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>

                <div className='flex flex-col gap-1 mt-4 text-xs'>
                  <label>Job Title</label>
                  <input type="tel" 
                  value={formData?.designation  || ""} 
                  onChange={(e)=>  setFormData({ ...formData, designation : e.target.value})} 
                  style={{width: "600px !important"}}
                  placeholder='Job Title' className='custom-input py-2 px-2 text-left mt-1'/>
                </div>
                </div>


                <div className='flex flex-col mt-6 ml-16'>

                    <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Email Address</label>
                  <input type="text" 
                  value={formData?.email_id  || ""} 
                  onChange={(e)=>
                    {  
                        setFormData({ ...formData, email_id: e.target.value})
                        handleValidationEmail();
                    }
                  } placeholder='Email Address' style={{width: "600px !important"}} className='custom-input py-2 px-2 text-left mt-1' required/>
                  {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                </div>

                <div className='flex flex-col gap-1 mt-4 text-xs'>
                  <label>Phone Number</label>
                  <input type="number" 
                  value={formData?.mobile_no  || ""}  
                  onChange={(e)=> 
                    { 
                        setFormData({ ...formData, mobile_no: e.target.value})
                        handleValidationNumber();
                    }
                    }
                  style={{width: "600px !important"}}
                  placeholder='Phone Number' styles={{fontWeight: "400 !important"}} className='custom-input py-2 px-2 font-normal text-left mt-1' required/>
                  {errors.phoneNumber && (
          <p style={{ color: 'red' }}>{errors.phoneNumber}</p>
        )}
                </div>

                <div className='flex flex-col gap-1 mt-4 text-xs'>
                  <label>Any other details/specific requirements</label>
                  <textarea type="tel" 
                  value={formData?.notes  || ""} 
                  onChange={(e)=>  setFormData({ ...formData, notes : e.target.value})} 
                  style={{width: "600px !important"}}
                  placeholder='Requirements' className='custom-input py-2 px-2 text-left mt-1'/>
                </div>
                </div>


                
                {/* </div> */}

                </div>

                
          </div>
          <div className='flex flex-row justify-start mt-16'>
              <button type="submit" style={{marginLeft:"60px"}} className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={leadCapture}>Submit</button>
              </div>
          </div>
              {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
  </>}
            </div>
            </div>
        </>
    )
}

export default LeadCaptureForm