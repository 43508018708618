import React, { useState } from "react";
import './../css/slider.css';
import { useEffect } from "react";


const ProgressbarReadOnly = ({ progress_value }) => {

  return (
    <>
      <div class="slidecontainer">
        <div className="progress-value">{progress_value}%/100%</div>
        <input type="range" className="custom-input-range" min="0" max="100" value={progress_value} onChange={() => { }} style={{background: `linear-gradient(to right, var(--secondary-200) 0%, var(--secondary-200) ${progress_value}%, rgb(255, 255, 255) ${progress_value}%, white 100%)`}}></input>
      </div>
    </>
  )
};

export default ProgressbarReadOnly;