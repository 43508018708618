import React, {useContext} from "react";
import { Context } from "../../contextApi/context";
import ScrumReportTable from "./tables/ScrumReportDetailsTable";

const ScrumReportDetails = () => {
  const {collapse} = useContext(Context);

  return (<>
    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
        <div className="container-fluid metadata">
            <ScrumReportTable/>
        </div>
      </div>
    </div>
  </>);
}

export default ScrumReportDetails;