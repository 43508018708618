// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-field {
    flex: 1 1;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #E3E9F4;
    border-radius: 12px;
    width: 600px !important;
}

.custom-input {
    font-weight: 400 !important;
}`, "",{"version":3,"sources":["webpack://./src/kz-crm/css/lead.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".custom-input-field {\n    flex: 1 1;\n    padding: 10px;\n    box-sizing: border-box;\n    border: 2px solid #E3E9F4;\n    border-radius: 12px;\n    width: 600px !important;\n}\n\n.custom-input {\n    font-weight: 400 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
