import React from 'react';
import { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contextApi/context';
import NewSubgroupPopup from '../groupPopup/NewSubgroupPopup';
import EditSubgroupPopup from '../groupPopup/EditSubgroupPopup.js';
import TableMembers from './TableMembers.js';
import TableTask from './TableTask.js';
import IssueStatus from './IssueStatus.js';
import IssueCategory from './IssueCategory.js';
import IssueRelations from '../components/IssueRelations.js';
import axios from 'axios';
import * as FaIcons from 'react-icons/fa';
import "../css/style.css";

const serverConfig = require("./../../config/server.js");

const TableSprint = ({token, selectedOrganization, handleLogout, newSubgroupPopup, setPopup, groupDetails, handleCloseAlertModal, handleShowAlertModal, isPlanner, isSprint}) => {

  const {isDarkTheme} = useContext(Context);
  const navigate = useNavigate();

  console.log(groupDetails);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  //const { tab, setTab } = useContext(Context);
  const [tab, setTab] = useState(groupDetails?.currentTab ? groupDetails?.currentTab : "Tasks");
  const [addGroup, setAddGroup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [viewPrivilege, setViewPrivilege] = useState(false);
  const [refreshGroup, setRefreshGroup] = useState(false);
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [popupCurrentTab, setPopupCurrentTab] = useState("");

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null && groupDetails?.grp !== null && groupDetails?.grp !== undefined){
      setIsLoading(true);
      console.log(groupDetails?.currentTab);
      setTab(groupDetails?.currentTab ? groupDetails?.currentTab : "Tasks");

      console.log('selectedOrganization', selectedOrganization, groupDetails?.grp)

      axios.post(serverConfig.api_base_url + "group_list_get",{token:token, org:selectedOrganization?.slug, grp:groupDetails.grp})
      .then(response=>{
        console.log('group_list_get response', response);
        if(response.data.status === 200){
          setViewPrivilege(true);
          if(response.data.items !== null){
            console.log(response.data.items);
            setTableData(response.data.items);
          } else {
            setTableData([]);
          }
          setIsLoading(false);
        } else if(response.data.status === 301){
          handleLogout();
        } else if(response.data.status === 401){
          setViewPrivilege(false);
          setIsLoading(false);
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));

      /* user_get_list */
      axios.post(serverConfig.api_base_url + "user_get_list",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("user_get_list response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(items);
            setUserList(items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization, groupDetails, refreshGroup]);

  useEffect(() => {
    if(newSubgroupPopup) {
      setAddGroup(true);
    }
  }, [newSubgroupPopup]);

  useEffect(() => {
    console.log(addGroup);
    console.log(newSubgroupPopup);
    if(addGroup) {
      //setAddGroup(false);
    } else {
      setAddGroup(false);
      setPopup(false);
    }
  }, [addGroup]);

  const refreshGroupTable = () => {
    setRefreshGroup(!refreshGroup);
  }

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
  
    // Get the bounding rectangle of the more-options-wrapper and the container
    const moreOptionsWrapper = event.target.closest('.more-options-wrapper');
    const moreOptionsRect = moreOptionsWrapper.getBoundingClientRect();
    const containerRect = moreOptionsWrapper.closest('.table-custom').getBoundingClientRect();
  
    // Check if the bottom of the more-options-wrapper is near the bottom of the container
    const isNearBottom = moreOptionsRect.bottom + 100 > containerRect.bottom;
  
    // Set the position of the more-options menu
    if (isNearBottom) {
      x.style.bottom = '100%'; // Open upwards
      x.style.top = 'auto';
    } else {
      x.style.top = '100%'; // Open downwards
      x.style.bottom = 'auto';
    }
  
    // Toggle visibility of the more-options menu
    if (x.style.display === "none" || x.style.display === '') {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }  

  //Edit
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const handleShowEditGroupModal = (details) => {
    setFormData(details);
    setShowEditGroupModal(true);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const [newTaskPopup, setNewTaskPopup] = useState(false);

  return (
    <div className="">
      {viewPrivilege && <div className="rounded-3xl p-3 table-custom mt-4 relative flex flex-col">
        {isLoading && <div className='loading absolute'><i className="fa-solid fa-spinner fa-spin-pulse"></i></div>}
        {/* Left side: Three tabs flexed together */}
        <div className="flex space-x-4 z-0">
          {/* <div className={`px-6 py-3 cursor-pointer ${tab === "Finances" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Finances")}>Finances</div> */}
          <div className={`px-6 py-3 cursor-pointer ${tab === "Settings" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Settings")}>Settings</div>
          <div className={`px-6 py-3 cursor-pointer ${tab === "Members" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Members")}>Members</div>
          {/* <div className={`px-6 py-3 cursor-pointer ${tab === "Resources" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Resources")}>Resources</div> */}
          <div className={`px-6 py-3 cursor-pointer ${tab === "Tasks" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Tasks")}>Tasks</div>
        </div>
          
        {(tab === "Finances" || tab === "Resources") && <div className='flex flex-col items-center justify-center flex-auto'>
          <img src={require('./../../Asset/Images/comingsoon.jpg')} width="400" className='under-dev-img' style={{width:"400px", height:"auto"}} />
          <h3 class="under-dev"><b>This feature is under development.</b></h3>
        </div>}

        {tab === "Members" &&
          <TableMembers token={token} handleLogout={handleLogout} selectedOrganization={selectedOrganization} grp={groupDetails?.grp} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleShowEditGroupModal={() => {handleShowEditGroupModal(groupDetails); setPopupCurrentTab("Members")}} isPlanner={isPlanner} />
        }

        {tab === "Tasks" &&
          <div><TableTask token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} newTaskPopup={newTaskPopup} setPopup={setNewTaskPopup} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} grp={groupDetails?.grp} groupDetails={groupDetails} isPlanner={isPlanner} isSprint={isSprint} /></div>
        }

        {tab === "Settings" &&
          <div className='flex flex-col table-container'>

            <IssueStatus token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupDetails?.grp} groupDetails={groupDetails} isPlanner={isPlanner} />

            <IssueCategory token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupDetails?.grp} groupDetails={groupDetails} isPlanner={isPlanner} />

            {/* <IssueRelations token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupDetails?.grp} groupDetails={groupDetails} /> */}

          </div>
        }

        {addGroup === true ? <div className='overlay'><NewSubgroupPopup setNewGroupPopup={setAddGroup} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} refreshGroupTable={refreshGroupTable} groupDetails={groupDetails} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} userList={userList} isPlanner={isPlanner} /></div> : <></>}
      </div>}

      {showEditGroupModal ? <div className='overlay'><EditSubgroupPopup setEditGroupPopup={setShowEditGroupModal} details={formData} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} refreshGroupTable={refreshGroupTable} groupDetails={groupDetails} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} userList={userList} popupCurrentTab={popupCurrentTab} isPlanner={isPlanner} /></div> : <></>}
    </div>
  );
}

export default TableSprint;