import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import UserService from '../../services/UserService';
import "../css/mapmetadata.css";
import DwhTable from './DwhTable.js';
import Notification from '../../common/notification/Notification.js';

const serverConfig = require("../../config/server.js");

const Schema = ({dbsSlug}) => {
  const {selectedOrganization} = useContext(Context);
  const {isDarkTheme} = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshSchemaList, setRefreshSchemaList] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);
      /* dwh_schema_list_get */
      axios.post(serverConfig.api_base_url + "dwh_schema_list_get",{token:token, dbs:dbsSlug})
      .then(response=>{
        console.log("dwh_schema_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(response.data.items);
            let appDataArray = response.data.items;
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              let noOfActions = Number(item.is_editable) + Number(item.is_deletable) + Number(item.is_validated === 0 ? 1 : 0) + Number(item.is_validated);
              const Actions = () => <>
                {item.is_editable === 1 && <Button title={noOfActions > 2 ? '' : 'Edit'} variant='text' onClick={() => handleShowEditSchemaModal(item)}><i className="fa-solid fa-pencil not-0" aria-hidden="true"></i> {noOfActions > 2 && <span>Edit</span>}</Button>}
                {item.is_deletable === 1 && <Button title={noOfActions > 2 ? '' : 'Delete'} variant='text' onClick={() => handleShowDeleteSchemaModal(item?.name, item?.schema)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> {noOfActions > 2 && <span>Delete</span>}</Button>}
                {item.is_validated === 0 && <Button title={noOfActions > 2 ? '' : 'Validate'} variant='text' onClick={(e) => handleValidateSchema(item?.schema, e)}><i className="fa-solid fa-file-circle-check not-0"></i> {noOfActions > 2 && <span>Validate</span>}</Button>}
                {item.is_validated === 1 && <Button title={noOfActions > 2 ? '' : 'Provision All'} variant='text' onClick={(e) => handleProvisionAll(item?.schema, e)}><i className="fa-solid fa-hand-holding-medical not-0"></i> {noOfActions > 2 && <span>Provision All</span>}</Button>}
              </>;
              {item.actions = (noOfActions > 2 ? <div className="text-right more-options-wrapper">
                {(item.is_editable === 1 || item.is_deletable === 1 || item.is_validated === 0 || item.is_validated === 1) && <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>}
                <div className="more-options" style={{display: "none"}}>
                  <Actions />
                </div>
              </div> : <div className="flex items-center justify-end gap-3">
                <Actions />
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setTableData(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
    
  }, [token, selectedOrganization, refreshSchemaList]);

  const sortDown=<i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp=<i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ['Updated', 'Option 2', 'Option 3'];
  const sortingIcons= [sortDown,sortUp];

  const columns = [
    {
      name: <div className='row-label'>Name</div>,
      selector: row => row.name,
      sortable: true,
      wrap: true,
      width: '15%'
      //width: '27%'
    },
    {
      name: <div className='row-label'>Description</div>,
      selector: row => row.description,
      sortable: true,
      wrap: true,
      width: '43%'
      //width: '32%'
    },
    {
      name: <div className='row-label'>Validated</div>,
      selector: row => row.is_validated,
      cell: (row) => row.is_validated === 1 ? <div className='status green'><i className="fa-regular fa-circle-check"></i></div> : <div className='status red'><i className="fa-regular fa-circle-xmark"></i></div>,
      sortable: true,
      wrap: true,
      //width: '11%'
    },
    {
      name: <div className='row-label'>Actions</div>,
      selector: row => row.actions,
      //width: 'calc(15% - 48px)'
    },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  //Create
  const [createSchemaModalShow, setCreateSchemaModalShow] = useState(false);
  const handleCloseCreateSchemaModal = () => {
    setCreateSchemaModalShow(false);
    setFormData({});
    setFormMessage({});
  }
  const handleShowCreateSchemaModal = () => {
    setCreateSchemaModalShow(true);
  }

  const handleCreateSchemaSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_schema_create",{
      token:token,
      dbs:dbsSlug,
      name: formData.name,
      description: formData.description ? formData.description : "-"
    })
    .then(response=>{
      console.log("dwh_schema_create:", response);
      if (response.data.status === 200) {
        setFormData({});
        setFormMessage({message:"Schema created successfully", type:"success"});
        setRefreshSchemaList(!refreshSchemaList);
        handleCloseCreateSchemaModal();
        handleShowAlertModal('Schema created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Edit
  const [schemaName, setSchemaName] = useState("");
  const [schemaSlug, setSchemaSlug] = useState("");
  const [editSchemaModalShow, setEditSchemaModalShow] = useState(false);
  const handleCloseEditSchemaModal = () => {
    setEditSchemaModalShow(false);
    setSchemaName("");
    setSchemaSlug("");
    setFormData({});
    setFormMessage({});
  }
  const handleShowEditSchemaModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditSchemaModalShow(true);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleUpdateSchema = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_schema_update",{
      token:token,
      schema:formData.schema,
      dbs:dbsSlug,
      name: formData.name,
      description: formData.description ? formData.description : "-"
    })
    .then(response=>{
      console.log("dwh_schema_update:", response);
      if (response.data.status === 200) {
        setFormData({});
        setFormMessage({message:"Schema updated successfully", type:"success"});
        setRefreshSchemaList(!refreshSchemaList);
        handleCloseEditSchemaModal();
        handleShowAlertModal('Schema updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Delete
  const [deleteSchemaModalShow, setDeleteSchemaModalShow] = useState(false);
  const handleCloseDeleteSchemaModal = () => {
    setDeleteSchemaModalShow(false);
    setSchemaName("");
    setFormData({});
    setFormMessage({});
  }
  const handleShowDeleteSchemaModal = (schema_name, schema_slug) => {
    setDeleteSchemaModalShow(true);
    setSchemaName(schema_name);
    setSchemaSlug(schema_slug);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleDeleteSchema = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "dwh_schema_delete",{token:token, schema:schemaSlug, dbs:dbsSlug})
    .then(response=>{
      console.log("dwh_schema_delete:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Schema deleted successfully", type:"success"});
        setRefreshSchemaList(!refreshSchemaList);
        handleCloseDeleteSchemaModal();
        handleShowAlertModal('Schema deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Validate
  const handleValidateSchema = (schemaSlug, event) => {
    event.preventDefault();

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }

    axios.post(serverConfig.api_base_url + "dwh_schema_validate",{token:token, schema:schemaSlug, dbs:dbsSlug})
    .then(response=>{
      console.log("dwh_schema_validate:", response);
      if (response.data.status === 200) {
        setRefreshSchemaList(!refreshSchemaList);

        setSchemaSlug("");
        setSchemaName("");

        handleShowAlertModal('Schema validated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Provision all tables
  const handleProvisionAll = (schemaSlug, event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }

    axios.post(serverConfig.api_base_url + "dwh_table_provision_all",{token:token, org:selectedOrganization?.slug, schema:schemaSlug})
    .then(response=>{
      console.log("dwh_table_provision_all:", response);
      if (response.data.status === 200) {
        setRefreshSchemaList(!refreshSchemaList);

        handleShowAlertModal('Related tables provisioned successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return(
    <div className="overflow-x-auto px-14 ms-2 mt-4 pb-3">
      <div className="table-custom table-dbs-sub">
        <DataTable title={
          <div className=" table-title rounded">
            <div className="flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h4 className='font-bold text-base'>Schemas</h4>
              </div>
              <span className="text-right">
                <Button type='button' className='sub-grp px-3 py-2' title="Create Schema" onClick={handleShowCreateSchemaModal}><i className="fa-solid fa-plus" aria-hidden="true"></i></Button>
              </span>               
            </div>
          </div>}
          columns={columns}
          data={tableData}
          expandableRows
          //expandableRowsComponent={({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>}
          expandableRowsComponent={({ data }) => <DwhTable dbs={dbsSlug} schema={data.schema} refreshSchemaList={refreshSchemaList} />}
          responsive={true}
          pagination={tableData.length > 10 ? true : false}
          //noTableHead
          highlightOnHover
          customStyles={{
            table: {
              style: {
                border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                borderRadius: '16px 16px 10px 10px',
              },
            },
            rows: {
              style: {
                minHeight: '48px',
                border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          noDataComponent={<div className='text-xs'>There are no records to display</div>}
        />

        {createSchemaModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Create Schema</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreateSchemaSubmit}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <div className='flex flex-col gap-1 mt-8 text-xs'>
                    <label>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Description</label>
                    <textarea value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                  </div>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateSchemaModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>}

        {editSchemaModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Schema</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateSchema}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <div className='flex flex-col gap-1 mt-8 text-xs'>
                    <label>Name</label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Description</label>
                    <textarea value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                  </div>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditSchemaModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
              </div>
            </form>
          </div>
        </div>}

        {deleteSchemaModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={handleDeleteSchema}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Schema</div>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Schema <b>{schemaName}</b> ?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteSchemaModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}

        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
      </div>
    </div>
  );
}

export default Schema;