
import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../contextApi/context';
import Search from './Search';
import axios from 'axios';

const serverConfig = require("./../../config/server.js");

function Popup({ setLabel, label, setColorCode, colorCode, setPopup, entries, setEntries, handleShowAlertModal, labels, searchList }) {

  const addEntry = () => {
    if (label) {
      const foundLabels = labels && labels.some(el => el.name === label);
      const foundSrchList = searchList && searchList.some(el => el.name === label);
      if (!foundLabels && !foundSrchList){
        setEntries([...entries, {name:label, color_code:colorCode}]);
        setLabel('');
        setColorCode('#ffff00');
      } else {
        handleShowAlertModal("This label already exists", true);
      }
    }
  };

  return (
    <div className=''>
      <div className='text-sm px-1 text-custom title-bdr-btm'>Add New Label</div>
      <button type="button" className='absolute top-2 right-2 text-gray-400' onClick={() => { setPopup(false) }}>
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div className=' text-sm px-1 text-custom'>Name</div>
      <input type="text" className='w-full p-2 rounded-lg link-txt mt-1 text-xs' value={label} onChange={(e) => { setLabel(e.target.value) }} placeholder='label ' />
      <div className='flex gap-2 mt-1 items-center'>
        <div className='text-sm px-1 text-custom'>Color</div>
        <input type="color" value={colorCode} onChange={(e) => { setColorCode(e.target.value) }} />
      </div>

      <button type="button" className='btn-popup px-4 py-2 rounded-lg mt-4 text-sm font-medium ' onClick={addEntry}>Add</button>

    </div>
  )
}

function Label({ setFormData, formData, token, selectedOrganization, handleShowAlertModal, handleCloseAlertModal }) {
  const { rowId } = useContext(Context);
  const [popup, setPopup] = useState(false);
  const [label, setLabel] = useState('');
  const [colorCode, setColorCode] = useState('#ffff00');

  const [entries, setEntries] = useState(formData.labels ? formData.labels : []);
  
  
  //const classNames = ['status-todo', 'status-inprogress', 'status-done', 'status-backlog'];
  
  const [searchList, setSearchList] = useState([]);
  const [srchList, setSrchList] = useState([]);
  const [selectedLabelList, setSelectedLabelList] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      axios.post(serverConfig.api_base_url + "label_list_get",{token: token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('label_list_get response', response);
        console.log('label_list_get response items',response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setSearchList(response.data.items);
            setSrchList(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          setSearchList([]);
          setSrchList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization]);

  useEffect(() => {
    console.log(labels);
    console.log(entries);
    console.log(selectedLabelList);
    console.log([...entries, ...selectedLabelList]);
    setLabels([...entries, ...selectedLabelList]);
  }, [entries, selectedLabelList]);

  useEffect(() => {
    console.log(formData);
    console.log(formData.labels);
    //setLabels(formData.labels);
  }, []);
  
  useEffect(() => {
    console.log(labels);
    console.log(formData);
    console.log(formData.labels);
    setFormData({...formData, labels: labels});
  }, [labels]);

  const handleAdd = (data) => {
    console.log(labels);
    console.log(data, selectedLabelList);
    setSelectedLabelList([...selectedLabelList, data]);
  }

  const handleRemove = (name) => {
    console.log(selectedLabelList);
    setSelectedLabelList(selectedLabelList.filter(el => el.name !== name));
  }


  return (
    <div className='mt-7'>
      <div className='flex items-center justify-start'>
        <label>Labels</label>
        <label className="cursor-pointer">
          <button type="button" className="font-medium inline-block text-gray-500 text-lg mx-2" onClick={() => { setPopup(!popup) }}>
            <i className="fa-solid fa-plus font-normal"></i>
          </button>
        </label>
      </div>
      {/* {popup && <Popup label={label} setLabel={setLabel} setPopup={setPopup} entries={entries} setEntries={setEntries} />} */}
      {popup && <div className="popup p-4 bg-gray-300 mt-3 relative">
        <Popup label={label} setLabel={setLabel} colorCode={colorCode} setColorCode={setColorCode} setPopup={setPopup} entries={entries} setEntries={setEntries} handleShowAlertModal={handleShowAlertModal} labels={labels} searchList={searchList} />
        {searchList.length > 0 && <div className='organisationDropdownCard mt-5' style={{width: '100%', left: 0, position: 'unset'}}>
          <div className='px-2 mt-4 w-full'>
            <div className='text-sm px-1 text-custom title-bdr-btm'>Search Label</div>
            <Search srchList={srchList} setSearchList={setSearchList} />
          </div>
          <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{borderBottom: 'none'}}>
            <ul className='px-1 text-sm'>
              {searchList.map((data, index)=>{
                return(<li key={index} className={index === searchList.length-1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => selectedLabelList.length > 0 && selectedLabelList.find(el => el.name === data.name) || labels.length > 0 && labels.find(el => el.name === data.name) ? handleRemove(data.name) : handleAdd(data)} title={selectedLabelList.length > 0 && selectedLabelList.find(el => el.name === data.name) || labels.length > 0 && labels.find(el => el.name === data.name) ? `Remove ${data.name}` : `Add ${data.name}`}>
                  <div>
                    <img src={`https://ui-avatars.com/api/?name=${data?.name} &rounded=false&length=1&background=${data?.color_code.replace('#','')}&format=svg&color=fff`} alt={data?.name} className='rounded-full' width="40" /></div>
                  <div className="ml-4">{data?.name}
                  </div>
                  {selectedLabelList.length > 0 && selectedLabelList.find(el => el.name === data.name) || labels.length > 0 && labels.find(el => el.name === data.name) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-plus text-slate-400"></i></span>}
                </li>)
              })}
            </ul>
          </div>
        </div>}
      </div>}

      <div className={`flex flex-wrap mt-4`}>
        {labels && labels.length > 0 && labels.map((item, index) => {
          return (
            <div className='status mx-1 relative' 
            style={{
              backgroundColor: `${item.color_code}30`,
              borderColor: `${item.color_code}90`,
            }}>
              <button type='button' className="btn-remove" title="Remove" onClick={() => {setLabels(labels.filter(el => el.name !== item.name)); setEntries(entries.filter(el => el.name !== item.name)); handleRemove(item.name);}}><i className="fa-solid fa-xmark "></i></button>
              {item.name}
            </div>
          )
        })
        }
      </div>
    </div>
  )
}

export default Label