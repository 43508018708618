import React, { useState, useEffect } from "react";
import Tooltip from "./Tooltip"; // Import the Tooltip component
import "../css/style.css";
import PlusButtonTooltip from "./PlusButtonTooltip";

const TimeGraph = ({ details, setAddTime }) => {
  const [backgroundWidth, setBackgroundWidth] = useState("100%");
  const [tooltip, setTooltip] = useState({
    content: "",
    x: 0,
    y: 0,
    orgName: "",
    taskName: "",
    groupName: "",
  });
  const [isMouseOverBackground, setIsMouseOverBackground] = useState(false); // State to track mouse over background
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [plusButtonTooltip, setPlusButtonTooltip] = useState("");
  const [formattedTime, setFormattedTime] = useState("");

  const startTime = "00:00:00";
  const endTime = "23:59:59";

  // Retrieve group colors from local storage or use empty object
  const storedGroupColors =
    JSON.parse(localStorage.getItem("groupColors")) || {};
  const [groupColors, setGroupColors] = useState(storedGroupColors);

  const generateRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const getGroupColor = (groupName) => {
    // If the group already has a color assigned, return it
    if (groupColors[groupName]) {
      return groupColors[groupName];
    }
    // Otherwise, generate a random color for the group
    const color = generateRandomColor();
    // Update the state with the new color assignment
    const updatedGroupColors = { ...groupColors, [groupName]: color };
    setGroupColors(updatedGroupColors);
    // Store the updated group colors in local storage
    localStorage.setItem("groupColors", JSON.stringify(updatedGroupColors));
    return color;
  };

  const progressSegments = details.map((entry) => ({
    //startTime: entry.start_time.split(" ")[1],
    //endTime: entry.end_time.split(" ")[1],
    startTime: new Date(entry.start_time+' UTC').toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false }),
    endTime: new Date(entry.end_time+' UTC').toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false }),
    taskName: entry.task_name,
    orgName: entry.org_name,
    groupName: entry.group.name,
    defaultColor: entry.group.default_color
  }));

  const startDate = new Date(`2000-01-01T${startTime}`);
  const endDate = new Date(`2000-01-01T${endTime}`);
  const totalTime = endDate - startDate;

  useEffect(() => {
    const handleResize = () => {
      setBackgroundWidth("100%");
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (event, segment) => {
    const { clientX, clientY } = event;
    if (segment.startTime && segment.endTime) {
      // Format time
      const formatTime = (time) => {
        const [hours, minutes] = time.split(":");
        const parsedTime = new Date(0, 0, 0, hours, minutes);
        return parsedTime.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      };

      const startTime = formatTime(segment.startTime);
      const endTime = formatTime(segment.endTime);
      const timeRange = `${startTime} to ${endTime}`;
      const tooltipContent = `${timeRange}`;
      const taskName = segment.taskName;
      const orgName = segment.orgName;
      const groupName = segment.groupName;
      setTooltip({
        content: tooltipContent,
        x: clientX,
        y: clientY,
        taskName: taskName,
        orgName: orgName,
        groupName: groupName,
      });
      setIsMouseOverBackground(true); // Set mouse over background state to true
    }
  };

  const calculateTimeFromMousePosition = (x, svgWidth) => {
    // Calculate the proportion of mouse position relative to SVG width
    const proportion = (x - 249) / svgWidth;

    // Calculate the corresponding time based on the proportion
    const milliseconds = proportion * totalTime;
    const timeOffset = new Date(startDate.getTime() + milliseconds);

    // Format the time as needed
    const formattedTime = timeOffset.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true, // Use 12-hour format
    });
    setFormattedTime(formattedTime);
    return formattedTime;
  };

  // ------------ Logic for Plus Button ---------------
  // const renderPlusButtonAndTooltip = () => {
  //   if (isMouseOverBackground && isMultipleOfOne) {
  //     return (
  //       <>
  //         <g onClick={handlePlusButtonClick}>
  //           <circle
  //             cx={mousePosition.x - 250}
  //             cy={14}
  //             r="8"
  //             stroke="#30D5C8"
  //             strokeWidth="2"
  //             fill="var(--secondary-200)"
  //           />
  //           <text
  //             x={mousePosition.x - 254}
  //             y={18}
  //             fill="#30D5C8"
  //             fontSize="12"
  //             fontWeight="bold"
  //             pointerEvents="none"
  //           >
  //             +
  //           </text>
  //         </g>
  //         <PlusButtonTooltip
  //           content={plusButtonTooltip}
  //           x={mousePosition.x}
  //           y={mousePosition.y}
  //         />
  //       </>
  //     );
  //   }
  //   return null;
  // };

  const handlePlusButtonClick = () => {
    console.log("Button Clicked");
    // Add your logic here
  };
  const handleMouseMove = (event) => {
    const { clientX } = event;
    const svgWidth = event.target.getBoundingClientRect().width; // Get SVG width
    setMousePosition({ x: clientX, y: 0 });

    // Calculate time based on mouse position
    const time = calculateTimeFromMousePosition(clientX, svgWidth);

    // Update plus button tooltip content
    setPlusButtonTooltip(time);
  };

  const handleMouseLeave = () => {
    setTooltip({
      content: "",
      x: 0,
      y: 0,
      orgName: "",
      taskName: "",
      groupName: "",
    });
    setIsMouseOverBackground(false); // Set mouse over background state to false
  };

  const currentTime = new Date(); // Get current time

  // Check if the current time's minutes component is divisible by 5
  const isMultipleOfOne = currentTime.getMinutes() % 1 === 0;

  const progressRectangles = progressSegments.map((segment, index) => {
    const segmentStartTime = new Date(`2000-01-01T${segment.startTime}`);
    const segmentEndTime = new Date(`2000-01-01T${segment.endTime}`);
    const segmentDuration = segmentEndTime - segmentStartTime;
    const segmentProgress = (segmentDuration / totalTime) * 100;
    const segmentX = ((segmentStartTime - startDate) / totalTime) * 100;

    // Get color for group
    //const groupColor = getGroupColor(segment.groupName);
    const groupColor = segment.defaultColor;

    return (
      <g key={index} className="progress-segment">
        {/* Rectangle representing progress segment */}
        <rect
          y="6.5"
          height="15"
          rx="8"
          ry="8"
          width={`${segmentProgress}%`}
          x={`${segmentX}%`}
          fill={groupColor} // Use group color
          stroke={groupColor} // Use group color for stroke
          strokeWidth="1"
          onMouseEnter={(event) => handleMouseEnter(event, segment)}
          onMouseLeave={handleMouseLeave}
        />
        {/* Tooltip */}
        {tooltip.content && (
          <Tooltip
            content={tooltip.content}
            x={tooltip.x}
            y={tooltip.y}
            taskName={tooltip.taskName}
            orgName={tooltip.orgName}
            groupName={tooltip.groupName}
          />
        )}
      </g>
    );
  });

  return (
    <svg
      focusable="false"
      className="time-entries-timeline--chart time-graph-svg"
      width={backgroundWidth}
      height="42"
    >
      {/* Background */}
      <g
        className="bg"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={() => setIsMouseOverBackground(true)}
      >
        <rect
          y="6"
          x="0"
          height="16"
          width="100%"
          rx="8"
          ry="100"
          fill="#DFE6F1"
          tabIndex="-1"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={() => setIsMouseOverBackground(true)} // Set mouse over background state to true
        />
        {/* Plus Button and Tooltip */}
        {/* {renderPlusButtonAndTooltip()} */}
      </g>
      {/* Progress segments */}
      <g className="time-entries">{progressRectangles}</g>
      {/* X axis */}
      <g
        className="x axis"
        transform="translate(0, 22)"
        fill="none"
        fontSize="10"
        fontFamily="sans-serif"
        textAnchor="middle"
      >
        <g className="tick time1" opacity="1" transform="translate(400,0)">
          <line stroke="#DFE6F1" y2="6"></line>
          <text fill="#8E9EB3" y="9" dy="0.71em">
            6am
          </text>
        </g>
        <g className="tick time2" opacity="1" transform="translate(800,0)">
          <line stroke="#DFE6F1" y2="6"></line>
          <text fill="#8E9EB3" y="9" dy="0.71em">
            12pm
          </text>
        </g>
        <g className="tick time3" opacity="1" transform="translate(1200,0)">
          <line stroke="#DFE6F1" y2="6"></line>
          <text fill="#8E9EB3" y="9" dy="0.71em">
            6pm
          </text>
        </g>
      </g>
    </svg>
  );
};

export default TimeGraph;
