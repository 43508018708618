import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as htmlToImage from 'html-to-image';
import {
    Bar, Line, Area, ComposedChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
    CartesianGrid
} from 'recharts';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// Chart Wrapper Component
const Chart = ({ defaultType, name, data, options }) => {
    const [chartType, setChartType] = useState(defaultType);

    // method - chart optons
    const UpdateType = (menu) => {
        if (menu !== "image" && menu !== "export") {
            setChartType(menu?.trim()?.toLowerCase());
        }
        else if (menu === "export") {
            // export to excel functionlity
            exportToExcel((name || "Export"), data);
        }
        else {
            // Download as Image
            OnDownloadClick("chartDashboard");
        }
    }

    // method - download chart as an Image (.png)
    const OnDownloadClick = async (tagName) => {
        await htmlToImage.toPng(document.getElementsByClassName(tagName)[0])
            .then(function (dataUrl) {
                const link = document.createElement('a')
                link.download = "Chart"
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                // nothing
            });
    }

    // method - excport data to excel
    const exportToExcel = (filename, data) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const date = new Date();

        const formattedFileName = `${filename}_${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${formattedFileName}.xlsx`);
    };


     // Handle click on a bar
     const handleBarClick = async (barData) => {
        const newFilter = {
            id: '',
            filter_name: '',
            filter_label: options?.operator?.filter_label,
            optr: options?.operator?.optr,
            filter_operator: options?.operator?.operator_label,
            array_value: [barData?.payload[options?.xAxis?.columns]],
            fltr: options?.operator?.fltr, 
            value: null
          };

        const existingFilterIndex = options?.filterData?.findIndex(
            filter => filter?.fltr === newFilter?.fltr
        );
        let updatedFilters = [...options?.filterData];

        if (existingFilterIndex !== -1) {
            updatedFilters[existingFilterIndex] = {
                ...updatedFilters[existingFilterIndex],
                array_value: [
                    ...new Set([...updatedFilters[existingFilterIndex]?.array_value, ...newFilter?.array_value])
                ] // using Set to avoid duplicate values
            };
        } else {
            updatedFilters.push(newFilter);
        }

        options?.handleSubmit(updatedFilters, null);
        console.log("Clicked bar data:", barData, options?.xAxis);
    }

    return (
        <div style={{
            height: chartType?.toLowerCase() === "table" ? options?.height : "",
            overflow: chartType?.toLowerCase() === "table" ? "auto" : "",
            borderRadius: "15px !important"
        }}>
            <div className={options?.style?.borderRadius ? "rounded chartDashboard" : "chartDashboard"} 
            // style={{ border: options?.style?.border ? "1px solid #ccc" : "" }}
            style={{borderRadius: "15px"}}
            >
                <div style={{ 
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px"
                     }} className={`d-flex ${options?.header?.name ? "flex justify-between" : "flex justify-end"} align-items-center text-center p-1 ${options?.style?.borderRadius ? "rounded" : ""} text-wrap`}>
                    {options?.header?.name ? <h6 style={{ textAlign: options?.header?.textAlign || "center" , fontSize: "18px", fontWeight: "500", marginTop: "10px"}} className="ps-2 pe-2 text-wrap flex-grow-1 mb-0">{options?.header?.name}</h6> : null}
                    {options?.filters?.show ? <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                        <Popover.Body>
                            {options?.filters?.menus?.map((menu, index) => (
                                <div key={menu} className={`${options?.filters?.menus?.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                                    <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                                </div>
                            ))}
                        </Popover.Body>
                    </Popover>}>
                        <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                            <i className="fa-solid fa-ellipsis-vertical not-0" style={{color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px"}}></i>
                            {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                            </span>
                    </OverlayTrigger></div> : null}
                </div>
                {chartType?.toLowerCase() !== "table" ? <div style={{ backgroundColor: options?.style?.bgColor || "white", 
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px" }}>
                    <ResponsiveContainer width={"100%"} height={options?.height || 300}>
                        <ComposedChart
                            height={options?.height || 300}
                            margin={{ top: 20, left: chartType?.toLowerCase() === "line" ? 50 : 30, right: 20, bottom: 10 }}
                            data={data}>
                            <XAxis dataKey={options?.xAxis?.columns || ""} height={data?.length > 5 ? 40 : 30}
                                tick={options?.xAxis?.tick ?? true}
                                tickLine={options?.xAxis?.tickLine ?? true}
                                axisLine={options?.xAxis?.axisLine ?? true}
                                tickFormatter={(val) => {
                                    const valueStr = String(val); // Convert to string
                                    return valueStr?.length > 15 ? `${valueStr.substring(0, 15)}...` : valueStr;
                                }}                                
                                interval={data.length > 20 ? 2 : 0} fontSize={options?.xAxis?.fontSize || 12} angle={data.length > 10 ? /* options?.xAxis?.angle */-45 : 0}
                                textAnchor={data?.length > 5 ? "end" : "middle"} />
                            {options?.yAxis?.tick ? <YAxis fontSize={options?.label?.fontSize || 12}
                                tick={options?.yAxis?.tick ?? true}
                                tickLine={options?.yAxis?.tickLine ?? true}
                                axisLine={options?.yAxis?.axisLine ?? true}
                                label={{ value: options?.YAxisLabel || "", angle: -90, position: 'insideLeft' }} /> : null}
                            <Tooltip contentStyle={{ fontSize: '14px', padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '5px' }} />
                            {options?.gridLine ? <CartesianGrid vertical={false} stroke='#eee' /> : null}
                            {options?.gradient && options?.colors?.map((color, index) => (
                                <defs>
                                    <linearGradient id={`chart-${index}`} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor={color} stopOpacity={1}></stop>
                                        <stop offset="100%" stopColor={color} stopOpacity={0.12}></stop>
                                    </linearGradient>
                                </defs>
                            ))}
                            {options?.legend ? <Legend verticalAlign={data?.length > 5 ? 'top' : 'bottom'} /> : null}
                            {chartType?.toLowerCase() === "bar" && options?.yAxis?.columns?.length > 0 && options?.yAxis?.columns?.map((axis, index) => (
                                options?.stackBar ?
                                    <Bar key={axis} barSize={options?.barSize || null} radius={(options?.yAxis?.columns?.length === (index + 1)) ? [5, 5, 0, 0] : [0, 0, 0, 0]} dataKey={axis} stackId="chrt" fill={options?.colors[index]} 
                                    onClick={(e) => options?.handleSubmit ? handleBarClick(e) : ''} // Bar click handler
                                    >
                                        {options?.label?.show ? <LabelList stroke={options?.label?.color} strokeWidth={0.5} fontSize={options?.label?.fontSize || 12} position="top"></LabelList> : null}
                                    </Bar> :
                                    <Bar key={axis} barSize={options?.barSize || null} radius={options?.radius ? options?.radius : [0, 0, 0, 0]} dataKey={axis} fill={options?.gradient ? `url(#chart-${index})` : options?.colors[index]}
                                    onClick={(e) => options?.handleSubmit ? handleBarClick(e) : ''} // Bar click handler
                                    >
                                        {options?.label?.show ? <LabelList stroke={options?.label?.color} strokeWidth={0.5} fontSize={options?.label?.fontSize || 12} position="top"></LabelList> : null}
                                    </Bar>
                            ))}
                            {chartType?.toLowerCase() === "line" && options?.yAxis?.columns?.length > 0 && options?.yAxis?.columns?.map((axis, index) => (
                                <Line key={axis} dataKey={axis} type="" dot={options?.lineDot ? true : false} stroke={options?.colors?.[index]} fill={options?.colors?.[index]}>
                                    {options?.label?.show ? <LabelList stroke={options?.label?.color} strokeWidth={0.5} fontSize={options?.label?.fontSize || 12} position="top"></LabelList> : null}
                                </Line>
                            ))}
                            {chartType?.toLowerCase() === "area" && options?.yAxis?.columns?.length > 0 && options?.yAxis?.columns?.map((axis, index) => (
                                <Area key={axis} dataKey={axis} stroke={options?.colors[index]} fill={options?.colors?.[index]}>
                                    {options?.label?.show ? <LabelList stroke={options?.label?.color} strokeWidth={0.5} fontSize={options?.label?.fontSize || 12} position="top"></LabelList> : null}
                                </Area>
                            ))}
                        </ComposedChart>
                    </ResponsiveContainer></div> : null}
                {chartType?.toLowerCase() === "table" && data?.length > 0 ? <table className='table' style={{ height: `${options?.height || 390}px`, overflow: "auto", textIndent: "20px", fontSize: "20px" }}>
                    <thead>
                        <tr>
                            {Object.keys(data[0])?.map(head => (
                                <th key={head}>{head}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(details => (
                            <tr key={details}>
                                {Object.keys(data[0])?.map(head => (
                                    <td>{details[head]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table> : null}
                {chartType?.toLowerCase() !== "table" && options?.footer?.name ? <div className='ps-2 pe-2 text-wrap' style={{
                    textAlign: options?.footer?.textAlign,
                    backgroundColor: options?.footer?.bgColor, fontSize: options?.footer?.fontSize || 12
                }}>{options?.footer?.name}</div> : null}
            </div>
        </div>
    );
};

export default Chart;