import React, { useContext } from "react";
import { Context } from "../../contextApi/context";
import MyPoliciesTable from "./tables/MyPoliciesTable";

const MyPolicies = () => {
  const { collapse } = useContext(Context);

  return (
    <>
      <div>
        <div className={collapse ? "content-body" : "content-body-collapse"}>
          <div className="container-fluid metadata">
            <MyPoliciesTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPolicies;
