import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Context } from "../contextApi/context";
import { Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router';
import UserService from '../services/UserService.js';
import Notification from '../common/notification/Notification.js';
import "./css/mapmetadata.css";

const serverConfig = require("../config/server.js");

const Template = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {collapse} = useContext(Context);
  const {selectedOrganization} = useContext(Context);

  const [token, setToken] = useState(null);
  const [inputs, setInputs] = useState({});
  const [mappingData, setMappingData] = useState([]);

  const [fileFormatLookupList, setFileFormatLookupList] = useState([]);
  const [stgTableList, setStgTableList] = useState([]);
  const [factTableList, setFactTableList] = useState([]);
  const [formData, setFormData] = useState({});
  const [targetListOriginal, setTargetListOriginal] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [refreshMapping, setRefreshMapping] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    console.log(JSON.parse(location.state.details));
    if(location.state){
      setFormData(JSON.parse(location.state.details));
      setFileFormatLookupList(location.state.fileFormatLookupList);
      setStgTableList(location.state.stgTableList);
      setFactTableList(location.state.factTableList);
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "dwh_template_get_mapping",{token:token, template:formData.template})
      .then(response=>{
        console.log("dwh_template_get_mapping:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("dwh_template_get_mapping items:", JSON.parse(response.data.items));
            
            setTargetListOriginal(JSON.parse(response.data.items).stg_columns);

            let mappedTarget = JSON.parse(response.data.items).mapping.map(el => el.target_column);
            //console.log(mappedTarget);
            let stgColArr = JSON.parse(response.data.items).stg_columns;
            let remainingStgColArr = stgColArr.filter(function(el) {
              return !mappedTarget.includes(el);
            });
            //console.log(remainingStgColArr);
            setTargetList(remainingStgColArr);

            setMappingData(JSON.parse(response.data.items).mapping);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, refreshMapping]);

  const handleUpdateTemplate = () => {
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_template_update",{
      token:token,
      template:formData.template,
      name: formData.name,
      format: formData.format,
      stgTable: formData.stgTable,
      instructions: formData.instructions,
      rowsToSkip: formData.rowsToSkip,
      factTable: formData.factTable,
      expectedColumns: formData.expectedColumns
    })
    .then(response=>{
      console.log("dwh_template_update:", response);
      if (response.data.status === 200) {
        handleShowAlertModal('Template updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleChange = (idx, targetCol, event) => {
    const name = event.target.name;
    const value = event.target.value;

    var updatedData = {[name]: value};
    var result;
    if(targetCol){
      console.log(targetCol);
      result = mappingData.map((item) => (item.target_column === targetCol ? { ...item, ...updatedData } : item));
    } else {
      console.log(idx)
      result = mappingData.map((item, index) => (index === idx ? { ...item, ...updatedData } : item));
    }
    console.log(result);
    setMappingData(result);
  }

  const handleAddNewRow = () => {
    setMappingData([...mappingData, {
      source_function: null,
      target_column: null
    }]);

    console.log(mappingData);
    console.log(targetList);

    let mappedTarget = mappingData.map(el => el.target_column);
    setTargetList(targetList.filter((el) => !mappedTarget.includes(el)));
  }

  const handleDeleteMapping = (targetCol) => {
    console.log(targetCol);

    let arr = mappingData;
    setMappingData([]);

    setTimeout(() => {
      setMappingData(arr.filter(el => el.target_column !== targetCol));
    });

    setTargetList([...targetList, targetCol]);
  }

  const handleSave = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(JSON.stringify(formData) === location.state.details);
    if(JSON.stringify(formData) !== location.state.details){
      handleUpdateTemplate();//save template details form
    }
    
    //save mapping
    console.log(mappingData);
    //if(mappingData.length > 0){
      axios.post(serverConfig.api_base_url + "dwh_template_set_mapping",{token:token, template:formData.template, mappingDetails: mappingData})
      .then(response=>{
        console.log("dwh_template_set_mapping:", response);
        if (response.data.status === 200) {
          setMappingData([]);
          setInputs({});
          setRefreshMapping(!refreshMapping);
          handleShowAlertModal('Mapping saved successfully');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    //}
  }

  return (<>
    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
        <div className="container-fluid metadata">
          <div>
            <form onSubmit={handleSave} className="p-3 table-custom mt-4 flex flex-col">
              <h1 className='font-semibold text-2xl'>Template Details</h1>
              <div className='flex flex-col overflow-y-auto'>
                <section className="mb-5">
                  <div className='grid grid-cols-3 gap-4 mt-3 mb-5'>
                    <div className='flex flex-col gap-1 text-xs'>
                      <label>Name</label>
                      <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
                    </div>
                    <div className='flex flex-col gap-1 text-xs'>
                      <label>File Format</label>
                      <select value={formData.format || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, format: e.target.value})}>
                        <option value="">File Format</option>
                        {fileFormatLookupList.length > 0 &&
                          fileFormatLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                        }
                      </select>
                    </div>
                    <div className='flex flex-col gap-1 text-xs'>
                      <label>Rows to Skip</label>
                      <input type="number" min="0" value={formData.rowsToSkip || ""} onChange={(e)=>  setFormData({ ...formData, rowsToSkip: e.target.value})} placeholder='Rows to Skip' className='custom-input py-2 px-2 text-left mt-1'/>
                    </div>
                    <div className='flex flex-col gap-1 text-xs'>
                      <label>Stage Table</label>
                      <select value={formData.stgTable || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, stgTable: e.target.value})} disabled>
                        <option value="">Stage Table</option>
                        {stgTableList.length > 0 &&
                          stgTableList.map((item, index) => <option value={item.name} key={index}>{item.name}</option>)
                        }
                      </select>
                    </div>
                    <div className='flex flex-col gap-1 text-xs'>
                      <label>Fact Table</label>
                      <select value={formData.factTable || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, factTable: e.target.value})} disabled>
                        <option value="">Fact Table</option>
                        {factTableList.length > 0 &&
                          factTableList.map((item, index) => <option value={item.name} key={index}>{item.name}</option>)
                        }
                      </select>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='flex flex-col gap-1 mt-3 text-xs'>
                      <label>Instructions</label>
                      <textarea value={formData.instructions || ""} rows="4" onChange={(e)=>  setFormData({ ...formData, instructions: e.target.value})} placeholder='Instructions' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                    </div>
                    <div className='flex flex-col gap-1 mt-3 text-xs'>
                      <label>Expected Columns
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip" className='text-sm'>Comma separated list</Tooltip>}>
                          <Button className="ms-2 info-icon">i</Button>
                        </OverlayTrigger>
                      </label>
                      <textarea value={formData.expectedColumns || ""} rows="4" onChange={(e)=>  setFormData({ ...formData, expectedColumns: e.target.value})} placeholder='Expected Columns' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                    </div>
                    {/* <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div> */}
                  </div>
                </section>

                <section>
                  <h2 className='font-semibold text-2xl'>Column Mappings</h2>
                  <Row xs={4} className="items-center">
                    <Col><h6>Source</h6></Col>
                    <Col xs={1}></Col>
                    <Col><h6>Target</h6></Col>
                    <Col></Col>
                  </Row>
                  {mappingData.length > 0 && mappingData.map((items, index) => {
                    return(<Row xs={4} className="items-center" key={index}>
                      {/* <Col><input name="source" value={items.source} type="text" className='custom-input py-2 px-2 text-left mt-1' disabled/></Col> */}
                      <Col><input name="source_function" defaultValue={items.source_function} type="text" onChange={(e) => handleChange(index, items.target_column, e)} className='custom-input py-2 px-2 text-left mt-1' required/></Col>
                      <Col xs={1} className="text-center"><i className="fa-solid fa-arrow-right-long"></i></Col>
                      
                      <Col>
                        {items.target_column !== null ? <input name="source_futarget_columnnction" defaultValue={items.target_column} type="text" className='custom-input py-2 px-2 text-left mt-1' disabled /> : <select name="target_column" defaultValue={items.target_column} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleChange(index, items.target_column, e)} required>
                          <option value="">Select</option>
                          {targetList && targetList.length > 0 &&
                            targetList.map((item, index) => <option value={item} key={index}>{item}</option>)
                          }
                        </select>}
                      </Col>
                      <Col xs={1} className="text-center"><button type="button" onClick={() => handleDeleteMapping(items.target_column)}><i className="fa-regular fa-trash-can"></i></button></Col>
                    </Row>)
                  })}
                  <div><button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleAddNewRow}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></button></div>
                  
                </section>
              </div>

              <div className='ms-auto mt-auto'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => navigate('/template-management')}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>

        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
      </div>
    </div>
  </>);
}

export default Template;