import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { Context } from '../../contextApi/context.js';
import '../css/cat.css';
import Notification from '../../common/notification/Notification.js';

const serverConfig = require('../../config/server.js');

function DeleteRes({ setDeleteConfirmation, rowToDelete, onDeleteSuccess }) {
    const { selectedOrganization } = useContext(Context);
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
    const [formMessage, setFormMessage] = useState({ type: '', message: '' });

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);

    useEffect(() => {
        //console.log("Alerrttt",alertMessage);
        if (alertMessage !== '') {
            setShowAlertModal(true);
        } else {
            setShowAlertModal(false);
        }
    }, [alertMessage]);

    const handleCloseAlertModal = () => {
        //console.log("closeeee modal")
        setAlertMessage('');
        setShowOkButton(false);
        setShowAlertModal(false);
    };

    const getToken = () => {
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt })
            .then(response => {
                console.log('user_get_token response', response);
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setCToken(response.data.token);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log('error is', err));
    }

    useEffect(() => {
        //console.log("3")
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
    }, []);

    const showMessage = (message, type = 'info') => {
        setFormMessage({ type, message });
        console.log(`${type.toUpperCase()}: ${message}`);
    };

    const handleDeleteCategory = () => {
        console.log("row", rowToDelete);
        axios
            .post(serverConfig.api_base_url + 'resource_delete', {
                token: cToken,
                org: selectedOrganization?.slug,
                res: rowToDelete.resource_slug,
            })
            .then((response) => {
                if (response.data.status === 200) {
                    setAlertMessage('Resource deleted successfully');
                    setShowOkButton(true);
                    setTimeout(() => {
                        handleCloseAlertModal();
                        onDeleteSuccess();
                    }, 500);
                    //showMessage(`Deleted: ${rowToDelete.resource_description}`, 'success');
                    
                } else {
                    setAlertMessage('Failed to delete category. Server error')
                    setTimeout(() => {
                        handleCloseAlertModal();
                    }, 3000);
                    console.log(`Failed to delete category. Server error: ${response.data.message}`);
                }
            })
            .catch((err) => {
                console.log('Error:', err);
                setAlertMessage('An error occurred. Please try again.');
                setTimeout(() => {
                    handleCloseAlertModal();
                }, 3000);
            });
    };



    return (
        <div className='edit-user rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'>
            <div className='flex-grow overflow-y-auto'>
                <div className='flex justify-between items-center gap-5'>
                    <div style={{ fontSize: 16 }}>Delete Resource</div>
                    <button className='my-2 title-text-small-color' onClick={() => setDeleteConfirmation(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className='mt-4'>
                    <p>Are you sure you want to delete Resource <b>{rowToDelete.resource_name}</b>?</p>
                </div>
            </div>
            <div className='spacing-div'>
                <button
                    type="button"
                    className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                    onClick={() => setDeleteConfirmation(false)}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                    onClick={handleDeleteCategory}
                >
                    Delete
                </button>
                <div className='mt-4 flex justify-end'>
                    {showAlertModal && (
                        <Notification
                            message={alertMessage}
                            notificationType={showOkButton ? 'Success' : 'Error'}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default DeleteRes;
