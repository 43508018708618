import React, { useState, useContext, useEffect, useRef } from "react";
import { Context } from '../../contextApi/context.js';
import DataTable from "react-data-table-component";
import * as FaIcons from 'react-icons/fa';
import './../css/style.css';
import IconPicker from "../components/IconPicker";
import ColorPicker from "../components/ColorPicker";
import { Button } from 'react-bootstrap';
import Search from "../components/Search.js";
import axios from "axios";

const serverConfig = require("../../config/server.js");

function GroupDropdownCard({data, selectedGrp, setSelectedGrp, setCollapse, setGroupList, grpList, setGrpList, token, selectedOrganization, handleLogout, handleShowAlertModal, handleCloseAlertModal}){
  const { isDarkTheme } = useContext(Context);
  const [subgroupList, setSubgroupList] = useState([]);
  
  const handleGetSubgroupList = (grp, event) => {
    //console.log(grp, event);
    event.target.classList.toggle("fa-angle-up");
    event.target.classList.toggle("fa-angle-down");
    event.target.closest('.border-b').classList.toggle(isDarkTheme ? 'bg-neutral-600' : 'bg-slate-50');
    if(event.target.closest('.oragnisationHover').nextSibling !== null){
      event.target.closest('.oragnisationHover').nextSibling.classList.toggle("hidden");
    }
    axios.post(serverConfig.api_base_url + "group_list_get",{token:token, org:selectedOrganization?.slug, grp:grp})
    .then(response=>{
      console.log('group_list_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(response.data.items);
          setSubgroupList(response.data.items);
          /* for (let i = 0; i < grpList.length; i++) {
            if (grpList[i].grp === grp) {
              grpList[i].child=response.data.items;
            }
          } */

          function addObjectToNestedArray(array, valueToFind, newObject) {
            for (let item of array) {
              if (item.grp === valueToFind) {
                // Found the item, add the new object to its children
                //item.child.push(newObject);
                item.child=newObject;
                return true; // Return true to stop further searching
              }
              // If the item has children, recursively search in children
              if (item.child && item.child.length > 0) {
                if (addObjectToNestedArray(item.child, valueToFind, newObject)) {
                  return true; // Return true if the object was added in the recursive call
                }
              }
            }
            return false; // Return false if the value is not found
          }

          const valueToFind = grp;
          addObjectToNestedArray(grpList, valueToFind, response.data.items);

          console.log(grpList);
          setGroupList(grpList);
          setGrpList(grpList);
        } else {
          setSubgroupList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  return(<div className='border-b'>
    <div title={data.name} className={'flex gap-2 align-middle justify-between p-2 oragnisationHover ' + (selectedGrp?.grp === data?.grp && (isDarkTheme ? 'bg-neutral-900' : 'bg-slate-200'))} style={{alignItems:'center'}}>
      <div className='flex gap-2 align-middle' onClick={() => {setSelectedGrp(data); setCollapse(true); setGroupList(grpList); }}>
        <div>
          {data?.logo_base64 === null ? <img src={`https://ui-avatars.com/api/?name=${data?.name}
                          &rounded=false&length=1
                          &background=${data.default_color.replace('#','')}&format=svg&color=fff`} alt='image'
            className='organisation-img rounded-lg' /> : <img src={data?.logo_base64} alt={data?.logo_name} className='rounded-lg organisation-img' />}
        </div>
        <div className='organisation-heading line-clamp-1'>{data.name}</div>
      </div>
      <div className="flex min-w-max">
        {selectedGrp?.grp === data?.grp && <><i className="fa-solid fa-check text-xs mr-2"></i></>}
        <button type="button" onClick={(e) => handleGetSubgroupList(data.grp, e)}><i className="fa-solid fa-angle-down"></i></button>
      </div>
    </div>
    {data.child && data.child.length > 0 && <div className='pl-3'>
      {data.child.map((data)=>{
        return(<GroupDropdownCard data={data} setCollapse={setCollapse} selectedGrp={selectedGrp} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setGrpList={setGrpList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
      })}
    </div>}
  </div>)
}

const IssueCategory = ({token, handleCloseAlertModal, handleShowAlertModal, handleLogout, grp, groupDetails, isPlanner}) => {

    const {selectedOrganization} = useContext(Context);
    const[collapse, setCollapse]=useState(true);
    const [selectedIcon, setSelectedIcon] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [createStatus, setCreateStatus] = useState(false);
    const [name, setName] = useState('')
    const iconList = Object.keys(FaIcons);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [colorCode, setColorCode] = useState('');
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [slug, setSlug] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageColor, setErrorMessageColor] = useState('');
    const[grpList, setGrpList] = useState([]);
    const[groupList, setGroupList] = useState([]);
    const[selectedGrp, setSelectedGrp] = useState(groupDetails ? groupDetails : '');

    const colorPickerRef = useRef(null);
    const iconPickerRef = useRef(null);

    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setOpenColorPicker(false);
      }
      if (iconPickerRef.current && !iconPickerRef.current.contains(event.target)) {
        setIsPickerOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useEffect(()=> {
      console.log(groupDetails);

      if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
        console.log('selectedOrganization', selectedOrganization)
  
        axios.post(serverConfig.api_base_url + "group_list_get", {token:token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log('group_list_get response', response);
          if(response.data.status === 200){
            if(response.data.items !== null){
              setGrpList(response.data.items);
              setGroupList(response.data.items);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));
      }

    },[]);

    const handleSelectIcon = (icon) => {
        console.log(icon);
        setSelectedIcon(icon);
        setIsPickerOpen(false); // Close picker after selecting an icon
      };
  
      const handleIconChange = (value) => {
        setSelectedIcon(value);
      };

      const convertDate = (date) => {
        var inputDateStr = date;
        var inputDate = new Date(inputDateStr);
        var monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        var dayOfWeek = inputDate.getDay();

        var day = inputDate.getDate();
        var month = inputDate.getMonth(); // Months are zero-based
        var year = inputDate.getFullYear();

        var outputDateStr = "";

        var dayOfWeekStr;
        switch (dayOfWeek) {
          case 0:
            dayOfWeekStr = "Sun";
            break;
          case 1:
            dayOfWeekStr = "Mon";
            break;
          case 2:
            dayOfWeekStr = "Tue";
            break;
          case 3:
            dayOfWeekStr = "Wed";
            break;
          case 4:
            dayOfWeekStr = "Thu";
            break;
          case 5:
            dayOfWeekStr = "Fri";
            break;
          case 6:
            dayOfWeekStr = "Sat";
            break;
        }

        outputDateStr = dayOfWeekStr + ", " + monthNames[month] + " " + day + ", " + year;

        console.log(outputDateStr); // Output: "Thu, Mar 22, 2024"
        return outputDateStr;
    }

    const columns = [
      {
        name: "",
        cell: (row) => (
          <div
            className="border rounded-lg flex items-center bg-white"
            style={{ width: "40px", height: "40px" }}
          >
            {row?.group?.logo_base64 ? (
              <img
                src={row?.group?.logo_base64}
                alt={row?.group.name}
                width="40"
                height="40"
              />
            ) : (
              <img
                className="rounded-lg image-logo"
                height={40}
                width={40}
                src={
                  row?.group && row?.group?.logo
                    ? row?.group?.logo
                    : `https://ui-avatars.com/api/?name=${
                        row?.group.name
                      }&length=1&background=${row?.group.default_color?.replace(
                        "#",
                        ""
                      )}&format=svg&color=fff`
                }
                alt={row?.group.name}
              />
            )}
          </div>
        ),
        grow: 0,
        sortable: false,
      },
        {
                name: <div className='row-label'>Name</div>,
                cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold ml-3">
                    {row?.name}
                 </div>
        },

        {
          name: <div className='row-label'>{isPlanner ? 'Project' : 'Group'}</div>,
          cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold ml-3">
              {row?.group?.name}
           </div>
        },

        {
            name: <div className='row-label'>Color</div>,
            cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold">
                <div className="selected-color ml-3" style={{background : `${row?.color}`}}></div>
             </div>,
            width: "10%"
        },

        {
            name: <div className='row-label'>Icon</div>,
            cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold">
                <div className="selected-icon ml-4" onClick={() => {console.log(isPickerOpen)}}>
                            {React.createElement(FaIcons[row?.icon])}
                            </div>
             </div>,
            width: "10%"
        },
        {
            name: <div className='row-label pl-1'>Date Added</div>,
            cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold ml-5">
                {convertDate(row?.date_added)}
             </div>
        },
        {
            name: <div className='row-label pl-12'>Added By</div>,
            cell: (row) =>  <div> <div className='ml-4 flex flex-row'>{row?.logo === null ?
                <><img src={`https://ui-avatars.com/api/?name=${row?.user || 'K'}
                &rounded=true&length=1
                &background=6ADA00&format=svg&color=fff`} alt='image'
                 height={40} width={40} className='rounded-full ml-1'/> <div className="font-semibold text-sm ml-2 mt-3">{row?.user || 'Kezel'}</div> </>
                : <><img src={row?.logo || 'K'} className='rounded-full w-12 h-12 object-cover p-1'/> <div className="font-semibold text-sm ml-2 mt-3">{row?.user || 'Kezel'}</div> </> }
                </div> </div>
        },
        {
            name: <div className='row-label'>Actions</div>,
            cell: (row) =>        <div className='flex flex-row ml-4'>
              
              <>
            <Button variant='text' className='mr-2 ml-2' onClick={() => {setEditStatus(true); setName(row?.name); setSelectedIcon(row?.icon); setColorCode(row?.color); setSlug(row?.cat); setSelectedGrp(row?.group);}}><i class="fa-solid fa-pencil"></i></Button>
            <Button variant='text' onClick={() => {setDeleteStatus(true); setName(row?.name); setSlug(row?.cat)}}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> </Button> 
              </> 
             </div>
        },
    ]

    const handleCreateIssueStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {
            if(selectedIcon === '') {
                setErrorMessage('Select an icon')
            }
            else if(colorCode === '') {
                setErrorMessageColor('Select a color')
            }
            else {
            axios.post(serverConfig.api_base_url + "issue_category_create" , {
                token: token,
                org: selectedOrganization?.slug,
                color: colorCode,
                name: name,
                icon: selectedIcon,
                grp: selectedGrp?.grp
            }).then(response=> {
                if(response.data.status === 200) {
                    setCreateStatus(false);
                    setSelectedIcon(''); setColorCode(''); setName(''); /* setSelectedGrp('') */;
                    handleShowAlertModal('Category added successfully');

                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
            }
        }
    }

    const handleUpdateIssueStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_category_update" , {
                token: token,
                org: selectedOrganization?.slug,
                color: colorCode,
                name: name,
                icon: selectedIcon,
                cat: slug,
                grp: selectedGrp?.grp
            }).then(response=> {
                if(response.data.status === 200) {
                    setEditStatus(false);
                    setSelectedIcon(''); setColorCode(''); setName('')
                    handleShowAlertModal('Category updated successfully');

                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
        }
    }

    const handleDeleteStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_category_delete", {
                token: token,
                org: selectedOrganization?.slug,
                cat: slug
            }).then(response=> {
                console.log(response.data);
                if(response.data.status === 200) {
                    setDeleteStatus(false);
                    setSelectedIcon(''); setColorCode(''); setName('');
                    handleShowAlertModal('Category deleted successfully');

                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
        }
        }

    useEffect(() => {
      console.log(selectedGrp);
        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_category_get_list", {
                token: token,
                org: selectedOrganization?.slug,
                grp: grp
            }).then(response => {
                console.log(response.data.items, 'issue_list_res')
                if(response.data.status === 200) {
                  if(response.data.items !== null){
                    if(isPlanner){
                      //console.log(response.data.items.filter(el => el?.group?.type_details?.type === "Project"));
                      setCategoryList(response.data.items.filter(el => el?.group?.type_details?.type === "Project" || el?.group?.type_details?.type === "Sprint"));
                    } else {
                      setCategoryList(response.data.items);
                    }
                  } else {
                    setCategoryList([]);
                  } 
                }
            }).catch(err => {
                console.log(err);
            })
        }

    }, [createStatus, selectedOrganization, editStatus, deleteStatus])


    return (
        <>
    <div className='' style={{"padding" : "20px"}}>
    {categoryList?.length === 0 || categoryList === null ? <h1 className='font-bold text-base mt-4 mb-2'>Issue Categories</h1> : <></>}
      {categoryList?.length > 0 && categoryList !== null ? 
        <DataTable
        title={
            <div className=" table-title rounded">
              <div className="flex justify-between items-center">
                {/* Left side: Three tabs flexed together */}
                <div className="flex space-x-4">
                  <h4 className='font-bold text-base'>Issue Category</h4>
                </div>
                <span className="text-right">
                  <Button type='button' className='sub-grp px-3 py-2' title="Create Table" onClick={()=> {setCreateStatus(true);}}>Add Category</Button>
                </span>               
              </div>
            </div>}
         columns={columns}
         className="issue-status"
         data={categoryList}
         responsive={true}
         //pagination={true}
         customStyles={{
           rows: {
             rows: {
               className: 'custom-hover', // Apply custom hover styles here
             }
           },
           cells: {
             className: 'p-6',
           },
         }}
 /> :

 <div className='mt-6 flex flex-col h-2/4 items-center justify-center'>
 <div className='mb-8'><img src={require('./../../Asset/Images/no-records.png')} alt="No records" height={90} width={90}/></div>
 <p><b>No issue categories created for {selectedOrganization?.name}</b></p>
 <p className='text-sm'>Start creating issue categories</p>
 <div className='mt-2.5'><button className='sub-grp px-3 py-2' onClick={() => { setCreateStatus(true);}} >Add Category</button></div>
 </div> }

 {createStatus && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Add Category</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreateIssueStatus}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" style={{"width" : "auto"}} value={name || ""} onChange={(e)=> { setName(e.target.value) }} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' required/>
                  </div>
                </div>

                <div className='mt-1 mb-5'>
                <fieldset disabled={groupDetails !== null && groupDetails !== undefined}>
                  {Object.keys(selectedGrp).length > 0 && <div className="flex items-center">
                    {selectedGrp?.logo_base64 ? <img src={selectedGrp?.logo_base64} alt={selectedGrp.name} width="70" className='border rounded-lg' /> :
                    <img className='rounded-lg image-logo' height={70} width={70} src={selectedGrp && selectedGrp?.logo ? selectedGrp?.logo : `https://ui-avatars.com/api/?name=${selectedGrp.name}&length=1
                        &background=${selectedGrp?.default_color?.replace('#','')}&format=svg&color=fff`} alt={selectedGrp.name} />}
                    <div className='ml-3'>
                      <div className="text-xl"><b>{selectedGrp.name}</b></div>
                      <div>New Category will be created in this {isPlanner ? 'Project' : 'Group'}</div>
                    </div>
                  </div>}
                  {grpList.length === 0 && <div className="text-red-500 mt-4 mb-2 text-sm">Currently there are no {isPlanner ? 'Projects' : 'Groups'}. Please create one <span className='underline cursor-pointer' onClick={() => {
                    if(isPlanner){
                      navigate("/planner"); setActiveOption('Projects');
                    } else {
                      navigate("/groups"); setActiveOption('Groups');
                    }
                  }}>here</span></div>}
                  <div className='relative mt-2 inline-block'>
                    <input type='text' className="absolute max-w-full h-full opacity-0" value={selectedGrp.grp} required />
                    <button type="button" className='organisations border rounded-lg py-2 px-4 z-10 relative' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => setCollapse(!collapse)}><b>{selectedGrp.grp ? 'Change '+(isPlanner ? 'Project' : 'Group') : 'Select '+(isPlanner ? 'Project' : 'Group')}</b></button> <span className='text-red-500 align-top'>*</span>
                    {!collapse && <div className='organisationDropdownCard' style={{width: '20vw', left: 0}}>
                      <div className='pt-2 px-2'>
                        <Search grpList={grpList} setGroupList={setGroupList} />
                      </div>
                      <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                        {groupList.length > 0 && groupList.map((data)=>{
                          return(<GroupDropdownCard data={data} setCollapse={setCollapse} selectedGrp={selectedGrp} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setGrpList={setGrpList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
                        })}
                      </div>
                    </div>}
                  </div>
                </fieldset>
              </div>

                <div>
                    
                  <div className='flex flex-row gap-4 mt-2 text-xs'>
                    <label className='text-xs mt-1.5'>Icon<span className=' text-red-500'>*</span></label>
                       <div className="flex flex-row">
                       <button type="button" required className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-3.5' style={selectedIcon !== '' ? { width: "6.9rem" } : { width: "4.9rem" }} onClick={(event) => {event.stopPropagation(); setIsPickerOpen(!isPickerOpen)}}><b>{selectedIcon !== '' ? 'Change Icon' : 'Pick Icon'}</b></button> 
                       {selectedIcon && <>
                        
                            <div className="selected-icon ml-4" onClick={() => {console.log(isPickerOpen)}}>
                            {React.createElement(FaIcons[selectedIcon])}
                            </div> </> }
                        
                        {isPickerOpen === true && (
                            
                            <div ref={iconPickerRef} className="popup-container">
                            <IconPicker onSelect={handleSelectIcon} setIsPickerOpen={setIsPickerOpen} setErrorMessage={setErrorMessage}/>
                            </div>
                    
                        )
                        }
                        
                        </div>  
                  </div>
                </div>

                <div>
                  <div className='flex flex-row gap-4 mt-4 text-xs'>
                    <label className='text-xs mt-1.5'>Color<span className=' text-red-500'>*</span></label>
                    <button type="button" className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-2' style={{ width: "6.9rem", height: "34px" }} onClick={(event) => {event.stopPropagation(); setOpenColorPicker(!openColorPicker)}}><b>Select Color</b></button> 
                    {openColorPicker && <div ref={colorPickerRef}><ColorPicker color={colorCode} setColor={setColorCode} setOpenColorPicker={setOpenColorPicker} setErrorMessage={setErrorMessageColor}/></div> }
                    {colorCode !== '' &&   <div className="selected-color" style={{ backgroundColor: colorCode, width: '35px', height: '35px', borderRadius: '30%' }}/>}
                  </div>
                </div>
 
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setCreateStatus(false); setIsPickerOpen(false); setSelectedIcon(''); setColorCode(''); setName(''); /* setSelectedGrp(''); */}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}

        {editStatus && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Category</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateIssueStatus}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" style={{"width" : "auto"}} value={name || ""} onChange={(e)=> { setName(e.target.value) }} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' required/>
                  </div>
                </div>


                <div className='mt-1 mb-5'>
                <fieldset disabled={groupDetails !== null && groupDetails !== undefined}>
                  {Object.keys(selectedGrp).length > 0 && <div className="flex items-center">
                    {selectedGrp?.logo_base64 ? <img src={selectedGrp?.logo_base64} alt={selectedGrp.name} width="70" className='border rounded-lg' /> :
                    <img className='rounded-lg image-logo' height={70} width={70} src={selectedGrp && selectedGrp?.logo ? selectedGrp?.logo : `https://ui-avatars.com/api/?name=${selectedGrp.name}&length=1
                        &background=${selectedGrp?.default_color?.replace('#','')}&format=svg&color=fff`} alt={selectedGrp.name} />}
                    <div className='ml-3'>
                      <div className="text-xl"><b>{selectedGrp.name}</b></div>
                      <div>New Category will be created in this {isPlanner ? 'Project' : 'Group'}</div>
                    </div>
                  </div>}
                  {grpList.length === 0 && <div className="text-red-500 mt-4 mb-2 text-sm">Currently there are no {isPlanner ? 'Projects' : 'Groups'}. Please create one <span className='underline cursor-pointer' onClick={() => {
                    if(isPlanner){
                      navigate("/planner"); setActiveOption('Projects');
                    } else {
                      navigate("/groups"); setActiveOption('Groups');
                    }
                  }}>here</span></div>}
                  <div className='relative mt-2 inline-block'>
                    <input type='text' className="absolute max-w-full h-full opacity-0" value={selectedGrp.grp} required />
                    <button type="button" className='organisations border rounded-lg py-2 px-4 z-10 relative' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => setCollapse(!collapse)}><b>{selectedGrp.grp ? 'Change '+(isPlanner ? 'Project' : 'Group') : 'Select '+(isPlanner ? 'Project' : 'Group')}</b></button> <span className='text-red-500 align-top'>*</span>
                    {!collapse && <div className='organisationDropdownCard' style={{width: '20vw', left: 0}}>
                      <div className='pt-2 px-2'>
                        <Search grpList={grpList} setGroupList={setGroupList} />
                      </div>
                      <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                        {groupList.length > 0 && groupList.map((data)=>{
                          return(<GroupDropdownCard data={data} setCollapse={setCollapse} selectedGrp={selectedGrp} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setGrpList={setGrpList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
                        })}
                      </div>
                    </div>}
                  </div>
                </fieldset>
              </div>

                <div>
                  <div className='flex flex-row gap-4 mt-2 text-xs'>
                    <label className='text-xs mt-1.5'>Icon<span className=' text-red-500'>*</span></label>
                       <div className="flex flex-row">
                       <button type="button" required className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-3.5' style={selectedIcon !== '' ? { width: "6.9rem" } : { width: "4.9rem" }} onClick={(event) => {event.stopPropagation(); setIsPickerOpen(!isPickerOpen)}}><b>{selectedIcon !== '' ? 'Change Icon' : 'Pick Icon'}</b></button> 
                       {selectedIcon && <>
                        
                            <div className="selected-icon ml-4" onClick={() => {console.log(isPickerOpen)}}>
                            {React.createElement(FaIcons[selectedIcon])}
                            </div> </> }
                        
                        {isPickerOpen === true && (
                            
                            <div ref={iconPickerRef} className="popup-container">
                            <IconPicker onSelect={handleSelectIcon} setIsPickerOpen={setIsPickerOpen} setErrorMessage={setErrorMessage} />
                            </div>
                    
                        )
                        }
                        
                        </div>  
                  </div>
                </div>

                <div>
                  <div className='flex flex-row gap-4 mt-4 text-xs'>
                    <label className='text-xs mt-1.5'>Color<span className=' text-red-500'>*</span></label>
                    <button type="button" className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-2' style={{ width: "6.9rem", height: "34px" }} onClick={(event) => {event.stopPropagation(); setOpenColorPicker(!openColorPicker)}}><b>Select Color</b></button> 
                    {openColorPicker && <div ref={colorPickerRef}><ColorPicker color={colorCode} setColor={setColorCode} setOpenColorPicker={setOpenColorPicker} setErrorMessage={setErrorMessageColor}/></div> }
                    {colorCode !== '' &&   <div className="selected-color" style={{ backgroundColor: colorCode, width: '35px', height: '35px', borderRadius: '30%' }}/>}
                  </div>
                </div>
 
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setEditStatus(false); setIsPickerOpen(false); setSelectedIcon(''); setColorCode(''); setName(''); /* setSelectedGrp(''); */}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}
        
        {deleteStatus && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={(e)=>{handleDeleteStatus(e);}}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Delete Category</div>
                <button className='my-2 title-text-small-color' onClick={()=>{setDeleteStatus(false)}}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Are you sure you want to delete category “<b>{name}</b>“? <br/>
                This action cannot be undone.</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={()=> {setDeleteStatus(false);}}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs'>Delete Category</button>
            </div>
          </form>
        </div>}

 </div>
        </>
    )

}

export default IssueCategory;