import React from 'react';
import { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contextApi/context.js';
import NewGroupPopup from '../groupPopup/NewGroupPopup.js';
import EditGroupPopup from '../groupPopup/EditGroupPopup.js';
import TableMembers from './TableMembers.js';
import TableTask from './TableTask.js';
import IssueStatus from './IssueStatus.js';
import IssueCategory from './IssueCategory.js';
import IssueRelations from '../components/IssueRelations.js';
import axios from 'axios';
import * as FaIcons from 'react-icons/fa';
import "../css/style.css";
import { create } from 'lodash';

const serverConfig = require("../../config/server.js");

const TableProjects = ({token, selectedOrganization, handleLogout, newGroupPopup, setPopup, handleCloseAlertModal, handleShowAlertModal}) => {

  const {isDarkTheme} = useContext(Context);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  //const { tab, setTab } = useContext(Context);
  const [tab, setTab] = useState("Projects");
  const [addGroup, setAddGroup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterGroupType, setFilterGroupType] = useState([]);
  const [selectedFilterGroupType, setSelectedFilterGroupType] = useState("");
  const [viewPrivilege, setViewPrivilege] = useState(false);
  const [refreshGroup, setRefreshGroup] = useState(false);
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [GroupCreatePrivilege, setGroupCreatePrivilege] = useState(false);


  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;

  const dropdownItems = ['Updated', 'Option 2', 'Option 3']
  const sortingIcons = [sortDown, sortUp]
  const columns = [
    {
      name: '',
      cell: (row) => <div className="flex-column flex items-center py-3">
        <img src={row?.logo_base64 ? row?.logo_base64 : `https://ui-avatars.com/api/?name=${row.name}
                    &length=1
                    &background=${row.default_color.replace('#','')}&format=svg&color=fff`} alt='image'
          height={40} width={40} className='rounded-lg' data-tag="allowRowEvents" />
      </div>,
      grow: 0,
      sortable: false,
    },
    {
      selector: row => row.name,
      name: <div className='row-label'>Name</div>,
      cell: (row) => <div className="flex-column  py-2 pl-3">
        <div className='text-heading' data-tag="allowRowEvents">{row.name}</div>
        {/* <div className='text-detail pt-1'>{row.detail}</div> */}
      </div>,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='row-label pl-2'>Type</div>,
      selector: row => row.type_details.type,
      cell: (row) => {
        let typeStyle;
        if(isDarkTheme){
          typeStyle={
            borderColor: row.type_details.defaultBorderColorDarkTheme,
            background: row.type_details.defaultBgColorDarkTheme+'20',
            color: row.type_details.defaultColorDarkTheme
          }
        } else {
          typeStyle={
            borderColor: row.type_details.defaultBorderColorLightTheme,
            background: row.type_details.defaultBgColorLightTheme+'20',
            color: row.type_details.defaultColorLightTheme
          }
        }
        return(<div className="type" style={typeStyle} data-tag="allowRowEvents">
          <div><span data-tag="allowRowEvents">{React.createElement(FaIcons[row?.type_details?.defaultIconClass])}</span></div><div data-tag="allowRowEvents">{row.type_details.type}</div>
        </div>)},
      sortable: true,
      wrap: true
    },
    {
      name: '',
      cell: (row) => <div className="flex-column  py-2 pl-3">
        <div data-tag="allowRowEvents">
          {row?.sprint_attributes?.start_date && <>
            {new Date(row?.sprint_attributes?.start_date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })} &ndash; {new Date(row?.sprint_attributes?.end_date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })}
          </>}
        </div>
      </div>,
      sortable: false,
      wrap: true,
    },
    {
      name: '',
      cell: (row) => <div className='flex align-middle justify-center text-center' /* data-tag="allowRowEvents" */>
        {row.type_details.type !== "Sprint" && <div className='p-2 not-0 relative' /* data-tag="allowRowEvents" */ onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button type='button' disabled={row.count_details.subgroups === 0}  onClick={() => navigate(`/planner/sprints?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Subgroups", type_details:row.type_details}})}>
            <i className="fa-regular fa-folder" /* data-tag="allowRowEvents" */></i> <span>{row.count_details.subgroups}</span>
            <div className="tooltip tooltip-top hidden">Sprints</div>
          </button>
        </div>}
        <div className='p-2 not-0 relative' /* data-tag="allowRowEvents" */ onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button type='button' disabled={row.count_details.members === 0} onClick={() => navigate(row.type_details.type !== "Sprint" ? `/planner/sprints?${row.grp}` : `/planner/sprints/sprint?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Members", type_details:row.type_details}})}>
            <i className="fa-solid fa-user-group" /* data-tag="allowRowEvents" */></i> <span>{row.count_details.members}</span>
            <div className="tooltip tooltip-top hidden">Members</div>
          </button>
        </div>
        <div className='ml-8 p-2 not-0 relative' /* data-tag="allowRowEvents" */ onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button type='button' disabled={row.count_details.openTasks === 0}  onClick={() => navigate(row.type_details.type !== "Sprint" ? `/planner/sprints?${row.grp}` : `/planner/sprints/sprint?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Tasks", type_details:row.type_details}})}>
            <i className="fa-solid fa-list-check" /* data-tag="allowRowEvents" */></i> <span>{row.count_details.openTasks}</span>
            <div className="tooltip tooltip-top hidden">Open Tasks</div>
          </button>
        </div>
      </div>,
      // selector: row =>,
      sortable: false,
      right: true,
      grow: 2,
      wrap: true,
      ignoreRowClick: true,
    },
    {
      cell: (row) => <div className="text-right more-options-wrapper">
        <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
        <div className="more-options" style={{display: "none"}}>
          <button type="button" onClick={() => handleShowEditGroupModal(row)}><i className="fa-solid fa-pencil" aria-hidden="true"></i> <span>Edit Project</span></button>
          <button type="button" /* onClick={() => handleDeleteColumn(items.seq_no, items.colm)} */><i className="fa-solid fa-ban"></i> <span>Disable Project</span></button>
          <button type="button" /* onClick={() => handleDeleteColumn(items.seq_no, items.colm)} */><i className="fa-solid fa-box-archive"></i> <span>Archive Project</span></button>
        </div>
      </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleShowTooltip = (event) => {
    event.target.closest('.relative').querySelector('.tooltip').style.display='block';
  }
  const handleHideTooltip = (event) => {
    event.target.closest('.relative').querySelector('.tooltip').style.display='none';
  }

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      setIsLoading(true);

      console.log('selectedOrganization', selectedOrganization)

      axios.post(serverConfig.api_base_url + "group_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('group_list_get response', response);
        if(response.data.status === 200){
          setViewPrivilege(true);
          if(response.data.items !== null){
            console.log("tableData",response.data.items);
            setTableData(response.data.items.filter(item => item.type_details.type.toLowerCase() === "project"));
            setFilteredTableData(response.data.items.filter(item => item.type_details.type.toLowerCase() === "project"));
          } else {
            setTableData([]);
          }
          setIsLoading(false);
        } else if(response.data.status === 301){
          handleLogout();
        } else if(response.data.status === 401){
          setViewPrivilege(false);
          setIsLoading(false);
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));

      /*get group types*/
      axios.post(serverConfig.api_base_url + "planner_get_group_type", {token:token, org:selectedOrganization?.slug})
      .then(response => {
        if(response.data.status === 200) {
          let items = response.data.items;
          if(items !== null && items !== undefined) {
            setFilterGroupType(items.group_types);
          }
        }
      }).catch(err => console.log("error in api planner_get_group_type", err));

      // check user privilege for group create
      axios.post(serverConfig.api_base_url + "user_check_privilege", {token:token, org:selectedOrganization?.slug, privilege:"group_create"})
      .then(response => {
        if(response.data.status === 200 && response.data.message === "success" && response.data.is_privileged === 1) {
          setGroupCreatePrivilege(true);
        }
      }
      ).catch(err => console.log("error in api while calling user_check_privilege", err));


      // /* user_get_list */
      // axios.post(serverConfig.api_base_url + "user_get_list",{token:token, org:selectedOrganization?.slug})
      // .then(response=>{
      //   console.log("user_get_list response:", response);
      //   if(response.data.status === 200){
      //     let items = response.data.items;
      //     if (items !== null && items !== undefined){
      //       console.log(items);
      //       items.reverse().map((item, index) => {
      //         let curRole = [];
      //         curRole = item.roles;
      //       });
      //       setUserList(items);
      //     }
      //   } else if(response.data.status === 301){
      //     handleLogout();
      //   } else {
      //     handleShowAlertModal(response.data.message, true);
      //     setTimeout(() => {
      //       handleCloseAlertModal();
      //     }, 6000);
      //   }
      // })
      // .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization, refreshGroup]);

  useEffect(() => {
    if(newGroupPopup) {
      setAddGroup(true);
    }
  }, [newGroupPopup]);

  useEffect(() => {
    console.log(addGroup);
    console.log(newGroupPopup);
    if(addGroup) {
      //setAddGroup(false);
    } else {
      setAddGroup(false);
      setPopup(false);
    }
  }, [addGroup]);


  
  // get the user list if it's members tab
  useEffect(() => {
    if(tab === "Members") {
      let isMounted = true;
      /* user_get_list */
      axios.post(serverConfig.api_base_url + "user_get_list",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("user_get_list response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null && items !== undefined){
            console.log(items);
            items.reverse().map((item, index) => {
              let curRole = [];
              curRole = item.roles;
            });
            setUserList(items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));

      // clean up function
      return () => {isMounted = false};
    }
  }, [tab]);


  const refreshGroupTable = () => {
    setRefreshGroup(!refreshGroup);
  }

  const handleShowMoreOptions = (event) => {
    const moreOptionsWrapper = event.target.closest('.more-options-wrapper');
    const moreOptions = moreOptionsWrapper.querySelector('.more-options');
  
    // Get the bounding rectangle of the more-options-wrapper and the container
    const moreOptionsRect = moreOptionsWrapper.getBoundingClientRect();
    const containerRect = moreOptionsWrapper.closest('.table-custom').getBoundingClientRect();
  
    // Check if the bottom of the more-options-wrapper is near the bottom of the container
    const isNearBottom = moreOptionsRect.bottom + 190 > containerRect.bottom;
  
    // Set the position of the more-options menu
    if (isNearBottom) {
      moreOptions.style.bottom = '100%'; // Open upwards
      moreOptions.style.top = 'auto';
    } else {
      moreOptions.style.top = '100%'; // Open downwards
      moreOptions.style.bottom = 'auto';
    }
  
    // Toggle visibility of the more-options menu
    if (moreOptions.style.display === 'none' || moreOptions.style.display === '') {
      document.querySelectorAll('.more-options-wrapper .more-options').forEach(el => el.style.display = 'none');
      moreOptions.style.display = 'block';
    } else {
      moreOptions.style.display = 'none';
    }
  }
  

  //Edit
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const handleShowEditGroupModal = (details) => {
    setFormData(details);
    setShowEditGroupModal(true);
    
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const [newTaskPopup, setNewTaskPopup] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  
  const handleFilter = (filterItem) => {
    setSelectedFilterGroupType(filterItem);
    if (filterItem.toLowerCase() === "all") {
      setFilteredTableData(tableData);
      setIsOpen(!isOpen);
    }
    else {
    const fitleredData = tableData.filter(item => item.type_details.type.toLowerCase() === filterItem.toLowerCase());
        if (fitleredData.length !== 0) {
          setFilteredTableData(fitleredData);
          setIsOpen(!isOpen);
        }
        else {
          setFilteredTableData(tableData);
          setIsOpen(!isOpen);
        }
    
      }

  }


  return (
    <div className="">
      {viewPrivilege && <div className="rounded-3xl p-3 table-custom mt-4 relative flex flex-col">
        {isLoading && <div className='loading absolute'><i className="fa-solid fa-spinner fa-spin-pulse"></i></div>}
        
        {/* Left side: Three tabs flexed together */}
        <div className="flex space-x-4 z-1">
          <button className={`px-6 py-3 cursor-pointer ${tab === "Projects" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Projects")}>Projects</button>
          {/* <div className={`px-6 py-3 cursor-pointer ${tab === "Finances" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Finances")}>Finances</div> */}
          {GroupCreatePrivilege && <div className={`px-6 py-3 cursor-pointer ${tab === "Settings" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Settings")}>Settings</div>}
          <div className={`px-6 py-3 cursor-pointer ${tab === "Members" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Members")}>Members</div>
          {/* <div className={`px-6 py-3 cursor-pointer ${tab === "Resources" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Resources")}>Resources</div> */}
          <div className={`px-6 py-3 cursor-pointer ${tab === "Tasks" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Tasks")}>Tasks</div>
        </div>

        {tab === "Projects" && <>
          {filteredTableData.length > 0 ? <DataTable className='z-2'
            title={<div className="table-title rounded">
              <div className="mx-auto flex justify-between items-center">
                

                <div className='task px-1'>Projects Overview</div>

                <div className="relative flex justify-between items-center">
                {/* Right side: Search input and two dropdowns */}

                <div className="flex items-center space-x-4 text-black">
                  {/* <div className="flex items-center gap-12 mt-4">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search"
                        className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
                      />
                      <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                        <i className=" text-sm fas fa-search"></i>
                      </div>
                    </div>
                  </div>

                  <div className="relative inline-block">
                    <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
                      <div className='pr-5'>
                        <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <i className="fa-solid fa-chevron-down text-sm"></i>
                    </div>
                  </div> */}
                  {GroupCreatePrivilege && <button className='sub-grp px-3 py-2' onClick={() => { setAddGroup(true) }} >New Project</button>}
                  
                </div>
                </div>
              </div>
            </div>}
            columns={columns}
            data={filteredTableData}
            responsive={true}
            pagination={filteredTableData.length > 10 ? true : false}
            highlightOnHover
            pointerOnHover
            onRowClicked={(row) => {
              if(row.type_details.type === "Sprint"){
                navigate(`/planner/sprints/sprint?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, count_details:row.count_details, type_details:row.type_details}})
              } else {
                navigate(`/planner/sprints?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, count_details:row.count_details, type_details:row.type_details}})
              }
            }}
            customStyles={{
              rows: {
                highlightOnHoverStyle: {
                  color: isDarkTheme ? '#ffffff' : '#000000',
                  backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                  borderRadius: '14px',
                  outline: 'none'
                }
              }
            }}
          /> : <div className='flex flex-col h-full items-center justify-center'>
            <div className='mb-8'><img src={require('./../images/no-groups-subgroups.png')} alt="No groups and subgroups" /></div>
            <p><b>This Organisation has no Projects and Sprints</b></p>
            <p className='text-sm'>Create first Project for {selectedOrganization?.name}</p>
            <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={() => { setAddGroup(true) }} >New Project</button></div>
          </div>}
        </>}

        {(tab === "Finances" || tab === "Resources") && <div className='flex flex-col items-center justify-center flex-auto'>
          <img src={require('./../../Asset/Images/comingsoon.jpg')} width="400" className='under-dev-img' style={{width:"400px", height:"auto"}} />
          <h3 class="under-dev"><b>This feature is under development.</b></h3>
        </div>}
        
        {tab === "Members" && userList?.length > 0 &&
          <TableMembers userList={userList} token={token} handleLogout={handleLogout} selectedOrganization={selectedOrganization} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} isPlanner={true} />
        }

        {tab === "Tasks" &&
          <div><TableTask token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} newTaskPopup={newTaskPopup} setPopup={setNewTaskPopup} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} isPlanner={true} /></div>
        }

        {tab === "Settings" &&
           <div className='flex flex-col table-container'>
      
           <IssueStatus token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} isPlanner={true} />
           
           <IssueCategory token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} isPlanner={true} />
     
           {/* <IssueRelations token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} /> */}
     
           </div>
        }

        {addGroup === true ? <div className='overlay'><NewGroupPopup setNewGroupPopup={setAddGroup} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} refreshGroupTable={refreshGroupTable} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} userList={userList} isPlanner={true} /></div> : <></>}
      </div>}

      {showEditGroupModal ? <div className='overlay'><EditGroupPopup setEditGroupPopup={setShowEditGroupModal} details={formData} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} refreshGroupTable={refreshGroupTable} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} userList={userList} isPlanner={true} /></div> : <></>}
    </div>
  );
}

export default TableProjects;