import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../contextApi/context';
import Search from './Search.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const serverConfig = require("./../../config/server.js");

function Checklist({ grpSlug, token, kyr, selectedOrganization, handleShowAlertModal, formData, setFormData, handleCloseAlertModal}) {
  const navigate = useNavigate();
  const{setActiveOption} = useContext(Context);
  const [popup, setPopup] = useState(false)
  const [checklistData, setChecklistData] = useState([]);
  const [name, setName] = useState([]);
  const [description, setDescription] = useState('');
  const [desc, setDesc] = useState('');
  //const [date, setDueDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date().toISOString().substring(0, 10));
  //const [name, setName] = useState([]);
  const [names, setNames] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [addingNameIndex, setAddingNameIndex] = useState(0);
  const [hours, setHours] = useState(6);
  const [minutes, setMinutes] = useState('00');
  const [assigneesPopup, setAssigneesPopup] = useState(false);
  const [assigneesPopupTwo, setAssigneesPopupTwo] = useState(false);

  const [searchList, setSearchList] = useState([]);
  const [checklistOwner, setChecklistOwner] = useState();
  const [srchList, setSrchList] = useState([]);
  const [checklistMemberList, setChecklistMemberList] = useState([]);
  const [checklistMemberSlugList, setChecklistMemberSlugList] = useState([]);
  const [checklistMemberSlug, setChecklistMemberSlug] = useState();
  const [checklist, setChecklist] = useState([]);

  useEffect(() => {
    // if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
    //   getSubtaskList();
    // }
    getKeyResultList();
  }, [token, selectedOrganization]);

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      axios.post(serverConfig.api_base_url + "user_get_list",{token: token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('user_get_list response', response);
        console.log('user_get_list response items',response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setSearchList(response.data.items);
            setSrchList(response.data.items);
          } else {
            setSearchList([]);
            setSrchList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          setSearchList([]);
          setSrchList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    console.log(checklistData);
    //checklistData.forEach(element => element.checklistMemberList = []);
  }, [token, selectedOrganization, grpSlug]);

  const getKeyResultList = () => {
    axios.post(serverConfig.api_base_url + "okr_key_result_checklist_list_get",{token: token, org:selectedOrganization?.slug, kyr: kyr, })
    .then(response=>{
      console.log('subtask_list_get response', response);
      console.log('subtask_list_get response items',response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setChecklistData(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        setChecklistData([]);
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  function getMonthName(month) {
    const monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"
    ];
    return monthNames[month];
  }
  const handleUpdateSubtask = (data, event) => {
    console.log(event.target.name);
    console.log(checklistData);

    console.log(formData, 'data for checklist');
    // let formData;
    // if(event.target.name === "status"){
    //   formData = [{...data, status: event.target.checked ? "Closed" : "To Do"}];
    // } else if(event.target.name === "dueDate"){
    //   formData = [{...data, dueDate: event.target.value}];
    // } else {//description
    //   console.log(desc)
    //   formData = [{...data, description: desc}];
    // }
 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      if(name !== null && name !== undefined && name !== ''){
   
      const newData = {
        name,
        description,
        due_date : dueDate,
        assignee: checklistMemberSlug,
        ownerChecklist: checklistOwner
      };

      setChecklist([...checklist, newData]);

      setFormData({...formData, checklistDetails: [...checklist, newData]});

      console.log([...checklist, newData], 'checklistDetails');
      setName('');
      setDescription('');
      setDueDate('');
      setChecklistMemberSlug('');
      setChecklistMemberList([]);
      setAssigneesPopup(false);
      setPopup(false);
      
    }
  };

  /* const handleAddName = () => {

    if (name) {
      setNames([...names, name]);
      setName('');
    }
  }; */
  const handleAddNametwo = (index, members) => {
    // Set the index of the data set for which we're adding a name
    console.log(index, members)

    setAddingNameIndex(index);
    setAssigneesPopupTwo(!assigneesPopupTwo);
    setChecklistMemberList([]);
    let arr = [];
    if(members){
      members.forEach(el => {
        arr.push(el.usr);
      });
      console.log(arr);
    }
    setChecklistMemberSlugList(arr);
  };

  const[editDescIndex, setEditDescIndex] = useState("");
  const[showDescTextarea, setShowDescTextarea] = useState(false);
  const handleEnableTextarea = (index) => {
    // Set the index of the data set for which we're adding a name
    console.log(index)
    setEditDescIndex("");
    setEditDescIndex(index);
    setShowDescTextarea(true);

  };

  const handleAddMember = (data) => {
    console.log(data);
    setChecklistMemberSlug(data?.slug);
    setChecklistOwner(data?.name);
    setChecklistMemberList([...checklistMemberList, {username: data.name, icon: data.icon, usr: data.slug}]);
    setChecklistMemberSlugList([...checklistMemberSlugList, data.slug]);
  }

  const handleRemoveMember = (userSlug) => {
    setChecklistMemberList(checklistMemberList.filter(el => el.usr !== userSlug));
    setChecklistMemberSlugList(checklistMemberSlugList.filter(el => el !== userSlug));
    console.log(checklistMemberSlugList);
  }

  const handleAddMemberTwo = (data, dataSrch) => {
    console.log(data, dataSrch);
    //setChecklistMemberSlugList([...checklistMemberSlugList, dataSrch.slug]);
    //setChecklistMemberList([{username: dataSrch.name, icon: dataSrch.icon, usr: dataSrch.slug}]);
    console.log(checklistMemberSlugList);
    let formData = {...data, checklistMemberList: [{usr: [...checklistMemberSlugList, dataSrch.slug]}]};
    console.log(formData);
    axios.post(serverConfig.api_base_url + "subtask_update", {token:token, org:selectedOrganization?.slug, sbtsk:data.sbtsk, items:formData})
    .then(response=>{
      console.log('subtask_update response', response);
      if(response.data.status === 200){
        console.log("success");
        getSubtaskList();
        //getAssigneeList();
        setChecklistMemberSlugList([...checklistMemberSlugList, dataSrch.slug]);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleRemoveMemberTwo = (data, userSlug) => {
    //setChecklistMemberList(checklistMemberList.filter(el => el.usr !== userSlug));
    //setChecklistMemberSlugList(checklistMemberSlugList.filter(el => el !== userSlug));
    //console.log(checklistMemberSlugList);
    console.log(checklistMemberSlugList);
    let formData = {...data, checklistMemberList: [{usr: checklistMemberSlugList.filter(el => el !== userSlug)}]};
    console.log(formData);
    axios.post(serverConfig.api_base_url + "subtask_update", {token:token, org:selectedOrganization?.slug, sbtsk:data.sbtsk, items:formData})
    .then(response=>{
      console.log('subtask_update response', response);
      if(response.data.status === 200){
        console.log("success");
        getSubtaskList();
        //getAssigneeList();
        setChecklistMemberSlugList(checklistMemberSlugList.filter(el => el !== userSlug));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <div className='mt-7'>
      <div className='flex items-center justify-start '>
        <label>Checklists</label>
        <label className="cursor-pointer">
          <button type="button" className="font-medium inline-block text-gray-500 text-lg mx-2" onClick={() => { setPopup(!popup) }}>
            <i className="fa-solid fa-plus font-normal"></i>
          </button>
        </label>
      </div>


      {/* popup form to add new information is here */}
      {popup &&
        <div className='popup p-4 bg-gray-300 mt-3 relative'>
          {/* <button type="button" className='absolute top-2 right-2 text-gray-400' onClick={() => { setPopup(false) }}>
            <i className="fa-solid fa-xmark icon-color"></i>
          </button> */}
          <div >
            <div className='text-sm px-1 text-custom'>Name:<span className='text-red-500'>*</span></div>
            <input type="text" className='w-full p-2 rounded-lg link-txt mt-1 text-xs' value={name} onChange={(e) => setName(e.target.value)} required/>
          </div>
          <div >
            <div className='text-sm px-1 text-custom'>Description:</div>
            <textarea type="text" className='w-full p-2 rounded-lg link-txt mt-1 text-xs' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>

          <div className='relative mt-4'>
            
            <div className='text-sm px-1 text-custom'>Due Date:<span className='text-red-500'>*</span></div>
            <input type="date" className='w-full rounded-lg link-txt mt-1 text-xs' min={new Date().toISOString().substring(0, 10)} value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
          </div>

          <div className='relative mt-4'>
            {searchList.length === 0 && <><span className="text-red-500 text-xs">Currently there are no Members. Please create a group <span className='underline cursor-pointer' onClick={() => {navigate("/groups"); setActiveOption('Groups');}}>here</span> first</span><br/></>}
            <button type="button" className=' text-sm px-1 mt-1 text-custom' onClick={() => { setAssigneesPopup(!assigneesPopup); setChecklistMemberSlugList([]); setChecklistMemberList([]); }}>
              Add Assignee: <i className="fa-solid fa-user-plus"></i>
            </button>
            {/* {assigneesPopup && <Assignees handleAddName={handleAddName} setName={setName} name={name} names={names} setNames={setNames} />} */}
            {assigneesPopup && <div className='organisationDropdownCard mt-2' style={{width: '100%', left: 0, position: 'unset'}}>
              <div className='px-2 mt-4 w-full'><Search srchList={srchList} setSearchList={setSearchList} /></div>
              <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{borderBottom: 'none'}}>
                {searchList.length > 0 && <ul className='px-1 text-sm'>
                  {searchList.map((data, index)=>{
                    return(<li key={index} className={index === searchList.length-1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => {checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? handleRemoveMember(data.slug) : handleAddMember(data); setAssigneesPopup(false);}} title={checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? `Remove ${data.name}` : `Add ${data.name}`}>
                      <div>{!data.icon ? <img src={`https://ui-avatars.com/api/?name=${data?.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt={data?.name} className='rounded-full' width="40" /> : <img src={data.icon} alt={data?.name} className='rounded-full' width="40" />}</div>
                      <div className="ml-4">{data.name}</div>
                      {checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
                    </li>)
                  })}
                </ul>}
              </div>
            </div>}

            {checklistMemberList.length > 0 && <div className='flex assignee-list mt-3'>
              {checklistMemberList.map((item, index) => {
                return(<div>{!item.icon ? <img src={`https://ui-avatars.com/api/?name=${item?.username} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt={item?.username} className='rounded-full' width="40" /> : <img src={item.icon} alt={item?.username} className='rounded-full' width="40" />}</div>)
              })}  
            </div>}
           
    
          </div>

          <div className='mt-3'>
            <button type='button' className='cancel-btn-popup px-4 py-2 rounded-lg text-sm font-medium' onClick={() => { setPopup(false); setName(''); setDescription(''); setDueDate(new Date().toISOString().substring(0, 10)); setChecklistMemberList([]); setChecklistMemberSlugList([]); setAssigneesPopup(false);}}>Cancel</button>
            <button type="button" disabled={searchList.length === 0 ? true : false} className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleSubmit}>Add</button>
          </div>
        </div>
      }
      {/* codes to display already existing Checklist */}
      <div className='mr-10 mt-3 relative'>

        {checklist.length > 0 && checklist.map((data, index) => {
          let todayDate=new Date().toISOString().split('T')[0];
          return(
            <div key={index} className="flex items-start justify-between gap-5 sidebar-data-todo m-1 py-3 px-2 relative">
              <div className='flex justify-start gap-2 items-start'>
                {/* slelect/unselect toggle button÷ */}
                <div>
                  <input
                    type="checkbox"
                    name="status"
                    defaultChecked={data.status === 'Closed'}
                    onChange={(e) => handleUpdateSubtask(data, e)}
                    className={data.status === 'Closed' ? 'h-4 w-4 bg-btn-teal rounded-sm flex items-center justify-center text-xs mt-0'
                      : 'rounded-sm border-btn-select h-4 w-4 flex items-center justify-center bg-white text-xs mt-0'}
                  />
                </div>
                {/* slelect/unselect toggle button÷ */}

                {/* description of the task */}
                <div className={`ml-2 ${data.status === 'Closed' ? 'flex gap-1 flex-grow line-through sidebar-txt ' : 'flex gap-1 flex-grow sidebar-txt '}`}>
                  <b>{data.name}:</b> 
                  {editDescIndex === index && showDescTextarea ? <div className='flex gap-2'>
                    <textarea name="description" defaultValue={data.description || desc} onChange={(e) => setDesc(e.target.value)}></textarea>
                    <button type='button' name="desc" title="Save" onClick={(e) => handleUpdateSubtask(data, e)}><i className="fa-regular fa-square-check text-sm"></i></button>
                    <button type='button' title="Cancel" onClick={() => {setDesc(""); setEditDescIndex(""); setShowDescTextarea(false);}}><i className="fa-regular fa-rectangle-xmark text-sm"></i></button>
                  </div> : <>{data.description}<button type='button' onClick={() => handleEnableTextarea(index)}><i className="fa-solid fa-pencil text-xs icon-color mx-1"></i></button></>}
                </div>
                {/* description of the task */}
              </div>

              <div className='flex items-center justify-end gap-2'>
                {/* display date selected start */}
                <div className={data.due_date === 'Today' ? 'dueto-today relative' : (data.due_date < todayDate ? 'dueto-past relative' : "sidebar-txt relative")}>
                  <div className='table-text-dark'>
                    {data.due_date && data.due_date === 'Today' ? 'Today' : (<span>
                    {getMonthName(new Date(data.due_date).getMonth())} {new Date(data.due_date).getDate()}
                    </span>)} {(data.due_date === 'Today' || data.due_date < todayDate) && <i className="fa-solid fa-triangle-exclamation ml-1"></i>}
                    <input type="date" name="dueDate" className='date-absolute' /* min={new Date().toISOString().substring(0, 10)} */ value={data.due_date
                    
                    
                    
                    } onChange={(e) => handleUpdateSubtask(data, e)} />
                  </div>
                </div>
                {/* <div className='sidebar-txt relative'>
                  <input type="date" name="dueDate" className='date-absolute' min={new Date().toISOString().substring(0, 10)} value={data.dueDate} onChange={(e) => handleUpdateSubtask(data, e)} />
                  {data.dueDate && (<span>
                    {getMonthName(new Date(data.dueDate).getMonth())} {new Date(data.dueDate).getDate()}
                  </span>)}
                </div> */}
                {/* display date selected end */}

                {/* display names/images of assignees start */}
                <p className='sidebar-txt'>
                  <div className='flex assignee-list'>
                    <div className='flex assignee-list'>
                     
                      <div> <img src={`https://ui-avatars.com/api/?name=${data?.ownerChecklist} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt={data?.name} className='rounded-full' width="20" /></div>
                    
                    </div>
                  </div>
                </p>

                {/* <button
                  type="button"
                  className="text-blue-500"
                  onClick={() => handleAddNametwo(index, data.checklistMemberList)}
                ><i className="fa-solid fa-user-plus text-custom text-xs"></i></button> */}
                {/* <div>
              {data.names.map((name, nameIndex) => (
                <p key={nameIndex}> {name}</p>
              ))}
            </div>  */}

                {/* <div>
                  <input
                    type="text"
                    placeholder="Enter a name"
                    value={name}
                    onChange={handleNameInputChange}
                    onBlur={handleNameInputBlur}
                    className='rounded-lg px-2 py-2 text-xs input-custom'
                  />
                </div> */}

                

                {/* display names/images of assignees end */}
              </div>

              {index === addingNameIndex && assigneesPopupTwo && <>
                {/* <AssigneesTwo handleNameInputBlur={handleNameInputBlur}
                  handleNameInputChange={handleNameInputChange}
                  name={name} addingNameIndex={addingNameIndex}
                  lastIndex={checklistData.length}
                /> */}
                <div className='organisationDropdownCard mt-2' style={{width: '100%', left: 0, top: '30px'}}>
                  <div className='px-2 mt-4 w-full'><Search srchList={srchList} setSearchList={setSearchList} /></div>
                  <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{borderBottom: 'none'}}>
                    {searchList.length > 0 && <ul className='px-1 text-sm'>
                      {searchList.map((dataSrch, index)=>{
                        return(<li key={index} className={index === searchList.length-1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => data.checklistMemberList && data.checklistMemberList.length > 0 && data.checklistMemberList.find(el => el.usr === dataSrch.slug) ? handleRemoveMemberTwo(data, dataSrch.slug) : handleAddMemberTwo(data, dataSrch)} title={data.checklistMemberList && data.checklistMemberList.length > 0 && data.checklistMemberList.find(el => el.usr === dataSrch.slug) ? `Remove ${dataSrch.name}` : `Add ${dataSrch.name}`}>
                          <div>{!dataSrch.icon ? <img src={`https://ui-avatars.com/api/?name=${dataSrch?.name} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt={dataSrch?.name} className='rounded-full' width="40" /> : <img src={dataSrch.icon} alt={dataSrch?.name} className='rounded-full' width="40" />}</div>
                          <div className="ml-4">{dataSrch.name}</div>
                          {data.checklistMemberList && data.checklistMemberList.length > 0 && data.checklistMemberList.find(el => el.usr === dataSrch.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
                        </li>)
                      })}
                    </ul>}
                  </div>
                </div>
              </>}
            </div>
          )
        })}
      </div>
    </div>

  )
}

export default Checklist