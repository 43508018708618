import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../contextApi/context';
import axios from 'axios';

const serverConfig = require("../../config/server.js");

function Popup({ isOpen, viewLogRowClicked, row, onClose, logo, access, member, context, access_type, justification, slug }) {

  const {selectedOrganization} = useContext(Context);
  const [token, setToken] = useState(null);
  const [logList, setLogList] = useState([]);

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const viewLog = () =>{
    axios.post(serverConfig.api_base_url + "access_view_log" , {
      token : token,
      org : selectedOrganization?.slug,
      reqSlug : slug
    }).then(response=>{
      console.log(response.data.items, 'access_view_log response');
      setLogList(response.data.items);
    }).catch(err=>{
      console.log(err, 'error');
    })
  }

  useEffect(()=>{

    if(token !== null && token !== undefined) {

    viewLog();

    }

  }, [token, isOpen])

  return (
    <div className={`popup-content ${viewLogRowClicked === row ? 'open' : ''}`} 
    >
       
      <div className="overlay-access" onClick={onClose}></div>
      <div className="content-view-log">
      <div className='flex flex-row justify-between'>
      <h1 className='font-bold text-lg'>View Log</h1>
      <div   className='ml-16 text-base'>
      <i className="fa-solid fa-xmark " style={{"lineHeight" : "0 rem !important", "cursor" : "pointer"}} onClick={onClose}></i>
      </div>

      </div>

      <div className="flex access-title-box m-1 mt-8 p-4">

      <div className="flex items-center py-2 gap-3">
              <div>
                <img src={logo} className='rounded-full w-12 h-12 object-cover p-1'/>
              </div>
              <div className='flex flex-col'>
              <div className="text-heading text-custom dark-heading">{access}</div>
              <div className='font-semibold text-xs dark-heading'>access to {member}</div>
              </div>
        </div>

      </div>

<div className='log-section'>
      {logList?.length > 0 &&
      <>

      {logList.map((item) => {

        return (
          <>

      { ((item?.status === 'Approved' || item?.status === 'Pending Approval') && row?.status !== 'Denied') &&
      <>
      <div className='flex flex-row justify-between'>
      <div className="flex items-center py-2 gap-3 mt-4">
              <div>
                <img src={require('./../assets/img-requested.png')} className='rounded-xs w-12 h-12 object-cover p-1'/>
              </div>
              <div className='flex flex-col'>
              <div className="font-semibold text-sm ml-1">Requested</div>
              
              </div>
        </div>

        <div className='flex items-center text-xs mt-4 py-2'>
          {item?.stamp}
        </div>

        </div>

        <div className='flex flex-col ml-16'>
        <div className='font-semibold text-xs'>{member} requested access</div>
        <div className='flex flex-row gap-2 mt-2'>
            <div className='font-semibold text-xs'>Context:</div>
            <div className='font-normal text-xs ml-4'>{context}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Access Type:</div>
            <div className='font-normal text-xs ml-4'>{access_type}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Justification:</div>
            <div className='font-normal text-xs ml-4'>{justification}</div>
        </div>

        <div className='flex flex-row gap-2 mt-3'>
            <div className='font-semibold text-xs'>{item?.status} by {item?.member}</div>
        </div>
        </div>
      </>
      }

{ (item?.status === 'Rejected') &&
      <>
      <div className='flex flex-row justify-between'>
      <div className="flex items-center py-2 gap-3 mt-4">
              <div>
                <img src={require('./../assets/img-requested.png')} className='rounded-xs w-12 h-12 object-cover p-1'/>
              </div>
              <div className='flex flex-col'>
              <div className="font-semibold text-sm ml-1">Requested</div>
              
              </div>
        </div>
         <div className='flex items-center text-xs mt-4 py-2'>
          {item?.stamp}
        </div>
        </div>

        <div className='flex flex-col ml-16'>
        <div className='font-semibold text-xs'>{member} requested access</div>
        <div className='flex flex-row gap-2 mt-2'>
            <div className='font-semibold text-xs'>Context:</div>
            <div className='font-normal text-xs ml-4'>{context}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Access Type:</div>
            <div className='font-normal text-xs ml-4'>{access_type}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Justification:</div>
            <div className='font-normal text-xs ml-4'>{justification}</div>
        </div>

        <div className='flex flex-row gap-2 mt-3'>
            <div className='font-semibold text-xs'>{item?.status} by {item?.member}</div>
        </div>
        </div>
      </>
      }


{ (item?.status === 'Pending Action' && row?.status !== 'Denied') &&
      <>
        <div className='flex flex-row justify-between'>
      <div className="flex items-center py-2 gap-3 mt-4">
              <div>
                <img src={require('./../assets/img-requested.png')} className='rounded-xs w-12 h-12 object-cover p-1'/>
              </div>
              <div className='flex flex-col'>
              <div className="font-semibold text-sm ml-1">Pending Provision</div>
              
              </div>
        </div>
        <div className='flex items-center text-xs mt-4 py-2'>
          {item?.stamp}
        </div>
        </div>

        <div className='flex flex-col ml-16'>
        <div className='font-semibold text-xs'>{member} requested access</div>
        <div className='flex flex-row gap-2 mt-2'>
            <div className='font-semibold text-xs'>Context:</div>
            <div className='font-normal text-xs ml-4'>{context}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Access Type:</div>
            <div className='font-normal text-xs ml-4'>{access_type}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Justification:</div>
            <div className='font-normal text-xs ml-4'>{justification}</div>
        </div>

        <div className='flex flex-row gap-2 mt-3'>
            <div className='font-semibold text-xs'>{item?.status} by {item?.member}</div>
        </div>
        </div>
      </>
      }


{ (item?.status === 'Completed' && row?.status !== 'Denied') &&
      <> 
        <div className='flex flex-row justify-between'>
      <div className="flex items-center py-2 gap-3 mt-4">
              <div>
                <img src={require('./../assets/img-success.png')} className='rounded-xs w-12 h-12 object-cover p-1'/>
              </div>
              <div className='flex flex-col'>
              <div className="font-semibold text-sm ml-1">Granted</div>
              
              </div>
        </div>
        <div className='flex items-center text-xs mt-4 py-2'>
          {item?.stamp}
        </div>
        </div>

        <div className='flex flex-col ml-16'>
        <div className='font-semibold text-xs'>{member} requested access</div>
        <div className='flex flex-row gap-2 mt-2'>
            <div className='font-semibold text-xs'>Context:</div>
            <div className='font-normal text-xs ml-4'>{context}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Access Type:</div>
            <div className='font-normal text-xs ml-4'>{access_type}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Justification:</div>
            <div className='font-normal text-xs ml-4'>{justification}</div>
        </div>

        <div className='flex flex-row gap-2 mt-3'>
            <div className='font-semibold text-xs'>{item?.status} by {item?.member}</div>
        </div>
        </div>

        </>}



        { (item?.status === 'Cancelled' && row?.status !== 'Denied') &&
      <>
      <div className='flex flex-row justify-between'>
      <div className="flex items-center py-2 gap-3 mt-4">
              <div>
                <img src={require('./../assets/img-cancel.png')} className='rounded-xs w-12 h-12 object-cover p-1'/>
              </div>
              <div className='flex flex-col'>
              <div className="font-semibold text-sm ml-1">Cancelled</div>
              
              </div>
        </div>

        <div className='flex items-center text-xs mt-4 py-2'>
          {item?.stamp}
        </div>
        </div>

        <div className='flex flex-col ml-16'>
        <div className='font-semibold text-xs'>{member} requested access</div>
        <div className='flex flex-row gap-2 mt-2'>
            <div className='font-semibold text-xs'>Context:</div>
            <div className='font-normal text-xs ml-4'>{context}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Access Type:</div>
            <div className='font-normal text-xs ml-4'>{access_type}</div>
        </div>
        <div className='flex flex-row gap-2 mt-1'>
            <div className='font-semibold text-xs'>Justification:</div>
            <div className='font-normal text-xs ml-4'>{justification}</div>
        </div>

        <div className='flex flex-row gap-2 mt-3'>
            <div className='font-semibold text-xs'>{item?.status} by {item?.member}</div>
        </div>
        </div>
        </>}
        </>
        )
        })}
        </> }

        </div>
        {/* <button onClick={onClose}>Close Popup</button> */}
      </div>
    </div>
  );
}

export default Popup;