import DataTable from "react-data-table-component";
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Context } from "../../contextApi/context";
import { Button } from "react-bootstrap";
import UserService from "../../services/UserService";
import Notification from "../../common/notification/Notification.js";

const serverConfig = require("../../config/server.js");

function MyActionsTable() {

    const navigate = useNavigate();
    const {selectedOrganization, setActiveSubmenuOption} = useContext(Context);
    const [reuqestAccessPopup, setRequestAccessPopup] = useState(false);
    const [myActions, setMyActions] = useState([]);
    const [token, setToken] = useState(null);
    const [refreshActionList, setRefreshActionList] = useState(false);
    const [approvalModalShow, setApprovalModalShow] = useState(false);
    const [btnText, setBtnText] = useState('');
    const [displayNone, setDisplayNone] = useState('none');
    const dropdownRef = useRef(null);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);
    const handleCloseAlertModal = () => {
      setAlertMessage("");
      setShowOkButton(false);
      setAlertModalShow(false);
    }
    const handleShowAlertModal = (alert_msg, show_ok_button) => {
      setAlertMessage(alert_msg);
      setShowOkButton(show_ok_button);
      setAlertModalShow(true);
    }

    const handleShowApprovalModal = (e, btn, row) => {
      e.preventDefault();
      setBtnText(btn);
      //setDisplayNone('none');
    }

    const handleCloseApprovalModal = () => {
      setApprovalModalShow(false);
      setDisplayNone('none');
    }

    const handleViewStatus = () => {
      navigate('/reporteeAccess');
      setActiveSubmenuOption('Team Access')
    }

    const getToken = () => {
      console.log(sessionStorage.getItem('token'));
      let jwt = UserService.getToken();
      axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
      .then(response=>{
        console.log('user_get_token response', response);
        if(response.data.status === 200){
          sessionStorage.setItem('token', response.data.token);
          setToken(response.data.token);
        } else if(response.data.status >= 400 && response.data.status <= 499){
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if(response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  
    useEffect(() => {
      console.log(sessionStorage.getItem('token'));
      if(sessionStorage.getItem('token') === null){
        getToken();
      } else {
        setToken(sessionStorage.getItem('token'));
      }
    }, []);

    useEffect(() => {

      if(token !== null && token !== undefined) {
        axios.post(serverConfig.api_base_url + "access_pending_actions_list_get", {
          token : token,
          org : selectedOrganization?.slug
        }).then(response => {
          if(response.data.status === 200) {
          setMyActions(response?.data?.items);
          if(response?.data?.items !== null) {
          console.log(response?.data?.items, 'access_pending_actions_list_get');
          setMyActions(response?.data?.items);
          }
          }
          else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        }).catch(err => {
          console.log(err);
        })
      }

    },[token, reuqestAccessPopup, selectedOrganization, refreshActionList]);


    const handleShowMoreOptions = (event) => {
        let el = document.querySelectorAll('.more-options-wrapper .more-options');
        var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
      
        if (x.style.display === "none") {
          for(let i=0; i<el.length; i++){
            el[i].style.display = "none";
          }
          x.style.display = "block";
          setDisplayNone('hello');
        } else {
          x.style.display = "none";
        }
      }

    const handleChangeStatus = (e, slug, status) => {
        e.preventDefault();
        axios.post(serverConfig.api_base_url + "access_action_update", {
            token: token,
            org: selectedOrganization?.slug,
            accFlow: slug,
            status_message: status
        }).then(response=>{
            console.log(response, 'access_action_update');
            if(response.data.status === 200) {
                setRefreshActionList(!refreshActionList);
                setDisplayNone('none');
                handleCloseApprovalModal();
                handleShowAlertModal('Action successful');
                setTimeout(() => {
                  handleCloseAlertModal();
                }, 6000);
            }
            else if(response.data.status === 301){
              handleLogout();
            } else {
              handleShowAlertModal(response.data.message, true);
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
        }).catch(err=> {
            console.log(err, 'err');
        })
    }

    useEffect(() => {
      console.log(displayNone, 'display of dropdown');
      console.log(approvalModalShow, 'approval modal');
      const handleMouseDown = (event) => {
        console.log(event.target.classList, !event.target.classList.contains('btn-more-options'), 'classlist')
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains('fa-solid') && !event.target.classList.contains("action-option") && !event.target.classList.contains("action-approval") && !event.target.classList.contains("modalPopup") && !event.target.classList.contains('btn-more-options') && !event.target.classList.contains('more-options')) {
          setDisplayNone('none');
        }
      };
      document.addEventListener('mousedown', handleMouseDown);
    
      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
      };
    }, [displayNone]);


    const columns = [
        
        {
            name: <div className='row-label'>Access</div>,
            selector:row=>row?.logo,
            cell: (row) => <div className="flex items-center py-2 gap-3">
              <div>
              {row?.logo !== null ? <img src={row?.logo} className='rounded-full w-12 h-12 object-cover p-1'/> :
                 <img src={`https://ui-avatars.com/api/?name=${row.access}
                 &rounded=true&length=1
                 &background=undefined&format=svg&color=fff`} alt='image'
                  height={40} width={40} className='rounded-full'/> }
              </div>
              <div>
              <div className="text-heading text-custom">{row?.access}</div>
              </div>
            </div>,
            grow:2
          
        },
        {
        
            name:<div className='row-label text-custom'>Member</div>,
            cell: (row) => <div className="row-label">
                         {row?.member}
                             </div>,
          
            wrap:true,
            grow:1.2     
        },
        {
        
            name:<div className='row-label text-custom'>Context</div>,
            cell: (row) => <div className="row-label">
                         {row?.context}
                             </div>,
          
            wrap:true,
            grow:1.2     
        },{
            name:<div className='row-label text-custom'>Access Type</div>,
            cell: (row) => <div className="row-label">
            {row?.access_type}
                </div>,
        },
        {
            name:<div className='row-label text-custom'>Status</div>,
            cell:(row)=><div className={row?.status==='Granted'?'active-status  py-1 px-2':row?.status==='Pending'?'status-inprogress py-1 px-2':'unactive-status  py-1 px-2'}>{row?.current_status}</div>
        },
        {                   
            name: <div className='row-label'>Action</div>,
            cell: (row) =>  
            <div className="text-right more-options-wrapper">

            <button type="button" ref={dropdownRef} onClick={(e)=>{handleShowMoreOptions(e); console.log(row?.next_actions, 'item')}} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
    
             <div className="more-options" style={{display: `${displayNone}`}}>
             
            {row?.next_actions?.map((item)=> 
            {
                return (
                  <>
                 
                <Button onClick={(e) => { setApprovalModalShow(true); handleShowApprovalModal(e, item, row); console.log(item, (approvalModalShow===row), approvalModalShow, row, 'item'); }} variant='text'><span className="action-approval">{item}</span></Button>
        </>
                )
            })}

          </div>
          {approvalModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={(e) => {handleChangeStatus(e, row?.slug, btnText); handleShowMoreOptions(e);}}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>{btnText}</div>
                {/* <button className='my-2 title-text-small-color' onClick={handleCloseApprovalModal}><i className="fa-solid fa-xmark "></i> </button> */}
              </div>
      
              {
              btnText === 'Approve' ? 

                <div className='mt-4 mb-6'>
                  <p>Please click below to approve this access.</p>
                </div> :

              btnText === 'Reject' ? 

                <div className='mt-4 mb-6'>
                <p>Please click below to deny this access.</p>
                </div> :

             btnText === 'Mark Completed' ? 

                <div className='mt-4 mb-6'>
                <p>Please click below to mark it as completed.</p>
                </div> :
           
                <div className='mt-4 mb-6'>
                <p>Are you sure you want to <b>{btnText}</b> ?</p>
                </div>

              }

            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseApprovalModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>{btnText}</button>
            </div>
          </form>
        </div>}
          </div>
             ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
           
      }, 
       
    ]
  return (
    <div className="overflow-x-auto">
    <div className="p-3 table-custom mt-4">
    {myActions === null && <h1 className='font-semibold text-2xl mt-4 mb-4'>My Actions</h1>}
      {myActions !== null ? 
        <DataTable
        title={ <div className=" table-title rounded">
        <div className=" mx-auto flex justify-between items-center">
          {/* Left side: Three tabs flexed together */}
          <div className="flex space-x-4">
           <h5 className='font-semibold theader'>My Actions</h5>
          </div>               
          {/* Right side: Search input and two dropdowns */}
          <div className="flex items-center space-x-4 text-black">
          <div class="flex items-center gap-12 mt-4">
<div class="relative">
<input
type="text"
placeholder="Search"
class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
/>
<div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
<i class=" text-sm fas fa-search"></i>
</div>
</div>

</div>

<div className="relative inline-block">
<div
className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center"
>

<div className='pr-5'>
<i class="fa-solid fa-arrow-down-short-wide text-sm" ></i>

</div>


</div>
<div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
<i class="fa-solid fa-chevron-down text-sm"></i> 
</div>
</div>
{/* <button className='sub-grp px-3 py-2' onClick={()=>{setRequestAccessPopup(true);}} >
Request Access
</button> */}

{/* {reuqestAccessPopup && <div className='overlay'><TeamAccessPopup setTeamPopup={setRequestAccessPopup}/></div>} */}
            
          </div>
        </div>
      </div>}
         columns={columns}
         data={myActions}
         responsive={true}
         pagination={true}
         customStyles={{
           rows: {
             rows: {
               className: 'custom-hover', // Apply custom hover styles here
             }
           },
           cells: {
             className: 'p-6',
           },
         }}
 /> :

 <div className='flex flex-col h-3/4 items-center justify-center'>
 <div className='mb-8'><img src={require('../../Asset/Images/no-records.png')} alt="No records" /></div>
 <p><b>You are all done.</b></p>
 <p className='text-sm'>You don't have any pending actions to complete.</p>
 
 </div> }


 {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} subMessage={"Click below to view the status of request."} notificationType={'Success'} buttonText={'View Status'} handleFunction={handleViewStatus}/>}
  </>}
 </div>
    </div>
  )
}

export default MyActionsTable;