import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { Context } from '../../contextApi/context';
import '../css/cat.css';

const serverConfig = require('../../config/server.js');

function AddResource({ setAddResource, categorySlug, dynamicColumns, onAddSuccess }) {
    const { selectedOrganization } = useContext(Context);
    const [resourceDescription, setResourceDescription] = useState('');
    const [resourceName, setResourceName] = useState('');
    const [error, setError] = useState('');
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
    const [formMessage, setFormMessage] = useState({ type: '', message: '' });
    const [tab, setTab] = useState('Add Resource');
    const [memberTab, setMemberTab] = useState(0);
    const [addResourceSuccess, setAddResourceSuccess] = useState(false);
    const [attributes, setAttributes] = useState([]);

    const getToken = () => {
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt })
            .then(response => {
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setCToken(response.data.token);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log('error is', err));
    };

    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
        console.log("dynamic col", dynamicColumns);
    }, []);

    const showMessage = (message, type = 'info') => {
        setFormMessage({ type, message });
    };

    const submitAddResource = async (event) => {
        event.preventDefault();

        if (validateResource()) {
            try {
                const response = await axios.post(serverConfig.api_base_url + 'resource_create', {
                    token: cToken,
                    org: selectedOrganization?.slug,
                    cat: categorySlug,
                    name : resourceName,
                    description: resourceDescription,
                });

                if (response.data.status === 200) {
                    //showMessage('Resource added successfully!', 'success');
                    console.log("response", response.data);
                    console.log("attr", attributes);

                    for (const column of dynamicColumns) {
                        try {
                            const attributeResponse = await axios.post(serverConfig.api_base_url + 'resource_attribute_create', {
                                token: cToken,
                                org: selectedOrganization?.slug,
                                res: response.data.new_res,
                                attribute_name: column,
                                attribute_value: attributes[column] || '',
                            });

                            if (attributeResponse.data.status !== 200) {
                                showMessage(`Failed to create attribute ${column}. ${attributeResponse.data.message}`, 'error');
                            }
                        } catch (attributeError) {
                            console.error('Error creating attribute:', attributeError);
                            showMessage(`Error creating attribute ${column}. Please try again.`, 'error');
                        }
                    }
                    onAddSuccess();
                    setAddResourceSuccess(true);
                } else {
                    showMessage(`Failed to add resource. ${response.data.message}`, 'error');
                }
            } catch (error) {
                console.error('Error creating resource:', error);
                showMessage('An error occurred while creating the resource. Please try again.', 'error');
            }
        }
    };

    const validateResource = () => {
        if (resourceName === '') {
            showMessage('Resource Name is required', 'error');
            return false;
        }
        setError('');
        return true;
    };

    const handleAttributeChange = (column, value) => {
        setAttributes(prevAttributes => ({
            ...prevAttributes,
            [column]: value,
        }));
    };

    return (
        <div className='add-user rounded-3xl py-4 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col items-center modalPopup'>
            {addResourceSuccess ? (
                <>
                    <div className='loading-div flex flex-col'>
                        <img src={require(`../images/uploadSuccess.png`)} alt='' className='loading-img' />
                        <h1 className='invite-text'>Successfully added Resource</h1>
                    </div>
                    <div className='flex flex-row justify-end'>
                        <button
                            type="submit"
                            className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                            onClick={() => { setAddResource(false); setAddResourceSuccess(false); }}
                        >
                            Done
                        </button>
                    </div>
                </>
            ) : (
                <><div className='flex-grow overflow-y-auto'>
                    <div className="flex space-x-4">
                        <button className={`px-6 py-3 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Add Resource'); setMemberTab(0); }}>Add Resource</button>
                    </div>
                    <div className='flex justify-between items-center gap-5'>
                        <h5 className='mt-4 font-semibold'>Add Resource</h5>
                    </div>
                    <div className='mt-4 flex flex-wrap'>
                        <div key='resource_description' className="mb-4 w-full pr-4 flex items-center">
                            <div className="flex-1">
                                <label className="text-gray-600" style={{ fontWeight: 'bold' }}>Resource Name <span className='text-red-500'>*</span></label>
                                <input
                                    type="text"
                                    value={resourceName}
                                    onChange={(e) => setResourceName(e.target.value)}
                                    className="border border-gray-300 rounded-md p-2 w-full mt-1" />
                            </div>
                        </div>
                        <div key='resource_description' className="mb-4 w-full pr-4 flex items-center">
                            <div className="flex-1">
                                <label className="text-gray-600" style={{ fontWeight: 'bold' }}>Resource Description</label>
                                <input
                                    type="text"
                                    value={resourceDescription}
                                    onChange={(e) => setResourceDescription(e.target.value)}
                                    className="border border-gray-300 rounded-md p-2 w-full mt-1" />
                            </div>
                        </div>

                        {dynamicColumns.map((column, index) => (
                            <div key={index} className="mb-4 w-1/3 pr-4 flex items-center">
                                <div className="flex-1">
                                    <label className="text-gray-600" style={{ fontWeight: 'bold' }}>{column}</label>
                                    <input
                                        style={{ width: '100%' }}
                                        type="text"
                                        value={attributes[column] || ''}
                                        onChange={(e) => handleAttributeChange(column, e.target.value)}
                                        className="border border-gray-300 rounded-md p-2 w-full mt-1"
                                    />
                                </div>
                            </div>
                        ))}

                    </div>
                </div><div className='mt-4 flex justify-end'>
                        <button
                            type="button"
                            className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                            onClick={() => setAddResource(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                            onClick={(event) => submitAddResource(event)}  
                        >
                            Add Resource
                        </button>



                    </div><div className='mt-4 flex justify-end'>
                        <div className={formMessage.type === "success" ? "mt-2 text-green-500" : formMessage.type === "error" && "mt-2 text-red-500"}>
                            {formMessage.message ? formMessage.message : <>&nbsp;</>}
                        </div>
                    </div></>
            )}
        </div>
    );
}

export default AddResource;
