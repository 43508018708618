import React, { useState, useEffect, useContext, useRef } from "react";
import { Context } from "../../contextApi/context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const serverConfig = require("./../../config/server.js");

function EditTimePopup({
  setEditTime,
  token,
  selectedOrganization,
  handleLogout,
  refreshTimesheet,
  handleCloseAlertModal,
  handleShowAlertModal,
  timeEntryDetails,
}) {
  const navigate = useNavigate();
  const { setActiveOption } = useContext(Context);

  const [formData, setFormData] = useState({});
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [reasonName, setReasonName] = useState(undefined);
  const [reasonDetails, setReasonDetails] = useState(undefined);
  const [note, setNote] = useState(undefined);
  const [groupListLookup, setGroupListLookup] = useState([]);
  const [taskListLookup, setTaskListLookup] = useState([]);
  const [reasonLookup, setReasonLookup] = useState([]);

  const startTimeInputRef = useRef(null);
  const endTimeInputRef = useRef(null);

  useEffect(() => {
    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);

      //group_list_get
      axios
        .post(serverConfig.api_base_url + "group_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("group_list_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              let arr = [];
              response.data.items.forEach((el) => {
                arr.push({
                  value: el.grp,
                  label: el.name,
                });
              });
              setGroupListLookup(arr);
            } else {
              setGroupListLookup([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
      axios
        .post(serverConfig.api_base_url + "group_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("group_list_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              let arr = [];
              response.data.items.forEach((el) => {
                arr.push({
                  value: el.grp,
                  label: el.name,
                });
              });
              setGroupListLookup(arr);
            } else {
              setGroupListLookup([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      axios
        .post(serverConfig.api_base_url + "lookup_list", {
          token: token,
          name: "time_entry_reason",
        })
        .then((response) => {
          console.log("time_entry_reason lookup:", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              response.data.items.sort(function (a, b) {
                return a.seq_no - b.seq_no;
              });
              let arr = [];
              response.data.items.forEach((el) => {
                arr.push({
                  value: el.value,
                  label: el.value,
                });
              });
              setReasonLookup(arr);
            } else {
              setReasonLookup([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization]);

  useEffect(() => {
    console.log(timeEntryDetails);
    if (timeEntryDetails !== null && timeEntryDetails !== undefined) {
      getTaskList(timeEntryDetails.group.grp);
      setFormData({
        grp: timeEntryDetails?.group?.grp,
        tsk: timeEntryDetails?.tsk,
        is_work_break: timeEntryDetails?.is_work_break,
        start_time: new Date(
          timeEntryDetails?.start_time + " UTC"
        ).toLocaleString("sv-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        }),
        end_time: new Date(timeEntryDetails?.end_time + " UTC").toLocaleString(
          "sv-SE",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          }
        ),
        is_billable: timeEntryDetails?.is_billable,
        reason: timeEntryDetails?.reason,
        note: timeEntryDetails?.note,
        timelog: timeEntryDetails?.timelog,
      });
      console.log(formData);
      console.log(
        timeEntryDetails?.start_time,
        new Date(timeEntryDetails?.start_time + " UTC").toLocaleString(
          "sv-SE",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          }
        )
      );
    }
  }, [timeEntryDetails]);

  const handleEditTime = (event) => {
    event.preventDefault();
    console.log(formData);
    let time1 = new Date(
      new Date(formData.start_time).toUTCString()
    ).toISOString();
    let startTime =
      time1.split("T")[0] + " " + time1.split("T")[1].substring(0, 8);
    // console.log(startTime);
    let time2 = new Date(
      new Date(formData.end_time).toUTCString()
    ).toISOString();
    let endTime =
      time2.split("T")[0] + " " + time2.split("T")[1].substring(0, 8);
    // console.log(endTime);
    console.log(startTime);
    console.log(formData.start_time);
    let formInputs = {
      ...formData,
      start_time: startTime !== "" ? startTime : time1,
      end_time: endTime !== "" ? endTime : time2,
      manual: 100,
      //reason: formData.reason ? [...formData.reason, {name: reasonName, details: reasonDetails}] : [{name: reasonName, details: reasonDetails}],
      //note: formData.note ? [...formData.note, note] : [note]
      reason:
        formData.reason && reasonName
          ? [...formData.reason, { name: reasonName, details: reasonDetails }]
          : reasonName
          ? [{ name: reasonName, details: reasonDetails }]
          : formData.reason && formData.reason,
      note:
        formData.note && note
          ? [...formData.note, note]
          : note
          ? [note]
          : formData.note && formData.note,
    };
    console.log(formInputs);

    axios
      .post(serverConfig.api_base_url + "time_log_update", {
        token: token,
        org: selectedOrganization?.slug,
        timelog: timeEntryDetails.timelog,
        items: formInputs,
      })
      .then((response) => {
        console.log("time_log_update response", response);
        if (response.data.status === 200) {
          setFormData({});
          refreshTimesheet();
          handleClosePopup();
          handleShowAlertModal("Time log updated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleClosePopup = () => {
    setEditTime(false);
    setFormData({});
    setReasonName(undefined);
    setReasonDetails(undefined);
    setNote(undefined);
    setStartTime("");
    setEndTime("");
  };

  const getTaskList = (grp) => {
    console.log(grp);
    axios
      .post(serverConfig.api_base_url + "my_task_list_get", {
        token: token,
        org: selectedOrganization?.slug,
        grp: grp,
      })
      .then((response) => {
        console.log("my_task_list_get response", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log(response.data.items);
            let relatedTasks = response.data.items.filter(
              (el) =>
                el.assignee !== null &&
                el.assignee.some((el1) => el1.usr === el.usr)
            ); //filter tasks assigned to logged in user (user is task member of the task)
            console.log(relatedTasks);
            let arr = [];
            //relatedTasks.forEach(el => {//format array in react select list format
            response.data.items.forEach((el) => {
              //format array in react select list format
              arr.push({
                value: el.tsk,
                label: el.name,
              });
            });
            setTaskListLookup(arr);
          } else {
            setTaskListLookup([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  function localeIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(Math.abs(tzo) % 60)
    );
  }

  return (
    <div
      className="memberPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex flex-col overflow-hidden"
      style={{ height: "95vh" }}
    >
      <div className="flex justify-between items-center gap-5">
        <div style={{ fontSize: 16 }}>Edit Time</div>
      </div>
      <div className="flex-1 flex">
        {Object.keys(formData).length > 0 && (
          <form
            className="flex flex-col justify-between w-full"
            onSubmit={handleEditTime}
          >
            <div className="scrollable mt-5 mb-5">
              <div className="flex flex-col gap-1 text-xs">
                <label>
                  Groups
                  <span className="text-red-500">*</span>
                  {groupListLookup.length === 0 && (
                    <span className="text-red-500 text-xs font-normal ml-4">
                      Currently there are no groups. Please create one{" "}
                      <span
                        className="underline cursor-pointer"
                        onClick={() => {
                          navigate("/groups");
                          setActiveOption("Groups");
                        }}
                      >
                        here
                      </span>
                    </span>
                  )}
                </label>
                <Select
                  defaultValue={
                    {
                      value: timeEntryDetails?.group.grp,
                      label: timeEntryDetails?.group.name,
                    } || ""
                  }
                  classNamePrefix="select"
                  placeholder="Select Group"
                  options={groupListLookup}
                  onChange={(option) => {
                    setFormData({ ...formData, grp: option.value });
                    getTaskList(option.value);
                  }}
                  required
                  isDisabled={true}
                />
              </div>
              <div className="flex flex-col gap-1 mt-4 text-xs">
                <label>
                  Task
                  <span className="text-red-500">*</span>
                  {formData.grp ? (
                    taskListLookup.length === 0 && (
                      <span className="text-red-500 text-xs font-normal ml-4">
                        Currently there are no tasks assigned to you under this
                        group. Please assign one{" "}
                        <span
                          className="underline cursor-pointer"
                          onClick={() => {
                            navigate("/task");
                            setActiveOption("Tasks");
                          }}
                        >
                          here
                        </span>
                      </span>
                    )
                  ) : (
                    <span className="text-red-500 text-xs font-normal ml-4">
                      Select group first
                    </span>
                  )}
                </label>
                <Select
                  defaultValue={
                    {
                      value: timeEntryDetails?.tsk,
                      label: timeEntryDetails?.task_name,
                    } || ""
                  }
                  classNamePrefix="select"
                  placeholder="Select Task"
                  options={taskListLookup}
                  onChange={(option) => {
                    setFormData({ ...formData, tsk: option.value });
                  }}
                  required
                  isDisabled={true}
                />
              </div>
              <div className="flex flex-col gap-1 mt-4 text-xs">
                <label>Work Break</label>
                <div className="flex flex-row mt-2">
                  No
                  <label class="checkbox-toggle-simple mx-3">
                    <input
                      type="checkbox"
                      checked={formData.is_work_break === 1 ? true : false}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_work_break: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                    <span></span>
                  </label>
                  Yes
                </div>
              </div>
              <div className="mt-5">
                <label>Time span</label>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2 w-2/4">
                  <label>
                    From<span className="text-red-500">*</span>
                  </label>
                  <div
                    onClick={() => startTimeInputRef.current.showPicker()}
                    style={{ display: "inline-block" }}
                  >
                    <input
                      ref={startTimeInputRef}
                      type="datetime-local"
                      value={formData.start_time.replace(" ", "T")}
                      onChange={(e) => {
                        const newStartTime = e.target.value;
                        const newStartDate = new Date(newStartTime);
                        const time = newStartDate
                          .toISOString()
                          .split("T")[1]
                          .substring(0, 8);
                        setFormData({ ...formData, start_time: newStartTime });
                        setStartTime(newStartTime.split("T")[0] + " " + time);
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                      className="custom-input py-2 px-2 text-left mt-1"
                      max={
                        localeIsoString(new Date()).split("T")[0] +
                        "T" +
                        localeIsoString(new Date()).split("T")[1]
                      }
                      required
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-2 w-2/4">
                  <label>
                    To<span className="text-red-500">*</span>
                  </label>
                  <div
                    onClick={() => endTimeInputRef.current.showPicker()}
                    style={{ display: "inline-block" }}
                  >
                    <input
                      ref={endTimeInputRef}
                      type="datetime-local"
                      value={formData.end_time.replace(" ", "T")}
                      onChange={(e) => {
                        const newEndTime = e.target.value;
                        const newEndDate = new Date(newEndTime);
                        const time = newEndDate
                          .toISOString()
                          .split("T")[1]
                          .substring(0, 8);
                        setFormData({ ...formData, end_time: newEndTime });
                        setEndTime(newEndTime.split("T")[0] + " " + time);
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                      className="custom-input py-2 px-2 text-left mt-1"
                      min={formData.start_time}
                      max={localeIsoString(new Date()).split("T")[0] + "T23:59"}
                      required
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-1 mt-4 text-xs">
                <label>Billable</label>
                <div className="flex flex-row mt-2">
                  No
                  <label class="checkbox-toggle-simple mx-3">
                    <input
                      type="checkbox"
                      checked={formData.is_billable === 1 ? true : false}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_billable: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                    <span></span>
                  </label>
                  Yes
                </div>
              </div>
              {/* put Time bar here */}
              <div className="flex flex-col gap-1 mt-4">
                <label>
                  Reason<span className="text-red-500">*</span>
                </label>

                <Select
                  defaultValue={
                    timeEntryDetails?.reason?.length > 0
                      ? {
                          value:
                            timeEntryDetails?.reason[
                              timeEntryDetails?.reason?.length - 1
                            ].name,
                          label:
                            timeEntryDetails?.reason[
                              timeEntryDetails?.reason?.length - 1
                            ].name,
                        }
                      : undefined
                  }
                  classNamePrefix="select"
                  placeholder="Why are you adding this time entry? (ex. Forgot to start timer)"
                  options={reasonLookup}
                  onChange={(option) => {
                    setReasonName(option.value);
                    setReasonDetails(undefined);
                  }}
                  required
                />
                {reasonName
                  ? reasonName === "Other" && (
                      <textarea
                        defaultValue={reasonDetails || ""}
                        onChange={(e) => {
                          setReasonDetails(e.target.value);
                        }}
                        placeholder="Please specify"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      ></textarea>
                    )
                  : timeEntryDetails?.reason?.length > 0 &&
                    timeEntryDetails?.reason[
                      timeEntryDetails?.reason?.length - 1
                    ].name === "Other" && (
                      <textarea
                        defaultValue={
                          timeEntryDetails?.reason[
                            timeEntryDetails?.reason?.length - 1
                          ].details
                        }
                        onChange={(e) => {
                          setReasonDetails(e.target.value);
                          setReasonName(
                            timeEntryDetails?.reason[
                              timeEntryDetails?.reason?.length - 1
                            ].name
                          );
                        }}
                        placeholder="Please specify"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      ></textarea>
                    )}

                {/* <textarea value={formData.reason && formData.reason.length > 0 && formData.reason[0]} onChange={(e)=>  setReason(e.target.value)} placeholder='Why are you adding this time entry? (ex. Forgot to start timer)' className='custom-input py-2 px-2 text-left mt-1'></textarea> */}
              </div>
              <div className="flex flex-col mt-4">
                <label>Add a Note</label>
                <textarea
                  defaultValue={
                    formData.note &&
                    formData.note.length > 0 &&
                    formData.note[0]
                  }
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="What are you working for? (ex. Responding to customers)"
                  className="custom-input py-2 px-2 text-left mt-1"
                ></textarea>
              </div>
            </div>
            {/* bottom buttom */}
            <div className="ml-auto">
              <button
                type="button"
                className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
              >
                Update
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditTimePopup;
