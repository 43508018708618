import React, { PureComponent, useRef, useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RCStackedBarChart = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const generateColors = (count) => {
    const colors = [
      "#8884d8",
      "#82ca9d",
      "#ffc658",
      "#ff7300",
      "#d7aefb",
      "#d4e157",
      "#ff5722",
      "#795548",
      "#607d8b",
      "#ffeb3b",
    ];
    return colors.slice(0, count);
  };
  const svgRef = useRef();
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [yKeys, setYKeys] = useState([]);

  const transformData = (data, groupKey, xCordKey, yCordKey) => {
    return data.reduce((acc, current) => {
      const locationId = current[yCordKey]; // Access the yCord value dynamically
      const group = current[groupKey]; // Access the group value dynamically
      const value = current[xCordKey]; // Access the xCord value dynamically

      const existingLocation = acc.find(
        (item) => item.location_id === locationId
      );

      if (existingLocation) {
        existingLocation[group] = value;
      } else {
        acc.push({
          location_id: locationId, // Use "location_id" instead of "location"
          [group]: value,
        });
      }

      return acc;
    }, []);
  };

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const chartdata = transformData(
        apiData?.data,
        apiData?.group,
        apiData?.x_cord,
        apiData?.y_cord
      );
      setData(chartdata);
      const keys = [...new Set(apiData?.data.map((d) => d[apiData?.group]))];
      setYKeys(keys);
      console.log(chartdata, keys);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  const colors = generateColors(yKeys.length);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="no-chart-title"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div style={{ height: "350px" }}>
          <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 20,  // Reduced top margin to make space for the chart
                  right: 30,
                  left: 20,
                  bottom: 60, // Ensure bottom margin for the rotated x-axis labels
                }}
                barCategoryGap="15%" // Adds space between bar groups
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={item?.y_cord}
                  interval={0}
                  //angle={-10} // Rotated labels
                  tickFormatter={(label) =>
                    label.length > 10 ? `${label.slice(0, 20)}...` : label
                  }
                  textAnchor="end"
                  angle={-40}
                  //textAnchor="end"
                  // tickMargin={20} // Space between ticks and axis
                  // label={{
                  //   value: item?.x_axis_label,
                  //   position: "insideBottom",
                  //   offset: -10,
                  //   fontSize: 14,
                  //   fontWeight: 600,
                  //   fill: "#000",
                  // }}
                />
                <YAxis
                  label={{
                    value: item?.y_axis_label,
                    angle: -90,
                    position: "insideLeft",
                    offset: 10,
                  }}
                />
                <Tooltip
                  contentStyle={{
                    fontSize: "14px",
                    padding: "10px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                  }}
                  formatter={(value) => value.toFixed(2)} // Limit to 2 decimal places
                />
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="top"
                  wrapperStyle={{ fontSize: "12px", marginBottom: "30px", top: "0px", left: "60px" }}  // Adjust margin for the legend
                />
                {yKeys?.map((key, index) => (
                  <Bar
                    connectNulls={true}
                    type="monotone"
                    dataKey={key}
                    stackId="a"
                    fill={colors[index]}
                    activeDot={{ r: 8 }}
                    isAnimationActive={true}
                    animationEasing="ease-out"
                    animationDuration={100}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>

        </div>
      )}
    </>
  );
};

export default RCStackedBarChart;
