import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { Context } from '../../contextApi/context';
import { Button } from 'react-bootstrap';
import '../css/cat.css';

const serverConfig = require('../../config/server.js');

function AddSubCat({ setAddCategory, parentCategory,onAddSuccess }) {

    const { selectedOrganization } = useContext(Context);
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    //const [addCategorySuccess, setAddCategorySuccess] = useState(false);
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
    const [formMessage, setFormMessage] = useState({ type: '', message: '' });
    const [attributes, setAttributes] = useState([]);
    const [showOptionsIndex, setShowOptionsIndex] = useState(null);
    const [addSubCategorySuccess, setAddSubCategorySuccess] = useState(false);

    const [tab, setTab] = useState('Add SubCategory');
    const [memberTab, setMemberTab] = useState(0)

    const getToken = async () => {
        try {
            let jwt = UserService.getToken();
            const response = await axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt });

            if (response.data.status === 200) {
                sessionStorage.setItem('token', response.data.token);
                setCToken(response.data.token);
            } else if (response.data.jwt_validation === 'fail') {
                handleLogout();
            }
        } catch (error) {
            console.log("Error fetching token:", error);
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
        console.log("Parent Category:", parentCategory);
    }, []);


    const showMessage = (text, type = 'info') => {
        setFormMessage({ type, message: text });
    };

    const submitAddCategory = async () => {
        //event.preventDefault();

        if (validateCategory()) {
            const requestData = {
                token: cToken,
                org: selectedOrganization?.slug,
                category: category,
                description: description,
                parent_category: parentCategory
            };
            try {
                const response = await axios.post(serverConfig.api_base_url + 'resource_category_create', requestData);

                console.log('Add category response:', response);

                if (response.data.status === 200) {
                    showMessage('Category added successfully!', 'success');
                    console.log("Attr",attributes);
                    for (const attribute of attributes) {
                            try {
                                const addAttributeResponse = await axios.post(serverConfig.api_base_url + 'resource_category_attribute_create', {
                                    token: cToken,
                                    cat: response.data.new_cat,
                                    org: selectedOrganization?.slug,
                                    attribute_name: attribute.Name,
                                    attribute_description: attribute.Description,
                                });

                                if (addAttributeResponse.data.status !== 200) {
                                    showMessage(`Error adding attribute ${attribute.Name}: ${addAttributeResponse.data.message}`, 'error');
                                }
                            } catch (error) {
                                console.error(`Error adding attribute ${attribute.Name}:`, error);
                                showMessage(`Error adding attribute ${attribute.Name}`, 'error');
                            }
                        
                    }
                    setAddSubCategorySuccess(true);
                    onAddSuccess();
                } else {
                    showMessage(`Failed to add category. ${response.data.message}`, 'error');
                }

            } catch (error) {
                console.log('Error:', error);
                showMessage('An error occurred. Please try again.', 'error');
            }
            
        }
    };


    const validateCategory = () => {
        if (category === '') {
            showMessage('Category is required', 'error');
            return false;
        }
        return true;
    };

    const addAttributeRow = () => {
        setAttributes([...attributes, { Name: '', Description: '' }]);
    };


    const deleteAttributeRow = (index) => {
        const newAttributes = [...attributes];
        newAttributes.splice(index, 1);
        setAttributes(newAttributes);
        setShowOptionsIndex(null);
    };

    const handleAttributeChange = (rowIndex, colIndex, value) => {
        const newAttributes = [...attributes];
        newAttributes[rowIndex][Object.keys(attributes[0])[colIndex]] = value;
        setAttributes(newAttributes);
    };


    const handleShowOptions = (index) => {
        setShowOptionsIndex(showOptionsIndex === index ? null : index);
    };


    return (
        <div className='add-subuser rounded-3xl py-4 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col items-center'>
            {addSubCategorySuccess ? (
                <>
                    <div className='loading-div flex flex-col'>
                        <img src={require(`../images/uploadSuccess.png`)} alt='' className='loading-img' />
                        <h1 className='invite-text'>Successfully added category</h1>
                    </div>
                    <div className='flex flex-row justify-end'>
                        <button
                            type="submit"
                            className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                            onClick={() => { setAddCategory(false); setAddSubCategorySuccess(false); }}
                        >
                            Done
                        </button>
                    </div>
                </>
            ) : (
                <div className='flex items-center gap-2'>
                    <div className='flex flex-col team-label'>
                        <div className="flex space-x-4">
                            <button className={`px-6 py-3 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Add SubCategory'); setMemberTab(0); }}>Add SubCategory</button>
                        </div>
                        <h5 className='mt-4 font-semibold'>Add Sub-Category</h5>
                        <div className='flex flex-row gap-2'>

                            <div className='flex flex-col gap-1 input-field'>
                                <label>Sub Category<span className='text-red-500'>*</span></label>
                                <input
                                    type='text'
                                    name='category'
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    placeholder='Category'
                                    className='invite-field custom-input py-2 px-2 text-left'
                                    required
                                />
                            </div>
                            <div className='flex flex-col gap-1 input-field'>
                                <label>Sub Category Description</label>
                                <input
                                    type='text'
                                    name='description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder='Description'
                                    className='invite-field custom-input py-2 px-2 text-left'
                                    required
                                />
                            </div>
                        </div>

                        {attributes.length > 0 ? (
                            <div>
                                <div className='font-semibold mt-2'>Attributes</div>
                                <table className='attribute-table mt-2'>
                                    <thead>
                                        <tr>
                                            {Object.keys(attributes[0]).map((colType, colIndex) => (
                                                <th key={colIndex}>{colType}</th>
                                            ))}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attributes.map((attribute, rowIndex) => (
                                            <tr key={rowIndex} className='attribute-row text-xs'>
                                                {Object.values(attribute).map((value, colIndex) => (
                                                    <td key={colIndex}>
                                                        <input
                                                            type='text'
                                                            name={`${Object.keys(attributes[0])[colIndex]}-${rowIndex}`}
                                                            value={value}
                                                            onChange={(e) => handleAttributeChange(rowIndex, colIndex, e.target.value)}
                                                            placeholder={Object.keys(attributes[0])[colIndex]}
                                                            className='invite-field custom-input py-2 px-2 text-left'
                                                        />
                                                    </td>
                                                ))}
                                                <td>
                                                    <div className="more-options-wrapper">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleShowOptions(rowIndex)}
                                                            title="More"
                                                            className='btn-more-options'
                                                        >
                                                            <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                                                        </button>
                                                        {showOptionsIndex === rowIndex && (
                                                            <div className="more-options">
                                                                <Button variant='text' onClick={() => deleteAttributeRow(rowIndex)}>
                                                                    <i className="fa-solid fa-trash not-0" aria-hidden="true"></i>
                                                                    <span>Delete</span>
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={addAttributeRow}>
                                    <i className="fa-solid fa-plus not-0" aria-hidden="true"></i> Add Attributes
                                </button>
                            </div>
                        ) : (
                            <div>
                                <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={addAttributeRow}>
                                    <i className="fa-solid fa-plus not-0" aria-hidden="true"></i> Add Attributes
                                </button>
                            </div>
                        )}


                        <div className='addform flex'>
                            <button
                                type='button'
                                className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium mr-2'
                                onClick={() => {
                                    console.log('Closing form');
                                    setAddCategory(false);
                                    setAddSubCategorySuccess(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type='submit'
                                className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                                onClick={submitAddCategory}
                            >
                                Add Sub-Category
                            </button>
                        </div>
                    </div>
                </div>)}
        </div>
    );
}

export default AddSubCat;
