import React, { useState } from 'react';

function Search({orgList, setOrganizationList, 
                grpList, setGroupList,
                issList, setIssueList,
                orgMembrList, setOrgMemberList,
                srchList, setSearchList,
}) {
    
  const[isFocus, setIsFocus]=useState(false);

  const handleSearch = (searchVal) => {
    if(orgList){
      console.log(searchVal);
      //console.log(organizationList);
      console.log(orgList);
      console.log(orgList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));

      setOrganizationList(orgList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
    }

    if(grpList){
      console.log(searchVal);
      //console.log(organizationList);
      console.log(grpList);
      console.log(grpList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
      //setGroupList(grpList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));

      const filterNestedArray = (data, filterFn, nestedKeys) => {
        // Base case: if there are no more nested keys, apply the filter function directly
        if (nestedKeys.length === 0) {
          return data.filter(filterFn);
        }
      
        // Recursively process the next level of nesting
        const key = nestedKeys[0];
        return data.map(item => {
          return {
            ...item,
            [key]: filterNestedArray(item[key] || [], filterFn, nestedKeys.slice(1))
          };
        })
        .filter(item => {
          if(item[key].length > 0){
            return(item[key].length > 0);
          } else {
            return(item.name.toLowerCase().includes(searchVal.toLowerCase()));
          }
        })
      };
      
      // filter function: filter out items when searched
      const filterFn = el => el.name.toLowerCase().includes(searchVal.toLowerCase());
      
      // Nested keys indicating the path to the deepest level
      const nestedKeys = ['child'];
      
      const filteredData = filterNestedArray(grpList, filterFn, nestedKeys);
      
      console.log(filteredData);
      setGroupList(filteredData);

    }

    if(issList){
      console.log(searchVal);
      //console.log(organizationList);
      console.log(issList);
      console.log(issList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase()) || el.task_key.toLowerCase().includes(searchVal.toLowerCase())));
      //setIssueList(issList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase()) || el.task_key.toLowerCase().includes(searchVal.toLowerCase())));

      const filterNestedArray = (data, filterFn, nestedKeys) => {
        // Base case: if there are no more nested keys, apply the filter function directly
        if (nestedKeys.length === 0) {
          return data.filter(filterFn);
        }
      
        // Recursively process the next level of nesting
        const key = nestedKeys[0];
        return data.map(item => {
          return {
            ...item,
            [key]: filterNestedArray(item[key] || [], filterFn, nestedKeys.slice(1))
          };
        })//.filter(item => item[key].length > 0)
        //.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase()) || el.task_key.toLowerCase().includes(searchVal.toLowerCase()));
        .filter(item => {
          if(item[key].length > 0){
            return(item[key].length > 0);
          } else {
            return(item.name.toLowerCase().includes(searchVal.toLowerCase()) || item.task_key.toLowerCase().includes(searchVal.toLowerCase()));
          }
        })
      };
      
      // filter function: filter out items when searched
      const filterFn = el => el.name.toLowerCase().includes(searchVal.toLowerCase()) || el.task_key.toLowerCase().includes(searchVal.toLowerCase());
      
      // Nested keys indicating the path to the deepest level
      const nestedKeys = ['child'];
      
      const filteredData = filterNestedArray(issList, filterFn, nestedKeys);
      
      //console.log(JSON.stringify(filteredData, null, 2));
      console.log(filteredData);
      setIssueList(filteredData);

    }
    
    if(orgMembrList){
      setOrgMemberList(orgMembrList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
    }

    if(srchList){
      setSearchList(srchList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
    }
  }

  return (
    <div class="relative">
      <input
        type="text"
        placeholder="Search"
        class=" h-9 w-40 w-rounded border-1 border-gray-300  search-input p-2"
        onFocus={()=>{setIsFocus(true)}}
        onBlur={()=>{setIsFocus(false)}}
        onChange={(e) => handleSearch(e.target.value)}
      />
      {!isFocus && <div class="absolute inset-y-0 left-5 pb-3.5 flex items-center pointer-events-none">
        <i class="text-custom-icon-color text-sm fas fa-search text-gray-200"></i>
      </div>}
    </div>
  )
}

export default Search;