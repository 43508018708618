import DataTable from "react-data-table-component";
import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import TeamAccessPopup from "../popups/TeamAccessPopup";
import { Context } from "../../contextApi/context";
import UserService from "../../services/UserService";
import Notification from "../../common/notification/Notification.js";
import { useNavigate } from "react-router-dom";

const serverConfig = require("../../config/server.js");

function TableTeamAccess() {

    const navigate = useNavigate();
    const {selectedOrganization, setActiveSubmenuOption} = useContext(Context);
    const [reuqestAccessPopup, setRequestAccessPopup] = useState(false);
    const [myAccess, setMyAccess] = useState([]);
    const [token, setToken] = useState(null);
    const [accessNotPresent, setAccessNotPresent] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);
    const handleCloseAlertModal = () => {
      setAlertMessage("");
      setShowOkButton(false);
      setAlertModalShow(false);
    }
    const handleShowAlertModal = (alert_msg, show_ok_button) => {
      setAlertMessage(alert_msg);
      setShowOkButton(show_ok_button);
      setAlertModalShow(true);
    }

    const getToken = () => {
      console.log(sessionStorage.getItem('token'));
      let jwt = UserService.getToken();
      axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
      .then(response=>{
        console.log('user_get_token response', response);
        if(response.data.status === 200){
          sessionStorage.setItem('token', response.data.token);
          setToken(response.data.token);
        } else if(response.data.status >= 400 && response.data.status <= 499){
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if(response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  
    useEffect(() => {
      console.log(sessionStorage.getItem('token'));
      if(sessionStorage.getItem('token') === null){
        getToken();
      } else {
        setToken(sessionStorage.getItem('token'));
      }
    }, []);

    useEffect(()=> {
      if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
        console.log('selectedOrganization', selectedOrganization)
        console.log('token', token);
        /* dwh_dbs_list_get */
        axios.post(serverConfig.api_base_url + "access_list_get",{token:token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log("access_list_get response:", response);
          if(response.data.status === 200){
            if(response.data.items === null) {
              setAccessNotPresent(true);
            }
            else {
              setAccessNotPresent(false);
            }
          }
        }).catch(err=> {
          console.log(err);
        })
      }
    },[token, reuqestAccessPopup])

    useEffect(() => {

      if(token !== null && token !== undefined) {
        axios.post(serverConfig.api_base_url + "member_access_list_get", {
          token : token,
          org : selectedOrganization?.slug
        }).then(response => {
          if(response.data.status === 200) {
          if(response?.data?.items !== null) {
          console.log(response?.data?.items, 'member_access_list_get');
          setMyAccess(response?.data?.items);
          }
          }
          else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        }).catch(err => {
          console.log(err);
        })
      }

    },[token, reuqestAccessPopup]);

    const handleViewStatus = () => {
      navigate("/reporteeAccess");
      setActiveSubmenuOption('Team Access');
    }

    const columns = [
        
        {
            name: <div className='row-label'>Access Type</div>,
            selector:row=>row?.logo,
            cell: (row) => <div className="flex items-center py-2 gap-3">
              <div>
              {row?.logo !== null ? <img src={row?.logo} className='rounded-full w-12 h-12 object-cover p-1'/> :
                 <img src={`https://ui-avatars.com/api/?name=${row.access}
                 &rounded=true&length=1
                 &background=undefined&format=svg&color=fff`} alt='image'
                  height={40} width={40} className='w-12 h-12 object-cover p-1 rounded-full'/> }
              </div>
              <div>
              <div className="text-heading text-custom">{row?.access}</div>
              </div>
            </div>,
            grow:3
          
        },
        {
        
            name:<div className='row-label text-custom'>Context</div>,
            cell: (row) => <div className="text-custom">
                         {row?.context}
                             </div>,
          
            wrap:true,
            grow:1.2     
        },{
            name:<div className='row-label text-custom'>Privilege</div>,
            cell: (row) => <div className="text-custom">
            {row?.access_type}
                </div>,
        },{
            name:<div className='row-label text-custom'>Status</div>,
            cell:(row)=><div className={row?.status==='Pending Provision'?'type-pending-provision  py-1 px-2':row?.status==='Requested'?'type-pending-provision  py-1 px-2':row?.status==='Pending Approval'?'type-pending-provision  py-1 px-2':row?.status==='Granted'?'active-status py-1 px-2':'unactive-status  py-1 px-2'}>{row?.status}</div>,
        }
       
    ]
  return (
    <div className="overflow-x-auto">
    <div className="p-3 table-custom mt-4">
    {accessNotPresent===true || myAccess.length < 0 && <h1 className='font-semibold text-2xl mt-4 mb-4'>Access Management Details</h1>}
        {myAccess.length > 0 && accessNotPresent === false ? <DataTable
        title={ <div className=" table-title rounded">
        <div className=" mx-auto flex justify-between items-center">
          {/* Left side: Three tabs flexed together */}
          <div className="flex space-x-4">
           <h5 className='font-semibold theader'>Access Management Details</h5>
          </div>               
          {/* Right side: Search input and two dropdowns */}
          <div className="flex items-center space-x-4 text-black">
          <div class="flex items-center gap-12 mt-4">
<div class="relative">
<input
type="text"
placeholder="Search"
class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
/>
<div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
<i class=" text-sm fas fa-search"></i>
</div>
</div>

</div>

<div className="relative inline-block">
<div
className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center"
>

<div className='pr-5'>
<i class="fa-solid fa-arrow-down-short-wide text-sm" ></i>

</div>


</div>
<div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
<i class="fa-solid fa-chevron-down text-sm"></i> 
</div>
</div>
<button className='sub-grp px-3 py-2' onClick={()=>{setRequestAccessPopup(true);}} >
Request Access
</button>

{reuqestAccessPopup && <div className='overlay'><TeamAccessPopup setTeamPopup={setRequestAccessPopup} accessNotPresent={accessNotPresent} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal}/></div>}
            
          </div>
        </div>
      </div>}
         columns={columns}
         data={myAccess}
         responsive={true}
         pagination={true}
         customStyles={{
           rows: {
             rows: {
               className: 'custom-hover', // Apply custom hover styles here
             }
           },
           cells: {
             className: 'p-6',
           },
         }}
      /> :

      <div className='flex flex-col h-3/4 items-center justify-center'>
      <div className='mb-8'><img src={require('../../Asset/Images/no-records.png')} alt="No records" /></div>
      <p><b>You have not requested for any access for yourself yet.</b></p>
      <p className='text-sm'>Want to request for a new access ?</p>
      <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={()=>{setRequestAccessPopup(true);}}>Request Access</button></div>
      {reuqestAccessPopup && <div className='overlay'><TeamAccessPopup setTeamPopup={setRequestAccessPopup} accessNotPresent={accessNotPresent} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal}/></div>}
      </div> 
}

{alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}

{ alertModalShow && <> 
    {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} buttonText={'View Status'} handleFunction={handleViewStatus} subMessage={'Please click below to view status of the request.'}/>} </> }
 </div>
    </div>
  )
}

export default TableTeamAccess