import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { Context } from "../../../contextApi/context.js";
import axios from "axios";
import UserService from "../../../services/UserService.js";
import "../../../kz-datamanagement/css/mapmetadata.css";
import Notification from "../../../common/notification/Notification.js";
import Members from "./Members.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import cloudUploadIcon from "../../../kz-datamanagement/assets/cloud_upload.png";
import "../css/policymanagement.css";

const serverConfig = require("../../../config/server.js");

const PoliciesTable = () => {
  const { selectedOrganization } = useContext(Context);
  const { isDarkTheme } = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [dbType, setDbType] = useState({});
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshDBServiceList, setRefreshDBServiceList] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [dueDate, setDueDate] = useState(null);
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState("");
  const [policyControlList, setPolicyControlList] = useState([]);
  const [control, setControl] = useState("");
  const [policyFrameworkList, setPolicyFrameworkList] = useState([]);
  const [framework, setFramework] = useState("");
  const [filedata, setFiledata] = useState(null);
  const [file, setFile] = useState("");
  const [editRow, setEditRow] = useState("");
  const [deleteRow, setDeleteRow] = useState("");
  const [pcy, setPcy] = useState("");
  const [addFilePcy, setAddFilePcy] = useState("");
  const [filePcy, setFilePcy] = useState("");
  const [fileFormat, setFileFormat] = useState("");
  const [confirmDeletePolicy, setConfirmDeletePolicy] = useState(false);
  const [deletePolicyDetails, setDeletePolicyDetails] = useState("");

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", { jwt: jwt })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  useEffect(() => {
    console.log("token", token);
    console.log(sessionStorage.getItem("token"));

    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);
      console.log("token", token);
      /* policy_list_get */
      axios
        .post(serverConfig.api_base_url + "policy_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("policy_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            if (items !== null || items !== undefined) {
              console.log(response.data.items);
              let appDataArray = response.data.items;
              let appData = [];

              console.log(appDataArray);
              appDataArray &&
                appDataArray.reverse().map((item, index) => {
                  let noOfActions =
                    Number(item.is_editable) +
                    Number(item.is_deletable) +
                    Number(item.is_validated === 0 ? 1 : 0);
                  const Actions = () => (
                    <>
                      {item.is_editable === 1 && (
                        <Button
                          variant="text"
                          onClick={() => handleShowEditPolicy(item)}
                          title={noOfActions > 2 ? "" : "Edit"}
                        >
                          <i
                            className="fa-solid fa-pencil not-0"
                            aria-hidden="true"
                          ></i>{" "}
                          {noOfActions > 2 && <span>Edit</span>}
                        </Button>
                      )}
                      {item.is_deletable === 1 && (
                        <Button
                          variant="text"
                          onClick={() =>
                            handleShowDeleteDBServiceModal(
                              item?.dbs,
                              item?.name
                            )
                          }
                          title={noOfActions > 2 ? "" : "Delete"}
                        >
                          <i
                            className="fa-solid fa-trash-can text-red-500"
                            aria-hidden="true"
                          ></i>{" "}
                          {noOfActions > 2 && <span>Delete</span>}
                        </Button>
                      )}
                      {item.is_validated === 0 && (
                        <Button
                          variant="text"
                          onClick={(e) => handleValidateDBService(item?.dbs, e)}
                          title={noOfActions > 2 ? "" : "Validate"}
                        >
                          <i className="fa-solid fa-file-circle-check not-0"></i>{" "}
                          {noOfActions > 2 && <span>Validate</span>}
                        </Button>
                      )}
                    </>
                  );
                  {
                    item.actions =
                      noOfActions > 2 ? (
                        <div className="text-right more-options-wrapper">
                          {(item.is_editable === 1 ||
                            item.is_deletable === 1 ||
                            item.is_validated === 0) && (
                            <button
                              type="button"
                              onClick={handleShowMoreOptions}
                              title="More"
                              className="btn-more-options"
                            >
                              <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                            </button>
                          )}
                          <div
                            className="more-options"
                            style={{ display: "none" }}
                          >
                            <Actions />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-end gap-3">
                          <Actions />
                        </div>
                      );
                  }
                  appData.push(item);
                });
              console.log(appData);
              setTableData(appData);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization, refreshDBServiceList]);

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ["Updated", "Option 2", "Option 3"];
  const sortingIcons = [sortDown, sortUp];

  const columns = [
    {
      name: <div className="font-bold text-sm">Policy Name</div>,
      selector: (row) => (
        <div className="text-sm ml-2">
          <b>{row.name}</b>
          <div>{row.description}</div>
        </div>
      ),
      sortable: true,
      wrap: true,
      width: "20%",
    },
    {
      name: <div className="font-bold text-sm">File Name</div>,
      selector: (row) => <div className="ml-2">{row.file_name +".pdf"}</div>,
      sortable: true,
      wrap: true,
      width: '16%',
    },
    {
      name: <div className="font-bold text-sm">Members</div>,
      selector: (row) => (
        <div className="flex items-center ml-1">
          {row.member[0]
            .split(",")
            .slice(0, 5)
            .map((member, index) => (
              <div
                className="rounded-full overflow-hidden"
                style={{ marginLeft: index > 0 ? "-15px" : "0" }}
                key={index}
              >
                <img
                  src={`https://ui-avatars.com/api/?name=${member.trim()}&rounded=true&length=1&background=6ADA00&format=svg&color=fff`}
                  alt="member-image"
                  height={40}
                  width={40}
                  className="object-cover p-1"
                />
              </div>
            ))}
          {row.member[0].split(",").length > 5 && (
            <div
              className="rounded-full overflow-hidden bg-gray-200 text-sm text-white flex items-center justify-center"
              style={{ marginLeft: "-10px", width: "32px", height: "32px" }}
            >
              +{row.member[0].split(",").length - 5}
            </div>
          )}
          {/* {rowHovered === row && ownerTooltip ? (
          <div className="role-tooltip">{row?.member}</div>
        ) : null} */}
        </div>
      ),
      // selector: (row) => row.member,
      sortable: true,
      wrap: true,
      width:'12%',
    },
    {
      name: <div className="font-bold text-sm">Controls</div>,
      selector: (row) => (
        <div
          className={
            row.control && row.control === "Confidential"
              ? "type-control-confidential ml-2"
              : "type-control-private ml-2"
          }
        >
          {row.control}
        </div>
      ),
      sortable: true,
      wrap: true,
      //width: '10%'
    },
    {
      name: <div className="font-bold text-sm">Control Code</div>,
      selector: (row) => <div className="ml-4">{row.control_code}</div>,
      sortable: true,
      wrap: true,
      //width: '10%'
    },
    {
      name: <div className="font-bold text-sm">Labels</div>,
      selector: (row) => (
        <div className="text-sm ml-4">
          {row.framework && row.framework.join(", ")}
        </div>
      ),
      sortable: true,
      wrap: true,
      // width: '30%'
    },
    {
      name: <div className="font-bold text-sm">Due Date</div>,
      selector: (row) => <div className="ml-4">{row.due_date}</div>,
      //width: 'calc(15% - 48px)'
    },
    {
      name: <div className="font-bold text-sm">Actions</div>,
      selector: (row) => row.actions,
      cell: (row) => (
        <div className="flex flex-row justify-end ml-8 pr-2">
          <Button
            variant="text"
            className="mr-2 ml-2"
            onClick={() => {
              handleShowEditPolicy(row);
              setEditRow(row);
              setPcy(row.pcy);
              // window.alert(row.pcy);
              setControl(row.control);
              // window.alert(row.control);
            }}
          >
            <i className="fa-solid fa-pencil"></i>
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setDeletePolicyDetails(row.pcy);
              setConfirmDeletePolicy(true);
              // setPcy(row.pcy);
              setDeleteRow(row);
            }}
          >
            <i
              className="fa-solid fa-trash-can text-red-500"
              aria-hidden="true"
            ></i>{" "}
          </Button>
        </div>
      ),
      // width: 'calc(15% - 48px)'
    },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };

  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  //Create
  const [createNewPolictModalShow, setCreateNewPolictModalShow] =
    useState(false);

  //User List
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          serverConfig.api_base_url + "user_get_list",
          {
            token: token,
            org: selectedOrganization?.slug,
          }
        );

        console.log("user_get_list response", response);

        if (response.data.status === 200) {
          if (response.data.items !== null) {
            // Transform the data here
            const transformedUserList = response.data.items.map((item) => ({
              label: item.name,
              value: item.slug,
            }));

            // Set the user list
            setUserList(transformedUserList);

            // Optionally, set the first user as the selected default value
            if (transformedUserList.length > 0) {
              setUser(transformedUserList[0]); // Update setUser with the selected user object
              setFormData({
                ...formData,
                db_type: transformedUserList[0].value,
              });
            }
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          setUserList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      } catch (err) {
        console.log("error is", err);
      }
    };

    fetchData();
  }, [token, selectedOrganization, createNewPolictModalShow]);

  //Control List
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          serverConfig.api_base_url + "policy_control_list_get",
          {
            token: token,
            org: selectedOrganization?.slug,
          }
        );

        console.log("policy_control_list_get response", response);
        console.log("policy_control_list_get response items", response);

        if (response.data.status === 200) {
          if (response.data.items !== null) {
            // Transform the data here if needed
            const transformedControlList = response.data.items.map((item) => ({
              label: item.name,
              value: item.name,
            }));
            setPolicyControlList(transformedControlList);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          setPolicyControlList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      } catch (err) {
        console.log("error is", err);
      }
    };

    fetchData();
  }, [token, selectedOrganization, createNewPolictModalShow]);

  //Framework List
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          serverConfig.api_base_url + "policy_framework_list_get",
          {
            token: token,
            org: selectedOrganization?.slug,
          }
        );

        console.log("policy_framework_list_get response", response);
        console.log("policy_framework_list_get response items", response);

        if (response.data.status === 200) {
          if (response.data.items !== null) {
            // Transform the data here if needed
            const transformedFrameworkList = response.data.items.map(
              (item) => ({
                label: item.name,
                value: item.name,
              })
            );
            setPolicyFrameworkList(transformedFrameworkList);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          setPolicyFrameworkList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      } catch (err) {
        console.log("error is", err);
      }
    };

    fetchData();
  }, [token, selectedOrganization, createNewPolictModalShow]);

  const handleCloseCreatePolicyModal = () => {
    setCreateNewPolictModalShow(false);
    setFormMessage({});
    setFormData({});
    setDueDate(null);
    setFramework("");
    setUser("");
    setControl("");
    setErrorMsg("");
  };
  const handleCreateNewPolicy = () => {
    setCreateNewPolictModalShow(true);
  };

  //Handle file change
  const handleChangeFile = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;

    if (event.target.type === "file") {
      var extension = value.split(".").pop();
      setFileFormat(extension);

      if (extension === "pdf") {
        console.log("File type matched");
        var file = event.target.files[0];
        if (file !== undefined) {
          console.log(file?.name, "file");
          setFile(event.target.files[0]);
          console.log(file);
          const fileNameWithoutExtension = file?.name.replace(/\.[^/.]+$/, "");
          setFiledata(fileNameWithoutExtension);
          setFormData((formData) => ({ ...formData, file: file.name }));
        }
      } else {
        alert("Supports only pdf document format.");
        event.target.value = null;
      }
    } else if (event.target.type === "date") {
      setDataCollectionDate(value);
    }
  };

  // Add file to policy
  const handleAddFile = () => {
    const fileToUpload = file;
    const extension = fileToUpload.name.split(".").pop();

    if (extension === "pdf") {
      const reader = new FileReader();

      reader.onload = () => {
        const fileDataUrl = reader.result;
        const fileSizeInMB = fileToUpload.size / 1048576;

        if (fileSizeInMB <= 10) {
          const formData = new FormData();
          formData.append("file", fileDataUrl);
          formData.append("token", token);
          formData.append("org", selectedOrganization?.slug);
          formData.append("pcy", addFilePcy);

          axios
            .post(serverConfig.japi_base_url + "policy_file_upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log("policy_file_upload", response);
              if (response.data.status === 200) {
                setAddFilePcy("");
                setFormMessage({
                  message: "Policy file created successfully",
                  type: "success",
                });
                setRefreshDBServiceList(!refreshDBServiceList);
                handleCloseCreatePolicyModal();
                handleShowAlertModal("Policy created successfully");
                setTimeout(() => {
                  handleCloseAlertModal();
                }, 6000);
              } else if (response.data.status === 301) {
                handleLogout();
              } else {
                handleShowAlertModal(response.data.message, true);
                setTimeout(() => {
                  handleCloseAlertModal();
                }, 6000);
                setFormMessage({});
              }
            })
            .catch((error) => {
              console.error("Error creating policy:", error);
              handleShowAlertModal(
                "An error occurred while creating the policy.",
                true
              );
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
              setFormMessage({});
            });
        } else {
          handleShowAlertModal(
            "File size exceeds maximum limit of 10MB. Please try again.",
            true
          );
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(fileToUpload);
    } else {
      handleShowAlertModal("Only PDF files are allowed.", true);
      setTimeout(() => {
        handleCloseAlertModal();
      }, 6000);
    }
  };

  // Create new policy
  const handleCreatePolicy = (event) => {
    event.preventDefault();
    console.log(formData);

    const selectedMembers = user.map((u) => u.value);
    const selectedFrameworks = framework.map((u) => u.value);

    const items = {
      name: formData.name,
      description: formData.description,
      due_date: formData.due_date,
      members: selectedMembers,
      control: formData.control.value,
      framework: selectedFrameworks,
      control_code: formData.control_code,
      start_date: new Date()
        .toLocaleDateString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-"),
    };

    axios
      .post(serverConfig.api_base_url + "policy_create", {
        token: token,
        org: selectedOrganization?.slug,
        file_name: filedata,
        file_format: fileFormat,
        items: items,
      })
      .then((response) => {
        console.log("policy_create", response);

        if (response.data.status === 200) {
          setFormData({});
          setUser([]);
          setControl("");
          console.log(response.data.pcy);
          setAddFilePcy(response.data.pcy);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch((error) => {
        console.error("Error creating policy:", error);
        handleShowAlertModal(
          "An error occurred while creating the policy.",
          true
        );
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      });
  };

  // useEffect hook to watch for changes in addFilePcy and perform actions
  useEffect(() => {
    if (addFilePcy) {
      handleAddFile();
      setFormMessage({
        message: "Policy created successfully",
        type: "success",
      });
      setRefreshDBServiceList(!refreshDBServiceList);
      handleCloseCreatePolicyModal();
      handleShowAlertModal("Policy created successfully");
      setTimeout(() => {
        handleCloseAlertModal();
      }, 6000);
    }
  }, [addFilePcy]);

  // Edit/Update policy
  const handleEditPolicy = (event) => {
    event.preventDefault();
    console.log(formData);

    const selectedMembers = user.map((u) => u.value);
    const selectedFrameworks = framework.map((u) => u.value);

    const items = {
      name: formData.name,
      description: formData.description,
      due_date: formData.due_date,
      members: selectedMembers,
      control: formData.control.value,
      framework: selectedFrameworks,
      control_code: formData.control_code,
    };

    axios
      .post(serverConfig.api_base_url + "policy_update", {
        token: token,
        org: selectedOrganization?.slug,
        pcy: pcy,
        items: items,
      })
      .then((response) => {
        console.log("policy_update", response);
        if (response.data.status === 200) {
          setFormData({});
          setUser([]);
          setControl("");
          handleAddFile();
          setFormMessage({
            message: "Policy created successfully",
            type: "success",
          });
          setRefreshDBServiceList(!refreshDBServiceList);
          handleCloseEditPolicyModal();
          handleShowAlertModal("Policy created successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch((error) => {
        console.error("Error creating policy:", error);
        handleShowAlertModal(
          "An error occurred while creating the policy.",
          true
        );
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      });
  };

  //Edit DB Service
  const [dbServiceSlug, setDBServiceSlug] = useState("");
  const [dbServiceName, setDBServiceName] = useState("");
  const [editPolicyModalShow, setEdirPolicyModalShow] = useState(false);
  const handleCloseEditPolicyModal = () => {
    setEdirPolicyModalShow(false);
    setFormMessage({});
    setFormData({});
    setDueDate(null);
    setFramework("");
    setUser("");
    setControl("");
    setErrorMsg("");
  };

  const handleShowEditPolicy = (details) => {
    console.log(details);
    console.log(details.name);
    console.log(details.description);
    console.log(details.control_code);
    console.log(details.control);
    console.log(details.framework.map((f) => ({ value: f, label: f })));
    setFramework(details.framework.map((f) => ({ value: f, label: f })));
    console.log(details.due_date);
    setDueDate(details.due_date);
    console.log(details.member.map((m) => ({ value: m, label: m })));
    setUser(details.member.map((m) => ({ value: m, label: m })));
    setControl(details.control);
    setFormData(details);
    // setDbType({ label: details.db_type, value: details.db_type });
    setEdirPolicyModalShow(true);

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
  };

  const handleValidateDBService = (dbServiceSlug, event) => {
    event.preventDefault();

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }

    axios
      .post(serverConfig.api_base_url + "dwh_dbs_validate", {
        token: token,
        dbs: dbServiceSlug,
      })
      .then((response) => {
        console.log("dwh_dbs_validate:", response);
        if (response.data.status === 200) {
          setFormMessage({
            message: "Database Service validated successfully",
            type: "success",
          });
          setRefreshDBServiceList(!refreshDBServiceList);

          setDBServiceSlug("");
          setDBServiceName("");
          setFormMessage({});
          setFormData({});

          //handleCloseValidateDBServiceModal();
          handleShowAlertModal("Database Service validated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleCloseConfirmDeletePolicyPopup = () => {
    setConfirmDeletePolicy(false);
  };

  const handleDeletePolicy = () => {
    axios
      .post(serverConfig.api_base_url + "policy_delete", {
        token: token,
        org: selectedOrganization?.slug,
        pcy: deletePolicyDetails,
      })
      .then((response) => {
        console.log("policy_delete:", response);
        if (response.data.status === 200) {
          setConfirmDeletePolicy(false);
          setDeletePolicyDetails("");
          setFormMessage({
            message: "Policy deleted successfully",
            type: "success",
          });
          setRefreshDBServiceList(!refreshDBServiceList);
          //handleCloseValidateDBServiceModal();
          handleShowAlertModal("Policy deleted successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-dbs table-mapmetadata mt-4 ">
        <div className="flex justify-between items-center space-x-4">
          <h1 className="font-semibold text-2xl mt-4 mb-4">Policies</h1>
          {tableData.length > 0 && (
            <div className="flex items-center space-x-4 text-black">
              <button
                type="button"
                className="sub-grp px-3 py-2"
                onClick={handleCreateNewPolicy}
              >
                <i className="fa-solid fa-plus mr-2" aria-hidden="true"></i>{" "}
                Policies
              </button>
            </div>
          )}
        </div>
        {tableData.length > 0 ? (
          <DataTable
            columns={columns}
            data={tableData}
            expandableRows
            //expandableRowsComponent={({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>}
            expandableRowsComponent={({ data }) => (
              <Members pcy={data.pcy} org={selectedOrganization?.slug} />
            )}
            responsive={true}
            pagination={tableData.length > 10 ? true : false}
            highlightOnHover
            customStyles={{
              rows: {
                style: {
                  minHeight: "48px",
                  border: "2px solid var(--neutral-400)",
                  borderRadius: "10px",
                  marginTop: "10px",
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? "#ffffff" : "#000000",
                  backgroundColor: isDarkTheme ? "#222222" : "#EFF3F9",
                  outline: "none",
                },
              },
              expanderButton: {
                style: {
                  borderRadius: "8px",
                },
              },
            }}
          />
        ) : (
          <div className="flex flex-col h-3/4 items-center justify-center">
            <div className="mb-8">
              <img
                src={require("../../../Asset/Images/no-records.png")}
                alt="No records"
              />
            </div>
            <p>
              <b>This Organisation has no Policies</b>
            </p>
            <p className="text-sm">
              Create first policy {selectedOrganization?.name}
            </p>
            <div className="mt-5">
              <button
                className="sub-grp px-3 py-2"
                onClick={handleCreateNewPolicy}
              >
                New Policy
              </button>
            </div>
          </div>
        )}

        {createNewPolictModalShow && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Add Policy</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleCreatePolicy}
              >
                <div className="scrollable mt-5 mb-5">
                  <div className="mb-1">
                    <div className="flex flex-col gap-1 text-xs">
                      <label>
                        Policy Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={formData.name || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        placeholder="Name"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>Policy Description</label>
                      <textarea
                        value={formData.description || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                        placeholder="Policy Description"
                        className="custom-input py-2 px-2 text-left mt-1"
                      ></textarea>
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Control<span className=" text-red-500">*</span>
                      </label>
                      <Select
                        value={control || ""}
                        classNamePrefix="select"
                        placeholder="Select Control"
                        options={policyControlList}
                        onChange={(option) => {
                          const controlValue = option ? option : "";
                          setFormData({ ...formData, control: controlValue });
                          setControl(controlValue);
                        }}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Control Code<span className=" text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={formData.control_code || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            control_code: e.target.value,
                          })
                        }
                        placeholder="Name"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Labels<span className=" text-red-500">*</span>
                      </label>
                      <Select
                        value={framework || []}
                        classNamePrefix="select"
                        placeholder="Select Labels"
                        options={policyFrameworkList}
                        onChange={(selectedOptions) => {
                          setFormData({
                            ...formData,
                            selectedFramework: selectedOptions.map(
                              (option) => option.value
                            ),
                          });
                          setFramework(selectedOptions);
                        }}
                        isMulti
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Due Date<span className="text-red-500">*</span>
                      </label>
                      <DatePicker
                        selected={dueDate}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = date
                              .toLocaleDateString("en-IN", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                              .split("/")
                              .reverse()
                              .join("-");
                            setFormData({
                              ...formData,
                              due_date: formattedDate,
                            });
                            setDueDate(date);
                          }
                        }}
                        className="custom-input py-2 px-2 text-left mt-1"
                        minDate={new Date()}
                        placeholderText="Select a date"
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Upload Policy File
                        <span className=" text-red-500">*</span>
                      </label>
                      <Form.Group
                        controlId="uploadData"
                        className="upload-data mb-6 mt-1"
                      >
                        <Form.Label className="custom-input py-2 px-2 text-left mt-1 cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-100">
                          {formData.file ? (
                            <span className="text-xs">{formData.file}</span>
                          ) : (
                            <span className="text-xs">Choose File</span>
                          )}
                          <Form.Control
                            name="file"
                            type="file"
                            accept=".pdf"
                            onChange={handleChangeFile}
                            className="hidden"
                            // required
                          />
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Members<span className="text-red-500">*</span>
                      </label>
                      <Select
                        value={user || []}
                        classNamePrefix="select"
                        placeholder="Select Member"
                        options={userList}
                        onChange={(selectedOptions) => {
                          setFormData({
                            ...formData,
                            selectedMember: selectedOptions.map(
                              (option) => option.value
                            ),
                          });
                          setUser(selectedOptions);
                        }}
                        isMulti
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseCreatePolicyModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Add Policy
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {editPolicyModalShow && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                {/* {window.alert(formData.control)} */}
                <div style={{ fontSize: 16 }}>Edit Policy</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleEditPolicy}
              >
                <div className="scrollable mt-5 mb-5">
                  <div className="mb-1">
                    <div className="flex flex-col gap-1 text-xs">
                      <label>
                        Policy Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={formData.name || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        placeholder="Name"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>Policy Description</label>
                      <textarea
                        value={formData.description || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                        placeholder="Policy Description"
                        className="custom-input py-2 px-2 text-left mt-1"
                      ></textarea>
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Control<span className=" text-red-500">*</span>
                      </label>
                      <Select
                        value={formData.control}
                        classNamePrefix="select"
                        placeholder="Select Control"
                        options={policyControlList}
                        onChange={(option) => {
                          const controlValue = option ? option : "";
                          setFormData({ ...formData, control: controlValue });
                          setControl(controlValue);
                        }}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Control Code<span className=" text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={formData.control_code || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            control_code: e.target.value,
                          })
                        }
                        placeholder="Name"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Labels<span className=" text-red-500">*</span>
                      </label>
                      <Select
                        value={framework || []}
                        classNamePrefix="select"
                        placeholder="Select Labels"
                        options={policyFrameworkList}
                        onChange={(selectedOptions) => {
                          setFormData({
                            ...formData,
                            selectedFramework: selectedOptions.map(
                              (option) => option.value
                            ),
                          });
                          setFramework(selectedOptions);
                        }}
                        isMulti
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Due Date<span className="text-red-500">*</span>
                      </label>
                      <DatePicker
                        selected={dueDate}
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = date
                              .toLocaleDateString("en-IN", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                              .split("/")
                              .reverse()
                              .join("-");
                            setFormData({
                              ...formData,
                              due_date: formattedDate,
                            });
                            setDueDate(date);
                          }
                        }}
                        className="custom-input py-2 px-2 text-left mt-1"
                        minDate={new Date()}
                        placeholderText="Select a date"
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Upload Policy File
                        <span className=" text-red-500">*</span>
                      </label>
                      <Form.Group
                        controlId="uploadData"
                        className="upload-data mb-6 mt-1"
                      >
                        <Form.Label className="custom-input py-2 px-2 text-left mt-1 cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-100">
                          {formData.file ? (
                            <span className="text-xs">{formData.file}</span>
                          ) : (
                            <span className="text-xs">Choose File</span>
                          )}
                          <Form.Control
                            name="file"
                            type="file"
                            accept=".pdf"
                            onChange={handleChangeFile}
                            className="hidden"
                            // required
                          />
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="flex flex-col gap-1 mt-3 text-xs">
                      <label>
                        Members<span className="text-red-500">*</span>
                      </label>
                      <Select
                        value={user || []}
                        classNamePrefix="select"
                        placeholder="Select Member"
                        options={userList}
                        onChange={(selectedOptions) => {
                          setFormData({
                            ...formData,
                            selectedMember: selectedOptions.map(
                              (option) => option.value
                            ),
                          });
                          setUser(selectedOptions);
                        }}
                        isMulti
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseEditPolicyModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Update Policy
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {confirmDeletePolicy && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/3">
              <p className="text-sm text-medium">
                <b>Are you sure you want to delete this policy?</b>
              </p>
              <br></br>
              <div className="spacing-div">
                <button
                  type="button"
                  className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  onClick={handleCloseConfirmDeletePolicyPopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  onClick={handleDeletePolicy}
                >
                  Delete Policy
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PoliciesTable;
