import React, {useState} from "react";
import '../css/dataman.css';

const ShowError = ({uploadErrorList, modalClosed, setModalClosed}) => {
    const[tab, setTab] = useState('User Info');
    const[memberTab, setMemberTab]=useState(0);
    const[close, setClose] = useState(true);
    return (
        <>
        <div className='edit-user  rounded-3xl py-4 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col items-center'>
      <div className='overflow-y-auto '>
      <div className='flex items-center gap-8'>
      
           
    <div className='flex flex-col team-label'>

      <h5 className='mt-4 font-semibold mb-4'>
      {uploadErrorList.length}{uploadErrorList.length >= 50 ? '+' : null} errors found, please correct and re-upload the file
      </h5>

      {uploadErrorList.length > 0 && uploadErrorList.map((item, index) => {
                          return(
                            <>
                            { item?.message_type==='I' || item.message_type==='W' ? 
                            <div className="flex flex-row warning-text mt-2">

                            <img src={require("../assets/error.png")} height={30} className='error-img' />
                    
                            <div className="warning">{item?.message}</div>
                    
                          </div> :
                          item?.message_type==='E' ? 
                          
                          <div className="flex flex-row error-text mt-2">

                            <img src={require("../assets/error.png")} height={30} className='error-img'/>
                    
                            <div className="errors">{item?.message}</div>
                    
                          </div>

                          : <></> }
                            </>
                          )})}

      </div>
      </div>
      </div>
      <button className='absolute right-6 top-4' onClick={()=>{setModalClosed(!modalClosed)}}>
        <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
        </>
    )
}

export default ShowError;