import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserService from './../services/UserService';
import axios from 'axios';

const serverConfig = require("./../config/server.js");

function Tasks() {

  const [token, setToken] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const callbackUrl = searchParams.get('url');
  console.log(callbackUrl);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if (token !== null && token !== undefined) {
      redirectToApp();
    }
  }, [token]);

  function sendMessage() {
    window.ReactNativeWebView.postMessage('Hello from WebView!');
  }

  const redirectToApp = () => {
    var kezel_token = token;
    var callbackUrlFinal = callbackUrl + kezel_token;
    console.log(callbackUrlFinal);
    window.location.href = callbackUrlFinal;
  }

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <div><Link to={'/'}><img src={require('./../Asset/Images/logo.png')} alt='logo'/></Link></div>
      <p className='text-center'>Authentication successful.<br/> You will be redirected to Kezel App.</p>
      {/* <Link to={'/'} onClick={handleLogout}>Sign out</Link> */}
      {/* <br/><br/>
      <Button onClick={sendMessage}>Send Message to React Native App</Button>
      <br/><br/>
      <Button onClick={redirectToApp}>Redirect to React Native App</Button> */}
    </div>
  );

}

export default Tasks