import React from 'react';
import { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contextApi/context';
import NewSubgroupPopup from '../groupPopup/NewSubgroupPopup';
import EditSubgroupPopup from '../groupPopup/EditSubgroupPopup.js';
import TableMembers from './TableMembers.js';
import TableTask from './TableTask.js';
import IssueStatus from './IssueStatus.js';
import IssueCategory from './IssueCategory.js';
import IssueRelations from '../components/IssueRelations.js';
import axios from 'axios';
import * as FaIcons from 'react-icons/fa';
import "../css/style.css";

const serverConfig = require("./../../config/server.js");

const TableSubgroup = ({token, selectedOrganization, handleLogout, newSubgroupPopup, setPopup, groupDetails, handleCloseAlertModal, handleShowAlertModal, isPlanner}) => {

  const {isDarkTheme} = useContext(Context);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  //const { tab, setTab } = useContext(Context);
  const [tab, setTab] = useState(groupDetails?.currentTab ? groupDetails?.currentTab : isPlanner ? 'Sprints' : 'Subgroups');
  const [addGroup, setAddGroup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [viewPrivilege, setViewPrivilege] = useState(false);
  const [refreshGroup, setRefreshGroup] = useState(false);
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [popupCurrentTab, setPopupCurrentTab] = useState("");

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;

  const dropdownItems = ['Updated', 'Option 2', 'Option 3']
  const sortingIcons = [sortDown, sortUp]
  const columns = [
    {
      name: '',
      cell: (row) => <div className="flex-column flex items-center py-3">
        <img src={row.logo_base64 ? row.logo_base64 : `https://ui-avatars.com/api/?name=${row.name}
                    &length=1
                    &background=${row.default_color.replace('#','')}&format=svg&color=fff`} alt='image'
          height={40} width={40} className='rounded-lg' data-tag="allowRowEvents" />
      </div>,
      grow: 0,
      sortable: false,
    },
    {
      selector: row => row.name,
      name: <div className='row-label'>{isPlanner ? 'Sprint' : 'Subgroup'}</div>,
      cell: (row) => <div className="flex-column  py-2 ">
        <div className='text-heading' data-tag="allowRowEvents">{row.name}</div>
        {/* <div className='text-detail pt-1'>{row.detail}</div> */}
        <div className='text-detail pt-1' data-tag="allowRowEvents">{selectedOrganization?.name} | {groupDetails?.name}</div>
      </div>,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='row-label'>Type</div>,
      selector: row => row.type_details.type,
      cell: (row) => {
        let typeStyle;
        if(isDarkTheme){
          typeStyle={
            borderColor: row.type_details.defaultBorderColorDarkTheme,
            background: row.type_details.defaultBgColorDarkTheme+'20',
            color: row.type_details.defaultColorDarkTheme
          }
        } else {
          typeStyle={
            borderColor: row.type_details.defaultBorderColorLightTheme,
            background: row.type_details.defaultBgColorLightTheme+'20',
            color: row.type_details.defaultColorLightTheme
          }
        }
        return(<div className="type" style={typeStyle} data-tag="allowRowEvents">
          <div><span data-tag="allowRowEvents">{React.createElement(FaIcons[row?.type_details?.defaultIconClass])}</span></div><div data-tag="allowRowEvents">{row.type_details.type}</div>
        </div>)},
      sortable: true,
      wrap: true
    },
    {
      name: '',
      cell: (row) => <div className="flex-column  py-2 pl-3">
        <div data-tag="allowRowEvents">
          {row?.sprint_attributes?.start_date && <>
            {new Date(row?.sprint_attributes?.start_date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })} &ndash; {new Date(row?.sprint_attributes?.end_date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })}
          </>}
        </div>
      </div>,
      sortable: false,
      wrap: true,
    },
    {
      name: '',
      cell: (row) => isPlanner ? <div className='flex align-middle justify-center text-center' data-tag="allowRowEvents">
        <div className='p-2 not-0 relative' data-tag="allowRowEvents" onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button disabled={row.count_details.subgroups === 0} onClick={() => navigate(`/planner/sprints?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: isPlanner ? 'Sprints' : 'Subgroups', type_details:row.type_details}})}>
            <i className="fa-regular fa-folder" data-tag="allowRowEvents"></i> <span>{row.count_details.subgroups}</span>
            <div className="tooltip tooltip-top hidden">{isPlanner ? 'Sprints' : 'Subgroups'}</div>
          </button>
        </div>
        <div className='p-2 not-0 relative' data-tag="allowRowEvents" onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button disabled={row.count_details.members === 0} onClick={() => navigate(row.type_details.type !== "Sprint" ? `/planner/sprints?${row.grp}` : `/planner/sprints/sprint?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Members", type_details:row.type_details}})}>
            <i className="fa-solid fa-user-group" data-tag="allowRowEvents"></i> <span>{row.count_details.members}</span>
            <div className="tooltip tooltip-top hidden">Members</div>
          </button>
        </div>
        <div className='ml-8 p-2 not-0 relative' data-tag="allowRowEvents" onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button disabled={row.count_details.openTasks === 0} onClick={() => navigate(row.type_details.type !== "Sprint" ? `/planner/sprints?${row.grp}` : `/planner/sprints/sprint?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Tasks", type_details:row.type_details}})}>
            <i className="fa-solid fa-list-check" data-tag="allowRowEvents"></i> <span>{row.count_details.openTasks}</span>
            <div className="tooltip tooltip-top hidden">Open Tasks</div>
          </button>
        </div>
      </div> : <div className='flex align-middle justify-center text-center' data-tag="allowRowEvents">
        {row.type_details.type !== "Sprint" && <div className='p-2 not-0 relative' data-tag="allowRowEvents" onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button disabled={row.count_details.subgroups === 0} onClick={() => navigate(`/groups/subgroups?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: isPlanner ? 'Sprints' : 'Subgroups'}})}>
            <i className="fa-regular fa-folder" data-tag="allowRowEvents"></i> <span>{row.count_details.subgroups}</span>
            <div className="tooltip tooltip-top hidden">{isPlanner ? 'Sprints' : 'Subgroups'}</div>
          </button>
        </div>}
        <div className='mx-6 p-2 not-0 relative' data-tag="allowRowEvents" onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button disabled={row.count_details.resources === 0} onClick={() => navigate(`/groups/subgroups?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Resources", type_details:row.type_details}})}>
            <i className="fa-solid fa-screwdriver-wrench not-0" data-tag="allowRowEvents"></i> <span>{row.count_details.resources ? row.count_details.resources : 0}</span>
            <div className="tooltip tooltip-top hidden">Resources</div>
          </button>
        </div>
        <div className='p-2 not-0 relative' data-tag="allowRowEvents" onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button disabled={row.count_details.members === 0} onClick={() => navigate(`/groups/subgroups?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Members", type_details:row.type_details}})}>
            <i className="fa-solid fa-user-group" data-tag="allowRowEvents"></i> <span>{row.count_details.members}</span>
            <div className="tooltip tooltip-top hidden">Members</div>
          </button>
        </div>
        <div className='ml-8 p-2 not-0 relative' data-tag="allowRowEvents" onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
          <button disabled={row.count_details.openTasks === 0} onClick={() => navigate(`/groups/subgroups?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, currentTab: "Tasks", type_details:row.type_details}})}>
            <i className="fa-solid fa-list-check" data-tag="allowRowEvents"></i> <span>{row.count_details.openTasks}</span>
            <div className="tooltip tooltip-top hidden">Open Tasks</div>
          </button>
        </div>
      </div>,
      // selector: row =>,
      sortable: false,
      right: true,
      grow: 2,
      wrap: true
    },
    {
      cell: (row) => <div className="text-right more-options-wrapper">
        <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
        <div className="more-options" style={{display: "none"}}>
          <button type="button" onClick={() => handleShowEditGroupModal(row)}><i className="fa-solid fa-pencil" aria-hidden="true"></i> <span>Edit {isPlanner ? 'Sprint' : 'Subgroup'}</span></button>
          <button type="button" /* onClick={() => handleDeleteColumn(items.seq_no, items.colm)} */><i className="fa-solid fa-ban"></i> <span>Disable {isPlanner ? 'Sprint' : 'Subgroup'}</span></button>
          <button type="button" /* onClick={() => handleDeleteColumn(items.seq_no, items.colm)} */><i className="fa-solid fa-box-archive"></i> <span>Archive {isPlanner ? 'Sprint' : 'Subgroup'}</span></button>
        </div>
      </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleShowTooltip = (event) => {
    event.target.closest('.relative').querySelector('.tooltip').style.display='block';
  }
  const handleHideTooltip = (event) => {
    event.target.closest('.relative').querySelector('.tooltip').style.display='none';
  }

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null && groupDetails?.grp !== null && groupDetails?.grp !== undefined){
      setIsLoading(true);
      console.log(groupDetails?.currentTab);
      setTab(groupDetails?.currentTab ? groupDetails?.currentTab : isPlanner ? 'Sprints' : 'Subgroups');

      console.log('selectedOrganization', selectedOrganization, groupDetails?.grp)

      axios.post(serverConfig.api_base_url + "group_list_get",{token:token, org:selectedOrganization?.slug, grp:groupDetails.grp})
      .then(response=>{
        console.log('group_list_get response', response);
        if(response.data.status === 200){
          setViewPrivilege(true);
          if(response.data.items !== null){
            console.log(response.data.items);
            setTableData(response.data.items);
          } else {
            setTableData([]);
          }
          setIsLoading(false);
        } else if(response.data.status === 301){
          handleLogout();
        } else if(response.data.status === 401){
          setViewPrivilege(false);
          setIsLoading(false);
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));

      /* user_get_list */
      axios.post(serverConfig.api_base_url + "user_get_list",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("user_get_list response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(items);
            setUserList(items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization, groupDetails, refreshGroup]);

  useEffect(() => {
    if(newSubgroupPopup) {
      setAddGroup(true);
    }
  }, [newSubgroupPopup]);

  useEffect(() => {
    console.log(addGroup);
    console.log(newSubgroupPopup);
    if(addGroup) {
      //setAddGroup(false);
    } else {
      setAddGroup(false);
      setPopup(false);
    }
  }, [addGroup]);

  const refreshGroupTable = () => {
    setRefreshGroup(!refreshGroup);
  }

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
  
    // Get the bounding rectangle of the more-options-wrapper and the container
    const moreOptionsWrapper = event.target.closest('.more-options-wrapper');
    const moreOptionsRect = moreOptionsWrapper.getBoundingClientRect();
    const containerRect = moreOptionsWrapper.closest('.table-custom').getBoundingClientRect();
  
    // Check if the bottom of the more-options-wrapper is near the bottom of the container
    const isNearBottom = moreOptionsRect.bottom + 100 > containerRect.bottom;
  
    // Set the position of the more-options menu
    if (isNearBottom) {
      x.style.bottom = '100%'; // Open upwards
      x.style.top = 'auto';
    } else {
      x.style.top = '100%'; // Open downwards
      x.style.bottom = 'auto';
    }
  
    // Toggle visibility of the more-options menu
    if (x.style.display === "none" || x.style.display === '') {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }  

  //Edit
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const handleShowEditGroupModal = (details) => {
    setFormData(details);
    setShowEditGroupModal(true);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const [newTaskPopup, setNewTaskPopup] = useState(false);

  return (
    <div className="">
      {viewPrivilege && <div className="rounded-3xl p-3 table-custom mt-4 relative flex flex-col">
        {isLoading && <div className='loading absolute'><i className="fa-solid fa-spinner fa-spin-pulse"></i></div>}
        {/* Left side: Three tabs flexed together */}
        <div className="flex space-x-4 z-0">
          {isPlanner && <button className={`px-6 py-3 cursor-pointer ${tab === "Sprints" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Sprints")}>Sprints</button>}
          {!isPlanner && <button className={`px-6 py-3 cursor-pointer ${tab === "Subgroups" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Subgroups")}>Subgroups</button>}
          {/* <div className={`px-6 py-3 cursor-pointer ${tab === "Finances" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Finances")}>Finances</div> */}
          <div className={`px-6 py-3 cursor-pointer ${tab === "Settings" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Settings")}>Settings</div>
          <div className={`px-6 py-3 cursor-pointer ${tab === "Members" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Members")}>Members</div>
          {/* <div className={`px-6 py-3 cursor-pointer ${tab === "Resources" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Resources")}>Resources</div> */}
          <div className={`px-6 py-3 cursor-pointer ${tab === "Tasks" ? 'tab' : 'tab-unactive'}`} onClick={() => setTab("Tasks")}>Tasks</div>
        </div>
          
        {(tab === "Sprints" || tab === "Subgroups") && <>
          {tableData.length > 0 ? <DataTable
            title={<div className=" table-title rounded">
              <div className=" mx-auto flex justify-between items-center">
                {/* Left side: Three tabs flexed together */}
                {/* <div className="flex space-x-4">
                          <button className={`px-6 py-3 cursor-pointer ${tab===1 ? 'tab' : 'tab-unactive'}`} onClick={()=>setTab(0)}>Subgroup</button>
                          <div className={`px-6 py-3 cursor-pointer ${tab===2 ? 'tab' : 'tab-unactive'}`} onClick={()=>setTab(1)}>Resouces</div>
                          <div className={`px-6 py-3 cursor-pointer ${tab===3 ? 'tab' : 'tab-unactive'}`} onClick={()=>setTab(2)}>Task</div>
                        </div> */}

                <div className='task px-1'>My {isPlanner ? 'Sprints' : 'Subgroups'}</div>

                {/* Right side: Search input and two dropdowns */}
                <div className="flex items-center space-x-4 text-black">
                  {/* <div className="flex items-center gap-12 mt-4">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search"
                        className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
                      />
                      <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                        <i className=" text-sm fas fa-search"></i>
                      </div>
                    </div>
                  </div>

                  <div className="relative inline-block">
                    <select className="w-48 h-8 md:h-10 px-4 bg-white border border-gray-300 rounded-md focus:outline-none   appearance-none search-input text-style dark-optimise">
                      {dropdownItems.map((item, index) => (
                        <option key={index} value={item} className='dark-optimise'>
                          {item}
                        </option>
                      ))}
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <i className="fa-solid fa-chevron-down text-sm "></i>
                    </div>
                  </div>

                  <div className="relative inline-block">
                    <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
                      <div className='pr-5'>
                        <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <i className="fa-solid fa-chevron-down text-sm"></i>
                    </div>
                  </div> */}

                  <button className='sub-grp px-3 py-2' onClick={() => { setAddGroup(true) }} >New {isPlanner ? 'Sprint' : 'Subgroup'}</button>
                </div>
              </div>
            </div>}
            columns={columns}
            data={tableData}
            responsive={true}
            pagination={tableData.length > 10 ? true : false}
            highlightOnHover
            pointerOnHover
            onRowClicked={(row) => {
              if(isPlanner){
                if(row.type_details.type === "Sprint"){
                  navigate(`/planner/sprints/sprint?${row.grp}`,{state:{name:row.name, logo:row?.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, count_details:row.count_details, type_details:row.type_details}})
                } else {
                  navigate(`/planner/sprints?${row.grp}`,{state:{name:row.name, logo:row.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, count_details:row.count_details, type_details:row.type_details}})
                }
              } else {
                navigate(`/groups/subgroups?${row.grp}`,{state:{name:row.name, logo:row.logo_base64, grp:row.grp, bgColor: row.default_color.replace('#',''), default_color:row.default_color, count_details:row.count_details, type_details:row.type_details}})
              }
            }}
            customStyles={{
              rows: {
                highlightOnHoverStyle: {
                  color: isDarkTheme ? '#ffffff' : '#000000',
                  backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                  borderRadius: '14px',
                  outline: 'none'
                }
              }
            }}
          /> : <div className='flex flex-col h-full items-center justify-center'>
            <div className='mb-8'><img src={require('./../images/no-subgroups.png')} alt="No subgroups" /></div>
            <p><b>This {isPlanner ? 'Project' : 'Subgroup'} has no {isPlanner ? 'Sprints' : 'Subgroups'}</b></p>
            <p className='text-sm'>Create first {isPlanner ? 'Sprint' : 'Subgroup'} for {groupDetails?.name}</p>
            <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={() => { setAddGroup(true) }} >New {isPlanner ? 'Sprint' : 'Subgroup'}</button></div>
          </div>}
        </>}

        {(tab === "Finances" || tab === "Resources") && <div className='flex flex-col items-center justify-center flex-auto'>
          <img src={require('./../../Asset/Images/comingsoon.jpg')} width="400" className='under-dev-img' style={{width:"400px", height:"auto"}} />
          <h3 class="under-dev"><b>This feature is under development.</b></h3>
        </div>}

        {tab === "Members" &&
          <TableMembers token={token} handleLogout={handleLogout} selectedOrganization={selectedOrganization} grp={groupDetails?.grp} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleShowEditGroupModal={() => {handleShowEditGroupModal(groupDetails); setPopupCurrentTab("Members")}} isPlanner={isPlanner} />
        }

        {tab === "Tasks" &&
          <div><TableTask token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} newTaskPopup={newTaskPopup} setPopup={setNewTaskPopup} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} grp={groupDetails?.grp} groupDetails={groupDetails} isPlanner={isPlanner} /></div>
        }

        {tab === "Settings" &&
          <div className='flex flex-col table-container'>

            <IssueStatus token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupDetails?.grp} groupDetails={groupDetails} isPlanner={isPlanner} />

            <IssueCategory token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupDetails?.grp} groupDetails={groupDetails} isPlanner={isPlanner} />

            {/* <IssueRelations token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupDetails?.grp} groupDetails={groupDetails} /> */}

          </div>
        }

        {addGroup === true ? <div className='overlay'><NewSubgroupPopup setNewGroupPopup={setAddGroup} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} refreshGroupTable={refreshGroupTable} groupDetails={groupDetails} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} userList={userList} isPlanner={isPlanner} /></div> : <></>}
      </div>}

      {showEditGroupModal ? <div className='overlay'><EditSubgroupPopup setEditGroupPopup={setShowEditGroupModal} details={formData} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} refreshGroupTable={refreshGroupTable} groupDetails={groupDetails} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} userList={userList} popupCurrentTab={popupCurrentTab} isPlanner={isPlanner} /></div> : <></>}
    </div>
  );
}

export default TableSubgroup;