import React, { useEffect, useState, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarView = ({
  timeLogCalendar,
  startDate, 
  endDate,   
}) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (timeLogCalendar && Array.isArray(timeLogCalendar)) {
      const formattedEvents = timeLogCalendar.flatMap((entry) =>
        entry.project.map((project) => {
          const start = new Date(project.start_time + ' UTC');
          const end = new Date(project.end_time + ' UTC');
          return {
            start,
            end,
            title: project.task_name,
            desc: `${project.org_name} | ${project.group.name}`,
            allDay: false,
          };
        })
      );
      setEvents(formattedEvents);
    } else {
      setEvents([]);
    }
  }, [timeLogCalendar]);

  const startOfWeek = useMemo(() => new Date(startDate), [startDate]);
  const endOfWeek = useMemo(() => moment(startOfWeek).endOf('week').toDate(), [startOfWeek]);

  const minTime = new Date(startOfWeek);
  minTime.setHours(0, 0, 0, 0); // Start at 12 AM

  const maxTime = new Date(startOfWeek);
  maxTime.setHours(23, 59, 59, 999); // End at 11:59 PM

  if (!events || events.length === 0) {
    return (
      <div className="flex flex-col h-full items-center justify-center">
        <div className="mb-8">
          <img
            src={require("./../images/no-groups-subgroups.png")}
            alt="No time entry"
          />
        </div>
        <p className="text-center">
          <b>
            This Organisation has no Time entry
            <br /> for the selected Date range
          </b>
        </p>
      </div>
    );
  }

  const eventStyleGetter = () => {
    const style = {
      backgroundColor: '#63dcd3',
      borderRadius: '10px',
      color: 'black',
      border: '1px solid #63dcd3', 
      display: 'block',
      fontWeight: '600', 
      padding: '5px', 
    };
  
    return {
      style,
    };
  };
  
  

  return (
    <div style={{ height: '100%', marginTop: '10px' }}>
      <Calendar
        key={`${startDate}-${endDate}`}  
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        views={['week']}
        defaultDate={startOfWeek}  
        toolbar={false}  
        min={minTime} 
        max={maxTime} 
        style={{ height: '92%', overflow: 'visible' }}
        eventPropGetter={eventStyleGetter} 
        components={{
          timeSlotWrapper: ({ children }) => (
            <div style={{ minHeight: '30px' }}>{children}</div> 
          ),
        }}
      />
    </div>
  );
};

export default CalendarView;
