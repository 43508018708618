import React, { useEffect, useState } from "react";
import DataGrid from "../datagrid/DataGrid.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { wrap } from "lodash";

const TableChart = ({
  data,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState("");

  useEffect(() => {
    if (data === "fetching") {
      setIsLoading(true);
      setNoDataFound("");
    } else if (data.length > 0) {
      setIsLoading(false);
      setColumns(
        item.data_sequence.map((column) => ({
          name: column.replace(/_/g, " ").toUpperCase(),
          selector: (row) => row[column],
          sortable: (data.length > 1 ? true : false),
          reorder: true,
          wrap: true,
          width:"60px"
        }))
      );
      setTableData(data);
    } else {
      setIsLoading(false);
      setNoDataFound("No data found");
    }
  }, [data, item]);

  const RowDetails = ({ data }) => (
    <div className="m-4">
      <div className="d-flex align-items-center border-bottom mb-2">
        <h6 className="mb-1 me-1">Additional Details of {data?.name}</h6>
      </div>
      <a
        target="_blank"
        className="ps-2"
        title={data.website}
        style={{ cursor: "pointer" }}
        href={data.website}
        rel="noreferrer"
      >
        <FontAwesomeIcon className="me-2" icon={faGlobe} />
        {data.website}
      </a>
      <div className="mt-2 ps-4 bg-light border rounded">
        {`Desktop + Mobile : ${data.desktop + data.mobile}`}
      </div>
    </div>
  );

  const handleShowMoreOptions = (event) => {
    const options = document.querySelectorAll(
      ".more-options-wrapper .more-options"
    );
    const current = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    options.forEach((opt) => (opt.style.display = "none"));
    current.style.display = current.style.display === "none" ? "block" : "none";
  };

  return (
    <>
      {noDataFound ? (
        <div className="chart-title" style={{ padding: "12px" }}>
          {noDataFound}
        </div>
      ) : isLoading ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <>
          {/* {item?.title && (
            <div className="chart-header">
              <div className="chart-title">{item.title}</div>
              
            </div>
          )} */}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
          <div className="table-chart-container">
            <div className="table-chart">
              <DataGrid
                columns={columns}
                data={tableData}
                title={item?.title}
                options={{
                  headerBgColor: "#fec71e",
                  headerColor: "black",
                  headerFontSize: 11,
                  cellFontSize: 11,
                  showHeader: true, 
                  export: true,
                  headerTitle: item?.title,
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TableChart;
