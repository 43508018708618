import React, { useState, useEffect } from 'react';
import axios from 'axios';

const serverConfig = require("./../../config/server.js");

function Comment({taskSlug, token, selectedOrganization, handleShowAlertModal, handleCloseAlertModal}) {
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState([]);
  const [refreshCommentList, setRefreshCommentList] = useState(false);

  const getTaskCommentList = () => {
    axios.post(serverConfig.api_base_url + "task_comment_list_get", {token:token, org:selectedOrganization?.slug, tsk: taskSlug})
    .then(response=>{
      console.log('task_comment_list_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setCommentList(response.data.items.reverse());
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      getTaskCommentList();
    }
  }, [token, selectedOrganization, refreshCommentList]);

  const handleSubmitComments = () => {
    console.log(comment);
    if(comment !== null && comment !== undefined && comment !==''){
      axios.post(serverConfig.api_base_url + "task_comment_create", {token:token, org:selectedOrganization?.slug, tsk:taskSlug, comment:comment})
      .then(response=>{
        console.log('task_comment_create response', response);
        if(response.data.status === 200){
          console.log("success");
          handleShowAlertModal(`Your comment "${comment}" has been added`);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setComment('');
          setRefreshCommentList(!refreshCommentList);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  return (
    <div className='mt-5'>
      <div>
        <textarea className=' comment-box w-full text-sm  text-custom  p-2  h-24' onChange={(e) => { setComment(e.target.value) }} value={comment} />
        <button type="button" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleSubmitComments}>Send</button>
      </div>
      {commentList.length > 0 && <ul className='mt-5'>
        {commentList.map((item,  index) => {
          return(
            <li className='flex items-start text-sm mb-2'>
              <img className='rounded-3xl image-logo' height={40} width={40} src={`https://ui-avatars.com/api/?name=${item.username.split(" ")[0]}+${item?.username.split(" ").length > 1 ? item?.username.split(" ")[item?.username.split(" ").length - 1] : ""}&length=2&background=6ADA00&format=svg&color=fff`} alt={item.username} />
              <div className='ml-2'>
                <div>
                  <b>{item.username}</b>
                  <span className='ml-2'>{item.time.split(".")[0]} {item.is_edited === 1 && <>(edited)</>}</span>
                </div>
                <p>{item.comment}</p>
              </div>
            </li>
          )
        })}  
      </ul>}
    </div>
  )
}
function WorkLogs() {
  return (
    <></>
  )
}
function History() {
  return (
    <></>
  )
}
function CommentMain({taskSlug, token, selectedOrganization, handleShowAlertModal, handleCloseAlertModal}) {
  const [tabValue, setTabValue] = useState('comment')
  return (
    <div>
      <div className='flex justify-start align-middle gap-6 mt-9'>
        <button type="button" onClick={() => { setTabValue('comment') }} className={tabValue === 'comment' ? 'task-tab px-5 py-3 text-xs' : 'text-gray-500 text-xs  px-5 py-3 font-medium'}>Comments</button>
        <button type="button" onClick={() => { setTabValue('workLogs') }} className={tabValue === 'workLogs' ? 'task-tab px-5 py-3 text-xs' : 'text-gray-500 text-xs  px-5 py-3 font-medium'}>Work Logs</button>
        <button type="button" onClick={() => { setTabValue('history') }} className={tabValue === 'history' ? 'task-tab px-5 py-3 text-xs' : 'text-gray-500 text-xs  px-5 py-3 font-medium'}>History</button>
      </div>
      {tabValue === 'comment' && <Comment taskSlug={taskSlug} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />}
      {tabValue === 'workLogs' && <WorkLogs />}
      {tabValue === 'history' && <History />}

    </div>
  )
}

export default CommentMain;