import React, { PureComponent, useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../css/adminDashboard.css";

const RCMultiLineChart = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const generateColors = (count) => {
    const colors = [
      "#3366cc",
      "#dc3911",
      "#ff9900",
      "#0d9618",
      "#990099",
      "#0099c6",
      "#dd4477",
      "#66aa02",
      "#b82e2f",
      "#326395",
    ];
    
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(colors[i % colors.length]); // Cycle through the palette
    }
    
    return result;
  };
  
  const svgRef = useRef();
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [yKeys, setYKeys] = useState([]);

  const transformData = (data, group, xCord, yCord) => {
    const groupedData = data?.reduce((acc, curr) => {
      if (!acc[curr[xCord]]) {
        acc[curr[xCord]] = { [xCord]: curr[xCord] };
      }
      acc[curr[xCord]][curr[group]] = curr[yCord];
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const chartdata = transformData(
        apiData?.data,
        apiData?.group,
        apiData?.x_cord,
        apiData?.y_cord
      );
      setData(chartdata);
      const keys = [...new Set(apiData?.data.map((d) => d[apiData?.group]))];
      setYKeys(keys);
      console.log(chartdata, keys);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  const colors = generateColors(yKeys.length);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title" style={{padding: "12px"}}>{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title" style={{padding: "12px"}}>{item?.title} </div>
          ) : (
            <div className="no-chart-title"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div style={{ height: "500px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={item?.x_cord}
                label={{
                  value: item?.x_axis_label,
                  position: "insideBottom",
                  offset: -5, // Adjusted offset for better positioning
                  fontSize: 14,
                  fontWeight: 600,
                  fill: "#000",
                }}
              />
              <YAxis
                label={{
                  value: item?.y_axis_label,
                  angle: -90,
                  position: "insideLeft",
                  offset: 10,
                  dy: 150,
                  fontWeight: 600,
                }}
              />
              <Tooltip
                contentStyle={{
                  fontSize: "14px",
                  padding: "10px",
                  backgroundColor: "#f5f5f5",
                  opacity: "0.75",
                  borderRadius: "5px",
                }}
              />
              <Legend
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{ fontSize: "12px", padding: "8px" }}
              />
              {yKeys?.map((key, index) => {
                return (
                  <Line
                    connectNulls={true}
                    type="monotone"
                    dataKey={key}
                    stroke={colors[index]}
                    activeDot={{ r: 8 }}
                    isAnimationActive={true}
                    animationEasing="ease-out"
                    animationDuration={100}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default RCMultiLineChart;
