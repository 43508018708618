import React, { useState } from 'react';

function Search({orgList, setOrganizationList, 
                grpList, setGroupList,
                orgMembrList, setOrgMemberList,
                srchList, setSearchList,
}) {
    
  const[isFocus, setIsFocus]=useState(false);

  const handleSearch = (searchVal) => {
    if(orgList){
      console.log(searchVal);
      //console.log(organizationList);
      console.log(orgList);
      console.log(orgList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));

      setOrganizationList(orgList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
    }

    if(grpList){
      console.log(searchVal);
      //console.log(organizationList);
      console.log(grpList);
      console.log(grpList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));

      setGroupList(grpList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
    }
    
    if(orgMembrList){
      setOrgMemberList(orgMembrList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
    }

    if(srchList){
      setSearchList(srchList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
    }
  }

  return (
    <div class="relative">
      <input
        type="text"
        placeholder="Search"
        class=" h-9 w-40 w-rounded border-1 border-gray-300  search-input p-2"
        onFocus={()=>{setIsFocus(true)}}
        onBlur={()=>{setIsFocus(false)}}
        onChange={(e) => handleSearch(e.target.value)}
      />
      {!isFocus && <div class="absolute inset-y-0 left-5 pb-3.5 flex items-center pointer-events-none">
        <i class="text-custom-icon-color text-sm fas fa-search text-gray-200"></i>
      </div>}
    </div>
  )
}

export default Search;