import React, { useState, useContext } from 'react';
import { Context } from '../../contextApi/context';
import Table from './tables/Table';

const Customers = () => {
  
  const {collapse, tab,taskPopup} = useContext(Context)
  const[newGroupPopup , setNewGroupPopup]= useState(false)

    return (
        <>
      <div>
      {/* <HeaderSecond linkName={'New Group'} link={'#'} popup={newGroupPopup} setPopup={setNewGroupPopup}/> */}
      {/* {newGroupPopup &&<div className='overlay'> <NewGroupPopup setNewGroupPopup={setNewGroupPopup}/></div>} */}
      <div className={collapse?'content-body':'content-body-collapse'} >
      <div className="container-fluid">
        <Table/>
        {/* subgroup  */}
    
      </div>
     
      </div>
    </div>
        </>
    );
}

export default Customers;