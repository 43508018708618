import React, { PureComponent, useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, } from "recharts";
import * as XLSX from 'xlsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as htmlToImage from 'html-to-image';

const RCPieChart = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
  handleSubmit,
  filterData
}) => {
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [chartType, setChartType] = useState("export");

    // method - chart optons
    const UpdateType = (menu) => {
        if (menu !== "image" && menu !== "export") {
            setChartType(menu?.trim()?.toLowerCase());
        }
        else if (menu === "export") {
            // export to excel functionlity
            exportToExcel((name || "Export"), data);
        }
        else {
            // Download as Image
            //OnDownloadClick("chartDashboard");
        }
    }

    // method - download chart as an Image (.png)
  //   const OnDownloadClick = async (tagName) => {
  //     await htmlToImage.toPng(document.getElementsByClassName(tagName)[0])
  //         .then(function (dataUrl) {
  //             const link = document.createElement('a')
  //             link.download = "Chart"
  //             link.href = dataUrl
  //             link.click()
  //         })
  //         .catch((err) => {
  //             // nothing
  //         });
  // }

const exportToExcel = (filename, data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const date = new Date();

    const formattedFileName = `${filename}_${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${formattedFileName}.xlsx`);
};

const optionsExport = ["export"];


  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
  };

  const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 5) * cos;
    const my = cy + (outerRadius + 5) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name} (${payload.value})`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(payload.value * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      setData(apiData?.data);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  // Custom label to display on Pie chart
  const renderCustomizedLabel = ({ name, percent }) => {
    return `${name} (${(percent * 100).toFixed(0)}%)`;
  };

  // Define colors for the slices
  const COLORS =  [
    "#3366cc",
    "#dc3911",
    "#ff9900",
    "#0d9618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#66aa02",
    "#b82e2f",
    "#326395",
  ];

  const handlePieClick = async (pieData) => {
    if(pieData?.payload[item?.label_column] !== "Others")
    {
        const newFilter = {
            filter_label: item?.operator?.filter_label,
            optr: item?.operator?.optr,
            filter_operator: item?.operator?.operator_label,
            array_value: [pieData?.payload[item?.label_column]],
            fltr: item?.operator?.fltr, 
            value: null
          };

        const existingFilterIndex = filterData?.findIndex(
            filter => filter?.fltr === newFilter?.fltr
        );
        let updatedFilters = [...filterData];

        if (existingFilterIndex !== -1) {
            updatedFilters[existingFilterIndex] = {
                ...updatedFilters[existingFilterIndex],
                array_value: [
                    ...new Set([...updatedFilters[existingFilterIndex]?.array_value, ...newFilter?.array_value])
                ] // using Set to avoid duplicate values
            };
        } else {
            updatedFilters.push(newFilter);
        }

        handleSubmit(updatedFilters, null);
      }
    }

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="flex flex row justify-between">
            <div className="chart-title" style={{padding: "12px"}}>{item?.title} </div> 

            {optionsExport?.length ? 
                  <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                      <Popover.Body>
                          {optionsExport?.map((menu, index) => (
                              <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                                  <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                              </div>
                          ))}
                      </Popover.Body>
                  </Popover>}>
                      <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                          <i className="fa-solid fa-ellipsis-vertical not-0" style={{color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px"}}></i>
                          {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                          </span>
                  </OverlayTrigger></div> : null}
            </div>

          ) : (
            <div className="no-chart-title flex flex-row justify-between">
            <div></div>
            {optionsExport?.length ? 
                <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                    <Popover.Body>
                        {optionsExport?.map((menu, index) => (
                            <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                                <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                            </div>
                        ))}
                    </Popover.Body>
                </Popover>}>
                    <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-ellipsis-vertical not-0" style={{color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px"}}></i>
                        {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                        </span>
                </OverlayTrigger></div> : null}
          </div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div style={{display: "flex",  height: "350px" }}>
        <div style={{ flex: 1 }}>
          <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={500}>
            <Pie
             onClick={(e) => handleSubmit ? handlePieClick(e) : ''}
             cx="50%" // Center horizontally
             cy="48%" // Lower the Pie chart to create space below it
                data={data}
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey={item?.value_column} 
                nameKey={item?.label_column} // Dynamically use the label column
                //label={renderCustomizedLabel} // Custom label
            >
                {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Tooltip contentStyle={{
                  fontSize: "14px",
                  padding: "10px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
                content={({ active, payload }) => {
                  //console.log(active, payload);
                  if (active && payload && payload.length) {
                    const { label, value_label } = payload[0].payload;
                    const { name, value } = payload[0];
                    return (
                      <div style={{
                        fontSize: "14px",
                        padding: "10px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                        border: "1px solid rgb(204, 204, 204)"
                      }}>
                        {name} : {value_label || value}
                      </div>
                    );
                  }
                  return null;
                }} />
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                  wrapperStyle={{ fontSize: "12px", padding: "0px", maxHeight: "100px", 
                  overflowY: "auto",  }}
                  // wrapperStyle={{
                  //   //paddingTop: "25px",
                  //   //marginTop: "40px",
                  //   fontSize: "12px",
                  //   maxWidth: "100%",
                  //   maxHeight: "100px", // Set a maximum height to control overflow
                  //   overflowY: "auto", // Enable vertical scroll when items exceed max height
                  //   display: "flex",
                  //   flexWrap: "wrap", // Wrap items onto the next line if they exceed the width
                  //   justifyContent: "center",
                  // }}
                />
              
            </PieChart>
          </ResponsiveContainer>
          </div>
          
        </div>
      )}{" "}
    </>
  );
};

export default RCPieChart;
