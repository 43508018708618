import React, { useState, useContext, useEffect } from "react";
import { Context } from '../../../contextApi/context';
import DataTable from "react-data-table-component";
import * as FaIcons from 'react-icons/fa';
import './../../css/settings.css';
import IconPicker from "../components/IconPicker";
import ColorPicker from "../components/ColorPicker";
import { Button } from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../../../config/server.js");

const IssueStatus = ({token, handleCloseAlertModal, handleShowAlertModal, handleLogout}) => {

    const {selectedOrganization} = useContext(Context);
    const [selectedIcon, setSelectedIcon] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [issueStatus, setIssueStatus] = useState([]);
    const [createStatus, setCreateStatus] = useState(false);
    const [name, setName] = useState('')
    const iconList = Object.keys(FaIcons);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [colorCode, setColorCode] = useState('');
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [slug, setSlug] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageColor, setErrorMessageColor] = useState('');

    const handleSelectIcon = (icon) => {
        console.log(icon);
        setSelectedIcon(icon);
        setIsPickerOpen(false); // Close picker after selecting an icon
      };
  
      const handleIconChange = (value) => {
        setSelectedIcon(value);
      };

      const convertDate = (date) => {
        var inputDateStr = date;
        var inputDate = new Date(inputDateStr);
        var monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        var dayOfWeek = inputDate.getDay();

        var day = inputDate.getDate();
        var month = inputDate.getMonth(); // Months are zero-based
        var year = inputDate.getFullYear();

        var outputDateStr = "";

        var dayOfWeekStr;
        switch (dayOfWeek) {
          case 0:
            dayOfWeekStr = "Sun";
            break;
          case 1:
            dayOfWeekStr = "Mon";
            break;
          case 2:
            dayOfWeekStr = "Tue";
            break;
          case 3:
            dayOfWeekStr = "Wed";
            break;
          case 4:
            dayOfWeekStr = "Thu";
            break;
          case 5:
            dayOfWeekStr = "Fri";
            break;
          case 6:
            dayOfWeekStr = "Sat";
            break;
        }

        outputDateStr = dayOfWeekStr + ", " + monthNames[month] + " " + day + ", " + year;

        console.log(outputDateStr); // Output: "Thu, Mar 22, 2024"
        return outputDateStr;
    }

    const columns = [
        {
                name: <div className='row-label'>Name</div>,
                cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold ml-3">
                    {row?.name}
                 </div>
        },

        {
            name: <div className='row-label'>Color</div>,
            cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold">
                <div className="selected-color ml-3" style={{background : `${row?.color}`}}></div>
             </div>,
            width: "10%"
        },

        {
            name: <div className='row-label'>Icon</div>,
            cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold">
                <div className="selected-icon ml-4" onClick={() => {console.log(isPickerOpen)}}>
                            {React.createElement(FaIcons[row?.icon])}
                            </div>
             </div>,
            width: "10%"
        },
        {
            name: <div className='row-label'>Date Added</div>,
            cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold ml-5">
                {convertDate(row?.date_added)}
             </div>
        },
        {
            name: <div className='row-label'>Added By</div>,
            cell: (row) =>  <div> <div className='ml-4 flex flex-row'>{row?.logo === null ?
                <><img src={`https://ui-avatars.com/api/?name=${row?.user}
                &rounded=true&length=1
                &background=6ADA00&format=svg&color=fff`} alt='image'
                 height={40} width={40} className='rounded-full ml-1'/> <div className="font-semibold text-sm ml-2 mt-3">{row?.user}</div> </>
                : <><img src={row?.logo} className='rounded-full w-12 h-12 object-cover p-1'/> <div className="font-semibold text-sm ml-2 mt-3">{row?.user}</div> </> }
                </div> </div>
        },
        {
            name: <div className='row-label'>Actions</div>,
            cell: (row) =>        <div className='flex flex-row ml-4'>
              {row?.user ? 
              <>
            <Button variant='text' className='mr-2 ml-2' onClick={() => {setEditStatus(true); setName(row?.name); setSelectedIcon(row?.icon); setColorCode(row?.color); setSlug(row?.sta)}}><i class="fa-solid fa-pencil"></i></Button>
            <Button variant='text' onClick={() => {setDeleteStatus(true); setName(row?.name); setSlug(row?.sta)}}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> </Button> 
            </> : <></> }
             </div>
        },
    ]

    const handleCreateIssueStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {

            if(selectedIcon === '') {
                setErrorMessage('Select an icon')
            }
            else if(colorCode === '') {
                setErrorMessageColor('Select a color')
            }
            else {
            axios.post(serverConfig.api_base_url + "issue_status_create" , {
                token: token,
                org: selectedOrganization?.slug,
                color: colorCode,
                name: name,
                icon: selectedIcon
            }).then(response=> {
                if(response.data.status === 200) {
                    setCreateStatus(false);
                    setSelectedIcon(''); setColorCode(''); setName('')
                    handleShowAlertModal('Status added successfully');

                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
            }
        }
    }

    const handleUpdateIssueStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_status_update" , {
                token: token,
                org: selectedOrganization?.slug,
                color: colorCode,
                name: name,
                icon: selectedIcon,
                sta: slug
            }).then(response=> {
                if(response.data.status === 200) {
                    setEditStatus(false);
                    setSelectedIcon(''); setColorCode(''); setName('')
                    handleShowAlertModal('Status updated successfully');

                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
        }
    }

    const handleDeleteStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_status_delete", {
                token: token,
                org: selectedOrganization?.slug,
                sta: slug
            }).then(response=> {
                console.log(response.data);
                if(response.data.status === 200) {
                    setDeleteStatus(false);
                    setSelectedIcon(''); setColorCode(''); setName('');
                    handleShowAlertModal('Status deleted successfully');

                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
        }
        }

    useEffect(() => {

        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_status_get_list", {
                token: token,
                org: selectedOrganization?.slug
            }).then(response => {
                console.log(response.data.items, 'issue_list_res')
                if(response.data.status === 200) {
                    
                    setIssueStatus(response.data.items);
                }
            }).catch(err => {
                console.log(err);
            })
        }

    }, [createStatus, selectedOrganization, editStatus, deleteStatus])


    return (
        <>
    <div className='' style={{"width" : "56vw"}}>
    {issueStatus?.length === 0 || issueStatus === null ? <h1 className='font-bold text-base mt-4 mb-2'>Issue Statuses</h1> : <></>}
      {issueStatus?.length > 0 && issueStatus !== null ? 
        <DataTable
        title={
            <div className=" table-title rounded">
              <div className="flex justify-between items-center">
                {/* Left side: Three tabs flexed together */}
                <div className="flex space-x-4">
                  <h4 className='font-bold text-base'>Issue Statuses</h4>
                </div>
                <span className="text-right">
                  <Button type='button' className='sub-grp px-3 py-2' title="Create Table" onClick={()=> {setCreateStatus(true);}}>Add Status</Button>
                </span>               
              </div>
            </div>}
            className="issue-status"
         columns={columns}
         data={issueStatus}
         responsive={true}
         //pagination={true}
         customStyles={{
           rows: {
             rows: {
               className: 'custom-hover', // Apply custom hover styles here
             }
           },
           cells: {
             className: 'p-6',
           },
         }}
 /> :

 <div className='mt-6 flex flex-col h-2/4 items-center justify-center'>
 <div className='mb-8'><img src={require('./../../../Asset/Images/no-records.png')} alt="No records" height={90} width={90}/></div>
 <p><b>No issue statuses created for {selectedOrganization?.name}</b></p>
 <p className='text-sm'>Start creating issue statuses</p>
 <div className='mt-2.5'><button className='sub-grp px-3 py-2' onClick={() => { setCreateStatus(true);}} >Add Status</button></div>
 </div> }

 {createStatus && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Add Status</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreateIssueStatus}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" style={{"width" : "auto"}} value={name || ""} onChange={(e)=> { setName(e.target.value) }} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' required/>
                  </div>
                </div>

                <div>
                {errorMessage ? <p className='mt-2 mb-2' style={{"color" : "red", "fontSize" : "11px"}}>{errorMessage}</p> : <div className='mt-6 mb-2'></div>}
                  <div className='flex flex-row gap-4 mt-2 text-xs'>
                    <label className='text-xs mt-1.5'>Icon<span className=' text-red-500'>*</span></label>
                       <div className="flex flex-row">
                       <button type="button" required className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-3.5' style={selectedIcon !== '' ? { width: "6.9rem" } : { width: "4.9rem" }} onClick={() => setIsPickerOpen(!isPickerOpen)}><b>{selectedIcon !== '' ? 'Change Icon' : 'Pick Icon'}</b></button> 
                       {selectedIcon && <>
                        
                            <div className="selected-icon ml-4" onClick={() => {console.log(isPickerOpen)}}>
                            {React.createElement(FaIcons[selectedIcon])}
                            </div> </> }
                        
                        {isPickerOpen === true && (
                            
                            <div className="popup-container">
                            <IconPicker onSelect={handleSelectIcon} setIsPickerOpen={setIsPickerOpen} setErrorMessage={setErrorMessage} />
                            </div>
                    
                        )
                        }
                        
                        </div>  
                  </div>
                </div>

                <div>
                {errorMessageColor ? <p className='mt-6 mb-2' style={{"color" : "red", "fontSize" : "11px"}}>{errorMessageColor}</p> : <div className='mt-6 mb-2'></div>}
                  <div className='flex flex-row gap-4 mt-4 text-xs'>
                    <label className='text-xs mt-1.5'>Color<span className=' text-red-500'>*</span></label>
                    <button type="button" className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-2' style={{ width: "6.9rem", height: "34px" }} onClick={() => setOpenColorPicker(!openColorPicker)}><b>Select Color</b></button> 
                    {openColorPicker && <ColorPicker color={colorCode} setColor={setColorCode} setOpenColorPicker={setOpenColorPicker} setErrorMessage={setErrorMessageColor}/> }
                    {colorCode !== '' &&   <div className="selected-color" style={{ backgroundColor: colorCode, width: '35px', height: '35px', borderRadius: '30%' }}/>}
                  </div>
                </div>
 
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setCreateStatus(false); setIsPickerOpen(false); setSelectedIcon(''); setColorCode(''); setName(''); setErrorMessage(''); setErrorMessageColor('');}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}

        {editStatus && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Status</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateIssueStatus}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" style={{"width" : "auto"}} value={name || ""} onChange={(e)=> { setName(e.target.value) }} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' required/>
                  </div>
                </div>

                <div>
                {errorMessage ? <p className='mt-2 mb-2' style={{"color" : "red", "fontSize" : "11px"}}>{errorMessage}</p> : <div className='mt-6 mb-2'></div>}
                  <div className='flex flex-row gap-4 mt-2 text-xs'>
                    <label className='text-xs mt-1.5'>Icon<span className=' text-red-500'>*</span></label>
                       <div className="flex flex-row">
                       <button type="button" required className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-3.5' style={selectedIcon !== '' ? { width: "6.9rem" } : { width: "4.9rem" }} onClick={() => setIsPickerOpen(!isPickerOpen)}><b>{selectedIcon !== '' ? 'Change Icon' : 'Pick Icon'}</b></button> 
                       {selectedIcon && <>
                        
                            <div className="selected-icon ml-4" onClick={() => {console.log(isPickerOpen)}}>
                            {React.createElement(FaIcons[selectedIcon])}
                            </div> </> }
                        
                        {isPickerOpen === true && (
                            
                            <div className="popup-container">
                            <IconPicker onSelect={handleSelectIcon} setIsPickerOpen={setIsPickerOpen} setErrorMessage={setErrorMessage} />
                            </div>
                    
                        )
                        }
                        
                        </div>  
                  </div>
                </div>

                <div>
                {errorMessageColor ? <p className='mt-2 mb-2' style={{"color" : "red", "fontSize" : "11px"}}>{errorMessageColor}</p> : <div className='mt-6 mb-2'></div>}
                  <div className='flex flex-row gap-4 mt-4 text-xs'>
                    <label className='text-xs mt-1.5'>Color<span className=' text-red-500'>*</span></label>
                    <button type="button" className='choose-icon border rounded-lg py-2 px-1 z-10 relative ml-2' style={{ width: "6.9rem", height: "34px" }} onClick={() => setOpenColorPicker(!openColorPicker)}><b>Select Color</b></button> 
                    {openColorPicker && <ColorPicker color={colorCode} setColor={setColorCode} setOpenColorPicker={setOpenColorPicker} setErrorMessage={setErrorMessageColor}/> }
                    {colorCode !== '' &&   <div className="selected-color" style={{ backgroundColor: colorCode, width: '35px', height: '35px', borderRadius: '30%' }}/>}
                  </div>
                </div>
 
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setEditStatus(false); setIsPickerOpen(false); setSelectedIcon(''); setColorCode(''); setName('')}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}
        
        {deleteStatus && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={(e)=>{handleDeleteStatus(e);}}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Delete Status</div>
                <button className='my-2 title-text-small-color' onClick={()=>{setDeleteStatus(false)}}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Are you sure you want to delete status “<b>{name}</b>“? <br/>
                This action cannot be undone.</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={()=> {setDeleteStatus(false);}}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs'>Delete Status</button>
            </div>
          </form>
        </div>}

 </div>
        </>
    )
};

export default IssueStatus;