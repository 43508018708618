import React, { useState } from 'react';

function Search({ list, setList }) {
    const [isFocus, setIsFocus] = useState(false);

    const handleSearch = (searchVal) => {
        
        if (list) {
            setList(prevList => prevList.filter(el => el.name.toLowerCase().includes(searchVal.toLowerCase())));
        }
    };
    

    return (
        <div className="relative">
            <input
                type="text"
                placeholder="Search"
                className="h-9 w-40 w-rounded border-1 border-gray-300 search-input p-2"
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={(e) => handleSearch(e.target.value)}
            />
            {!isFocus && 
                <div className="absolute inset-y-0 left-5 pb-3.5 flex items-center pointer-events-none">
                    <i className="text-custom-icon-color text-sm fas fa-search text-gray-200"></i>
                </div>
            }
        </div>
    );
}

export default Search;
