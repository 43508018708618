import React from 'react';
const Tooltip = ({ content, x, y, taskName, orgName, groupName }) => {
  console.log(orgName);
  return (
    <foreignObject x={x - 370} y="-70" width="290" height="80">
      <div className="time-graph-tooltip">
        <div className='task-name'>{taskName}</div>
        <div className='org-group-name'>{orgName} | {groupName}</div>
        <div className='content'>{content}</div>
      </div>
    </foreignObject>
  );
};

export default Tooltip;
