import React from 'react'
import DBtezbig from '../../Asset/Images/DBtezbig.png'
import { useContext } from 'react'
import { Context } from '../../contextApi/context'
import { Link } from 'react-router-dom'
function HeaderSecond({link,linkName, popup , setPopup}) {
  const {collapse} = useContext(Context)
  return (
    <div className={collapse?'headerSecond py-3 px-4':'headerSecond-collapse py-3 px-4'}>
<div className='flex justify-center items-center gap-3'>
    <img className='rounded-lg image-dbtez' height={40} width={40} src={DBtezbig} alt='image'/>
    <div>
    <div className='flex gap-2' style={{alignItems:'center'}}>
       
       <div className='dbtez-second'>DBTEZ</div>
       <div className='text-sm '> <i class="fa-solid fa-lock"></i></div>
   </div>
   <div className='groupid text-xs'>Group ID : 2462765 <i class="fa-regular fa-copy"></i>
   </div>
   <div className='groupid text-xs'> All Sub-groups Managed by DBTEZ </div>
    </div>
  

</div>


<div className='flex justify-center items-center gap-3'>
    <div className='bell flex justify-center px-2 py-1 rounded-lg gap-3'>
    <i class="fa-regular fa-bell text-sm"></i>
    <i class="fa-solid fa-chevron-down text-sm"></i>
    </div>
   <button className='sub-grp px-3 py-2' onClick={()=>{setPopup(true)}} >
  {linkName}
   </button>
  
   
</div>
    </div>
  )
}

export default HeaderSecond;