import React from 'react'
import { useEffect, useState, useContext, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Context } from '../../../contextApi/context';
import axios from 'axios';
import UserService from './../../../services/UserService';
import CreateCustomer from '../forms/CreateCustomer';
import CreateUser from '../forms/CreateUser.js';
import { Button } from 'react-bootstrap';
//import "../css/userman.css";
import Notification from '../../../common/notification/Notification.js';

const serverConfig = require("./../../../config/server.js");

function Table() {

  const [selectedRows, setSelectedRows] = useState([]);
  const { tab, setTab } = useContext(Context);
  const [appDataGridRender, setAppDataGridRender] = useState();
  const [tableData, setTableData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { selectedOrganization } = useContext(Context);
  const [displayNone, setDisplayNone] = useState('none');
  const [viewCustomersPrivileged, setViewCustomersPrivileged] = useState(false);

  /* token - start */
  const [token, setToken] = useState(null);
  const [organizationList, setOrganizationList] = useState([]);
  const [userPrivileged, setUserPrivileged] = useState(false);
  const [rowHovered, setRowHovered] = useState('');
  const [editRowClicked, setEditRowClicked] = useState('');
  const [modalClosed, setModalClosed] = useState(false);
  const [modalClosedUser, setModalClosedUser] = useState(false);
  const [actions, setActions] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [addCustomer, setAddCustomer] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipStates, setTooltipStates] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [memberProfile, setMemberProfile] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  const dropdownRef = useRef(null);

  const toggleTooltip = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipIndex(index);
    setTooltipStates(newTooltipStates);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token", { jwt: jwt, url: window.location.host })
      .then(response => {
        console.log('user_get_token response', response);
        if (response.data.status === 200) {
          sessionStorage.setItem('token', response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch(err => console.log("error is", err));
  }

  const RoleBadge = ({ option, index, toggleTooltip, showTooltip, row }) => {
    return (
      <div key={index} className="flex-column flex items-center py-2 roles" onMouseEnter={() => { toggleTooltip(index); setRowHovered(row); }} onMouseLeave={() => { setRowHovered(''); }}>
        <img src={`https://ui-avatars.com/api/?name=${option}
    &length=1
    &${option === 'Owner' ? 'background=9D6EF8' : option === 'Manager' ?
            'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`} alt='image'
          height={36} width={36} className='role-avatar rounded-xl' />
        {row === rowHovered && tooltipIndex === index && (
          <div key={index} className="role-tooltip">
            {option}
          </div>
        )}
      </div>
    )
  }

  function OptionsDropdownCard({ data, dropdownRef }) {

    return (

      <div ref={dropdownRef} id='orgHover' className={`flex gap-2 align-middle p-2 oragnisationHover`} style={{ alignItems: 'center' }} onClick={() => { }}>
        <div>
        </div>

        <div className='options-heading line-clamp-1'>{data}</div>
      </div>

    )
  }

  const handleActive = (emailid, changeActive, slug) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active", { token: token, user: slug, org: selectedOrganization?.slug, active: changeActive })
      .then(response => {
        console.log('user_active response', response);
        if (response.data.status === 200) {
          console.log('success');
          setActions(!actions);// to refresh user list after user active is set 0/1
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err => console.log("error is", err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if (sessionStorage.getItem('token') === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');

    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
      setDisplayNone('hello');
    } else {
      x.style.display = "none";
    }
  }

  useEffect(() => {
    const handleMouseDown = (event) => {
      console.log(event.target.classList, 'classlist')
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('more-options') && !event.target.classList.contains('more-options-wrapper') && !event.target.classList.contains('btn-more-options') && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains('fa-solid')) {
        setShowDropdown('');
      }
    };
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);


  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));


    if (token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null) {
      console.log('selectedOrganization', selectedOrganization)
      console.log('cToken', token);

      axios.post(serverConfig.api_base_url + "organization_customer_list_get", { token: token, org: selectedOrganization?.slug })
      .then(response => {
        console.log("organization_customer_list_get response:", response);
        if (response.data.status === 200) {
          let items = response.data.items;
          if (items !== null || items !== undefined) {
            let appData = [];
            items.reverse().map((item, index) => {
              let curRole = [];
              curRole = item.roles;
            });
            console.log(items, 'organization_customer_list_get');
            console.log(appData);
            setTableData(items);
            setAppDataGridRender(appData);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err => console.log("error is", err));
    }

  }, [token, modalClosed, actions, selectedOrganization, modalClosedUser]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      console.log(event.target.classList, !event.target.classList.contains('btn-more-options'), 'classlist')
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains("action-option")) {
        setDisplayNone('none');
      }
    };
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [displayNone]);

  useEffect(() => {

  }, [token, selectedOrganization]);

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;


  const dropdownItems = ['Updated', 'Option 2', 'Option 3']
  const sortingIcons = [sortDown, sortUp]
  const columns = [

    {
      name: <div className='row-label'>Organization Name</div>,

      cell: (row) => <div className='flex flex-row'><div className="flex-column flex items-center py-2">
        <img src={`https://ui-avatars.com/api/?name=${row.org_name}
                    &rounded=true&length=1
                    &${row.type === 'Project' ? 'background=9D6EF8' : row.type === 'Departments' ?
            'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`} alt='image'
          height={40} width={40} className='rounded-full' />
      </div>
        <div className="flex-column mt-3 ml-8 py-2 "><div className='text-heading'>{row.org_name}</div></div>
      </div>,
      grow: 1,
      sortable: false,
    },
    {
      name: <div className='row-label'>Owner Name</div>,

      cell: (row) => <div className='flex flex-row'><div className="flex-column flex items-center py-2">
        <img src={`https://ui-avatars.com/api/?name=${row.owner_name}
                    &rounded=true&length=1
                    &${row.type === 'Project' ? 'background=9D6EF8' : row.type === 'Departments' ?
            'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`} alt='image'
          height={40} width={40} className='rounded-full' />
      </div>
        <div className="flex-column mt-3 ml-8 py-2 "><div className='text-heading'>{row.owner_name}</div></div>
      </div>,
      grow: 1,
      sortable: false,

    },
    {
      name: <div className='row-label'>Email ID</div>,
      selector: row => row.type,
      cell: (row) => <div >
        {row.email_id}
      </div>,
      sortable: true,

      wrap: true
    },
    {
      name: <div className='row-label'>No. of Users</div>,
      selector: row => row.type,
      cell: (row) => <div >
        {row.user_nos}
      </div>,
      sortable: false,
      wrap: true
    },
    {
      name: <div className='row-label'>Actions</div>,
      cell: (row) => <div>{(memberProfile?.email === row?.email_id) ? <div onClick={() => { console.log((memberProfile?.email !== row?.email_id), 'member') }}> </div> : <div><button title="Invite User" onClick={() => { editRowClicked === row ? setEditRowClicked('') : setEditRowClicked(row); }}><i className="fa-solid fa-user-plus"></i></button>
        {row===editRowClicked && <div className='overlay'><CreateUser modalClosedUser={modalClosedUser} setModalClosedUser={setModalClosedUser} setEditRowClicked={setEditRowClicked} customerorg={row.customerorg} cust={row.cust} /></div>}</div>}</div>,
      //ignoreRowClick: true,
      allowOverflow: true,
      button: true,

    },
  ]

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom mt-4 ">
        <DataTable
          title={<div className=" table-title rounded">
            <div className=" mx-auto flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h5 className='font-semibold'>Customer Management</h5>
              </div>
              {/* Right side: Search input and two dropdowns */}
              <div className="flex items-center space-x-4 text-black">
                <div className="flex items-center gap-12 mt-4">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search"
                      className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
                    />
                    <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                      <i className=" text-sm fas fa-search"></i>
                    </div>
                  </div>

                </div>

                <div className="relative inline-block">
                  <div
                    className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center"
                  >

                    <div className='pr-5'>
                      <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>

                    </div>


                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <i className="fa-solid fa-chevron-down text-sm"></i>
                  </div>
                </div>


                <button className='sub-grp px-3 py-2' onClick={() => { setAddCustomer(true) }} >
                  Invite Customer
                </button>
                {addCustomer === true ? <div className='overlay'><CreateCustomer setAddCustomer={setAddCustomer} modalClosed={modalClosed} setModalClosed={setModalClosed} setEditRowClicked={setEditRowClicked} /></div> : <></>}

              </div>
            </div>
          </div>}
          columns={columns}
          data={tableData}
          responsive={true}
          pagination={true}
          customStyles={{
            rows: {
              rows: {
                className: 'custom-hover', // Apply custom hover styles here
              }
            },
            cells: {
              className: 'p-6',
            },
          }}

        //subHeader={TableHeader}

        />

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}

      </div>
    </div>
  );
}

export default Table;