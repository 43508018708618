import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context.js';
import axios from 'axios';
import { useNavigate } from 'react-router';
import UserService from '../../services/UserService.js';
import "../css/mapmetadata.css";
import Notification from '../../common/notification/Notification.js';

const serverConfig = require("../../config/server.js");

const Templates = () => {
  const navigate = useNavigate();

  const {selectedOrganization, isDarkTheme, setActiveSubmenuOption} = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  
  const [fileFormatLookupList, setFileFormatLookupList] = useState([]);
  const [orgSchemaList, setOrgSchemaList] = useState([]);
  const [schema, setSchema] = useState({});
  const [stgTableList, setStgTableList] = useState([]);
  const [factTableList, setFactTableList] = useState([]);
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshTemplateList, setRefreshTemplateList] = useState(false);

  const [mappingData, setMappingData] = useState([]);
  const [targetList, setTargetList] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    //set lookup dropdown
    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"file_format"})
    .then(response=>{
      console.log("file_format lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("file_format data:", response.data.items);
          response.data.items.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          setFileFormatLookupList(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }, [token]);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);
      /* dwh_template_list_get */
      axios.post(serverConfig.api_base_url + "dwh_template_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("dwh_template_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null && items !== undefined){
            console.log(response.data.items);
            let appDataArray = response.data.items;
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              let noOfActions = 1 + 1 + Number(item.is_deletable);
              const Actions = () => <>
                <Button title={noOfActions > 2 ? '' : 'Edit'} variant='text' onClick={() => handleShowEditTemplateModal(item)}><i className="fa-solid fa-pencil not-0" aria-hidden="true"></i> {noOfActions > 2 && <span>Edit</span>}</Button>
                <Button title={noOfActions > 2 ? '' : 'Map'} variant='text' onClick={() => handleShowMapModal(item?.template, item?.name)}><i className="fa-solid fa-sitemap" aria-hidden="true"></i> {noOfActions > 2 && <span>Map</span>}</Button>
                {item.is_deletable === 1 && <Button title={noOfActions > 2 ? '' : 'Delete'} variant='text' onClick={() => handleShowDeleteTemplateModal(item?.template, item?.name)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> {noOfActions > 2 && <span>Delete</span>}</Button>}
              </>;
              {item.actions = (noOfActions > 2 ? <div className="text-right more-options-wrapper">
                <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
                <div className="more-options" style={{display: "none"}}>
                  <Actions />
                </div>
              </div> : <div className="flex items-center justify-end gap-3">
                <Actions />
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setTableData(appData);
          } else {
            setTableData([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));

      //set schema dropdown
      axios.post(serverConfig.api_base_url + "org_schema_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("org_schema_list_get list:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("org_schema_list_get items:", response.data.items);
            setOrgSchemaList(response.data.items);
          } else {
            setOrgSchemaList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization, refreshTemplateList]);

  useEffect(() => {
    console.log('schema', schema, schema.schema);
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null && schema.schema !== null && schema.schema !== undefined){

      //set table dropdown
      axios.post(serverConfig.api_base_url + "table_list",{token:token, org:selectedOrganization?.slug, schema:schema.schema, tableType:"stage"})
      .then(response=>{
        console.log("stg table list:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("stg table list items:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            setStgTableList(response.data.items);
          } else {
            setStgTableList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "table_list",{token:token, org:selectedOrganization?.slug, schema:schema.schema, tableType:"fact"})
      .then(response=>{
        console.log("fact table list:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("fact table list items:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            setFactTableList(response.data.items);
          } else {
            setFactTableList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization, refreshTemplateList, schema]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  const sortDown=<i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp=<i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ['Updated', 'Option 2', 'Option 3'];
  const sortingIcons= [sortDown,sortUp];

  const columns = [
    {
      name: '',
      selector: row => row.name,
      sortable: false,
      wrap: true,
    },
    /* {
      name: 'Group',
      selector: row => row.group,
    }, */
    {
      name: <div className='font-bold text-sm'>Format</div>,
      selector: row => row.format,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='font-bold text-sm'>Stage Table</div>,
      selector: row => row.stgTable,
      sortable: true,
      wrap: true,
    },
    /* {
      name: 'Instructions',
      selector: row => <span className='unordered-list' dangerouslySetInnerHTML={{__html: row.instructions}}></span>,
      wrap: true,
      style:{alignItems:"flex-start"}
    },
    {
      name: 'Rows to Skip',
      selector: row => row.rowsToSkip,
    }, */
    {
      name: <div className='font-bold text-sm'>Fact Table</div>,
      selector: row => row.factTable,
      sortable: true,
      wrap: true,
    },
    /* {
      name: 'Expected Columns',
      selector: row => row.expectedColumns,
      wrap: true,
      style:{alignItems:"flex-start"}
    }, */
    {
      name: <div className='font-bold text-sm'>Actions</div>,
      selector: row => row.actions,
    }
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  //Create
  const [createTemplateModalShow, setCreateTemplateModalShow] = useState(false);
  const handleCloseCreateTemplateModal = () => {
    setCreateTemplateModalShow(false);
    setFormMessage({});
    setFormData({});
    setSchema({});
    setStgTableList([]);
    setFactTableList([]);
  }
  const handleShowCreateTemplateModal = () => {
    setCreateTemplateModalShow(true);
  }

  const handleCreateTemplateSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_template_create",{
      token:token,
      org:selectedOrganization?.slug,
      name: formData.name,
      format: formData.format,
      schema: schema.schema,
      stgTable: formData.stgTable,
      stgTbl: formData.stgTbl,
      instructions: formData.instructions,
      rowsToSkip: formData.rowsToSkip,
      factTable: formData.factTable,
      fctTbl: formData.fctTbl,
      expectedColumns: formData.expectedColumns
    })
    .then(response=>{
      console.log("dwh_template_create:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Template created successfully", type:"success"});
        setRefreshTemplateList(!refreshTemplateList);
        handleCloseCreateTemplateModal();
        handleShowAlertModal('Template created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Edit
  const [templateSlug, setTemplateSlug] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [editTemplateModalShow, setEditTemplateModalShow] = useState(false);
  const handleCloseEditTemplateModal = () => {
    setEditTemplateModalShow(false);
    setTemplateSlug("");
    setTemplateName("");
    setFormMessage({});
    setFormData({});
    setSchema({});
    setStgTableList([]);
    setFactTableList([]);
  }
  const handleShowEditTemplateModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditTemplateModalShow(true);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }

    setSchema(details.schema);
  }

  const handleUpdateTemplate = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_template_update",{
      token:token,
      template:formData.template,
      name: formData.name,
      format: formData.format,
      schema: schema.schema,
      stgTable: formData.stgTable,
      stgTbl: formData.stgTbl,
      instructions: formData.instructions,
      rowsToSkip: formData.rowsToSkip,
      factTable: formData.factTable,
      fctTbl: formData.fctTbl,
      expectedColumns: formData.expectedColumns
    })
    .then(response=>{
      console.log("dwh_template_update:", response);
      if (response.data.status === 200) {
        handleShowAlertModal('Template updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        handleCloseEditTemplateModal();
        setRefreshTemplateList(!refreshTemplateList);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Delete
  const [deleteTemplateModalShow, setDeleteTemplateModalShow] = useState(false);
  const handleCloseDeleteTemplateModal = () => {
    setDeleteTemplateModalShow(false);
    setTemplateSlug("");
    setTemplateName("");
    setFormMessage({});
    setFormData({});
  }
  const handleShowDeleteTemplateModal = (templateSlug, templateName) => {
    setDeleteTemplateModalShow(true);
    setTemplateSlug(templateSlug);
    setTemplateName(templateName);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleDeleteTemplate = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "dwh_template_delete",{token:token, template:templateSlug})
    .then(response=>{
      console.log("dwh_template_delete:", response);
      if (response.data.status === 200) {
        handleShowAlertModal('Template deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        handleCloseDeleteTemplateModal();
        setRefreshTemplateList(!refreshTemplateList);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [mapModalShow, setMapModalShow] = useState(false);
  const handleCloseMapModal = () => {
    setMapModalShow(false);
    setTemplateSlug("");
    setTemplateName("");
    setFormData({});
  }
  const handleShowMapModal = (templateSlug, templateName) => {
    setMapModalShow(true);

    setTemplateSlug(templateSlug);
    setTemplateName(templateName);

    axios.post(serverConfig.api_base_url + "dwh_template_get_mapping",{token:token, template:templateSlug})
    .then(response=>{
      console.log("dwh_template_get_mapping:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("dwh_template_get_mapping items:", JSON.parse(response.data.items));
          
          //setTargetListOriginal(JSON.parse(response.data.items).stg_columns);

          let mappedTarget = JSON.parse(response.data.items).mapping.map(el => el.target_column);
          //console.log(mappedTarget);
          let stgColArr = JSON.parse(response.data.items).stg_columns;
          if(stgColArr){
            let remainingStgColArr = stgColArr.filter(function(el) {
              return !mappedTarget.includes(el);
            });
            //console.log(remainingStgColArr);
            setTargetList(remainingStgColArr);
          }

          setMappingData(JSON.parse(response.data.items).mapping);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleMap = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "dwh_template_set_mapping",{token:token, template:templateSlug, mappingDetails: mappingData})
    .then(response=>{
      console.log("dwh_template_set_mapping:", response);
      if (response.data.status === 200) {
        setMappingData([]);
        handleShowAlertModal('Mapping saved successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        handleCloseMapModal();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleChange = (idx, targetCol, event) => {
    const name = event.target.name;
    const value = event.target.value;

    var updatedData = {[name]: value};
    var result;
    if(targetCol){
      console.log(targetCol);
      result = mappingData.map((item) => (item.target_column === targetCol ? { ...item, ...updatedData } : item));
    } else {
      console.log(idx)
      result = mappingData.map((item, index) => (index === idx ? { ...item, ...updatedData } : item));
    }
    console.log(result);
    setMappingData(result);
  }

  const handleAddNewRow = () => {
    setMappingData([...mappingData, {
      source_function: null,
      target_column: null
    }]);

    console.log(mappingData);
    console.log(targetList);

    let mappedTarget = mappingData.map(el => el.target_column);
    setTargetList(targetList.filter((el) => !mappedTarget.includes(el)));
  }

  const handleDeleteMapping = (targetCol) => {
    console.log(targetCol);

    let arr = mappingData;
    setMappingData([]);

    setTimeout(() => {
      setMappingData(arr.filter(el => el.target_column !== targetCol));
    });

    setTargetList([...targetList, targetCol]);
  }

  /* const handleGoToTemplate = (data) => {
    console.log(data);
    navigate('/template-management/template', {state: {
        details: JSON.stringify(data),
        fileFormatLookupList: fileFormatLookupList,
        stgTableList: stgTableList,
        factTableList: factTableList,
      }
    });
  } */

  return(
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-mapmetadata mt-4">
        <div className="flex justify-between items-center space-x-4">
          <h1 className='font-semibold text-2xl mt-4 mb-4'>Templates</h1>
          {tableData.length > 0 && <div className="flex items-center space-x-4 text-black">
            {/* <div className='flex items-center gap-12 mt-4'>
              <div className="relative">
                <input type="text" placeholder="Search" className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input" />
                <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                  <i className=" text-sm fas fa-search"></i>
                </div>
              </div>
            </div>
            <div className="relative inline-block">
              <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
                <div className='pr-5'>
                  <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <i className="fa-solid fa-chevron-down text-sm"></i> 
              </div>
            </div> */}
            <button type='button' className='sub-grp px-3 py-2' onClick={handleShowCreateTemplateModal}><i className="fa-solid fa-plus mr-2" aria-hidden="true"></i> Create Template</button>
          </div>}
        </div>
        {tableData.length > 0 ?
          <DataTable
            columns={columns}
            data={tableData}
            responsive={true}
            pagination={tableData.length > 10 ? true : false}
            //striped
            highlightOnHover
            //pointerOnHover
            customStyles={{
              rows: {
                style: {
                  minHeight: '48px',
                  border: '2px solid var(--neutral-400)',
                  borderRadius: '10px',
                  marginTop: '10px',
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? '#ffffff' : '#000000',
                  backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                  outline: 'none'
                }
              }
            }}
            //onRowClicked={(row) => {handleGoToTemplate(row)}}
          /> : <div className='flex flex-col h-3/4 items-center justify-center'>
          <div className='mb-8'><img src={require('./../../Asset/Images/no-records.png')} alt="No records" /></div>
          <p><b>This Organisation has no Templates</b></p>
          <p className='text-sm'>Create first Template for {selectedOrganization?.name}</p>
          <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={handleShowCreateTemplateModal}>New Template</button></div>
        </div>}

        {createTemplateModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Create Template</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreateTemplateSubmit}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>File Format<span className=' text-red-500'>*</span></label>
                    <select value={formData.format || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, format: e.target.value})} required>
                      <option value="">File Format</option>
                      {fileFormatLookupList.length > 0 &&
                        fileFormatLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                      }
                    </select>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Select Schema<span className=' text-red-500'>*</span></label>
                    {orgSchemaList.length === 0 && <span className="text-red-500">Currently there are no schemas. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/mapmetadata"); setActiveSubmenuOption('Map Metadata');}}>here</span> after creating Database Service</span>}
                    <select value={schema.name || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setSchema({name:e.target.value, schema:e.target.selectedOptions[0].getAttribute('schema')})} required>
                      <option value="">Select Schema</option>
                        {orgSchemaList.length > 0 && orgSchemaList.map((item, index) => <option value={item.name} key={index} schema={item.schema}>{item.name}</option>)}
                    </select>
                  </div>
                  {schema.schema && <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Stage Table<span className=' text-red-500'>*</span></label>
                    {stgTableList.length === 0 && <span className="text-red-500">Currently there are no Stage tables under this schema. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/mapmetadata"); setActiveSubmenuOption('Map Metadata');}}>here</span></span>}
                    <select value={formData.stgTable || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, stgTable: e.target.value, stgTbl:e.target.selectedOptions[0].getAttribute('tbl')})} required>
                      <option value="">Stage Table</option>
                        {stgTableList.length > 0 && stgTableList.map((item, index) => <option value={item.name} key={index} tbl={item.tbl}>{item.name}</option>)}
                    </select>
                  </div>}
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Instructions<span className=' text-red-500'>*</span></label>
                    <textarea value={formData.instructions || ""} onChange={(e)=>  setFormData({ ...formData, instructions: e.target.value})} placeholder='Instructions' className='custom-input py-2 px-2 text-left mt-1' required></textarea>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Rows to Skip<span className=' text-red-500'>*</span></label>
                    <input type="number" min="0" value={formData.rowsToSkip || ""} onChange={(e)=>  setFormData({ ...formData, rowsToSkip: e.target.value})} placeholder='Rows to Skip' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  {schema.schema && <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Fact Table<span className=' text-red-500'>*</span></label>
                    {factTableList.length === 0 && <span className="text-red-500">Currently there are no Fact tables under this schema. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/mapmetadata"); setActiveSubmenuOption('Map Metadata');}}>here</span></span>}
                    <select value={formData.factTable || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, factTable: e.target.value, fctTbl:e.target.selectedOptions[0].getAttribute('tbl')})} required>
                      <option value="">Fact Table</option>
                        {factTableList.length > 0 && factTableList.map((item, index) => <option value={item.name} key={index} tbl={item.tbl}>{item.name}</option>)}
                    </select>
                  </div>}
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Expected Columns<span className=' text-red-500'>*</span>
                      <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip" className='text-sm'>Comma separated list</Tooltip>}>
                        <Button className="ms-2 info-icon">i</Button>
                      </OverlayTrigger>
                    </label>
                    <textarea value={formData.expectedColumns || ""} onChange={(e)=>  setFormData({ ...formData, expectedColumns: e.target.value})} placeholder='Expected Columns' className='custom-input py-2 px-2 text-left mt-1' required></textarea>
                  </div>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateTemplateModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>}

        {editTemplateModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Template</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateTemplate}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>File Format<span className=' text-red-500'>*</span></label>
                    <select value={formData.format || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, format: e.target.value})} required>
                      <option value="">File Format</option>
                      {fileFormatLookupList.length > 0 &&
                        fileFormatLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                      }
                    </select>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Select Schema<span className=' text-red-500'>*</span></label>
                    {orgSchemaList.length === 0 && <span className="text-red-500">Currently there are no schemas. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/mapmetadata"); setActiveSubmenuOption('Map Metadata');}}>here</span> after creating Database Service</span>}
                    <select value={schema.name || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setSchema({name:e.target.value, schema:e.target.selectedOptions[0].getAttribute('schema')})} required>
                      <option value="">Select Schema</option>
                        {orgSchemaList.length > 0 && orgSchemaList.map((item, index) => <option value={item.name} key={index} schema={item.schema}>{item.name}</option>)}
                    </select>
                  </div>
                  {schema.schema && <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Stage Table<span className=' text-red-500'>*</span></label>
                    {stgTableList.length === 0 && <span className="text-red-500">Currently there are no Stage tables under this schema. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/mapmetadata"); setActiveSubmenuOption('Map Metadata');}}>here</span></span>}
                    <select value={formData.stgTable || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, stgTable: e.target.value, stgTbl:e.target.selectedOptions[0].getAttribute('tbl')})} required>
                      <option value="">Stage Table</option>
                      {stgTableList.length > 0 &&
                        stgTableList.map((item, index) => <option value={item.name} key={index} tbl={item.tbl}>{item.name}</option>)
                      }
                    </select>
                  </div>}
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Instructions<span className=' text-red-500'>*</span></label>
                    <textarea value={formData.instructions || ""} onChange={(e)=>  setFormData({ ...formData, instructions: e.target.value})} placeholder='Instructions' className='custom-input py-2 px-2 text-left mt-1' required></textarea>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Rows to Skip<span className=' text-red-500'>*</span></label>
                    <input type="number" min="0" value={formData.rowsToSkip !== null ? formData.rowsToSkip : ""} onChange={(e)=>  setFormData({ ...formData, rowsToSkip: e.target.value})} placeholder='Rows to Skip' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  {schema.schema && <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Fact Table<span className=' text-red-500'>*</span></label>
                    {factTableList.length === 0 && <span className="text-red-500">Currently there are no Fact tables under this schema. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/mapmetadata"); setActiveSubmenuOption('Map Metadata');}}>here</span></span>}
                    <select value={formData.factTable || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, factTable: e.target.value, fctTbl:e.target.selectedOptions[0].getAttribute('tbl')})} required>
                      <option value="">Fact Table</option>
                      {factTableList.length > 0 &&
                        factTableList.map((item, index) => <option value={item.name} key={index} tbl={item.tbl}>{item.name}</option>)
                      }
                    </select>
                  </div>}
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Expected Columns<span className=' text-red-500'>*</span>
                      <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip" className='text-sm'>Comma separated list</Tooltip>}>
                        <Button className="ms-2 info-icon">i</Button>
                      </OverlayTrigger>
                    </label>
                    <textarea value={formData.expectedColumns || ""} onChange={(e)=>  setFormData({ ...formData, expectedColumns: e.target.value})} placeholder='Expected Columns' className='custom-input py-2 px-2 text-left mt-1' required></textarea>
                  </div>
                </div>
              </div>

              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditTemplateModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
              </div>
            </form>
          </div>
        </div>}

        {mapModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2 h-full' onSubmit={handleMap}>
            <div>
              <div style={{fontSize:16}}>Map Columns</div>
              <Row xs={4} className="items-center mt-4">
                <Col><h6>Source</h6></Col>
                <Col xs={1}></Col>
                <Col><h6>Target</h6></Col>
                <Col></Col>
              </Row>
            </div>
            <div className=' flex-auto overflow-y-auto '>
              <div className='mt-4'>
                {mappingData.length > 0 && mappingData.map((items, index) => {
                  return(<Row xs={4} className="items-center" key={index}>
                    {/* <Col><input name="source" value={items.source} type="text" className='custom-input py-2 px-2 text-left mt-1' disabled/></Col> */}
                    <Col><input name="source_function" defaultValue={items.source_function} type="text" onChange={(e) => handleChange(index, items.target_column, e)} className='custom-input py-2 px-2 text-left mt-1' required/></Col>
                    <Col xs={1} className="text-center"><i className="fa-solid fa-arrow-right-long"></i></Col>
                    
                    <Col>
                      {items.target_column !== null ? <input name="source_futarget_columnnction" defaultValue={items.target_column} type="text" className='custom-input py-2 px-2 text-left mt-1' disabled /> : <select name="target_column" defaultValue={items.target_column} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleChange(index, items.target_column, e)} required>
                        <option value="">Select</option>
                        {targetList && targetList.length > 0 &&
                          targetList.map((item, index) => <option value={item} key={index}>{item}</option>)
                        }
                      </select>}
                    </Col>
                    <Col xs={1} className="text-center"><button type="button" onClick={() => handleDeleteMapping(items.target_column)}><i className="fa-regular fa-trash-can"></i></button></Col>
                  </Row>)
                })}
              </div>
            </div>

            <div><button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleAddNewRow}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></button></div>

            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseMapModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
            </div>
          </form>
        </div>}

        {deleteTemplateModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleDeleteTemplate}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Template</div>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Template <b>{templateName}</b> ?</p>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>

            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteTemplateModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}

        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
      </div>
    </div>
  );
}

export default Templates;