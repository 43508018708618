import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../contextApi/context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const serverConfig = require("./../../config/server.js");

function SplitTimePopup({
  setSplitTime,
  token,
  selectedOrganization,
  handleLogout,
  refreshTimesheet,
  handleCloseAlertModal,
  handleShowAlertModal,
  timeEntryDetails,
}) {
  const navigate = useNavigate();
  const { setActiveOption } = useContext(Context);

  const [formData, setFormData] = useState({});
  const [reason, setReason] = useState("");
  const [reasonName, setReasonName] = useState(undefined);
  const [reasonDetails, setReasonDetails] = useState(undefined);
  const [deleteReasonName, setDeleteReasonName] = useState(undefined);
  const [deleteReasonDetails, setDeleteReasonDetails] = useState(undefined);
  const [deleteTimeReason, setDeleteTimeReason] = useState("");
  const [groupListLookup, setGroupListLookup] = useState([]);
  const [taskListLookup, setTaskListLookup] = useState([]);
  const [tab, setTab] = useState("Reassign Time");
  const [reasonLookup, setReasonLookup] = useState([]);

  useEffect(() => {
    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);

      //group_list_get
      axios
        .post(serverConfig.api_base_url + "group_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("group_list_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              let arr = [];
              response.data.items.forEach((el) => {
                arr.push({
                  value: el.grp,
                  label: el.name,
                });
              });
              setGroupListLookup(arr);
            } else {
              setGroupListLookup([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      axios
        .post(serverConfig.api_base_url + "lookup_list", {
          token: token,
          name: "time_entry_reason",
        })
        .then((response) => {
          console.log("time_entry_reason lookup:", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              response.data.items.sort(function (a, b) {
                return a.seq_no - b.seq_no;
              });
              let arr = [];
              response.data.items.forEach((el) => {
                arr.push({
                  value: el.value,
                  label: el.value,
                });
              });
              setReasonLookup(arr);
            } else {
              setReasonLookup([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization]);

  const getTaskList = (grp) => {
    console.log(grp);
    axios
      .post(serverConfig.api_base_url + "task_list_get", {
        token: token,
        org: selectedOrganization?.slug,
        grp: grp,
      })
      .then((response) => {
        console.log("task_list_get response", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log(response.data.items);
            let relatedTasks = response.data.items.filter(
              (el) =>
                el.assignee !== null &&
                el.assignee.some((el1) => el1.usr === el.usr)
            ); //filter tasks assigned to logged in user (user is task member of the task)
            console.log(relatedTasks);
            let arr = [];
            relatedTasks.forEach((el) => {
              //format array in react select list format
              arr.push({
                value: el.tsk,
                label: el.name,
              });
            });
            setTaskListLookup(arr);
          } else {
            setTaskListLookup([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log("TIME ENTRY DETAILS : ", timeEntryDetails);
    if (timeEntryDetails !== null && timeEntryDetails !== undefined) {
      getTaskList(timeEntryDetails.group.grp);
      setFormData({
        grp: timeEntryDetails?.group?.grp,
        tsk: timeEntryDetails?.tsk,
        is_work_break: timeEntryDetails?.is_work_break,
        start_time: new Date(
          timeEntryDetails?.start_time + " UTC"
        ).toLocaleString("sv-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        }),
        end_time: new Date(timeEntryDetails?.end_time + " UTC").toLocaleString(
          "sv-SE",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          }
        ),
        is_billable: timeEntryDetails?.is_billable,
        reason: timeEntryDetails?.reason,
        note: timeEntryDetails?.note,
        timelog: timeEntryDetails?.timelog,
      });
      console.log(formData);
      console.log(
        timeEntryDetails?.start_time,
        new Date(timeEntryDetails?.start_time + " UTC").toLocaleString(
          "sv-SE",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          }
        )
      );
    }
  }, [timeEntryDetails]);

  const handleReassignTime = () => {
    // Logic for reassigning time
    console.log("Reassigning time...");
    let time1 = new Date(
      new Date(formData.start_time).toUTCString()
    ).toISOString();
    let startTime =
      time1.split("T")[0] + " " + time1.split("T")[1].substring(0, 8);
    //console.log(startTime);
    let time2 = new Date(
      new Date(formData.end_time).toUTCString()
    ).toISOString();
    let endTime =
      time2.split("T")[0] + " " + time2.split("T")[1].substring(0, 8);
    //console.log(endTime);

    let isBillable = formData.is_billable === null ? 0 : formData.is_billable;

    const reasonArray = [reason];

    const reassignData = {
      start_time: startTime,
      end_time: endTime,
      is_billable: isBillable,
      grp: formData.grp,
      tsk: formData.tsk,
      //reason: reasonArray,
      reason:
        formData.reason && reasonName
          ? [...formData.reason, { name: reasonName, details: reasonDetails }]
          : reasonName
          ? [{ name: reasonName, details: reasonDetails }]
          : formData.reason && formData.reason,
    };

    // let itemsString = JSON.stringify(reassignData);

    axios
      .post(serverConfig.api_base_url + "time_log_split_reassign", {
        token: token,
        org: selectedOrganization?.slug,
        timelog: timeEntryDetails.timelog,
        items: reassignData,
      })
      .then((response) => {
        // Handle success response
        console.log("Reassign time successful:", response.data);
        handleClosePopup();
        // You can perform any additional actions here, such as updating state or showing a success message
      })
      .catch((error) => {
        // Handle error response
        console.error("Error reassigning time:", error);
        // You can show an error message to the user or handle the error in another way
      });
  };

  const handleDeleteTime = () => {
    // Logic for deleting time
    let time1 = new Date(
      new Date(formData.start_time).toUTCString()
    ).toISOString();
    let startTime =
      time1.split("T")[0] + " " + time1.split("T")[1].substring(0, 8);
    //console.log(startTime);
    let time2 = new Date(
      new Date(formData.end_time).toUTCString()
    ).toISOString();
    let endTime =
      time2.split("T")[0] + " " + time2.split("T")[1].substring(0, 8);
    //console.log(endTime);

    // let itemsString = JSON.stringify(reassignData);

    const deleteReasonJson = JSON.stringify(deleteTimeReason);

    axios
      .post(serverConfig.api_base_url + "time_log_split_delete", {
        token: token,
        org: selectedOrganization?.slug,
        timelog: timeEntryDetails.timelog,
        start_time: startTime,
        end_time: endTime,
        is_billable: formData.is_billable,
        //reason: deleteReasonJson,
        reason:
          formData.reason && deleteReasonName
            ? [
                ...formData.reason,
                { name: deleteReasonName, details: deleteReasonDetails },
              ]
            : deleteReasonName
            ? [{ name: deleteReasonName, details: deleteReasonDetails }]
            : formData.reason && formData.reason,
      })
      .then((response) => {
        // Handle success response
        console.log("Reassign time successful:", response.data);
        handleClosePopup();
        // You can perform any additional actions here, such as updating state or showing a success message
      })
      .catch((error) => {
        // Handle error response
        console.error("Error reassigning time:", error);
        // You can show an error message to the user or handle the error in another way
      });
    console.log("Deleting time...");
  };

  const handleEditTime = (event) => {
    event.preventDefault();
    // Conditionally call the appropriate function based on the active tab
    if (tab === "Reassign Time") {
      handleReassignTime();
    } else if (tab === "Delete Time") {
      handleDeleteTime();
    }
  };

  const handleClosePopup = () => {
    setSplitTime(false);
    setFormData({});
    setReason("");
    setReasonName(undefined);
    setReasonDetails(undefined);
    setDeleteReasonName(undefined);
    setDeleteReasonDetails(undefined);
    setDeleteTimeReason("");
  };

  const formatDate = (timeString) => {
    const date = new Date(timeString + " UTC");
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  console.log("START :", formData.start);

  return (
    <div
      className="memberPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex flex-col overflow-hidden"
      style={{ height: "80vh" }}
    >
      <div className="flex justify-between items-center gap-5">
        <div style={{ fontSize: 16 }}>Split Time</div>
      </div>
      <div className="flex-1 flex">
        <form
          className="flex flex-col justify-between w-full"
          onSubmit={handleEditTime}
        >
          <div className="scrollable mt-5 mb-5">
            <div className="flex justify-between items-center mb-4">
              <div>
                <div className="text-sm font-semibold">Project</div>
                <div className="flex items-center mt-1">
                  <span>
                    {timeEntryDetails.task_name} | {timeEntryDetails.org_name} |{" "}
                    {timeEntryDetails.group.name}
                  </span>
                </div>
              </div>
            </div>

            <div className="text-sm font-semibold mb-2">Time Span</div>
            <div className="text-sm mb-4">
              {`${formatDate(timeEntryDetails.start_time)} to ${formatDate(
                timeEntryDetails.end_time
              )}`}
            </div>

            <div className="flex space-x-4 mb-4">
              <div className="flex">
                <button
                  className={`px-6 py-3 cursor-pointer ${
                    tab === "Reassign Time" ? "tab" : "tab-unactive"
                  }`}
                  onClick={() => setTab("Reassign Time")}
                >
                  Reassign Time
                </button>
                <div
                  className={`px-6 py-3 cursor-pointer ${
                    tab === "Delete Time" ? "tab" : "tab-unactive"
                  }`}
                  onClick={() => setTab("Delete Time")}
                >
                  Delete Time
                </div>
              </div>
            </div>
            {tab === "Reassign Time" && (
              <div className="flex-1 flex flex-col gap-4">
                <div className="flex gap-4">
                  <div className="flex flex-col w-1/2">
                    <label className="text-sm">From</label>
                    <input
                      type="datetime-local"
                      value={formData.start_time || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          start_time: e.target.value,
                        })
                      }
                      onKeyDown={(e) => e.preventDefault()}
                      className="custom-input"
                      required
                    />
                  </div>
                  <div className="flex flex-col w-1/2">
                    <label className="text-sm">To</label>
                    <input
                      type="datetime-local"
                      value={formData.end_time || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          end_time: e.target.value,
                        })
                      }
                      onKeyDown={(e) => e.preventDefault()}
                      className="custom-input"
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <label className="text-sm">Billable</label>
                  <div className="flex flex-row mt-2">
                    No
                    <label class="checkbox-toggle-simple mx-3">
                      <input
                        type="checkbox"
                        checked={formData.is_billable === 1 ? true : false}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            is_billable: e.target.checked ? 1 : 0,
                          })
                        }
                      />
                      <span></span>
                    </label>
                    Yes
                  </div>
                </div>
                <div className="flex flex-col gap-1 text-xs">
                  <label>
                    Groups<span className="text-red-500">*</span>
                    {groupListLookup.length === 0 && (
                      <span className="text-red-500 text-xs font-normal ml-4">
                        Currently there are no groups. Please create one{" "}
                        <span
                          className="underline cursor-pointer"
                          onClick={() => {
                            navigate("/groups");
                            setActiveOption("Groups");
                          }}
                        >
                          here
                        </span>
                      </span>
                    )}
                  </label>
                  <Select
                    defaultValue=""
                    classNamePrefix="select"
                    placeholder="Select Group"
                    options={groupListLookup}
                    onChange={(option) => {
                      setFormData({ ...formData, grp: option.value });
                      getTaskList(option.value);
                    }}
                    required
                  />
                </div>
                <div className="flex flex-col gap-1 text-xs">
                  <label>
                    Task<span className="text-red-500">*</span>
                  </label>
                  <Select
                    defaultValue=""
                    classNamePrefix="select"
                    placeholder="Select Task"
                    options={taskListLookup}
                    onChange={(option) => {
                      setFormData({ ...formData, tsk: option.value });
                    }}
                    required
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label className="text-sm">
                    Reason<span className="text-red-500">*</span>
                  </label>

                  <Select
                    defaultValue={
                      timeEntryDetails?.reason?.length > 0
                        ? {
                            value:
                              timeEntryDetails?.reason[
                                timeEntryDetails?.reason?.length - 1
                              ].name,
                            label:
                              timeEntryDetails?.reason[
                                timeEntryDetails?.reason?.length - 1
                              ].name,
                          }
                        : undefined
                    }
                    classNamePrefix="select"
                    placeholder="Why are you changing this time entry?"
                    options={reasonLookup}
                    onChange={(option) => {
                      setReasonName(option.value);
                      setReasonDetails(undefined);
                    }}
                    required
                  />
                  {reasonName
                    ? reasonName === "Other" && (
                        <textarea
                          defaultValue={reasonDetails || ""}
                          onChange={(e) => {
                            setReasonDetails(e.target.value);
                          }}
                          placeholder="Please specify"
                          className="custom-input py-2 px-2 text-left mt-1"
                          required
                        ></textarea>
                      )
                    : timeEntryDetails?.reason?.length > 0 &&
                      timeEntryDetails?.reason[
                        timeEntryDetails?.reason?.length - 1
                      ].name === "Other" && (
                        <textarea
                          defaultValue={
                            timeEntryDetails?.reason[
                              timeEntryDetails?.reason?.length - 1
                            ].details
                          }
                          onChange={(e) => {
                            setReasonDetails(e.target.value);
                            setReasonName(
                              timeEntryDetails?.reason[
                                timeEntryDetails?.reason?.length - 1
                              ].name
                            );
                          }}
                          placeholder="Please specify"
                          className="custom-input py-2 px-2 text-left mt-1"
                          required
                        ></textarea>
                      )}

                  {/* <textarea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Why are you changing this time entry?"
                    className="custom-input"
                    required
                  ></textarea> */}
                </div>
              </div>
            )}
            {tab === "Delete Time" && (
              <div className="flex-1 flex flex-col gap-4">
                <div className="flex gap-4">
                  <div className="flex flex-col w-1/2">
                    <label className="text-sm">From</label>
                    <input
                      type="datetime-local"
                      value={formData.start_time || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          start_time: e.target.value,
                        })
                      }
                      onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                      className="custom-input"
                      required
                    />
                  </div>
                  <div className="flex flex-col w-1/2">
                    <label className="text-sm">To</label>
                    <input
                      type="datetime-local"
                      value={formData.end_time || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          end_time: e.target.value,
                        })
                      }
                      onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                      className="custom-input"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label className="text-sm">Billable</label>
                  <label className="checkbox-toggle-simple">
                    <input
                      type="checkbox"
                      checked={formData.is_billable === 1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_billable: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                    <span className="toggle"></span>
                  </label>
                </div>
                <div className="flex flex-col gap-2">
                  <label className="text-sm">
                    Reason<span className="text-red-500">*</span>
                  </label>

                  <Select
                    defaultValue={
                      timeEntryDetails?.reason?.length > 0
                        ? {
                            value:
                              timeEntryDetails?.reason[
                                timeEntryDetails?.reason?.length - 1
                              ].name,
                            label:
                              timeEntryDetails?.reason[
                                timeEntryDetails?.reason?.length - 1
                              ].name,
                          }
                        : undefined
                    }
                    classNamePrefix="select"
                    placeholder="Why are you removing this time from the entry?"
                    options={reasonLookup}
                    onChange={(option) => {
                      setDeleteReasonName(option.value);
                      setDeleteReasonDetails(undefined);
                    }}
                    required
                  />
                  {deleteReasonName
                    ? deleteReasonName === "Other" && (
                        <textarea
                          defaultValue={deleteReasonDetails || ""}
                          onChange={(e) => {
                            setDeleteReasonDetails(e.target.value);
                          }}
                          placeholder="Please specify"
                          className="custom-input py-2 px-2 text-left mt-1"
                          required
                        ></textarea>
                      )
                    : timeEntryDetails?.reason?.length > 0 &&
                      timeEntryDetails?.reason[
                        timeEntryDetails?.reason?.length - 1
                      ].name === "Other" && (
                        <textarea
                          defaultValue={
                            timeEntryDetails?.reason[
                              timeEntryDetails?.reason?.length - 1
                            ].details
                          }
                          onChange={(e) => {
                            setDeleteReasonDetails(e.target.value);
                            setDeleteReasonName(
                              timeEntryDetails?.reason[
                                timeEntryDetails?.reason?.length - 1
                              ].name
                            );
                          }}
                          placeholder="Please specify"
                          className="custom-input py-2 px-2 text-left mt-1"
                          required
                        ></textarea>
                      )}

                  {/* <textarea
                    value={deleteTimeReason}
                    onChange={(e) => setDeleteTimeReason(e.target.value)}
                    placeholder="Why are you removing this time from the entry?"
                    className="custom-input"
                    required
                  ></textarea> */}
                </div>
              </div>
            )}
          </div>
          {/* bottom buttom */}
          <div className="ml-auto">
            <button
              type="button"
              className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
              onClick={handleClosePopup}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SplitTimePopup;
