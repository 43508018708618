import React, { useState, useEffect } from 'react';
import Search from './Search';
import axios from 'axios';

const serverConfig = require("./../../config/server.js");

function AssigneesOKR({setFormData, formData, assignee, grpSlug, token, selectedOrganization, handleShowAlertModal, handleCloseAlertModal}) {
  const [popup, setPopup] = useState(false);

  const [searchList, setSearchList] = useState([]);
  const [srchList, setSrchList] = useState([]);
  const [checklistMember, setChecklistMember] = useState();
  const [checklistMemberSlugList, setChecklistMemberSlugList] = useState();
  const [ownerSelected, setOwnerSelected] = useState(false);
  const [okrAssignee, setOKRAssignee] = useState();

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      axios.post(serverConfig.api_base_url + "org_owner_list_get",{token: token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('org_owner_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setSearchList(response.data.items);
            if(response.data.items.length === 1) {
              setSrchList(response.data.items)
              handleAddMember(response.data.items[0]);
              setPopup(false);
            }
            setSrchList(response.data.items);
          } else {
            setSearchList([]);
            setSrchList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          setSearchList([]);
          setSrchList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));

      //getSubtaskList();
    }
  }, [token, selectedOrganization, grpSlug]);

  useEffect(() => { 
    setChecklistMember();
    setChecklistMemberSlugList();
    setFormData({...formData, owner: ''});
  }, [grpSlug]);

useEffect(() => {
  console.log(formData);
  setFormData({...formData, owner: checklistMemberSlugList});
  console.log({...formData, owner: checklistMemberSlugList}, 'check1');
}, [checklistMember]);

useEffect(() => {
  if(assignee){
    console.log(checklistMemberSlugList); 
    // check if owner is present
    console.log(assignee?.usr, 'owner_slug')
    console.log(assignee?.username, 'username');   
    setChecklistMember(assignee?.username);
    setChecklistMemberSlugList(assignee?.usr);
    setFormData({...formData, owner: checklistMemberSlugList});
    console.log('assignee', {...formData, owner: checklistMemberSlugList});
  } else {
    setChecklistMember();
    setChecklistMemberSlugList();
  }
}, [assignee]);

const handleAddMember = (data) => {
  console.log(data);
  setChecklistMember(data.owner);
  setChecklistMemberSlugList(data.slug);
  setPopup(false);
}

const handleRemoveMember = () => {
  setChecklistMember();
  setChecklistMemberSlugList();
  console.log(checklistMemberSlugList);
}
  return (

    <div className='mt-7'>
      <div className='flex items-center justify-start'>
        <h2 className="sum-title text-custom">Owner<span className='text-red-500'>*</span></h2>
        <label className="cursor-pointer">
          <button type="button" className="font-medium inline-block text-gray-500 text-lg px-2" onClick={() => { setPopup(!popup) }} disabled={srchList.length===1} title={searchList.length === 0 ? 'Select group or add members to selected group in order to add assignees' : 'Add Assignees'}>+</button>
        </label>
      </div>

      {popup && <div className='organisationDropdownCard mt-2' style={{width: '100%', left: 0, position: 'unset'}}>
        <div className='px-2 mt-4 w-full'><Search srchList={srchList} setSearchList={setSearchList} /></div>
        <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{borderBottom: 'none'}}>
          {searchList && searchList.length > 0 && <ul className='px-1 text-sm'>
            {searchList.map((data, index)=>{
              return(<li key={index} className={index === searchList.length-1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => checklistMember && checklistMemberSlugList === data.slug ? handleRemoveMember(data.slug) : handleAddMember(data)} title={checklistMember && checklistMemberSlugList === data.slug ? `Remove ${data.name}` : `Add ${data.name}`}>
                <div>{!data.icon ? <img src={`https://ui-avatars.com/api/?name=${data?.owner} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt={data?.owner} className='rounded-full' width="40" /> : <img src={data.icon} alt={data?.owner} className='rounded-full' width="40" />}</div>
                <div className="ml-4">{data.owner}</div>
                {checklistMember && checklistMemberSlugList === data.slug ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
              </li>)
            })}
          </ul>}
        </div>
      </div>}
      {checklistMember &&  <div className='flex assignee-list mt-3'>
        
        <div> <img src={`https://ui-avatars.com/api/?name=${checklistMember} &rounded=false&length=1&background=6C9BF6&format=svg&color=fff`} alt={checklistMember} className='rounded-full' width="30" /></div>
      
      </div>}

    </div>
  )
}

export default AssigneesOKR;