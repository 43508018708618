import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Context } from "../../../contextApi/context";
import axios from "axios";
import UserService from "../../../services/UserService";
import "../../../kz-datamanagement/css/mapmetadata.css";
import DwhTable from "../../../kz-datamanagement/tables/DwhTable.js";
import Notification from "../../../common/notification/Notification.js";
const serverConfig = require("../../../config/server.js");

const Members = ({ pcy, org }) => {
  const { selectedOrganization } = useContext(Context);
  const { isDarkTheme } = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshSchemaList, setRefreshSchemaList] = useState(false);

  const [rowHovered, setRowHovered] = useState(null);
  const [ownerTooltip, setOwnerTooltip] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  const toggleTooltip = () => {
    setOwnerTooltip(!ownerTooltip);
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", { jwt: jwt })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  useEffect(() => {
    console.log("token", token);
    console.log(sessionStorage.getItem("token"));

    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);
      console.log("token", token);
      /* policy_member_get */
      axios
        .post(serverConfig.api_base_url + "policy_member_get", {
          token: token,
          org: org,
          pcy: pcy,
        })
        .then((response) => {
          console.log("policy_member_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            if (items !== null || items !== undefined) {
              console.log(response.data.items);
              let appDataArray = response.data.items;
              let appData = [];

              console.log(appDataArray);
              appDataArray &&
                appDataArray.reverse().map((item, index) => {
                  let noOfActions =
                    Number(item.is_editable) +
                    Number(item.is_deletable) +
                    Number(item.is_validated === 0 ? 1 : 0) +
                    Number(item.is_validated);
                  const Actions = () => (
                    <>
                      {item.is_editable === 1 && (
                        <Button
                          title={noOfActions > 2 ? "" : "Edit"}
                          variant="text"
                          onClick={() => handleShowEditSchemaModal(item)}
                        >
                          <i
                            className="fa-solid fa-pencil not-0"
                            aria-hidden="true"
                          ></i>{" "}
                          {noOfActions > 2 && <span>Edit</span>}
                        </Button>
                      )}
                      {item.is_deletable === 1 && (
                        <Button
                          title={noOfActions > 2 ? "" : "Delete"}
                          variant="text"
                          onClick={() =>
                            handleShowDeleteSchemaModal(
                              item?.name,
                              item?.schema
                            )
                          }
                        >
                          <i
                            className="fa-solid fa-trash-can text-red-500"
                            aria-hidden="true"
                          ></i>{" "}
                          {noOfActions > 2 && <span>Delete</span>}
                        </Button>
                      )}
                      {item.is_validated === 0 && (
                        <Button
                          title={noOfActions > 2 ? "" : "Validate"}
                          variant="text"
                          onClick={(e) => handleValidateSchema(item?.schema, e)}
                        >
                          <i className="fa-solid fa-file-circle-check not-0"></i>{" "}
                          {noOfActions > 2 && <span>Validate</span>}
                        </Button>
                      )}
                      {item.is_validated === 1 && (
                        <Button
                          title={noOfActions > 2 ? "" : "Provision All"}
                          variant="text"
                          onClick={(e) => handleProvisionAll(item?.schema, e)}
                        >
                          <i className="fa-solid fa-hand-holding-medical not-0"></i>{" "}
                          {noOfActions > 2 && <span>Provision All</span>}
                        </Button>
                      )}
                    </>
                  );
                  {
                    item.actions =
                      noOfActions > 2 ? (
                        <div className="text-right more-options-wrapper">
                          {(item.is_editable === 1 ||
                            item.is_deletable === 1 ||
                            item.is_validated === 0 ||
                            item.is_validated === 1) && (
                            <button
                              type="button"
                              onClick={handleShowMoreOptions}
                              title="More"
                              className="btn-more-options"
                            >
                              <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                            </button>
                          )}
                          <div
                            className="more-options"
                            style={{ display: "none" }}
                          >
                            <Actions />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-end gap-3">
                          <Actions />
                        </div>
                      );
                  }
                  appData.push(item);
                });
              console.log(appData);
              setTableData(appData);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization, refreshSchemaList]);

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ["Updated", "Option 2", "Option 3"];
  const sortingIcons = [sortDown, sortUp];

  const columns = [
    {
      name: <div className="row-label">Member Name</div>,
      selector: (row) => (
        <div className="flex items-center ml-2">
          <div className="rounded-full overflow-hidden">
            <img
              src={`https://ui-avatars.com/api/?name=${row?.member}&rounded=true&length=1&background=6ADA00&format=svg&color=fff`}
              alt="member-image"
              height={40}
              width={40}
              className="object-cover p-1"
            />
          </div>
          <div className="ml-2">
            <b>{row?.member}</b>
          </div>
        </div>
      ),
      //width: '27%'
    },
    {
      name: <div className="row-label">Signed</div>,
      // selector: (row) => row.description,
      cell: (row) =>
        row.is_signed === 1 ? (
          <div className="status green ml-2">
            <i className="fa-regular fa-circle-check"></i>
          </div>
        ) : (
          <div className="status red ml-2">
            <i className="fa-regular fa-clock"></i>
          </div>
        ),
      sortable: true,
      wrap: true,
      width: "15%",
      //width: '32%'
    },
    {
      name: (
        <div
          className="row-label
      "
        >
          Signed Date
        </div>
      ),
      selector: (row) => {
        if (row.is_signed_date_time) {
          const dateTimeString = row.is_signed_date_time;
          const dateOnly = dateTimeString.split(" ")[0];
          return dateOnly;
        }
        return "Pending to sign";
      },

      sortable: true,
      wrap: true,
      //width: '11%'
    },
    {
      name: <div className="row-label">Due Date</div>,
      selector: (row) => (
        <div className="ml-8 pl-20">
          {row.due_date}
        </div>
      ),
      sortable: true,
      wrap: true,
      width: "15%",
      //width: '32%'
    },
    // {
    //   name: <div className="row-label">Actions</div>,
    //   selector: (row) => row.actions,
    //   //width: 'calc(15% - 48px)'
    // },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  //Create
  const [createSchemaModalShow, setCreateSchemaModalShow] = useState(false);
  const handleCloseCreateSchemaModal = () => {
    setCreateSchemaModalShow(false);
    setFormData({});
    setFormMessage({});
  };

  //Edit
  const [schemaName, setSchemaName] = useState("");
  const [schemaSlug, setSchemaSlug] = useState("");
  const [editSchemaModalShow, setEditSchemaModalShow] = useState(false);

  const handleShowEditSchemaModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditSchemaModalShow(true);

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
  };

  //Delete
  const [deleteSchemaModalShow, setDeleteSchemaModalShow] = useState(false);

  const handleShowDeleteSchemaModal = (schema_name, schema_slug) => {
    setDeleteSchemaModalShow(true);
    setSchemaName(schema_name);
    setSchemaSlug(schema_slug);

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
  };

  //Validate
  const handleValidateSchema = (schemaSlug, event) => {
    event.preventDefault();

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }

    axios
      .post(serverConfig.api_base_url + "dwh_schema_validate", {
        token: token,
        schema: schemaSlug,
      })
      .then((response) => {
        console.log("dwh_schema_validate:", response);
        if (response.data.status === 200) {
          setRefreshSchemaList(!refreshSchemaList);

          setSchemaSlug("");
          setSchemaName("");

          handleShowAlertModal("Schema validated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  //Provision all tables
  const handleProvisionAll = (schemaSlug, event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }

    axios
      .post(serverConfig.api_base_url + "dwh_table_provision_all", {
        token: token,
        org: selectedOrganization?.slug,
        schema: schemaSlug,
      })
      .then((response) => {
        console.log("dwh_table_provision_all:", response);
        if (response.data.status === 200) {
          setRefreshSchemaList(!refreshSchemaList);

          handleShowAlertModal("Related tables provisioned successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div className="overflow-x-auto px-14 ms-2 mt-4 pb-3">
      <div className="table-custom table-dbs-sub">
        <DataTable
          title={
            <div className=" table-title rounded">
              <div className="flex justify-between items-center">
                {/* Left side: Three tabs flexed together */}
                <div className="flex space-x-4">
                  <h4 className="font-bold text-base">Members</h4>
                </div>
                <span className="text-right">
                  {/* <Button
                    type="button"
                    className="sub-grp px-3 py-2"
                    title="Create Schema"
                    onClick={handleAddMember}
                  >
                    <i className="fa-solid fa-plus" aria-hidden="true"></i> Add
                    Members
                  </Button>
                  &nbsp;
                  <Button
                    type="button"
                    className="sub-grp px-3 py-2"
                    title="Create Schema"
                    onClick={handleAddMember}
                  >
                    <i className="fa-solid fa-download" aria-hidden="true"></i>{" "}
                    Report Summary
                  </Button> */}
                </span>
              </div>
            </div>
          }
          columns={columns}
          data={tableData}
          responsive={true}
          pagination={tableData.length > 10 ? true : false}
          //noTableHead
          highlightOnHover
          customStyles={{
            table: {
              style: {
                border: isDarkTheme
                  ? "2px solid #494B4B"
                  : "2px solid var(--neutral-400)",
                borderRadius: "16px 16px 10px 10px",
              },
            },
            rows: {
              style: {
                minHeight: "48px",
                border: isDarkTheme
                  ? "2px solid var(--neutral-dark-400)"
                  : "2px solid var(--neutral-400)",
                borderRadius: "10px",
                marginTop: "10px",
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? "#ffffff" : "#000000",
                backgroundColor: isDarkTheme ? "#222222" : "#EFF3F9",
                outline: "none",
              },
            },
            expanderButton: {
              style: {
                borderRadius: "8px",
              },
            },
          }}
          noDataComponent={
            <div className="text-xs">There are no records to display</div>
          }
        />
      </div>
    </div>
  );
};

export default Members;
