import React, { useState, useContext } from 'react';
import { Context } from '../../contextApi/context';
import TableSubscription from './table/TableSubscription';

const Subscription = () => {
  
  const {collapse, tab,taskPopup} = useContext(Context)
  const[newGroupPopup , setNewGroupPopup]= useState(false)

    return (
        <>
      <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
      <div class="container-fluid">
        <TableSubscription/>
    
      </div>
     
      </div>
    </div>
        </>
    );
}

export default Subscription;