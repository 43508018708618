import React,{useState, useEffect, useContext} from 'react';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import TimeGraph from './TimeGraph.js';

const serverConfig = require("./../../config/server.js");

function Daily({timeLog, selectedOrganization, setAddTime, handleShowDeleteModal, handleShowEditModal, handleShowSplitModal}) {

  const { isDarkTheme } = useContext(Context);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    console.log(selectedOrganization);
    console.log(timeLog);
    console.log(new Date().toISOString().split("T")[0]);
    setTableData(timeLog);
  },[timeLog]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }

    event.target.closest('.rdt_TableBody').scrollTop = event.target.closest('.rdt_TableBody').scrollHeight;

  }

  const columns = [
    {
      name: '',
      cell: (row) => <div className='border rounded-lg flex items-center bg-white' style={{width: "40px", height: "40px"}}>{row?.group.logo_base64 ? <img className='rounded-lg image-logo' src={row?.group.logo_base64} alt={row?.group.name} width="40" height="40" style={{borderColor:row?.group.default_color}} /> : <img className='rounded-lg image-logo' height={40} width={40} src={row?.group && row?.group.logo ? row?.group.logo : `https://ui-avatars.com/api/?name=${row?.group.name}&length=1&background=${row?.group.default_color.replace('#','')}&format=svg&color=fff`} alt={row?.group.name} style={{borderColor:row?.group.default_color}} />}</div>,
      grow: 0,
      sortable: false,
    },
    {
      selector: row => row?.task_name,
      name: <div className='row-label '>Project</div>,
      cell: (row) => <div className="flex-column  py-2 pl-2">
        <div className='text-heading line-clamp-2'>{row?.task_name}</div>
        <div className='text-detail pt-1 line-clamp-2'>{selectedOrganization?.name} | {row?.group.name}</div>
      </div>,
      sortable: true,
      wrap: true,
      width: "47%",
    },
    {
      name: <div className='row-label'>Activity</div>,
      selector: row => row?.activity,
      cell: (row) => <div className='pl-6'>{row.activity ? row.activity : '0'}%</div>,
      grow: 0,
      sortable: true,
    },
    {
      name: <div className='row-label'>Idle</div>,
      selector: row => row?.idle,
      cell: (row) => <div className='pl-4'>{row.idle ? row.idle : '0'}%</div>,
      grow: 0,
      sortable: true,
    },
    {
      name: <div className='row-label'>Manual</div>,
      selector: row => row?.manual,
      cell: (row) => <div className='pl-6'>{row.manual ? row.manual : '0'}%</div>,
      grow: 0,
      sortable: true,
    },
    {
      name: <div className='row-label'>Duration</div>,
      selector: row => <div className='pl-4'>{row?.duration}</div>,
      grow: 0,
      sortable: true,
      width: "8%",
    },
    {
      name: <div className='row-label pl-16'>Time</div>,
      cell: (row) => <div className='text-blue-400 pl-4'>{new Date(row.start_time + ' UTC').toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })} - {new Date(row.end_time + ' UTC').toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}</div>,
      width: "150px",
      grow: 0,
      sortable: true,
      width: "14%",
    },
    {
      cell: (row) => <div className="text-right more-options-wrapper">
        <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
        <div className="more-options" style={{display: "none"}}>
          <button type="button" onClick={() => handleShowEditModal(row)}><i className="fa-solid fa-pencil" aria-hidden="true"></i> <span>Edit time entry</span></button>
          <button type="button" onClick={() => handleShowSplitModal(row)} ><i className="fa-solid fa-arrows-split-up-and-left" aria-hidden="true"></i> <span>Split time entry</span></button>
          <button type="button" onClick={() => handleShowDeleteModal(
            row?.timelog, 
            new Date(row.start_time).toDateString(), 
            new Date(row.start_time + ' UTC').toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }),
            new Date(row.end_time + ' UTC').toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
          )}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> <span>Delete time entry</span></button>
        </div>
      </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  return (
    <div className='mt-5 pt-5 flex-auto overflow-y-auto'>
      {timeLog.length > 0 ? timeLog.map((item) => {
        return(
          <section className='mb-5'>
            <div className='flex items-center gap-2 mb-3'>
              <h3><b>{item.date === new Date().toISOString().split("T")[0] ? 'Today' : new Date(item.date).toDateString()}</b></h3>
              <span className='bg-accent-50 font-semibold'>{item.total_duration}</span>
            </div>
            <TimeGraph details={item?.project} />
            {item.project.length > 0 ? <DataTable
              className='table-h-auto'
              columns={columns}
              data={item.project}
              responsive={true}
              pagination={item.project.length > 10 ? true : false}
              //highlightOnHover
              //pointerOnHover
              customStyles={{
                rows: {
                  highlightOnHoverStyle: {
                    color: isDarkTheme ? '#ffffff' : '#000000',
                    backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                    borderRadius: '14px',
                    outline: 'none'
                  }
                }
              }}
            /> : <>No data</>}
          </section>
        )
      }) : <div className='flex flex-col h-full items-center justify-center'>
        <div className='mb-8'><img src={require('./../images/no-groups-subgroups.png')} alt="No time entry" /></div>
        <p className='text-center'><b>This Organisation has no Time entry<br/> for the selected Date range</b></p>
        <p className='text-sm'>Add Time entry for {selectedOrganization?.name}</p>
        <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={() => { setAddTime(true) }} >Add Time</button></div>
      </div>}
    </div>
  );
}

export default Daily;