import React, { setState, useEffect, useState } from 'react'
import './../css/Assignees.css';
import { userData } from '../../Asset/Data/UserData'
function Assignees({ handleAddName, setName, name, setNames, names }) {
  const [isFocus, setIsFocus] = useState('')
  const [reload, setReload] = useState(true)

  const handleSelectChange = (e) => {
    setName(e.target.value);


    // setName('');

    if (e.target.value !== '' && !names.includes(e.target.value)) {
      userData[e.target.value - 1].select = true
      setNames([...names, e.target.value]);

    } else if (names.includes(e.target.value)) {
      const indexToRemove = names.indexOf(e.target.value);
      setReload(!reload)
      if (indexToRemove !== -1) {
        // Remove the element at the found index
        names.splice(indexToRemove, 1);
      }
      userData[e.target.value - 1].select = false
    }

  };
  useEffect(() => { }, [reload])
  return (

    <div className='Assignees'>
      <div className='py-4 px-4 search'>
        <div className='relative'>
          <input className='search-input p-2 w-full' placeholder='Search' onFocus={() => { setIsFocus(true) }} onBlur={() => { setIsFocus(false) }} />

          {!isFocus && <div className='absolute left-4 top-2'><i class="fa-solid fa-magnifying-glass text-xs text-gray-600"></i></div>}
        </div>

      </div>
      <select
        multiple={true}
        value={name}
        //onChange={handleSelectChange}
        className='w-full'
      >
        {userData.map((info, index) => {
          return (

            <option value={info.id} onClick={handleSelectChange} className='hover-names'>
              <div className='flex justify-between align-middle p-2 w-full hover-names'>
                <div className='flex justify-start align-middle w-full gap-4' style={{ alignItems: 'center' }}>
                  <img className='h-8 w-8 rounded-full object-cover' src={info.user} alt={info.name} />
                  <div className=' text-xs'>{info.name}</div>
                </div>
                <div>
                  {info.select && <i class="fa-solid fa-check text-black text-xs"></i>}


                </div>
              </div>
            </option>
          )
        })}


      </select>
      <div></div>
    </div>


  )
}

export default Assignees