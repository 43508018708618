import React, {useState, useEffect} from "react";
import axios from "axios";
import { Form } from 'react-bootstrap';
import user from '../Asset/Images/userprofile.png';
import aiProfle from './assets/ai-profile.png';
import { useContext } from "react";
import { Context } from "../contextApi/context.js";
import loadingGIF from './assets/loading.gif';
import Notification from "../common/notification/Notification.js";
import './css/aidocs.css';

const UploadDoc = () => {

    const {selectedOrganization} = useContext(Context);

    const [selectedFile, setSelectedFile] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [query, setQuery] = useState('');
    const [token, setToken] = useState(null);
    const [dbServiceLookup, setDbServiceLookup] = useState([]);
    const [schemaLookup, setSchemaLookup] = useState([]);
    const [tableLookup, setTableLookup] = useState([]);
    const [selectedDbService, setSelectedDbService] = useState({});
    const [selectedSchema, setSelectedSchema] = useState({});
    const [selectedTable, setSelectedTable] = useState([]);
    const [dbSlug, setDbSlug] = useState('');
    const [scSlug, setScSlug] = useState('');
    const [plotData, setPlotData] = useState();
    const [plotLayout, setPlotLayout] = useState();
    const [textOutput, setTextOutput] = useState('');
    const [completedTyping, setCompletedTyping] = useState(false);
    const [displayResponse, setDisplayResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState(0);
    const [loader, setLoader] = useState(false);
    const [fileUploadDone, setFileUploadDone] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);

    const plotConfig = { displayModeBar: false };

    const handleCloseAlertModal = () => {
        setAlertMessage("");
        setShowOkButton(false);
        setAlertModalShow(false);
      }
    
      const handleShowAlertModal = (alert_msg, show_ok_button) => {
        setAlertMessage(alert_msg);
        setShowOkButton(show_ok_button);
        setAlertModalShow(true);
      }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log(file, 'file');
        const allowedTypes = ['application/pdf', 'text/plain']; // Allowed file types
    
        if (file && allowedTypes.includes(file.type)) {
          // File type is allowed
          setSelectedFile(file);
          // You can perform further actions with the selected file
        } else {
          // File type is not allowed

          setSelectedFile(null);
          handleShowAlertModal('Please upload a .pdf or .txt file.', true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          //alert('Please upload a .pdf or .txt file.');
        }
      };

      const handleUpload = () =>{
        console.log(selectedFile, 'selectedFile');
        if(selectedFile) {
            setLoader(true);
            const formData = new FormData();
            formData.append('file', selectedFile);

            axios.post('https://sandbox.kezel.io/pdf-loader/upload_pdf/', formData)
            .then(response=> {
                console.log(response.data);
                if(response.data.status === 200) {
                    setLoader(false);
                    setFileUploadDone(true);
                    handleShowAlertModal('File Uploaded Successfully. You can now ask questions related to the uploaded doc.');
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                }
                else {
                    setLoader(false);
                    handleShowAlertModal(response.data.error, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                }
            })
        }
      }

      const handleSendMessage = async (e) => {
        
        setQuery(inputValue);
        setTextOutput("");

        setLoading(true);

        try {
          const response = await axios.post('https://sandbox.kezel.io/pdf-poc/generate_output/', {
            user_input: inputValue
          });

          console.log(response.data, 'response ai');
          if(response.data.text_output) {
            setTextOutput(response.data.text_output);
          }            

        } catch (error) {
            handleShowAlertModal('Error fetching data', true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
        } finally {
          setLoading(false);
          setInputValue('');
        }

};



    return (
        <>
        <div className="chat-container">
            <div className="chat-box">

        <div className="flex flex-row">

        <Form.Group controlId="uploadData" className="upload-data mb-6 mt-1 ml-6">
                          <Form.Label className='browse-files'>
                             {selectedFile ? 
                            
                            <><img src={require('./assets/cloud_upload.png')} alt="Upload Data" className="mb-3" /><br/>
                            {selectedFile?.name}</>
                            
                            : 
                            <><img src={require('./assets/cloud_upload.png')} alt="Upload Data" height={95} className="mb-3" /><br/>
                            <p className='files-upload'>Drag &amp; Drop or click here to &nbsp; <p className='template'> Browse</p></p></>
                             } 
                          </Form.Label>
                          <Form.Control name="file" type="file" accept=".pdf, .txt" filename= 
                           {selectedFile?.name || ''}
                           onChange={handleFileChange} required />
        </Form.Group>

        <button type="submit"  className='btn-popup px-4 py-2 rounded-lg mt-2 ml-4 text-sm text-medium' style={{"height" : "40px"}} disabled={loader ? true : false} onClick={handleUpload}>{loader ? 'Uploading..' : 'Upload'}</button>

        </div>

        <div className="flex flex-row">
        {textOutput && loading === false ? <> 

            <div className="flex flex-col" >
        <div className="flex flex-row query-header mb-4">
                <img className='rounded-full mr-4' height={40} width={40} src={user} alt='user-img'/>
                <div className="queryText">{query}</div>
         
                </div>

                <div className="flex flex-row">
        <div className="flex query-answer">
                <img className='rounded-full mr-4' height={40} width={40} src={aiProfle} alt='user-img'/>
                {/* <img className='rounded-full mr-4 ai-loader' height={25} width={60} src={loader} alt='user-img'/> */}
                </div>
      <div className="col-md-6 chat-result-ai">
          <div>
          {textOutput.split('\n').map((line, index) => 
          (<p> {line}
          <br /></p>))}
          </div>
          <div>
          {/* {responseStatus === 200  ?  <Plot
              data={[plotData]}
              layout={plotLayout}
              config={plotConfig}
            /> : <> </>} */}
          </div>
        </div> </div> </div> </> : loading === true ? <>

        <div className="flex flex-col" >
        <div className="flex flex-row query-header mb-4">
                <img className='rounded-full mr-4' height={40} width={40} src={user} alt='user-img'/>
                <div className="queryText">{query}</div>
                </div>
                <div className="flex flex-row">
        <div className="flex query-asked">
                <img className='rounded-full mr-4' height={40} width={40} src={aiProfle} alt='user-img'/>
                </div>
        <div className="loader-container">
        <img className='rounded-full mr-4 ai-loader' height={25} width={60} src={loadingGIF} alt='user-img'/>
        </div>
        </div>
        </div> </> :<></> }
        </div>

            </div>
            <div className="flex flex-row ml-2 mr-2">
            <input
                type="text"
                value={(loading === true) ? '' : inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Your Request"
                className="ai-chat"
            />
            <button onClick={handleSendMessage} disabled={!fileUploadDone ? true : false} className="send-btn"><img src={require('./assets/up-arrow.png')} className="up-arrow-img" height={17} width={17} alt="Up Arrow" /></button>
            </div>

            {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
  </>}
        </div>
        </>
    )
}

export default UploadDoc;