import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Context } from "../contextApi/context";
import { Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router';
import UserService from '../services/UserService.js';
import Notification from '../common/notification/Notification.js';
import "./css/mapmetadata.css";

const serverConfig = require("../config/server.js");

const Category = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {collapse} = useContext(Context);
  const {selectedOrganization} = useContext(Context);

  const [token, setToken] = useState(null);
  const [inputs, setInputs] = useState({});
  const [mappingData, setMappingData] = useState([]);

  const [fileFormatLookupList, setFileFormatLookupList] = useState([]);
  const [stgTableList, setStgTableList] = useState([]);
  const [factTableList, setFactTableList] = useState([]);
  const [formData, setFormData] = useState({});
  const [childGroupTypeList, setChildGroupTypeList] = useState({});
  const [templateGroupList, setTemplateGroupList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [targetListOriginal, setTargetListOriginal] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [refreshMapping, setRefreshMapping] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    //console.log(JSON.parse(location.state.details));
    console.log(location.state);
    console.log(JSON.parse(location.state.details));
    if(location.state){
      setFormData(JSON.parse(location.state.details));
      setChildGroupTypeList(location.state.childGroupTypeList);
      setTemplateGroupList(location.state.templateGroupList);
      setTemplateList(location.state.templateList);
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      
    }
  }, [token, refreshMapping]);

  const handleUpdate = () => {
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_template_group_update",{token: token, org:selectedOrganization?.slug, items:formData, templateGrp:formData.templateGrp})
    .then(response=>{
      console.log("dwh_template_group_update:", response);
      if (response.data.status === 200) {
        handleShowAlertModal('Category details updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleSave = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(JSON.stringify(formData) === location.state.details);
    if(JSON.stringify(formData) !== location.state.details){
      handleUpdate();
    }
  }

  return (<>
    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
        <div className="container-fluid metadata">
          <div className="overflow-x-auto">
            <form onSubmit={handleSave} className="p-3 table-custom mt-4 flex flex-col">
              <section className="mb-5">
                <h1 className='font-semibold text-2xl'>Category Details</h1>
                <div className='grid grid-cols-2 gap-4 mt-3 mb-5'>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Name
                      <span className=' text-red-500'>*</span>
                      <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip" className='text-sm'>No whitespace and only lowercase allowed</Tooltip>}>
                        <Button className="ms-2 info-icon">i</Button>
                      </OverlayTrigger>
                    </label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' pattern="[a-z]*" className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Display Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.display_name || ""} onChange={(e)=>  setFormData({ ...formData, display_name: e.target.value})} placeholder='Display Name' className='custom-input py-2 px-2 text-left mt-1' required />
                  </div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Child Group Type</label>
                    <select value={formData.child_group_type || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, child_group_type: e.target.value, child_group_display_text: e.target.value && 'Select ' + e.target.value.split(" ").map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")})}>
                      <option value="">Select</option>
                      {childGroupTypeList.length > 0 &&
                        childGroupTypeList.map((item, index) => <option value={item.child_group_type} key={index}>{item.child_group_type}</option>)
                      }
                    </select>
                  </div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Child Group Display Text</label>
                    <div className='py-2 px-2 text-left mt-2'>{formData.child_group_type && 'Select '+ formData.child_group_type.split(" ").map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")}</div>
                  </div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Template</label>
                    <select value={formData.template || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, template: e.target.value})}>
                      <option value="">Select</option>
                      {templateList.length > 0 &&
                        templateList.map((item, index) => <option value={item.template} key={index}>{item.name}</option>)
                      }
                    </select>
                  </div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Status</label>
                    <div className='flex flex-row mt-3'>
                      {formData.status === 0 ?
                        (<div className='flex flex-row'>
                          <label class="toggle-bg">
                            <input className='toggle-checkbox' type="checkbox" checked={formData.status === 0} onChange={() => setFormData({ ...formData, status: 1})} />
                            <span class="slider round"></span>
                            <p className='toggle-btn-dis-text'>Disabled</p>
                            <p className='toggle-btn-text'>Enabled</p>
                          </label>
                        </div>) :
                        (
                          <div className='flex flex-row'>
                            <label class="toggle-bg">
                              <input className='toggle-checkbox' type="checkbox" checked={formData.status === 0} onChange={() => setFormData({ ...formData, status: 0})} />
                              <span class="slider round"></span>
                              <p className='toggle-btn-dis-text'>Disabled</p>
                              <p className='toggle-btn-text'>Enabled</p>
                            </label>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Parent Group</label>
                    <select value={formData.parentGrp || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, parentGrp: e.target.value})}>
                      <option value="">Select</option>
                      {templateGroupList.length > 0 &&
                        templateGroupList.map((item, index) => <option value={item.templateGrp} key={index}>{item.display_name}</option>)
                      }
                    </select>
                  </div>
                </div>
              </section>

              <div className='ms-auto mt-auto'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => navigate('/template-category')}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>

        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
      </div>
    </div>
  </>);
}

export default Category;