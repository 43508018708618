import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { SketchPicker } from 'react-color'; // Import the color picker component

const ColorPicker = ({color, setColor, setOpenColorPicker, setErrorMessage}) => {
  const handleColorChange = (color) => {
    setColor(color.hex);
    //setOpenColorPicker(false);
    setErrorMessage('')
  };

  return (
    <div className="color-picker-dialog">
      <SketchPicker
        color={color}
        onChange={handleColorChange}
        onChangeComplete={(color) => handleColorChange(color)}
      />
    </div>
  );
};

export default ColorPicker;
