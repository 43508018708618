import React, { useState } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';

const Graph = () => {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [textOutput, setTextOutput] = useState('');
  const [plotData, setPlotData] = useState([]);
  const [plotLayout, setPlotLayout] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://sandbox.kezel.io/api/test_graph', {
        service_name: input1,
        schema_name: input2,
        table_name: input3,
        query: input4,
      });

      console.log(response.data.data);
      const item_str = response.data.data
      const item = JSON.parse(item_str);

      setTextOutput(item.items.response_text);
      console.log(item);
      setPlotData(item.items.plotly_data);
      console.log(item.items.plotly_data);

      setPlotLayout(item.items.plotly_layout);
    } catch (error) {
      setError('Error fetching data. Please try again.');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* First column */}
        <div className="col-md-6 pe-3 position-fixed"> {/* Added 'position-fixed' class */}
          <form>
            <div className="mb-3">
              <input
                type="text"
                className="form-control custom-input"
                placeholder="Service"
                value={input1}
                onChange={(e) => setInput1(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control custom-input"
                placeholder="Schema Name"
                value={input2}
                onChange={(e) => setInput2(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control custom-input"
                placeholder="Table Name"
                value={input3}
                onChange={(e) => setInput3(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control custom-input"
                rows="5"
                placeholder="Query"
                value={input4}
                onChange={(e) => setInput4(e.target.value)}
                required
              />
            </div>
            <button type="button" style={{ backgroundColor: 'var(--secondary-700)', color: '#fff', width: "100px", height: "30px", borderRadius:"10px" }} onClick={handleSubmit}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
          </form>
          {error && <p className="text-danger mt-3">{error}</p>}
        </div>
        {/* Second column */}
        <div className="col-md-6 ps-3 ms-50 position-fixed" style={{ left: '50%' }}> {/* Added 'position-fixed' class and style */}
          <div>
            <p>{textOutput}</p>
          </div>
          <div>
            <Plot
              data={[plotData]}
              layout={plotLayout}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;
