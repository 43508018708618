import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProgressbarReadOnly from './ProgressbarReadOnly.js';
import ProgressBarUpdate from './ProgressBarUpdate.js';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import { TaskStatus } from '../tables/IssueStatus.js';
import * as FaIcons from 'react-icons/fa';

const serverConfig = require("../../config/server.js");

function Kanban({token, data, grp, setTableData, Mapme, selectedOrganization, handleLogout, refreshTable, handleShowAlertModal, handleCloseAlertModal, setShowTask, setStatus, handleShowMoreOptions, handleShowEditModalKanban, isMyTask}) {
  const [statusList, setStatusList] = useState([]);
  const [formData, setFormData] = useState({});
  const [taskData, setTaskData] = useState([]);

  useEffect(()=>{
    if (token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null) {
      console.log(grp);
      console.log(data, 'kanban_data')

      axios.post(serverConfig.api_base_url + "issue_status_get_list", { token: token, org: selectedOrganization?.slug, grp: grp })
      .then(response => {
        console.log(response.data.items, 'issue_status_get_list_res')
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("status data:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });

            setTaskData(response.data.items.map((item, index) => {
              return({
                ...item,
                id: item.seq_no,
                tasks: data.filter(el => el.status.name === item.name).sort(function(a,b){
                  return a.seq_no - b.seq_no;
                })
              })
            }));

            setStatusList(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  },[token, selectedOrganization, data]);

  useEffect(() => {
    console.log(taskData);
  },[taskData]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
  
    // change background colour if dragging
    //background: isDragging ? "rgba(0,0,0,0.125)" : "red",
    borderStyle: isDragging && "groove",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver && "rgba(0,0,0,0.125)",
  });

  const reorder = (list, startIndex, endIndex) => {
    console.log(list, startIndex, endIndex);

    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log(result);

    let finalDestArr = result.map((element, index) => {
      return({
        ...element,
        seq_no: index
      })
    });
    console.log(finalDestArr);

    let formItems = {
      token:token, 
      org: selectedOrganization.slug, 
      lane: "same",
      items: {
        /* tsk1:list[startIndex].tsk, 
        seq_no1:endIndex, 
        tsk2:list[endIndex].tsk, 
        seq_no2:startIndex */
        destination: finalDestArr
      }
    }
    console.log(formItems);

    axios.post(serverConfig.api_base_url + "task_seqno_update", formItems)
    .then(response=>{
      console.log("task_seqno_update", response);
      if (response.data.status === 200) {
        //refreshTable();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    return result;
  };
  
  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    console.log(source, destination, droppableSource, droppableDestination);
    /* console.log(sourceClone, destClone);

    console.log(sourceClone[droppableSource.index].tsk, sourceClone[droppableSource.index].name, droppableDestination.index);
    console.log(destClone[droppableDestination.index].tsk, destClone[droppableDestination.index].name, droppableDestination.index); */

    const [removed] = sourceClone.splice(droppableSource.index, 1);
  
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    console.log('sourceClone', sourceClone);
    console.log('destClone', destClone);

    let finalSourceArr = sourceClone.map((element, index) => {
      return({
        ...element,
        seq_no: index
      })
    });
    console.log(finalSourceArr);

    let finalDestArr = destClone.map((element, index) => {
      return({
        ...element,
        seq_no: index,
        status: statusList[droppableDestination.droppableId].name,
        sta: statusList[droppableDestination.droppableId].sta,
      })
    });
    console.log(finalDestArr);

    let formItems = {
      token:token, 
      org: selectedOrganization.slug, 
      lane: "different",
      items: {
        source: finalSourceArr,
        destination: finalDestArr
      }
    }
    console.log(formItems);

    axios.post(serverConfig.api_base_url + "task_seqno_update", formItems)
    .then(response=>{
      console.log("task_seqno_update", response);
      if (response.data.status === 200) {
        //refreshTable();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  
    return result;
  };

  function onDragEnd(result) {
    const { source, destination, draggableId, type } = result;
    console.log('result', result);
    console.log('source.droppableId', source.droppableId);
    console.log('destination.droppableId', destination?.droppableId);

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (type === 'column') {
      console.log('column', taskData.filter(el => el.name === draggableId)[0]);
      const newColumnOrder = Array.from(taskData);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, taskData.filter(el => el.name === draggableId)[0]);

      console.log('newColumnOrder', newColumnOrder);

      /* const newState = {
        ...taskData,
        columnOrder: newColumnOrder,
      };
      setData(newState); */
      setTaskData(newColumnOrder);

      let finalOrder = newColumnOrder.map((element, index) => {
        return({
          token:token, 
          org: selectedOrganization.slug, 
          color: element.color,
          name: element.name,
          seqno: index,
          icon: element.icon,
          sta: element.sta,
          grp: grp,
        })
      });
      console.log(finalOrder);
  
      finalOrder.forEach(formItems => {
        console.log(formItems);

        axios.post(serverConfig.api_base_url + "issue_status_update", formItems).then(response => {
          if (response.data.status === 200) {
            //refreshTable();
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        }).catch(err=>console.log("error is",err));
      });

      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    console.log(sInd, dInd);

    if (sInd === dInd) {
      console.log(taskData);
      const items = reorder(taskData[sInd].tasks, source.index, destination.index);
      //console.log(items);
      //console.log(taskData);
      const newState = [...taskData];
      //console.log(newState);
      newState[sInd].tasks = items;
      console.log(newState);
      setTaskData(newState);
    } else {
      const result = move(taskData[sInd].tasks, taskData[dInd].tasks, source, destination);
      const newState = [...taskData];
      newState[sInd].tasks = result[sInd];
      newState[dInd].tasks = result[dInd];
      console.log(newState, newState.filter(group => group.length));
      //setState(newState.filter(group => group.length));
      setTaskData(newState);
    }
  }

  const handleEditModalKanban = (taskSlug) => {
    console.log(taskData);//this data can be pushed to DB at this point also. In this case remove task_seqno_update from other place
    handleShowEditModalKanban(taskSlug);
  }

  return (
    <div className='flex-auto overflow-y-hidden'>
      {taskData.length > 0 && 
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-columns" direction="horizontal" type="column">
            {(provided, snapshot) => (
              <div className="flex gap-4 h-full" {...provided.droppableProps} ref={provided.innerRef} style={{border: snapshot.isDraggingOver && "1px dashed #cccccc", borderRadius: snapshot.isDraggingOver && "14px"}}>
                {taskData.map((status, ind)=>{
                  return(
                    <Draggable key={status.name} draggableId={status.name} index={ind}>
                      {(provided, snapshot) => (
                        <div className='flex flex-col overflow-hidden pb-3' {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps} style={{
                          backgroundColor: status.color ? status.color+'20' : 'var(--secondary-700)'+'20',
                          borderRadius: "14px",
                          minWidth: "260px",
                          width: "260px",
                          ...provided.draggableProps.style
                        }}>
                          <div className='flex justify-start items-center gap-2 status status-grid mb-3'
                          style={{
                            color: status.color ? status.color : 'var(--secondary-700)',
                            backgroundColor: status.color ? status.color+'20' : 'var(--secondary-700)'+'20',
                            borderColor:  status.color ? status.color+'90' : 'var(--secondary-700)'+'50',
                          }}>
                            <div className='flex gap-2 items-center'>
                              <div className='text-sm brightness-[0.6]'>{React.createElement(FaIcons[status?.icon])}</div>
                              <div className='text-sm brightness-[0.6]'>{status.name}</div>
                              <div className='text-sm brightness-[0.6]'>({status?.tasks?.length ? status?.tasks?.length : 0})</div>
                              {/* check privilege to add status */}
                              {/* <TaskStatus token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} refreshTable={refreshTable} statusAction="edit" row={{name:status.name, icon:status.icon, color:status.color, sta:""}} /> */}
                            </div>
                            <button title='Add Task' className='btn-add' onClick={() => { setShowTask(true); setStatus(status); }} style={{
                              borderColor:  status.color ? status.color+'90' : 'var(--secondary-700)'+'50',
                            }}><i className="fa-solid fa-plus brightness-[0.6]"></i></button>
                          </div>

                          {/* {status.tasks.length > 0 &&  */}
                          <Droppable key={ind} droppableId={`${ind}`} type="task">
                            {(provided, snapshot) => (
                              <div id={status.name} className='flex-auto cards-wrap ml-2 mr-1 pr-1 overflow-y-auto' ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                {status?.tasks?.length > 0 && status.tasks/* .sort(function(a,b){
                                  return a.seq_no - b.seq_no;
                                }) */.map((row, index) => {
                                  let todayDate=new Date().toISOString().split('T')[0];
                                  return(
                                    <Draggable key={row.tsk} draggableId={row.tsk} index={index}>
                                      {(provided, snapshot) => (
                                        <div id={row.tsk} className='card no-drop flex flex-col py-3 px-3' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}>
                                          <div className='flex items-center gap-2'>
                                            <div className='border rounded-lg flex items-center bg-white' style={{width: "40px", height: "40px"}}>{row?.group.logo_base64 ? <img src={row?.group.logo_base64} alt={row?.group.name} width="40" height="40" /> : <img className='rounded-lg image-logo' height={40} width={40} src={row?.group && row?.group.logo ? row?.group.logo : `https://ui-avatars.com/api/?name=${row?.group.name}&length=1&background=${row?.group.default_color.replace('#','')}&format=svg&color=fff`} alt={row?.group.name} />}</div>
                          
                                              <div className="flex-column">
                                              <div className='text-heading line-clamp-2'>{row?.name}</div>
                                              <div className='text-detail pt-1 line-clamp-2'>{row?.group?.name}</div>                                              
                                            </div>
                                
                                          </div>
                                          <div style={{cursor: "pointer"}} className="select-icon mt-2 mr-2" title={row?.issue_type?.name}>
                                                {row.issue_type ? <span className='icn' style={{color:row.issue_type.color, backgroundColor: row.issue_type.color+'20', borderColor: row.issue_type.color+'90'}}>{React.createElement(FaIcons[row.issue_type?.icon])}</span> : <></>}
                                              </div>
                                            
                                          <div style={{ fontSize: 10 }} className='w-full mt-3'>
                                            {/* <ProgressbarReadOnly progress_value={row.progress_percentage ? row.progress_percentage : 0}/>  */}
                                            <ProgressBarUpdate progress_value={row.progress_percentage ? row.progress_percentage : 0} token={token} data={row} selectedOrganization={selectedOrganization} handleLogout={handleLogout} refreshTable={refreshTable} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
                                          </div>
                                          <div className='flex items-center gap-1 text-sm mt-3'>
                                            <span>Due Date:</span>
                                            <div className={row?.due_date === 'Today' ? 'dueto-today' : (row.due_date < todayDate ? 'dueto-past' : "")}>
                                              <div className='table-text-dark'>{row?.due_date} {row?.due_date === 'Today' || row.due_date < todayDate ? <i className="fa-solid fa-triangle-exclamation ml-1"></i> : ''}</div>
                                            </div>
                                          </div>
                                          <div className="flex overflow-hidden mt-3">
                                            {Mapme(row?.labels)}
                                          </div>
                                          <div className='actions'>
                                            {/* <div className="text-right more-options-wrapper text-xs">
                                              <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
                                              <div className="more-options" style={{display: "none"}}>
                                                <button type="button" onClick={() => handleShowEditModal(row)}><i className="fa-solid fa-pencil" aria-hidden="true"></i> <span>Edit Task</span></button>
                                              </div>
                                            </div> */}
                                            <div className="flex items-center justify-end gap-3 text-xs p-2">
                                              <button title="Edit Task" type="button" onClick={() => /* handleShowEditModalKanban(row.tsk) */ handleEditModalKanban(row.tsk)}><i className="fa-solid fa-pencil" aria-hidden="true"></i></button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          {/* } */}
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
    </div>
  )
}

export default Kanban