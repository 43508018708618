import React,{useState, useEffect, useContext} from 'react';
import { projectData } from '../../Asset/Data/projectData';
import { permissionsData } from '../../Asset/Data/permissionsData';
import axios from 'axios';
import { Context } from '../../contextApi/context';
import UserService from '../../services/UserService';
import { Form, Dropdown } from 'react-bootstrap';

const serverConfig = require("../../config/server.js");

function EditUser({name, orgSlug, roles, email, slug, active, lock, setEditRowClicked, setModalClosed, modalClosed, manager}) {
  const[formData, setFormData] = useState({ name: "", email: "" });
  const[users, setUsers] = useState([]);
  const[tab, setTab] = useState('User Info');
  const[formDataTwo, setFormDataTwo]= useState({role:'',projects:[]});
  const[memberTab, setMemberTab]=useState(0)
  const[isAlertDropdown, setIsAlertDropdown] = useState(false);
  const[userName, setUserName] = useState(name);
  const[userEmail, setUserEmail] = useState(email);
  const[nameError, setNameError] = useState('');
  const[lockChecked, setLockChecked] = useState(lock);
  const[activeChecked, setActiveChecked] = useState(active);
  const[userPrivileged, setUserPrivileged] = useState(false);
  const[cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [rolesList, setRolesList] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [userData, setUserData] = useState({});
  const [actions, setActions] = useState(false);
  const [activeCheckboxChecked, setActiveCheckboxChecked] = useState((active===1) ? true : false);
  const [lockCheckboxChecked, setLockCheckboxChecked] = useState((lock===1) ? true : false);
  const {selectedOrganization} = useContext(Context);
  const [managerList, setManagerList] = useState([]);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }


  const handleActive = (emailid, changeActive) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: cToken, active: changeActive, user:slug, org:selectedOrganization?.slug})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActiveCheckboxChecked(!activeCheckboxChecked);
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const handleClose=()=>{
    setEditRowClicked('');
    setModalClosed(!modalClosed);
  }



  const handleLocked = (emailid, changeLock) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: cToken, email_id: emailid, locked: changeLock, user:slug, org:selectedOrganization?.slug})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setLockCheckboxChecked(!lockCheckboxChecked);
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const handleAddUser = () => {
    if (formData.name && formData.email) {
      setUsers([...users, formData]);
      setFormData({ name: "", email: "" });
    }
    console.log(roles)
  };

  const handleNameChange = (event) => {
    const name = event.target.name;
        const value = event.target.value;
        setUserName(value);
  }

  const handleEmailChange = (event) => {
    const name = event.target.name;
        const value = event.target.value;
        setUserEmail(value);
  }

  const handleRemoveUser = (index) => {
    const updatedUsers = [...users];
    updatedUsers.splice(index, 1);
    setUsers(updatedUsers);
  };


  const handleCheckboxChange = (event) => {
    console.log(event.target.checked, event.target.value);
    if(event.target.checked){
      axios.post(serverConfig.api_base_url + "user_role_grant",{token: cToken,  user:slug, org:orgSlug, roles: [event.target.value]})
      .then(response=>{
        console.log('user_role_grant response', response);
        if(response.data.status === 200){
          console.log('success user_grant_role');
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    } else {
      axios.post(serverConfig.api_base_url + "user_role_revoke",{token: cToken,  org:orgSlug, user:slug, roles: [event.target.value]})
      .then(response=>{
        console.log('user_role_revoke response', response);
        if(response.data.status === 200){
          console.log('success user_revoke_role');
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }


  useEffect(()=> {

    if(cToken !== null && cToken !== undefined) {
      axios.post(serverConfig.api_base_url + "user_get_manager_list", {
        token: cToken,
        org : selectedOrganization?.slug
      }).then(response=> {
        console.log(response.data.items, 'user_get_manager_list');
        setManagerList(response.data.items);
      }).catch(err=> {
        console.log(err);
      })
    }

  },[cToken])


  const getToken = () => {
    //console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }  else if(response.data.status === 301){
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    //console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    }
  }, []);

  let currentRole = [];

  const handleSelect = (e) => {
    console.log(e.target.value, 'manager');
    const manager = e.target.value;
    e.preventDefault();
    axios.post(serverConfig.api_base_url + "user_reporting_manager_add", {
      token: cToken,
      org:selectedOrganization?.slug,
      user_email: email,
      manager_email: manager
    }).then(response=> {
      console.log(response.data)
      if(response.data.status === 200) {
        //handleClose();
      }
    }).catch(err=> {
      console.log(err);
    })
  }

  useEffect(() => {
   
      if(cToken !== null && cToken !== undefined){
    

        /* user_get_list */
        // console.log(roles, 'rolessss');
        // currentRole = roles.map((item) => {
        //     item?.value
        // })
        setCurrentUserRole(roles);
        // axios.post(serverConfig.api_base_url + "user_get_list",{token:cToken})
        // .then(response=>{
        //   console.log("user_get_list response:", response);
        //   //console.log(window.location.href.split('?')[1]);
        //   if(response.data.status === 200){
           
        //     console.log(items);
        //     if (items !== null || items !== undefined){
        //       var result = items.filter(user => user.email_id===email);
        //       console.log(result[0], 'userData');
        //       setUserData(result[0]);
        //     //   setCurrentUserRole(result[0].roles);
        //     }
        //   } else if(response.data.status === 301){
        //     handleLogout();
        //   } else {
        //     alert(response.data.message);
        //   }
        // })
        // .catch(err=>console.log("error is",err));

        /* role_get_list */
        axios.post(serverConfig.api_base_url + "role_list_get",{token: cToken, level:'Organization'})
        .then(response=>{
          console.log('role_get_list response', response.data.items);
          if(response.data.status === 200){
            setRolesList(response.data.items);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));

    }
    
  }, [cToken,actions]);

 const options=[ { label: "Grapes", value: "grapes" },
 { label: "Mango", value: "mango" },
 { label: "Strawberry", value: "strawberry" }]
  return (
    <div className='edit-user rounded-3xl py-4 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col items-center'>
      <div className='overflow-y-auto '>
      <div className='flex items-center gap-8'>
      
           
    <div className='flex flex-col team-label'>

      <div className="flex space-x-4">
           <button className={`px-6 py-3 cursor-pointer ${memberTab===0 ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('User Info'); setMemberTab(0);}}>User Info</button>
      </div>

      <h5 className='mt-4 font-semibold'>User Info</h5>

      <div className='flex flex-row'>
      <div className='flex flex-col gap-1 mt-6 mr-8 text-xs input-field'>
          <label>Name<span className=' text-red-500'>*</span></label>
          <input value={name}
              onChange={()=>{}} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
              {nameError !== '' ? <p className="error-text">{nameError}</p> : <></>}

      </div>

      <div className='flex flex-col gap-1 mt-6 text-xs input-field'>
          <label>Email ID<span className=' text-red-500'>*</span></label>
          <input value={email}
              onChange={()=>{}} placeholder='Email' className='custom-input py-2 px-2 text-left mt-1'/>
              {nameError !== '' ? <p className="error-text">{nameError}</p> : <></>}

      </div>
      </div>
      <div className='flex flex-row'>
        <div>
      <h5 className='mt-6 font-semibold'>Status</h5>

      <p className='mt-4 text-xs'>Active Status</p>

      <div className='flex flex-row mt-4'>    

      {active===0 ?
      (<div className='flex flex-row'>
            <label class="toggle-bg">
        <input className='toggle-checkbox' type="checkbox" checked={!activeCheckboxChecked} onChange={() => {handleActive(email, 1)}} />
        <span class="slider round"></span>
        <p className='toggle-btn-dis-text'>Disabled</p>
        <p className='toggle-btn-text'>Enabled</p>
        </label>
        </div>) :
        (
          <div className='flex flex-row'>
            <label class="toggle-bg">
        <input className='toggle-checkbox' type="checkbox" checked={!activeCheckboxChecked} onChange={() => {handleActive(email, 0)}} />
        <span class="slider round"></span>
        <p className='toggle-btn-dis-text'>Disabled</p>
        <p className='toggle-btn-text'>Enabled</p>
        </label>
        </div>
        ) }

      </div>

      <p className='mt-4 text-xs'>Lock Status</p>

      <div className='flex flex-row mt-4'>    

      {userData.locked===1 ? (<div className='flex flex-row'>
            <label class="toggle-bg">
        <input className='toggle-checkbox' type="checkbox" checked={lockCheckboxChecked} onChange={() => {handleLocked(email, 0)}} />
        <span class="slider round"></span>
        <p className='toggle-btn-text'>Unlocked</p>
        <p className='toggle-btn-dis-text'>Locked</p>
        </label>
        </div>) :
        (<>
          <div className='flex flex-row'>
            <label class="toggle-bg">
        <input className='toggle-checkbox' type="checkbox" checked={lockCheckboxChecked} onChange={() => {handleLocked(email, 1)}}/>
        <span class="slider round"></span>
        <p className='toggle-btn-text'>Unlocked</p>
        <p className='toggle-btn-dis-text'>Locked</p>
        </label>
        </div>
        </>)}

      </div>
      </div>
      <div className='ml-28'>
      <h5 className='mt-6 mb-4 font-semibold'>Roles</h5>
      <div className='flex flex-col'>
      {rolesList?.map((role) => (
     

        <Form.Check type="checkbox" id={role?.value} value={role?.value} label={role?.label} defaultChecked={currentUserRole && currentUserRole.includes(role?.label)} onChange={handleCheckboxChange} />
        // <label className='mb-2'>
        // <input className='mr-4' type="checkbox" defaultChecked={currentUserRole && currentUserRole.includes(item)} />
        //  {role}
        // </label>
       
      ))}
      </div>

      </div>

      </div>

      <div className='flex flex-row'>
      <div className='fflex flex-col gap-1 mt-6 mr-8 text-xs input-field'>
      <label>Manager<span className=' text-red-500'>*</span></label>
                            {/* <Form.Select onChange={handleSelect} defaultValue={manager} required id="manager-dropdown" className='custom-input py-2 px-2 text-left mt-1'>
                          
                              <option value={manager} className='custom-input py-2 my-1'>Select Manager</option>
                              {managerList?.map((optitem, optindex) => {
                                return(
                                  <option key='' className='custom-input py-2 my-1' value={optitem.email_id}>{optitem.name}</option>
                                )
                              })}
                            
                             
                            </Form.Select> */}
{/* 
                            <Form.Select value={manager} onChange={handleSelect} required id="manager-dropdown" className='custom-input py-2 px-2 text-left mt-1'>
                              <option value="" disabled hidden>Select Manager</option>
                              {managerList?.map((optitem, optindex) => (
                                <option key={optitem.email_id} value={optitem.email_id}>{optitem.name}</option>
                              ))}
                            </Form.Select> */}

                            <Form.Select onChange={handleSelect} defaultValue={manager} required id="manager-dropdown" className='custom-input py-2 px-2 text-left mt-1'>
                              {!manager ? <option value="" className='custom-input py-2 my-1'>Select Manager</option> : <option value={manager} className='custom-input py-2 my-1'>{manager}</option>}
                              {managerList?.map((optitem, optindex) => (
                                <option key={optitem.email_id} value={optitem.email_id}>{optitem.name}</option>
                              ))}
                            </Form.Select>
      </div>

      <div className='flex flex-col gap-1 mt-6 text-xs input-field'>

      </div>
      </div>


      </div>     
     
      </div> 

      <div className='flex flex-row justify-end'>
      <button type="submit"  className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleClose}>Cancel</button>
      <button type="submit"  className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleClose}>Save Changes</button>
      </div>

      </div>
      
    </div>
  )
}

export default EditUser;