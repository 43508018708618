import React,{useState, useEffect, useContext} from 'react';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import Select from 'react-select';
import UserService from './../../services/UserService';

const serverConfig = require("./../../config/server.js");

function Filters({token, selectedOrganization, setShowFilter, showFilter, timeLog, setTimeLog, startDate, endDate, handleCloseAlertModal, handleShowAlertModal, viewMemberTimesheetPrivileged, setIsLoading, setMemberSlugArr, setFilterData}) {

  const [formData, setFormData] = useState({});
  const [memberListLookup, setMemberListLookup] = useState([]);
  const [groupListLookup, setGroupListLookup] = useState([]);

  useEffect(() => {
    console.log(selectedOrganization);
    console.log(timeLog);
    console.log('formData0', formData);
    setFilterData(formData);
  },[timeLog]);

  useEffect(() => {
    console.log('formData1', formData);
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      //group_list_get
      axios.post(serverConfig.api_base_url + "group_list_get", {token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('group_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            let arr = [];
            (response.data.items).forEach(el => {
              arr.push({
                value:el.grp,
                label:el.name
              });
            });
            setGroupListLookup(arr);
          } else {
            setGroupListLookup([]);
          }
        } else if(response.data.status === 301){
					handleLogout();
				} else {
					handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
				}
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization]);

  useEffect(() => {
    console.log('formData2', formData);
    setFormData({ ...formData, member_input: null, group_input: null, time_type: null, time_type_input: null});//clear filter
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('viewMemberTimesheetPrivileged', viewMemberTimesheetPrivileged);
      if(viewMemberTimesheetPrivileged){//if manager, get all members
        var loggedInUserSlug;
        /* loggedin_user_details_get */
        axios.post(serverConfig.api_base_url + "loggedin_user_details_get",{token:token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log("loggedin_user_details_get response:", response);
          if(response.data.status === 200){
            let items = response.data.items;
            if (items !== null || items !== undefined){
              console.log(items);
              loggedInUserSlug = items[0].slug;
              console.log(loggedInUserSlug);

              /* user_get_list */
              axios.post(serverConfig.api_base_url + "user_get_list",{token:token, org:selectedOrganization?.slug})
              .then(response=>{
                console.log("user_get_list response:", response);
                if(response.data.status === 200){
                  let items = response.data.items;
                  if (items !== null || items !== undefined){
                    console.log(items);
                    let arr = [];
                    (response.data.items).forEach(el => {
                      arr.push({
                        value:el.slug,
                        label:el.name
                      });
                    });
                    console.log(arr.filter(el => el.value === loggedInUserSlug), loggedInUserSlug);
                    setMemberListLookup(arr);
                    //setFormData({ ...formData, member_input: [arr[0]]});
                    setFormData({ ...formData, member_input: arr.filter(el => el.value === loggedInUserSlug)});
                  }
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  handleShowAlertModal(response.data.message, true);
                  setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                }
              })
              .catch(err=>console.log("error is",err));
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));
      } else {// if member then get only his name
        /* loggedin_user_details_get */
        axios.post(serverConfig.api_base_url + "loggedin_user_details_get",{token:token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log("loggedin_user_details_get response:", response);
          if(response.data.status === 200){
            let items = response.data.items;
            if (items !== null || items !== undefined){
              console.log(items);
              let arr = [];
              (response.data.items).forEach(el => {
                arr.push({
                  value:el.slug,
                  label:el.name
                });
              });
              console.log(arr[0], arr[0].value);
              setMemberListLookup(arr);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));
        /* (response.data.items).filter(el => el.email_id === UserService.getUsername()).forEach(el => {
          arr.push({
            value:el.slug,
            label:el.name
          });
        });
        console.log(arr[0], arr[0].value);
        setMemberListLookup(arr); */
      }
    }
  }, [token, selectedOrganization, viewMemberTimesheetPrivileged]);

  const handleFilter = (e) => {
    e.preventDefault();
    setIsLoading(true);

    console.log(formData);
    let groupSlugArr = formData.group_input ? formData.group_input.map(el => el.value) : [];
    console.log(groupSlugArr);
    let memberSlugArr = formData.member_input ? formData.member_input.map(el => el.value) : [];
    console.log(memberSlugArr);
    //setMemberSlugArr(memberSlugArr);
    //setFilterData(formData);

    axios.post(serverConfig.api_base_url + "time_log_get", {token:token, org:selectedOrganization?.slug, grp: groupSlugArr.length > 0 ? groupSlugArr : undefined, timeType: formData.time_type, members: memberSlugArr.length > 0 ? memberSlugArr : undefined, startDate: new Date(new Date(startDate).toUTCString()).toISOString().split('T')[0], endDate: new Date(new Date(endDate).toUTCString()).toISOString().split('T')[0]})
    .then(response=>{
      console.log('time_log_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(response.data.items);
          setTimeLog(response.data.items);
        } else {
          setTimeLog([]);
        }
        setIsLoading(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleClearFilter = () => {
    setIsLoading(true);

    setFormData({ ...formData, member_input: null, group_input: null, time_type: null, time_type_input: null});
    //setFormData({});

    axios.post(serverConfig.api_base_url + "time_log_get", {token:token, org:selectedOrganization?.slug, grp: undefined, timeType: undefined, members: undefined, startDate: new Date(new Date(startDate).toUTCString()).toISOString().split('T')[0], endDate: new Date(new Date(endDate).toUTCString()).toISOString().split('T')[0]})
    .then(response=>{
      console.log('time_log_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(response.data.items);
          setTimeLog(response.data.items);
        } else {
          setTimeLog([]);
        }
        setIsLoading(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <div className={showFilter ? "sidebar-right show" : "sidebar-right"}>
      <form onSubmit={handleFilter} className="flex flex-col h-full">
        <div className='flex justify-between items-center gap-5'>
          <div>Filters</div>
          <button type="button" className='my-2 title-text-small-color' onClick={() => {setShowFilter(false); /* setFormData({member_input: null, group_input: null, time_type_input: null}); */}}><i className="fa-solid fa-xmark "></i> </button>
        </div>
        <div className='flex-grow overflow-y-auto my-5'>
          <div className='flex flex-col gap-1 text-xs'>
            <div className='flex align-center justify-between'>
              <label>Member</label>
              {viewMemberTimesheetPrivileged && formData.member_input?.length > 0 && <button type="button" className='text-red-500' onClick={() => setFormData({ ...formData, member_input: null})}>Remove filter</button>}
            </div>
            {/* if manager show all members else if user then default select the one option available */}
            {viewMemberTimesheetPrivileged ? <Select value={formData.member_input || ""} 
              classNamePrefix="select_multi" placeholder="Select" options={memberListLookup} onChange={(option)=>  {setFormData({ ...formData, member_input: option});}} isMulti={true} /> : <Select value={memberListLookup.length > 0 ? memberListLookup[0] : ""} 
              classNamePrefix="select" placeholder="Select" options={memberListLookup} isDisabled={true} />}
          </div>
          <div className='flex flex-col gap-1 text-xs mt-4'>
            <div className='flex align-center justify-between'>
              <label>Group</label>
              {formData.group_input?.length > 0 && <button type="button" className='text-red-500' onClick={() => setFormData({ ...formData, group_input: null})}>Remove filter</button>}
            </div>
            <Select value={formData.group_input || ""} classNamePrefix="select_multi" placeholder="Select" options={groupListLookup} onChange={(option)=>  {setFormData({ ...formData, group_input: option});}} isMulti={true} />
          </div>
          <div className='flex flex-col gap-1 text-xs mt-4'>
            <div className='flex align-center justify-between'>
              <label>Time Type</label>
              {formData.time_type_input && <button type="button" className='text-red-500' onClick={() => setFormData({ ...formData, time_type: undefined, time_type_input: null})}>Remove filter</button>}
            </div>
            <Select value={formData.time_type_input} classNamePrefix="select" placeholder="Select" 
              options={[
                {value: 'Manual', label: 'Manual'},
                {value: 'Timer', label: 'Timer'}
              ]} 
              onChange={(option)=>  {setFormData({ ...formData, time_type: option.value, time_type_input: option});}} 
            />
          </div>
        </div>
        <div className='flex justify-center gap-2'>
          <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium w-2/4' onClick={handleClearFilter}>Clear All</button>
          <button type="submit" className='btn-popup px-4 py-2 rounded-lg text-sm text-medium w-2/4'>Apply Filter</button>
        </div>
      </form>
    </div>
  );
}

export default Filters;