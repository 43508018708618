import React,{useState, useEffect} from 'react';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { useContext } from 'react';
import { Context } from '../../../contextApi/context';
import { MultiSelect } from "react-multi-select-component";
import "../css/customers.css";
import Notification from "../../../common/notification/Notification.js";

const serverConfig = require("../../../config/server.js");

function CreateUser({setEditRowClicked, setModalClosedUser, modalClosedUser, customerorg, cust}) {

  const {selectedOrganization} = useContext(Context);
  const [inputs, setInputs] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tab, setTab] = useState('User Info');
  const [memberTab, setMemberTab]=useState(0)
  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [rolesList, setRolesList] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [message, setMessage] = useState('');


  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isInviteSent, setIsInviteSent] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  const submitInviteUser = (event) => {
    event.preventDefault();
   
   if(validateName(firstName) && validateEmail(email)  && validateOptions(selectedOptions)) {
    setIsLoading(true);
    setIsInviteSent(true);
    console.log(selectedOptions, 'selectedOptions');

    let rolesArray = selectedOptions.map((item)=>{
        return item?.value
    });
    let roles = JSON.stringify(rolesArray)
    console.log(roles, 'rolesArray');
  
    axios.post(serverConfig.api_base_url + "organization_user_invite", {
        token: cToken,
        url: window.location.host,
        org:selectedOrganization?.slug,
        targetorg:customerorg,
        name: `${firstName} ${lastName}`,
        email_id:email,
        roles:roles,
    })
    .then(response=>{
      // setLoading(true);
      console.log("User create response:", response);
      if (response.data.status === 200) {
        setIsInviteSent(false);
        handleShowAlertModal("Invite sent successfully");
    
        setTimeout(() => {
          handleCloseAlertModal();
          setEditRowClicked(''); 
          setIsLoading(false);
          setModalClosedUser(!modalClosedUser);
        }, 3000);
    }
    
      else if (response.data.status === 501) {
        // setLoading(false);
        setLoading(false);
        setMessage(response.data.message);
        setIsLoading(false);
      }
      else if(response.data.status === 301){
        handleLogout();
      }  
    })
    .catch(err=>console.log("error is",err));
   }
  }

  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if(!email || email.trim() === '') {
        setEmailError('Email is required');
        return false;
    }
    
    if(!emailRegex.test(email)) {
        setEmailError('Enter a valid email');
        return false;
    }
    
    setEmailError('');
    return true;
  };


  const validateOptions = (options = selectedOptions) => {
    if (options.length === 0) {
      setRoleError('Select at least one role');
      return false;
    }
    setRoleError('');
    return true;
  }  

  const validateName = (name) => {
    if(!name || name.trim() === '') {
        setNameError('Name is required');
        return false;
    }
    setNameError('');
    return true;
  };


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name, value);

    if(name === 'first_name') {
        setFirstName(value);
        validateName(value);  
    }
    else if(name === 'last_name') {
        setLastName(value);
    }
    else if(name === 'email_id') {
        setEmail(value);
        validateEmail(value);  
    }
  };

   useEffect(() => {
    
    if(cToken !== null && cToken !== undefined){


      /* role_get_list */
      axios.post(serverConfig.api_base_url + "role_list_get",{token: cToken, level:'Organization'})
      .then(response=>{
        console.log('role_get_list response', response);
        console.log('role_get_list response items',response);
        if(response.data.status === 200){
         
          console.log(selectedOrganization?.slug, 'selectedOrg')
          console.log(response.data.items, 'role_options');
          setRolesList(response.data.items);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  
}, [cToken]);

  const getToken = () => {
    //console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setCToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }  else if(response.data.status === 301){
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    //console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    }
  }, []);


  useEffect(() => {
    
      if(cToken !== null && cToken !== undefined){


        /* role_get_list */
        axios.post(serverConfig.api_base_url + "role_list_get",{token: cToken, level:'Organization'})
        .then(response=>{
          console.log('role_get_list response', response);
          console.log('role_get_list response items',response);
          if(response.data.status === 200){
           
            console.log(selectedOrganization?.slug, 'selectedOrg')
            console.log(response.data.items, 'role_options');
            setRolesList(response.data.items);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    
  }, [cToken]);


  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }
 return (
   
    <div className='edit-user  rounded-3xl py-4 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col items-center'>
      <div className='overflow-y-auto '>

      {loading === true ? 
      <>
        <div className='loading-div flex flex-col'>
             <img src={require(`./../assets/invitingUser.png`)} alt='' className='loading-img' />
             <h1 className='invite-text'>Sending Invite...</h1>
        </div>
        <div className='flex flex-row justify-end'>
        <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setEditRowClicked(''); setShowSuccessModal(false);}}>Close</button>
        </div>
      </> :
      showSuccessModal === true ? <>
 

        <div className='loading-div flex flex-col'>
             <img src={require(`./../assets/uploadSuccess.png`)} alt='' className='loading-img' />
             <h1 className='invite-text'>Invite Sent!</h1>
        </div>
        <div className='flex flex-row justify-end'>
        <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setEditRowClicked(''); setModalClosedUser(!modalClosedUser);}}>Done</button>
        </div>
     

      </> : <>
      <div className='flex items-center gap-8'>
        
    
     
    <div className='flex flex-col team-label'>

      {/* <div className="flex space-x-4">
           <button className={`px-6 py-3 ml-6 cursor-pointer ${memberTab===0 ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('User Info'); setMemberTab(0);}}>User Info</button>
      </div> */}

      <h5 className='mt-4 font-semibold ml-8'>User Info</h5>

      <div className='flex flex-row'>
      <div className='flex flex-col'>
      <div className='flex flex-col gap-1 mt-8 text-xs input-field mr-8 ml-8'>
          <label>First Name<span className=' text-red-500'>*</span></label>
          <input type='text' name='first_name' value={firstName}
              onChange={handleChange} placeholder='First Name' className='invite-field custom-input py-2 px-2 text-left mt-1' required autoComplete='off'/>
          
      </div>
      {nameError !== '' ? <p className="input-errors  ml-8">{nameError}</p> : <></>}
      </div>
      

      <div className='flex flex-col gap-1 mt-8 text-xs input-field ml-4' style={{"height" : "38px"}}>
          <label>Last Name</label>
          <input type='text' name='last_name' value={lastName}
              onChange={handleChange} placeholder='Last Name' className='invite-field custom-input py-2 px-2 text-left mt-1' required autoComplete='off'/>

      </div>
      </div>

      <div className='flex flex-row'>
      <div className='flex flex-col'>
      <div className='flex flex-col gap-1 mt-8 text-xs email-field input-field mr-8 ml-8'>
          <label>Email ID<span className=' text-red-500'>*</span></label>
          <input type='email' name='email_id' value={email}
              onChange={handleChange} placeholder='Email' className='invite-field custom-input py-2 px-2 text-left mt-1' required autoComplete='off'/>
          
      </div>
      {emailError !== '' ? <p className="input-errors mt-12 ml-8">{emailError}</p> : <></>}
      </div>
      <div className='flex flex-col gap-1 mt-8 text-xs input-field mr-2 ml-4'>
      <label className='mb-1'>Organization Role<span className=' text-red-500 '>*</span></label>
      <MultiSelect
        value={selectedOptions}
        options={rolesList}
        onChange={(selected) => {
          setSelectedOptions(selected);
          validateOptions(selected); 
        }}
        className='custom-multiselect'
        placeholder='Select Roles'
      />
      {roleError !== '' ? <p className="input-errors">{roleError}</p> : <></>}
      
        </div>     
      </div>

      <div className='flex flex-row ml-10'>

      {message !== '' ? <div className={"mt-4 text-sm font-medium text-red-500"}>{message}</div> : <></> }

      </div>

      </div>     
    
      </div> 

      <div className='flex flex-row justify-end mr-8'>
      <button type="submit"  className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setEditRowClicked('')}} disabled={isLoading}>Cancel</button>
      <button type="submit"  className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={submitInviteUser} disabled={isLoading}> {isLoading ? (isInviteSent ? <div className="signup-loader" style={{ marginLeft: '15px' }}></div> : "Invite Sent") : "Invite User"}</button>
     
    
      </div>
      </>
      }

      </div>
      {alertModalShow && (
          <>
            {showOkButton ? (
              <Notification message={alertMessage} notificationType={"Error"} />
            ) : (
              <Notification
                message={alertMessage}
                notificationType={"Success"}
              />
            )}
          </>
        )}
    </div>
  
  )
}

export default CreateUser;