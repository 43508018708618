import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { Context } from "../../../contextApi/context.js";
import axios from "axios";
import UserService from "../../../services/UserService.js";
import "../../../kz-datamanagement/css/mapmetadata.css";
import Notification from "../../../common/notification/Notification.js";
import Members from "./Members.js";
import "../css/policymanagement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeCircleCheck,
  faEnvelopeOpen,
  faClock,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";

const serverConfig = require("../../../config/server.js");

const MyActionsTable = () => {
  const { selectedOrganization } = useContext(Context);
  const { isDarkTheme } = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [dbTypeLookup, setDBTypeLookup] = useState([]);
  const [formData, setFormData] = useState({});
  const [dbType, setDbType] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshDBServiceList, setRefreshDBServiceList] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [editRow, setEditRow] = useState("");
  const [viewAndSignPolicyModal, setViewAndSignPolicyModal] = useState(false);
  const [pcy, setPcy] = useState("");
  const [file, setFile] = useState(null);
  const [policyFileDetails, setPolicyFileDetails] = useState({});

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", { jwt: jwt })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  useEffect(() => {
    console.log("token", token);
    console.log(sessionStorage.getItem("token"));

    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);
      console.log("token", token);
      /* policy_self_unsigned_list_get */
      axios
        .post(serverConfig.api_base_url + "policy_self_unsigned_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("policy_self_unsigned_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            if (items !== null || items !== undefined) {
              console.log(response.data.items);
              let appDataArray = response.data.items;
              let appData = [];

              console.log(appDataArray);
              appDataArray &&
                appDataArray.reverse().map((item, index) => {
                  let noOfActions =
                    Number(item.is_editable) +
                    Number(item.is_deletable) +
                    Number(item.is_validated === 0 ? 1 : 0);
                  const Actions = () => (
                    <>
                      {item.is_editable === 1 && (
                        <Button
                          variant="text"
                          onClick={() => handleShowEditDBServiceModal(item)}
                          title={noOfActions > 2 ? "" : "Edit"}
                        >
                          <i
                            className="fa-solid fa-pencil not-0"
                            aria-hidden="true"
                          ></i>{" "}
                          {noOfActions > 2 && <span>Edit</span>}
                        </Button>
                      )}
                      {item.is_deletable === 1 && (
                        <Button
                          variant="text"
                          onClick={() =>
                            handleShowDeleteDBServiceModal(
                              item?.dbs,
                              item?.name
                            )
                          }
                          title={noOfActions > 2 ? "" : "Delete"}
                        >
                          <i
                            className="fa-solid fa-trash-can text-red-500"
                            aria-hidden="true"
                          ></i>{" "}
                          {noOfActions > 2 && <span>Delete</span>}
                        </Button>
                      )}
                      {item.is_validated === 0 && (
                        <Button
                          variant="text"
                          onClick={(e) => handleValidateDBService(item?.dbs, e)}
                          title={noOfActions > 2 ? "" : "Validate"}
                        >
                          <i className="fa-solid fa-file-circle-check not-0"></i>{" "}
                          {noOfActions > 2 && <span>Validate</span>}
                        </Button>
                      )}
                    </>
                  );
                  {
                    item.actions =
                      noOfActions > 2 ? (
                        <div className="text-right more-options-wrapper">
                          {(item.is_editable === 1 ||
                            item.is_deletable === 1 ||
                            item.is_validated === 0) && (
                            <button
                              type="button"
                              onClick={handleShowMoreOptions}
                              title="More"
                              className="btn-more-options"
                            >
                              <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                            </button>
                          )}
                          <div
                            className="more-options"
                            style={{ display: "none" }}
                          >
                            <Actions />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-end gap-3">
                          <Actions />
                        </div>
                      );
                  }
                  appData.push(item);
                });
              console.log(appData);
              setTableData(appData);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [
    token,
    selectedOrganization,
    refreshDBServiceList,
    viewAndSignPolicyModal,
  ]);

  // Get File
  const handleGetFile = () => {
    axios
      .post(serverConfig.japi_base_url + "policy_file_get", {
        token: token,
        org: selectedOrganization?.slug,
        pcy: pcy,
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== null) {
            console.log("policy_file_get data:", response.data);
            setFile(response.data.data);
            // setPcy("");

            axios
              .post(serverConfig.api_base_url + "policy_file_details_get", {
                token: token,
                org: selectedOrganization?.slug,
                pcy: pcy,
                opened_at:
                  new Date()
                    .toLocaleDateString("en-IN", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-") +
                  " " +
                  new Date().toLocaleTimeString("en-IN", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  }),
              })
              .then((secondResponse) => {
                console.log("policy_file_details_get:", secondResponse);
                if (secondResponse.data.status === 200) {
                  console.log(
                    "policy_file_details_get data:",
                    secondResponse.data.items
                  );
                  // Set policyFileDetails state with the items array
                  setPolicyFileDetails(secondResponse.data.items);
                } else {
                  console.log(
                    "Second API call failed:",
                    secondResponse.data.message
                  );
                }
              })
              .catch((err) => console.log("Second API call error:", err));
          }
        } else if (response.status === 301) {
          handleLogout();
        } else {
          setTimeout(() => {
            // Handle other cases here
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ["Updated", "Option 2", "Option 3"];
  const sortingIcons = [sortDown, sortUp];

  //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

  const columns = [
    {
      name: <div className="font-bold text-sm">Policy Name</div>,
      selector: (row) => (
        <div className="text-sm ml-2">
          <b>{row.name}</b>
          <div>{row.description}</div>
        </div>
      ),
      sortable: true,
      wrap: true,
      width: "20%",
    },
    {
      name: <div className="font-bold text-sm">File</div>,
      selector: (row) => <div className="ml-2">{row.file_name + ".pdf"}</div>,
      sortable: true,
      wrap: true,
      width: "16%",
    },
    {
      name: <div className="font-bold text-sm">Due Date</div>,
      selector: (row) => <div className="ml-3">{row.due_date}</div>,
      //width: 'calc(15% - 48px)'
    },
    {
      name: <div className="font-bold text-sm">Controls</div>,
      selector: (row) => (
        <div
          className={
            row.control && row.control === "Confidential"
              ? "type-control-confidential ml-3"
              : "type-control-private ml-3"
          }
        >
          {row.control}
        </div>
      ),
      //width: 'calc(15% - 48px)'
    },
    {
      name: <div className="font-bold text-sm">Labels</div>,
      selector: (row) => (
        <div className="text-sm ml-4">
          {row.framework && row.framework.join(", ")}
        </div>
      ),
      //width: 'calc(15% - 48px)'
    },
    {
      name: <div className="font-bold text-sm">Actions</div>,
      selector: (row) => row.actions,
      cell: (row) => (
        <div className="flex-container pr-2">
          <Button
            variant="text"
            className="button-margin"
            onClick={() => {
              setEditRow(row);
              setPcy(row.pcy);
              setViewAndSignPolicyModal(true);
              console.log(row.pcy);
            }}
          >
            <i className="fa-solid fa-file-signature"></i>
          </Button>
        </div>
      ),
      //width: 'calc(15% - 48px)'
    },
  ];

  useEffect(() => {
    if (pcy) {
      handleGetFile();
    }
  }, [pcy]);

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  //Create
  const [createDBServiceModalShow, setCreateDBServiceModalShow] =
    useState(false);

  const handleValidateDBService = (dbServiceSlug, event) => {
    event.preventDefault();

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }

    axios
      .post(serverConfig.api_base_url + "dwh_dbs_validate", {
        token: token,
        dbs: dbServiceSlug,
      })
      .then((response) => {
        console.log("dwh_dbs_validate:", response);
        if (response.data.status === 200) {
          setFormMessage({
            message: "Database Service validated successfully",
            type: "success",
          });
          setRefreshDBServiceList(!refreshDBServiceList);

          setDBServiceSlug("");
          setDBServiceName("");
          setFormMessage({});
          setFormData({});

          //handleCloseValidateDBServiceModal();
          handleShowAlertModal("Database Service validated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleCloseViewAndSignPolicyModal = () => {
    setViewAndSignPolicyModal(false);
    setFile(null);
    setPcy("");
  };

  const convertToBlob = (pdfData) => {
    const byteCharacters = atob(pdfData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    return blob;
  };

  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);

  const handleCheckboxChange = (checkboxNum) => {
    if (checkboxNum === 1) {
      setCheckbox1Checked(!checkbox1Checked);
    } else {
      setCheckbox2Checked(!checkbox2Checked);
    }
  };

  const handlePolicySigned = () => {
    if (!pcy) {
      // Handle the case where pcy is empty
      console.log("Error: pcy is empty");
      return;
    }

    axios
      .post(serverConfig.api_base_url + "policy_is_signed_check", {
        token: token,
        org: selectedOrganization?.slug,
        pcy: pcy,
        signed_at:
          new Date()
            .toLocaleDateString("en-IN", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-") +
          " " +
          new Date().toLocaleTimeString("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          }),
      })
      .then((response) => {
        console.log("policy_file_details_get:", response);
        setViewAndSignPolicyModal(false);
        if (response.data.status === 200) {
          // setPcy("");
          console.log("policy_file_details_get data:", response.data.items);
          // Set policyFileDetails state with the items array
          setPolicyFileDetails(response.data.items);
        } else {
          console.log("Second API call failed:", response.data.message);
        }
      })
      .catch((err) => console.log("Second API call error:", err));
  };

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-dbs table-mapmetadata mt-4 ">
        <div className="flex justify-between items-center space-x-4">
          <h1 className="font-semibold text-2xl mt-4 mb-4">Pending</h1>
          {tableData.length > 0 && (
            <div className="flex items-center space-x-4 text-black"></div>
          )}
        </div>
        {tableData.length > 0 ? (
          <DataTable
            columns={columns}
            data={tableData}
            responsive={true}
            pagination={tableData.length > 10 ? true : false}
            highlightOnHover
            customStyles={{
              rows: {
                style: {
                  minHeight: "48px",
                  border: "2px solid var(--neutral-400)",
                  borderRadius: "10px",
                  marginTop: "10px",
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? "#ffffff" : "#000000",
                  backgroundColor: isDarkTheme ? "#222222" : "#EFF3F9",
                  outline: "none",
                },
              },
              expanderButton: {
                style: {
                  borderRadius: "8px",
                },
              },
            }}
          />
        ) : (
          <div className="flex flex-col h-3/4 items-center justify-center">
            <div className="mb-8"></div>
            <p>
              <b>You do not have any pending policies</b>
            </p>
          </div>
        )}

        {viewAndSignPolicyModal && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col w-[75%]">
              <div className="flex justify-between items-center mb-4">
                <div className="font-semibold text-lg">
                  View and Sign Policy
                </div>
                <button
                  className="text-gray-400 hover:text-gray-600"
                  onClick={handleCloseViewAndSignPolicyModal}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="flex flex-col lg:flex-row p-4 space-y-4 lg:space-y-0 lg:space-x-8">
                <div className="flex-1">
                  <div
                    className="flex bg-[#FDF0D7] p-4 rounded-md mb-4 border-2"
                    style={{ borderColor: "#FAD387" }}
                  >
                    <div
                      className="m-1 text-lg p-2 border-2 border-gray-300 rounded-full flex items-center justify-center"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderColor: "#87641E",
                        backgroundColor: "#FDF0D7",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClock}
                        color="#87641E"
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div className="ml-2">
                      <div>
                        <p className="font-semibold">Action pending</p>
                      </div>
                      <div>
                        <p>Please review Policy and sign it.</p>
                      </div>
                    </div>
                  </div>
                  <h1 className="text-2xl font-bold mb-4">{editRow.name}</h1>
                  <div className="space-y-4">
                    {file && <embed src={file} width="850" height="450" />}
                  </div>
                </div>
                <div className="w-full lg:w-1/3">
                  <div className="p-4 rounded-md">
                    <h2 className="text-xl font-semibold mb-4">Progress</h2>
                    <ul className="space-y-4">
                      <li className="flex items-start">
                        <div
                          className="mr-2 text-lg p-2 border-2 border-gray-300 rounded-full flex items-center justify-center"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderColor: "#A6E966",
                            backgroundColor: "#E1F8CC",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEnvelopeCircleCheck}
                            color="#3A7800"
                          />
                        </div>
                        <div>
                          <p className="font-semibold">Policy Sent</p>
                          <p className="text-sm text-zinc-600">
                            Sent by {policyFileDetails?.[0]?.uploaded_by_name}{" "}
                            on {policyFileDetails?.[0]?.effective_start_date}
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div
                          className="mr-2 text-lg p-2 border-2 border-gray-300 rounded-full flex items-center justify-center"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderColor: "#A6E966",
                            backgroundColor: "#E1F8CC",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            color="#3A7800"
                          />
                        </div>
                        <div>
                          <p className="font-semibold">Policy Opened</p>
                          <p className="text-sm text-zinc-600">
                            {policyFileDetails?.[0]?.is_opened_date_time
                              ? policyFileDetails[0].is_opened_date_time.slice(
                                  0,
                                  19
                                )
                              : ""}
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div
                          className="mr-2 text-lg p-2 border-2 border-gray-300 rounded-full flex items-center justify-center"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderColor: "#FAD387",
                            backgroundColor: "#FDF0D7",
                          }}
                        >
                          <FontAwesomeIcon icon={faClock} color="#87641E" />
                        </div>
                        <div>
                          <p className="font-semibold">Due Date:</p>
                          <p className="text-sm text-zinc-600">
                            {policyFileDetails?.[0]?.effective_end_date}
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div
                          className="mr-2 text-lg p-2 border-2 border-gray-300 rounded-full flex items-center justify-center"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderColor: "#FAD387",
                            backgroundColor: "#FDF0D7",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFileSignature}
                            color="#87641E"
                          />
                        </div>
                        <div>
                          <p className="font-semibold">Pending to Sign</p>
                          <p className="text-sm text-zinc-600">
                            Policy is pending for signature.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="bg-[var(--secondary-200)] p-4 rounded-md mt-44">
                    <div className="space-y-2">
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-green-500 custom-checkbox"
                          checked={checkbox1Checked}
                          onChange={() => handleCheckboxChange(1)}
                        />
                        <span>I have read and reviewed this policy.</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-green-500 custom-checkbox"
                          checked={checkbox2Checked}
                          onChange={() => handleCheckboxChange(2)}
                        />
                        <span>
                          I accept and hereby agree to the terms of this policy.
                        </span>
                      </label>
                    </div>
                    <button
                      className="bg-[var(--primary)] text-white py-2 px-4 rounded-md mt-4 w-full"
                      disabled={!checkbox1Checked || !checkbox2Checked}
                      onClick={handlePolicySigned}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyActionsTable;
