import React, { useContext, useEffect, useState } from 'react'
import DropdownTwo from '../components/DropdownTwo';
import '../css/access.css';
import axios from 'axios';
import UserService from "../../services/UserService";
import { Context } from '../../contextApi/context';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const serverConfig = require("../../config/server.js");

function TeamAccessPopup({setTeamPopup, accessNotPresent, handleShowAlertModal, handleCloseAlertModal}) {
   
    const navigate = useNavigate();
    const{selectedOrganization, activeSubmenuOption, setActiveSubmenuOption} = useContext(Context);
    const option=[{label:'Git', value:'Git'},{label:'Git Access', value:'Git Access'}, {label:'Kezel', value:'Kezel'},]
    const optionContext=[{label:'kezel', value:'kezel'},{label:'project abx', value:'project abx'}, {label:'chandrayan', value:'Chandrayan'},]
    const[access , setAccess]= useState();
    const[context, setContext]= useState();
    const[accessType, setAccessType] = useState()
    const[accessOptions, setAccessOptions] = useState([]);
    const[contextOptions, setContextOptions] = useState([]);
    const[accessTypeOptions, setAccessTypeOptions] = useState([]);
    const[token, setToken] = useState(null);
    const[checkboxValue, setCheckboxValue] = useState('self');
    const[orgMember, setOrgMember] = useState({value : '', label : ''});
    const[memberLookup, setMemberLookup] = useState([]);
    const[success, setSuccess] = useState(false);
    const[justification, setJustification] = useState('');
    const [error, setError] = useState('');

    const handleOptionChange = (event) => {
      setCheckboxValue(event.target.value);
    };

    const handleLogout = () => {
      sessionStorage.clear();
      UserService.doLogout();
    }

    const validateJustification = (e) => {
        if(justification === '') {
          setError("Please enter justification");
          return false;
        }
          setError("");
          return true;
    }
    
    const getToken = () => {
      console.log(sessionStorage.getItem('token'));
      let jwt = UserService.getToken();
      axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
      .then(response=>{
        console.log('user_get_token response', response);
        if(response.data.status === 200){
          sessionStorage.setItem('token', response.data.token);
          setToken(response.data.token);
        } else if(response.data.status >= 400 && response.data.status <= 499){
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if(response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  
    useEffect(() => {
      console.log(sessionStorage.getItem('token'));
      if(sessionStorage.getItem('token') === null){
        getToken();
      } else {
        setToken(sessionStorage.getItem('token'));
      }
    }, []);


    const handleRequestAccess = (e) => {
      e.preventDefault();
      if(validateJustification(e)) {
      axios.post(serverConfig.api_base_url + "access_request",{
          token: token,
          acc: access?.accessSlug,
          con: context?.contextSlug,
          accType: accessType?.accessTypeSlug,
          orgmem: orgMember?.value,
          justification: justification
      }).then(response => {
        console.log(response.data, 'request_access_response');
        if(response.data.status === 200) {
          
          setTeamPopup(false);
          handleShowAlertModal('Request Created successfully');
          setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }

      }).catch(err => {
        console.log(err, 'error');
      })
    }
    } 

    const handleJustification = (e) => {
        e.preventDefault();
        setJustification(e.target.value);
    }


    useEffect(() => {

      if(token !== null && token !== undefined) {

        axios.post(serverConfig.api_base_url + "access_reportee_get_list", {token:token, org: selectedOrganization?.slug})
        .then(response=>{
          console.log("access_reportee_get_list:", response);
          if(response.data.items !== null && response.data.status === 200) {
            const memberList = response.data.items.map((item)=>{
              return {
                value : item?.email_id,
                label : item?.name
              }
            })
            setMemberLookup(memberList);
          }
          else if(response.data.status === 301){
            handleLogout();
          } else if(response.data.status !== 200) {
            // handleShowAlertModal(response.data.message, true);
            // setTimeout(() => {
            //   handleCloseAlertModal();
            // }, 6000);
          }
        })
        .catch(err=> {
          alert(err);
        })

      axios.post(serverConfig.api_base_url + "access_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("access_list_get response:", response);
        if(response.data.status === 200){

          const dropdownOptions = response.data.items.map((item)=>{
            return {
              label : item?.access_name,
              value : item?.access_name,
              accessSlug : item?.slug
            }
          });

          setAccessOptions(dropdownOptions);

          if(access?.accessSlug !== null && access?.accessSlug !== undefined) {

            axios.post(serverConfig.api_base_url + "access_context_list_get",{token:token, acc: access?.accessSlug})
            .then(responseContext=>{
                console.log("access_context_list_get : ", responseContext);
                const dropdownOptions = responseContext.data.items.map((item)=>{
                  return {
                    label : item?.context,
                    value : item?.context,
                    contextSlug : item?.slug
                  }
                });
                setContextOptions(dropdownOptions); 
            }).catch(err=>{
              console.log(err);
            })

            axios.post(serverConfig.api_base_url + "access_type_list_get",{token:token, acc: access?.accessSlug})
            .then(responseContext=>{
                console.log("access_type_list_get : ", responseContext);
                const dropdownOptions = responseContext.data.items.map((item)=>{
                  return {
                    label : item?.access_type,
                    value : item?.access_type,
                    accessTypeSlug : item?.slug
                  }
                });
                setAccessTypeOptions(dropdownOptions); 
            }).catch(err=>{
              console.log(err);
            })

          }
          
        } else if(response.data.status === 301){
          handleLogout();
        }  else if(response.data.status !== 200) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    }, [token, access]);

  return (
    <div className='access-req bg-custom rounded-xl p-5 relative w-full mx-28  my-5' >

        <div className='grid grid-cols-2 p-4'>
            <div className='col-span-1 '>
            <div className='text-custom font-medium text-xl'>Request Access</div> 
            <div className='flex flex-col justify-start align-start mt-5 gap-3 text-custom'>
              <div className='flex flex-row'>
              <div className="radio">
                <label className='flex flex-row mr-4'>
                  <input type="radio" value="self" name="userType" checked={checkboxValue === "self"} onChange={handleOptionChange} />
                  <span className="custom-radio"></span>
                  <div className='label-checkbox'>
                  Self
                  </div>
                </label>
              </div>
              <div className="radio">
                <label className='flex flex-row'>
                  <input type="radio" value="other" name="userType" checked={checkboxValue === "other"} onChange={handleOptionChange}  />
                  <span className="custom-radio"></span>
                  <div className='label-checkbox'>
                  Other
                  </div>
                </label>
              </div>
              </div>
              {checkboxValue === "other" &&
              
                  <div>
                  <div className='text-semibold mt-3 font-semibold text-sm mb-3'>Select Member</div>
                
                      <DropdownTwo options={memberLookup} id={1} selectedOption={orgMember} setSelectedOption={setOrgMember} />
                
                  </div>
              
              }
                <div>
                <div className='text-semibold mt-3 font-semibold text-sm mb-3'>Access</div>
               {accessNotPresent===true ? <span className="text-red-500">Currently there are no access created. Please create one <span className='underline cursor-pointer' onClick={() =>{setActiveSubmenuOption('Access Metadata'); navigate("/accessmanage");}}>here</span></span> :
                    <DropdownTwo options={accessOptions} id={1} selectedOption={access} setSelectedOption={setAccess} /> }
              
                </div>
                {accessNotPresent===true ? <></> :
                <div>
                    <div className='text-semibold font-semibold mt-3 text-sm mb-3'>Context</div>

                    {accessNotPresent===true ? <></> :
                    <DropdownTwo options={contextOptions} id={1} selectedOption={context} setSelectedOption={setContext}/>}

                  </div> }
                  {accessNotPresent===true ? <></> :
                  <div>
                <div className='text-semibold font-semibold mt-3 text-sm mb-3'>Access Type</div>          
                
                    <DropdownTwo options={accessTypeOptions} id={1} selectedOption={accessType}  setSelectedOption={setAccessType} /> 
              
                </div>}

                {accessNotPresent===true ? <></> :
                  <div>
                <Form.Group controlId="UserComments" className="txt-gray">
                          <Form.Label className='text-semibold font-semibold mt-3 text-sm mb-3'>Justification<span className='text-red-600'>*</span></Form.Label>
                          <textarea type="text" value={justification} onChange={handleJustification} placeholder='Type Justification' className="justification mb-6 mt-1 w-full" required/>
                        </Form.Group>
                {error !== '' ? <div className='text-red-600 text-sm'>{error}</div> : <></>}
              
                </div>}

                 {/* {success === true &&
                    <>
                      <div className={success === true ? "mt-4 text-sm font-semibold text-green-500" : "mt-4 text-sm font-semibold text-red-500"}>Request Created Successfully</div>
                      {checkboxValue === "other" && <span className="text-xs font-medium text-red-500">Please click  <span className='underline cursor-pointer' onClick={() => navigate("/reporteeAccess")}>here</span> to go to the Team Page to view the status of this request.</span>}
                    </>
                 } */}
            </div>
            </div>
            <div className='col-span-1 flex flex-col justify-between align-baseline'>
                <img src={'https://img.freepik.com/free-vector/data-privacy-abstract-concept-illustration_335657-3828.jpg?w=1480&t=st=1701885720~exp=1701886320~hmac=af7478e4549361743ea413a6fa85aea71594823ab16e437aa3e47b251f940a4a'}/>
                <div>

               
                <div className='flex gap-2 justify-end'>
          <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setTeamPopup(false)}}>Cancel</button>
          <button type="submit" className='btn-popup newOrg px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleRequestAccess}>Request</button>

</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TeamAccessPopup 