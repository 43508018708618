import React, {useState, useContext} from "react";
import { Context } from "../contextApi/context";
import logo from './../Asset/Images/logoTM.png';
import UploadDoc from "./UploadDoc";
import "./css/aidocs.css";

const AIDocs = () => {


  const {collapse, tab,taskPopup} = useContext(Context)
  const[newGroupPopup , setNewGroupPopup]= useState(false)
  const[close, setClose] = useState(false);


    return (
        <>
         <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
      <div class="container-fluid">
      {close===false ?
        <>
        <div className={collapse?'ai-header-collapse p-4 pl-4 rounded-xl relative':'ai-header-collapse p-4 pl-4 rounded-xl relative'}>
        <div className='flex px-4 gap-10 py-2' style={{alignItems:'center'}}>

        <img src={logo} alt='logo' style={{"width" : "90px", "height" : "40px"}}/>
            <div className='md:mr-10 '>
            <div className='txt-title'>AI DOCS</div>
            </div>
            <div className='mx-10'>
            {/* <img src={desk} alt='Banner'/> */}
            </div>

        </div>
        {/* <button className='absolute right-4 top-2' onClick={()=>{setClose(true)}}>
        <i class="fa-solid fa-xmark"></i>
        </button> */}
            </div>
            </> : <></> }
      <div className="">
    <div className="p-1 chat-background-ai mt-4">
        <UploadDoc />
    </div>
    </div>
    </div>
     
      </div>
    </div>
        </>
    )
}

export default AIDocs;