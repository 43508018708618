import React, { useState, useEffect } from "react";
import axios from 'axios';
import './../css/slider.css';

const serverConfig = require("./../../config/server.js");

const ProgressBarUpdate = ({ progress_value, token, data, selectedOrganization, handleLogout, refreshTable, handleShowAlertModal, handleCloseAlertModal }) => {

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    console.log(progress_value, 'progress');
    setProgress(progress_value);
  }, [progress_value]);

  useEffect(() => {

    document.getElementById("myinput").oninput = function () {

      var value = (progress - 0) / (100 - 0) * 100;
      console.log(value, 'dragging value')
      this.style.background = 'linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) ' + value + '%, #fff ' + value + '%, white 100%)'
    };

  }, []);

  const handleChange = (e) => {
    console.log(data);
    document.getElementById("myinput").oninput = function () {
      var value = (this.value - this.min) / (this.max - this.min) * 100;
      console.log(value, 'dragging value')
      this.style.background = 'linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) ' + value + '%, #fff ' + value + '%, white 100%)'
    };
    console.log(e.target.value);
    setProgress(e.target.value);

    setTimeout(() => {
      let formItems = { ...data, progress_percentage: e.target.value, status: data.status.name, grp: data.group.grp };
      console.log(formItems);
      axios.post(serverConfig.api_base_url + "task_update", {token:token, org:selectedOrganization?.slug, tsk: data.tsk, items:formItems})
      .then(response=>{
        console.log('task_update response', response);
        if(response.data.status === 200){
          refreshTable();
          handleShowAlertModal('Task updated successfully');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }, 300);
    
  }

  return (
    <>
      <div class="slidecontainer">
        <div className="progress-value">{progress}%/100%</div>
        <input type="range" id="myinput" className="custom-input-range" min="0" max="100" value={progress} onChange={handleChange} style={{background: `linear-gradient(to right, var(--secondary-200) 0%, var(--secondary-200) ${progress}%, rgb(255, 255, 255) ${progress}%, white 100%)`}} />
      </div>
    </>
  )
};

export default ProgressBarUpdate;

