import React, { useState} from 'react'
import '../css/Assignees.css'
import {userData} from '../../Asset/Data/UserData'

function AssigneesTwo({name,handleNameInputBlur,handleNameInputChange,addingNameIndex,lastIndex}) {
    const[isFocus, setIsFocus]= useState('')
    let newIndex= addingNameIndex
let x;
if(addingNameIndex<=lastIndex/3){newIndex=addingNameIndex+1} else if(addingNameIndex>lastIndex/3){newIndex=addingNameIndex+2}

    const topValue = `${newIndex * 60}px`;
  return (
    <div className='Assignees-two' style={{top:topValue}}>
    <div className='py-4 px-4 search'>
        <div className='relative'>
        <input className='search-input p-2 w-full' placeholder='Search' onFocus={()=>{setIsFocus(true)}} onBlur={()=>{setIsFocus(false)}}/>

       {!isFocus && <div className='absolute left-4 top-2'><i class="fa-solid fa-magnifying-glass text-xs text-gray-600"></i></div>} 
        </div>

    </div>
    <select
          multiple={true}
          value={name}
          //onChange={handleSelectChange}
          className='w-full'
        >
            {userData.map((info, index)=>{
                return(
               
<option value={info.id} onClick={handleNameInputBlur} className='hover-names'>
    <div className='flex justify-between align-middle p-2 w-full hover-names'>
    <div className='flex justify-start align-middle w-full gap-4' style={{alignItems:'center'}}>
        <img className='h-8 w-8 rounded-full object-cover' src={info.user} alt={info.name} />
        <div className=' text-xs'>{info.name}</div>
    </div>
    <div>
   {info.select && <i class="fa-solid fa-check text-black text-xs"></i>  }
             
        
    </div>
    </div>
</option>
                )
            })}
          
         
        </select>
      <div></div>
    </div>
  )
}

export default AssigneesTwo