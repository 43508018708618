import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const NumberCard = ({
  item,
  data,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const containerRef = useRef();
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Helper function to format numbers based on type
  const formatNumber = (number, type) => {
    switch (type) {
      case "currency":
        return `$${number.toLocaleString()}`;
      case "percentage":
        return `${number}%`;
      default:
        return number.toLocaleString();
    }
  };

  useEffect(() => {
    setIsLoading(false);
    console.log(item);
    
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const container = d3.select(containerRef.current);
      
      // Clear any existing content
      container.selectAll("*").remove();
      
      // Render a card for each data item
      data?.forEach((dataItem, index) => {
        const card = container
          .append("svg")
          .attr("viewBox", "0 0 350 200")
          .attr("preserveAspectRatio", "xMidYMid meet")
          .style("width", "100%")
          .attr("height", 180) 
          .attr("class", "number-card");
        
        card
          .append("rect")
          .attr("width", "100%")
          .attr("height", 180) 
          .attr("rx", 10)
          .attr("ry", 10)
          .style("fill", "#fff")
          .style("stroke", "#fff")
          .style("stroke-width", 1);
        
        const numberText = card
          .append("text")
          .attr("x", 170)
          .attr("y", 70)
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .style("font-size", "24px")
          .style("fill", "#333");
        
        const labelText = card
          .append("text")
          .attr("x", 170)
          .attr("y", 100)
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .style("font-size", "16px")
          .style("fill", "#777")
          .text(item?.title);
        
        // Initialize the numberText with 0
        numberText.text(formatNumber(0, dataItem.type));
        
        // Animate the number change
        numberText.transition()
          .duration(2000)
          .tween("text", function () {
            const i = d3.interpolate(0, dataItem[item?.label]);
            return function (t) {
              numberText.text(formatNumber(Math.round(i(t)), dataItem.type));
            };
          });
      });
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [data, isLoading]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    var x = event.target.closest(".more-options-wrapper").querySelector(".more-options");
    
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-heading">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          ref={containerRef}
          className="card-container"
          style={{ marginTop: "30px" }}
        ></div>
      )}
    </>
  );
};

export default NumberCard;
