import React from 'react'
import { useEffect,useState, useContext, useRef } from 'react';
import { Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import UserService from '../../services/UserService';
import "../css/mapmetadata.css";
import Notification from '../../common/notification/Notification.js';

const serverConfig = require("../../config/server.js");

const DwhTable = ({dbs, schema, refreshSchemaList}) => {
  const {selectedOrganization} = useContext(Context);
  const {isDarkTheme} = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  
  const [mapData, setMapData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableTypeLookupList, setTableTypeLookupList] = useState([]);
  const [columnTypeLookupList, setColumnTypeLookupList] = useState([]);
  const [dimTableList, setDimTableList] = useState([]);
  const [factTableList, setFactTableList] = useState([]);
  const [dataTypeLookupList, setDataTypeLookupList] = useState([]);
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshTableList, setRefreshTableList] = useState(false);
  const [mapColumnItems, setMapColumnItems] = useState([]);
  const [showDeleteTarget, setShowDeleteTarget] = useState(false);
  const [deleteFromTarget, setDeleteFromTarget] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    //set lookup dropdown
    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"table_type"})
    .then(response=>{
      console.log("table_type lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("table_type data:", response.data.items);
          response.data.items.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          setTableTypeLookupList(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"column_type"})
    .then(response=>{
      console.log("column_type lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("column_type data:", response.data.items);
          response.data.items.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          setColumnTypeLookupList(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"data_type"})
    .then(response=>{
      console.log("data_type lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("data_type data:", response.data.items);
          response.data.items.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          setDataTypeLookupList(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }, [token]);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);
      /* dwh_table_list_get */
      axios.post(serverConfig.api_base_url + "dwh_table_list_get",{token:token, dbs:dbs, schema:schema})
      .then(response=>{
        console.log("dwh_table_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(response.data.items);
            let appDataArray = response.data.items;

            setDimTableList(appDataArray.filter(el => el.table_type === 'dimension'));
            setFactTableList(appDataArray.filter(el => el.table_type === 'fact'));
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              let typeClass;
              let icon;
              if(item.table_type === "stage"){
                typeClass = 'bg-bdr bg-bdr-peach justify-center';
                icon = <i className="fa-solid fa-bars-staggered"></i>;
              } else if(item.table_type === "fact"){
                typeClass = 'bg-bdr bg-bdr-green justify-center';
                icon = <i className="fa-solid fa-industry"></i>;
              } else if(item.table_type === "dimension"){
                typeClass = 'bg-bdr bg-bdr-yellow justify-center';
                icon = <i className="fa-solid fa-ruler-combined"></i>;
              }
              {item.tableType= <div className={typeClass}>{icon} {item.table_type}</div>}

              let noOfActions = Number(item.table_type === 'stage' ? 1 : 0) + Number(item.is_provisioned === 0 ? 1 : 0) + 1 + Number(item.is_provisioned === 0 ? 1 : 0);
              const Actions = () => <>
                {item.table_type === 'stage' && <Button title={noOfActions > 2 ? '' : 'Map'} variant='text' onClick={() => handleShowMapModal(item?.name, item?.tbl, item?.columnItems)}><i className="fa-solid fa-sitemap" aria-hidden="true"></i> {noOfActions > 2 && <span>Map</span>}</Button>}
                {item.is_provisioned === 0 && <Button title={noOfActions > 2 ? '' : 'Edit'} variant='text' onClick={() => handleShowEditTableModal(item)}><i className="fa-solid fa-pencil not-0" aria-hidden="true"></i> {noOfActions > 2 && <span>Edit</span>}</Button>}
                <Button title={noOfActions > 2 ? '' : 'Delete'} variant='text' onClick={() => handleShowDeleteTableModal(item?.name, item?.tbl, item?.is_provisioned)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> {noOfActions > 2 && <span>Delete</span>}</Button>
                {item.is_provisioned === 0 && <Button title={noOfActions > 2 ? '' : 'Provision'} variant='text' onClick={() => handleProvisionTable(item?.tbl)}><i className="fa-solid fa-hand-holding-medical not-0"></i> {noOfActions > 2 && <span>Provision</span>}</Button>}
              </>;
              {item.actions = (noOfActions > 2 ? <div className="text-right more-options-wrapper">
                <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
                <div className="more-options" style={{display: "none"}}>
                  <Actions />
                </div>
              </div> : <div className="flex items-center justify-end gap-3">
                <Actions />
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setTableData(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
    
  }, [token, selectedOrganization, refreshTableList, refreshSchemaList]);

  const sortDown=<i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp=<i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ['Updated', 'Option 2', 'Option 3'];
  const sortingIcons= [sortDown,sortUp];

  const columns = [
    {
      name: <div className='row-label'>Name</div>,
      selector: row => row.name,
      sortable: true,
      wrap: true,
      //width: '22%'
    },
    {
      name: <div className='row-label'>Type</div>,
      selector: row => row.tableType,
      sortable: true,
      wrap: true,
      //width: '19%'
    },
    {
      name: <div className='row-label'>Description</div>,
      selector: row => row.description,
      sortable: true,
      wrap: true,
      //width: '44%'
    },
    {
      name: <div className='row-label'>Actions</div>,
      selector: row => row.actions,
      //width: '15%'
    },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setTableSlug("");
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  //Create
  const [createTableModalShow, setCreateTableModalShow] = useState(false);
  const handleCloseCreateTableModal = () => {
    setCreateTableModalShow(false);
    setFormData({});
    setFormMessage({});
    setColumnData([]);
  }
  const handleShowCreateTableModal = () => {
    setCreateTableModalShow(true);
  }

  const handleCreateTableSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(columnData);
    console.log({
      token:token,
      dbs:dbs,
      schema:schema,
      name: formData.name,
      tableType: formData.table_type,
      description: formData.description ? formData.description : "-",
      columnDetails: columnData
    });
    axios.post(serverConfig.api_base_url + "dwh_table_create",{
      token:token,
      dbs:dbs,
      schema:schema,
      name: formData.name,
      tableType: formData.table_type,
      description: formData.description ? formData.description : "-",
      columnDetails: columnData
    })
    .then(response=>{
      console.log("dwh_table_create:", response);
      if (response.data.status === 200) {
        setFormData({});
        setFormMessage({message:"Table created successfully", type:"success"});
        setRefreshTableList(!refreshTableList);
        handleCloseCreateTableModal();
        setTableSlug(response.data.tbl);
        handleShowAlertModal('Table created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Provision
  const handleProvisionTable = (table_slug) => {
    console.log(table_slug);
    console.log(tableSlug);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
    
    axios.post(serverConfig.api_base_url + "dwh_sync_table",{token:token, org:selectedOrganization?.slug, tbl:table_slug})
    .then(response=>{
      console.log("dwh_sync_table:", response);
      if (response.data.status === 200) {
        //setFormData({});
        //setFormMessage({message:"Table created successfully", type:"success"});
        setRefreshTableList(!refreshTableList);
        handleCloseAlertModal();
        handleShowAlertModal('Table provisioned successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        setTableSlug("");
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        //setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Edit
  const [tableName, setTableName] = useState("");
  const [tableSlug, setTableSlug] = useState("");
  const [editTableModalShow, setEditTableModalShow] = useState(false);
  const handleCloseEditTableModal = () => {
    setEditTableModalShow(false);
    setTableName("");
    setTableSlug("");
    setFormData({});
    setFormMessage({});
    setColumnData([]);
  }
  const handleShowEditTableModal = (details) => {
    console.log(details);
    setFormData(details);
    if(details.columnItems){
      setColumnData(details.columnItems.sort(({seq_no:a}, {seq_no:b}) => a-b));
    }
    setEditTableModalShow(true);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleUpdateTable = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_table_update",{
      token:token,
      dbs:dbs,
      tbl:formData.tbl,
      name: formData.name,
      tableType: formData.table_type,
      description: formData.description ? formData.description : "-",
      columnDetails: columnData
    })
    .then(response=>{
      console.log("dwh_table_update:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Table updated successfully", type:"success"});
        handleShowAlertModal('Table updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setRefreshTableList(!refreshTableList);
        handleCloseEditTableModal();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Delete
  const [deleteTableModalShow, setDeleteTableModalShow] = useState(false);
  const handleCloseDeleteTableModal = () => {
    setDeleteTableModalShow(false);
    setTableName("");
    setFormData({});
    setFormMessage({});
    setTableSlug("");
    setDeleteFromTarget(false);
    setShowDeleteTarget(false);
  }
  const handleShowDeleteTableModal = (table_name, table_slug, is_provisioned) => {
    setDeleteTableModalShow(true);
    setTableName(table_name);
    setTableSlug(table_slug);
    setShowDeleteTarget(is_provisioned === 1 ? true : false);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleDeleteTable = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "dwh_table_delete",{token:token, dbs:dbs, tbl:tableSlug, deleteFromTarget: deleteFromTarget ? 1 : 0})
    .then(response=>{
      console.log("dwh_table_delete:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Table deleted successfully", type:"success"});
        handleShowAlertModal('Table deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setRefreshTableList(!refreshTableList);
        handleCloseDeleteTableModal();
      } else if(response.data.status === 301){
        handleLogout();
      } else if(response.data.status === 405){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        handleCloseDeleteTableModal();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        handleCloseDeleteTableModal();
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Map
  const [mapModalShow, setMapModalShow] = useState(false);
  const handleCloseMapModal = () => {
    setMapModalShow(false);
    setTableName("");
    setFormData({});
    setFormMessage({});
    setTableColumns([]);
    setMapColumnItems([]);
    setMapData([]);
    setTableSlug("");
  }
  const handleShowMapModal = (table_name, table_slug, table_columns) => {
    setTableName(table_name);
    setTableSlug(table_slug);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }

    axios.post(serverConfig.api_base_url + "dwh_map_column_list_get",{token:token, dbs:dbs, tbl:table_slug})
    .then(response=>{
      console.log("dwh_map_column_list_get:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log(response.data.items);
          setMapColumnItems(response.data.items);
          console.log(table_columns);
          if(table_columns){//this should come only after we get dwh_map_column data so we can pre-populate, so its called after setMapColumnItems
            setTableColumns(table_columns.filter(el => el.column_type !== 'id'));
          }
          setMapModalShow(true);
        } else {
          setMapColumnItems([]);
          console.log(table_columns);
          if(table_columns){//this should come only after we get dwh_map_column data so we can pre-populate, so its called after setMapColumnItems
            setTableColumns(table_columns.filter(el => el.column_type !== 'id'));
          }
          setMapModalShow(true);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleChangeMapInputs = (columnSlug, event) => {
    console.log(columnSlug);
    const name = event.target.name;
    const value = event.target.value;

    var updatedData;
    var result;

    if(name==='dim_table'){
      var dimColumnSel = event.target.closest("tr").querySelector('select[name="dim_column"]');
      axios.post(serverConfig.api_base_url + "dwh_table_column_list_get",{token:token, dbs:dbs, tbl:value})
      .then(response=>{
        console.log("dwh_table_column_list_get:", response);
        if (response.data.status === 200) {
          let items = response.data.items;
          dimColumnSel.length = 1;
          if(items !== null){
            console.log(response.data.items);
            for (var i = 0; i < items.length; i++) {
              //console.log(items[i]);
              if(items[i].column_name !== 'id'){
                dimColumnSel.options[dimColumnSel.options.length] = new Option(items[i]['column_name'], items[i]['colm']);
              }
            }
          } else {
            //console.log(dimColumnSel.length);
            for (var i = 0; i <= dimColumnSel.length; i++) {
              //console.log(i);
              if(i !== 0){
                dimColumnSel.remove(i);
              }
            }
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if(name==='fact_table'){
      var factColumnSel = event.target.closest("tr").querySelector('select[name="fact_column"]');
      axios.post(serverConfig.api_base_url + "dwh_table_column_list_get",{token:token, dbs:dbs, tbl:value})
      .then(response=>{
        console.log("dwh_table_column_list_get:", response);
        if (response.data.status === 200) {
          let items = response.data.items;
          factColumnSel.length = 1;
          if(items !== null){
            console.log(response.data.items);
            for (var i = 0; i < items.length; i++) {
              //console.log(items[i]);
              if(items[i].column_name !== 'id'){
                factColumnSel.options[factColumnSel.options.length] = new Option(items[i]['column_name'], items[i]['colm']);
              }
            }
          } else {
            //console.log(factColumnSel.length);
            for (var i = 0; i <= factColumnSel.length; i++) {
              //console.log(i);
              if(i !== 0){
                factColumnSel.remove(i);
              }
            }
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if(name==='fact_column'){
      updatedData = {[name]: event.target.options[event.target.options.selectedIndex].text, fact_colm: value};
    } else if(name==='dim_column'){
      updatedData = {[name]: event.target.options[event.target.options.selectedIndex].text, dim_colm: value};
    }

    if(updatedData){
      //console.log(mapData.filter(el => el.colm === columnSlug).length, mapData.filter(el => el.colm === columnSlug));
      //console.log(mapColumnItems.filter(el => el.colm === columnSlug).length, mapColumnItems.filter(el => el.colm === columnSlug));

      //console.log(mapData);
      //console.log(tableColumns);

      if(mapData.filter(el => el.colm === columnSlug).length > 0){
        result = mapData.map((item) => (item.colm === columnSlug ? { ...item, ...updatedData } : item));
        //console.log(result);
        setMapData(result);
      } else {
        result = tableColumns.filter(el => el.colm === columnSlug).map((item) => ({...item, ...updatedData }));
        //console.log(result);
        //console.log([...mapData, ...result]);
        setMapData([...mapData, ...result]);
      }
    }
  }
  
  const handleMap = (event) => {
    event.preventDefault();
    console.log(mapData);
    axios.post(serverConfig.api_base_url + "dwh_map_column_update",{token:token, dbs:dbs, items:mapData})
    .then(response=>{
      console.log("dwh_map_column_update:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Mapping successful", type:"success"});
        handleCloseMapModal();
        handleShowAlertModal('Columns mapped successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Manage Columns
  const handleEdit = (columnSeqNo, columnSlug, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(columnSeqNo, columnSlug);
    console.log(columnData);

    var updatedData;
    var result;

    if(name==='data_type'){
      event.target.closest('.items-row').querySelector('input[name="length"]').value = "";
      if(value==='varchar'){
        updatedData = {[name]: value};
      } else {
        updatedData = {[name]: value, length: null};
      }
    } else {
      updatedData = {[name]: value};
    }

    
    if(columnSlug){
      result = columnData.map((item) => (item.colm === columnSlug ? { ...item, ...updatedData } : item));
    } else {
      result = columnData.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData } : item));
    }
    console.log(result);
    setColumnData(result.sort(({seq_no:a}, {seq_no:b}) => a-b));
  }

  const handleAddNewRow = () => {
    console.log(columnData);
    console.log(Math.max(...columnData.map(o => o.seq_no)));
    var seqNo;
    if(columnData.length > 0){
      seqNo = Math.max(...columnData.map(o => o.seq_no)) + 1;
    } else {
      seqNo = 1;
    }
    console.log([...columnData, {
      colm: null,
      column_name: null,
      column_type: null,
      data_type: null,
      description: null,
      length: null,
      seq_no: seqNo
    }]);
    setColumnData([...columnData, {
      colm: null,
      column_name: null,
      column_type: null,
      data_type: null,
      description: null,
      length: null,
      seq_no: seqNo
    }]);
  }

  const handleDeleteColumn = (columnSeqNo, columnSlug) => {
    console.log(columnData);
    console.log(columnSeqNo, columnSlug);

    let arr = columnData;
    setColumnData([]);

    setTimeout(() => {
      if(columnSlug){
        setColumnData(arr.filter(el => el.colm !== columnSlug).sort(({seq_no:a}, {seq_no:b}) => a-b));
      } else {
        setColumnData(arr.filter(el => el.seq_no !== columnSeqNo).sort(({seq_no:a}, {seq_no:b}) => a-b));
      }
    });
  }

  const handleMoveUpColumn = (columnSeqNo) => {
    console.log(columnData);
    console.log(columnSeqNo);

    let currentIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo);
    let prevIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo) - 1;
    let currentIndexSeqNo = columnData[currentIndex].seq_no;
    let prevIndexSeqNo = columnData[prevIndex].seq_no;

    //console.log(currentIndex, currentIndexSeqNo);
    //console.log(prevIndex, prevIndexSeqNo);

    let updatedData0 = {seq_no: 0};
    let updatedData1 = {seq_no: prevIndexSeqNo};
    let updatedData2 = {seq_no: currentIndexSeqNo};
    let result1 = columnData.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item));
    let result2 = result1.map((item) => (item.seq_no === prevIndexSeqNo ? { ...item, ...updatedData2 } : item));
    let result3 = result2.map((item) => (item.seq_no === 0 ? { ...item, ...updatedData1 } : item));

    console.log(result3);
    setColumnData([]);
    setTimeout(() => {
      setColumnData(result3.sort(({seq_no:a}, {seq_no:b}) => a-b));
    });
  }

  const handleMoveDownColumn = (columnSeqNo) => {
    console.log(columnData);
    console.log(columnSeqNo);

    let currentIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo);
    let nextIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo) + 1;
    let currentIndexSeqNo = columnData[currentIndex].seq_no;
    let nextIndexSeqNo = columnData[nextIndex].seq_no;

    //console.log(currentIndex, currentIndexSeqNo);
    //console.log(nextIndex, nextIndexSeqNo);

    let updatedData0 = {seq_no: 0};
    let updatedData1 = {seq_no: nextIndexSeqNo};
    let updatedData2 = {seq_no: currentIndexSeqNo};
    let result1 = columnData.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item));
    let result2 = result1.map((item) => (item.seq_no === nextIndexSeqNo ? { ...item, ...updatedData2 } : item));
    let result3 = result2.map((item) => (item.seq_no === 0 ? { ...item, ...updatedData1 } : item));

    console.log(result3);
    setColumnData([]);
    setTimeout(() => {
      setColumnData(result3.sort(({seq_no:a}, {seq_no:b}) => a-b));
    });
  }

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  return(
    <div className="overflow-x-auto px-14 ms-2 mt-4 pb-3">
      <div className="table-custom table-dbs-sub">
        <DataTable title={
          <div className=" table-title rounded">
            <div className="flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h4 className='font-bold text-base'>Tables</h4>
              </div>
              <span className="text-right">
                <Button type='button' className='sub-grp px-3 py-2' title="Create Table" onClick={handleShowCreateTableModal}><i className="fa-solid fa-plus" aria-hidden="true" style={{fontSize: "16px"}}></i></Button>
              </span>               
            </div>
          </div>}
          columns={columns} 
          data={tableData}
          responsive={true}
          pagination={tableData.length > 10 ? true : false}
          //noTableHead
          highlightOnHover
          customStyles={{
            table: {
              style: {
                border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                borderRadius: '16px 16px 10px 10px',
              },
            },
            rows: {
              style: {
                minHeight: '48px',
                border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          noDataComponent={<div className='text-xs'>There are no records to display</div>}
        />

        {createTableModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-3/4'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Create Table</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreateTableSubmit}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <section className='flex flex-row gap-1'>
                    <div className="basis-1/2">
                      <div className='flex flex-col gap-1 mt-4 text-xs'>
                        <label>Name<span className=' text-red-500'>*</span></label>
                        <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                      </div>
                      <div className='flex flex-col gap-1 mt-3 text-xs'>
                        <label>Table Type<span className='text-red-500'>*</span></label>
                        <select value={formData.table_type || ""} className='custom-input py-2 px-2 text-left mt-1' 
                        onChange={(e)=>  {
                          setFormData({ ...formData, table_type: e.target.value});
                          if(e.target.value === 'fact' || e.target.value === 'dimension'){
                            setColumnData([]);
                            setTimeout(() => {
                              setColumnData([{
                                colm: null,
                                column_name: "id",
                                column_type: "id",
                                data_type: "int",
                                description: null,
                                length: null,
                                seq_no: 1,
                                is_identity: 1
                              }]);
                            }, 100);
                          } else {
                            setColumnData([]);
                          }
                        }} 
                        required>
                          <option value="">Table Type</option>
                          {tableTypeLookupList.length > 0 &&
                            tableTypeLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          }
                        </select>
                      </div>
                    </div>
                    <div className="basis-1/2">
                      <div className='flex flex-col gap-1 mt-4 text-xs'>
                        <label>Description</label>
                        <textarea rows="4" value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1' style={{minHeight:"126px"}}></textarea>
                      </div>
                    </div>
                  </section>
                  
                  <section className='mt-5'>
                    <h5 className='font-semibold text-base'>Columns</h5>
                    {(formData.table_type === 'fact' || formData.table_type === 'dimension') && <p className='my-2'><b>The identifier column is mandatory for table creation. It cannot be deleted.</b></p>}
                    <div className='flex flex-row gap-1 items-center'>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Name</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Column Type</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Data Type</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Length</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Description</span>
                      </div>
                      <div className='basis-auto'><button type="button" className='invisible'><i className="fa-regular fa-trash-can"></i></button></div>
                    </div>
                    {columnData && columnData.length > 0 && columnData.map((items, index) => {
                      return(<div key={index} className='flex flex-row gap-1 items-center items-row'>
                        <div className='basis-1/5'>
                          <input name="column_name" defaultValue={items.column_name} type="text" className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required/>
                        </div>
                        <div className='basis-1/5'>
                          <select name="column_type" defaultValue={items.column_type} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required disabled={items.is_identity === 1}>
                            <option value="">Column Type</option>
                            {columnTypeLookupList.length > 0 &&
                              formData.table_type === 'dimension' ?
                              columnTypeLookupList.filter((el) => (el.value === 'id' || el.value === 'dimension')).map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                              :
                              columnTypeLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                            }
                          </select>
                        </div>
                        <div className='basis-1/5'>
                          <select name="data_type" defaultValue={items.data_type} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required disabled={items.is_identity === 1}>
                            <option value="">Data Type</option>
                            {dataTypeLookupList.length > 0 &&
                              dataTypeLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                            }
                          </select>
                        </div>
                        <div className='basis-1/5'>
                          <input name="length" defaultValue={items.length} type="number" min="0" className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} disabled={items.data_type !== 'varchar'} required={items.data_type === 'varchar'} />
                        </div>
                        <div className='basis-1/5'>
                          <textarea name="description" defaultValue={items.description} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} disabled={items.is_identity === 1}></textarea>
                        </div>
                        <div className='basis-auto more-options-wrapper'/*  ref={moreOptionsIconRef} */>
                          {items.is_identity === 1 ? <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip" className='text-sm'>This is an identifier column</Tooltip>}>
                            <Button className="ms-2 info-icon">i</Button>
                          </OverlayTrigger> : <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>}
                          <div className="more-options" style={{display: "none"}}>
                            <button type="button" onClick={() => handleDeleteColumn(items.seq_no, items.colm)}><i className="fa-regular fa-trash-can"></i> <span>Delete</span></button>
                            <button type="button" onClick={() => handleMoveUpColumn(items.seq_no)} disabled={index === 0}><i className="fa-solid fa-arrow-up-long"></i> <span>Move Up</span></button>
                            <button type="button" onClick={() => handleMoveDownColumn(items.seq_no)} disabled={index+1 === columnData.length}><i className="fa-solid fa-arrow-down-long"></i> <span>Move Down</span></button>
                          </div>
                        </div>
                      </div>)
                    })}
                    <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleAddNewRow}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></button>
                  </section>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium' onClick={handleCloseCreateTableModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>}

        {editTableModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-3/4'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Table</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateTable}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <section className='flex flex-row gap-1'>
                    <div className="basis-1/2">
                      <div className='flex flex-col gap-1 mt-4 text-xs'>
                        <label>Name</label>
                        <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
                      </div>
                      <div className='flex flex-col gap-1 mt-3 text-xs'>
                        <label>Table Type</label>
                        <select value={/* (formData.description && formData.description.split("_")[0]) ||  */formData.table_type || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, table_type: e.target.value})} disabled>
                          <option value="">Table Type</option>
                          {tableTypeLookupList.length > 0 &&
                            tableTypeLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          }
                        </select>
                      </div>
                    </div>
                    <div className="basis-1/2">
                      <div className='flex flex-col gap-1 mt-4 text-xs'>
                        <label>Description</label>
                        <textarea rows="4" value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1' style={{minHeight:"126px"}}></textarea>
                      </div>
                    </div>
                  </section>
                  <section className='mt-5'>
                    <h5 className='font-semibold text-base'>Columns</h5>
                    {(formData.table_type === 'fact' || formData.table_type === 'dimension') && <p className='my-2'><b>The identifier column is mandatory for table creation. It cannot be deleted.</b></p>}
                    <div className='flex flex-row gap-1 items-center'>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Name</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Column Type</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Data Type</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Length</span>
                      </div>
                      <div className='basis-1/5'>
                        <span className='font-bold'>Description</span>
                      </div>
                      <div className='basis-auto'><button type="button" className='invisible'><i className="fa-regular fa-trash-can"></i></button></div>
                    </div>
                    {columnData && columnData.length > 0 && columnData.map((items, index) => {
                      return(<div key={index} className='flex flex-row gap-1 items-center items-row'>
                        <div className='basis-1/5'>
                          <input name="column_name" defaultValue={items.column_name} type="text" className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required/>
                        </div>
                        <div className='basis-1/5'>
                          <select name="column_type" defaultValue={items.column_type} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required disabled={items.is_identity === 1}>
                            <option value="">Column Type</option>
                            {columnTypeLookupList.length > 0 &&
                              formData.table_type === 'dimension' ?
                              columnTypeLookupList.filter((el) => (el.value === 'id' || el.value === 'dimension')).map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                              :
                              columnTypeLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                            }
                          </select>
                        </div>
                        <div className='basis-1/5'>
                          <select name="data_type" defaultValue={items.data_type} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required disabled={items.is_identity === 1}>
                            <option value="">Data Type</option>
                            {dataTypeLookupList.length > 0 &&
                              dataTypeLookupList.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                            }
                          </select>
                        </div>
                        <div className='basis-1/5'>
                          <input name="length" defaultValue={items.length} type="number" min="0" className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} disabled={items.data_type !== 'varchar'} required={items.data_type === 'varchar'} />
                        </div>
                        <div className='basis-1/5'>
                          <textarea name="description" defaultValue={items.description} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} disabled={items.is_identity === 1}></textarea>
                        </div>
                        <div className='basis-auto more-options-wrapper'/*  ref={moreOptionsIconRef} */>
                          {items.is_identity === 1 ? <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip" className='text-sm'>This is an identifier column</Tooltip>}>
                            <Button className="ms-2 info-icon">i</Button>
                          </OverlayTrigger> : <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>}
                          <div className="more-options" style={{display: "none"}}>
                            <button type="button" onClick={() => handleDeleteColumn(items.seq_no, items.colm)}><i className="fa-regular fa-trash-can"></i> <span>Delete</span></button>
                            <button type="button" onClick={() => handleMoveUpColumn(items.seq_no)} disabled={index === 0}><i className="fa-solid fa-arrow-up-long"></i> <span>Move Up</span></button>
                            <button type="button" onClick={() => handleMoveDownColumn(items.seq_no)} disabled={index+1 === columnData.length}><i className="fa-solid fa-arrow-down-long"></i> <span>Move Down</span></button>
                          </div>
                        </div>
                      </div>)
                    })}
                    <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleAddNewRow}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></button>
                  </section>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditTableModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
              </div>
            </form>
          </div>
        </div>}

        {deleteTableModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={handleDeleteTable}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Table</div>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Table <b>{tableName}</b> ?</p>
                {showDeleteTarget && <div className='mt-3'>
                  <label className='font-normal'><input type="checkbox" onChange={(e) => setDeleteFromTarget(e.target.checked)} />Delete table from the linked datawarehouse schema</label>
                </div>}
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteTableModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}

        {mapModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Map <b>{tableName}</b></div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleMap}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <table>
                    <thead className='sticky top-0'>
                      <tr>
                        <th className="px-2 py-1 text-left">Column Name</th>
                        <th className="px-2 py-1 text-left">Column Type</th>
                        <th className="px-2 py-1 text-left">Fact Table</th>
                        <th className="px-2 py-1 text-left">Fact Column</th>
                        <th className="px-2 py-1 text-left">Dim Table</th>
                        <th className="px-2 py-1 text-left">Dim Column</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableColumns && tableColumns.length > 0 && tableColumns.map((item, index) => {
                        let typeClass;
                        if(item.column_type === 'dimension'){
                          typeClass = "bg-bdr bg-bdr-peach justify-center"
                        } else if(item.column_type === 'measurement'){
                          typeClass = "bg-bdr bg-bdr-green justify-center"
                        } else if(item.column_type === 'date'){
                          typeClass = "bg-bdr bg-bdr-yellow justify-center"
                        } else if(item.column_type === 'information'){
                          typeClass = "bg-bdr bg-bdr-purple justify-center"
                        }

                        let factTable = mapColumnItems.filter(el => el.colm === item.colm).map(obj => obj.fact_table);
                        let factColm = mapColumnItems.filter(el => el.colm === item.colm).map(obj => obj.fact_colm);
                        let factColumnList = mapColumnItems.filter(el => el.colm === item.colm).map(obj => obj.fact_column_list);
                        let dimTable = mapColumnItems.filter(el => el.colm === item.colm).map(obj => obj.dim_table);
                        let dimColm = mapColumnItems.filter(el => el.colm === item.colm).map(obj => obj.dim_colm);
                        let dimColumnList = mapColumnItems.filter(el => el.colm === item.colm).map(obj => obj.dim_column_list);

                        return(
                          <tr key={index}>
                            <td className="px-2 py-1 text-left">{item.column_name}</td>
                            <td className="px-2 py-1 text-left">
                              <div className={typeClass}>{item.column_type}</div>
                            </td>
                            <td className="px-2 py-1 text-left">
                              {factTableList.length > 0 ? <select name="fact_table" defaultValue={factTable || ""} onChange={(e) => handleChangeMapInputs(item.colm, e)} className='custom-input py-2 px-2 text-left mt-1'>
                                <option value="">Select</option>
                                {
                                  factTableList.map((fitem, findex) => <option value={fitem.tbl} key={findex}>{fitem.name}</option>)
                                }
                              </select> : <span className='text-red-500 text-xs'>Create Fact Table first</span>}
                            </td>
                            <td className="px-2 py-1 text-left">
                              <select name="fact_column" defaultValue={factColm || ""} onChange={(e) => handleChangeMapInputs(item.colm, e)} className='custom-input py-2 px-2 text-left mt-1' required>
                                <option value="">Select Fact Table first</option>
                                {factColumnList[0] && factColumnList[0].length > 0 &&
                                  factColumnList[0].map((fcitem, fcindex) => fcitem.column_name !== 'id' && <option value={fcitem.colm} key={fcindex}>{fcitem.column_name}</option>)
                                }
                              </select>
                            </td>
                            <td className="px-2 py-1 text-left">
                              {dimTableList.length > 0 ? <select name="dim_table" defaultValue={dimTable || ""} onChange={(e) => handleChangeMapInputs(item.colm, e)} className='custom-input py-2 px-2 text-left mt-1' disabled={item.column_type !== 'dimension'}>
                                <option value="">Select</option>
                                {
                                  dimTableList.map((ditem, dindex) => <option value={ditem.tbl} key={dindex}>{ditem.name}</option>)
                                }
                              </select> : <span className='text-red-500 text-xs'>Create Dimension Table first</span>}
                            </td>
                            <td className="px-2 py-1 text-left">
                              <select name="dim_column" defaultValue={dimColm || ""} onChange={(e) => handleChangeMapInputs(item.colm, e)} className='custom-input py-2 px-2 text-left mt-1' disabled={item.column_type !== 'dimension'} required={item.column_type === 'dimension'}>
                                <option value="">Select Dim Table first</option>
                                {dimColumnList[0] && dimColumnList[0].length > 0 &&
                                  dimColumnList[0].map((dcitem, dcindex) => dcitem.column_name !== 'id' && <option value={dcitem.colm} key={dcindex}>{dcitem.column_name}</option>)
                                }
                              </select>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseMapModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>}

        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
      </div>
    </div>
  );
}

export default DwhTable;