import React from 'react';
const PlusButtonTooltip = ({ content, x, y}) => {
  return (
    <foreignObject x={x - 315} y="-25" width="130" height="25">
      <div className="plus-button-tooltip">
        <div className='content'>{content} - Add Time</div>
      </div>
    </foreignObject>
  );
};

export default PlusButtonTooltip;
