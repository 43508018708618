import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import serverConfig from '../../config/server.js';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contextApi/context.js';
import "../css/cat.css"
import DeleteSubCategory from '../popups/deleteSubCat.js';
import { Button } from 'react-bootstrap';
import AddSubCat from '../forms/AddSubCat.js';
import EditSubCat from '../popups/editSubCat.js';


function CategoryDetails() {
    const [childCategoryName, setChildCategoryName] = useState('');
    const [resourcesCount, setResourcesCount] = useState(0);
    const [resources, setResources] = useState([]);
    const { collapse } = useContext(Context);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [catId, setCatId] = useState(null);
    const [data, setData] = useState([]);
    const [addSubCategory, setAddSubCategory] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [rowToDelete, setRowToDelete] = useState({});
    const [categoryaAttr, setCategoryAttr] = useState({});
    //showOptionsIndex, that keeps track of the row index for which the options should be displayed.
    const [showOptionsIndex, setShowOptionsIndex] = useState(null);

    const location = useLocation()

    const handleShowMoreOptions = (index) => {
        setShowOptionsIndex(showOptionsIndex === index ? null : index);
    };

    const navigate = useNavigate();

    //const searchParams = new URLSearchParams(window.location.search);
    const category = location.state?.category
    const slug = location.state?.cat

    const [showEditSubCategoryModal, setShowEditSubCategoryModal] = useState(false);
    const [rowToEdit, setRowToEdit] = useState(null);

    const handleShowEditSubCategoryModal = (row) => {
        console.log('Show edit modal for subcategory:', row);
        setRowToEdit(row);
        setShowEditSubCategoryModal(true);
    };

    const handleEditSubCategorySuccess = () => {
        getCategoryDetails();
        setShowEditSubCategoryModal(false);
    };

    const handleShowDeleteCategoryModal = (row) => {
        //console.log("test", row);
        setRowToDelete(row);
        setShowDeletePopup(true);
    };

    const handleDeleteCategorySuccess = () => {
        console.log("Dellll")
        setTimeout(() => {
            getCategoryDetails();
        }, 500);
        setShowDeletePopup(false);
    };

    const navigateToAttr = (row) => {
        setSelectedCategory(row);
        const childCategoryId = row && row.child_category_id ? row.child_category_id : 'undefined';
        //console.log("row de", row)
        navigate(`/Category-details/CatAttr`, { state: { Cat: slug, Category: category, ChildCat: row.child_category_slug, ChildcatID: childCategoryId } });
    };


    const getCategoryDetails = async () => {
        try {
            const response = await axios.post(serverConfig.api_base_url + 'get_category_details', {
                category_slug: slug,
                category_name: category,
            });
            console.log("Response", response.data);

            if (response.data.status === 200) {
                const categoryDetails = response.data.category_details;
                //console.log("catDet",categoryDetails);
                if (categoryDetails.child_categories && categoryDetails.child_categories.length > 0) {
                    const childCategories = categoryDetails.child_categories;

                    const mappedChildCategories = childCategories.map((childCategory) => {
                        return {
                            child_category_name: childCategory.child_category_name,
                            resources_count: childCategory.resources ? childCategory.resources.length : 0,
                            child_category_attributes: childCategory.child_category_attributes,
                            resources: childCategory.resources || [],
                            child_category_id: childCategory.child_category_id,
                            child_category_slug: childCategory.child_category_slug,
                            description: childCategory.child_category_description
                        };
                    });

                    if (mappedChildCategories.length > 0) {
                        const firstChildCategory = mappedChildCategories[0];
                        setChildCategoryName(firstChildCategory.child_category_name);
                        setResourcesCount(firstChildCategory.resources_count);
                        setResources(firstChildCategory.resources);
                        setCatId(firstChildCategory.child_category_id);
                        setData(mappedChildCategories);
                        setCategoryAttr(firstChildCategory.child_category_attributes);
                    }
                    console.log("mapped data", mappedChildCategories);

                }
                else {
                    setChildCategoryName(null);
                    setResourcesCount(0);
                    setResources([]);
                    setCatId(null);
                    setData([]);
                    setCategoryAttr({});
                }

            } else {
                console.error(response.data.message)

            }
        } catch (error) {
            console.error('Error fetching category details:', error);

        }
    };

    useEffect(() => {
        if (!location.state) {
            navigate('/manage-resource');
        } else {
            getCategoryDetails();
        }
    }, [slug, category, location.state, navigate]);


    const columns = [
        {
            name: '',
            cell: (row) => (
                row.child_category_name && (
                    <div className='flex-column flex items-center py-2'>
                        <img
                            src={`https://ui-avatars.com/api/?name=${row.child_category_name}&rounded=true&length=1&${row.child_category_name ===
                                'Project' ? 'background=9D6EF8' : row.child_category_name === 'Departments' ? 'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`}
                            alt='image'
                            height={40}
                            width={40}
                            className='rounded-full'
                        />
                    </div>
                )
            ),
            grow: 0,
            sortable: false,

        },
        {
            name: <div className='row-label '>Category name</div>,
            selector: 'child_category_name',
            sortable: true,
            cell: (row) => (
                <div flex-column py-2
                    className='text-heading'
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigateToAttr(row)}
                >
                    {row.child_category_name}
                </div>
            ),

        },
        {
            name: <div className='row-label '>Description</div>,
            selector: 'child_category_name',
            sortable: true,
            cell: (row) => (
                <div flex-column py-2>
                    {row.description}
                </div>
            ),
            width: '30%'
        },
        {
            name: <div flex-column py-2 className='row-label '>Resource count</div>,
            selector: 'resources',
            sortable: true,
            cell: (row) => (row.resources ? row.resources.length : 0),

        },
        {
            name: <div className='row-label'>Actions</div>,
            cell: (row, index) => (
                <div className="text-right more-options-wrapper">
                    <button
                        type="button"
                        onClick={() => handleShowMoreOptions(index)}
                        title="More"
                        className='btn-more-options'
                    >
                        <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                    </button>
                    {showOptionsIndex === index && (
                        <div className="more-options">
                            <Button variant='text' onClick={() => handleShowEditSubCategoryModal(row)}>
                                <i className="fa-solid fa-pencil not-0" aria-hidden="true"></i>
                                <span>Edit</span>
                            </Button>
                            <Button variant='text' onClick={() => handleShowDeleteCategoryModal(row)}>
                                <i className="fa-solid fa-trash not-0" aria-hidden="true"></i>
                                <span>Delete</span>
                            </Button>
                        </div>
                    )}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className={collapse ? 'content-body' : 'content-body-collapse'}>
            <div className="container-fluid">
                <div className="rounded-3xl p-3 table-custom mt-4 ">
                    {showDeletePopup && (
                        <div className='overlay'>
                            <DeleteSubCategory
                                setDeleteConfirmation={setShowDeletePopup}
                                rowToDelete={rowToDelete}
                                onDeleteSuccess={handleDeleteCategorySuccess}
                            />
                        </div>
                    )}
                    {showEditSubCategoryModal && (
                        <div className='overlay'>
                            <EditSubCat
                                setEditCategory={setShowEditSubCategoryModal}
                                rowToEdit={rowToEdit}
                                onEditSuccess={handleEditSubCategorySuccess}
                            />
                        </div>
                    )}
                    {addSubCategory &&
                        <div className='overlay'>
                            <AddSubCat setAddCategory={setAddSubCategory} parentCategory={slug} onAddSuccess={handleEditSubCategorySuccess} />
                        </div>
                    }

                    <DataTable
                        title={
                            <div className="table-title rounded flex justify-between items-center">
                                <div className="flex space-x-4">
                                    <div
                                        className='font-semibold cursor-pointer'
                                        onClick={() => navigate('/manage-resource')}
                                    >
                                        Manage Resources
                                    </div>
                                    {category && <span>{` > ${category}`}</span>}
                                </div>
                                <div className="flex items-center space-x-4 text-black ">
                                    <div className="relative flex items-center gap-1">
                                        <div className="relative">
                                            <div className="absolute inset-y-5 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                                                <i className="text-sm fas fa-search"></i>
                                            </div>
                                            <input
                                                category_name="text"
                                                placeholder="Search"
                                                className="w-30 md:w-48 h-2 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300 search-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="relative inline-block">
                                        <div className="h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
                                            <div className='pr-5'>
                                                <i className="fa-solid fa-arrow-down-short-wide text-sm"></i>
                                            </div>
                                        </div>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                            <i className="fa-solid fa-chevron-down text-sm"></i>
                                        </div>
                                    </div>
                                    <button className='sub-grp px-3 py-2' onClick={() => setAddSubCategory(true)}>
                                        Add SubCategory
                                    </button>

                                </div>
                            </div>
                        }
                        columns={columns}
                        data={data}
                        responsive={true}
                        pagination={true}
                        noDataComponent={
                            <div className='flex flex-col h-full items-center justify-center mt-20'>
                                <div className='mb-8'><img src={require('../images/noResource.png')} alt="No Resources" /></div>
                                <p><b>{category} has no SubCategory</b></p>
                                <p className='text-sm'>Create the first Sub Category for {category}</p>
                                <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={() => setAddSubCategory(true)}>Add SubCategory</button></div>
                            </div>
                        }

                    />
                </div>
            </div>
        </div>
    );
}

export default CategoryDetails;
