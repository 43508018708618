// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.query-answer {
    height: 40px;
    width: 145px;
    margin-top: 30px;
}

.chat-result-ai{
    height: 17vh;
    margin-top: 30px;
    margin-left: 21px;
}

.chat-background-ai{
    background: white;
    height: 79vh;
    border-radius: 12px;
    overflow: scroll;
}

.DarkTheme .chat-background-ai{
    background: var(--foundation-neutral-dark-theme-neutral-dark-theme-700, #343535);;
}

@media (max-height: 695.20px) {
    
    .chat-background-ai{
        background: white;
        height: 75vh;
        border-radius: 12px;
        overflow: scroll;
    }

}`, "",{"version":3,"sources":["webpack://./src/kz-aidocs/css/aidocs.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gFAAgF;AACpF;;AAEA;;IAEI;QACI,iBAAiB;QACjB,YAAY;QACZ,mBAAmB;QACnB,gBAAgB;IACpB;;AAEJ","sourcesContent":[".query-answer {\n    height: 40px;\n    width: 145px;\n    margin-top: 30px;\n}\n\n.chat-result-ai{\n    height: 17vh;\n    margin-top: 30px;\n    margin-left: 21px;\n}\n\n.chat-background-ai{\n    background: white;\n    height: 79vh;\n    border-radius: 12px;\n    overflow: scroll;\n}\n\n.DarkTheme .chat-background-ai{\n    background: var(--foundation-neutral-dark-theme-neutral-dark-theme-700, #343535);;\n}\n\n@media (max-height: 695.20px) {\n    \n    .chat-background-ai{\n        background: white;\n        height: 75vh;\n        border-radius: 12px;\n        overflow: scroll;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
