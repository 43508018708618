import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { feature } from "topojson-client";
import usMapData from "../data/us-10m.json";

const VectorMap = ({
  data = [],
  item = {},
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [selectedState, setSelectedState] = useState("");
  const [noData, setNoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const svgRef = useRef();
  const containerRef = useRef();

  const stateLabelKey = item.label || "state";
  const revenueValueKey = item.value || "revenue";
  const title = item.title || "Map";

  const stateNames = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };

  const getRevenue = (stateAbbr) => {
    const stateData = Array.isArray(data)
      ? data.find(
          (item) =>
            item[stateLabelKey] === stateAbbr ||
            stateNames[item[stateLabelKey]] === stateAbbr
        )
      : null;
    return stateData ? stateData[revenueValueKey] : "No data available";
  };

  const getColorForRevenue = (revenue) => {
    if (!Array.isArray(data)) return "#ccc"; // Gray for no data
    const revenueNumbers = data
      .map((d) => parseFloat(d[revenueValueKey]))
      .filter(Boolean);

    const minRevenue = Math.min(...revenueNumbers);
    const maxRevenue = Math.max(...revenueNumbers);

    // Scale revenue to be a value between 0 and 1
    const intensity = (revenue - minRevenue) / (maxRevenue - minRevenue);

    // Interpolate between a light blue and a dark blue
    const blueShade = d3.interpolateRgb("#b3e5fc", "#005f99")(intensity);

    return blueShade;
  };

  useEffect(() => {
    if (item.data === "fetching") {
      setIsLoading(true);
      return;
    } else if (!Array.isArray(data) || data.length === 0) {
      setNoData("No Data Found");
      return;
    }

    console.log(item.data, "DATA");

    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;

    const svg = d3.select(svgRef.current);
    const width = containerWidth;
    const height = containerHeight;

    // Adjust projection scale and translation based on the container size
    const projection = d3
      .geoAlbersUsa()
      .scale(550)
      .translate([width / 2, height / 2]);

    const path = d3.geoPath().projection(projection);

    const states = feature(usMapData, usMapData.objects.states).features;

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "custom-map-tooltip")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "lightsteelblue")
      .style("border", "0px")
      .style("border-radius", "8px")
      .style("padding", "5px")
      .style("font", "12px sans-serif")
      .style("pointer-events", "none");

    svg.attr("width", width).attr("height", height);

    svg
      .selectAll("path")
      .data(states)
      .enter()
      .append("path")
      .attr("d", path)
      .attr("fill", (d) => {
        const stateAbbr = Object.keys(stateNames).find(
          (key) => stateNames[key] === d.properties.name
        );
        const stateData = Array.isArray(data)
          ? data.find(
              (item) =>
                item[stateLabelKey] === stateAbbr ||
                stateNames[item[stateLabelKey]] === stateAbbr
            )
          : null;
        return stateData
          ? getColorForRevenue(parseFloat(stateData[revenueValueKey]))
          : "#ccc";
      })
      .attr("stroke", "white")
      .attr("stroke-width", 2)
      .on("mouseover", function (event, d) {
        d3.select(this).attr("fill", "orange").style("cursor", "pointer");
        const stateAbbr = Object.keys(stateNames).find(
          (key) => stateNames[key] === d.properties.name
        );
        const stateFullName = stateNames[stateAbbr];
        const revenue = getRevenue(stateFullName);
        tooltip
          .style("visibility", "visible")
          .html(`<strong>${stateFullName}</strong><br>Revenue: ${revenue}`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 10}px`);
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", (d) => {
          const stateAbbr = Object.keys(stateNames).find(
            (key) => stateNames[key] === d.properties.name
          );
          const stateData = Array.isArray(data)
            ? data.find(
                (item) =>
                  item[stateLabelKey] === stateAbbr ||
                  stateNames[item[stateLabelKey]] === stateAbbr
              )
            : null;
          return stateData
            ? getColorForRevenue(parseFloat(stateData[revenueValueKey]))
            : "#ccc";
        });
        tooltip.style("visibility", "hidden");
        setSelectedState("");
      });
  }, [item.data]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <div>
      {noData ? (
        <>
          <div className="chart-title">{noData}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <></>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <>
          <div className="flex">
            <div
              ref={containerRef}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <svg
                ref={svgRef}
                style={{
                  height: 300,
                  minWidth: "300px",
                  minHeight: "200px",
                  maxWidth: "3000px",
                  maxHeight: "2000px",
                  overflow: "visible",
                }}
              ></svg>
            </div>

            <div className="map-info-container pt-10">
              <div
                className="custom-map-tooltip"
                style={{
                  position: "absolute",
                  textAlign: "center",
                  width: "auto",
                  height: "auto",
                  padding: "5px",
                  font: "12px sans-serif",
                  background: "lightsteelblue",
                  border: "0px",
                  borderRadius: "8px",
                  pointerEvents: "none",
                  visibility: "hidden",
                }}
              >
                {selectedState && (
                  <>
                    <p>{stateNames[selectedState]}</p>
                    <p>{getRevenue(selectedState)}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VectorMap;
