import React, {useState, useEffect} from "react";
import axios from "axios";
import user from '../../Asset/Images/userprofile.png';
import aiProfle from '../assets/ai-profile.png';
import loader from '../assets/loader-gif.png';
import CursorSVG from "./CursorSVG";
import { useContext } from "react";
import { Context } from "../../contextApi/context";
import Plot from 'react-plotly.js';
import loadingGIF from '../assets/loading.gif';
import { MultiSelect } from "react-multi-select-component";

const serverConfig = require("../../config/server.js");

const ChatBot = () => {

    const {selectedOrganization} = useContext(Context);

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [query, setQuery] = useState('');
    const [token, setToken] = useState(null);
    const [dbServiceLookup, setDbServiceLookup] = useState([]);
    const [schemaLookup, setSchemaLookup] = useState([]);
    const [tableLookup, setTableLookup] = useState([]);
    const [selectedDbService, setSelectedDbService] = useState({});
    const [selectedSchema, setSelectedSchema] = useState({});
    const [selectedTable, setSelectedTable] = useState([]);
    const [dbSlug, setDbSlug] = useState('');
    const [scSlug, setScSlug] = useState('');
    const [plotData, setPlotData] = useState();
    const [plotLayout, setPlotLayout] = useState();
    const [textOutput, setTextOutput] = useState('');
    const [completedTyping, setCompletedTyping] = useState(false);
    const [displayResponse, setDisplayResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState(0);

    const plotConfig = { displayModeBar: false };

    const getToken = () => {
        console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
        .then(response=>{
          console.log('user_get_token response', response);
          if(response.data.status === 200){
            sessionStorage.setItem('token', response.data.token);
            setToken(response.data.token);
          } else if(response.data.status >= 400 && response.data.status <= 499){
            handleShowAlertModal(response.data.message, true);
          } else if(response.data.jwt_validation === 'fail') {
            handleLogout();
          }
        })
        .catch(err=>console.log("error is",err));
      }

      useEffect(() => {
        console.log(sessionStorage.getItem('token'));
        if(sessionStorage.getItem('token') === null){
          getToken();
        } else {
          setToken(sessionStorage.getItem('token'));
        }
      }, []);

    const handleSendMessage = async (e) => {
        
            setQuery(inputValue);
            setTextOutput("");

            setLoading(true);
            //setInputValue("");
            // const table_names = selectedTable.map((item) => {
            //     return item?.value;              
            // })
            // console.log(table_names, 'table_names');
            // const tablesArray = JSON.stringify(table_names);
            // console.log(tablesArray, 'tablesArray')
            //setError(null);
            try {
              const response = await axios.post(serverConfig.api_base_url + "ask_gai", {
                service_name: selectedDbService,
                schema_name: selectedSchema,
                table_name: selectedTable,
                query: inputValue,
              });

              if(response.data.status === 200) {

                  const urlData = JSON.parse(response.data.data);
                  console.log(urlData);

                  const res = await axios.post(`${urlData?.url}`, {
                    enc: urlData?.enc
                  });
                  console.log(res, 'ai data');

                  if(res?.data?.status === 200) {
                    setResponseStatus(200);
                      const item = res.data.items


                      setTextOutput(item.text_response);

                      console.log(item);
                      setPlotData(item.graph_data);
                     
                      var layout = item.graph_layout;
                      if (layout.startsWith('[')) {          
                        layout = layout.substring(1);
                      }
                      if (layout.endsWith(']')) {
                        layout = layout.substring(0, layout.length - 1);
                      }
                     
                      console.log(JSON.parse(layout), 'graph_layout');
                      console.log(item.graph_data, 'graph_data');
 
                      setPlotLayout(JSON.parse(layout));
                      //setTextOutput("Some Error Occured. Please try again.");

                  }

                  else if(res?.data?.status === 502) {
                      setResponseStatus(502);
                      setTextOutput(res.data.message);
                      setPlotData([]);
                      setPlotLayout({});
                  }

              else {
              }

              }

            } catch (error) {
             // setError('Error fetching data. Please try again.');
              console.error('Error fetching data:', error);
            } finally {
              setLoading(false);
              setInputValue('');
            }

    };

    useEffect(() => {

    axios.post(serverConfig.api_base_url + "dwh_dbs_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("dwh_dbs_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;

          const dbsLookup = items?.map((item) => {
            return {
                value : item?.name,
                label : item?.name,
                slug : item?.dbs
            }
          });

          console.log(dbsLookup);

          setDbServiceLookup(dbsLookup);
        }
        }).catch(err=> {
            console.log(err);
        })

        if(dbSlug !== '') {
        axios.post(serverConfig.api_base_url + "dwh_schema_list_get",{token:token, dbs:dbSlug})
            .then(response=>{
                console.log("dwh_schema_list_get response:", response);
                if(response.data.status === 200){

                    let items = response.data.items;
                    const schLookup = items?.map((item) => {
                        return {
                            value : item?.name,
                            label : item?.name,
                            slug : item?.schema
                        }
                      });

                      console.log(schLookup);

                      setSchemaLookup(schLookup);
                }
            }).catch(err=> {
                console.log(err);
            })
        }

        if(scSlug !== '') {

        axios.post(serverConfig.api_base_url + "dwh_table_list_get",{token:token, dbs:dbSlug, schema:scSlug})
        .then(response=>{
            console.log("dwh_table_list_get response:", response);
            if(response.data.status === 200){
                let items = response.data.items;
                const tlookup = items?.map((item) => {
                    return {
                        value : item?.name,
                        label : item?.name,
                        // slug : item?.tbl
                    }
                  });

                  console.log(tlookup);

                  setTableLookup(tlookup);
            }
        }).catch(err=> {
            console.log(err);
        })
    }

    }, [dbSlug, scSlug])


    return (
        <>
        <div className="chat-container">
            <div className="chat-box">

            <div className="flex flex-row">
                        <select name="Database Service" defaultValue="" className='custom-metadata-input py-2 px-2 text-left mt-1 mr-2' onChange={(e) =>{
                              const selectedOption = JSON.parse(e.target.value);
                              console.log(selectedOption.value); // Access the value attribute
                              console.log(selectedOption.slug); // Access the slug attribute
                              setSelectedDbService(selectedOption?.value);
                              setDbSlug(selectedOption.slug);
                             }} required>
                           <option value="">Select Database Service</option>
                         {dbServiceLookup?.length > 0 &&
                            dbServiceLookup?.map((item, index) => <option value={JSON.stringify(item)} key={index} >{item.value}</option>)
                          }
                        </select>

                        <select name="Schema" defaultValue="" className='custom-metadata-input py-2 px-2 text-left mt-1 mr-2' onChange={(e) =>{
                              const selectedOption = JSON.parse(e.target.value);
                              console.log(selectedOption.value); // Access the value attribute
                              console.log(selectedOption.slug); // Access the slug attribute
                              setSelectedSchema(selectedOption?.value);
                              setScSlug(selectedOption.slug);
                             }} required>
                           <option value="">Select Schema</option>
                         {schemaLookup?.length > 0 &&
                            schemaLookup?.map((item, index) => <option value={JSON.stringify(item)} key={index} >{item.value}</option>)
                          }
                        </select>

                        {/* <MultiSelect
                            value={selectedTable}
                            options={tableLookup}
                            onChange={setSelectedTable}
                            className='ai-multiselect mt-1'
                            placeholder='Select Table'
                        /> */}

                        <select name="Table" defaultValue="" className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) =>{
                              const selectedOption = JSON.parse(e.target.value);
                              console.log(selectedOption.value); // Access the value attribute

                              setSelectedTable(selectedOption?.value);

                             }} required>
                           <option value="">Select Table</option>
                         {tableLookup?.length > 0 &&
                            tableLookup?.map((item, index) => <option value={JSON.stringify(item)} key={index} >{item.value}</option>)
                          }
                        </select>
            </div>

                {/* {query !== '' ? <>
                <div className="flex flex-col">
                <div className="flex flex-row query-asked mb-4">
                <img className='rounded-full mr-4' height={40} width={40} src={user} alt='user-img'/>
                <div>{query}</div>
                </div>

                <div className="flex flex-row query-asked">
                <img className='rounded-full mr-4' height={40} width={40} src={aiProfle} alt='user-img'/>
                <img className='rounded-full mr-4 ai-loader' height={25} width={60} src={loader} alt='user-img'/>
                </div>
                </div>
                </> : <></>} */}

        <div className="flex flex-row">
        {textOutput && loading === false ? <> 

            <div className="flex flex-col" >
        <div className="flex flex-row query-header mb-4">
                <img className='rounded-full mr-4' height={40} width={40} src={user} alt='user-img'/>
                <div className="queryText">{query}</div>
         
                </div>

                <div className="flex flex-row">
        <div className="flex query-ans">
                <img className='rounded-full mr-4' height={40} width={40} src={aiProfle} alt='user-img'/>
                {/* <img className='rounded-full mr-4 ai-loader' height={25} width={60} src={loader} alt='user-img'/> */}
                </div>
      <div className="col-md-6 chat-result">
          <div>
          {textOutput.split('\n').map((line, index) => 
          (<p> {line}
          <br /></p>))}
          </div>
          <div>
          {responseStatus === 200  ?  <Plot
              data={[plotData]}
              layout={plotLayout}
              config={plotConfig}
            /> : <> </>}
          </div>
        </div> </div> </div> </> : loading === true ? <>

        <div className="flex flex-col" >
        <div className="flex flex-row query-header mb-4">
                <img className='rounded-full mr-4' height={40} width={40} src={user} alt='user-img'/>
                <div className="queryText">{query}</div>
                </div>
                <div className="flex flex-row">
        <div className="flex query-asked">
                <img className='rounded-full mr-4' height={40} width={40} src={aiProfle} alt='user-img'/>
                </div>
        <div className="loader-container">
        <img className='rounded-full mr-4 ai-loader' height={25} width={60} src={loadingGIF} alt='user-img'/>
        </div>
        </div>
        </div> </> :<></> }
        </div>

            </div>
            <div className="flex flex-row ml-2 mr-2">
            <input
                type="text"
                value={(loading === true) ? '' : inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Your Request"
                className="ai-chat"
            />
            <button onClick={handleSendMessage} className="send-btn"><img src={require('../assets/up-arrow.png')} className="up-arrow-img" height={17} width={17} alt="Up Arrow" /></button>
            </div>
        </div>
        </>
    )
}

export default ChatBot;