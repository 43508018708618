import React, {useContext} from "react";
import { Context } from "../../contextApi/context";
import CustomerPlanTable from "./tables/CustomerPlanTable";

const CustomerPlan = () => {
  const {collapse} = useContext(Context);

  return (<>
    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
        <div className="container-fluid metadata">
            <CustomerPlanTable/>
        </div>
      </div>
    </div>
  </>);
}

export default CustomerPlan;