import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import UserService from '../../services/UserService.js';
import { useContext } from 'react';
import { Context } from '../../contextApi/context';
import '../css/cat.css';
import Notification from '../../common/notification/Notification.js';

const serverConfig = require('../../config/server.js');

function EditCat({ setEditCategory, rowToEdit, onEditSuccess }) {
    const { selectedOrganization } = useContext(Context);
    const [categoryError, setCategoryError] = useState('');
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
    const [editedCategory, setEditedCategory] = useState(rowToEdit.category);
    const [editedDescription, setEditedDescription] = useState(rowToEdit.description);
    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);

    const [tab, setTab] = useState('Edit Category');
    const [memberTab, setMemberTab] = useState(0)

    const inputRef = useRef();

    const getToken = () => {
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt })
            .then(response => {
                console.log('user_get_token response', response);
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setCToken(response.data.token);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log('error is', err));
    };

    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        console.log("Alerrttt", alertMessage);
        if (alertMessage !== '') {
            setShowAlertModal(true);
        } else {
            setShowAlertModal(false);
        }
    }, [alertMessage]);


    const handleEditCategory = () => {
        if (isCategoryUpdated()) {
            axios.post(serverConfig.api_base_url + 'resource_category_update', {
                token: cToken,
                org: selectedOrganization?.slug,
                cat: rowToEdit.slug,
                new_name: editedCategory,
                new_description: editedDescription,
            })
                .then(response => {
                    if (response.data.status === 200) {
                        console.log('Category updated successfully')
                        setAlertMessage('Category updated successfully');
                        setShowOkButton(true);
                        setShowAlertModal(true);
                        setTimeout(() => {
                            setShowOkButton(false);
                            setShowAlertModal(false);
                            setEditCategory(false);
                            onEditSuccess();
                        }, 500);
                    } else if (response.data.status === 301) {
                        handleLogout();
                    } else {
                        setAlertMessage(response.data.message);
                        setShowOkButton(false);
                        setShowAlertModal(true);
                    }
                })
                .catch(err => {
                    console.log('Error updating category:', err);
                    setAlertMessage('An error occurred while updating the category');
                    setShowAlertModal(true);
                    setShowOkButton(false);
                    setTimeout(() => {
                        setShowAlertModal(false);
                    }, 3000);
                    //setShowOkButton(false);
                });
        }
        else {
            setAlertMessage('Nothing edited, Update the value');
            setShowAlertModal(true);
            setShowOkButton(false);
            setTimeout(() => {
                setShowAlertModal(false);
            }, 3000);
        }
    };



    const isCategoryUpdated = () => {
        return editedCategory !== rowToEdit.category || editedDescription !== rowToEdit.description;
    };

    return (
        <div className='add-user rounded-3xl py-4 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col items-center modalPopup'>
            <>
                <div className='flex items-center gap-2'>
                    <div className='flex flex-col team-label'>
                        <div className="flex space-x-4">
                            <button className={`px-6 py-3 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Edit Category'); setMemberTab(0); }}>Edit Category</button>
                        </div>
                        <h5 className='mt-4 font-semibold'>Edit Category</h5>
                        <div className='flex flex-row gap-2 mt-2'>
                            <div className='flex flex-col gap-1 input-field'>
                                <label>Category</label>
                                <input
                                    type='text'
                                    name='category'
                                    value={editedCategory}
                                    onChange={(e) => setEditedCategory(e.target.value)}
                                    placeholder='Category'
                                    className='invite-field custom-input py-2 px-2 text-left'
                                    required
                                    ref={inputRef}
                                />
                            </div>
                            <div className='flex flex-col gap-1 input-field'>
                                <label>Category Description</label>
                                <input
                                    type='text'
                                    name='categoryDescription'
                                    value={editedDescription}
                                    onChange={(e) => setEditedDescription(e.target.value)}
                                    placeholder='Description'
                                    className='invite-field custom-input py-2 px-2 text-left'
                                    required
                                />
                            </div>
                        </div>
                        {categoryError !== '' && <p className='error-text'>{categoryError}</p>}
                        <div className='addform flex '>
                            <button
                                type='button'
                                className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium mr-2'
                                onClick={() => {
                                    console.log('Closing form');
                                    setEditCategory(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type='button'
                                className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                                onClick={handleEditCategory}
                            >
                                Update Category
                            </button>
                        </div>
                        <div className='mt-4 flex justify-end'>
                            {showAlertModal && (
                                <Notification
                                    message={alertMessage}
                                    notificationType={showOkButton ? 'Success' : 'Error'}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}

export default EditCat;