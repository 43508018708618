import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "../css/style.css";

const PieChart = ({
  item,
  pieData,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const svgRef = useRef();
  const legendRef = useRef();
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const radius = 90;
  const totalWidth = radius * 2;

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const svg = d3.select(svgRef.current);

      const legendSvg = d3.select(legendRef.current);

      const color = d3.scaleOrdinal(d3.schemeCategory10);

      const pie = d3.pie().value((d) => d[item?.value_column]);
      const dataReady = pie(item?.data);

      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip-dashboards");

      const arcGenerator = d3.arc().innerRadius(0).outerRadius(radius);

      svg
        .selectAll(".mySlices")
        .data(dataReady)
        .enter()
        .append("path")
        .attr("d", arcGenerator)
        .attr("fill", (d, i) => {
          const col = color(i);
          d.data.originalColor = col; // Store the original color in data
          return col;
        })
        .attr("stroke", "white")
        .attr("stroke-width", "0.5px")
        .attr("opacity", 0.9)
        .attr("transform", `translate(${radius},${radius})`)
        .on("mouseover", function (event, d) {
          const currentColor = d3.select(this).attr("fill");
          const lightenedColor = d3
            .color(currentColor)
            .brighter(-0.9)
            .toString();

          d3.select(this)
            .transition()
            .duration(200)
            .attr("fill", lightenedColor);

          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(
              d.data[item?.label_column] +
                "<br>" +
                "<br>" +
                item?.value_column +
                "<br>" +
                d.data[item?.value_column]
            )
            .style("left", event.pageX + "px")
            .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", function (event, d) {
          d3.select(this)
            .transition()
            .duration(200)
            .attr("fill", d.data.originalColor);

          tooltip.transition().duration(500).style("opacity", 0);
        });

      svg
        .selectAll(".mySlices")
        .data(dataReady)
        .enter()
        .append("text")
        .text(
          (d) =>
            (((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100).toFixed(2) +
            " % "
        )
        .attr("text-anchor", "middle")
        .attr("font-size", 17);
      //.attr('transform', (d) => `translate(${arcGenerator.centroid(d)}) translate(${radius},${radius})`)

      const legend = legendSvg
        .selectAll(".legend")
        .data(dataReady)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", (d, i) => `translate(0,${i * 20})`);

      legend
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 10)
        .attr("height", 10)
        //.attr('opacity', 0.5)
        .style("fill", color);
      //.attr('fill', (d, i) => color[(i%5)]);

      legend
        .append("text")
        .attr("x", 20)
        .attr("y", 10)
        .text(
          (d) =>
            `${d.data[item?.label_column]} : ${(
              ((d.endAngle - d.startAngle) / (2 * Math.PI)) *
              100
            ).toFixed(2)}%`
        );
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [pieData, isLoading]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="no-chart-title"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <svg ref={svgRef} width={totalWidth} height={totalWidth}></svg>
          <div style={{ marginLeft: "20px", marginTop: "20px" }}>
            <div style={{ overflow: "scroll", height: "20vh" }}>
              <svg ref={legendRef} width={250} height={200}></svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PieChart;
