import logo1 from '../Images/projects/logo1.png';
import logo2 from '../Images/projects/logo2.png';
import logo3 from '../Images/projects/logo3.png';
import logo4 from '../Images/projects/logo4.png';
import logo5 from '../Images/projects/logo5.png';

export const projectData =[
    {
    img:logo5,
    name:'Fusion Framework',
    },
    {
        img:logo2,
        name:'Binary Blossom',
    },
    {
        img:logo1,
        name:'Quantum',
    },
    {
        img:logo4,
        name:'Infinity Interface',
    },
    {
        img:logo3,
        name:'KYC Success Renew',
    },
  
]