import React, { useEffect, useState } from "react";

const NumberCard = ({
  item,
  data,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [numberValue, setNumberValue] = useState(0);
  const [displayValue, setDisplayValue] = useState(0);

  // Helper function to format numbers based on type
  const formatNumber = (number, type) => {
    switch (type) {
      case "currency":
        return `$${number.toLocaleString()}`;
      case "percentage":
        return `${number}%`;
      default:
        return number.toLocaleString();
    }
  };

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const value = data?.[0]?.[item?.label] || 0;
      setNumberValue(value);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [data, isLoading]);

  useEffect(() => {
    let start = 0;
    const duration = 1000;
    const increment = numberValue / (duration / 10);

    if (numberValue > 0) {
      const interval = setInterval(() => {
        start += increment;
        if (start >= numberValue) {
          setDisplayValue(numberValue);
          clearInterval(interval);
        } else {
          setDisplayValue(start);
        }
      }, 10);
    }
  }, [numberValue]);

  return (
    <>
      {noDataFound ? (
        <div className="chart-heading">{noDataFound}</div>
      ) : (
        <div className="number-card-container">
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button type="button" title="More" className="btn-more-options">
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
            </div>
          )}
          {isLoading ? (
            <i
              className="fa-solid fa-spinner fa-spin-pulse"
              style={{ marginTop: "20px" }}
            ></i>
          ) : (
            <div
              className="card-container flex flex-col"
              style={{
                textAlign: "center",
                padding: "20px",
                background: "#fff",
                borderRadius: "10px",
                gap: "0",
              }}
            >
              <div
                style={{ fontSize: "24px", fontWeight: "bold", color: "#333" }}
              >
                {formatNumber(Math.round(displayValue), item?.type)}
              </div>
              <div
                style={{ fontSize: "16px", color: "#777", marginTop: "5px" }}
              >
                {item?.title}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NumberCard;