import React, { useRef, useEffect, useState } from "react";
import { Gantt, Willow, WillowDark } from "wx-react-gantt";
import "wx-react-gantt/dist/gantt.css";

const GanttTask = ({taskList}) => {
  
  const apiRef = useRef(null);
  
  useEffect(() => {
    if (apiRef.current) {
      const task = apiRef.current.getTask(567);
      console.log("Task:", task);//can be used to show tooltip
      
      apiRef.current.on("select-task", ev => {
        console.log("The id of the selected task:", ev.id);
      });
    }
  }, [apiRef.current]);

  const [tasks, setTasks] = useState([]);
  const [links, setlinks] = useState([]);

  let holidays = [{date:"10-03-2025", desc:"casual1"}, {date:"12-03-2025", desc:"casual2"}, {date:"11-03-2025", desc:"casual3"},{date:"14-03-2025", desc:"Holi"}];
  let holidayList = holidays.map(el => {
      const [day, month, year] = el.date.split('-');
      return `${year}-${month}-${day}`;
  })
  console.log(holidayList);

  useEffect(() => {
    console.log(taskList);
    let taskListArr = taskList;

    const hoursPerDay = 8; // working hours per day
 
    function addWorkingDays(startDate, days) {
      let currentDate = new Date(startDate);
      while (currentDate.getDay() === 0 || currentDate.getDay() === 6 || holidayList.includes(currentDate.toISOString().split('T')[0])) {
        currentDate.setDate(currentDate.getDate() + 1);
      }
      let daysLeft = days;
      while (daysLeft > 0) {
        currentDate.setDate(currentDate.getDate() + 1);
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6 && !holidayList.includes(currentDate.toISOString().split('T')[0])) daysLeft--;
      }
      return currentDate;
    }

    function subtractWorkingDays(endDate, days) {
      let currentDate = new Date(endDate);
      console.log(currentDate);
      while (currentDate.getDay() === 0 || currentDate.getDay() === 6 || holidayList.includes(currentDate.toISOString().split('T')[0])) {
        currentDate.setDate(currentDate.getDate() - 1);
      }
      let daysLeft = days;
      while (daysLeft > 0) {
        currentDate.setDate(currentDate.getDate() - 1);
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6 && !holidayList.includes(currentDate.toISOString().split('T')[0])) daysLeft--;
      }
      return currentDate;
    }

    function calculateTotalEstimatedDays(arr) {
      return arr.reduce((total, task) => {
        const duration = Math.ceil(task.estimated_hours / hoursPerDay);
        return total + duration;
      }, 0);
    }

    //function calculateTaskDates(taskList) {
      let groupStart = null;
      let groupEnd = null;

      taskListArr.forEach(item => {
        if (item.start) {
          const startDate = new Date(item.start);
          if (!groupStart || startDate < groupStart) {
            groupStart = startDate;
          }
        }
        if (item.end) {
          const endDate = new Date(item.end);
          if (!groupEnd || endDate > groupEnd) {
            groupEnd = endDate;
          }
        }
      });

      // If no dates provided, default to today
      if (!groupStart && !groupEnd) {
        groupStart = new Date();
      }

      const totalEstimatedDays = calculateTotalEstimatedDays(taskListArr);

      // Check if the tasks fit within the date range
      if (groupEnd && groupStart) {
        const diffDays = Math.ceil((groupEnd - groupStart) / (1000 * 3600 * 24));
        console.log("the diffDays is :", diffDays);
        if (totalEstimatedDays > diffDays) {
          console.log('Total duration exceeds available date range. Using start date for scheduling...');
          groupEnd = null;
        }
      }

      let currentStartDate = groupStart;
      let currentEndDate = groupEnd;

      // Reverse tasks for backward scheduling
      const tasksToProcess = groupEnd && !groupStart ? taskListArr.slice().reverse() : taskListArr;

      tasksToProcess.forEach(task => {
        const duration = Math.ceil(task.estimated_hours / hoursPerDay);
        let startDate, endDate;

        if (groupStart) {
          // Forward scheduling
          startDate = new Date(currentStartDate);
          while (startDate.getDay() === 0 || startDate.getDay() === 6 || holidayList.includes(startDate.toISOString().split('T')[0])) {
            startDate.setDate(startDate.getDate() + 1);
          }
          endDate = addWorkingDays(startDate, duration - 1);
          currentStartDate = addWorkingDays(endDate, 1);
        } else if (!groupStart && groupEnd) {
          // Backward scheduling
          endDate = new Date(currentEndDate);
          while (endDate.getDay() === 0 || endDate.getDay() === 6 || holidayList.includes(endDate.toISOString().split('T')[0])) {
            endDate.setDate(endDate.getDate() - 1);
          }
          startDate = subtractWorkingDays(endDate, duration - 1);
          currentEndDate = subtractWorkingDays(startDate, 1);
        }

        task.start = startDate ? startDate.toISOString() : null;
        task.end = endDate ? endDate.toISOString() : null;

        /* if (startDate && endDate && startDate.toISOString() === endDate.toISOString()) {
          startDate.setUTCHours(0, 0, 0, 0);
          task.start = startDate.toISOString();
   
          endDate.setUTCHours(23, 0, 0, 0);
          task.end = endDate.toISOString();
        } */
        
        startDate.setUTCHours(0, 0, 0, 0);
        task.start = startDate.toISOString();
  
        endDate.setUTCHours(23, 59, 59, 0);
        task.end = endDate.toISOString();
        console.log(task.end);

        // console.log(`Task ID: ${task.id}`);
        // console.log(`Start Date: ${task.start}`);
        // console.log(`End Date: ${task.end}`);
        // console.log("----------");
      });

      console.log(tasksToProcess);
    //}
    console.log(taskList);

    setTasks(tasksToProcess.map(el => {
      return({
        ...el,
        text: el.name,
        assignee: el.task_assignee ? el?.task_assignee.map(item => item.username).join(", ") : undefined,
        total_duration: el.estimated_hours / hoursPerDay
      })
    }));
    console.log(tasks);
  },[taskList]);


  useEffect(() => {
    // Show tooltip on hover
    const elements = document.querySelectorAll('.wx-task');
    const tooltip = document.getElementById('tooltip');

    elements.forEach(element => {
      element.addEventListener('mouseover', function(e) {
        let tooltipText = element.querySelector('.wx-content').textContent;
        if(element.querySelector('.wx-progress-percent')){

          tooltipText += '<br><small>Progress: ' + element.querySelector('.wx-progress-percent')?.style.width +'</small>';
        }
        
        // Set the tooltip content
        tooltip.innerHTML = tooltipText;
        
        // Show the tooltip
        tooltip.style.display = 'block';
        
        // Position the tooltip near the mouse pointer
        tooltip.style.left = e.pageX - 220 + 'px';  // to the right of the mouse
        tooltip.style.top = e.pageY - 110 + 'px';   // below the mouse
      });

      element.addEventListener('mouseout', function() {
        // Hide the tooltip when the mouse leaves the element
        tooltip.style.display = 'none';
      });
    });

    //show holiday name
    // Get all the elements with the class name containing square brackets
    let holidayElements = document.querySelectorAll('.wx-holiday.x2-1ff484e');
    // Extract the text inside square brackets
    holidayElements.forEach(element => {
      // Extract the class name
      let className = element.className;
      // Match text inside square brackets
      let match = className.match(/\[(.*?)\]/);
      if (match) {
        let textInsideBrackets = match[1]; // Extracted text (e.g., 'casual')
        // Set the extracted text as a CSS variable specific to each element
        element.style.setProperty('--holiday-text', `'${textInsideBrackets}'`);
      }
    });


    //set links
    console.log(tasks.filter(el => el.dependencies !== undefined));
    let linksArr = [];
    tasks.filter(el => el.dependencies !== undefined).forEach(el => {
      el?.dependencies.forEach((dep, idx) => {
        linksArr.push({id: idx + 1, source: dep, target: el.id, type: "e2s"})
      });
    });
    //console.log(linksArr);
    setlinks(linksArr);
  },[tasks]);

  const markers = [
    {
      start: new Date(2025, 3, 5),
      text: "Start Project",
      //css: "myMarkerClass",
    },
  ];

  let scales = [
    { unit: "month", step: 1, format: "MMMM yyy" },
    { unit: "day", step: 1, format: "eee, "+"d" },
  ];

  function isDayOff(date) {
    const d = date.getDay();
    return d === 0 || d === 6;//Saturday, Sunday
  }

  function isHourOff(date) {
    const h = date.getHours();
    return h < 8 || h === 12 || h > 17;
  }

  function formatDate(date) {
    let day = String(date.getDate()).padStart(2, '0');  // Ensure 2-digit day
    let month = String(date.getMonth() + 1).padStart(2, '0');  // Months are 0-indexed
    let year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  function highlightTime(d, u) {
    //console.log(u);
    let highlightDayClass = [];
    //highlight weekend
    if (u === "day" && isDayOff(d)){
      highlightDayClass.push("wx-weekend");
    }
    //highlight hour
    if (u === "hour" && (isDayOff(d) || isHourOff(d))){
      highlightDayClass.push("wx-weekend");
    }
    //highlight today
    if (d.getDate() === new Date().getDate() && d.getMonth() === new Date().getMonth() && d.getFullYear() === new Date().getFullYear()){
      highlightDayClass.push("wx-today");
    } 
    //highlight holidays
    if (holidays.find(obj => obj.date === formatDate(d))) {
      highlightDayClass.push("wx-holiday");
      highlightDayClass.push(`[${holidays.find(obj => obj.date === formatDate(d)).desc}]`);
    }
    return highlightDayClass.join(" ");
  }

  function clearTaskText() {
    console.log("Aaaa")
    apiRef.current.exec("update-task", {
      id: 3,
      task: { text: "" }
    });
  }

  /* const taskTypes = [
    { id: "task", label: "Task" },
    { id: "summary", label: "Summary task" },
    { id: "milestone", label: "Milestone" },
    { id: "Epic", label: "Epic" },
    { id: "Story", label: "Story" },
  ];  */ 

  const columns = [
    { id: "text", header: "Task name", flexgrow: 2 },
    { id: "assignee", header: "Assignee", flexgrow: 2/* , sort: true */ },
    {
      id: "start",
      header: "Start date",
      flexgrow: 1,
      align: "center",
    },
    {
      id: "total_duration",
      header: "Duration",
      align: "center",
      flexgrow: 1,
    },
    {
      id: "action",
      header: "",
      width: 50,
      align: "center",
    },
  ];

  return (<div className="flex-1 max-h-full overflow-y-auto">
    {tasks.length > 0 && <Willow>
      <Gantt
        init={(api) => (apiRef.current = api)}
        ref={apiRef}
        tasks={tasks}
        links={links}
        scales={scales}
        //markers={markers}
        highlightTime={highlightTime}
        //start={new Date(2025, 2, 1)}
        //end={new Date(2025, 2, 31)}
        cellWidth={getComputedStyle(root).getPropertyValue('--wx-gantt-cell-width').trim()}
        readonly={true}
        //lengthUnit="hour"
        //taskTypes={taskTypes}
        onShowEditor={clearTaskText}
        columns={columns}
      />
    </Willow>}
    <div id="tooltip" class="tooltip"></div>
  </div>);
};

export default GanttTask;