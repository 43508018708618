import React, {useState, useContext} from "react";
import { Context } from "../contextApi/context";
import Table from './tables/Table';

const Vexa = () => {

  const {collapse, tab,taskPopup} = useContext(Context)
  const[newGroupPopup , setNewGroupPopup]= useState(false)

  return (<>
    <div>
      <div className={collapse ? 'content-body' : 'content-body-collapse'} >
        <div class="container-fluid">
          <Table />
        </div>

      </div>
    </div>
  </>)
}

export default Vexa;