import React, { useState } from "react";
import { useContext } from "react";
import { Context } from "../../contextApi/context";
import { values } from "pdf-lib";
import axios from "axios";

const serverConfig = require("../../config/server.js");

const ToggleSwitch = () => {
  const { setDarkTheme, isDarkTheme } =
    useContext(Context);

  const handleSetDarkTheme = () => {
    let token = sessionStorage.getItem("token");

    setDarkTheme(!isDarkTheme);
    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "global",
        context_name: "is_dark_theme",
        value: !isDarkTheme,
      })
      .then((response) => {
        if (response.data.status === 200) {
          //Succes code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div className="w-10 h-4 relative rounded-xl toggleSwitch">
      <button
        className={
          isDarkTheme
            ? "bg-white left-0 top-0 rounded-full absolute w-4 h-4 flex justify-center"
            : "bg-white right-0 rounded-full absolute w-4 h-4 top-0  align-middle flex justify-center"
        }
        onClick={() => {
          handleSetDarkTheme();
        }}
      >
        {isDarkTheme ? (
          <i class="fa-regular fa-moon text-black text-xs"></i>
        ) : (
          <i class="fa-regular fa-sun text-black text-xs "></i>
        )}
      </button>
    </div>
  );
};

export default ToggleSwitch;
