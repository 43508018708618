import React,{useState, useContext, useEffect } from 'react'
import user from './../Asset/Images/userprofile.png';
import ToggleSwitch from './Toggle/ToggleSwitch';
import {notificationData}  from '../Asset/Data/NotificationData';
import { Context } from './../contextApi/context';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import axios from 'axios';
import UserService from '../services/UserService';
import { useRef } from 'react';

const serverConfig = require("./../config/server.js");

function HeaderTop() {
  const { handleProfileDropdown,handleAlertDropdown
    ,isAlertDropdown, setIsProfileDropdown, isProfileDropdown,collapse, isActive,setDarkTheme, selectedOrganization, activeOption
   } = useContext(Context);
   const [token, setToken] = useState(null);
   const [profilePic, setProfilePic] = useState(null);
   const profileRef = useRef(null);

   const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  console.log(selectedOrganization, 'selected_org');

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
  console.log(selectedOrganization?.slug , 'selected_org_slug');

    if (token !== null && token !== undefined && selectedOrganization) {
      axios.post(serverConfig.api_base_url + "member_profile_get", {
        token: token,
        org: selectedOrganization?.slug
      })
      .then(response => {
        const profileData = response?.data?.item?.profile_picture;
        console.log(profileData,'PROFILE');  
        if (profileData) {
          setProfilePic(profileData); 
        }
      })
      .catch(err => {
        console.log(err);
      });
    }
  }, [token,selectedOrganization, activeOption]);  
 
  return (
   <div className={collapse?'headerTop px-4 py-2':'headerTop-collapse px-4 py-2'}>
    <div  className='flex align-middle justify-end gap-5' style={{alignItems:'center'}}>
    <ToggleSwitch/>

    {/* ---- DISABLED FOR NOW -> Notification Icon ---- */}
    {/* <button className={isAlertDropdown?'relative flex justify-center rounded-full p-1 bgOne':'relative flex justify-center rounded-full p-1'} /* onClick={handleAlertDropdown} >  */}
    {/* <i class="fa-solid fa-circle text-red-400 absolute top-1 right-1"></i> 
    <i class="fa-regular fa-bell text-sm"></i>
    {isAlertDropdown &&  <div className='notificationcard backdrop px-2 py-2 '>
      <div className='notification py-2 px-2'> 4 Notifications </div>
     { notificationData.map((data)=>{
      return(
        <div className='notification-card p-2 flex items-center justify-start gap-4'>
        <div>
        <img src={data.img} className='rounded-lg notification-img'/>
        </div>
        <div>
          <div className='notification-heading'>{data.name}</div>
          <div className='notification-description mt-1'>{data.alert}</div>
        </div>
      </div>
      )
     
     })}
      </div>} */}
    {/* </button> */}

    {/* ---- DISABLED FOR NOW -> Notification Icon ---- */}
    {/* <div className='flex justify-center align-middle' style={{alignItems:'center'}}> */}

    {/* ---- DISABLED FOR NOW -> Help Icon ---- */}
    {/* DISABLED FOR NOW -> Notification and Help Icon*/}
    {/* <i class="fa-regular fa-circle-question"></i> */}
    {/* </div> */}
    {/* ---- DISABLED FOR NOW -> Help Icon ---- */}
    
<div className='relative flex justify-center align-middle'>
  <button onClick={handleProfileDropdown}>
 {isActive &&   <i class="fa-solid fa-circle text-green-400 absolute bottom-0 right-0"></i> }
 {profilePic === null ? <img className='rounded-full user-img' height={30} width={30} src={user} alt='user-img'/> : <img className='rounded-full user-img' height={30} width={30} src={profilePic} alt='user-img'/>}
  </button>

{isProfileDropdown &&
<ProfileDropdown profileRef={profileRef}/>
 }

</div>
   
    </div>
   </div>
  )
}

export default HeaderTop;