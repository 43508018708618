import React from 'react';

const CheckEmail = () => {
    return (
        <>
        <h1>
            Check your email!!!!!
        </h1>
        </>
    )
}

export default CheckEmail;