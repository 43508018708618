import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';

const serverConfig = require("../../config/server.js");

const EditVexa = ({row, metaAttributes, token, org, vexaSlug, handleShowAlertModal, handleCloseAlertModal, handleLogout, refreshVexaList, setRefreshVexaList}) => {

  const [recordSlug, setRecordSlug] = useState("");
  const [recordName, setRecordName] =useState("");
  const [editModalShow, setEditModalShow] = useState(false);
  const [formData, setFormData] = useState({});

  const handleCloseEditModal = () => {
    setEditModalShow(false);
    setRecordSlug("");
    setRecordName("");
    setFormData({});
  }

  const handleShowEditModal = (details) => {
    console.log(details);

    /* axios.post(serverConfig.api_base_url + "vexa_get",{
      token: token,
      org: org,
      entity: vexaSlug,
      record_id: details.id
    })
    .then(response=>{
      console.log("vexa_get response:", response);
      if(response.data.status === 200){
        let items = response.data.items;
        if (items !== null || items !== undefined){
          console.log(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err)); */

    console.log(details);
    
    let inputs = {};
    metaAttributes.filter(item => item.is_visible === 1)
    .forEach(item => {
      inputs[item.name] = details[item.name] ? details[item.name] : undefined;
    });

    console.log(inputs);

    setFormData(inputs);
    setRecordSlug(details.slug);
    setEditModalShow(true);
  }

  const handleUpdate = (event) => {
    event.preventDefault();
    console.log({
      token: token,
      org: org,
      entity: vexaSlug,
      attributes: formData,
      identity_values: {slug: recordSlug}
    });
    axios.post(serverConfig.api_base_url + "vexa_update",{
      token: token,
      org: org,
      entity: vexaSlug,
      attributes: formData,
      identity_values: {slug: recordSlug}
    })
    .then(response=>{
      console.log("vexa_update :", response);
      if (response.data.status === 200) {
        setFormData({});
        setRefreshVexaList(!refreshVexaList);
        handleCloseEditModal();
        handleShowAlertModal('Record updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const newData={...formData}

    if(event.target.type === 'checkbox'){//checkbox
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
      newData[name]=checkboxesChecked;
    } else if (event.target.type === "file") {//file
      let filesArr = event.target.files;
      if (filesArr.length > 0) {//multiple file upload
        let arr = [];
        for (var i = 0; i < filesArr.length; i++){
          arr.push({name:filesArr[i].name, size:filesArr[i].size, blob: URL.createObjectURL(filesArr[i])});
        }
        console.log(arr);
        newData[name]=arr;
        event.target.value = null; // Clear the input field to allow reselection of the same file
      } else {//single file upload
        var extension = value.split(".").pop();

        if (extension === ".pdf, .doc, .docx, .txt, .jpg, .png, .jpeg") {
          console.log("File type matched");
          var file = event.target.files[0];
          if (file !== undefined) {
            console.log(file);
            console.log(file?.name, "file");
            
            /* var fileData;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              fileData = reader.result;
              newData[name+"_data"]=fileData; //base4
            } */

            newData[name]=file.name;
          }
        } else {
          alert("Does not support "+extension+" format.");
          event.target.value = null;
        }
      }
    } else {
      newData[name]=value;
    }

    console.log(newData);
    setFormData(newData);
  }

  return (<>
    <Button variant='text' title="Edit" onClick={() => handleShowEditModal(row)}><i className="fa-solid fa-pencil not-0 mr-2" aria-hidden="true"></i></Button>

    <div className={editModalShow ? 'slide-in overlay justify-end' : 'slide-out overlay justify-end'}>
      <form className={editModalShow ? 'modalPopup py-7 px-9 relative text-custom text-sm flex justify-between flex-col h-full max-h-none slide-in' : 'modalPopup py-7 px-9 relative text-custom text-sm flex justify-between flex-col h-full max-h-none slide-out'} onSubmit={handleUpdate}>
        <div className=' flex-grow overflow-y-auto '>
          <div className='flex justify-between items-center gap-5'>
            <div style={{fontSize:16}}>Edit Record</div>
            <button className='my-2 title-text-small-color' onClick={handleCloseEditModal}><i className="fa-solid fa-xmark "></i> </button>
          </div>
  
          <div className='mt-4'>
            {metaAttributes.length > 0 && 
              metaAttributes.sort(({sequence_no:a}, {sequence_no:b}) => a-b).map((item, index) => {
                return (
                  item.is_visible === 1 && <div className='flex flex-col gap-1 mt-8 text-xs'>
                    <label>
                      {item.display_label}
                      {item.is_required === 1 && <span className='text-red-500'>*</span>}
                    </label>

                    {//control_type:"text"
                      item.control_type === "text" && <input type="text" name={item.name} value={formData[item.name] || ""} onChange={handleChange} placeholder={item.display_label} className='custom-input py-2 px-2 text-left mt-1' required={item.is_required === 1} disabled={item.is_editable === 0} />
                    }
                    {//control_type:"textarea"
                      item.control_type === "textarea" && <textarea type="text" name={item.name} value={formData[item.name] || ""} onChange={handleChange} placeholder={item.display_label} className='custom-input py-2 px-2 text-left mt-1' required={item.is_required === 1} disabled={item.is_editable === 0} />
                    }
                    {//control_type:"number"
                      item.control_type === "number" && <input type="number" min="0" name={item.name} value={formData[item.name] || ""} onChange={handleChange} placeholder={item.display_label} className='custom-input py-2 px-2 text-left mt-1' required={item.is_required === 1} disabled={item.is_editable === 0} />
                    }
                    {//control_type:"email"
                      item.control_type === "email" && <input type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,}$" name={item.name} value={formData[item.name] || ""} onChange={handleChange} placeholder={item.display_label} className='custom-input py-2 px-2 text-left mt-1' required={item.is_required === 1} disabled={item.is_editable === 0} />
                    }
                    {//control_type:"date"
                      item.control_type === "date" && <input type="date" name={item.name} value={formData[item.name] || ""} onChange={handleChange} placeholder={item.display_label} className='custom-input py-2 px-2 text-left mt-1' required={item.is_required === 1} disabled={item.is_editable === 0} />
                    }
                    {//control_type:"radio"
                      item.control_type === "radio" && <div className="flex flex-wrap">
                        {item?.options?.map((option, optionIndex) =>
                          <div className="radio">
                            <label className='flex flex-row ms-4 text-sm relative'>
                              <input type="radio" value={option || ""} name={item.name} defaultChecked={formData[item.name] === option} style={{width: "100%", height: "100%"}} onChange={handleChange} required={item.is_required === 1} disabled={item.is_editable === 0} />
                              <span className="custom-radio"></span>
                              <div className='label-checkbox'>{option}</div>
                            </label>
                          </div>
                        )}
                      </div>
                    }
                    {//control_type:"checkbox"
                      item.control_type === "checkbox" && <div className="space-y-2">
                        {item?.options?.map((option, optionIndex) =>
                          <label className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              name={item.name}
                              value={option}
                              className="form-checkbox h-4 w-4 text-green-500 custom-checkbox"
                              defaultChecked={formData[item.name] && formData[item.name].includes(option)}
                              onChange={handleChange}
                              required={item.is_required === 1} disabled={item.is_editable === 0}
                            />
                            <span>{option}</span>
                          </label>
                        )}
                      </div>
                    }
                    {//control_type:"select"
                      item.control_type === "select" && <select name={item.name} value={formData[item.name] || ""} onChange={handleChange} className='custom-input py-2 px-2 text-left mt-1' required={item.is_required === 1} disabled={item.is_editable === 0}>
                        <option value="">Select</option>
                        {item?.options?.map((option, optionIndex) =>
                          <option key={optionIndex} value={option}>{option}</option>
                        )}
                      </select>
                    }
                    {//control_type:"file"
                      item.control_type === "file" && <label htmlFor={item.name} className="cursor-pointer">
                        <div className="font-medium inline-block text-gray-500 text-lg mx-2">
                          <i className="fa-solid fa-plus font-normal"></i>
                        </div>
                        <input
                          type="file"
                          name={item.name}
                          id={item.name}
                          accept=".pdf, .doc, .docx, .txt, .jpg, .png, .jpeg"
                          onChange={handleChange}
                          className="hidden"
                          required={item.is_required === 1} disabled={item.is_editable === 0}
                        />
                      </label>
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
        {/* bottom buttom */}
        <div className='spacing-div'>
          <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditModal}>Cancel</button>
          <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
        </div>
      </form>
    </div>
  </>);
}

export default EditVexa;