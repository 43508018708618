import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import UserService from '../../services/UserService';
import "../css/okr.css";
import CreateOKRPopup from '../components/CreateOKRPopup.js';
import ProgressBar from '../components/ProgressBar.js';
import EditOKRPopup from '../components/EditOKRPopup.js';
import KeyResults from './KeyResults.js';
import Notification from '../../common/notification/Notification.js';
import RecursiveOKR from './RecursiveOKR.js';
import OKRFilters from '../components/OKRFilters.js';

const serverConfig = require("../../config/server.js");

const OKRTable = () => {
  const {selectedOrganization, isDarkTheme} = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [refreshKRList, setRefreshKRList] = useState(false);
  const [filterData, setFilterData] = useState({});
  
  const [dbTypeLookup, setDBTypeLookup] = useState([]);
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshDBServiceList, setRefreshDBServiceList] = useState(false);
  const [createOKRModal, setCreateOKRModal] = useState(false);
  const [editOKRModal, setEditOKRModal] = useState(false);
  const [deleteOKRModal, setDeleteOKRModal] = useState(false);
  const [scoreTypeLookup, setScoreTypeLookup] = useState([]);
  const [groupLookup, setGroupLookup] = useState([]);
  const [refreshOkrList, setRefreshOkrList] = useState(false);
  const [okrList, setOkrList] = useState([]);
  const [okrName, setOkrName] = useState('');
  const [okrSlug, setOkrSlug] = useState('');
  const [tooltipShow, setShowTooltip] = useState(false);
  const [rowHovered, setRowHovered] = useState('');
  const [editRow, setEditRow] = useState('');
  const [deleteRow, setDeleteRow] = useState('');
  const [tooltipStates, setTooltipStates] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [labelTooltip, setLabelTooltip] = useState(false);
  const [ownerTooltip, setOwnerTooltip] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterObject, setFilterObject] = useState({});

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }

  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  const toggleTooltip = () => {     
    setOwnerTooltip(!ownerTooltip);
    setLabelTooltip(false);
  }

  const toggleTooltipLabel = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipIndex(index);
    setLabelTooltip(!labelTooltip);
    setOwnerTooltip(false);
    setTooltipStates(newTooltipStates);
  };

  
  function Mapme(data, row) {
    return (data && <div className='gap-2 overflow-hidden h-5 flex line-clamp-1 '>
      {data.map((item, index) => {
        return (

          <>
          
        <div key={index} className='status' style={{backgroundColor: `${item.color_code}30`,
        borderColor: `${item.color_code}90`}} onMouseEnter={()=>{toggleTooltipLabel(index); setRowHovered(row);}} onMouseLeave={()=>{setRowHovered('')}} >
          <div className='line-clamp-1'>{item.name}</div></div>

          {tooltipIndex === index && row===rowHovered && labelTooltip && (
                      <div className="role-tooltip">
                        {item.name}
                      </div>
                    )}
          
          </>
          )
      })}
    </div>)
  }

  const handleOKRTableCreateShow = () => {
        setCreateOKRModal(true);
  }
  const handleOKRTableCreateClose = () => {
        setFormData({});
        setCreateOKRModal(false);
        setRefreshOkrList(!refreshOkrList);
  }

  const handleOKRTableEditShow = (details) => {
    setFormData(details);
    setEditOKRModal(true);
  }

  const handleOKRTableEditClose = () => {
    setEditOKRModal(false);
    setRefreshOkrList(!refreshOkrList);
  }

  const handleOKRTableDeleteShow = (row) => {
    setDeleteOKRModal(true);
    setOkrName(row?.name);
    setOkrSlug(row?.slug)
  }
  const handleOKRTableDeleteClose = () => {
    setDeleteOKRModal(false);
    setRefreshOkrList(!refreshOkrList);
  }

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

      const convertDate = (date) => {
        var inputDateStr = date;
        var inputDate = new Date(inputDateStr);
        var monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        var dayOfWeek = inputDate.getDay();

        var day = inputDate.getDate();
        var month = inputDate.getMonth(); // Months are zero-based
        var year = inputDate.getFullYear();

        var outputDateStr = "";

        var dayOfWeekStr;
        switch (dayOfWeek) {
          case 0:
            dayOfWeekStr = "Sun";
            break;
          case 1:
            dayOfWeekStr = "Mon";
            break;
          case 2:
            dayOfWeekStr = "Tue";
            break;
          case 3:
            dayOfWeekStr = "Wed";
            break;
          case 4:
            dayOfWeekStr = "Thu";
            break;
          case 5:
            dayOfWeekStr = "Fri";
            break;
          case 6:
            dayOfWeekStr = "Sat";
            break;
        }

        outputDateStr = dayOfWeekStr + ", " + monthNames[month] + " " + day + ", " + year;

        console.log(outputDateStr); // Output: "Thu, Mar 22, 2024"
        return outputDateStr;
    }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {

    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"score_type"})
    .then(response=>{
      console.log("score_type lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("score_type data:", response.data.items);
          setScoreTypeLookup(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "group_list_get",{token:token, org:selectedOrganization?.slug})
    .then(response=>{
      console.log("group_list_get lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("group_list_get data:", response.data.items);
          var groups = response.data.items.map((item) => {
            return {
                value: item?.grp,
                label: item?.name
            }
          })
          setGroupLookup(groups);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

  }, [token]);

  useEffect(()=> {

    if(token !== null && token !== undefined) {
        OKRListGet();
    }

  }, [token, selectedOrganization, refreshOkrList, refreshKRList, filterData])

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  const handleDeleteOKR = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "okr_delete", {
        token: token,
        org: selectedOrganization?.slug,
        okr : okrSlug
    }).then(response=> {
        console.log("okr_delete_response", response.data.items);
        if(response.data.status === 200){
            handleOKRTableDeleteClose();
            handleShowAlertModal('Objective deleted successfully');
            setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
            setRefreshOkrList(!refreshOkrList);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
  
        }).catch(err=> {
        console.log(err);
    })
  }

  const OKRListGet = () => {
    axios.post(serverConfig.api_base_url + "okr_list_get", {
        token: token,
        org: selectedOrganization?.slug,
        filter: filterData
    }).then(response=> {
      if(response.data.status === 200) {
        console.log("okr_list_get : ", response.data.items);
        setOkrList(response.data.items);
      }
      else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
      }).catch(err=> {
        console.log(err);
    })
  }

  const columns = [
    {
      name: <div className='row-label'>Key</div>,
      cell: (row) =>  <div className="flex-row flex items-center py-2">
      <img src={`https://ui-avatars.com/api/?name=Objectives
      &rounded=false&length=1
      &background=F6B637&format=svg&color=fff`} alt='image'
       height={40} width={40} className='rounded-xl okr-avatar'/>
       <div className='flex flex-col'>
       <div className='text-heading ml-2.5'>{row?.okr_id}</div>
       </div>
       </div>,
                    width: '15%'
      
    },
    {
      name: <div className='row-label'>Objective</div>,
      cell: (row) => <div className="flex-row flex items-center py-2">
                     <div className='flex flex-col'>
                     <div className='text-heading ml-2.5'>{row.name}</div>
                     <div className='ml-2.5 desc-box'>{row.description}</div>
                     </div>
                     </div>,
      width: '30%'
      
    },
    // {
    //     name: <div className='row-label'>Start Date</div>,
    //     selector: row => row.start_date,
    //     cell: (row) =>  <div className='text-heading'>
    //                     {convertDate(row.start_date)}
    //                     </div>,
     
    // },
    // {
    //     name: <div className='row-label'>End Date</div>,
    //     selector: row => row.end_date,
    //     cell: (row) =>  <div className='text-heading'>
    //                     {convertDate(row.end_date)}
    //                     </div>,
        
    // },
    {
      name: <div className='row-label'>Owner</div>,
      cell: (row) => <div>{row?.owner?.username !== null ? <div className='ml-2' onMouseEnter={()=> {toggleTooltip(); setRowHovered(row);}} onMouseLeave={()=>{toggleTooltip(); setRowHovered('');}}>{row?.logo === null ?
      <img src={`https://ui-avatars.com/api/?name=${row?.owner?.username}
      &rounded=true&length=1
      &background=6ADA00&format=svg&color=fff`} alt='image'
       height={40} width={40} className='rounded-full'/> 
      : <img src={row?.logo} className='rounded-full w-12 h-12 object-cover p-1'/> }
        {rowHovered===row && ownerTooltip ? <div className="role-tooltip">
                     {row?.owner?.username}
          </div> : <></> }
      </div> : <></> } </div>,
    
    },
    // {
    //     name: <div className='row-label'>Group</div>,
    //     selector: row => row.group.name,
    //     cell: (row) => <div> {row?.group?.name ? <div className={'type-okr'} onClick={() => {}}>
    //     <div>{row?.group.name}</div>
    //     </div> : <></>} </div>,
       
    // },
    {
      name: <div className='row-label'>Labels</div>,
      cell: (row) =>
        <div className="flex overflow-hidden ml-2">
          {Mapme(row?.labels, row)}
        </div>,
      selector: row => row.labels,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className='row-label'>Progress Percentage</div>,
      cell: (row) => <div style={{ fontSize: 10 }} className='w-full ml-4'>
         {/* <ProgressbarReadOnly progress_value={row.progress_percentage ? row.progress_percentage : 0}/> */} 
         <ProgressBar isDraggable={false} progress_value={row?.progress_percentage === null ? 0 : row?.progress_percentage} formData={formData} setFormData={setFormData} />
        </div>,
      selector: row => row.progress_percentage ? row.progress_percentage : 0,
      sortable: true,
      wrap: true,
      width: '18%'
      //grow: 1.8
    },
    {
      name: <div className='row-label ml-6'>Actions</div>,
      selector: row => row.actions,
      cell: (row) =>  

      <div className='flex flex-row ml-8'>
      <Button variant='text' className='mr-2 ml-2' onClick={() => {handleOKRTableEditShow(); setEditRow(row);}}><i class="fa-solid fa-pencil"></i></Button>
      <Button variant='text' onClick={() => {handleOKRTableDeleteShow(row); setDeleteRow(row)}}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> </Button> 
      {editOKRModal && row===editRow && <div className='overlay'><EditOKRPopup convertDate={convertDate} setCloseEditOKR={handleOKRTableEditClose} token={token} data={row} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} /></div>}
      </div>
      ,
   
    },
  ];

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-dbs table-mapmetadata mt-4 ">

      {okrList?.length > 0  ?
      <>
      <div className="flex items-center justify-between space-x-4 text-black mb-4 mt-2">
          <div>
          <h1 className='font-semibold text-2xl theader'>Objectives and Key Results</h1>
          </div>
          <div className="flex items-center space-x-4 text-black">
           {tableData.length > 0 && <><div class="flex items-center gap-12 mt-4">
            <div class="relative">
              <input type="text" placeholder="Search" class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input" />
              <div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                <i class=" text-sm fas fa-search"></i>
              </div>
            </div>
          </div>
          <div className="relative inline-block">
            <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
              <div className='pr-5'>
                <i class="fa-solid fa-arrow-down-short-wide text-sm" ></i>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <i class="fa-solid fa-chevron-down text-sm"></i> 
            </div>
          </div>
          </>}

          <div>
                  <button
                    type="button"
                    class="cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium"
                    onClick={() => { setShowFilter(true); }}
                    /* disabled={timeLog.length === 0} */ title="filter data for OKR"
                  >
                    <i className="fa-solid fa-filter"></i>
                  </button>
                </div>

          <OKRFilters
        filterData={filterData}
        setFilterData={setFilterData}
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        token={token}
        selectedOrganization={selectedOrganization}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        // startDate={startDate}
        // endDate={endDate}
        handleCloseAlertModal={handleCloseAlertModal}
        handleShowAlertModal={handleShowAlertModal}
        // viewMemberTimesheetPrivileged={viewMemberTimesheetPrivileged}
        // setIsLoading={setIsLoading}
        // setMemberSlugArr={setMemberSlugArr} 
        // setFilterData={setFilterData}
      />

          <button type='button' className='sub-grp px-3.5 py-2.5' onClick={handleOKRTableCreateShow}><i className="fa-solid fa-plus mr-3" aria-hidden="true"></i> Add Objective</button>
          </div>
        </div>
       
        <DataTable
          columns={columns}
          data={okrList === null ? "" : okrList}
          expandableRows
          //expandableRowsComponent={({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>}
          expandableRowsComponent={({ data }) =><>  <RecursiveOKR okrData={data} okrSlug={data?.slug} convertDate={convertDate} keyResultColumns={columns} refreshKRList={refreshKRList} setRefreshKRList={setRefreshKRList}/></>}
          responsive={true}
          pagination={okrList?.length > 10 ? true : false}
          highlightOnHover
          customStyles={{
            rows: {
              style: {
                minHeight: '48px',
                border: '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          //subHeader subHeaderComponent={<div className="flex items-center justify-between" style={{position: "absolute", left: 0, top: "55px", zIndex: 9, width: "100%"}}><span className='font-semibold text-xs'>Objectives and Key Results</span><span className="text-right" style={{width: "15%", paddingRight: "16px"}}><Button variant='text btn-create' title="Create DB Service" onClick={handleOKRTableCreateShow}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></Button></span></div>} subHeaderAlign="left"
        />
       

        {createOKRModal && <div className='overlay'> <CreateOKRPopup token={token} selectedOrganization={selectedOrganization} handleCreateOKR={handleOKRTableCreateClose} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal}/></div>
        } </>:
        
        (okrList===null && Object.keys(filterData).length > 0) ?

        <>
        <div className="flex items-center justify-between space-x-4 text-black mb-4 mt-2">
        <div>
        <h1 className='font-semibold text-2xl theader'>Objectives and Key Results</h1>
        </div>
        <div className="flex items-center space-x-4 text-black">
         {tableData.length > 0 && <><div class="flex items-center gap-12 mt-4">
          <div class="relative">
            <input type="text" placeholder="Search" class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input" />
            <div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
              <i class=" text-sm fas fa-search"></i>
            </div>
          </div>
        </div>
        <div className="relative inline-block">
          <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
            <div className='pr-5'>
              <i class="fa-solid fa-arrow-down-short-wide text-sm" ></i>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
            <i class="fa-solid fa-chevron-down text-sm"></i> 
          </div>
        </div>
        </>}

        <div>
                <button
                  type="button"
                  class="cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium"
                  onClick={() => { setShowFilter(true); }}
                  /* disabled={timeLog.length === 0} */ title="filter data for OKR"
                >
                  <i className="fa-solid fa-filter"></i>
                </button>
              </div>

        <OKRFilters
      filterData={filterData}
      setFilterData={setFilterData}
      filterObject={filterObject}
      setFilterObject={setFilterObject}
      token={token}
      selectedOrganization={selectedOrganization}
      setShowFilter={setShowFilter}
      showFilter={showFilter}
      // startDate={startDate}
      // endDate={endDate}
      handleCloseAlertModal={handleCloseAlertModal}
      handleShowAlertModal={handleShowAlertModal}
      // viewMemberTimesheetPrivileged={viewMemberTimesheetPrivileged}
      // setIsLoading={setIsLoading}
      // setMemberSlugArr={setMemberSlugArr} 
      // setFilterData={setFilterData}
    />

        <button type='button' className='sub-grp px-3.5 py-2.5' onClick={handleOKRTableCreateShow}><i className="fa-solid fa-plus mr-3" aria-hidden="true"></i> Add Objective</button>
        </div>
      </div>

      <div className='flex flex-col h-full items-center justify-center'>
          <div className='mb-8'><img src={require('./../images/no-okr.png')} alt="No records" /></div>
          <p><b>No results found.</b></p>
        </div>
      
      </>:
        
        okrList?.length === 0 && Object.keys(filterData).length === 0  ?

        <>
        <div className='flex flex-col h-full items-center justify-center'>
          <div className='mb-8'><img src={require('./../images/no-okr.png')} alt="No records" /></div>
          <p><b>This Organisation has no OKRs</b></p>
          <p className='text-sm'>Create first objective for {selectedOrganization?.name}</p>
          <button type='button' className='sub-grp mt-4 px-3.5 py-2.5' onClick={handleOKRTableCreateShow}> Add Objective</button>
        </div>
        {createOKRModal && <div className='overlay'> <CreateOKRPopup token={token} selectedOrganization={selectedOrganization} handleCreateOKR={handleOKRTableCreateClose} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal}/></div>
      }</> : <></>}

        {deleteOKRModal && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={(e)=>{handleDeleteOKR(e);}}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Delete Objective</div>
                <button className='my-2 title-text-small-color' onClick={handleOKRTableDeleteClose}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Deleting the Objective means you no longer have access to this objective. It will delete all objectives and key results inside this Objective. Are you sure you want to delete <b>{okrName}</b> ?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={handleOKRTableDeleteClose}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs'>Delete Objective</button>
            </div>
          </form>
        </div>}

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
  </>}
      </div>
    </div>
  )
}

export default OKRTable;