import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const StackedBarChart = ({
  item,
  chartData,
  gridRow,
  gridColumn,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const svgRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState("");

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const { data, y_cord, x_cord, group, x_axis_label, y_axis_label } = item;

      const parentElement = svgRef.current.parentElement;
      const width = parentElement.clientWidth;
      const baseHeight = 90; // Define a base height for the chart
      const margin = { top: 20, right: 30, bottom: 130, left: 60 };
      const legendHeight = 50;

      // Calculate the actual height considering margins and legends
      const height = baseHeight + margin.top + margin.bottom + legendHeight;

      // Extract unique locations and races
      const locations = Array.from(new Set(data.map((d) => d[y_cord])));
      const groups = Array.from(new Set(data.map((d) => d[group])));

      // Stack the data
      const stack = d3
        .stack()
        .keys(groups)
        .value((d, key) => {
          const groupData = d.values.find((r) => r[group] === key);
          return groupData ? groupData[x_cord] : 0;
        });

      const stackedData = stack(
        d3
          .groups(data, (d) => d[y_cord])
          .map(([key, values]) => ({ key, values }))
      );

      // Create scales
      const x = d3
        .scaleBand()
        .domain(locations)
        .range([margin.left, width - margin.right])
        .padding(0.3); // Adjust padding to reduce bar width

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(stackedData, (d) => d3.max(d, (d) => d[1]))])
        .nice()
        .range([height - margin.bottom, margin.top]);

      const color = d3.scaleOrdinal().domain(groups).range(d3.schemeCategory10);

      svg.selectAll("*").remove();

      // Add tooltip
      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip-dashboards")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px");

      const onMouseOver = (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`Group: ${d.data.key}<br>Fraction: ${(d[1] - d[0]).toFixed(2)}`)
          .style("left", event.pageX + 5 + "px")
          .style("top", event.pageY - 28 + "px");
      };

      const onMouseOut = () => {
        tooltip.transition().duration(500).style("opacity", 0);
      };

      // Draw the bars
      svg
        .append("g")
        .selectAll("g")
        .data(stackedData)
        .enter()
        .append("g")
        .attr("fill", (d) => color(d.key))
        .selectAll("rect")
        .data((d) => d)
        .enter()
        .append("rect")
        .attr("x", (d) => x(d.data.key))
        .attr("y", (d) => y(d[1]))
        .attr("height", (d) => y(d[0]) - y(d[1]))
        .attr("width", x.bandwidth())
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut);

      // Add the x-axis
      svg
        .append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .attr("transform", "rotate(-45)")
        .style("text-anchor", "end"); // Center the text under the ticks

      // Add the y-axis
      svg
        .append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).tickFormat(d3.format(".0%")));

      // Add x-axis label
      svg
        .append("text")
        .attr("class", "x-axis-label")
        .attr("text-anchor", "end")
        .attr("x", width / 2)
        .attr("y", height - margin.bottom + 140)
        .text(x_axis_label);

      // Add y-axis label
      svg
        .append("text")
        .attr("class", "y-axis-label")
        .attr("text-anchor", "end")
        .attr("x", -height / 2 + 100)
        .attr("y", margin.left - 40)
        .attr("transform", "rotate(-90)")
        .text(y_axis_label);

      // Add legend
      const legend = svg
        .append("g")
        .attr("class", "legend")
        .attr(
          "transform",
          `translate(${margin.left - 40}, ${height - margin.bottom + 200})`
        );

      let legendOffsetX = 0;
      let legendOffsetY = 0;

      groups.forEach((key, i) => {
        const legendItem = legend
          .append("g")
          .attr("transform", `translate(${legendOffsetX}, ${legendOffsetY})`);

        legendItem
          .append("circle")
          .attr("cx", (d, i) => i * 100)
          .attr("cy", 0)
          .attr("r", 6)
          .attr("fill", color(key));

        legendItem
          .append("text")
          .attr("x", (d, i) => i * 100 + 10)
          .attr("y", 0)
          .attr("dy", "0.35em")
          .text(key);

        const legendItemWidth = legendItem.node().getBBox().width;
        legendOffsetX += legendItemWidth + 20;

        if (legendOffsetX > width - margin.left - margin.right) {
          legendOffsetX = 0;
          legendOffsetY += 20;
        }
      });
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [chartData, isLoading, gridRow, gridColumn]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="no-chart-title"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          style={{
            overflow: "visible",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            ref={svgRef}
            style={{ width: "100%", height: "100%" }}
            height={400}
          ></svg>
        </div>
      )}
    </>
  );
};

export default StackedBarChart;
