import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../contextApi/context';
import Label from '../components/Label';
import EditChecklist from './EditChecklist';
// import Status from '../components/Status.js';
import AssignessSide from '../components/AssignessSide';
import Progress from './Progress.js';
import Search from './Search';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CommentMain from './comment.js';

const serverConfig = require("./../../config/server.js");

function GroupDropdownCard({data, setSelectedGrp, setCollapse, setGroupList, grpList, setFormData, formData}){
  return(
    <div className='flex gap-2 align-middle p-2 oragnisationHover' style={{alignItems:'center'}} onClick={() => {setSelectedGrp(data); setCollapse(true); setGroupList(grpList); setFormData({...formData, grp:data.grp});}}>
      <div>
        {data?.logo_base64 === null ? <img src={`https://ui-avatars.com/api/?name=${data?.name}
                        &rounded=false&length=1
                        &background=${data?.type_details?.defaultColorLightTheme.replace('#','')}&format=svg&color=fff`} alt='image'
          className='organisation-img rounded-lg' /> : <img src={data.logo_base64} alt={data.logo_name} className='rounded-lg organisation-img' />}
      </div>
      <div className='organisation-heading line-clamp-1'>{data?.name}</div>
    </div>
  )
}

function EditKeyResult({setCloseEditKR, token, data, selectedOrganization, refreshKRList, handleLogout, handleShowAlertModal, handleCloseAlertModal}) {

  const navigate = useNavigate();
  const{setActiveOption} = useContext(Context);
  const[status, setStatus]= useState('');
  const[todoPopup, setTodoPopup]= useState(false);

  const [formData, setFormData] = useState({
    ...data,
    owner: data?.owner?.usr,
    grp:data.group.grp,
    status: data?.status?.value,
  });

  const[collapse, setCollapse]=useState(true);
  const[grpList, setGrpList] = useState([]);
  const[groupList, setGroupList] = useState([]);
  const[selectedGrp, setSelectedGrp] = useState(data.group.name !== null ? data.group : '');
  const[scoreTypeLookup, setScoreTypeLookup] = useState([]);

  useEffect(() => {
    console.log(selectedGrp, 'selectedGrp');
  }, [selectedGrp]);

  const[showNameTextarea, setShowNameTextarea] = useState(false);
  const[showDescTextarea, setShowDescTextarea] = useState(false);
  const[name, setName] = useState('');
  const[desc, setDesc] = useState('');

  const handleEnableTextarea = (index) => {
    // Set the index of the data set for which we're adding a name
    console.log(index)
    setShowNameTextarea(true);

  };

  
  const handleUpdate = () => {
    if(name !== '') {
      console.log({...formData, name: name});
      setFormData({...formData, name: name});
    }
    else if(desc !== '') {
      setFormData({...formData, description: desc});
    }
    setShowDescTextarea(false);
    setShowNameTextarea(false);
}

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)

      axios.post(serverConfig.api_base_url + "group_list_get", {token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('group_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setGrpList(response.data.items);
            setGroupList(response.data.items);
          }
        } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization]);

  const handleCreateNewOKR = (event) => {
    event.preventDefault();
    console.log(formData);
    
    axios.post(serverConfig.api_base_url + "okr_key_result_update", {token:token, org:selectedOrganization?.slug, kyr:data?.slug, items:formData})
    .then(response=>{
      console.log('task_create response', response);
      if(response.data.status === 200){
        setFormData({});
        refreshKRList();
        setCloseEditKR();
        handleShowAlertModal('Key Result updated successfully');
        setTimeout(() => {
        handleCloseAlertModal();
      }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
 
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {

    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"score_type"})
    .then(response=>{
      console.log("score_type lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("score_type data:", response.data.items);
          setScoreTypeLookup(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
    },[])

  return (
    <div className='taskPopUp rounded-xl p-5 relative mx-28 my-5 w-3/5'>
      <button type='button' className='my-2 absolute right-10 top-4 title-text-small-color' onClick={setCloseEditKR}><i class="fa-solid fa-xmark "></i> </button>
      <form className='flex' onSubmit={handleCreateNewOKR}>
        <div className="p-4 pr-1 border-right basis-3/4" style={{width: "54vw"}} >
          <div className='flex flex-col'>
            <div className='scrollable mb-5 pr-5' style={{maxHeight: "75vh"}}>
            <div className="flex justify-between">
                <div>
                {showNameTextarea ? <div className='flex gap-2'>
                    <textarea name="description" className='obj-name-textarea'  defaultValue={formData?.name || name} onChange={(e) => setName(e.target.value)}></textarea>
                    <button type='button' name="desc" title="Save" className='cross-mark' onClick={()=>{setShowNameTextarea(false);}}><i class='fa-solid fa-xmark'></i></button>
                    <button type='button' title="Cancel" className='tick-mark' onClick={(e) => {handleUpdate()}}><i class='fa fa-check'></i></button>
                  </div> : <div className='title text-custom'>{formData?.name}
                  <button type='button' onClick={() => handleEnableTextarea()}><i className="fa-solid fa-pencil text-xs icon-color mx-1"></i></button>
                  </div>}
                  <div className='text-sm font-medium flex items-center mt-1 title-text-small-color'>
                    
                    {selectedOrganization?.logo === null ? <img src={`https://ui-avatars.com/api/?name=${selectedOrganization?.name}
                          &rounded=false&length=1
                          &${
                          'background=6C9BF6'}&format=svg&color=fff`} alt='image'
                            className='rounded-lg mr-2' height={30} width={30}/> :<img src={selectedOrganization?.logo} alt={selectedOrganization?.logo_name} height={30} width={30} className='rounded-lg mr-2'/> }
                      
                       {selectedOrganization?.name} / <div className="flex-row flex items-center py-2">
                       <img src={`https://ui-avatars.com/api/?name=KeyResults
                        &rounded=false&length=1
                        &background=9D6EF8&format=svg&color=fff`} alt='image'
                        height={30} width={30} className='rounded-lg ml-2'/>
                        <div className='flex flex-col'>
                        <div className='text-heading ml-2.5'>{formData?.kr_id}</div>
                        </div>
                        </div></div>
                </div>
                {/* <div className='play-pause p-2 rounded-full flex justify-between items-center gap-1 me-2'>
                  <button type='button' className='rounded-full play-pause-button h-10 w-10 flex items-center justify-center  text-white' onClick={() => { setPlay(!isPlay) }}>
                    {isPlay ? <i className="fa-solid fa-play"></i> : <i className="fa-solid fa-pause"></i>}</button>
                  <div className=' font-medium text-sm px-2 text-custom'>00.00.00</div>
                </div> */}
              </div>
              <div className='mb-8'>
              {showDescTextarea ?<div className='mt-4'> <label className='mt-8 text-sm font-bold'>Description</label> <div className='flex gap-2'>
                    <textarea name="description" className='obj-desc-textarea mt-2'  defaultValue={formData?.description || desc} onChange={(e) => setDesc(e.target.value)}></textarea>
                    <button type='button' name="desc" title="Save" className='cross-mark mt-2' onClick={()=>{setShowDescTextarea(false);}}><i class='fa-solid fa-xmark'></i></button>
                    <button type='button' title="Cancel" className='tick-mark mt-2' onClick={(e) => {handleUpdate()}}><i class='fa fa-check'></i></button>
                  </div> </div> :
                  <>
                <div className='flex flex-row'><label className='mt-8 text-sm font-bold'>Description</label><button type='button' className='mt-8' onClick={()=>{setShowDescTextarea(true);}}><i className="fa-solid fa-pencil text-xs icon-color mx-1"></i></button></div>
                <div>{formData?.description}</div></>}
                {/* <textarea type="text" value={formData.description || data.description} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-2'></textarea> */}
              </div>
            
              <div className='mt-1 mb-5'>
                {Object?.keys(selectedGrp)?.length > 0 && <div className="flex items-center">
                  {selectedGrp?.logo_base64 ? <img src={selectedGrp?.logo_base64} alt={selectedGrp?.name} width="70" className='border rounded-lg' /> :
                  <img className='rounded-lg image-logo' height={70} width={70} src={selectedGrp && selectedGrp?.logo ? selectedGrp?.logo : `https://ui-avatars.com/api/?name=${selectedGrp?.name}&length=1
                      &background=${selectedGrp?.type_details?.defaultColorLightTheme.replace('#','')}&format=svg&color=fff`} alt={selectedGrp.name} />}
                  <div className='ml-3'>
                    <div className="text-xl"><b>{selectedGrp.name}</b></div>
                    <div>New Key result will be created in this group</div>
                  </div>
                </div>}
                <div className='relative mt-4 inline-block'>
                  <input type='text' className="absolute max-w-full h-full opacity-0" value={selectedGrp?.grp} />
                  <button type="button" className='choose-org border rounded-lg py-2 px-4 z-10 relative' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => setCollapse(!collapse)}><b>{selectedGrp.grp ? 'Change Group' : 'Select Group'}</b></button>
                  {!collapse && <div className='organisationDropdownCard' style={{width: '100%', left: 0}}>
                    <div className='pt-2 px-2'>
                      <Search grpList={grpList} setGroupList={setGroupList} />
                    </div>
                    <div className='choose-org py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                      {groupList.length > 0 && groupList.map((data)=>{
                        return(<GroupDropdownCard data={data} setCollapse={setCollapse} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setFormData={setFormData} formData={formData} />)
                      })}
                    </div>
                  </div>}
                </div>
              </div>
            
              {/* <div className='mt-2'>
                <FileUploader setFormData={setFormData} formData={formData} />
              </div> */}
              {/* <div className='mt-2'>
                <Link setFormData={setFormData} formData={formData} />
              </div> */}
               <div className='mt-2'>
                <Label setFormData={setFormData} formData={formData} taskSlug={formData.slug} assignee={formData?.owner} grpSlug={formData?.group?.grp} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={''} handleCloseAlertModal={''} />
              </div>
               <div className='mt-2'>
                <EditChecklist taskSlug={formData.tsk} kyr={data?.slug} formData={formData} setFormData={setFormData} grpSlug={selectedGrp.grp !== null ? selectedGrp.grp : formData.group.grp} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
              </div>
              {/* <div className='mt-2'>
                <Checklist taskSlug={formData.tsk} grpSlug={''} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={''} handleCloseAlertModal={''} getAssigneeList={[]} />
              </div> */}
              {/* <div className='mt-2'>
                <Checklist />
              </div> */}
              <div className='mt-2'>
                <CommentMain
                 formData={formData}
                 setFormData={setFormData}
                 token={token}
                 selectedOrganization={selectedOrganization}
                 handleShowAlertModal={handleShowAlertModal}
                 handleCloseAlertModal={handleCloseAlertModal} />
              </div> 
            </div>

            {/* bottom buttom */}
            {/* <div className='ml-auto'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={setCloseEditKR}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
            </div> */}
          </div>
        </div>

        <div className="p-4 pr-0 basis-1/4" >
          {/* Content for the 30% wide container */}
          <div className='flex flex-col popup-sidebar'>
            <div className='scrollable pr-1' style={{maxHeight: "75vh"}}>
              {/* status option and popup */}
              {/* <div className='relative w-48'>
                <button type='button' className='status' 
                  style={{
                    color: status.attribute2 ? status.attribute2 : 'var(--secondary-700)',
                    backgroundColor: status.attribute2 ? status.attribute2+'20' : 'var(--secondary-700)'+'20',
                    borderColor:  status.attribute2 ? status.attribute2+'90' : 'var(--secondary-700)'+'50',
                  }} 
                  onClick={() => { setTodoPopup(!todoPopup) }}>
                  <div className='brightness-[0.6]'><i className={status.attribute1 ? status.attribute1 :'fa-regular fa-calendar'}></i></div><div className='brightness-[0.6]'>{status.value ? status.value :'To Do'}</div>
                </button>
                {todoPopup && <Status status={status} setStatus={setStatus} setTodoPopup={setTodoPopup} token={token} formData={formData} setFormData={setFormData} />}

              </div> */}
              {/* assignees options and popup */}
              <AssignessSide setFormData={setFormData} assignee={data?.owner} formData={formData} grpSlug={selectedGrp.grp !== null ? selectedGrp.grp : formData.group.grp} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={''} handleCloseAlertModal={''} />

              {/* watchers options and popup */}
              {/* <WatchersSide setFormData={setFormData} formData={formData} grpSlug={selectedGrp?.grp} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={''} handleCloseAlertModal={''} /> */}

              {/* <Sprint /> */}

              <Progress formData={formData} isDraggable={formData?.score_type === 'maunual' ? false : true} setCloseEditOKR={setCloseEditKR} progress={formData?.progress_percentage === null ? 0 : formData?.progress_percentage} handleLogout={handleLogout} setFormData={setFormData} newTask={'no'} token={token} selectedOrganization={selectedOrganization} />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditKeyResult;