import React from 'react'
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';

const serverConfig = require("./../../config/server.js");

function TableMembers({token, handleLogout, selectedOrganization, userList, grp, handleShowAlertModal, handleCloseAlertModal, handleShowEditGroupModal}) {
  const[groupMemberList, setGroupMemberList] = useState([]);
  const [rowHovered, setRowHovered] = useState('');
  const [tooltipStates, setTooltipStates] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [memberProfile, setMemberProfile] = useState({});
  const [editRowClicked, setEditRowClicked] = useState('');
  const dropdownRef = useRef(null);

  const toggleTooltip = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipIndex(index);
    setTooltipStates(newTooltipStates);
  };

  useEffect(() => {
    console.log(userList, grp);
    if(grp){
      userProfileGet();
      axios.post(serverConfig.api_base_url + "group_member_list_get",{token: token, org:selectedOrganization?.slug, grp:grp})
      .then(response=>{
        console.log('group_member_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            console.log('group_member_list_get response items',response.data.items);
            setGroupMemberList(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          setGroupMemberList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization, grp]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      console.log(event.target.classList, 'classlist')
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('more-options') && !event.target.classList.contains('more-options-wrapper') && !event.target.classList.contains('btn-more-options') && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains('fa-solid')) {
        setShowDropdown('');
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
  
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const sortDown = <i class="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i class="fa-solid fa-arrow-up-short-wide"></i>;

  const RoleBadge = ({option, index, toggleTooltip, showTooltip, row }) => {
    return (
    <div key={index} className="flex-column flex items-center py-2 roles" onMouseEnter={() => {toggleTooltip(index);setRowHovered(row);}} onMouseLeave={()=>{setRowHovered('');}}>
    <img src={`https://ui-avatars.com/api/?name=${option}
    &length=1
    &${option==='Owner'?'background=9D6EF8':option==='Manager'?
    'background=F6B637':'background=6ADA00'}&format=svg&color=fff`} alt='image'
     height={36} width={36} className='role-avatar rounded-xl' />
      {row===rowHovered && tooltipIndex === index && (
        <div key={index} className="role-tooltip">
          {option}
        </div>
      )}
     </div>
    )
  }

  const userProfileGet = () => {
    axios.post(serverConfig.api_base_url + "member_profile_get", {
      token: token,
      org: selectedOrganization?.slug
    }).then(response=> {
      console.log(response.data, 'user_profile_data');
      setMemberProfile(response.data.item);
    }).catch(err=>{
      console.log(err);
    })
  }

  function OptionsDropdownCard({data, dropdownRef}){
    return(
      <div  ref={dropdownRef} id='orgHover' className={`flex gap-2 align-middle p-2 oragnisationHover`} style={{alignItems:'center'}} onClick={()=>{}}>
        <div className='options-heading line-clamp-1'>{data}</div>
      </div>
     )
    }

  const sortingIcons = [sortDown, sortUp]
  const columns = [
    {
      name: '',
      cell: (row) => <div className="flex-column flex items-center py-2">
        <img src={`https://ui-avatars.com/api/?name=${row.name.split(" ")[0]}+${row.name.split(" ").length > 1 ? row.name.split(" ")[row.name.split(" ").length - 1] : ""}
        &rounded=true&length=2
        &${row.type === 'Project' ? 'background=9D6EF8' : row.type === 'Departments' ?
            'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`} alt='image'
          height={40} width={40} className='rounded-full' />
      </div>,
      grow: 0,
      sortable: false,
      width: '4%',
    },
    {
      selector: row => row.name,
      name: <div className='row-label'>Name</div>,
      cell: (row) => <div className="flex-column  py-2 pl-3">
        <div className='text-heading'>{row.name}</div>
        {/* <div className='text-detail pt-1'>{row.detail}</div> */}
      </div>,
      sortable: true,
      wrap: true,
      width: '15%',
    },
    {
      name: <div className='row-label'>Email ID</div>,
      selector: row => row.email_id,
      cell: (row) => <div className='pl-3'>
        {row.email_id}
      </div>,
      sortable: true,
      wrap: true,
      width: '25%',
    },
    {
      name: <div className='row-label pl-3'>Active</div>,
      selector: row => row.active,
      cell: (row) => row.active === 0 ?
        <div className={'type-department'}>
          <div><i class="fa-regular fa-user"></i></div><div>Disabled</div>
        </div> :
        <div className={'type-Client'}>
          <div><i class="fa-regular fa-user"></i></div><div>Enabled</div>
        </div>,
      sortable: true,
      wrap: true
    },
    {
      name: <div className='row-label pl-3'>Locked</div>,
      selector: row => row.locked,
      cell: (row) => row.locked === 0 ?
        <div className='type-Client'>
          <div><i class="fa-regular fa-user"></i></div><div>Unlocked</div>
        </div> :
        <div className='type-department'>
          <div><i class="fa-regular fa-user"></i></div><div>Locked</div>
        </div>,
      sortable: true,
      wrap: true
    },
    {
      name: <div className='row-label'>Roles</div>,
      //selector: row => row.type,
      cell: (row) =>
        <div className='flex pl-7'>
          {
            row?.roles?.length <= 2 ? row?.roles?.map((option, index) => {
              return (
                <RoleBadge option={option} index={index} toggleTooltip={() => toggleTooltip(index)} row={row} showTooltip={tooltipStates[index]} />
              )
            }) :
              <div className='hover-div flex align-middle justify-center text-center' >{
                row?.roles?.slice(0, 2).map((option, index) => {
                  return (
                    <>
                      <div key={index} className="flex-column flex items-center py-2 roles" onMouseEnter={() => { toggleTooltip(index); setRowHovered(row); }} onMouseLeave={() => { setRowHovered('') }} showTooltip={tooltipStates[index]} >
                        <img src={`https://ui-avatars.com/api/?name=${option}
            &rounded=false&length=1
            &${index === 1 ? 'background=9D6EF8' :
                            'background=6C9BF6'}&format=svg&color=fff`} alt='image'
                          height={36} width={36} className='role-avatar rounded-xl' />
                        {tooltipIndex === index && row === rowHovered && (
                          <div className="role-tooltip">
                            {option}
                          </div>
                        )}
                      </div>
                    </>
                  )
                })
              }
                <div className='rounded-xl bg-gray-200 number-span' onClick={() => { showDropdown === row ? setShowDropdown('') : setShowDropdown(row); }}>
                  <div className='py-2'>
                    +{row?.roles?.length - 2}
                  </div>
                  <div className='rolesDropdownCard '>
                    {showDropdown === row && <div className='options-dropdown py-2 my-1'>
                      {row?.roles?.map((data) => {
                        return (
                          <OptionsDropdownCard dropdownRef={dropdownRef} data={data} />
                        )
                      })}
                    </div>}
                  </div>
                </div>
              </div>
          }
        </div>,


      sortable: false,
      wrap: true
    },
    // {
    //   name: <div className='row-label'>Actions</div>,
    //   selector: row => row.locked,      
    //   cell: (row) => <div>{(memberProfile?.email === row?.email_id) ? <div onClick={()=>{console.log((memberProfile?.email !== row?.email_id), 'member')}}> </div> : <div><div className='flex flex-row'><button className='ml-4' onClick={()=>{editRowClicked===row?setEditRowClicked(''):setEditRowClicked(row);}}><i class="fa-solid fa-pencil not-0"></i></button></div>
    //                  {row===editRowClicked && <div className='overlay'><EditUser modalClosed={modalClosed} setModalClosed={setModalClosed} setEditRowClicked={setEditRowClicked} orgSlug={selectedOrganization?.slug} slug={row.slug} active={row.active} lock={row.locked} manager={row.manager} name={row.name} roles={row.roles} email={row.email_id}/></div>}</div> }</div> ,
    //                 //ignoreRowClick: true,
    //                 allowOverflow: true,
    //                 button: true,
    //   sortable: true,
    //   wrap: true
    // },
  ]

  return (<div>
    <DataTable
      title={<div className=" table-title rounded">
        <div className=" mx-auto flex justify-between items-center">
          <div className='task px-1'>{handleShowEditGroupModal && 'Group'} Members</div>
          {/* Right side: Search input and two dropdowns */}
          {handleShowEditGroupModal && <div className="flex items-center space-x-4 text-black">
            <button className='sub-grp px-3 py-2' onClick={handleShowEditGroupModal} >Add Member</button>
          </div>}
        </div>
      </div>}
      columns={columns}
      //data={memberData}
      data={(userList && userList.length) > 0 ? userList : groupMemberList}
      responsive={true}
      pagination={true}
      customStyles={{
        rows: {
          rows: {
            className: 'custom-hover', // Apply custom hover styles here
          }
        },
        cells: {
          className: 'p-6',
        },
      }}
    />
  </div>)
}

export default TableMembers