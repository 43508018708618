import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as FaIcons from 'react-icons/fa';

const serverConfig = require("../../config/server.js");

function Status({ status, setStatus, setTodoPopup, formData, setFormData, grpSlug, token, selectedOrganization, handleShowAlertModal, handleCloseAlertModal }) {
  const [todoData, setTodoData] = useState("");

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization !== null && selectedOrganization !== undefined) {
      axios.post(serverConfig.api_base_url + "issue_status_get_list", {token: token, org: selectedOrganization?.slug, grp: grpSlug})
      .then(response => {
        console.log("issue_status_get_list:", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log("status data:", response.data.items);
            response.data.items.sort(function (a, b) {
              return a.seq_no - b.seq_no;
            });
            setTodoData(response.data.items);
          } else {
            setTodoData([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err => console.log("error is", err));
    }
  }, [token, selectedOrganization, grpSlug]);

  const handleStatusChange = (selectedStatus) => {
    console.log(selectedStatus);
    setStatus(selectedStatus);

    if (selectedStatus.name === "Closed") {
      // Capture end_date when status changes to "Closed"
      const endDate = new Date().toISOString().substring(0, 10);
      setFormData({ ...formData, status: selectedStatus.name, end_date: endDate });
    } else {
      setFormData({ ...formData, status: selectedStatus.name });
    }

    setTodoPopup(false);
  };

  return (
    <div className='absolute bg-white top-7 left-0 rounded-lg p-2 todo w-44 dropdown-content'>
      {todoData && <>
        {todoData.length > 0 ? todoData.map((item, index) => (
          <button
            type="button"
            key={index}
            className='flex justify-between items-center todo-hover w-full p-2 dropdown-content'
            onClick={() => handleStatusChange(item)}
          >
            <div className='flex justify-start items-center gap-2 dropdown-content'>
              <div className='text-xs text-gray-700 dropdown-content'>{React.createElement(FaIcons[item?.icon])}</div>
              <div className='text-xs text-gray-600 dropdown-content'>{item.name}</div>
            </div>
            <div className='text-xs dropdown-content'>{
              //status.name ?
                status.name === item.name && <><i className="fa-solid fa-check  text-gray-600 dropdown-content"></i></>
                //:
                //index === 0 && <><i className="fa-solid fa-check  text-gray-600"></i></>
            }</div>
          </button>
        )) : <span className='text-xs text-red-500 dropdown-content'>No statuses found, please contact group admin</span>}
      </>}
    </div>
  );
}


export default Status