import React from "react";
import { useEffect, useState, useContext } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { Context } from "../../contextApi/context.js";
import axios from "axios";
import { useNavigate } from "react-router";
import UserService from "../../services/UserService.js";
import "../css/mapmetadata.css";
import "../css/dataman.css";

const serverConfig = require("../../config/server.js");

const TemplateWizardManagement = ({ dbs, schema, refreshSchemaList }) => {
  const navigate = useNavigate();

  const { selectedOrganization } = useContext(Context);
  const { isDarkTheme } = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [fileFormatLookupList, setFileFormatLookupList] = useState([]);
  const [stgTableList, setStgTableList] = useState([]);
  const [factTableList, setFactTableList] = useState([]);
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshTemplateList, setRefreshTemplateList] = useState(false);

  const [mappingData, setMappingData] = useState([]);
  const [targetList, setTargetList] = useState([]);
  //<<<<<<<<<<<<<<<<------------->>>>>>>>>>>>>>>
  const [dbServiceLookup, setDbServiceLookup] = useState([]);
  const [dbSlug, setDbSlug] = useState("");
  const [scSlug, setScSlug] = useState("");
  const [schemaLookup, setSchemaLookup] = useState([]);
  const [tableLookup, setTableLookup] = useState([]);
  const [selectedDbService, setSelectedDbService] = useState({});
  const [selectedSchema, setSelectedSchema] = useState({});
  const [selectedTable, setSelectedTable] = useState({});
  const [tableSlug, setTableSlug] = useState("");
  //----------
  const [mapColumnItems, setMapColumnItems] = useState([]);
  const [dimTableList, setDimTableList] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [stageToFactData, setStageToFactData] = useState([]);

  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [mapStageToFact, setMapStageToFact] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", { jwt: jwt, url:window.location.host })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    axios
      .post(serverConfig.api_base_url + "dwh_dbs_list_get", {
        token: token,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log("dwh_dbs_list_get response:", response);
        if (response.data.status === 200) {
          let items = response.data.items;

          const dbsLookup = items?.map((item) => {
            return {
              value: item?.name,
              label: item?.name,
              slug: item?.dbs,
            };
          });

          console.log(dbsLookup);

          setDbServiceLookup(dbsLookup);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (dbSlug !== "") {
      axios
        .post(serverConfig.api_base_url + "dwh_schema_list_get", {
          token: token,
          dbs: dbSlug,
        })
        .then((response) => {
          console.log("dwh_schema_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            const schLookup = items?.map((item) => {
              return {
                value: item?.name,
                label: item?.name,
                slug: item?.schema,
              };
            });

            console.log(schLookup);

            setSchemaLookup(schLookup);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (scSlug !== "") {
      axios
        .post(serverConfig.api_base_url + "dwh_table_list_get", {
          token: token,
          dbs: dbSlug,
          schema: scSlug,
        })
        .then((response) => {
          console.log("dwh_table_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            const tlookup = items?.map((item) => {
              return {
                value: item?.name,
                label: item?.name,
                slug: item?.tbl,
              };
            });

            console.log(tlookup);

            setTableLookup(tlookup);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dbSlug, scSlug]);

  //---------------Drop Downs for Stage Table Details-----------------------
  useEffect(() => {
    //set lookup dropdown
    axios
      .post(serverConfig.api_base_url + "lookup_list", {
        token: token,
        name: "table_type",
      })
      .then((response) => {
        console.log("table_type lookup:", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log("table_type data:", response.data.items);
            response.data.items.sort(function (a, b) {
              return a.seq_no - b.seq_no;
            });
            setTableTypeLookupList(response.data.items);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
        }
      })
      .catch((err) => console.log("error is", err));

    axios
      .post(serverConfig.api_base_url + "lookup_list", {
        token: token,
        name: "column_type",
      })
      .then((response) => {
        console.log("column_type lookup:", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log("column_type data:", response.data.items);
            response.data.items.sort(function (a, b) {
              return a.seq_no - b.seq_no;
            });
            setColumnTypeLookupList(response.data.items);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
        }
      })
      .catch((err) => console.log("error is", err));

    axios
      .post(serverConfig.api_base_url + "lookup_list", {
        token: token,
        name: "data_type",
      })
      .then((response) => {
        console.log("data_type lookup:", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log("data_type data:", response.data.items);
            response.data.items.sort(function (a, b) {
              return a.seq_no - b.seq_no;
            });
            setDataTypeLookupList(response.data.items);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
        }
      })
      .catch((err) => console.log("error is", err));
  }, [token]);

  //---------------Template Details -> Drop Down for File Type -------------------
  useEffect(() => {
    //set lookup dropdown
    axios
      .post(serverConfig.api_base_url + "lookup_list", {
        token: token,
        name: "file_format",
      })
      .then((response) => {
        console.log("file_format lookup:", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log("file_format data:", response.data.items);
            response.data.items.sort(function (a, b) {
              return a.seq_no - b.seq_no;
            });
            setFileFormatLookupList(response.data.items);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
        }
      })
      .catch((err) => console.log("error is", err));
  }, [token]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ["Updated", "Option 2", "Option 3"];
  const sortingIcons = [sortDown, sortUp];

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      width: "22%",
    },
    {
      name: "Type",
      selector: (row) => row.tableType,
      width: "19%",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
      width: "44%",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      width: "15%",
    },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  //Create
  const [createTemplateModalShow, setCreateTemplateModalShow] = useState(false);
  const [uploadTemplateFile, setUploadTemplateFile] = useState(false);
  const [stageTableDetails, setStageTableDetails] = useState(false);
  const [factTableDetails, setFactTableDetails] = useState(false);

  const [DIFormInputs, setDIFormInputs] = useState({});
  const [DIFormDisabled, setDIFormDisabled] = useState(false);
  const [templateParameter, setTemplateParameter] = useState([]);
  const [templateInstructions, setTemplateInstructions] = useState(null);
  const [templateFileData, setTemplateFileData] = useState({});
  const [file, setFile] = useState("");
  const [template, setTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadErrorList, setUploadErrorList] = useState([]);
  const [showUploadErrorList, setShowUploadErrorList] = useState(false);
  const [tableTypeLookupList, setTableTypeLookupList] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [factColumnData, setFactColumnData] = useState([]);
  const [columnTypeLookupList, setColumnTypeLookupList] = useState([]);
  const [dataTypeLookupList, setDataTypeLookupList] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableName, setTableName] = useState("");
  const [filedata, setFiledata] = useState(null);
  const [showUploadFail, setShowUploadFail] = useState(false);
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showImportData, setShowImportData] = useState(true);
  const [templateGroup, setTemplateGroup] = useState([]);
  const [dataCollectionDate, setDataCollectionDate] = useState(null);

  const handleShowCreateTemplateModal = () => {
    setCreateTemplateModalShow(true);
  };

  //---------------Handle Create New Template -> Next Button (Popup 1)--------------------
  const handleCreateTemplateSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setCreateTemplateModalShow(false);
    setUploadTemplateFile(true);
    // axios
    //   .post(serverConfig.api_base_url + "dwh_template_create", {
    //     token: token,
    //     org: selectedOrganization?.slug,
    //     name: formData.name,
    //     format: formData.format,
    //     // stgTable: formData.stgTable,
    //     description: formData.description,
    //     instructions: formData.instructions,
    //     rowsToSkip: formData.rowsToSkip,
    //     // factTable: formData.factTable,
    //     // expectedColumns: formData.expectedColumns,
    //   })
    //   .then((response) => {
    //     console.log("dwh_template_create:", response);
    //     if (response.data.status === 200) {
    //       setFormData({});
    //       setFormMessage({
    //         message: "Template created successfully",
    //         type: "success",
    //       });
    //       setRefreshTemplateList(!refreshTemplateList);
    //       handleCloseCreateTemplateModal();
    //       handleShowAlertModal("Template created successfully");
    //       setTimeout(() => {
    //         handleCloseAlertModal();
    //       }, 3000);
    //     } else if (response.data.status === 301) {
    //       handleLogout();
    //     } else {
    //       handleShowAlertModal(response.data.message, true);
    //       setFormMessage({});
    //     }
    //   })
    //   .catch((err) => console.log("error is", err));
  };

  //---------------Handle Create New Template -> Cancel Button (Popup 1)--------------------
  const handleCloseCreateTemplateModal = () => {
    setCreateTemplateModalShow(false);
    setFormMessage({});
    setFormData({});
  };

  //-------------Handle Table Name here for Upload file-------------
  const handleTableName = (event) => {
    console.log(event);
    // const name = event.target.name;
    // const value = event.target.value;
    setTableName(event.target.value);
  };

  //------- Handle Upload File -> Infer Schema Button (Popup 2)---------------
  const handleUploadFileNextButton = (event) => {
    event.preventDefault();

    // Check if file and table name are empty
    if (!file && !tableName) {
      // Show error message for both fields being mandatory
      alert("Both the file and table name are mandatory.");
      return; // Exit the function
    } else if (!file) {
      // Show error message for file being empty
      alert("Please select a file.");
      return; // Exit the function
    } else if (!tableName) {
      // Show error message for table name being empty
      alert("Please enter a table name.");
      return; // Exit the function
    }

    // Proceed with the schema inference process
    setUploadTemplateFile(false); // Close Popup 2 (Upload File)
    setStageTableDetails(true); // Open Popup 3 (Stage Table Details)

    if (token) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("data", formData);
      console.log(formData);
      setIsLoading(true);

      axios
        .post(serverConfig.japi_base_url + "infer_schema_csv", formData)
        .then((response) => {
          console.log(response);
          const responseData = response.data;
          // Parse the data from the response
          const parsedData = JSON.parse(responseData.data);
          // Set the parsed data to the columnData state variable
          setColumnData(parsedData);
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error("Error fetching schema:", error);
          setIsLoading(false);
        });
    }
  };

  const handleChangeDIFormInputs = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;

    if (event.target.type === "file") {
      var extension = value.split(".").pop();

      if (extension == "xls" || extension == "csv" || extension == "xlsx") {
        console.log("File type matched");
        var file = event.target.files[0];
        console.log(file, "FILE");
        if (file !== undefined) {
          console.log(file?.name, "file");
          console.log(event.target.files[0]);
          setFile(event.target.files[0]);
          setFiledata(file?.name);
          setDIFormInputs((values) => ({ ...values, [name]: file.name }));
        }
      } else {
        alert("Supports only xls, csv, xlsx document format.");
        event.target.value = null;
      }
    } else if (event.target.type === "date") {
      setDataCollectionDate(value);
    }
  };

  //------- Handle Upload File -> Back Button (Popup 2)---------------
  const handleUploadFileCancelButton = () => {
    setCreateTemplateModalShow(true); //Open Popup 1 (Create new template)
    setUploadTemplateFile(false); //Close Popup 2 (Upload File)
    setDIFormInputs({});
    setFiledata(null);
    setDataCollectionDate(null);
    setFile("");
    setTableName("");
  };

  //--------Handle Edit for Stage Table Column Details--------
  const handleEdit = (columnSeqNo, columnSlug, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(columnSeqNo, columnSlug);
    console.log(columnData);

    var updatedData;
    var result;

    if (name === "data_type") {
      event.target
        .closest(".items-row")
        .querySelector('input[name="length"]').value = "";
      if (value === "varchar") {
        updatedData = { [name]: value };
      } else {
        updatedData = { [name]: value, length: null };
      }
    } else {
      updatedData = { [name]: value };
    }

    if (columnSlug) {
      result = columnData.map((item) =>
        item.colm === columnSlug ? { ...item, ...updatedData } : item
      );
    } else {
      result = columnData.map((item) =>
        item.seq_no === columnSeqNo ? { ...item, ...updatedData } : item
      );
    }
    console.log(result);
    setColumnData(result.sort(({ seq_no: a }, { seq_no: b }) => a - b));
  };

  //--------Handle Edit for Fact Table Column Details--------
  const handleFactEdit = (columnSeqNo, columnSlug, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(columnSeqNo, columnSlug);
    console.log(factColumnData);

    var updatedData;
    var result;

    if (name === "data_type") {
      event.target
        .closest(".items-row")
        .querySelector('input[name="length"]').value = "";
      if (value === "varchar") {
        updatedData = { [name]: value };
      } else {
        updatedData = { [name]: value, length: null };
      }
    } else {
      updatedData = { [name]: value };
    }

    if (columnSlug) {
      result = factColumnData.map((item) =>
        item.colm === columnSlug ? { ...item, ...updatedData } : item
      );
    } else {
      result = factColumnData.map((item) =>
        item.seq_no === columnSeqNo ? { ...item, ...updatedData } : item
      );
    }
    console.log(result);
    setFactColumnData(result.sort(({ seq_no: a }, { seq_no: b }) => a - b));
  };

  //------- Handle Stage Table Details -> Next Button (Popup 3)----------------
  const handleMapToStage = async (event) => {
    event.preventDefault();
    setFactTableDetails(true); //Open Popup 4
    setStageTableDetails(false); //Close Popup 3
    console.log(columnData);
    console.log(formData);
    try {
      // Serialize the column data to JSON

      const columnDetails = columnData.map((item) => ({
        column_name: item.column_name,
        column_type: item.column_type,
        data_type: item.data_type,
        length: item.length,
        description: item?.description,
        seq_no: item.seq_no,
      }));

      // Create the request body
      const requestBody = {
        columnDetails,
        // name: tableName,
        dbs: dbSlug,
        // description: formData.description || "null",
        // schema: scSlug,
        // tableType: "stage",
        token: token || "null",
      };

      //Make a POST request to the desired URL with the request body
      const response = await axios.post(
        serverConfig.api_base_url + "dwh_temp_wiz_stg",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(requestBody);

      // Handle the response if needed
      const responseData = JSON.parse(response.data.data);
      setFactColumnData(responseData);
    } catch (error) {
      // Handle errors if the request fails
      console.error("Error:", error);
    }
  };

  //------- Handle Stage Table Details -> Back Button (Popup 3)---------------
  const handleStageTableDetailsCancelButton = () => {
    setStageTableDetails(false); //Open Popup 2 (Upload File)
    setUploadTemplateFile(true); //Close Popup 3 (Stage Table Details Table)
    //setDbSlug("");
    //setScSlug("");
    setSchemaLookup([]);
    setTableLookup([]);
    setSelectedSchema({});
    setSelectedTable({});
    setColumnData([]);
    console.log(tableName);
  };

  //------- Handle Fact Table Details -> Back Button (Popup 4)----------------
  const handleCloseFactTableDetails = () => {
    setStageTableDetails(true); //Open Popup 3
    setFactTableDetails(false); //Close Popup 4\
    setFactColumnData([]);
    // setSelectedDbService(selectedDbService);
    // setSelectedSchema(selectedSchema);
  };

  //Edit
  const [templateSlug, setTemplateSlug] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [editTemplateModalShow, setEditTemplateModalShow] = useState(false);
  const handleCloseEditTemplateModal = () => {
    setEditTemplateModalShow(false);
    setTemplateSlug("");
    setTemplateName("");
    setFormMessage({});
    setFormData({});
  };
  const handleShowEditTemplateModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditTemplateModalShow(true);

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
  };

  //<<<<<<---------DeleteColumn------>>>>>>>>>>>>
  const handleDeleteColumn = (columnSeqNo, columnSlug) => {
    console.log(columnData);
    console.log(columnSeqNo, columnSlug);

    let arr = columnData;
    setColumnData([]);

    setTimeout(() => {
      if (columnSlug) {
        setColumnData(
          arr
            .filter((el) => el.colm !== columnSlug)
            .sort(({ seq_no: a }, { seq_no: b }) => a - b)
        );
      } else {
        setColumnData(
          arr
            .filter((el) => el.seq_no !== columnSeqNo)
            .sort(({ seq_no: a }, { seq_no: b }) => a - b)
        );
      }
    });
  };

  //<<<<<<---------MoveUpColumn------>>>>>>>>>>>>
  const handleMoveUpColumn = (columnSeqNo) => {
    console.log(columnData);
    console.log(columnSeqNo);

    let currentIndex = columnData.findIndex((x) => x.seq_no === columnSeqNo);
    let prevIndex = columnData.findIndex((x) => x.seq_no === columnSeqNo) - 1;
    let currentIndexSeqNo = columnData[currentIndex].seq_no;
    let prevIndexSeqNo = columnData[prevIndex].seq_no;

    let updatedData0 = { seq_no: 0 };
    let updatedData1 = { seq_no: prevIndexSeqNo };
    let updatedData2 = { seq_no: currentIndexSeqNo };
    let result1 = columnData.map((item) =>
      item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item
    );
    let result2 = result1.map((item) =>
      item.seq_no === prevIndexSeqNo ? { ...item, ...updatedData2 } : item
    );
    let result3 = result2.map((item) =>
      item.seq_no === 0 ? { ...item, ...updatedData1 } : item
    );

    console.log(result3);
    setColumnData([]);
    setTimeout(() => {
      setColumnData(result3.sort(({ seq_no: a }, { seq_no: b }) => a - b));
    });
  };

  //<<<<<<---------MoveDownColumn------>>>>>>>>>>>>
  const handleMoveDownColumn = (columnSeqNo) => {
    console.log(columnData);
    console.log(columnSeqNo);

    let currentIndex = columnData.findIndex((x) => x.seq_no === columnSeqNo);
    let nextIndex = columnData.findIndex((x) => x.seq_no === columnSeqNo) + 1;
    let currentIndexSeqNo = columnData[currentIndex].seq_no;
    let nextIndexSeqNo = columnData[nextIndex].seq_no;

    let updatedData0 = { seq_no: 0 };
    let updatedData1 = { seq_no: nextIndexSeqNo };
    let updatedData2 = { seq_no: currentIndexSeqNo };
    let result1 = columnData.map((item) =>
      item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item
    );
    let result2 = result1.map((item) =>
      item.seq_no === nextIndexSeqNo ? { ...item, ...updatedData2 } : item
    );
    let result3 = result2.map((item) =>
      item.seq_no === 0 ? { ...item, ...updatedData1 } : item
    );

    console.log(result3);
    setColumnData([]);
    setTimeout(() => {
      setColumnData(result3.sort(({ seq_no: a }, { seq_no: b }) => a - b));
    });
  };

  const handleUpdateTemplate = (event) => {
    event.preventDefault();
    console.log(formData);
    axios
      .post(serverConfig.api_base_url + "dwh_template_update", {
        token: token,
        template: formData.template,
        name: formData.name,
        format: formData.format,
        stgTable: formData.stgTable,
        instructions: formData.instructions,
        rowsToSkip: formData.rowsToSkip,
        factTable: formData.factTable,
        expectedColumns: formData.expectedColumns,
      })
      .then((response) => {
        console.log("dwh_template_update:", response);
        if (response.data.status === 200) {
          handleShowAlertModal("Template updated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 3000);
          handleCloseEditTemplateModal();
          setRefreshTemplateList(!refreshTemplateList);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setFormMessage({});
        }
      })
      .catch((err) => console.log("error is", err));
  };

  //Delete
  const [deleteTemplateModalShow, setDeleteTemplateModalShow] = useState(false);
  const handleCloseDeleteTemplateModal = () => {
    setDeleteTemplateModalShow(false);
    setTemplateSlug("");
    setTemplateName("");
    setFormMessage({});
    setFormData({});
  };
  const handleShowDeleteTemplateModal = (templateSlug, templateName) => {
    setDeleteTemplateModalShow(true);
    setTemplateSlug(templateSlug);
    setTemplateName(templateName);

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
  };

  const handleDeleteTemplate = (event) => {
    event.preventDefault();
    axios
      .post(serverConfig.api_base_url + "dwh_template_delete", {
        token: token,
        template: templateSlug,
      })
      .then((response) => {
        console.log("dwh_template_delete:", response);
        if (response.data.status === 200) {
          handleShowAlertModal("Template deleted successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 3000);
          handleCloseDeleteTemplateModal();
          setRefreshTemplateList(!refreshTemplateList);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setFormMessage({});
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const [mapModalShow, setMapModalShow] = useState(false);

  //-----------------------Map Column List--------------------
  const handleFactTableDetails = (event) => {
    event.preventDefault();
    // axios
    //   .post(serverConfig.api_base_url + "dwh_template_set_mapping", {
    //     token: token,
    //     template: templateSlug,
    //     mappingDetails: mappingData,
    //   })
    //   .then((response) => {
    //     console.log("dwh_template_set_mapping:", response);
    //     if (response.data.status === 200) {
    //       setMappingData([]);
    //       handleShowAlertModal("Mapping saved successfully");
    //       setTimeout(() => {
    //         handleCloseAlertModal();
    //       }, 3000);
    //       handleCloseMapModal();
    //     } else if (response.data.status === 301) {
    //       handleLogout();
    //     } else {
    //       handleShowAlertModal(response.data.message, true);
    //     }
    //   })
    //   .catch((err) => console.log("error is", err));
  };

  const handleChange = (idx, targetCol, event) => {
    const name = event.target.name;
    const value = event.target.value;

    var updatedData = { [name]: value };
    var result;
    if (targetCol) {
      console.log(targetCol);
      result = mappingData.map((item) =>
        item.target_column === targetCol ? { ...item, ...updatedData } : item
      );
    } else {
      console.log(idx);
      result = mappingData.map((item, index) =>
        index === idx ? { ...item, ...updatedData } : item
      );
    }
    console.log(result);
    setMappingData(result);
  };

  //--------Handle Stage Table Details -> Add New Row (Popup 3) --------------
  const handleAddNewRow = () => {
    console.log(columnData);
    console.log(Math.max(...columnData.map((o) => o.seq_no)));
    var seqNo;
    if (columnData.length > 0) {
      seqNo = Math.max(...columnData.map((o) => o.seq_no)) + 1;
    } else {
      seqNo = 1;
    }
    console.log([
      ...columnData,
      {
        colm: null,
        column_name: null,
        column_type: null,
        data_type: null,
        description: null,
        length: null,
        seq_no: seqNo,
      },
    ]);
    setColumnData([
      ...columnData,
      {
        colm: null,
        column_name: null,
        column_type: null,
        data_type: null,
        description: null,
        length: null,
        seq_no: seqNo,
      },
    ]);
  };

  //--------Handle Fact Table Details -> Add New Row (Popup 4) --------------
  const handleAddNewFactRow = () => {
    console.log(factColumnData);
    console.log(Math.max(...factColumnData.map((o) => o.seq_no)));
    var seqNo;
    if (factColumnData.length > 0) {
      seqNo = Math.max(...factColumnData.map((o) => o.seq_no)) + 1;
    } else {
      seqNo = 1;
    }
    console.log([
      ...factColumnData,
      {
        colm: null,
        column_name: null,
        column_type: null,
        data_type: null,
        description: null,
        length: null,
        seq_no: seqNo,
      },
    ]);
    setFactColumnData([
      ...factColumnData,
      {
        colm: null,
        column_name: null,
        column_type: null,
        data_type: null,
        description: null,
        length: null,
        seq_no: seqNo,
      },
    ]);
  };

  //---------Handle Fact Table Details -> Save (Popup 4)----------------
  const handleFactTableUpdate = (event) => {
    event.preventDefault();
    setFactTableDetails(false); //Close Popup 4
    setConfirmationPopup(true); //Open Confirmation Popup
  };

  //--------------Handle Confirmation Popup -> Cancel Button ----------------------
  const handleConfirmationPopupCancel = () => {
    setFactTableDetails(true); //Open Popup 4
    setConfirmationPopup(false); //Close Confirmation Popup
  };

  //--------------Handle Confirmation Popup -> Save Button ----------------------
  const handleConfirmationPopupSave = async (event) => {
    event.preventDefault();
    console.log(columnData);
    console.log(formData);
    setConfirmationPopup(false);
    setMapStageToFact(true); //Open Popup 5

    try {
      // Serialize the column data to JSON

      const stgColumnDetails = columnData.map((item) => ({
        column_name: item.column_name,
        column_type: item.column_type,
        data_type: item.data_type,
        length: item.length,
        description: item?.description,
        seq_no: item.seq_no,
      }));

      const fctColumnDetails = factColumnData.map((item) => ({
        column_name: item.column_name,
        column_type: item.column_type,
        data_type: item.data_type,
        length: item.length,
        description: item?.description,
        seq_no: item.seq_no,
      }));

      // Create the request body
      const requestBody = {
        fctColumnDetails,
        stgColumnDetails,
        name: tableName,
        dbs: dbSlug,
        schema: scSlug,
        token: token || "null",
      };

      //Make a POST request to the desired URL with the request body
      const response = await axios
        .post(serverConfig.api_base_url + "dwh_temp_wiz_fct", requestBody, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.data.status === 200)
          setMapColumnItems(response.data.items);
          setMapData(response.data.items);
          {
            axios.post(serverConfig.api_base_url + "dwh_table_list_get", {
                token: token,
                dbs: dbSlug,
                schema: scSlug,
              })
              .then((response) => {
                console.log("dwh_table_list_get response:", response);
                if (response.data.status === 200) 
                {
                  let items = response.data.items;
                  if (items !== null || items !== undefined) 
                  {
                    console.log(response.data.items);
                    let appDataArray = response.data.items;

                    setDimTableList(appDataArray.filter((el) => el.table_type === "dimension"));
                    setFactTableList(appDataArray.filter((el) => el.table_type === "fact"));
                  }
                }
              }).catch((error) => {
                // Handle error
                console.error("Error fetching schema:", error);
              });


          }
          console.log('dwh_temp_wiz_fct', response);
          setTableColumns(response.data.items);
          // setStageToFactData
        })
        .catch((error) => {
          // Handle error
          console.error("Error fetching schema:", error);
          setIsLoading(false);
        });

      // Perform actions after successful table creation
      console.log(response);
      setFactColumnData([]);
      //setDbSlug("");
      //setScSlug("");
      setSchemaLookup([]);
      setTableLookup([]);
      setSelectedSchema({});
      setSelectedTable({});
      setColumnData([]);
      setDIFormInputs({});
      setFiledata(null);
      setDataCollectionDate(null);
      setFile("");
      setTableName("");
      setFormMessage({});
      setFormData({});
      // handleShowMapModal();
    } catch (error) {
      // Handle errors if the request fails
      console.error("Error:", error);
    }
  };

  //------------Handle Stage To Fact Data Changes------------
  const handleChangeMapInputs = (columnSlug, event) => {
    console.log(columnSlug);
    console.log(dbSlug);
    const name = event.target.name;
    const value = event.target.value;

    var updatedData;
    var result;

    if (name === "dim_table") {
      var dimColumnSel = event.target
        .closest("tr")
        .querySelector('select[name="dim_column"]');
      axios
        .post(serverConfig.api_base_url + "dwh_table_column_list_get", {
          token: token,
          dbs: dbSlug,
          tbl: value,
        })
        .then((response) => {
          console.log("dwh_table_column_list_get:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            dimColumnSel.length = 1;
            if (items !== null) {
              console.log(response.data.items);
              for (var i = 0; i < items.length; i++) {
                //console.log(items[i]);
                if (items[i].column_name !== "id") {
                  dimColumnSel.options[dimColumnSel.options.length] =
                    new Option(items[i]["column_name"], items[i]["colm"]);
                }
              }
            } else {
              //console.log(dimColumnSel.length);
              for (var i = 0; i <= dimColumnSel.length; i++) {
                //console.log(i);
                if (i !== 0) {
                  dimColumnSel.remove(i);
                }
              }
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setFormMessage({});
          }
        })
        .catch((err) => console.log("error is", err));
    }

    if (name === "fact_table") {
      var factColumnSel = event.target
        .closest("tr")
        .querySelector('select[name="fact_column"]');
      axios
        .post(serverConfig.api_base_url + "dwh_table_column_list_get", {
          token: token,
          dbs: dbSlug,
          tbl: value,
        })
        .then((response) => {
          console.log("dwh_table_column_list_get:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            factColumnSel.length = 1;
            if (items !== null) {
              console.log(response.data.items);
              for (var i = 0; i < items.length; i++) {
                //console.log(items[i]);
                if (items[i].column_name !== "id") {
                  factColumnSel.options[factColumnSel.options.length] =
                    new Option(items[i]["column_name"], items[i]["colm"]);
                }
              }
            } else {
              //console.log(factColumnSel.length);
              for (var i = 0; i <= factColumnSel.length; i++) {
                //console.log(i);
                if (i !== 0) {
                  factColumnSel.remove(i);
                }
              }
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setFormMessage({});
          }
        })
        .catch((err) => console.log("error is", err));
    }

    if (name === "fact_column") {
      updatedData = {
        [name]: event.target.options[event.target.options.selectedIndex].text,
        fact_colm: value,
      };
    } else if (name === "dim_column") {
      updatedData = {
        [name]: event.target.options[event.target.options.selectedIndex].text,
        dim_colm: value,
      };
    }

    if (updatedData) {
      //console.log(mapData.filter(el => el.colm === columnSlug).length, mapData.filter(el => el.colm === columnSlug));
      //console.log(mapColumnItems.filter(el => el.colm === columnSlug).length, mapColumnItems.filter(el => el.colm === columnSlug));

      //console.log(mapData);
      //console.log(tableColumns);

      if (mapData.filter((el) => el.colm === columnSlug).length > 0) {
        result = mapData.map((item) =>
          item.colm === columnSlug ? { ...item, ...updatedData } : item
        );
        //console.log(result);
        setMapData(result);
      } else {
        result = tableColumns
          .filter((el) => el.colm === columnSlug)
          .map((item) => ({ ...item, ...updatedData }));
        //console.log(result);
        //console.log([...mapData, ...result]);
        setMapData([...mapData, ...result]);
      }
    }
  };

  //-----------------Handle Stage to Fact -> Save Button (Popup 5)----------
  const handleStageToFactSave = (event) => {
    event.preventDefault();
    console.log(mapData);
    axios.post(serverConfig.api_base_url + "dwh_map_column_update",{token:token, dbs:dbSlug, items:mapData})
    .then(response=>{
      console.log("dwh_map_column_update:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Mapping successful", type:"success"});
        handleCloseMapModal();
        handleShowAlertModal('Columns mapped successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  };
  
  const handleCloseMapModal = () => {
    setMapStageToFact(false);
    setTableName("");
    setFormData({});
    setFormMessage({});
    setTableColumns([]);
    setMapColumnItems([]);
    setMapData([]);
    setDimTableList([]);
    setFactTableList([]);
  }

  const handleDeleteMapping = (targetCol) => {
    console.log(targetCol);

    let arr = mappingData;
    setMappingData([]);

    setTimeout(() => {
      setMappingData(arr.filter((el) => el.target_column !== targetCol));
    });

    setTargetList([...targetList, targetCol]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-mapmetadata mt-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            <h1 className="font-semibold text-2xl mt-4 mb-4">
              Template Wizard
            </h1>
          </div>
          <div className="me-5">
            <button
              className="sub-grp px-3 py-2"
              variant="text"
              title="Create Template"
              onClick={handleShowCreateTemplateModal}
            >
              New Template
            </button>
          </div>
        </div>
        <div className="flex flex-col h-3/4 items-center justify-center">
          <div className="mb-8">
            <img
              src={require("./../../Asset/Images/no-records.png")}
              alt="No records"
            />
          </div>
          <p className="text-sm">
            <b>Create New Template for {selectedOrganization?.name}</b>
          </p>
          <div className="mt-5">
            <button
              className="sub-grp px-3 py-2"
              onClick={handleShowCreateTemplateModal}
            >
              New Template
            </button>
          </div>
        </div>

        {/*-----------------Create New Template - Popup 1------------------- */}
        {createTemplateModalShow && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Template Details</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleCreateTemplateSubmit}
              >
                <div className="scrollable mt-5 mb-5">
                  <div>
                    <div className="flex flex-col gap-1 text-xs">
                      <label>
                        Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={formData.name || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        placeholder="Name"
                        className="custom-input py-2 px-2 text-left mt-1"
                        style={{ width: "auto" }}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-1 mt-3 mb-5 text-xs">
                      <label>
                        File Format<span className=" text-red-500">*</span>
                      </label>
                      <select
                        value={formData.format || ""}
                        className="custom-input py-2 px-2 text-left mt-1"
                        style={{ width: "auto" }}
                        onChange={(e) =>
                          setFormData({ ...formData, format: e.target.value })
                        }
                        required
                      >
                        <option value="">File Format</option>
                        {fileFormatLookupList.length > 0 &&
                          fileFormatLookupList.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.value}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="flex flex-col gap-1 mt-3 mb-5 text-xs">
                      <label>
                        Description<span className=" text-red-500">*</span>
                      </label>
                      <textarea
                        value={formData.td_description || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            td_description: e.target.value,
                          })
                        }
                        placeholder="Description"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      ></textarea>
                    </div>
                    <div className="flex flex-col gap-1 mt-3 mb-5 text-xs">
                      <label>
                        Instructions<span className=" text-red-500">*</span>
                      </label>
                      <textarea
                        value={formData.instructions || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            instructions: e.target.value,
                          })
                        }
                        placeholder="Instructions"
                        className="custom-input py-2 px-2 text-left mt-1"
                        required
                      ></textarea>
                    </div>
                    {/* <div className="flex flex-col gap-1 mt-3 mb-5 text-xs">
                      <label>
                        Rows to Skip<span className=" text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        min="0"
                        value={formData.rowsToSkip || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            rowsToSkip: e.target.value,
                          })
                        }
                        placeholder="Rows to Skip"
                        className="custom-input py-2 px-2 text-left mt-1"
                        style={{ width: "auto" }}
                        required
                      />
                    </div> */}
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseCreateTemplateModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    // onClick={handleTemplateUploadFile}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/*-----------------Upload File - Popup 2----------------------------*/}
        {uploadTemplateFile && (
          <div className="overlay">
            <div className="import-data  rounded-3xl py-4 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col">
              <div className={template ? `overflow-scroll` : ``}>
                {isLoading === false ? (
                  DIFormDisabled || showUploadErrorList === true ? (
                    <>
                      <div className="overflow-y-auto ">
                        <div className="flex items-center gap-8">
                          <div className="flex flex-col team-label">
                            <h5 className="mt-4 font-semibold mb-4">
                              {uploadErrorList.length}
                              {uploadErrorList.length >= 50 ? "+" : null} errors
                              found, please correct and re-upload the file
                            </h5>

                            {uploadErrorList.length > 0 &&
                              uploadErrorList.map((item, index) => {
                                return (
                                  <>
                                    {item?.message_type === "I" ||
                                    item.message_type === "W" ? (
                                      <div className="flex flex-row warning-text mt-2">
                                        <img
                                          src={require("../assets/success.png")}
                                          className="success-image"
                                        />

                                        <div className="warning">
                                          {item?.message}
                                        </div>
                                      </div>
                                    ) : item?.message_type === "E" ? (
                                      <div className="flex flex-row error-text mt-2">
                                        <img
                                          src={require("../assets/error.png")}
                                          height={30}
                                        />

                                        <div className="errors">
                                          {item?.message}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-row  gap-6">
                        <div className="flex flex-col team-label">
                          <h5 className="normal">Upload File</h5>
                          <>
                            <Form.Group
                              controlId="uploadData"
                              className="upload-data mb-6 mt-1"
                            >
                              <Form.Label className="browse-files">
                                {DIFormInputs.file ? (
                                  <>
                                    <img
                                      src={require("../assets/cloud_upload.png")}
                                      alt="Upload Data"
                                      className="mb-3"
                                    />
                                    <br />
                                    {DIFormInputs.file}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={require("../assets/cloud_upload.png")}
                                      alt="Upload Data"
                                      height={95}
                                      className="mb-3"
                                    />
                                    <br />
                                    <p className="files-upload">
                                      Drag &amp; Drop or click here to &nbsp;{" "}
                                      <p className="template"> Browse</p>
                                    </p>
                                  </>
                                )}
                              </Form.Label>
                              <Form.Control
                                name="file"
                                type="file"
                                accept=".xls, .csv, .xlsx"
                                filename={DIFormInputs.file || ""}
                                onChange={handleChangeDIFormInputs}
                                required
                              />
                            </Form.Group>

                            <Form.Group
                              controlId="tableName"
                              className="txt-gray"
                            >
                              <Form.Label>
                                <b>Table Name:</b>
                                <span className="text-red-500">*</span>
                              </Form.Label>
                              <Form.Control
                                className="user-comments mb-6 mt-1"
                                name="tableName"
                                type="text"
                                value={tableName || ""}
                                onChange={handleTableName}
                                required
                              />
                            </Form.Group>

                            {templateParameter.map((item, index) => {
                              return (
                                <Form.Group
                                  className="mb-3 flex flex-col"
                                  key={index}
                                >
                                  <Form.Label className="date-label">
                                    {item.display_text}:{" "}
                                    <span className="txt-red">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    className="date"
                                    type={item.name}
                                    required
                                    onChange={handleChangeDIFormInputs}
                                    name="dataCollectionDate"
                                  ></Form.Control>
                                  <Form.Text className="text-muted">
                                    {item.instruction}
                                  </Form.Text>
                                </Form.Group>
                              );
                            })}

                            {templateInstructions && (
                              <div className="txt-gray">
                                <p className="mt-4 mb-4 normal">
                                  <span className="me-3">*</span>Key
                                  instructions while uploading the file
                                </p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: templateInstructions,
                                  }}
                                ></div>
                              </div>
                            )}
                          </>
                        </div>

                        <div className="upload-gif">
                          <img src={require("../assets/upload-img.png")} />
                        </div>
                      </div>
                    </>
                  )
                ) : showUploadFail === true ? (
                  <div className="uploading-div flex flex-col items-center">
                    <h1 className="mt-4 failed-msg">File upload failed</h1>
                    <div className="flex flex-row gap-4">
                      <div>
                        <img
                          src={require(`../assets/failed-icon.png`)}
                          alt=""
                          className="success-img"
                        />
                        <h1 className="mt-4 mb-4 success-text">
                          <b>
                            File upload failed <br /> due to one or more errors.{" "}
                            <br />
                            Please rectify and reupload.
                          </b>
                        </h1>
                      </div>

                      <div>
                        <div className="list-container">
                          {uploadErrorList.length > 0 &&
                            uploadErrorList.map((item, index) => {
                              return (
                                <>
                                  {item?.message_type === "I" ||
                                  item.message_type === "W" ? (
                                    <div className="flex flex-row warning-text mt-2">
                                      <div className="warning">
                                        {item?.message}
                                      </div>
                                    </div>
                                  ) : item?.message_type === "E" ? (
                                    <div className="flex flex-row error-text mt-2">
                                      <div className="errors">
                                        {item?.message}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-end done-btn">
                      <button
                        type="submit"
                        className="cancel-btn-popup mt-10 px-4 py-2 rounded-lg text-sm text-medium"
                        onClick={handleUploadFileCancelButton}
                      >
                        Back to Upload
                      </button>
                    </div>
                  </div>
                ) : showUploadSuccess === true ? (
                  <>
                    <div className="uploading-div flex flex-col items-center">
                      <h1 className="mt-4 failed-msg">File upload Success</h1>
                      <div className="flex flex-row gap-4">
                        <div>
                          <img
                            src={require(`../assets/uploadSuccess.png`)}
                            alt=""
                            className="success-img"
                          />
                          <h1 className="mt-4 mb-4 success-text">
                            <b>Success!</b>
                          </h1>
                        </div>
                        <div className="list-container">
                          {uploadErrorList.length > 0 ? (
                            uploadErrorList.map((item, index) => {
                              return (
                                <div>
                                  <>
                                    {item?.message_type === "I" ||
                                    item.message_type === "W" ? (
                                      <div className="flex flex-row warning-text mt-2">
                                        <div className="warning">
                                          {item?.message}
                                        </div>
                                      </div>
                                    ) : item?.message_type === "E" ? (
                                      <div className="flex flex-row error-text mt-2">
                                        <div className="errors">
                                          {item?.message}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row  justify-end done-btn">
                        <button
                          type="submit"
                          className="cancel-btn-popup mt-10 px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                          onClick={handleUploadFileCancelButton}
                        >
                          Back to Upload
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="uploading-div flex flex-col items-center">
                      <div className="flex flex-col">
                        {uploadErrorList.length > 0 ? (
                          <>
                            <div className="uploading-div flex flex-col items-center">
                              <h1 className="mt-4 failed-msg">
                                Uploading File
                              </h1>
                              <div className="flex flex-row gap-4">
                                <div>
                                  <img
                                    src={require(`../assets/invitingUser.png`)}
                                    alt=""
                                    className="success-img"
                                  />
                                </div>
                                <div className="list-container">
                                  {uploadErrorList.map((item, index) => {
                                    return (
                                      <>
                                        {item?.message_type === "I" ||
                                        item.message_type === "W" ? (
                                          <div className="flex flex-row warning-text mt-2">
                                            <div className="warning">
                                              {item?.message}
                                            </div>
                                          </div>
                                        ) : item?.message_type === "E" ? (
                                          <div className="flex flex-row error-text mt-2">
                                            <div className="errors">
                                              {item?.message}
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="mt-4 font-medium">
                                Note: Closing this window will not terminate the
                                process. You can check the logs from View Log
                                option.
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="loadingg-div flex flex-col">
                              {errorMessage !== "" ? (
                                <p className="error-display">{errorMessage}</p>
                              ) : (
                                <></>
                              )}
                              <img
                                src={require(`../assets/invitingUser.png`)}
                                alt=""
                                className="loading-img"
                              />
                              <h1 className=" mt-8">
                                <b>Your data is uploading...</b>
                              </h1>
                              <div className="mt-4 font-medium">
                                Note: Closing this window will not terminate the
                                process. You can check the logs from View Log
                                option.
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row justify-center done-btn">
                      <button
                        type="submit"
                        className="cancel-btn-popup mt-10 px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                        onClick={handleUploadFileCancelButton}
                      >
                        Back to Upload
                      </button>
                    </div>
                  </>
                )}
              </div>

              {isLoading === false ? (
                <div className="flex flex-row justify-end">
                  <button
                    type="submit"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleUploadFileCancelButton}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleUploadFileNextButton}
                  >
                    Infer Schema
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}

        {/*-----------------Stage Table Details - Popup 3--------------------*/}
        {stageTableDetails && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-3/4">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Stage Table Details</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleMapToStage}
              >
                <div className="scrollable mt-5 mb-5">
                  <div>
                    <section className="flex flex-row gap-1">
                      <div className="basis-1/2">
                        <div className="flex flex-col gap-1 mt-4 text-xs">
                          <label>Name : stg_{tableName}</label>
                        </div>
                        <div className="flex flex-col gap-1 mt-3 text-xs">
                          <select
                            name="Database Service"
                            defaultValue=""
                            className="custom-metadata-input py-2 px-2 text-left mt-1 mr-2"
                            onChange={(e) => {
                              const selectedOption = JSON.parse(e.target.value);
                              console.log(selectedOption.value); // Access the value attribute
                              console.log(selectedOption.slug); // Access the slug attribute
                              setSelectedDbService(selectedOption?.value);
                              setDbSlug(selectedOption.slug);
                            }}
                            required
                          >
                            <option value="">Select Database Service</option>
                            {dbServiceLookup?.length > 0 &&
                              dbServiceLookup?.map((item, index) => (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                >
                                  {item.value}
                                </option>
                              ))}
                          </select>

                          <select
                            name="Schema"
                            defaultValue=""
                            className="custom-metadata-input py-2 px-2 text-left mt-1 mr-2"
                            onChange={(e) => {
                              const selectedOption = JSON.parse(e.target.value);
                              console.log(selectedOption.value); // Access the value attribute
                              console.log(selectedOption.slug); // Access the slug attribute
                              setSelectedSchema(selectedOption?.value);
                              setScSlug(selectedOption.slug);
                            }}
                            required
                          >
                            <option value="">Select Schema</option>
                            {schemaLookup?.length > 0 &&
                              schemaLookup?.map((item, index) => (
                                <option
                                  value={JSON.stringify(item)}
                                  key={index}
                                >
                                  {item.value}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="basis-1/2">
                        <div className="flex flex-col gap-1 mt-4 text-xs">
                          <label>Description</label>
                          <textarea
                            rows="4"
                            value={formData.description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              })
                            }
                            placeholder="Description"
                            className="custom-input py-2 px-2 text-left mt-1"
                          ></textarea>
                        </div>
                      </div>
                    </section>
                    <section className="mt-5">
                      <h5 className="font-semibold text-base">Columns</h5>
                      {(formData.table_type === "fact" ||
                        formData.table_type === "dimension") && (
                        <p className="my-2">
                          <b>
                            The identifier column is mandatory for table
                            creation. It cannot be deleted.
                          </b>
                        </p>
                      )}
                      <div className="flex flex-row gap-1 items-center">
                        <div className="basis-1/5">
                          <span className="font-bold">Name</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Column Type</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Data Type</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Length</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Description</span>
                        </div>
                        <div className="basis-auto">
                          <button type="button" className="invisible">
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </div>
                      </div>
                      {columnData &&
                        columnData.length > 0 &&
                        columnData.map((item, index) => (
                          <div
                            key={index}
                            className="flex flex-row gap-1 items-row"
                          >
                            <div className="basis-1/5">
                              <input
                                name="column_name"
                                defaultValue={item.column_name}
                                type="text"
                                className="stg-columns py-2 px-2 text-left mt-1"
                                onChange={(e) =>
                                  handleEdit(item.seq_no, item.colm, e)
                                }
                                required
                              />
                            </div>
                            <div className="basis-1/5">
                              {/* Render column type select based on the data */}
                              <select
                                name="column_type"
                                defaultValue={item.column_type}
                                className="stg-columns py-2 px-2 text-left mt-1"
                                onChange={(e) =>
                                  handleEdit(item.seq_no, item.colm, e)
                                }
                                required
                                disabled={item.is_identity === 1}
                              >
                                <option value="">Column Type</option>
                                {columnTypeLookupList.length > 0 &&
                                  columnTypeLookupList.map((colType, index) => (
                                    <option value={colType.value} key={index}>
                                      {colType.value}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="basis-1/5">
                              {/* Render data type select based on the data */}
                              <select
                                name="data_type"
                                defaultValue={item.data_type}
                                className="stg-columns py-2 px-2 text-left mt-1"
                                onChange={(e) =>
                                  handleEdit(item.seq_no, item.colm, e)
                                }
                                required
                                disabled={item.is_identity === 1}
                              >
                                <option value="">Data Type</option>
                                {dataTypeLookupList.length > 0 &&
                                  dataTypeLookupList.map((dataType, index) => (
                                    <option value={dataType.value} key={index}>
                                      {dataType.value}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="basis-1/5">
                              {/* Render length input based on the data */}
                              <input
                                name="length"
                                defaultValue={item.length}
                                type="number"
                                min="0"
                                className="stg-columns py-2 px-2 text-left mt-1"
                                onChange={(e) =>
                                  handleEdit(item.seq_no, item.colm, e)
                                }
                                disabled={item.data_type !== "varchar"}
                                required={item.data_type === "varchar"}
                              />
                            </div>
                            <div className="basis-1/5">
                              {/* Render description textarea based on the data */}
                              <textarea
                                name="description"
                                defaultValue={item.description}
                                className="stg-columns py-2 px-2 text-left mt-1"
                                onChange={(e) =>
                                  handleEdit(item.seq_no, item.colm, e)
                                }
                                disabled={item.is_identity === 1}
                              ></textarea>
                            </div>
                            <div className="basis-auto more-options-wrapper">
                              {item.is_identity === 1 ? (
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip" className="text-sm">
                                      This is an identifier column
                                    </Tooltip>
                                  }
                                >
                                  <Button className="ms-2 info-icon">i</Button>
                                </OverlayTrigger>
                              ) : (
                                <button
                                  type="button"
                                  onClick={handleShowMoreOptions}
                                  title="More"
                                  className="btn-more-options"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                                </button>
                              )}
                              <div
                                className="more-options"
                                style={{ display: "none" }}
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDeleteColumn(item.seq_no, item.colm)
                                  }
                                >
                                  <i className="fa-regular fa-trash-can"></i>{" "}
                                  <span>Delete</span>
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleMoveUpColumn(item.seq_no)
                                  }
                                  disabled={index === 0}
                                >
                                  <i className="fa-solid fa-arrow-up-long"></i>{" "}
                                  <span>Move Up</span>
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleMoveDownColumn(item.seq_no)
                                  }
                                  disabled={index + 1 === columnData.length}
                                >
                                  <i className="fa-solid fa-arrow-down-long"></i>{" "}
                                  <span>Move Down</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                      <button
                        type="button"
                        className="btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                        onClick={handleAddNewRow}
                      >
                        <i
                          className="fa-solid fa-plus not-0"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </section>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleStageTableDetailsCancelButton}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/*-----------------Fact Table Details - Popup 4-------------------- */}
        {factTableDetails && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-3/4">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Fact Table Details</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleFactTableUpdate}
              >
                <div className="scrollable mt-5 mb-5">
                  <div>
                    <section className="flex flex-row gap-1">
                      <div className="basis-1/2">
                        <div className="flex flex-col gap-1 mt-4 text-xs">
                          <label>Name : fct_{tableName}</label>
                        </div>
                        <div className="flex flex-col gap-1 mt-3 text-xs">
                          <label>Table Type : Fact</label>
                        </div>
                        <div className="flex flex-col gap-1 mt-3 text-xs">
                          <label>
                            Selected Database Service : {selectedDbService}
                          </label>
                        </div>
                        <div className="flex flex-col gap-1 mt-3 text-xs">
                          <label>Selected Schema : {selectedSchema}</label>
                        </div>
                      </div>
                      <div className="basis-1/2">
                        <div className="flex flex-col gap-1 mt-4 text-xs">
                          <label>Description</label>
                          <textarea
                            rows="4"
                            value={formData.fct_description || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                fct_description: e.target.value,
                              })
                            }
                            placeholder="Description"
                            className="custom-input py-2 px-2 text-left mt-1"
                            style={{ minHeight: "126px" }}
                          ></textarea>
                        </div>
                      </div>
                    </section>
                    <section className="mt-5">
                      <h5 className="font-semibold text-base">Columns</h5>
                      {(formData.table_type === "fact" ||
                        formData.table_type === "dimension") && (
                        <p className="my-2">
                          <b>
                            The identifier column is mandatory for table
                            creation. It cannot be deleted.
                          </b>
                        </p>
                      )}
                      <div className="flex flex-row gap-1 items-center">
                        <div className="basis-1/5">
                          <span className="font-bold">Name</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Column Type</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Data Type</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Length</span>
                        </div>
                        <div className="basis-1/5">
                          <span className="font-bold">Description</span>
                        </div>
                        <div className="basis-auto">
                          <button type="button" className="invisible">
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        </div>
                      </div>
                      {factColumnData &&
                        factColumnData.length > 0 &&
                        factColumnData.map((items, index) => {
                          return (
                            <div
                              key={index}
                              className="flex flex-row gap-1 items-row"
                            >
                              <div className="basis-1/5">
                                <input
                                  name="column_name"
                                  defaultValue={items.column_name}
                                  type="text"
                                  className="fct-columns py-2 px-2 text-left mt-1"
                                  onChange={(e) =>
                                    handleFactEdit(items.seq_no, items.colm, e)
                                  }
                                  required
                                />
                              </div>
                              <div className="basis-1/5">
                                <select
                                  name="column_type"
                                  defaultValue={items.column_type}
                                  className="fct-columns py-2 px-2 text-left mt-1"
                                  onChange={(e) =>
                                    handleFactEdit(items.seq_no, items.colm, e)
                                  }
                                  required
                                  disabled={items.is_identity === 1}
                                >
                                  <option value="">Column Type</option>
                                  {columnTypeLookupList.length > 0 &&
                                  formData.table_type === "dimension"
                                    ? columnTypeLookupList
                                        .filter(
                                          (el) =>
                                            el.value === "id" ||
                                            el.value === "dimension"
                                        )
                                        .map((item, index) => (
                                          <option
                                            value={item.value}
                                            key={index}
                                          >
                                            {item.value}
                                          </option>
                                        ))
                                    : columnTypeLookupList.map(
                                        (item, index) => (
                                          <option
                                            value={item.value}
                                            key={index}
                                          >
                                            {item.value}
                                          </option>
                                        )
                                      )}
                                </select>
                              </div>
                              <div className="basis-1/5">
                                <select
                                  name="data_type"
                                  defaultValue={items.data_type}
                                  className="fct-columns py-2 px-2 text-left mt-1"
                                  onChange={(e) =>
                                    handleFactEdit(items.seq_no, items.colm, e)
                                  }
                                  required
                                  disabled={items.is_identity === 1}
                                >
                                  <option value="">Data Type</option>
                                  {dataTypeLookupList.length > 0 &&
                                    dataTypeLookupList.map((item, index) => (
                                      <option value={item.value} key={index}>
                                        {item.value}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="basis-1/5">
                                <input
                                  name="length"
                                  defaultValue={items.length}
                                  type="number"
                                  min="0"
                                  className="fct-columns py-2 px-2 text-left mt-1"
                                  onChange={(e) =>
                                    handleFactEdit(items.seq_no, items.colm, e)
                                  }
                                  disabled={items.data_type !== "varchar"}
                                  required={items.data_type === "varchar"}
                                />
                              </div>
                              <div className="basis-1/5">
                                <textarea
                                  name="description"
                                  defaultValue={items.description}
                                  className="fct-columns py-2 px-2 text-left mt-1"
                                  onChange={(e) =>
                                    handleFactEdit(items.seq_no, items.colm, e)
                                  }
                                  disabled={items.is_identity === 1}
                                ></textarea>
                              </div>
                              <div className="basis-auto more-options-wrapper">
                                {items.is_identity === 1 ? (
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id="tooltip" className="text-sm">
                                        This is an identifier column
                                      </Tooltip>
                                    }
                                  >
                                    <Button className="ms-2 info-icon">
                                      i
                                    </Button>
                                  </OverlayTrigger>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={handleShowMoreOptions}
                                    title="More"
                                    className="btn-more-options"
                                  >
                                    <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                                  </button>
                                )}
                                <div
                                  className="more-options"
                                  style={{ display: "none" }}
                                >
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleDeleteColumn(
                                        items.seq_no,
                                        items.colm
                                      )
                                    }
                                  >
                                    <i className="fa-regular fa-trash-can"></i>{" "}
                                    <span>Delete</span>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleMoveUpColumn(items.seq_no)
                                    }
                                    disabled={index === 0}
                                  >
                                    <i className="fa-solid fa-arrow-up-long"></i>{" "}
                                    <span>Move Up</span>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleMoveDownColumn(items.seq_no)
                                    }
                                    disabled={
                                      index + 1 === factColumnData.length
                                    }
                                  >
                                    <i className="fa-solid fa-arrow-down-long"></i>{" "}
                                    <span>Move Down</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <button
                        type="button"
                        className="btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                        onClick={handleAddNewFactRow}
                      >
                        <i
                          className="fa-solid fa-plus not-0"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </section>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseFactTableDetails}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/*-----------------Confirmation Popup -------------------- */}
        {confirmationPopup && (
          <div className="overlay">
            <div
              className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-3/4"
              style={{
                lineHeight: "60px",
                width: "415px",
              }}
            >
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleConfirmationPopupSave}
              >
                <div style={{ fontSize: 16, fontWeight: "bold" }}>
                  Are you sure you want to create the tables?
                </div>
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleConfirmationPopupCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/*-------------- Map Stage to Fact -> Popup 5------------------------*/}
        {mapStageToFact && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>
                  Map <b>{tableName}</b>
                </div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleStageToFactSave}
              >
                <div className="scrollable mt-5 mb-5">
                  <div>
                    <table>
                      <thead className="sticky top-0">
                        <tr>
                          <th className="px-2 py-1 text-left">Column Name</th>
                          <th className="px-2 py-1 text-left">Column Type</th>
                          <th className="px-2 py-1 text-left">Fact Table</th>
                          <th className="px-2 py-1 text-left">Fact Column</th>
                          <th className="px-2 py-1 text-left">Dim Table</th>
                          <th className="px-2 py-1 text-left">Dim Column</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableColumns &&
                          tableColumns.length > 0 &&
                          tableColumns.map((item, index) => {
                            let typeClass;
                            if (item.column_type === "dimension") {
                              typeClass = "bg-bdr bg-bdr-peach justify-center";
                            } else if (item.column_type === "measurement") {
                              typeClass = "bg-bdr bg-bdr-green justify-center";
                            } else if (item.column_type === "date") {
                              typeClass = "bg-bdr bg-bdr-yellow justify-center";
                            } else if (item.column_type === "information") {
                              typeClass = "bg-bdr bg-bdr-purple justify-center";
                            }

                            let factTable = mapColumnItems
                              .filter((el) => el.colm === item.colm)
                              .map((obj) => obj.fact_table);
                            let factColm = mapColumnItems
                              .filter((el) => el.colm === item.colm)
                              .map((obj) => obj.fact_colm);
                            let factColumnList = mapColumnItems
                              .filter((el) => el.colm === item.colm)
                              .map((obj) => obj.fact_column_list);
                            let dimTable = mapColumnItems
                              .filter((el) => el.colm === item.colm)
                              .map((obj) => obj.dim_table);
                            let dimColm = mapColumnItems
                              .filter((el) => el.colm === item.colm)
                              .map((obj) => obj.dim_colm);
                            let dimColumnList = mapColumnItems
                              .filter((el) => el.colm === item.colm)
                              .map((obj) => obj.dim_column_list);

                            return (
                              <tr key={index}>
                                <td className="px-2 py-1 text-left">
                                  {item.column_name}
                                </td>
                                <td className="px-2 py-1 text-left">
                                  <div className={typeClass}>
                                    {item.column_type}
                                  </div>
                                </td>
                                <td className="px-2 py-1 text-left">
                                  {factTableList.length > 0 ? (
                                    <select
                                      name="fact_table"
                                      defaultValue={factTable || ""}
                                      onChange={(e) =>
                                        handleChangeMapInputs(item.colm, e)
                                      }
                                      className="custom-input py-2 px-2 text-left mt-1"
                                    >
                                      <option value="">Select</option>
                                      {factTableList.map((fitem, findex) => (
                                        <option value={fitem.tbl} key={findex}>
                                          {fitem.name}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <span className="text-red-500 text-xs">
                                      Create Fact Table first
                                    </span>
                                  )}
                                </td>
                                <td className="px-2 py-1 text-left">
                                  <select
                                    name="fact_column"
                                    defaultValue={factColm || ""}
                                    onChange={(e) =>
                                      handleChangeMapInputs(item.colm, e)
                                    }
                                    className="custom-input py-2 px-2 text-left mt-1"
                                    required
                                  >
                                    <option value="">
                                      Select Fact Table first
                                    </option>
                                    {factColumnList[0] &&
                                      factColumnList[0].length > 0 &&
                                      factColumnList[0].map(
                                        (fcitem, fcindex) =>
                                          fcitem.column_name !== "id" && (
                                            <option
                                              value={fcitem.colm}
                                              key={fcindex}
                                            >
                                              {fcitem.column_name}
                                            </option>
                                          )
                                      )}
                                  </select>
                                </td>
                                <td className="px-2 py-1 text-left">
                                  {dimTableList.length > 0 ? (
                                    <select
                                      name="dim_table"
                                      defaultValue={dimTable || ""}
                                      onChange={(e) =>
                                        handleChangeMapInputs(item.colm, e)
                                      }
                                      className="custom-input py-2 px-2 text-left mt-1"
                                      disabled={
                                        item.column_type !== "dimension"
                                      }
                                    >
                                      <option value="">Select</option>
                                      {dimTableList.map((ditem, dindex) => (
                                        <option value={ditem.tbl} key={dindex}>
                                          {ditem.name}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <span className="text-red-500 text-xs">
                                      Create Dimension Table first
                                    </span>
                                  )}
                                </td>
                                <td className="px-2 py-1 text-left">
                                  <select
                                    name="dim_column"
                                    defaultValue={dimColm || ""}
                                    onChange={(e) =>
                                      handleChangeMapInputs(item.colm, e)
                                    }
                                    className="custom-input py-2 px-2 text-left mt-1"
                                    disabled={item.column_type !== "dimension"}
                                    required={item.column_type === "dimension"}
                                  >
                                    <option value="">
                                      Select Dim Table first
                                    </option>
                                    {dimColumnList[0] &&
                                      dimColumnList[0].length > 0 &&
                                      dimColumnList[0].map(
                                        (dcitem, dcindex) =>
                                          dcitem.column_name !== "id" && (
                                            <option
                                              value={dcitem.colm}
                                              key={dcindex}
                                            >
                                              {dcitem.column_name}
                                            </option>
                                          )
                                      )}
                                  </select>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseMapModal}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateWizardManagement;
