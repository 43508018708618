import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../../../contextApi/context.js";

const serverConfig = require("../../../config/server.js");

const IssueRelations = ({token, handleCloseAlertModal, handleShowAlertModal, handleLogout}) => {

    const {selectedOrganization} = useContext(Context);
    const [issueRelations, setIssueRelations] = useState([]);
    const [createIssueRelation, setCreateIssueRelation] = useState(false);
    const [deleteIssueRelation, setDeleteIssueRelation] = useState(false);
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');

    const getIssueRelationsList = () => {
        axios.post(serverConfig.api_base_url + "issue_relations_get_list", {
            token: token,
            org: selectedOrganization?.slug
        }).then(response=> {
                if(response.data.status === 200) {                  
                    setIssueRelations(response.data.items);
                }
            }).catch(err => {
                console.log(err);
            })
    }

    const handleDeleteStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_relations_delete", {
                token: token,
                org: selectedOrganization?.slug,
                rel: slug
            }).then(response=> {
                console.log(response.data);
                if(response.data.status === 200) {
                    setName('');
                    setDeleteIssueRelation(false);
                    handleShowAlertModal('Issue relation deleted successfully');
                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
        }
        }

    const handleCreate = (e) => {
        e.preventDefault();
        axios.post(serverConfig.api_base_url + "issue_relations_create", {
            token: token,
            org: selectedOrganization?.slug, 
            name: name
        }).then(response => {
            if(response.data.status === 200) {
                setCreateIssueRelation(false);
                handleShowAlertModal('Issue relation added successfully');
                setName('');
                setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                handleShowAlertModal(response.data.message, true);
                setTimeout(() => {
                  handleCloseAlertModal();
                }, 6000);
              }
        }).catch(err=> {
            console.log(err);
            handleShowAlertModal(err, true);
            setTimeout(() => {
                handleCloseAlertModal();
                }, 6000);
            });
    }

    useEffect(() => {

        getIssueRelationsList();


    }, [createIssueRelation, deleteIssueRelation]);

    return(<>

<div className=" table-title rounded ">
              <div className="flex items-center mb-4
              
              ">
                {/* Left side: Three tabs flexed together */}
                <div className="flex space-x-4">
                  <h4 className='font-bold text-base'>Issue Relations</h4>
                </div>
                <span className="ml-2 mt-0.5" onClick={()=>{setCreateIssueRelation(true);}}>
                <i className="fa-solid fa-plus text-base mr-3 font-semibold cursor-pointer" aria-hidden="true" style={{"color" : "var(--Text-medium-emphasis, #09082699)"}}></i>
                </span>               
              </div>
              <div className="flex flex-col">
                {issueRelations.map(item => {
                    return(
                        <div className="flex flex-row">
                        <div className="relation-container">
                            {item?.name}
                        </div>
                        {item?.name !== 'Predecessor' && item?.name !== 'Blocked By' &&
                        <div onClick={()=> {setDeleteIssueRelation(true); setName(item?.name); setSlug(item?.rel);}}>
                        <i className="fa-solid fa-xmark text-base font-semibold ml-2 mt-2 cursor-pointer" style={{"color" : "var(--Text-medium-emphasis, #09082699)"}}></i>
                            </div> }
                        </div>
                    )
                })}
              </div>

              {createIssueRelation && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Add Issue Relations</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreate}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" style={{"width" : "auto"}} value={name || ""} onChange={(e)=> { setName(e.target.value) }} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' required/>
                  </div>
                </div>
 
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setCreateIssueRelation(false); setName('');}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}

        {deleteIssueRelation && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={(e)=>{handleDeleteStatus(e);}}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Delete Issue Relation</div>
                <button className='my-2 title-text-small-color' onClick={()=>{setDeleteIssueRelation(false)}}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Are you sure you want to delete “<b>{name}</b>“? <br/>
                This action cannot be undone.</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={()=> {setDeleteIssueRelation(false); setName('');}}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs'>Delete Issue Relation</button>
            </div>
          </form>
        </div>}

            </div>

    </>)

}

export default IssueRelations;