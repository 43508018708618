import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@material/web/button/filled-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/checkbox/checkbox.js';
import UserService from "./services/UserService";
import axios from 'axios';
const serverConfig = require("./config/server.js");

const root = createRoot(document.getElementById('root'));
// root.render(
  
//     <App />

// );

const renderApp = () => createRoot(document.getElementById('root')).render(<App/>);
console.log(serverConfig.api_base_url, serverConfig.api_base_url + "domain_sso_details_get");

axios.post(serverConfig.api_base_url + "domain_sso_details_get", {domainName: window.location.host})
  .then(response=>{
    console.log("domain_sso_details_get response", response);
    if (response.data.status === 200) {
      if(response.data.items !== null){
        //alert(response.data.items);
        localStorage.setItem("ssoDetails", JSON.stringify({
          url: response.data.items.sso_url+"/",
          realm: response.data.items.sso_realm,
          clientId: response.data.items.sso_client_id,
        }));
        console.log(JSON.parse(localStorage.getItem("ssoDetails")));
        UserService.initKeycloak(renderApp);
      }
    }
  })
  .catch(err=>console.log("error is",err));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
