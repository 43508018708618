import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import UserService from '../../services/UserService';
import "../css/access.css";
import Notification from '../../common/notification/Notification';

const serverConfig = require("../../config/server.js");

const SubItemsMap = ({accessSlug, access}) => {
  const {selectedOrganization, isDarkTheme} = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [accessTypeData, setAccessTypeData] = useState([]);
  const [accessTypeFormData, setAccessTypeFormData] = useState({});
  const [accessFlowFormData, setAccessFlowFormData] = useState({});
  const [refreshAccessList, setRefreshAccessList] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [columnDataRevoke, setColumnDataRevoke] = useState([]);
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshContextList, setRefreshContextList] = useState(false);
  const [memberLookup, setMemberLookup] = useState(false);
  const [memberSelected, setMemberSelected] = useState(false);
  const [memberSelectedRevoke, setMemberSelectedRevoke] = useState(false);
  const [sequenceNo, setSequenceNo] = useState(0);
  const [sequenceNoRevoke, setSequenceNoRevoke] = useState(0);
  const [accessFlowList, setAccessFlowList] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);
      /* dwh_schema_list_get */
      axios.post(serverConfig.api_base_url + "access_context_list_get",{token:token, acc:accessSlug})
      .then(response=>{
        console.log("access_context_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(response.data.items);
            let appDataArray = response.data.items;
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              {item.actions = (<div className="text-right  ml-4">
                <Button variant='text' className='mr-2' title="Edit" onClick={() => handleShowEditContextModal(item)}><i className="fa-solid fa-pencil not-0" aria-hidden="true"></i></Button>
                <Button variant='text' title="Delete" onClick={() => handleShowDeleteContextModal(item?.context, item?.slug)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i></Button>
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setTableData(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
    
  }, [token, selectedOrganization, refreshContextList]);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);
      /* dwh_schema_list_get */
      axios.post(serverConfig.api_base_url + "access_type_list_get",{token:token, acc:accessSlug})
      .then(response=>{
        console.log("access_type_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(response.data.items);
            let appDataArray = response.data.items;
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              {item.actions = (<div className="text-right ml-4">
                <Button variant='text' className='mr-2' title="Edit" onClick={() => handleShowEditAccessTypeModal(item)}><i className="fa-solid fa-pencil not-0" aria-hidden="true"></i></Button>
                <Button variant='text' title="Delete" onClick={() => handleShowDeleteAccessTypeModal(item?.access_type, item?.slug)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i></Button>
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setAccessTypeData(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));


      axios.post(serverConfig.api_base_url + "access_flow_list_get", {
        token: token,
        org: selectedOrganization?.slug,
        acc: accessSlug
      }).then(response=> {
        console.log(response.data.items, 'access_flow_list_get');
        if(response.data.status === 200) {
          if(response.data.items !== null && response.data.items !== undefined) {
          setAccessFlowList(response?.data?.items);
          }
        }
        else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      }).catch(err=>{
        console.log(err)
      })
    }
    
  }, [token, selectedOrganization, refreshAccessList]);

  const sortDown=<i class="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp=<i class="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ['Updated', 'Option 2', 'Option 3'];
  const sortingIcons= [sortDown,sortUp];

  const columns = [
    {
      name: <div className='font-bold text-sm'>Name</div>,
      selector: row => row.context,
      width: '38%'
    },
    {
      name: <div className='font-bold text-sm'>Description</div>,
      selector: row => row.description,
      wrap: true,
      width: '38%'
    },
    {
      name: <div className='font-bold text-sm'>Actions</div>,
      selector: row => row.actions,
      width: 'calc(15% - 49px)'
    },
  ];

  const columnsType = [
    {
      name: <div className='font-bold text-sm'>Name</div>,
      selector: row => row.access_type,
      width: '40%'
    },
    {
      name: <div className='font-bold text-sm'>Description</div>,
      selector: row => row.description,
      wrap: true,
      width: '40%'
    },
    {
      name: <div className='font-bold text-sm'>Actions</div>,
      selector: row => row.actions,
      width: 'calc(15% - 49px)'
    },
  ];

  const columnsFlow = [

    {
      name: <div className='font-bold text-sm'>S No</div>,
      selector: row => row.seq_no,
      width :'5%'
    },
   

    {
      name: <div className='font-bold text-sm'>Access</div>,
      selector: row => row.access,
    },
   
     {
      name: <div className='font-bold text-sm'>Persona</div>,
      selector: row => row.persona,
      wrap: true,
  
    },
    {
      name: <div className='font-bold text-sm'>Member</div>,
      selector: row => row.member,
      wrap: true,
  
    },
    {
      name: <div className='font-bold text-sm'>Action/Approval</div>,
      selector: row => row.action,
      wrap: true,
  
    },
    {
      name: <div className='font-bold text-sm'>Flow Type</div>,
      selector: row => row.flow_type==='grant' ?
                        <div className={'type-Client'}>
                        <div>Access Flow</div>
                        </div> :
                       row.flow_type==='revoke' ? 
                        <div className={'type-department'}>
                        <div>Revoke Flow</div>
                        </div> : 
                        <></>,
    },
  ];

  const [createAccessFlowModalShow, setCreateAccessModalShow] = useState(false);
  const handleShowCreateAccessFlowModal = () => {
    setCreateAccessModalShow(true);
    if(accessFlowList.length > 0) {
      console.log(accessFlowList, 'access_list')
        const grantFlow = accessFlowList.filter(item => item.flow_type === 'grant');
        console.log(grantFlow, 'grant_flow')
        const revokeFlow = accessFlowList.filter(item => item.flow_type === 'revoke');
        setColumnData(grantFlow.sort(({seq_no:a}, {seq_no:b}) => a-b));
        setColumnDataRevoke(revokeFlow.sort(({seq_no:a}, {seq_no:b}) => a-b));
    }
  }

  const handleCloseCreateAccessFlowModal = () => {
    setCreateAccessModalShow(false);
    setAccessFlowFormData({});
    setColumnData([]);
    setFormMessage({});
  }

  const [createContextModalShow, setCreateContextModalShow] = useState(false);
  const handleCloseCreateContextModal = () => {
    setCreateContextModalShow(false);
    setFormData({});
    setFormMessage({});
  }
  const handleShowCreateContextModal = () => {
    setCreateContextModalShow(true);
  }

  const handleCreateContextSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "access_context_create",{
      token:token,
      acc:accessSlug,
      context: formData.context,
      description: formData.description
    })
    .then(response=>{
      console.log("access_context_create :", response);
      if (response.data.status === 200) {
        setFormData({});
        handleCloseCreateContextModal();
        setRefreshContextList(!refreshContextList);
        handleShowAlertModal('Context created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [createAccessTypeModalShow, setCreateAccessTypeModalShow] = useState(false);
  const handleCloseCreateAccessTypeModal = () => {
    setCreateAccessTypeModalShow(false);
    setAccessTypeFormData({});
    setFormMessage({});
  }
  const handleShowCreateAccessTypeModal = () => {
    setCreateAccessTypeModalShow(true);
  }

  const handleCreateAccessTypeSubmit = (event) => {
    event.preventDefault();
    console.log(accessTypeFormData);
    axios.post(serverConfig.api_base_url + "access_type_create",{
      token:token,
      acc:accessSlug,
      access_type: accessTypeFormData.access_type,
      description: accessTypeFormData.description
    })
    .then(response=>{
      console.log("access_context_create :", response);
      if (response.data.status === 200) {
        setFormData({});
        setRefreshAccessList(!refreshAccessList);
        handleCloseCreateAccessTypeModal();
        handleShowAlertModal('Access type created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Edit
  const [contextName, setContextName] = useState("");
  const [contextSlug, setContextSlug] = useState("");
  const [editContextModalShow, setEditContextModalShow] = useState(false);
  const handleCloseEditContextModal = () => {
    setEditContextModalShow(false);
    setContextName("");
    setContextSlug("");
    setFormData({});
    setFormMessage({});
  }
  const handleShowEditContextModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditContextModalShow(true);
  }

  const handleUpdateContext = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "access_context_update",{
      token:token,
      con:formData.slug,
      acc:accessSlug,
      context: formData.context,
      description: formData.description
    })
    .then(response=>{
      console.log("access_context_update:", response);
      if (response.data.status === 200) {
        setFormData({});
        setRefreshContextList(!refreshContextList);
        handleCloseEditContextModal();
        handleShowAlertModal('Context updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const [accessTypeName, setAccessTypeName] = useState("");
  const [accessTypeSlug, setAccessTypeSlug] = useState("");
  const [editAccessTypeModalShow, setEditAccessTypeModalShow] = useState(false);
  const handleCloseEditAccessTypeModal = () => {
    setEditAccessTypeModalShow(false);
    setAccessTypeName("");
    setAccessTypeSlug("");
    setAccessTypeFormData({});
    setFormMessage({});
  }
  const handleShowEditAccessTypeModal = (details) => {
    console.log(details);
    setAccessTypeFormData(details);
    setEditAccessTypeModalShow(true);
  }

  const handleUpdateAccessType = (event) => {
    event.preventDefault();
    console.log(accessTypeFormData);
    axios.post(serverConfig.api_base_url + "access_type_update",{
        token:token,
        accType:accessTypeFormData.slug,
        acc:accessSlug,
        access_type: accessTypeFormData.access_type,
        description: accessTypeFormData.description
    })
    .then(response=>{
      console.log("access_type_update:", response);
      if (response.data.status === 200) {
        setAccessTypeFormData({});
        setRefreshAccessList(!refreshAccessList);
        handleCloseEditAccessTypeModal();
        handleShowAlertModal('Access type updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Delete
  const [deleteContextModalShow, setDeleteContextModalShow] = useState(false);
  const handleCloseDeleteContextModal = () => {
    setDeleteContextModalShow(false);
    setContextName("");
    setFormData({});
    setFormMessage({});
  }
  const handleShowDeleteContextModal = (context_name, context_slug) => {
    setDeleteContextModalShow(true);
    setContextName(context_name);
    setContextSlug(context_slug);
  }

  const handleDeleteContext = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "access_context_delete",{token:token, con:contextSlug, acc:accessSlug})
    .then(response=>{
      console.log("access_context_delete:", response);
      if (response.data.status === 200) {
        setRefreshContextList(!refreshContextList);
        handleCloseDeleteContextModal();
        handleShowAlertModal('Context deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const [deleteAccessTypeModalShow, setDeleteAccessTypeModalShow] = useState(false);
  const handleCloseDeleteAccessTypeModal = () => {
    setDeleteAccessTypeModalShow(false);
    setContextName("");
    setFormData({});
    setFormMessage({});
  }
  const handleShowDeleteAccessTypeModal = (type_name, type_slug) => {
    setDeleteAccessTypeModalShow(true);
    setAccessTypeName(type_name);
    setAccessTypeSlug(type_slug);
  }

  const handleDeleteAccessType = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "access_type_delete",{token:token, accType:accessTypeSlug, acc:accessSlug})
    .then(response=>{
      console.log("access_type_delete:", response);
      if (response.data.status === 200) {
        setRefreshAccessList(!refreshAccessList);
        handleCloseDeleteAccessTypeModal();
        handleShowAlertModal('Access type deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const handleEditRevoke = (columnSeqNo, columnSlug, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value, 'persona_value')
    console.log(columnSeqNo, columnSlug);
    console.log(columnDataRevoke);

    var updatedData;
    var result;

    if(name==='data_type'){
      event.target.closest('.items-row').querySelector('input[name="length"]').value = "";
      if(value==='varchar'){
        updatedData = {[name]: value};
      } else {
        updatedData = {[name]: value, length: null};
      }
    } else {
      updatedData = {[name]: value};
    }

    if(value === 'Member'){
      setMemberSelectedRevoke(true);
      setSequenceNoRevoke(columnSeqNo)
    }

    if(value === 'L1 Manager' || value === 'L2 Manager') {
      setMemberSelectedRevoke(false);
    }
    
    if(columnSlug){
      result = columnDataRevoke.map((item) => (item.colm === columnSlug ? { ...item, ...updatedData } : item));
    } else {
      result = columnDataRevoke.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData } : item));
    }
    console.log(result);
    setColumnDataRevoke(result.sort(({seq_no:a}, {seq_no:b}) => a-b));
  }


  const handleEdit = (columnSeqNo, columnSlug, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value, 'persona_value')
    console.log(columnSeqNo, columnSlug);
    console.log(columnData);

    var updatedData;
    var result;

    if(name==='data_type'){
      event.target.closest('.items-row').querySelector('input[name="length"]').value = "";
      if(value==='varchar'){
        updatedData = {[name]: value};
      } else {
        updatedData = {[name]: value, length: null};
      }
    } else {
      updatedData = {[name]: value};
    }

    if(value === 'Member'){
      setMemberSelected(true);
      setSequenceNo(columnSeqNo)
    }

    if(value === 'L1 Manager' || value === 'L2 Manager') {
      setMemberSelected(false);
    }
    
    if(columnSlug){
      result = columnData.map((item) => (item.colm === columnSlug ? { ...item, ...updatedData } : item));
    } else {
      result = columnData.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData } : item));
    }
    console.log(result);
    setColumnData(result.sort(({seq_no:a}, {seq_no:b}) => a-b));
  }

  const handleAddNewRevokeRow = () => {
    console.log(columnDataRevoke);
    console.log(Math.max(...columnDataRevoke.map(o => o.seq_no)));
    //setSequenceNumber(sequenceNumber+1);
    var seqNo;
    if(columnDataRevoke.length > 0){
      seqNo = Math.max(...columnDataRevoke.map(o => o.seq_no)) + 1;
    } else {
      seqNo = 1;
    }
    console.log([...columnDataRevoke, {
      persona: null,
      action: null,
      description: null,
      member: null,
      seq_no: seqNo,
      flow_type: 'revoke'
    }]);
    
    setColumnDataRevoke([...columnDataRevoke, {
      persona: null,
      action: null,
      description: null,
      member: null,
      seq_no: seqNo,
      flow_type: 'revoke'
    }]);
  }

  const handleAddNewRow = () => {
    console.log(columnData);
    console.log(Math.max(...columnData.map(o => o.seq_no)));
    //setSequenceNumber(sequenceNumber+1);
    var seqNo;
    if(columnData.length > 0){
      seqNo = Math.max(...columnData.map(o => o.seq_no)) + 1;
    } else {
      seqNo = 1;
    }
    console.log([...columnData, {
      persona: null,
      action: null,
      description: null,
      member: null,
      seq_no: seqNo,
      flow_type: 'grant'
    }]);
    setColumnData([...columnData, {
      persona: null,
      action: null,
      description: null,
      member: null,
      seq_no: seqNo,
      flow_type: 'grant'
    }]);
  }

  const handleDeleteColumn = (columnSeqNo, columnSlug, flowSlug) => {
    console.log(columnData);
    console.log(columnSeqNo, columnSlug);

    let arr = columnData;
    if(flowSlug) {
      axios.post(serverConfig.api_base_url + "access_flow_delete", {
        token: token,
        acc: accessSlug,
        accFlow: flowSlug
      }).then(response => {
        console.log(response.data);
        if(response.data.status === 200){
          setColumnData([]);

          setTimeout(() => {
            if(columnSlug){
              setColumnData(arr.filter(el => el.colm !== columnSlug).sort(({seq_no:a}, {seq_no:b}) => a-b));
            } else {
              setColumnData(arr.filter(el => el.seq_no !== columnSeqNo).sort(({seq_no:a}, {seq_no:b}) => a-b));
            }
          });
          setFormMessage({message:"Deleted successfully", type:"success"});
        }
      }).catch(err=> {
        console.log(err);
      })
    }

    else {
      setColumnData([]);

          setTimeout(() => {
            if(columnSlug){
              setColumnData(arr.filter(el => el.colm !== columnSlug).sort(({seq_no:a}, {seq_no:b}) => a-b));
            } else {
              setColumnData(arr.filter(el => el.seq_no !== columnSeqNo).sort(({seq_no:a}, {seq_no:b}) => a-b));
            }
          });
    }
  }


  const handleDeleteColumnRevoke = (columnSeqNo, columnSlug, flowSlug) => {
    console.log(columnDataRevoke);
    console.log(columnSeqNo, columnSlug);

    let arr = columnDataRevoke;
    if(flowSlug) {
      axios.post(serverConfig.api_base_url + "access_flow_delete", {
        token: token,
        acc: accessSlug,
        accFlow: flowSlug
      }).then(response => {
        console.log(response.data);
        if(response.data.status === 200){
          setColumnDataRevoke([]);

          setTimeout(() => {
            if(columnSlug){
              setColumnDataRevoke(arr.filter(el => el.colm !== columnSlug).sort(({seq_no:a}, {seq_no:b}) => a-b));
            } else {
              setColumnDataRevoke(arr.filter(el => el.seq_no !== columnSeqNo).sort(({seq_no:a}, {seq_no:b}) => a-b));
            }
          });
          setFormMessage({message:"Deleted successfully", type:"success"});
        }
      }).catch(err=> {
        console.log(err);
      })
    }

    else {
      setColumnDataRevoke([]);

          setTimeout(() => {
            if(columnSlug){
              setColumnDataRevoke(arr.filter(el => el.colm !== columnSlug).sort(({seq_no:a}, {seq_no:b}) => a-b));
            } else {
              setColumnDataRevoke(arr.filter(el => el.seq_no !== columnSeqNo).sort(({seq_no:a}, {seq_no:b}) => a-b));
            }
          });
    }
  }

  const handleMoveUpColumn = (columnSeqNo) => {
    console.log(columnData);
    console.log(columnSeqNo);

    let currentIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo);
    let prevIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo) - 1;
    let currentIndexSeqNo = columnData[currentIndex].seq_no;
    let prevIndexSeqNo = columnData[prevIndex].seq_no;

    //console.log(currentIndex, currentIndexSeqNo);
    //console.log(prevIndex, prevIndexSeqNo);

    let updatedData0 = {seq_no: 0};
    let updatedData1 = {seq_no: prevIndexSeqNo};
    let updatedData2 = {seq_no: currentIndexSeqNo};
    let result1 = columnData.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item));
    let result2 = result1.map((item) => (item.seq_no === prevIndexSeqNo ? { ...item, ...updatedData2 } : item));
    let result3 = result2.map((item) => (item.seq_no === 0 ? { ...item, ...updatedData1 } : item));

    console.log(result3);
    setColumnData([]);
    setTimeout(() => {
      setColumnData(result3.sort(({seq_no:a}, {seq_no:b}) => a-b));
    });
  }

  const handleMoveUpColumnRevoke = (columnSeqNo) => {
    console.log(columnDataRevoke);
    console.log(columnSeqNo);

    let currentIndex = columnDataRevoke.findIndex(x => x.seq_no ===columnSeqNo);
    let prevIndex = columnDataRevoke.findIndex(x => x.seq_no ===columnSeqNo) - 1;
    let currentIndexSeqNo = columnDataRevoke[currentIndex].seq_no;
    let prevIndexSeqNo = columnDataRevoke[prevIndex].seq_no;

    //console.log(currentIndex, currentIndexSeqNo);
    //console.log(prevIndex, prevIndexSeqNo);

    let updatedData0 = {seq_no: 0};
    let updatedData1 = {seq_no: prevIndexSeqNo};
    let updatedData2 = {seq_no: currentIndexSeqNo};
    let result1 = columnDataRevoke.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item));
    let result2 = result1.map((item) => (item.seq_no === prevIndexSeqNo ? { ...item, ...updatedData2 } : item));
    let result3 = result2.map((item) => (item.seq_no === 0 ? { ...item, ...updatedData1 } : item));

    console.log(result3);
    setColumnDataRevoke([]);
    setTimeout(() => {
      setColumnDataRevoke(result3.sort(({seq_no:a}, {seq_no:b}) => a-b));
    });
  }

  const handleMoveDownColumn = (columnSeqNo) => {
    console.log(columnData);
    console.log(columnSeqNo);

    let currentIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo);
    let nextIndex = columnData.findIndex(x => x.seq_no ===columnSeqNo) + 1;
    let currentIndexSeqNo = columnData[currentIndex].seq_no;
    let nextIndexSeqNo = columnData[nextIndex].seq_no;

    //console.log(currentIndex, currentIndexSeqNo);
    //console.log(nextIndex, nextIndexSeqNo);

    let updatedData0 = {seq_no: 0};
    let updatedData1 = {seq_no: nextIndexSeqNo};
    let updatedData2 = {seq_no: currentIndexSeqNo};
    let result1 = columnData.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item));
    let result2 = result1.map((item) => (item.seq_no === nextIndexSeqNo ? { ...item, ...updatedData2 } : item));
    let result3 = result2.map((item) => (item.seq_no === 0 ? { ...item, ...updatedData1 } : item));

    console.log(result3);
    setColumnData([]);
    setTimeout(() => {
      setColumnData(result3.sort(({seq_no:a}, {seq_no:b}) => a-b));
    });
  }

  const handleMoveDownColumnRevoke = (columnSeqNo) => {
    console.log(columnDataRevoke);
    console.log(columnSeqNo);

    let currentIndex = columnDataRevoke.findIndex(x => x.seq_no ===columnSeqNo);
    let nextIndex = columnDataRevoke.findIndex(x => x.seq_no ===columnSeqNo) + 1;
    let currentIndexSeqNo = columnDataRevoke[currentIndex].seq_no;
    let nextIndexSeqNo = columnDataRevoke[nextIndex].seq_no;

    //console.log(currentIndex, currentIndexSeqNo);
    //console.log(nextIndex, nextIndexSeqNo);

    let updatedData0 = {seq_no: 0};
    let updatedData1 = {seq_no: nextIndexSeqNo};
    let updatedData2 = {seq_no: currentIndexSeqNo};
    let result1 = columnDataRevoke.map((item) => (item.seq_no === columnSeqNo ? { ...item, ...updatedData0 } : item));
    let result2 = result1.map((item) => (item.seq_no === nextIndexSeqNo ? { ...item, ...updatedData2 } : item));
    let result3 = result2.map((item) => (item.seq_no === 0 ? { ...item, ...updatedData1 } : item));

    console.log(result3);
    setColumnDataRevoke([]);
    setTimeout(() => {
      setColumnDataRevoke(result3.sort(({seq_no:a}, {seq_no:b}) => a-b));
    });
  }

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  const handleUpdateAccessFlow = (event) => {
    event.preventDefault();
    const columnDetails = [...columnData, ...columnDataRevoke];
    console.log(formData);
    axios.post(serverConfig.api_base_url + "access_flow_update",{
      token:token,
      acc:accessSlug,
      columnDetails: columnDetails
    })
    .then(response=>{
      console.log("dwh_table_update:", response);
      if (response.data.status === 200) {
        handleCloseCreateAccessFlowModal();
        setRefreshAccessList(!refreshAccessList);
        handleShowAlertModal('Workflow added successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [personaLookup, setPersonaLookup] = useState([]);
  const [actionLookup, setActionLookup] = useState([]);

  useEffect(()=> {

    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"persona"})
    .then(response=>{
      console.log("persona lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("persona data:", response.data.items);
          response.data.items.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          setPersonaLookup(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"action"})
    .then(response=>{
      console.log("action lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("action data:", response.data.items);
          response.data.items.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          setActionLookup(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "user_get_list", {token:token, org: selectedOrganization?.slug})
    .then(response=>{
      console.log("user_get_list:", response);
      if(response.data.items !== null) {
        const memberList = response.data.items.map((item)=>{
          return {
            value : item?.email_id,
            label : item?.name
          }
        })
        setMemberLookup(memberList);
      }
    })
    .catch(err=> {
      alert(err);
    })

  },[token]);

  return(

    <div className='flex flex-col'>

    <div className="overflow-x-auto px-14 ms-2 mt-4 pb-3">
      <div className="table-dbs-sub sub-access">
        <DataTable title={
          <div className=" table-title rounded">
            <div className=" mx-auto flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h4 className='font-bold text-base'>Context</h4>
              </div>
              <span className="text-right" style={{width: "15%", paddingRight: "16px"}}>
                <Button variant='text' title="Create Schema" className='sub-grp px-3 py-2' onClick={handleShowCreateContextModal}><i className="fa-solid fa-plus" aria-hidden="true"></i></Button>
              </span>               
            </div>
          </div>}
          columns={columns}
          data={tableData}
          responsive={true}
          pagination={tableData?.length > 10 ? true : false}
          highlightOnHover
          customStyles={{
            table: {
              style: {
                border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                borderRadius: '16px 16px 10px 10px',
              },
            },
            rows: {
              style: {
                minHeight: '48px',
                border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          noDataComponent={<div className='text-xs'>There are no records to display</div>}
          //noTableHead
        />

        {createContextModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={handleCreateContextSubmit}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Create Context</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseCreateContextModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name<span className=' text-red-500'>*</span></label>
                  <input type="text" value={formData.context || ""} onChange={(e)=>  setFormData({ ...formData, context: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>
                <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Description</label>
                  <textarea value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                </div>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateContextModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
            </div>
          </form>
        </div>}

        {editContextModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={handleUpdateContext}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Edit Context</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseEditContextModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name</label>
                  <input type="text" value={formData.context || ""} onChange={(e)=>  setFormData({ ...formData, context: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
                </div>
                <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Description</label>
                  <textarea value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                </div>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditContextModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
            </div>
          </form>
        </div>}

        {deleteContextModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleDeleteContext}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Context</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseDeleteContextModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Context <b>{contextName}</b> ?</p>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteContextModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}
      </div>
    </div>


    <div className="overflow-x-auto px-14 ms-2 mt-4 pb-3">
      <div className="table-dbs-sub sub-access">
        <DataTable title={
          <div className=" table-title rounded">
            <div className=" mx-auto flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h4 className='font-bold text-base'>Access Type</h4>
              </div>
              <span className="text-right" style={{width: "15%", paddingRight: "16px"}}>
                <Button variant='text'  className='sub-grp px-3 py-2' title="Create Access Type" onClick={handleShowCreateAccessTypeModal}><i className="fa-solid fa-plus" aria-hidden="true" style={{fontSize: "16px"}}></i></Button>
              </span>               
            </div>
          </div>}
          columns={columnsType}
          data={accessTypeData}
          responsive={true}
          pagination={accessTypeData.length > 10 ? true : false}
          highlightOnHover
          customStyles={{
            table: {
              style: {
                border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                borderRadius: '16px 16px 10px 10px',
              },
            },
            rows: {
              style: {
                minHeight: '48px',
                border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          noDataComponent={<div className='text-xs'>There are no records to display</div>}
          //noTableHead
        />

        {createAccessTypeModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={handleCreateAccessTypeSubmit}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Create Access Type</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseCreateAccessTypeModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name<span className=' text-red-500'>*</span></label>
                  <input type="text" value={accessTypeFormData.access_type || ""} onChange={(e)=>  setAccessTypeFormData({ ...accessTypeFormData, access_type: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>
                <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Description</label>
                  <textarea value={accessTypeFormData.description || ""} onChange={(e)=>  setAccessTypeFormData({ ...accessTypeFormData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                </div>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateAccessTypeModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
            </div>
          </form>
        </div>}

        {editAccessTypeModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={handleUpdateAccessType}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Edit Access Type</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseEditAccessTypeModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name</label>
                  <input type="text" value={accessTypeFormData.access_type || ""} onChange={(e)=>  setAccessTypeFormData({ ...accessTypeFormData, access_type: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
                </div>
                <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Description</label>
                  <textarea value={accessTypeFormData.description || ""} onChange={(e)=>  setAccessTypeFormData({ ...accessTypeFormData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                </div>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditAccessTypeModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
            </div>
          </form>
        </div>}

        {deleteAccessTypeModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleDeleteAccessType}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Access Type</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseDeleteAccessTypeModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Access Type <b>{accessTypeName}</b> ?</p>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteAccessTypeModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}
      </div>
    </div>


    <div className="overflow-x-auto px-14 ms-2 mt-4 pb-3">
      <div className="table-dbs-sub sub-access">
        <DataTable title={
          <div className=" table-title rounded">
            <div className=" mx-auto flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h4 className='font-bold text-base'>Access Flow</h4>
              </div>
              <span className="text-right" style={{width: "15%", paddingRight: "16px"}}>
                {/* <Button variant='text' title="Create Schema" onClick={handleShowCreateAccessFlowModal}><i className="fa-solid fa-plus not-0" aria-hidden="true" style={{fontSize: "16px"}}></i></Button> */}
                <Button className='sub-grp px-3 py-2' variant='text' title="Edit" onClick={handleShowCreateAccessFlowModal}><i className=" fa-solid fa-pencil" aria-hidden="true"></i></Button>
              </span>               
            </div>
          </div>}
          columns={columnsFlow}
          data={accessFlowList}
          responsive={true}
          pagination={accessFlowList?.length>10 ? true : false} 
          highlightOnHover
          customStyles={{
            table: {
              style: {
                border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                borderRadius: '16px 16px 10px 10px',
              },
            },
            rows: {
              style: {
                minHeight: '48px',
                border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          noDataComponent={<div className='text-xs'>There are no records to display</div>}
          //noTableHead
        />

        {createAccessFlowModalShow && <div className='overlay'>

        <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-3/4' onSubmit={handleUpdateAccessFlow}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Create Access Workflow</div>
                <button type="button" className='my-2 title-text-small-color' onClick={handleCloseCreateAccessFlowModal}><i className="fa-solid fa-xmark"></i> </button>
              </div>
      
              <div className='mt-4'>
                <section className='flex flex-row gap-1'>
                  <div className="basis-1/2">
                    <div className='flex flex-col gap-1 mt-4 text-xs'>
                      <label>Access Name</label>
                      <input type="text" value={access} onChange={()=>  {}} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
                    </div>
                  </div>
                </section>
                <section className='mt-15'>
                  <h5 className='font-semibold text-base'>Access Request Workflow</h5>
                  <div className='flex flex-row gap-1 items-center  mt-4'>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Sequence Number</span>
                    </div>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Persona</span>
                    </div>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Description</span>
                    </div>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Action</span>
                    </div>
                    <div className='basis-1/5'>

                    </div>
                    <div className='basis-auto'><button type="button" className='invisible'><i className="fa-regular fa-trash-can"></i></button></div>
                  </div>
                  {columnData && columnData.length > 0 && columnData.map((items, index) => {
                    return(<div key={index} className='flex flex-row gap-1 items-center items-row'>
                      <div className='basis-1/5'>
                        <input name="sequence_no" defaultValue={items.seq_no} type="text" className='custom-metadata-input py-1 px-2 text-left mt-1' onChange={() => {}} required/>
                      </div>
                   
                      <div className='basis-1/5'>
                        <select name="action" defaultValue={items.action} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required>
                           <option value="">Select Action Type</option> 
                        {actionLookup.length > 0 &&
                            actionLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          } 
                        </select>
                      </div>
                      {/* <div className='basis-1/5'>
                        <input name="member" defaultValue={items.length} type="number" min="0" className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} disabled={items.persona !== 'Member'} required={items.data_type === 'varchar'} />
                      </div> */}
                      <div className='basis-1/5'>
                        <textarea name="description" defaultValue={items.description} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)}></textarea>
                      </div>

                      <div className='basis-1/5'>
                        <select name="persona" defaultValue={items.persona} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required>
                          <option value="" onClick={(e)=> {e.preventDefault(); setMemberSelected(false);}}>Select Persona</option> 
                        {personaLookup.length > 0 && 
                            personaLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          } 
                        </select>
                      </div>

                      {(memberSelected===true && sequenceNo === items.seq_no) || (items.member !== null) ?
                      <div className='basis-1/5'>
                        <select name="member" defaultValue={items.member} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEdit(items.seq_no, items.colm, e)} required>
                           <option value="">Select Member</option> 
                        {memberLookup.length > 0 &&
                            memberLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          } 
                        </select>
                      </div>  : <div className='basis-1/5'></div>}
                      <div className='basis-auto more-options-wrapper'/*  ref={moreOptionsIconRef} */>
                        <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
                        <div className="more-options" style={{display: "none"}}>
                          <button type="button" onClick={() => handleDeleteColumn(items.seq_no, items.colm, items.slug)}><i className="fa-regular fa-trash-can"></i> <span>Delete</span></button>
                          <button type="button" onClick={() => handleMoveUpColumn(items.seq_no)} disabled={index === 0}><i className="fa-solid fa-arrow-up-long"></i> <span>Move Up</span></button>
                          <button type="button" onClick={() => handleMoveDownColumn(items.seq_no)} disabled={index+1 === columnData.length}><i className="fa-solid fa-arrow-down-long"></i> <span>Move Down</span></button>
                        </div>
                      </div>
                    </div>)
                  })}
                  <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleAddNewRow}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></button>
                </section>



                {/* <section className='mt-5'>
                  <h5 className='font-semibold text-base'>Access Revoke Workflow</h5>
                  <div className='flex flex-row gap-1 items-center mt-4'>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Sequence Number</span>
                    </div>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Persona</span>
                    </div>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Description</span>
                    </div>
                    <div className='basis-1/5'>
                      <span className='font-bold'>Action</span>
                    </div>
                    <div className='basis-1/5'>

                    </div>
                    <div className='basis-auto'><button type="button" className='invisible'><i className="fa-regular fa-trash-can"></i></button></div>
                  </div>
                  {columnDataRevoke && columnDataRevoke.length > 0 && columnDataRevoke.map((items, index) => {
                    return(<div key={index} className='flex flex-row gap-1 items-center items-row'>
                      <div className='basis-1/5'>
                        <input name="sequence_no" defaultValue={items.seq_no} type="text" className='custom-metadata-input py-1 px-2 text-left mt-1' onChange={() => {}} required/>
                      </div>
                    
                      <div className='basis-1/5'>
                        <select name="action" defaultValue={items.action} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEditRevoke(items.seq_no, items.colm, e)} required>
                           <option value="">Select Action Type</option> 
                        {actionLookup.length > 0 &&
                            actionLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          } 
                        </select>
                      </div>
                      <div className='basis-1/5'>
                        <textarea name="description" defaultValue={items.description} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEditRevoke(items.seq_no, items.colm, e)}></textarea>
                      </div>

                      <div className='basis-1/5'>
                        <select name="persona" defaultValue={items.persona} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEditRevoke(items.seq_no, items.colm, e)} required>
                          <option value="">Select Persona</option> 
                        {personaLookup.length > 0 && 
                            personaLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          } 
                        </select>
                      </div>

                      {(memberSelectedRevoke===true && sequenceNoRevoke === items.seq_no) || (items.member !== null) ?
                      <div className='basis-1/5'>
                        <select name="member" defaultValue={items.member} className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => handleEditRevoke(items.seq_no, items.colm, e)} required>
                           <option value="">Select Member</option> 
                        {memberLookup.length > 0 &&
                            memberLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                          } 
                        </select>
                      </div>  : <div className='basis-1/5'></div>}
                      <div className='basis-auto more-options-wrapper'>
                        <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
                        <div className="more-options" style={{display: "none"}}>
                          <button type="button" onClick={() => handleDeleteColumnRevoke(items.seq_no, items.colm, items.slug)}><i className="fa-regular fa-trash-can"></i> <span>Delete</span></button>
                          <button type="button" onClick={() => handleMoveUpColumnRevoke(items.seq_no)} disabled={index === 0}><i className="fa-solid fa-arrow-up-long"></i> <span>Move Up</span></button>
                          <button type="button" onClick={() => handleMoveDownColumnRevoke(items.seq_no)} disabled={index+1 === columnDataRevoke.length}><i className="fa-solid fa-arrow-down-long"></i> <span>Move Down</span></button>
                        </div>
                      </div>
                    </div>)
                  })}
                  <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleAddNewRevokeRow}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></button>
                </section> */}



                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateAccessFlowModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
            </div>
          </form>



          
          
        </div>}


        
      </div>

       {/* {alertModalShow && 
       <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>
         }  */}
      {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
    </div>

    </div>
  );
}

export default SubItemsMap;