import React, { useEffect, useState, useContext } from 'react';
//import NewGroupPopup from './groupPopup/NewGroupPopup';
import { Context } from '../contextApi/context';
import HeaderSecond from './components/HeaderSecond';
import TableSubgroup from './tables/TableSubgroup';
import TableSprint from './tables/TableSprint';
import axios from 'axios';
import UserService from './../services/UserService';
import { useLocation } from 'react-router-dom';
import Notification from '../common/notification/Notification.js';

const serverConfig = require("./../config/server.js");

function Subgroup({ isPlanner, isSprint }) {
  const { collapse } = useContext(Context);
  const [newSubgroupPopup, setNewSubgroupPopup] = useState(false);

  const location = useLocation();

  const {selectedOrganization} = useContext(Context);

  const [token, setToken] = useState(null);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    console.log(location.state);
  }, []);

  return (
    <div>
      <HeaderSecond linkName={isPlanner ? 'New Sprint' : 'New Subgroup'} setPopup={setNewSubgroupPopup} isSubgroup={true} isPlanner={isPlanner} groupDetails={location.state} token={token} selectedOrganization={selectedOrganization} />
      {/* {newSubgroupPopup && <div className='overlay'> <NewGroupPopup setNewSubgroupPopup={setNewSubgroupPopup} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} /></div>} */}
      <div className={collapse ? 'content-body' : 'content-body-collapse'} >
        <div className="container-fluid">
          {location?.state?.type_details?.type === "Sprint" ? 
            <TableSprint token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} newSubgroupPopup={newSubgroupPopup} setPopup={setNewSubgroupPopup} groupDetails={location.state} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} isPlanner={isPlanner} isSprint={isSprint} />
            :
            <TableSubgroup token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} newSubgroupPopup={newSubgroupPopup} setPopup={setNewSubgroupPopup} groupDetails={location.state} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} isPlanner={isPlanner} />
          }
        </div>
        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
      </div>
    </div>
  )
}

export default Subgroup;