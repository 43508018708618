import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { feature } from "topojson-client";
import usMapData from "../data/us-10m.json";
import * as XLSX from 'xlsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as htmlToImage from 'html-to-image';

const StatumVectorMap = ({
  data = [],
  item = {},
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [selectedState, setSelectedState] = useState("");
  const [noData, setNoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [chartType, setChartType] = useState("export");

  // method - chart optons
  const UpdateType = (menu) => {
    if (menu !== "image" && menu !== "export") {
      setChartType(menu?.trim()?.toLowerCase());
    }
    else if (menu === "export") {
      // export to excel functionlity
      exportToExcel((name || "Export"), data);
    }
    else {
      // Download as Image
      //OnDownloadClick("chartDashboard");
    }
  }

  // method - download chart as an Image (.png)
  // const OnDownloadClick = async (tagName) => {
  //   console.log(tagName);
  //     await htmlToImage.toPng(document.getElementsByClassName(tagName)[0])
  //         .then(function (dataUrl) {
  //             const link = document.createElement('a')
  //             link.download = "Chart"
  //             link.href = dataUrl
  //             link.click()
  //         })
  //         .catch((err) => {
  //             // nothing
  //         });
  // }

  const exportToExcel = (filename, data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const date = new Date();

    const formattedFileName = `${filename}_${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${formattedFileName}.xlsx`);
  };

  const optionsExport = ["export"];

  const svgRef = useRef();
  const containerRef = useRef();

  const stateLabelKey = item.label || "state";
  const revenueValueKey = item.value || "revenue";
  const valueLabel = item.value_label || "";
  const title = item.title || "Map";

  const stateNames = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };

  const getRevenue = (stateAbbr) => {
    const stateData = Array.isArray(data)
      ? data.find(
        (item) =>
          item[stateLabelKey] === stateAbbr ||
          stateNames[item[stateLabelKey]] === stateAbbr
      )
      : null;
    return stateData ? stateData[revenueValueKey] : "No data available";
  };

  const parsedColorMap = item?.color_map ? JSON.parse(item.color_map) : {};

  const getColorForRevenue = (revenue) => {
    if (!Array.isArray(data)) return "#ccc"; // Default gray for no data

    const sortedRevenues = data
      .map((d) => parseFloat(d[revenueValueKey]))
      .filter(Boolean)
      .sort((a, b) => a - b);

    const calculatePercentile = (value) => {
      const index = sortedRevenues.findIndex(rv => rv >= value);
      return index === -1 ? 100 : (index / sortedRevenues.length) * 100;
    };

    const percentile = calculatePercentile(revenue);

    // Dynamically assign colors based on percentile
    if (percentile <= 10) return parsedColorMap?.topTen || { base: "#ccc", hover: "#999" };
    if (percentile <= 25) return parsedColorMap?.topQuarter || { base: "#ccc", hover: "#999" };
    if (percentile <= 50) return parsedColorMap?.topHalf || { base: "#ccc", hover: "#999" };
    if (percentile <= 75) return parsedColorMap?.topThreeQuarter || { base: "#ccc", hover: "#999" };
    if (percentile <= 90) return parsedColorMap?.topNinety || { base: "#ccc", hover: "#999" };
    return parsedColorMap?.bottomTen || { base: "#ccc", hover: "#999" };
  };

  const renderLegend = () => {
    if (!parsedColorMap) return null;
    
    const colorEntries = Object.entries(parsedColorMap);
    
    return (
      <div className="map-legend" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
        <div style={{ width: "80%", height: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 5px" }}>
          {colorEntries.map(([key, color], index) => (
            <div key={index} style={{ flex: 1, height: "100%", backgroundColor: color.base }} />
          ))}
        </div>
        <div style={{ width: "80%", display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
          <span style={{ color: parsedColorMap.topTen?.base || "green", fontSize: "12px", fontWeight: "bold" }}>Top 10%</span>
          <span style={{ color: parsedColorMap.bottomTen?.base || "red", fontSize: "12px", fontWeight: "bold" }}>Bottom 10%</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (item.data === "fetching") {
      setIsLoading(true);
      return;
    } else if (!Array.isArray(data) || data.length === 0) {
      setNoData("No Data Found");
      return;
    }

    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;

    const svg = d3.select(svgRef.current);
    const width = containerWidth;
    const height = containerHeight;

    const projection = d3
      .geoAlbersUsa()
      .scale(550)
      .translate([width / 2, height / 2]);

    const path = d3.geoPath().projection(projection);

    const states = feature(usMapData, usMapData.objects.states).features;

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "custom-map-tooltip")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("background", "lightsteelblue")
      .style("border", "0px")
      .style("border-radius", "8px")
      .style("padding", "5px")
      .style("font", "12px sans-serif")
      .style("pointer-events", "none");

    svg.attr("width", width).attr("height", height);

    svg
      .selectAll("path")
      .data(states)
      .enter()
      .append("path")
      .attr("d", path)
      .attr("fill", (d) => {
        const stateAbbr = Object.keys(stateNames).find(
          (key) => stateNames[key] === d.properties.name
        );
        const stateData = Array.isArray(data)
          ? data.find(
            (item) =>
              item[stateLabelKey] === stateAbbr ||
              stateNames[item[stateLabelKey]] === stateAbbr
          )
          : null;
        const colorData = stateData
          ? getColorForRevenue(parseFloat(stateData[revenueValueKey]))
          : { base: "#ccc", hover: "#999" };
        return colorData.base;
      })
      .attr("stroke", "white")
      .attr("stroke-width", 2)
      .on("mouseover", function (event, d) {
        const stateAbbr = Object.keys(stateNames).find(
          (key) => stateNames[key] === d.properties.name
        );
        const stateData = Array.isArray(data)
          ? data.find(
            (item) =>
              item[stateLabelKey] === stateAbbr ||
              stateNames[item[stateLabelKey]] === stateAbbr
          )
          : null;
        const colorData = stateData
          ? getColorForRevenue(parseFloat(stateData[revenueValueKey]))
          : { base: "#ccc", hover: "#999" };

        d3.select(this)
          .attr("fill", colorData.hover)
          .style("cursor", "pointer");

        const stateFullName = stateNames[stateAbbr];
        const revenue = getRevenue(stateFullName);
        tooltip
          .style("visibility", "visible")
          .html(`<strong>${stateFullName}</strong><br>${valueLabel}: ${revenue}`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 10}px`);
      })
      .on("mouseout", function (event, d) {
        const stateAbbr = Object.keys(stateNames).find(
          (key) => stateNames[key] === d.properties.name
        );
        const stateData = Array.isArray(data)
          ? data.find(
            (item) =>
              item[stateLabelKey] === stateAbbr ||
              stateNames[item[stateLabelKey]] === stateAbbr
          )
          : null;
        const colorData = stateData
          ? getColorForRevenue(parseFloat(stateData[revenueValueKey]))
          : { base: "#ccc", hover: "#999" };

        d3.select(this).attr("fill", colorData.base);
        tooltip.style("visibility", "hidden");
        setSelectedState("");
      })
  }, [item.data]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <div>
      {noData ? (
        <>
          <div className="chart-title">{noData}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="flex flex row justify-between">
              <div className="chart-title" style={{ padding: "12px" }}>{item?.title} </div>

              {optionsExport?.length ?
                <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                  <Popover.Body>
                    {optionsExport?.map((menu, index) => (
                      <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                        <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                      </div>
                    ))}
                  </Popover.Body>
                </Popover>}>
                  <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                    <i className="fa-solid fa-ellipsis-vertical not-0" style={{ color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px" }}></i>
                    {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                  </span>
                </OverlayTrigger></div> : null}
            </div>

          ) : (
            <div className="no-chart-title flex flex-row justify-between">
              <div></div>
              {optionsExport?.length ?
                <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                  <Popover.Body>
                    {optionsExport?.map((menu, index) => (
                      <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                        <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                      </div>
                    ))}
                  </Popover.Body>
                </Popover>}>
                  <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                    <i className="fa-solid fa-ellipsis-vertical not-0" style={{ color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px" }}></i>
                    {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                  </span>
                </OverlayTrigger></div> : null}
            </div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <>
          <div className="flex">
            <div
              ref={containerRef}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <svg
                ref={svgRef}
                style={{
                  height: 300,
                  minWidth: "300px",
                  minHeight: "200px",
                  maxWidth: "3000px",
                  maxHeight: "2000px",
                  overflow: "visible",
                }}
              ></svg>
            </div>

            <div className="map-info-container pt-10">
              <div
                className="custom-map-tooltip"
                style={{
                  position: "absolute",
                  textAlign: "center",
                  width: "auto",
                  height: "auto",
                  padding: "5px",
                  font: "12px sans-serif",
                  background: "lightsteelblue",
                  border: "0px",
                  borderRadius: "8px",
                  pointerEvents: "none",
                  visibility: "hidden",
                }}
              >
                {selectedState && (
                  <>
                    <p>{stateNames[selectedState]}</p>
                    <p>{getRevenue(selectedState)}</p>
                  </>
                )}
              </div>
            </div>
          </div>

          {renderLegend()}
        </>
      )}
    </div>
  );
};

export default StatumVectorMap;
