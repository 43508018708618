import React,{useState, useEffect, useRef} from 'react';
import Search from '../components/Search';
import axios from 'axios';
import IssueStatus from '../tables/IssueStatus.js';
import IssueCategory from '../tables/IssueCategory.js';
import IssueRelations from '../components/IssueRelations.js';

const serverConfig = require("./../../config/server.js");

function GroupDropdownCard({data, setSelectedGrp, setCollapse, setGroupList, grpList}){
  return(
    <div title={data.name} className='flex gap-2 align-middle p-2 oragnisationHover' style={{alignItems:'center'}} onClick={() => {setSelectedGrp(data); setCollapse(true); setGroupList(grpList);}}>
      <div>
        {data.logo_base64 === null ? <img src={`https://ui-avatars.com/api/?name=${data?.name}
                        &rounded=false&length=1
                        &background=${data.default_color.replace('#','')}&format=svg&color=fff`} alt='image'
          className='organisation-img rounded-lg' /> : <img src={data.logo_base64} alt={data.logo_name} className='rounded-lg organisation-img' />}
      </div>
      <div className='organisation-heading line-clamp-1'>{data.name}</div>
    </div>
  )
}

function EditSubgroupPopup({setEditGroupPopup, details, token, selectedOrganization, handleLogout, refreshGroupTable, groupDetails, handleCloseAlertModal, handleShowAlertModal, userList, popupCurrentTab, isPlanner}) {
  const [formData, setFormData] = useState(details);
  console.log(details);
  const [tab, setTab] = useState(popupCurrentTab ? popupCurrentTab : 'General');
  const[memberTab, setMemberTab]=useState(0);
  const[collapse, setCollapse]=useState(true);

  const[grpList, setGrpList] = useState([]);
  const[groupList, setGroupList] = useState([]);
  const[groupTypeList, setGroupTypeList] = useState([]);
  const[selectedGrp, setSelectedGrp] = useState('');

  const[groupSlug, setGroupSlug] = useState(details.grp);
  const[orgMemberList, setOrgMemberList] = useState(userList);
  const[orgMembrList, setOrgMembrList] = useState(userList);
  const[groupMemberList, setGroupMemberList] = useState([]);
  const[collapseOrgMemberList, setCollapseOrgMemberList]=useState(true);
  const[refreshGroupMemberList, setRefreshGroupMemberList]=useState(false);

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [showConfirmAdd, setShowConfirmAdd] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [memberToAdd, setMemberToAdd] = useState(null);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCollapseOrgMemberList(true); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const getGroupMemberList = () => {
    axios.post(serverConfig.api_base_url + "group_member_list_get",{token: token, org:selectedOrganization?.slug, grp: details.grp})
    .then(response=>{
      console.log('group_member_list_get response', response);
      console.log('group_member_list_get response items',response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setGroupMemberList(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        setGroupMemberList([]);
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)

      axios.post(serverConfig.api_base_url + "group_list_get", {token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('group_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setGrpList(response.data.items);
            setGroupList(response.data.items);
            setSelectedGrp(groupDetails.name);
          }
        } else if(response.data.status === 301){
					handleLogout();
				} else {
					handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
				}
      })
      .catch(err=>console.log("error is",err));

      //group_status_get_list
      axios.post(serverConfig.api_base_url + "group_status_get_list", {
        token: token,
        org: selectedOrganization?.slug
      }).then(response => {
        console.log(response.data.items, 'group_list_res')
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            if(isPlanner){
              setGroupTypeList(response.data.items);
            } else {
              setGroupTypeList(response.data.items.filter(el => el.name !== "Sprint"));
            }
          } else {
            setGroupTypeList([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          setGroupTypeList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      }).catch(err => {
        console.log(err);
      });
    }

    getGroupMemberList();
  }, [token, selectedOrganization, refreshGroupMemberList]);

  const getBase64 = (file, e) => {
    console.log(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      if(e.target.name === 'logo') {
        setFormData({ ...formData, logo: file.name, logo_base64: reader.result});
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleUpdateSubgroup = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(selectedGrp.grp, groupDetails.grp, formData.grp);
    let form_data = {
      name: formData.name,
      logo: formData.logo ? formData.logo : undefined,
      logo_base64: formData.logo_base64 ? formData.logo_base64 : undefined,
      type: formData.type ? formData.type : formData.type_details.type,
      parentGrp: selectedGrp.grp || groupDetails.grp,
      sprint_attributes: formData.sprint_attributes ? {
        start_date: formData?.sprint_attributes?.start_date,
        end_date: formData?.sprint_attributes?.end_date
      } : undefined
    }

    console.log(form_data);

    axios.post(serverConfig.api_base_url + "subgroup_update",{
      token:token,
      org:selectedOrganization?.slug,
      grp: formData.grp,
      items: form_data
    })
    .then(response=>{
      console.log("subgroup_update:", response);
      if (response.data.status === 200) {
        setFormData({});
        refreshGroupTable();
        handleShowAlertModal(isPlanner ? 'Sprint updated successfully' : 'Subgroup updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setEditGroupPopup(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleAddMember = (userSlug) => {
    axios.post(serverConfig.api_base_url + "group_member_add",{token: token, grp:groupSlug, usr:userSlug})
    .then(response=>{
      console.log('group_member_add response', response);
      console.log('group_member_add response items',response);
      if(response.data.status === 200){
        setCollapseOrgMemberList(!collapseOrgMemberList);
        setRefreshGroupMemberList(!refreshGroupMemberList);
        refreshGroupTable();
        handleShowAlertModal('Member added successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleRemoveMember = (userSlug) => {
    axios.post(serverConfig.api_base_url + "group_member_delete",{token: token, grp:groupSlug, usr:userSlug})
    .then(response=>{
      console.log('group_member_delete response', response);
      console.log('group_member_delete response items',response);
      if(response.data.status === 200){
        setGroupMemberList([]);
        setRefreshGroupMemberList(!refreshGroupMemberList);
        refreshGroupTable();
        handleShowAlertModal('Member removed successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleClosePopup = () => {
    setEditGroupPopup(false);
    setGroupSlug('');
    setOrgMemberList([]);
    setGroupMemberList([]);
  }

  useEffect(() => {
    const handleMouseDown = (event) => {
      var x = document.querySelectorAll('.organisationDropdownCard');
      if(!event.target.classList.contains("btn-toggle") && !event.target.classList.contains('organisation-heading') && !event.target.classList.contains('organisation-img') && !event.target.classList.contains('search-input') && !event.target.classList.contains('oragnisationHover')){
        setCollapse(true);
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  return (<div className='memberPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex flex-col overflow-hidden' style={{height: "95vh"}}>
    
      <div className='flex justify-between items-center gap-5'>
        <div style={{fontSize:16}}>Edit {isPlanner ? 'Sprint' : 'Subgroup'}</div>
      </div>
      <div className='mt-2'>
        <div className="flex space-x-4">
          <button className={`px-6 py-3 ${tab==='General' ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('General');}}>General</button>
          {/* <button className={`px-6 py-3 ${tab === 'Finances' ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Finances');}}>Finances</button> */}
          <button className={`px-6 py-3 ${tab === 'Settings' ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Settings');}}>Settings</button>
          <button className={`px-6 py-3 ${tab === 'Members' ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Members');}}>Members</button>
          {/* <button className={`px-6 py-3 ${tab === 'Resources' ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Resources');}}>Resources</button> */}
        </div>
        <hr className='mt-1'></hr>
      </div>
      <div className='flex-1 flex'>
        {(tab==='General') ?
          <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateSubgroup}>
            <div className='scrollable mt-5 mb-5'>
              <div className='flex flex-col'>
                <label>Name of {isPlanner ? 'Sprint' : 'Subgroup'}<span className=' text-red-500 '>*</span></label>
                <input type="text" value={formData.name} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder={isPlanner ? 'Name of Sprint' : 'Name of Subgroup'} className='custom-input py-2 px-2 text-left mt-1' required/>
              </div>
              <div className='mt-1 mb-5'>
                <div className="flex items-center">
                  {selectedGrp.name ? <img src={selectedGrp.logo_base64 || `https://ui-avatars.com/api/?name=${selectedGrp.name}&length=1&background=${selectedGrp.default_color.replace('#','')}&format=svg&color=fff`} alt={selectedGrp.name} width="70" className='border rounded-lg' /> : <img src={groupDetails.logo || `https://ui-avatars.com/api/?name=${groupDetails.name}&length=1&background=${groupDetails.bgColor}&format=svg&color=fff`} alt={groupDetails.name} width="70" className='border rounded-lg' />}
                  <div className='ml-3'>
                    <div className="text-xl"><b>{selectedGrp.name || groupDetails.name}</b></div>
                    <div>New {isPlanner ? 'Sprint' : 'Subgroup'} will be created in this {isPlanner ? 'Project' : 'Group'}</div>
                  </div>
                </div>
                <div className='relative mt-4 inline-block'>
                  <button type="button" className='organisations border rounded-lg py-2 px-4 btn-toggle' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => setCollapse(!collapse)}><b className='btn-toggle'>Change {isPlanner ? 'Project' : 'Group'}</b></button>
                  {!collapse && <div className='organisationDropdownCard' style={{width: '100%', left: 0}}>
                    <div className='pt-2 px-2'>
                      <Search grpList={grpList} setGroupList={setGroupList} />
                    </div>
                  
                    <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                      {groupList.length > 0 && groupList.map((data)=>{
                        return(<GroupDropdownCard data={data} setCollapse={setCollapse} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} />)
                      })}

                    </div>
                  </div>}
                </div>
                <div className='flex item-center'>
                  <div className='flex flex-col gap-1 mt-4 text-xs'>
                    <label>Logo</label>
                    <div className="flex items-center">
                      {formData.logo_base64 && <img src={formData.logo_base64} alt="logo" width="72" />}
                      <div className={formData.logo_base64 ? "flex flex-col ml-3" : "flex flex-col"}>
                        <label className="custom-file-upload">
                          <input  type='file' value="" onChange={(e)=>  {getBase64(e.target.files[0], e)}} name="logo" accept='.jpg, .jpeg, .png' className='custom-input py-2 px-2 text-left mt-1'/>
                          {formData.logo_base64 ? <>Choose Another File</> : <>Choose File</>}
                        </label>
                        <small className='text-slate-400 mt-2'>File Size Limit: 1Mb</small>
                      </div>
                    </div>
                  </div>
                </div>
                {!isPlanner && <div className='mt-4'>
                  <label>Type<span className=' text-red-500 '>*</span></label>
                  <select value={formData.type || formData.type_details.type} onChange={(e)=>  setFormData({ ...formData, type: e.target.value})} className='custom-input py-2 px-2 text-left mt-1' required disabled={isPlanner}>
                    <option value="">Select</option>
                    {groupTypeList.length > 0 && groupTypeList.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
                  </select>
                </div>}
                {(formData.type === "Sprint" || formData.type_details.type === "Sprint") && <div className='mt-4 flex justify-between gap-2'>
                  <div>
                    <label>Start Date<span className=' text-red-500 '>*</span></label>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={formData?.sprint_attributes?.start_date}
                      className="date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs"
                      min={new Date().toISOString().substring(0, 10)}
                      onChange={(e) =>
                        e.target.value >= new Date().toISOString().split("T")[0] &&
                        setFormData({ ...formData, sprint_attributes:{...formData.sprint_attributes, start_date: e.target.value}})
                      }
                      required
                    />
                  </div>
                  <div>
                    <label>End Date<span className=' text-red-500 '>*</span></label>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={formData?.sprint_attributes?.end_date}
                      className="date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs"
                      min={new Date().toISOString().substring(0, 10)}
                      onChange={(e) =>
                        e.target.value >= new Date().toISOString().split("T")[0] &&
                        setFormData({ ...formData, sprint_attributes:{...formData.sprint_attributes, end_date: e.target.value}})
                      }
                      required
                    />
                  </div>
                </div>}
              </div>
            </div>
            {/* bottom buttom */}
            <div className='ml-auto'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setEditGroupPopup(false)}}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
            </div>
          </form> : 
      
          (tab === 'Finances') ? <>
          {/* <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateGroupFinance}>
            <div className='scrollable mt-5 mb-5'>
              <div className='flex flex-col'>
                <label className="mb-2">Payment rate</label>
                {financeFormData.paymentRate === "Pay rate" ?
                  (<div className='flex flex-row'>
                    <label class="toggle-bg">
                      <input className='toggle-checkbox' type="checkbox" checked={financeFormData.paymentRate === "Pay rate"} onChange={() => setFinanceFormData({ ...financeFormData, paymentRate: "Bill rate"})} />
                      <span class="slider slider-green round"></span>
                      <p className='toggle-btn-dis-text'>Bill rate</p>
                      <p className='toggle-btn-text'>Pay rate</p>
                    </label>
                  </div>) :
                  (
                    <div className='flex flex-row'>
                      <label class="toggle-bg">
                        <input className='toggle-checkbox' type="checkbox" checked={financeFormData.paymentRate === "Pay rate"} onChange={() => setFinanceFormData({ ...financeFormData, paymentRate: "Pay rate"})} />
                        <span class="slider slider-green round"></span>
                        <p className='toggle-btn-dis-text'>Bill rate</p>
                        <p className='toggle-btn-text'>Pay rate</p>
                      </label>
                    </div>
                  )}
              </div>
            </div>
            <div className='ml-auto'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setEditGroupPopup(false)}}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
            </div>
          </form> */}</> :
          
          (tab === 'Members') ? 
          <div className='flex-auto flex flex-col'>
            <div className={`flex items-center justify-between my-4 relative ${showConfirmAdd || showConfirmRemove ? 'pointer-events-none opacity-95' : ''}`}>
              <div>{groupMemberList.length} Members</div>
              <div>
                <button 
                  type="button" 
                  className='border rounded-lg py-2 px-4' 
                  onClick={() => setCollapseOrgMemberList(!collapseOrgMemberList)}
                  disabled={showConfirmAdd || showConfirmRemove} 
                >
                  <b>Add Members</b>
                </button>
                {!collapseOrgMemberList && (
                  <div 
                    className='organisationDropdownCard' 
                    style={{ width: '100%', left: 0 }} 
                    ref={dropdownRef}>
                    <div className='px-2 mt-4 w-full'>
                      <Search orgMembrList={orgMembrList} setOrgMemberList={setOrgMemberList} />
                    </div>
                    <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{ borderBottom: 'none' }}>
                      {orgMemberList.length > 0 && (
                        <ul className='px-1'>
                          {orgMemberList.map((data, index) => (
                            <li 
                              key={index} 
                              className={index === orgMemberList.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"}
                              title={groupMemberList.length > 0 && groupMemberList.find(el => el.slug === data.slug) ? `Remove ${data.name}` : `Add ${data.name}`}
                              onClick={() => {
                                if(groupMemberList.length > 0 && groupMemberList.find(el => el.slug === data.slug)){
                                  setMemberToRemove(data.slug);
                                  setShowConfirmRemove(true);
                                } else {
                                  setMemberToAdd(data.slug);
                                  setShowConfirmAdd(true);
                                }
                              }}>
                              <div>
                                {!data.icon ? (
                                  <img 
                                    src={`https://ui-avatars.com/api/?name=${data?.name.split(" ")[0]}+${data?.name.split(" ").length > 1 ? data?.name.split(" ")[data?.name.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`} 
                                    alt='User Image' 
                                    className='rounded-full' 
                                    width="40" />
                                ) : (
                                  <img src={data.icon} alt='User Image' className='rounded-full' width="40" />
                                )}
                              </div>
                              <div className="ml-4">{data.name}</div>
                              {groupMemberList.length > 0 && groupMemberList.find(el => el.slug === data.slug) ? (
                                <span className="ml-auto mr-3" /* onClick={() => {
                                  setMemberToRemove(data.slug);
                                  setShowConfirmRemove(true);
                                }} */>
                                  <i className="fa-solid fa-xmark text-slate-400"></i>
                                </span>
                              ) : (
                                <span className="ml-auto mr-3" /* onClick={() => {
                                  setMemberToAdd(data.slug);
                                  setShowConfirmAdd(true);
                                }} */>
                                  <i className="fa-solid fa-user-plus text-slate-400"></i>
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
          </div>
            <div className="flex-auto">
              {groupMemberList.length > 0 && (
                <ul className='overflow-y-auto px-1'>
                  {groupMemberList.map((data, index) => (
                    <li
                      key={index}
                      className={index === groupMemberList.length - 1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"}
                      title={`Remove ${data.name}`}
                      onClick={() => {
                        setMemberToRemove(data.slug);
                        setShowConfirmRemove(true);
                      }}
                    >
                      <div>
                        {!data.icon ? (
                          <img
                            src={`https://ui-avatars.com/api/?name=${data?.name.split(" ")[0]}+${data?.name.split(" ").length > 1 ? data?.name.split(" ")[data?.name.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`}
                            alt='User Image'
                            className='rounded-full'
                            width="40"
                          />
                        ) : (
                          <img src={data.icon} alt='User Image' className='rounded-full' width="40" />
                        )}
                      </div>
                      <div className="ml-4">{data.name}</div>
                      <span className="ml-auto mr-3" /* onClick={() => {
                        setMemberToRemove(data.slug);
                        setShowConfirmRemove(true);
                      }} */>
                        <i className="fa-solid fa-xmark text-slate-400"></i>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className='ml-auto'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleClosePopup}>Close</button>
            </div>
            
             {/* Confirmation Popup for Removing a Member */}
             {showConfirmRemove && (
               <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                 <div className="bg-white p-4 rounded-lg shadow-lg">
                   <p>Are you sure you want to remove this member?</p>
                   <div className="mt-4 flex justify-end">
                     <button
                       className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium2"
                       onClick={() => setShowConfirmRemove(false)}
                     >
                       Cancel
                     </button>
                     <button
                       className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                       onClick={() => {
                         handleRemoveMember(memberToRemove);
                         setShowConfirmRemove(false);
                       }}
                     >
                       Confirm
                     </button>
                   </div>
                 </div>
               </div>
             )}

             {/* Confirmation Popup for Adding a Member */}
             {showConfirmAdd && (
               <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                 <div className="bg-white p-4 rounded-lg shadow-lg">
                   <p>Are you sure you want to add this member?</p>
                   <div className="mt-4 flex justify-end">
                     <button
                       className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium2"
                       onClick={() => setShowConfirmAdd(false)}
                     >
                       Cancel
                     </button>
                     <button
                       className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                       onClick={() => {
                         handleAddMember(memberToAdd);
                         setShowConfirmAdd(false);
                       }}
                     >
                       Confirm
                     </button>
                   </div>
                 </div>
               </div>
             )}
          </div>
          :

          (tab === "Settings") ?
           <div className='flex flex-col table-container'>
      
            <IssueStatus token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupSlug} groupDetails={groupDetails} isPlanner={isPlanner} />
            
            <IssueCategory token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupSlug} groupDetails={groupDetails} isPlanner={isPlanner} />
      
            {/* <IssueRelations token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} grp={groupSlug} groupDetails={groupDetails} /> */}

            <div className='ml-auto'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleClosePopup}>Close</button>
            </div>
      
           </div> :
          <></>
        }
      </div> 
    
  </div>)
}

export default EditSubgroupPopup;