import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Context } from '../../contextApi/context.js';
import "../css/userman.css";
import "../css/cat.css";

const serverConfig = require("../../config/server.js");

function MyResTable() {
    const [tableData, setTableData] = useState([]);
    const { selectedOrganization } = useContext(Context);
    const [token, setToken] = useState(null);

    const handleLogout = () => {
        sessionStorage.clear();
        UserService.doLogout();
    }

    const getToken = () => {
        console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token", { jwt: jwt })
            .then(response => {
                console.log('user_get_token response', response);
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setToken(response.data.token);
                } else if (response.data.status >= 400 && response.data.status <= 499) {
                    alert(response.data.message);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log("error is", err));
    }

    const getMyResources = () => {
        axios
            .post(serverConfig.api_base_url + 'my_resources_list', {
                token: token,
                org: selectedOrganization?.slug,

            })
            .then((response) => {
                if (response.data.status === 200) {
                    console.log("Datttaa",response.data.items)
                    if(response.data.items !== null){
                    setTableData(response.data.items);}
                } else if (response.data.status === 301) {
                    handleLogout();
                } else {
                    console.log(response.data.message);
                }
            })
            .catch((err) => console.log('error is', err));
    };

    useEffect(() => {
        getMyResources();
    }, [token]);

    useEffect(() => {
        const storedToken = sessionStorage.getItem('token');
        if (storedToken === null) {
            getToken();
        } else {
            setToken(storedToken);
        }
    }, []);

    const columns = [
        {
            name: '',
            cell: (row) => (
                <div className='flex-column flex items-center py-2'>
                    <img
                        src={`https://ui-avatars.com/api/?name=${row.name}&rounded=true&length=1&${row.name ===
                            'Project' ? 'background=9D6EF8' : row.name === 'Departments' ? 'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`}
                        alt='image'
                        height={40}
                        width={40}
                        className='rounded-full'
                    />
                </div>
            ),
            grow: 0,
            sortable: false,
        },
        {
            name: <div className='row-label '>Resource </div>,
            cell: (row) => (
                <div flex-column py-2>
                    <div
                        className='text-heading'
                        style={{ cursor: 'pointer' }}
                    >
                        {row.name}
                    </div>
                </div>
            ),
            sortable: true,
            wrap: true,
        },
        {
            name: <div className='row-label '>Description</div>,
            cell: (row) => (
                <div flex-column py-2>
                        {row.description}
                    </div>
                
            ),
            sortable: true,
            wrap: true,
        }
    ];

    return (
        <div className='overflow-x-auto'>
            <div className="rounded-3xl p-3 table-custom mt-4 ">
                {tableData.length > 0 ? (
                    <DataTable
                        title={
                            <div className="table-title rounded flex justify-between items-center">
                                <div className="flex space-x-4">
                                    <h5 className='font-semibold'>My Resources</h5>
                                </div>

                                <div className="flex items-center space-x-4 text-black ">
                                    <div className="relative flex items-center gap-1">
                                        <div className="relative">
                                            <div className="absolute inset-y-5 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                                                <i className="text-sm fas fa-search"></i>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="w-30 md:w-48 h-2 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300 search-input"
                                            />

                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                        columns={columns}
                        data={tableData}
                        responsive={true}
                        pagination={true}
                        customStyles={{
                            rows: {
                                rows: {
                                    className: 'custom-hover',
                                }
                            },
                            cells: {
                                className: 'p-6',
                            },
                        }}
                    />
                ) : (
                    <div className='flex flex-col h-full items-center justify-center'>
                        <div className='mb-8'><img src={require('../images/noResource.png')} alt="No Resources" /></div>
                        <p><b>You have no Resources Assigned</b></p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MyResTable;
