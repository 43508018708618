import React from 'react'

function Sprint() {
  return (
    <div className='mt-7'>
    <div className='flex items-center justify-start'>
    <h2 className="sum-title text-custom text-xs">Sprint</h2>
   </div>
   <div>
    <select className='w-full  mt-1 dropdown-task p-2 text-xs'>
        <option className='p-2'> Sprint 21</option>
       </select>
   </div>
    </div>
  )
}

export default Sprint