import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../contextApi/context';
import Label from '../components/Label';
import AssigneesOKR from './AssigneesOKR.js';
import Progress from './Progress.js';
import Search from './Search';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CommentMain from './comment.js';

const serverConfig = require("./../../config/server.js");

function GroupDropdownCard({data, setSelectedGrp, setCollapse, setGroupList, grpList, setFormData, formData}){
  return(
    <div className='flex gap-2 align-middle p-2 oragnisationHover' style={{alignItems:'center'}} onClick={() => {setSelectedGrp(data); setCollapse(true); setGroupList(grpList); setFormData({...formData, grp:data.grp});}}>
      <div>
        {data.logo_base64 === null ? <img src={`https://ui-avatars.com/api/?name=${data?.name}
                        &rounded=false&length=1
                        &background=${data.type_details.defaultColorLightTheme.replace('#','')}&format=svg&color=fff`} alt='image'
          className='organisation-img rounded-lg' /> : <img src={data.logo_base64} alt={data.logo_name} className='rounded-lg organisation-img' />}
      </div>
      <div className='organisation-heading line-clamp-1'>{data.name}</div>
    </div>
  )
}

function CreateOKRPopup({ token, selectedOrganization, handleCreateOKR, handleShowAlertModal, handleCloseAlertModal}) {

  const navigate = useNavigate();
  const{setActiveOption} = useContext(Context);
  const[status, setStatus]= useState('');
  const[todoPopup, setTodoPopup]= useState(false);

  const [formData, setFormData] = useState({});
  const[collapse, setCollapse]=useState(true);
  const[grpList, setGrpList] = useState([]);
  const[groupList, setGroupList] = useState([]);
  const[selectedGrp, setSelectedGrp] = useState('');
  const[scoreTypeLookup, setScoreTypeLookup] = useState([]);

  useEffect(() => {
    console.log(selectedGrp);
  }, [selectedGrp]);

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)

      axios.post(serverConfig.api_base_url + "group_list_get", {token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log('group_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setGrpList(response.data.items);
            setGroupList(response.data.items);
          }
        } else if(response.data.status === 301){
					handleLogout();
				} else {
					handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
				}
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization]);

  const handleCreateNewOKR = (event) => {
    event.preventDefault();
    console.log(formData);
    if(formData?.owner === '') {

      handleShowAlertModal('Please select the owner', true);
         setTimeout(() => {
           handleCloseAlertModal();
         }, 6000);
  
    }
    else
    {
    axios.post(serverConfig.api_base_url + "okr_create", {token:token, org:selectedOrganization?.slug,  items:formData})
    .then(response=>{
      console.log('task_create response', response);
      if(response.data.status === 200){
        setFormData({});
        handleCreateOKR();
       handleShowAlertModal('Objective Created successfully');
       setTimeout(() => {
       handleCloseAlertModal();
     }, 6000);
     } else if(response.data.status === 301){
       handleLogout();
     } else {
       handleShowAlertModal(response.data.message, true);
       setTimeout(() => {
         handleCloseAlertModal();
       }, 6000);
     }

   })
    .catch(err=>console.log("error is",err));
    }
  }

  useEffect(() => {

    axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"score_type"})
    .then(response=>{
      console.log("score_type lookup:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("score_type data:", response.data.items);
          setScoreTypeLookup(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
    },[])

  return (
    <div className='taskPopUp rounded-xl p-5 relative mx-28 my-5 w-3/5'>
      <button type='button' className='my-2 absolute right-10 top-4 title-text-small-color' onClick={handleCreateOKR}><i class="fa-solid fa-xmark "></i> </button>
      <form className='flex' onSubmit={handleCreateNewOKR}>
        <div className="p-4 pr-1 border-right basis-3/4" style={{width: "54vw"}}>
          <div className='flex flex-col'>
            <div className='scrollable mb-5 pr-5' style={{maxHeight: "75vh"}}>
              <div>
                <label>Name<span className='text-red-500'>*</span></label>
                <input type="text" value={formData.name} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
              </div>
              <div>
                <label>Description</label>
                <textarea type="text" value={formData.description} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
              </div>

                <div className='flex flex-col gap-1 mt-3 text-xs'>
                <label>Score Type<span className='text-red-500'>*</span></label>
                <select value={formData.score_type || ""} className='custom-input py-2 px-2 text-left mt-1' onChange={(e)=>  setFormData({ ...formData, score_type: e.target.value})} required>
                   <option value="">Score Type</option>
                  {scoreTypeLookup.length > 0 &&
                    scoreTypeLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                  }
                </select>
                </div>

              <div className='mt-1 mb-5'>
                {Object.keys(selectedGrp).length > 0 && <div className="flex items-center">
                  {selectedGrp.logo_base64 ? <img src={selectedGrp.logo_base64} alt={selectedGrp.name} width="70" className='border rounded-lg' /> :
                  <img className='rounded-lg image-logo' height={70} width={70} src={selectedGrp && selectedGrp.logo ? selectedGrp.logo : `https://ui-avatars.com/api/?name=${selectedGrp.name}&length=1
                      &background=${selectedGrp.type_details.defaultColorLightTheme.replace('#','')}&format=svg&color=fff`} alt={selectedGrp.name} />}
                  <div className='ml-3'>
                    <div className="text-xl"><b>{selectedGrp.name}</b></div>
                    <div>New Task will be created in this group</div>
                  </div>
                </div>}
                {grpList.length === 0 && <div className="text-red-500 mt-4 mb-2 text-sm">Currently there are no groups. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/groups"); setActiveOption('Groups');}}>here</span></div>}
                <div className='relative mt-2 inline-block'>
                  <input type='text' className="absolute max-w-full h-full opacity-0" value={selectedGrp.grp} />
                  <button type="button" className='choose-org border rounded-lg py-2 px-4 z-10 relative' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => setCollapse(!collapse)}><b>{selectedGrp.grp ? 'Change Group' : 'Select Group'}</b></button> 
                  {!collapse && <div className='groupsDropdownCard' style={{width: '100%', left: 0}}>
                    <div className='pt-2 px-2'>
                      <Search grpList={grpList} setGroupList={setGroupList} />
                    </div>
                    <div className='choose-org py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                      {groupList.length > 0 && groupList.map((data)=>{
                        return(<GroupDropdownCard data={data} setCollapse={setCollapse} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setFormData={setFormData} formData={formData} />)
                      })}
                    </div>
                  </div>}
                </div>
              </div>
              {/* <div className='mt-2'>
                <FileUploader setFormData={setFormData} formData={formData} />
              </div> */}
              {/* <div className='mt-2'>
                <Link setFormData={setFormData} formData={formData} />
              </div> */}
              <div className='mt-2'>
                <Label setFormData={setFormData} formData={formData} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={''} handleCloseAlertModal={''} />
              </div>
              {/* <div className='mt-2'>
                <Checklist taskSlug={formData.tsk} grpSlug={''} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={''} handleCloseAlertModal={''} getAssigneeList={[]} />
              </div> */}
              {/* <div className='mt-2'>
                <Checklist />
              </div> */}
              <div className='mt-2'>
                <CommentMain
                 token={token}
                 formData={formData}
                 setFormData={setFormData}
                 selectedOrganization={selectedOrganization}
                 handleShowAlertModal={handleShowAlertModal}
                 handleCloseAlertModal={handleCloseAlertModal} />
              </div> 
            </div>

            {/* bottom buttom */}
            {/* <div className='ml-auto'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCreateOKR}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
            </div> */}
          </div>
        </div>

        <div className="p-4 pr-0 basis-1/4">
          {/* Content for the 30% wide container */}
          <div className='flex flex-col popup-sidebar'>
            <div className='scrollable pr-1' style={{maxHeight: "75vh"}}>
              {/* status option and popup */}
              {/* <div className='relative w-48'>
                <button type='button' className='status' 
                  style={{
                    color: status.attribute2 ? status.attribute2 : 'var(--secondary-700)',
                    backgroundColor: status.attribute2 ? status.attribute2+'20' : 'var(--secondary-700)'+'20',
                    borderColor:  status.attribute2 ? status.attribute2+'90' : 'var(--secondary-700)'+'50',
                  }} 
                  onClick={() => { setTodoPopup(!todoPopup) }}>
                  <div className='brightness-[0.6]'><i className={status.attribute1 ? status.attribute1 :'fa-regular fa-calendar'}></i></div><div className='brightness-[0.6]'>{status.value ? status.value :'To Do'}</div>
                </button>
                {todoPopup && <Status status={status} setStatus={setStatus} setTodoPopup={setTodoPopup} token={token} formData={formData} setFormData={setFormData} />}

              </div> */}
              {/* assignees options and popup */}
              <AssigneesOKR setFormData={setFormData} formData={formData} grpSlug={selectedGrp?.grp} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />

              {/* watchers options and popup */}
              {/* <WatchersSide setFormData={setFormData} formData={formData} grpSlug={selectedGrp?.grp} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={''} handleCloseAlertModal={''} /> */}

              {/* <Sprint /> */}

              <Progress formData={formData} setCloseEditOKR={handleCreateOKR} setFormData={setFormData} newTask={'yes'} token={token} />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateOKRPopup;