// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .slidecontainer {
    width: 100%;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
  } */
  
  input[type='range'].custom-input-range {
    accent-color: var(--primary);
    background: linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) 0%, #fff 0%, #fff 100%);
    border: solid 1px var(--secondary-300);
    border-radius: 8px;
    height: 7px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }

  .progress-value{
    /* margin-left: 135px; */
    font-size: 11px;
  }

  .progress-readonly {
    background: linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) 0%, #fff 0%, #fff 100%);
    border: solid 1px var(--secondary-300);
    border-radius: 8px;
    height: 7px;
    width: 200px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }`, "",{"version":3,"sources":["webpack://./src/kz-okr/css/slider.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KAiCK;;EAEH;IACE,4BAA4B;IAC5B,yGAAyG;IACzG,sCAAsC;IACtC,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,aAAa;IACb,oCAAoC;IACpC,wBAAwB;EAC1B;;EAEA;IACE,wBAAwB;IACxB,eAAe;EACjB;;EAEA;IACE,yGAAyG;IACzG,sCAAsC;IACtC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,wBAAwB;EAC1B","sourcesContent":["/* .slidecontainer {\n    width: 100%;\n  }\n  \n  .slider {\n    -webkit-appearance: none;\n    width: 100%;\n    height: 25px;\n    background: #d3d3d3;\n    outline: none;\n    opacity: 0.7;\n    -webkit-transition: .2s;\n    transition: opacity .2s;\n  }\n  \n  .slider:hover {\n    opacity: 1;\n  }\n  \n  .slider::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    appearance: none;\n    width: 25px;\n    height: 25px;\n    background: #04AA6D;\n    cursor: pointer;\n  }\n  \n  .slider::-moz-range-thumb {\n    width: 25px;\n    height: 25px;\n    background: #04AA6D;\n    cursor: pointer;\n  } */\n  \n  input[type='range'].custom-input-range {\n    accent-color: var(--primary);\n    background: linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) 0%, #fff 0%, #fff 100%);\n    border: solid 1px var(--secondary-300);\n    border-radius: 8px;\n    height: 7px;\n    width: 100%;\n    outline: none;\n    transition: background 450ms ease-in;\n    -webkit-appearance: none;\n  }\n\n  .progress-value{\n    /* margin-left: 135px; */\n    font-size: 11px;\n  }\n\n  .progress-readonly {\n    background: linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) 0%, #fff 0%, #fff 100%);\n    border: solid 1px var(--secondary-300);\n    border-radius: 8px;\n    height: 7px;\n    width: 200px;\n    outline: none;\n    transition: background 450ms ease-in;\n    -webkit-appearance: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
