import React,{useState, useEffect, useContext} from 'react';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import Select from 'react-select';
import UserService from './../../services/UserService';
import { MultiSelect } from 'react-multi-select-component';

const serverConfig = require("./../../config/server.js");

function OKRFilters({token, filterData, filterObject, setFilterObject, setFilterData, selectedOrganization, setShowFilter, showFilter, timeLog, setTimeLog, startDate, endDate, handleCloseAlertModal, handleShowAlertModal, viewMemberTimesheetPrivileged, setIsLoading, setMemberSlugArr}) {

  const [orgOwnerList, setOrgOwnerList] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [grpList, setGrpList] = useState([]);
//   const [filterObject, setFilterObject] = useState(null);
  const [memberListLookup, setMemberListLookup] = useState([]);
  const [groupListLookup, setGroupListLookup] = useState([]);


  useEffect(() => {

    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
        axios.post(serverConfig.api_base_url + "org_owner_list_get",{token: token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log('org_owner_list_get response', response);
          if(response.data.status === 200){
            const ownerLookup = response.data.items.map((owner) =>{
                return {
                    label: owner?.owner,
                    value: owner?.slug
                }
            })
            setOrgOwnerList(ownerLookup);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            setSearchList([]);
            setSrchList([]);
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));
  
        //getSubtaskList();
      }

  }, []);

  const handleApplyFilter = (e) => {
    
    e.preventDefault();
    console.log(filterObject, 'filter_data');
    setFilterData(filterObject);
    setShowFilter(false);

  }

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
        console.log('selectedOrganization', selectedOrganization)
  
        axios.post(serverConfig.api_base_url + "group_list_get", {token:token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log('group_list_get response', response);
          if(response.data.status === 200){
            if(response.data.items !== null){
              const groups = response.data.items.map((group)=> {
                return {
                    label: group?.name,
                    value: group?.grp
                }
              });
              setGroupList(groups);
            }
          } else if(response.data.status === 301){
                      handleLogout();
                  } else {
                      handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
                  }
        })
        .catch(err=>console.log("error is",err));
      }
  }, []);


  return (
    <div className={showFilter ? "sidebar-right show" : "sidebar-right"}>
      <form onSubmit={handleApplyFilter} className="flex flex-col h-full">
        <div className='flex justify-between items-center gap-5'>
          <div>Filters</div>
          <button type="button" className='my-2 title-text-small-color' onClick={() => {setShowFilter(false); /* setFormData({member_input: null, group_input: null, time_type_input: null}); */}}><i className="fa-solid fa-xmark "></i> </button>
        </div>
        <div className='flex-grow overflow-y-auto my-5'>
          {/* <div className='flex flex-col gap-1 text-xs'>
            {viewMemberTimesheetPrivileged ? <Select value={formData.member_input || ""} 
              classNamePrefix="select_multi" placeholder="Select" options={memberListLookup} onChange={(option)=>  {setFormData({ ...formData, member_input: option});}} isMulti={true} /> : <Select value={memberListLookup.length > 0 ? memberListLookup[0] : ""} 
              classNamePrefix="select" placeholder="Select" options={memberListLookup} isDisabled={true} />}
          </div> */}
          <div className='flex flex-col gap-1 text-xs mt-4'>
            <div className='align-center'>
              <label>Start Date</label>
              <input type="date" value={filterObject?.start_date} className='date-calender-okr w-full p-2 rounded-lg text-black  mt-1 text-xs' onChange={(e) => setFilterObject({ ...filterObject, start_date: e.target.value })} />
            </div>

            <div className='align-center'>
              <label>End Date</label>
              <input type="date" value={filterObject?.end_date} className='date-calender-okr w-full p-2 rounded-lg text-black  mt-1 text-xs' onChange={(e) => setFilterObject({ ...filterObject, end_date: e.target.value })} />
            </div>

            <div className='flex flex-col gap-1 text-xs mt-4'>
            <div className='flex align-center justify-between'>
              <label>Owner</label>
              {/* {formData.group_input?.length > 0 && <button type="button" className='text-red-500' onClick={() => setFormData({ ...formData, group_input: null})}>Remove filter</button>} */}
            </div>
            <MultiSelect
          value={ownerList}
          options={orgOwnerList}
          onChange={(e) => { setOwnerList(e); console.log(e); setFilterObject({ ...filterObject, owner: e })}}
          className='custom-multiselect-branch'
          overrideStrings={{
            "selectSomeItems": `Select Owner`
          }}
        /> 
            {/* <Select value={formData.group_input || ""} classNamePrefix="select_multi" placeholder="Select Owner" options={orgOwnerList} onChange={(option)=>  {}} isMulti={true} /> */}
          </div>

          <div className='flex flex-col gap-1 text-xs mt-4'>
            <div className='flex align-center justify-between'>
              <label>Group</label>
              {/* {formData.group_input?.length > 0 && <button type="button" className='text-red-500' onClick={() => setFormData({ ...formData, group_input: null})}>Remove filter</button>} */}
            </div>
            <MultiSelect
          value={grpList}
          options={groupList}
          onChange={(e) => { setGrpList(e); setFilterObject({ ...filterObject, group: e })}}
          className='custom-multiselect-branch'
          overrideStrings={{
            "selectSomeItems": `Select Group`
          }}
        /> 
            {/* <Select value={formData.group_input || ""} classNamePrefix="select_multi" placeholder="Select Owner" options={orgOwnerList} onChange={(option)=>  {}} isMulti={true} /> */}
          </div>
          </div>
        </div>
        <div className='flex justify-center gap-2'>
          <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium w-2/4' onClick={()=> {setFilterObject({}); setFilterData({}); setOwnerList([]); setGrpList([]);}}>Clear All</button>
          <button type="submit" disabled={filterObject === null} className='btn-popup px-4 py-2 rounded-lg text-sm text-medium w-2/4'>Apply Filter</button>
        </div>
      </form>
    </div>
  );
}

export default OKRFilters;