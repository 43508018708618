import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Context } from '../../contextApi/context';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const serverConfig = require("./../../config/server.js");

function HeaderSecond({ linkName, setPopup, isGroup, isSubgroup, isTask, token, selectedOrganization, groupDetails }) {

  const { collapse } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
    }
  }, [token, selectedOrganization]);

  return (
    <div className={collapse ? 'headerSecond py-3 px-4' : 'headerSecond-collapse py-3 px-4'}>
      <div className='flex justify-center items-center gap-3'>
        {!isGroup && <button title='Click to go back' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i></button>}
        {(isGroup || isTask) && <img className='rounded-lg image-logo' height={40} width={40} src={selectedOrganization?.logo} alt='image' />}
        {groupDetails && <img className='rounded-lg image-logo' height={40} width={40} src={groupDetails && groupDetails?.logo ? groupDetails?.logo : `https://ui-avatars.com/api/?name=${groupDetails.name}&length=1&background=${groupDetails.bgColor}&format=svg&color=fff`} alt='image' />}
        <div>
          <div className='flex gap-2' style={{ alignItems: 'center' }}>
            <div className='image-logo-second'>
              {(isGroup || isTask) && selectedOrganization?.name}
              {groupDetails && groupDetails.name}
            </div>
            <div className='text-sm '> <i className="fa-solid fa-lock"></i></div>
          </div>
          {/* <div className='groupid text-xs'>{isGroup ? 'Organization' : 'Group'} ID : 2462765 <button onClick={() => navigator.clipboard.writeText('2462765aaa')}><i className="fa-regular fa-copy"></i></button>
          </div> */}
          <div className='groupid text-xs'> All {isGroup && 'Groups and Subgroups'}{isSubgroup && 'Subgroups'}{isTask && 'Tasks'} managed by {selectedOrganization?.name} </div>
        </div>
      </div>
      <div className='flex justify-center items-center gap-3'>

        {/* ---- DISABLED THE BELL ICON FOR NOW ---- */}
        {/* <div className='bell flex justify-center px-2 py-1 rounded-lg gap-3'>
          <i className="fa-regular fa-bell text-sm"></i>
          <i className="fa-solid fa-chevron-down text-sm"></i>
        </div> */}
        {/* ---- DISABLED THE BELL ICON FOR NOW ---- */}
        
        {/* <button className='sub-grp px-3 py-2' onClick={() => { setPopup(true) }} >
          {linkName}
        </button> */}
      </div>
    </div>
  )
}

export default HeaderSecond