import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import serverConfig from '../../config/server.js';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../contextApi/context.js';
import "../css/userman.css";
import "../css/cat.css"
import DeleteRes from '../popups/deleteRes.js';
import { Button } from 'react-bootstrap';
import AddResource from '../forms/AddResouce.js';
import EditRes from '../popups/editRes.js';
import AssignToModal from '../popups/assignTo.js';

function CategoryAttr() {
    const [resources, setResources] = useState([]);
    const [categoryName, setCategoryName] = useState(null);
    const { collapse } = useContext(Context);

    //const searchParams = new URLSearchParams(window.location.search);
    const location = useLocation()
    const category = location.state?.Category
    const slug = location.state?.Cat
    const CatAttrId = location.state?.ChildcatID
    const childCategorySlug = location.state?.ChildCat

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [rowToDelete, setRowToDelete] = useState({});
    const [rowToEdit, setRowToEdit] = useState({});
    const [showAddResource, setShowAddResource] = useState(false);
    const [showEditResource, setShowEditResource] = useState(false);
    const [showOptionsIndex, setShowOptionsIndex] = useState(null);
    const [initialDynamicColumns, setInitialDynamicColumns] = useState([]);
    const [categoryAttr, setCategoryAttr] = useState({});

    const [showAssignToModal, setShowAssignToModal] = useState(false);
    const [rowToAssign, setRowToAssign] = useState(null);

    const handleShowAssignTo = (row) => {
        setRowToAssign(row);
        setShowAssignToModal(true);
    };


    const handleShowMoreOptions = (index) => {
        setShowOptionsIndex(showOptionsIndex === index ? null : index);
    };

    const navigate = useNavigate();

    const getData = async () => {
        try {
            const response = await axios.post(serverConfig.api_base_url + 'get_category_details', {
                category_slug: slug,
                category_name: category,
            });
    
            if (response.data.status === 200) {
                const categoryDetails = response.data.category_details;
                if (categoryDetails.child_categories && categoryDetails.child_categories.length > 0) {
                    const childCategories = categoryDetails.child_categories;
                    const mappedChildCategories = childCategories.map((childCategory) => {
                        return {
                            child_category_name: childCategory.child_category_name,
                            resources_count: childCategory.resources ? childCategory.resources.length : 0,
                            resources: childCategory.resources || [],
                            child_category_id: childCategory.child_category_id,
                            child_category_attributes: childCategory.child_category_attributes,
                        };
                    });
    
                    console.log('Mapped Child Categories:', mappedChildCategories);
    
                    if (CatAttrId) {
                        const selectedChildCategory = mappedChildCategories.find(
                            (childCategory) => childCategory.child_category_id === parseInt(CatAttrId)
                        );
    
                        if (selectedChildCategory) {
                            console.log('Selected Child Category:', selectedChildCategory);
                            const selectedResources = selectedChildCategory.resources;
                            setResources(selectedResources);
                            setCategoryName(selectedChildCategory.child_category_name);
                            setCategoryAttr(selectedChildCategory.child_category_attributes);
                        } else {
                            setResources([]);
                            setCategoryName(null);
                            setCategoryAttr({});
                        }
                    }
                }
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching category details:', error);
        }
    };
    
    const handleShowDeleteCategoryModal = (row) => {
        setRowToDelete(row);
        setShowDeletePopup(true);
    };

    const handleDeleteCategorySuccess = () => {
        getData();
        setShowDeletePopup(false);
    };

    const addResourceSuccess = () => {
        getData();
    };

    useEffect(() => {
        if (!location.state) {
            navigate('/manage-resource'); 
        } else {
            getData();
            console.log("loca",location.state);
        }
    }, [slug, category, showEditResource,location.state, navigate]);

    const handleShowEditResourceModal = (row) => {
        setRowToEdit(row);
        setShowEditResource(true);
    };

    const handleEditResourceSuccess = async () => {
        getData();
        setShowEditResource(false);

    };

    const initialColumns = [
        {
            name: '',
            cell: (row) => (
                <div className='flex-column flex items-center py-2'>
                    <img
                        src={`https://ui-avatars.com/api/?name=${row.resource_name}&rounded=true&length=1&${row.resource_name ===
                            'Project' ? 'background=9D6EF8' : row.resource_name === 'Departments' ? 'background=F6B637' : 'background=6ADA00'}&format=svg&color=fff`}
                        alt='image'
                        height={40}
                        width={40}
                        className='rounded-full'
                    />
                </div>
            ),
            grow: 0,
            sortable: false,
        },
        {
            name: <div className='row-label '>Resource </div>,
            selector: 'resource_name',
            cell: (row) => <div className='row-label '>{row.resource_name}</div>,
            sortable: false,
            wrap: true,
        },
        {
            name: <div className='row-label '>Description</div>,
            selector: 'resource_description',
            cell: (row) => <div >{row.resource_description}</div>,
            sortable: false,
            wrap: true,
        },
    ];

    const [dynamicColumns, setDynamicColumns] = useState([]);

    useEffect(() => {
        if (resources.length > 0) {
            const firstResource = resources[0];
            const attributeColumns = [];
            console.log("ress", resources[0]);
            if (firstResource && firstResource.attributes) {
                firstResource.attributes.forEach((attribute, index) => {
                    console.log("Attr -", attribute);
                    const column = {
                        name: attribute.attribute_name,
                        selector: attribute.attribute_id !== '' ? `attributes.${index}.value` : '',
                        sortable: false,
                        cell: (row) => (
                            <div>
                                <div >
                                    {attribute.attribute_id !== '' &&
                                        row.attributes &&
                                        row.attributes[index] &&
                                        row.attributes[index].value !== null
                                        ? row.attributes[index].value
                                        : ''}
                                </div>
                            </div>
                        ),
                        value: {
                            name: attribute.attribute_name,
                            value: attribute.description,
                        },
                    };

                    attributeColumns.push(column);
                });
            }

            setDynamicColumns(attributeColumns);
        }
    }, [resources]);


    const allColumns = [...initialColumns, ...dynamicColumns];

    const actionColumn = {
        name: <div className='row-label'>Actions</div>,
        cell: (row, index) => (
            <div className="text-right more-options-wrapper">
                <button
                    type="button"
                    onClick={() => handleShowMoreOptions(index)}
                    title="More"
                    className='btn-more-options'
                >
                    <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                </button>
                {showOptionsIndex === index && (
                    <div className="more-options">
                        <Button variant='text' onClick={() => handleShowEditResourceModal(row)}>
                            <i className="fa-solid fa-pencil not-0" aria-hidden="true"></i>
                            <span>Edit</span>
                        </Button>
                        <Button variant='text' onClick={() => handleShowDeleteCategoryModal(row)}>
                            <i className="fa-solid fa-trash not-0" aria-hidden="true"></i>
                            <span>Delete</span>
                        </Button>
                        <Button variant='text' onClick={() => handleShowAssignTo(row)}>
                            <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                            <span>Assign To </span>
                        </Button>
                    </div>
                )}
            </div>
        ),
        subHeader: true,
        width: '150px',
    };

    allColumns.push(actionColumn);

    useEffect(() => {
        if (resources && resources.length > 0 && resources[0]?.attributes) {
            const cols = resources[0].attributes.map(attribute => attribute.attribute_name);
            setInitialDynamicColumns(cols);
        } else {
            const cols = categoryAttr ? Object.keys(categoryAttr).map(attrName => categoryAttr[attrName].attribute_name) : [];
            setInitialDynamicColumns(cols);
        }
    }, [resources, categoryAttr]);


    return (
        <div className={collapse ? 'content-body' : 'content-body-collapse'}>
            <div className='container-fluid'>
                <div className='rounded-3xl p-3 table-custom mt-4 '>
                    {showDeletePopup && (
                        <div className='overlay'>
                            <DeleteRes
                                setDeleteConfirmation={setShowDeletePopup}
                                rowToDelete={rowToDelete}
                                onDeleteSuccess={handleDeleteCategorySuccess}
                            />
                        </div>
                    )}
                    {showAddResource && (
                        <div className='overlay'>
                            <AddResource
                                setAddResource={setShowAddResource}
                                categorySlug={childCategorySlug}
                                dynamicColumns={initialDynamicColumns}
                                onAddSuccess={addResourceSuccess}
                            />
                        </div>
                    )}
                    {showAssignToModal && (
                        <div className='overlay'>
                            <AssignToModal
                                setAssignToModal={setShowAssignToModal}
                                rowToAssign={rowToAssign}
                            // Pass any necessary props
                            />
                        </div>
                    )}

                    {showEditResource && (
                        <div className='overlay'>
                            <EditRes
                                setEditResource={setShowEditResource}
                                rowToEdit={rowToEdit}
                                onEditSuccess={handleEditResourceSuccess}
                                dynamicColumns={dynamicColumns}
                                catSlug={childCategorySlug}
                            />
                        </div>
                    )}
                    {allColumns.length > 0 && (
                        <DataTable
                            title={
                                <div className="table-title rounded flex justify-between items-center">
                                    <div className="flex space-x-4">
                                        <div
                                            className='font-semibold cursor-pointer'
                                            onClick={() => navigate('/manage-resource')}
                                        >
                                            Manage Resources
                                        </div>
                                        <div
                                            className='font-semibold cursor-pointer'   
                                            onClick={() => navigate(`/Category-details`,{state:{category:category, cat:slug}})}
                                        >
                                            {<span>{` > ${category}`}</span>}
                                        </div>
                                        {<span>{` > ${categoryName}`}</span>}
                                    </div>
                                    <div className="flex items-center space-x-4 text-black ">
                                        <div className="relative flex items-center gap-1">
                                            <div className="relative">
                                                <div className="absolute inset-y-5 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                                                    <i className="text-sm fas fa-search"></i>
                                                </div>
                                                <input
                                                    category_name="text"
                                                    placeholder="Search"
                                                    className="w-30 md:w-48 h-2 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300 search-input"
                                                />
                                            </div>
                                        </div>
                                        <div className="relative inline-block">
                                            <div className="h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
                                                <div className='pr-5'>
                                                    <i className="fa-solid fa-arrow-down-short-wide text-sm"></i>
                                                </div>
                                            </div>
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                                <i className="fa-solid fa-chevron-down text-sm"></i>
                                            </div>
                                        </div>
                                        <button className='sub-grp px-2 py-2' onClick={() => setShowAddResource(true)}>
                                            Add Resource
                                        </button>

                                    </div>
                                </div>
                            }
                            columns={allColumns}
                            data={resources}
                            responsive={true}
                            pagination={true}
                            noDataComponent={
                                <div className='flex flex-col h-full items-center justify-center mt-20'>
                                    <div className='mb-8'>
                                        <img src={require('../images/noResource.png')} alt="No Resources" />
                                    </div>
                                    <p className='mt-8'><b>{categoryName} has no Resources</b></p>
                                    <p className='text-sm'>Create the first Resource for {categoryName}</p>
                                    <button className='sub-grp px-2 py-2' onClick={() => setShowAddResource(true)}>
                                        Add Resource
                                    </button>
                                </div>

                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default CategoryAttr;
