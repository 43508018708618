import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../../contextApi/context.js";
import './../css/style.css';
import Search from "./Search.js";

const serverConfig = require("../../config/server.js");


function GroupDropdownCard({data, selectedGrp, setSelectedGrp, setCollapse, setGroupList, grpList, setGrpList, token, selectedOrganization, handleLogout, handleShowAlertModal, handleCloseAlertModal}){
  const { isDarkTheme } = useContext(Context);
  const [subgroupList, setSubgroupList] = useState([]);
  
  const handleGetSubgroupList = (grp, event) => {
    //console.log(grp, event);
    event.target.classList.toggle("fa-angle-up");
    event.target.classList.toggle("fa-angle-down");
    event.target.closest('.border-b').classList.toggle(isDarkTheme ? 'bg-neutral-600' : 'bg-slate-50');
    if(event.target.closest('.oragnisationHover').nextSibling !== null){
      event.target.closest('.oragnisationHover').nextSibling.classList.toggle("hidden");
    }
    axios.post(serverConfig.api_base_url + "group_list_get",{token:token, org:selectedOrganization?.slug, grp:grp})
    .then(response=>{
      console.log('group_list_get response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          console.log(response.data.items);
          setSubgroupList(response.data.items);
          /* for (let i = 0; i < grpList.length; i++) {
            if (grpList[i].grp === grp) {
              grpList[i].child=response.data.items;
            }
          } */

          function addObjectToNestedArray(array, valueToFind, newObject) {
            for (let item of array) {
              if (item.grp === valueToFind) {
                // Found the item, add the new object to its children
                //item.child.push(newObject);
                item.child=newObject;
                return true; // Return true to stop further searching
              }
              // If the item has children, recursively search in children
              if (item.child && item.child.length > 0) {
                if (addObjectToNestedArray(item.child, valueToFind, newObject)) {
                  return true; // Return true if the object was added in the recursive call
                }
              }
            }
            return false; // Return false if the value is not found
          }

          const valueToFind = grp;
          addObjectToNestedArray(grpList, valueToFind, response.data.items);

          console.log(grpList);
          setGroupList(grpList);
          setGrpList(grpList);
        } else {
          setSubgroupList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  return(<div className='border-b'>
    <div title={data.name} className={'flex gap-2 align-middle justify-between p-2 oragnisationHover ' + (selectedGrp?.grp === data?.grp && (isDarkTheme ? 'bg-neutral-900' : 'bg-slate-200'))} style={{alignItems:'center'}}>
      <div className='flex gap-2 align-middle' onClick={() => {setSelectedGrp(data); setCollapse(true); setGroupList(grpList); }}>
        <div>
          {data.logo_base64 === null ? <img src={`https://ui-avatars.com/api/?name=${data?.name}
                          &rounded=false&length=1
                          &background=${data.default_color.replace('#','')}&format=svg&color=fff`} alt='image'
            className='organisation-img rounded-lg' /> : <img src={data.logo_base64} alt={data.logo_name} className='rounded-lg organisation-img' />}
        </div>
        <div className='organisation-heading line-clamp-1'>{data.name}</div>
      </div>
      <div className="flex min-w-max">
        {selectedGrp?.grp === data?.grp && <><i className="fa-solid fa-check text-xs mr-2"></i></>}
        <button type="button" onClick={(e) => handleGetSubgroupList(data.grp, e)}><i className="fa-solid fa-angle-down"></i></button>
      </div>
    </div>
    {data.child && data.child.length > 0 && <div className='pl-3'>
      {data.child.map((data)=>{
        return(<GroupDropdownCard data={data} setCollapse={setCollapse} selectedGrp={selectedGrp} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setGrpList={setGrpList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
      })}
    </div>}
  </div>)
}

const IssueRelations = ({token, handleCloseAlertModal, handleShowAlertModal, handleLogout, grp, groupDetails}) => {

    const {selectedOrganization} = useContext(Context);
    const [issueRelations, setIssueRelations] = useState([]);
    const [createIssueRelation, setCreateIssueRelation] = useState(false);
    const [deleteIssueRelation, setDeleteIssueRelation] = useState(false);
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    const[collapse, setCollapse]=useState(true);
    const[grpList, setGrpList] = useState([]);
    const[groupList, setGroupList] = useState([]);
    const[selectedGrp, setSelectedGrp] = useState('');


    useEffect(()=> {

      if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
        console.log('selectedOrganization', selectedOrganization)
  
        axios.post(serverConfig.api_base_url + "group_list_get", {token:token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log('group_list_get response', response);
          if(response.data.status === 200){
            if(response.data.items !== null){
              setGrpList(response.data.items);
              setGroupList(response.data.items);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));
      }

    },[]);

    const getIssueRelationsList = () => {
        axios.post(serverConfig.api_base_url + "issue_relations_get_list", {
            token: token,
            org: selectedOrganization?.slug
        }).then(response=> {
                if(response.data.status === 200) {                  
                    setIssueRelations(response.data.items);
                }
            }).catch(err => {
                console.log(err);
            })
    }

    const handleDeleteStatus = (e) => {
        e.preventDefault();
        if(token !== null && token !== undefined) {
            axios.post(serverConfig.api_base_url + "issue_relations_delete", {
                token: token,
                org: selectedOrganization?.slug,
                rel: slug
            }).then(response=> {
                console.log(response.data);
                if(response.data.status === 200) {
                    setName('');
                    setDeleteIssueRelation(false);
                    handleShowAlertModal('Issue relation deleted successfully');
                    setTimeout(() => {
                    handleCloseAlertModal();
                  }, 6000);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
            }).catch(err=> {
                console.log(err);
                handleShowAlertModal(err, true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    }, 6000);
                });
        }
        }

    const handleCreate = (e) => {
        e.preventDefault();
        axios.post(serverConfig.api_base_url + "issue_relations_create", {
            token: token,
            org: selectedOrganization?.slug, 
            name: name,
            grp: selectedGrp?.grp
        }).then(response => {
            if(response.data.status === 200) {
                setCreateIssueRelation(false);
                handleShowAlertModal('Issue relation added successfully');
                setName('');
                setSelectedGrp('');
                setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                handleShowAlertModal(response.data.message, true);
                setTimeout(() => {
                  handleCloseAlertModal();
                }, 6000);
              }
        }).catch(err=> {
            console.log(err);
            handleShowAlertModal(err, true);
            setTimeout(() => {
                handleCloseAlertModal();
                }, 6000);
            });
    }

    useEffect(() => {

        getIssueRelationsList();


    }, [createIssueRelation, deleteIssueRelation]);

    return(<>

<div className=" table-title rounded " style={{"padding" : "25px"}}>
              <div className="flex items-center mb-4
              
              ">
                {/* Left side: Three tabs flexed together */}
                <div className="flex space-x-4">
                  <h4 className='font-bold text-base'>Issue Relations</h4>
                </div>
                <span className="ml-2 mt-0.5" onClick={()=>{setCreateIssueRelation(true);}}>
                <i className="fa-solid fa-plus text-base mr-3 font-semibold cursor-pointer" aria-hidden="true"></i>
                </span>               
              </div>
              <div className="flex flex-col">
                {issueRelations.map(item => {
                    return(
                        <div className="flex flex-row">
                          <div className="flex flex-row">

                          <div
            className="border rounded-lg flex items-center bg-white"
            style={{ width: "40px", height: "40px" }}
          >
            {item?.group.logo_base64 ? (
              <img
                src={item?.group.logo_base64}
                alt={item?.group.name}
                width="40"
                height="40"
              />
            ) : (
              <img
                className="rounded-lg image-logo"
                height={40}
                width={40}
                src={
                  item?.group && item?.group.logo
                    ? item?.group.logo
                    : `https://ui-avatars.com/api/?name=${
                      item?.group.name
                      }&length=1&background=${item?.group.default_color?.replace(
                        "#",
                        ""
                      )}&format=svg&color=fff`
                }
                alt={item?.group.name}
              />
            )}
          </div>
                        <div className="relation-container ml-4">
                            {item?.name}
                        </div>
                        </div>
                        {item?.name !== 'Predecessor' && item?.name !== 'Blocked By' &&
                        <div onClick={()=> {setDeleteIssueRelation(true); setName(item?.name); setSlug(item?.rel);}}>
                        <i className="fa-solid fa-xmark text-base font-semibold ml-2 mt-2 cursor-pointer" style={{"color" : "var(--Text-medium-emphasis, #09082699)"}}></i>
                            </div> }
                        </div>
                    )
                })}
              </div>

              {createIssueRelation && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Add Issue Relations</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreate}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" style={{"width" : "auto"}} value={name || ""} onChange={(e)=> { setName(e.target.value) }} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' required/>
                  </div>
                </div>

                <div className='mt-1 mb-5'>
                <fieldset >
                  {Object.keys(selectedGrp).length > 0 && <div className="flex items-center">
                    {selectedGrp.logo_base64 ? <img src={selectedGrp.logo_base64} alt={selectedGrp.name} width="70" className='border rounded-lg' /> :
                    <img className='rounded-lg image-logo' height={70} width={70} src={selectedGrp && selectedGrp.logo ? selectedGrp.logo : `https://ui-avatars.com/api/?name=${selectedGrp.name}&length=1
                        &background=${selectedGrp.default_color.replace('#','')}&format=svg&color=fff`} alt={selectedGrp.name} />}
                    <div className='ml-3'>
                      <div className="text-xl"><b>{selectedGrp.name}</b></div>
                      <div>New Task will be created in this group</div>
                    </div>
                  </div>}
                  {grpList.length === 0 && <div className="text-red-500 mt-4 mb-2 text-sm">Currently there are no groups. Please create one <span className='underline cursor-pointer' onClick={() => {navigate("/groups"); setActiveOption('Groups');}}>here</span></div>}
                  <div className='relative mt-2 inline-block'>
                    <input type='text' className="absolute max-w-full h-full opacity-0" value={selectedGrp.grp} required />
                    <button type="button" className='organisations border rounded-lg py-2 px-4 z-10 relative' style={{borderBottomWidth: '1px', borderTopWidth: '1px'}} onClick={() => setCollapse(!collapse)}><b>{selectedGrp.grp ? 'Change Group' : 'Select Group'}</b></button> <span className='text-red-500 align-top'>*</span>
                    {!collapse && <div className='organisationDropdownCard' style={{width: '20vw', left: 0}}>
                      <div className='pt-2 px-2'>
                        <Search grpList={grpList} setGroupList={setGroupList} />
                      </div>
                      <div className='organisations py-2 mb-2  h-40 overflow-y-auto' style={{borderBottom: 'none'}}>
                        {groupList.length > 0 && groupList.map((data)=>{
                          return(<GroupDropdownCard data={data} setCollapse={setCollapse} selectedGrp={selectedGrp} setSelectedGrp={setSelectedGrp} setGroupList={setGroupList} grpList={grpList} setGrpList={setGrpList} token={token} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} />)
                        })}
                      </div>
                    </div>}
                  </div>
                </fieldset>
              </div>
 
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setCreateIssueRelation(false); setName(''); setSelectedGrp('')}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}

        {deleteIssueRelation && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={(e)=>{handleDeleteStatus(e);}}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Delete Issue Relation</div>
                <button className='my-2 title-text-small-color' onClick={()=>{setDeleteIssueRelation(false)}}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Are you sure you want to delete “<b>{name}</b>“? <br/>
                This action cannot be undone.</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={()=> {setDeleteIssueRelation(false); setName('');}}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs'>Delete Issue Relation</button>
            </div>
          </form>
        </div>}

            </div>

    </>)

}

export default IssueRelations;