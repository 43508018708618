import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar";
//import { useContext } from 'react';
//import { Context } from '../../contextApi/context';
//import { data } from '../../Asset/Data/taskData'
//import Calender from './Calender';
import axios from "axios";
import { Accordion } from 'react-bootstrap';

const serverConfig = require("../../config/server.js");

function Progress({
  formData,
  progress,
  setFormData,
  newTask,
  token,
  handleShowAlertModal,
  handleCloseAlertModal,
}) {
  let todayDate = new Date().toISOString().split("T")[0];
  //const { rowId } = useContext(Context);
  //const [startDate, setStartDate] = useState(new Date());
  //const [showCalendar, setShowCalendar] = useState(false);
  const [dueDateReminder, setDueDateReminder] = useState([]);

  useEffect(() => {
    axios
      .post(serverConfig.api_base_url + "lookup_list", {
        token: token,
        name: "due_date_reminder",
      })
      .then((response) => {
        console.log("due_date_reminder lookup:", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log("due_date_reminder data:", response.data.items);
            response.data.items.sort(function (a, b) {
              return a.seq_no - b.seq_no;
            });
            setDueDateReminder(response.data.items);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  }, [token]);

  const hoursPerDay = 8;
  function calculateEstimatedHours(startDate, endDate, hoursPerDay) {
    console.log(startDate, endDate, hoursPerDay);

    const start = new Date(startDate);
    const end = new Date(endDate);
    console.log(start);

    // Ensure that the end date is later than the start date
    if (end < start) {
      return 'End date should be later than start date.';
    }
  
    let totalHours = 0;
  
    // Loop through each day between start and end date
    for (let currentDate = new Date(start); currentDate <= end; currentDate.setDate(currentDate.getDate() + 1)) {
      const dayOfWeek = currentDate.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
      // Check if it's a weekday (Monday to Friday)
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        totalHours += hoursPerDay; // Add 8 hours for weekdays
      }
    }
  
    return totalHours;
  }

  return (
    <div className="mt-7">
      <div className="flex items-center justify-start">
        <h2 className="sum-title text-custom">Progress</h2>
      </div>

      {newTask === "no" ? (
        <>
          <div className="progress-estimated mt-1">Progress Percent</div>
          <div className="w-full font-semibold text-sm">
            <ProgressBar
              progress_value={progress}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </>
      ) : (
        <></>
      )}


      <Accordion className="mt-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <small>Select Dates</small>
            <kbd className="arrow-key-down inline-flex items-center px-2 py-1.5 text-gray-800 rounded-lg dark:text-gray-100 ">
              <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
              <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
              </svg>
              <span className="sr-only">Arrow key down</span>
            </kbd>
            <kbd className="arrow-key-up inline-flex items-center px-2 py-1.5 text-gray-800 rounded-lg dark:text-gray-100 ">
              <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                <path d="M9.207 1A2 2 0 0 0 6.38 1L.793 6.586A2 2 0 0 0 2.207 10H13.38a2 2 0 0 0 1.414-3.414L9.207 1Z"/>
              </svg>
              <span class="sr-only">Arrow key up</span>
            </kbd>
          </Accordion.Header>
          <Accordion.Body>
            <div
              className={
                newTask === "no"
                  ? "progress-estimated mt-2"
                  : "progress-estimated mt-2"
              }
            >
              Start Date:
            </div>
            {formData.estimated_start_date < todayDate ? (
              <div className="sum-title mb-4">{formData.estimated_start_date}</div>
            ) : (
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                value={formData.estimated_start_date}
                className="date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs"
                min={new Date().toISOString().substring(0, 10)}
                max={formData.estimated_end_date && formData.estimated_end_date}
                onChange={(e) =>{
                  e.target.value >= todayDate &&
                  setFormData({ ...formData, estimated_start_date: e.target.value });

                  if(formData.estimated_end_date){
                    const totalHours = calculateEstimatedHours(e.target.value, formData.estimated_end_date, hoursPerDay);
                    console.log('Total weekday hours:', totalHours, typeof(totalHours));
  
                    if(typeof(totalHours) === "number"){
                      console.log("is number");
                      setFormData({ ...formData, estimated_start_date: e.target.value, estimated_hours: totalHours })
                    } else {
                      console.log("is NOT number");
                      setFormData({ ...formData, estimated_start_date: e.target.value, estimated_hours: "" })
                    }
                  }
                }}
              />
            )}

            <div
              className={
                newTask === "no"
                  ? "progress-estimated mt-1"
                  : "progress-estimated mt-1"
              }
            >
              Estimated End Date:
            </div>
            {formData.estimated_end_date < todayDate ? (
              <div className="sum-title mb-4">{formData.estimated_end_date}</div>
            ) : (
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                value={formData.estimated_end_date}
                className="date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs"
                min={formData.estimated_start_date ? formData.estimated_start_date : new Date().toISOString().substring(0, 10)}
                onChange={(e) =>{
                  e.target.value >= todayDate &&
                  setFormData({ ...formData, estimated_end_date: e.target.value });

                  if(formData.estimated_start_date){
                    const totalHours = calculateEstimatedHours(formData.estimated_start_date, e.target.value, hoursPerDay);
                    console.log('Total weekday hours:', totalHours, typeof(totalHours));
  
                    if(typeof(totalHours) === "number"){
                      console.log("is number");
                      setFormData({ ...formData, estimated_end_date: e.target.value, estimated_hours: totalHours })
                    } else {
                      console.log("is NOT number");
                      setFormData({ ...formData, estimated_end_date: e.target.value, estimated_hours: "" })
                    }
                  }
                }}
              />
            )}
            <div className="actual-dates">
              {formData.start_date ? (
                <div className="progress-estimated mt-1">Actual Start Date:</div>
              ) : null}
              {formData.start_date && formData.start_date < todayDate ? (
                <div className="sum-title mb-4">{formData.start_date}</div>
              ) : (
                formData.start_date && (
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={formData.start_date}
                    className="date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs"
                    min={new Date().toISOString().substring(0, 10)}
                    onChange={(e) =>
                      e.target.value >= todayDate &&
                      setFormData({ ...formData, start_date: e.target.value })
                    }
                  />
                )
              )}

              {formData.end_date ? (
                <div className="progress-estimated mt-1">Actual End Date:</div>
              ) : null}
              {formData.end_date && formData.end_date < todayDate ? (
                <div className="sum-title mb-4">{formData.end_date}</div>
              ) : (
                formData.end_date && (
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={formData.end_date}
                    className="date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs"
                    min={new Date().toISOString().substring(0, 10)}
                    onChange={(e) =>
                      e.target.value >= todayDate &&
                      setFormData({ ...formData, end_date: e.target.value })
                    }
                  />
                )
              )}
            </div>

            {/* 
            {formData.end_date && (
              <>
                <div className="progress-estimated">End Date:</div>
                <div className="sum-title mb-3">{formData.end_date}</div>
              </>
            )} */}

            <div className="progress-estimated">Due Date:</div>
            <div
              className={
                formData.due_date === "Today"
                  ? "dueto-today mb-2 relative"
                  : formData.due_date < todayDate
                  ? "dueto-past mb-2 relative"
                  : ""
              }
              style={{ display: "inline-flex" }}
            >
              <div className="table-text-dark">
                {formData.due_date && formData.due_date === "Today" ? (
                  "Today"
                ) : formData.due_date < todayDate ? (
                  <span>{formData.due_date}</span>
                ) : (
                  ""
                )}{" "}
                {formData.due_date === "Today" || formData.due_date < todayDate ? (
                  <>
                    <i className="fa-solid fa-triangle-exclamation ml-1"></i>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={formData.due_date}
                      className="date-absolute w-full p-2 rounded-lg text-black  mt-1 text-xs"
                      min={
                        formData.start_date ||
                        new Date().toISOString().substring(0, 10)
                      }
                      onChange={(e) =>
                        setFormData({ ...formData, due_date: e.target.value })
                      }
                    />
                  </>
                ) : (
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={formData.due_date}
                    className="date-calender w-full p-2 rounded-lg text-black  mt-1 text-xs"
                    min={
                      formData.start_date || new Date().toISOString().substring(0, 10)
                    }
                    onChange={(e) =>
                      setFormData({ ...formData, due_date: e.target.value })
                    }
                  />
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="progress-estimated mt-2">Due Date Reminder:</div>
      <select
        className="w-full mt-1 dropdown-task p-2 text-xs"
        value={formData.due_date_reminder}
        onChange={(e) =>
          setFormData({ ...formData, due_date_reminder: e.target.value })
        }
      >
        <option value="">Select</option>
        {dueDateReminder.length > 0 &&
          dueDateReminder.map((item) => (
            <option value={item.value}>{item.value}</option>
          ))}
      </select>

      <div className="progress-estimated mt-4">Estimated Hours:</div>
      <input
        type="number"
        min="0"
        value={formData.estimated_hours}
        placeholder="0 hours"
        className="date-calender w-full p-2 rounded-lg text-black mt-1 text-xs"
        onChange={(e) =>
          setFormData({ ...formData, estimated_hours: e.target.value })
        }
      />

      {/* <div className='progress-estimated mt-5'>Due Date:</div> 
<div className='text-custom font-semibold text-sm '>{data[rowId-1]. dueTo}</div> */}

      {/* <div className='progress-estimated mt-5'>Cost:</div>
      <div className='text-custom font-semibold text-sm '>$180</div> */}

      {/* <button type="button" className='dropdown-task w-full py-2 px-4 flex justify-start align-middle text-custom gap-2 text-xs mt-4'>
        <i className="fa-regular fa-calendar"></i>
        <div className='text-xs'>Dates</div>
      </button>
      <button type="button" className='dropdown-task w-full py-2 px-4 flex justify-start align-middle text-custom gap-2 text-xs mt-4'>
        <i className="fa-solid fa-crosshairs"></i>
        <div className='text-xs'>Actions</div>
      </button> */}
    </div>
  );
}

export default Progress;
