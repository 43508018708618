import React,{useState} from 'react'
import Calendar from 'react-calendar';
import './../css/calender.css';
import 'react-calendar/dist/Calendar.css';
function Calender({date,setDate, hours, setHours, minutes, setMinutes, setShowCalendar}) {
   
   
    const handleDateChange = (newDate) => {
        setDate(newDate);
       console.log(date)
      };

      const handleSave=()=>{
       setShowCalendar(false)
      }
      const handleCancel=()=>{
        setDate(new Date());
        setHours('');
        setMinutes('');
        setShowCalendar(false)
      }
  return (
   <div>
     <div className='calendar-container '>
   
      
  

   <Calendar
   onChange={handleDateChange}
     value={date}
     selectRange={false}
   />
   
  
    {date.length > 0 ? (
   <p className='text-center bg-white'>
     <span className='bold'>Start:</span>{' '}
     {date[0].toString()}
   
    
   </p>
 ) : (
   <div className=' bg-white extended-calender '>
     {/* <span className='bold'>date:</span>{' '}
     {date.toString()} */}
     <div className='px-5 py-4'>
     <div className='due'>Due Date</div>
     <div className='grid grid-cols-2'>
         <div className='md:col-span-1 col-span-2 date-day p-2 mt-2 mr-2'>{date.toDateString()}</div>
         <div className='md:col-span-1 col-span-2 date-day p-2 mt-2 text-center'>
             <input value={hours} onChange={(e)=>{setHours(e.target.value)}} className='w-4' maxLength={2} placeholder='12'/> :
             <input value={minutes} onChange={(e)=>{setMinutes(e.target.value)}} className='w-5' placeholder='00' maxLength={2}/>
         </div>
     </div>
     </div>
    
     <div className='px-5 py-4 estimated'>
         <div className='due'>Estimated Hours</div>
     <input className='date-day px-4 py-2 mt-2 text-sm text-medium' placeholder='0w 0d 0h 0m'/>

     </div>
     <div className='grid grid-cols-2 mx-5'>
     <button className='date-day px-4 py-2 mt-2 text-sm text-medium md:col-span-1 col-span-2 ' onClick={handleCancel}>Remove</button>
        <button className='btn-popup px-4 py-2 rounded-2xl mt-2 text-sm text-medium md:col-span-1 col-span-2 mr-2' onClick={handleSave}>Save</button>      
     </div>
   </div>
 )}
 </div>


   </div>

   
  )
}

export default Calender