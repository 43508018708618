import React, { useState, useEffect } from "react";
import axios from "axios";

const serverConfig = require("../../config/server.js");

function Comment({
  token,
  selectedOrganization,
  handleShowAlertModal,
  handleCloseAlertModal,
  reportDate,
  reportComments,
}) {
  const [comment, setComment] = useState("");
  const [refreshCommentList, setRefreshCommentList] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [localComments, setLocalComments] = useState(Array.isArray(reportComments) ? reportComments : []);

useEffect(() => {
  setLocalComments(Array.isArray(reportComments) ? reportComments : []);
}, [reportComments]);

  const handleSubmitComments = () => {
    console.log(comment);
    const is_private = isPrivate === true ? 1 : 0;

    if (comment !== null && comment !== undefined && comment !== "") {
      const newComment = {
        by: "You", // Assuming 'You' as a placeholder, replace with actual user data if available
        created_at: new Date().toISOString(),
        comment: comment,
      };

      // Temporarily add the new comment to the local state (at the bottom)
      setLocalComments([...localComments, newComment]);

      axios
        .post(serverConfig.api_base_url + "scrum_report_comment", {
          token: token,
          org: selectedOrganization?.slug,
          report_date: reportDate,
          comment: comment,
          is_private: is_private,
        })
        .then((response) => {
          console.log("scrum_report_comment response", response);
          if (response.data.status === 200) {
            console.log("success");
            handleShowAlertModal(`Your comment "${comment}" has been added`);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
            setComment("");
            setRefreshCommentList(!refreshCommentList); // Trigger a refresh when the real data is fetched
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  };

  return (
    <div className="mt-2">
      <label>Comments :</label>
      {localComments && localComments.length > 0 && (
        <ul className="mt-5">
          {localComments.map((item, index) => (
            <li className="flex items-start text-sm mb-2" key={index}>
              <img
                className="rounded-3xl image-logo"
                height={40}
                width={40}
                src={`https://ui-avatars.com/api/?name=${
                  item.by.split(" ")[0]
                }+${
                  item.by.split(" ").length > 1 ? item.by.split(" ")[1] : ""
                }&length=2&background=6ADA00&format=svg&color=fff`}
                alt={item.by}
              />
              <div className="ml-2">
                <div>
                  <b>{item.by}</b>
                  <span className="ml-2">
                    {new Date(item.created_at).toLocaleString()}
                  </span>
                </div>
                <p>{item.comment}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div>
        <textarea
          className="comment-box w-full text-sm text-custom p-2 h-24"
          onChange={(e) => {
            setComment(e.target.value);
          }}
          value={comment}
        />

        {/* Toggle Switch for "Is Private?" */}
        <div className="flex items-center mt-2">
          <label className="mr-2 text-sm">Is Private?</label>
          <input
            type="checkbox"
            className="toggle-switch"
            checked={isPrivate}
            onChange={() => setIsPrivate(!isPrivate)}
          />
        </div>

        <button
          type="button"
          className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
          onClick={handleSubmitComments}
        >
          Post
        </button>
      </div>
    </div>
  );
}

export default Comment;

