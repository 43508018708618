import React,{useState, useContext} from 'react'
import { Context } from '../contextApi/context';
import HeaderSecond from './components/HeaderSecond';
import TableTeamAccess from './tables/TableTeamAccess';
import TeamAccessPopup from './popups/TeamAccessPopup';
function TeamAccess() {
  
    const {collapse} = useContext(Context)
    const [teamPopup , setTeamPopup]= useState(false)
  return (
    <div>
    {/* <HeaderSecond linkName={'Request Access'} link={'#'} popup={teamPopup} setPopup={setTeamPopup}/> */}
    {teamPopup &&<div className='overlay'> <TeamAccessPopup setTeamPopup={setTeamPopup}/></div>}
    <div className={collapse?'content-body':'content-body-collapse'} >
          
          <div class="container-fluid">
       
   <TableTeamAccess/>
        
          </div>
         
          </div>
    </div>
  )
}

export default TeamAccess