import React,{useState, useContext} from 'react';
import { Context } from '../../contextApi/context';
import AllSettings from "./pages/AllSettings";

const Settings = () => {
    const {collapse, tab,taskPopup} = useContext(Context)

    return (
        <>
      <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
      <div class="container-fluid">
      <AllSettings/>
      </div>
     
      </div>
    </div>
        </>
    );
}

export default Settings;