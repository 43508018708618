
import user1 from '../Images/user1.jpg';
import user2 from '../Images/user2.jpg';
import user3 from '../Images/user3.jpg';
import user4 from '../Images/user4.jpg';
import user5 from '../Images/user5.jpg';
import user6 from '../Images/user6.jpg';


export const userData = [
    {   
        id:1,
        user:user1,
        name:'Gerard Antony'
    }
    ,{
        id:2,
        user:user2,
        name:'Cooper Workman'
    }
    ,{
        id:3,
        user:user3,
        name:'Desirae Passaquindici Arcand'
    },
    {
        id:4,
        user:user4,
        name:'Kierra Septimus'
    },
    {
        id:5,
        user:user5,
        name:'Kadin Torff'
    },
    {
        id:6,
        user:user6,
        name:'Gerard Antony'
    }
]
   