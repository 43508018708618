 export const data =
 [
    {
        id:1,
        todo:'Data ingestion',
        detail:'DBTEZ | Timze',
        status:'To do',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
       worked:0,
       totalhour:24,
        labels:['Rizing Ahead','Design','Manatoko','Rizing Ahead','Manatoko']
    },
    {
        id:2,
        todo:'New sidebar design',
        detail:'Coldstart | Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:22,
        totalhour:48,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:3,
        todo:'Implementation of Generic Checkpoint Mechanism',
        detail:'Simpson-cox | Timze',
        status:'Done',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
       
        worked:20,
        totalhour:20,
        labels:['Manatoko']
    },
    {
        id:4,
        todo:'Dashboard Design',
        detail:'Simpson-cox | Timze',
        status:'Backlog',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
        worked:3,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:5,
        todo:'Data ingestion',
        detail:'DBTEZ | Timze',
        status:'To do',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Sep 8',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Design']
    },
    {
        id:6,
        todo:'New sidebar design',
        detail:'Coldstart | Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:7,
        todo:'New sidebar design',
        detail:'Coldstart | Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:22,
        totalhour:48,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:8,
        todo:'Implementation of Generic Checkpoint Mechanism',
        detail:'Simpson-cox | Timze',
        status:'Done',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
       
        worked:20,
        totalhour:20,
        labels:['Manatoko']
    },
    {
        id:9,
        todo:'Dashboard Design',
        detail:'Simpson-cox|Timze',
        status:'Backlog',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
        worked:3,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        todo:'Data ingestion',
        detail:'DBTEZ|Timze',
        status:'To do',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Sep 8',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Design']
    },
    {
        id:10,
        todo:'New sidebar design',
        detail:'Coldstart|Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {

        id:11,
        todo:'New sidebar design',
        detail:'Coldstart|Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:22,
        totalhour:48,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:12,
        todo:'Implementation of Generic Checkpoint Mechanism',
        detail:'Simpson-cox|Timze',
        status:'Done',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
       
        worked:20,
        totalhour:20,
        labels:['Manatoko']
    },
    {
        id:13,
        todo:'Dashboard Design',
        detail:'Simpson-cox|Timze',
        status:'Backlog',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
        worked:3,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:14,
        todo:'Data ingestion',
        detail:'DBTEZ|Timze',
        status:'To do',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Sep 8',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Design']
    },
    {
        id:15,
        todo:'New sidebar design',
        detail:'Coldstart|Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:16,
        todo:'New sidebar design',
        detail:'Coldstart|Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:22,
        totalhour:48,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:17,
        todo:'Implementation of Generic Checkpoint Mechanism',
        detail:'Simpson-cox|Timze',
        status:'Done',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
       
        worked:20,
        totalhour:20,
        labels:['Manatoko']
    },
    {
        id:18,
        todo:'Dashboard Design',
        detail:'Simpson-cox|Timze',
        status:'Backlog',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
        worked:3,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    { 
        id:19,
        todo:'Data ingestion',
        detail:'DBTEZ|Timze',
        status:'To do',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Sep 8',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Design']
    },
    {
        id:20,
        todo:'New sidebar design',
        detail:'Coldstart|Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        
        id:21,
        todo:'New sidebar design',
        detail:'Coldstart|Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:22,
        totalhour:48,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:22,
        todo:'Implementation of Generic Checkpoint Mechanism',
        detail:'Simpson-cox|Timze',
        status:'Done',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
       
        worked:20,
        totalhour:20,
        labels:['Manatoko']
    },
    {
        id:23,
        todo:'Dashboard Design',
        detail:'Simpson-cox|Timze',
        status:'Backlog',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Tue, Sep 19, 2023',
        worked:3,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
    {
        id:24,
        todo:'Data ingestion',
        detail:'DBTEZ|Timze',
        status:'To do',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Sep 8',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Design']
    },
    {
        id:25,
        todo:'New sidebar design',
        detail:'Coldstart|Timze',
        status:'In Progress',
        startDate:'Tue, Sep 2, 2023',
        dueTo:'Today',
        worked:9,
        totalhour:20,
        labels:['Rizing Ahead','Manatoko']
    },
]
