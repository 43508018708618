import React, { useContext, useState, useEffect } from "react";
import { Context } from "../contextApi/context";
import desk from "./assets/desk.svg";
import "./OrgLanding.css";
import NewOrganizationPopup from "./NewOrganizationPopup";
import UserService from "../services/UserService";
import axios from "axios";
import logo from "../Asset/Images/logo.png";

const serverConfig = require("./../config/server.js");

const OrgLanding = () => {
  const { collapse, setRefreshOrgList, refreshOrgList } = useContext(Context);
  const [newOrganizationPopup, setNewOrganizationPopup] = useState(false);
  const [close, setClose] = useState(false);
  const [token, setToken] = useState(null);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);
    const handleCloseAlertModal = () => {
      setAlertMessage("");
      setShowOkButton(false);
      setAlertModalShow(false);
    }
    const handleShowAlertModal = (alert_msg, show_ok_button) => {
      setAlertMessage(alert_msg);
      setShowOkButton(show_ok_button);
      setAlertModalShow(true);
    }

  const handleLogout = () => {
    sessionStorage.clear();
    window.history.replaceState({}, "", "/");
    navigate("/");
    UserService.doLogout();
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", {
        jwt: jwt,
        url: window.location.host,
      })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          if (organizationList.length > 0) {
            navigate("/users");
          } else {
            navigate("/members");
          }
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          alert(response.data.message);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  return (
    <>
      {close === false && (
        <div className="welcome-container pl-40">
          <h1 className="inline-flex items-center text-4xl pt-20">
            Welcome to the <img src={logo} alt="Logo" /> Experience!
          </h1>

          <div>
            <div className="mx-80 pt-20">
              <img src={desk} alt="Banner" />
            </div>
            <div
              className="pt-10 pl-20"
              style={{ alignItems: "center" }}
            >
              <div className="md:mr-10 pt-10">
                <div>Start by adding your Organisation</div>
                <div>
                  We notice that you haven’t added any organisation.
                  <br /> Add an organisation so that you can invite your
                  colleagues and collaborate together.
                </div>
                <button
                  type="submit"
                  onClick={() => {
                    setNewOrganizationPopup(true);
                  }}
                  className="btn-popup px-4 py-2 rounded-lg mt-6 text-sm text-medium mt-20"
                >
                  Create Organization
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {newOrganizationPopup && (
        <div className="overlay">
          <NewOrganizationPopup
            setNewOrganizationPopup={setNewOrganizationPopup}
            token={token}
            handleLogout={handleLogout}
          />
        </div>
      )}
    </>
  );
};

export default OrgLanding;
