import React, { useEffect, useState } from "react";
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";


const GanttChart = ({ message, tasks, isLoading, setIsLoading, handleCloseAlertModal, handleShowAlertModal }) => {

	const [taskList, setTaskList] = useState([]);

    const [view, setView] = useState(ViewMode.Day);
    const [isChecked, setIsChecked] = useState(true);

	const getRandomColor = () => {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	};
	
	const assignColorsToTypes = (tasks) => {
		const typeColorMap = {};
		tasks.forEach(task => {
			if (!typeColorMap[task.type]) {
				typeColorMap[task.type] = getRandomColor();
			}
			task.color = typeColorMap[task.type];
		});
		return tasks;
	};

	

	    return (
        <>
		 {message !== '' ? 
             <div className="flex flex-col h-full items-center justify-center">
			 <div className="mb-8">
			   <img
				 src={require("./../images/no-groups-subgroups.png")}
				 alt="No records"
			   />
			 </div>			
			   <p style={{"color" : "red"}}>
				 <b>{message}</b>
			   </p>
			  
		   </div>
          :
            <div style={{marginTop: '10px'}}>
                {/* <ViewSwitcher
                    onViewModeChange={setView}
                    onViewListChange={setIsChecked}
                    isChecked={isChecked}
                /> */}

                {/* ORIGINAL */}
				<Gantt
				tasks={assignColorsToTypes(tasks)}
				viewMode={view}
				ganttHeight={"56vh"}
				todayColor="var(--primary-opactiy-10)"
				/>
            </div>}
        </>
    );

}

export default GanttChart;






// import "gantt-task-react/dist/index.css";
// import {
//     GanttOriginal,
//     ViewMode,
// } from "react-gantt-chart";

// const GanttChart = ({ tasks }) => {
//     const [taskList, setTaskList] = useState(tasks);

//     const [view, setView] = useState(ViewMode.Month);
//     const [isChecked, setIsChecked] = useState(true);

//     // *** CONSTANTS ***
//     let columnWidth = 60;
//     if (view === ViewMode.Month) {
//         columnWidth = 300;
//     } else if (view === ViewMode.Week) {
//         columnWidth = 250;
//     }

//     // *** HANDLERS ***
//     const handleTaskChange = (task) => {
//         console.log("On date change Id:" + task.id);

//         let newTasks = taskList.map((t) => (t.id === task.id ? task : t));

//         if (task.project) {
//             const [start, end] = getStartEndDateForProject(newTasks, task.project);
//             const project =
//                 newTasks[newTasks.findIndex((t) => t.id === task.project)];

//             if (
//                 project.start.getTime() !== start.getTime() ||
//                 project.end.getTime() !== end.getTime()
//             ) {
//                 const changedProject = { ...project, start, end };
//                 newTasks = newTasks.map((t) =>
//                     t.id === task.project ? changedProject : t
//                 );
//             }
//         }

//         setTaskList(newTasks);
//     };

//     const handleTaskDelete = (task) => {
//         const conf = window.confirm("Are you sure about " + task.name + " ?");
//         if (conf) {
//             setTaskList(taskList.filter((t) => t.id !== task.id));
//         }
//         return conf;
//     };

//     const handleProgressChange = async (task) => {
//         console.log("On progress change Id:" + task.id);
//         setTaskList(taskList.map((t) => (t.id === task.id ? task : t)));
//     };

//     const handleDblClick = (task) => {
//         console.log("On Double Click event Id:" + task.id);
//     };

//     const handleSelect = (task, isSelected) => {
//         console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
//     };

//     const handleExpanderClick = (task) => {
//         console.log("On expander click Id:" + task.id);
//         setTaskList(taskList.map((t) => (t.id === task.id ? task : t)));
//     };

//     useEffect(() => {
// 		console.log(taskList, 'tasks');
//     }, [taskList]);

//     return (
//         <>
//             <div>
//                 {/* <ViewSwitcher
//                     onViewModeChange={setView}
//                     onViewListChange={setIsChecked}
//                     isChecked={isChecked}
//                 /> */}

//                 {/* ORIGINAL */}
//                 <GanttOriginal
//                     tasks={taskList}
//                     viewMode={view}
//                     // handlers
//                     onDateChange={handleTaskChange}
//                     onDelete={handleTaskDelete}
//                     onProgressChange={handleProgressChange}
//                     onDoubleClick={handleDblClick}
//                     onSelect={handleSelect}
//                     onExpanderClick={handleExpanderClick}
//                     // styles
//                     listCellWidth={isChecked ? "155px" : ""}
//                     ganttHeight={"800px"}
//                     columnWidth={columnWidth}
//                 />
//             </div>
//         </>
//     );
// };

// export default GanttChart;