import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../contextApi/context";
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import axios from "axios";
import UserService from "../../services/UserService";
import SubItemsMap from "./SubItemsMap";
import Notification from "../../common/notification/Notification.js";

const serverConfig = require("../../config/server.js");

const MapAccessTable = () => {

  const {selectedOrganization, isDarkTheme} = useContext(Context);
  const [tableData, setTableData] = useState([]);
  const [token, setToken] = useState(null);
  const [refreshAccessList, setRefreshAccessList] = useState(false);
  const [formData, setFormData] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [createAccessModalShow, setCreateAccessModalShow] = useState(false);
  const [editAccessModalShow, setEditAccessModalShow] = useState(false);
  const [deleteAccessModalShow, setDeleteAccessModalShow] = useState(false);
  const [accessSlug, setAccessSlug] = useState("");
  const [accessName, setAccessName] =useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  const handleCloseEditAccessModal = () => {
    setEditAccessModalShow(false);
    setAccessSlug("");
    setAccessName("");
    setFormMessage({});
    setFormData({});
  }
  const handleShowEditAccessModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditAccessModalShow(true);
  }

  const handleCloseDeleteAccessModal = () => {
    setDeleteAccessModalShow(false);
    setAccessSlug("");
    setAccessName("");
    setFormMessage({});
    setFormData({});
  }
  const handleShowDeleteAccessModal = (slug, name) => {
    setDeleteAccessModalShow(true);
    setAccessSlug(slug);
    setAccessName(name);
  }

  const handleCloseCreateAccessModal = () => {
    setCreateAccessModalShow(false);
    setFormMessage({});
    setFormData({});
  }

  const handleShowCreateAccessModal = () => {
    setCreateAccessModalShow(true);
  }

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);


  const handleDeleteAccess = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "access_delete",{token:token, acc:accessSlug})
    .then(response=>{
      console.log("access_delete:", response);
      if (response.data.status === 200) {
        setRefreshAccessList(!refreshAccessList);
        handleCloseDeleteAccessModal();
        handleShowAlertModal('Access Deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const handleCreateAcess = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "access_create",{
      token:token,
      org:selectedOrganization?.slug,
      access: formData.name,
      description: formData.description,
      logo: formData.logo_base64
    })
    .then(response=>{
      console.log("access_create:", response);
      if (response.data.status === 200) {
        setFormData({});
        setRefreshAccessList(!refreshAccessList);
        handleCloseCreateAccessModal();
        handleShowAlertModal('Access created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const handleUpdateAccess
   = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "access_update",{
      token:token,
      acc:formData.slug,
      access: formData.access_name,
      description: formData.description,
      logo: formData.logo_base64
    })
    .then(response=>{
      console.log("access_update :", response);
      if (response.data.status === 200) {
        setFormData({});
        setRefreshAccessList(!refreshAccessList);
        handleCloseEditAccessModal();
        handleShowAlertModal('Access created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  useEffect(() => {
    if(token !== null && token !== undefined) {
    axios.post(serverConfig.api_base_url + "access_list_get",{token:token, org:selectedOrganization?.slug})
    .then(response=>{
      console.log("access_list_get:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("access_list_get data:", response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  }, [token, selectedOrganization]);


  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);
      /* dwh_dbs_list_get */
      axios.post(serverConfig.api_base_url + "access_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("dwh_dbs_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(response.data.items);
            let appDataArray = response.data.items;
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              {item.actions = (<div className="text-right  ml-4">
                <Button variant='text' title="Edit" onClick={() => handleShowEditAccessModal(item)}><i className="fa-solid fa-pencil not-0 mr-2" aria-hidden="true"></i></Button>
                <Button variant='text' title="Delete" onClick={() => handleShowDeleteAccessModal(item?.slug, item?.access_name)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i></Button>
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setTableData(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
    
  }, [token, selectedOrganization, refreshAccessList]);

  const getBase64 = (file, e) => {
    console.log(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      if(e.target.name === 'logo') {
        setFormData({ ...formData, logo: file.name, logo_base64: reader.result});
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }


  const columns = [
    {
      name: <div className='font-bold text-sm'>Access Name</div>,
      selector: row => <b className='text-sm'>{row.access_name}</b>,
      wrap: true,
      width: '40%'
    },
    {
      name: <div className='font-bold text-sm'>Description</div>,
      selector: row => row.description,
      wrap: true,
      width: '40%'
    },
    {
      name: <div className='font-bold text-sm'>Actions</div>,
      selector: row => row.actions,
      wrap: true,
      width: 'calc(15% - 49px)'
    },
  ];



    return (
        <>
         <div className="overflow-x-auto">
      <div className="p-3 table-custom table-dbs table-mapmetadata mt-4 ">
        <div className="flex items-center justify-between space-x-4 text-black">
          <div>
          <h1 className='font-semibold text-2xl theader'>Access</h1>
          </div>
          <div className="flex items-center space-x-4 text-black">
           {tableData.length > 0 && <><div class="flex items-center gap-12 mt-4">
            <div class="relative">
              <input type="text" placeholder="Search" class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input" />
              <div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                <i class=" text-sm fas fa-search"></i>
              </div>
            </div>
          </div>
          <div className="relative inline-block">
            <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
              <div className='pr-5'>
                <i class="fa-solid fa-arrow-down-short-wide text-sm" ></i>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <i class="fa-solid fa-chevron-down text-sm"></i> 
            </div>
          </div>
          </>}
          <button type='button' className='sub-grp px-3 py-2' onClick={handleShowCreateAccessModal}><i className="fa-solid fa-plus mr-2" aria-hidden="true"></i> Create Access</button>
          </div>
        </div>


        {tableData.length > 0 ? <DataTable
          columns={columns}
          data={tableData}
          expandableRows
          //expandableRowsComponent={({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>}
          expandableRowsComponent={({ data }) => <SubItemsMap accessSlug={data.slug} access={data.access_name} />}
          responsive={true}
          pagination={tableData.length > 10 ? true : false}
          highlightOnHover
          customStyles={{
            rows: {
              style: {
                minHeight: '48px',
                border: '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          //subHeader subHeaderComponent={<div className="flex items-center justify-between" style={{position: "absolute", left: 0, top: "55px", zIndex: 9, width: "100%"}}><span className='font-semibold text-xs'>Access</span><span className="text-right" style={{width: "15%", paddingRight: "16px"}}><Button variant='text' title="Create DB Service" onClick={handleShowCreateAccessModal}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></Button></span></div>} subHeaderAlign="left"
        /> : 
        
        <div className='flex flex-col h-3/4 items-center justify-center'>
                <div className='mb-8'><img src={require('../../Asset/Images/no-records.png')} alt="No records" /></div>
                <p><b>This Organisation does not have any access.</b></p>
                <p className='text-sm'>Create first access for {selectedOrganization?.name}</p>
                <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={handleShowCreateAccessModal}>Create Access</button></div>
              </div>
        
        }

        {createAccessModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={handleCreateAcess}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Create Access</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseCreateAccessModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name<span className=' text-red-500'>*</span></label>
                  <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>
              
              <div className='flex flex-col gap-1 mt-4 text-xs'>
                <label>Logo</label>
                <div className="flex items-center">
                  {formData.logo_base64 && <img src={formData.logo_base64} alt="logo" width="70" className='w-auto max-w-full rounded-md' style={{maxHeight: "60px"}} />}
                  <div className={formData.logo_base64 ? "flex flex-col ml-3" : "flex flex-col"}>
                    <label className="custom-file-upload">
                      <input  type='file' value="" onChange={(e)=>  {getBase64(e.target.files[0], e)}} name="logo" accept='.jpg, .jpeg, .png' className='custom-input py-2 px-2 text-left mt-1'/>
                      {formData.logo_base64 ? <>Choose Another File</> : <>Choose File</>}
                    </label>
                    <small className='text-slate-400 mt-2'>File Size Limit: 1Mb</small>
                  </div>
                </div>
              </div>
           
                <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Description<span className=' text-red-500'>*</span></label>
                  <textarea type="text" value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>
               
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateAccessModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
            </div>
          </form>
        </div>}

        {editAccessModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={handleUpdateAccess}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Edit Access</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseEditAccessModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name</label>
                  <input type="text" value={formData.access_name || ""} onChange={(e)=>  setFormData({ ...formData, access_name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1'/>
                </div>

                <div className='flex flex-col gap-1 mt-4 text-xs'>
                <label>Logo</label>
                <div className="flex items-center">
                  {formData.logo_base64 && <img src={formData.logo_base64} alt="logo" width="70" className='w-auto max-w-full rounded-md' style={{maxHeight: "60px"}} />}
                  <div className={formData.logo_base64 ? "flex flex-col ml-3" : "flex flex-col"}>
                    <label className="custom-file-upload">
                      <input  type='file' value="" onChange={(e)=>  {getBase64(e.target.files[0], e)}} name="logo" accept='.jpg, .jpeg, .png' className='custom-input py-2 px-2 text-left mt-1'/>
                      {formData.logo_base64 ? <>Choose Another File</> : <>Choose File</>}
                    </label>
                    <small className='text-slate-400 mt-2'>File Size Limit: 1Mb</small>
                  </div>
                </div>
              </div>

              <div className='flex flex-col gap-1 mt-3 text-xs'>
                  <label>Description<span className=' text-red-500'>*</span></label>
                  <textarea type="text" value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>
          
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditAccessModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
            </div>
          </form>
        </div>}

        {deleteAccessModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleDeleteAccess}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Access</div>
                <button className='my-2 title-text-small-color' onClick={handleCloseDeleteAccessModal}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Access <b>{accessName}</b> ?</p>
                <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteAccessModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}

        </div>
        </div>
        </>
    )
}

export default MapAccessTable;