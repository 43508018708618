import React from 'react'
import { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import UserService from './../../services/UserService';
import "../css/vexa.css";
import Notification from '../../common/notification/Notification.js';

import CreateVexa from '../components/CreateVexa.js';
import EditVexa from '../components/EditVexa.js';
import DeleteVexa from '../components/DeleteVexa.js';

const serverConfig = require("./../../config/server.js");

function Table() {

  const searchParams = new URLSearchParams(window.location.search);
  const vexaSlug = searchParams.get('slug');

  const { selectedOrganization, isDarkTheme } = useContext(Context);
  
  /* token - start */
  const [token, setToken] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [metaEntity, setMetaEntity] = useState(null);
  const [metaAttributes, setMetaAttributes] = useState([]);
  const [metaChildren, setMetaChildren] = useState([]);
  const [refreshVexaList, setRefreshVexaList] = useState(false);

  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token", { jwt: jwt, url: window.location.host })
      .then(response => {
        console.log('user_get_token response', response);
        if (response.data.status === 200) {
          sessionStorage.setItem('token', response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch(err => console.log("error is", err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if (sessionStorage.getItem('token') === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      axios.post(serverConfig.api_base_url + "vexa_get_meta",{
        token: token,
        org: selectedOrganization?.slug,
        entity: vexaSlug,
      })
      .then(response=>{
        console.log("vexa_get_meta response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(response.data.items);
            if(items.entity !== null || items.entity !== undefined){
              setMetaEntity(items.entity);
            } else {
              setMetaEntity(null);
            }

            if(items.attributes !== null || items.attributes !== undefined){
              setMetaAttributes(items.attributes);
            } else {
              setMetaAttributes([]);
            }

            if(items.children !== null || items.children !== undefined){
              setMetaChildren(items.children);
            } else {
              setMetaChildren([]);
            }
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "vexa_list",{
        token: token,
        org: selectedOrganization?.slug,
        entity: vexaSlug,
        limit: 10,
        offset:0,
      })
      .then(response=>{
        console.log("vexa_list response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(items);
            setTableData(items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization, refreshVexaList]);

  useEffect(() => {
    if(metaAttributes.length > 0){
      setColumns(metaAttributes.sort(({sequence_no:a}, {sequence_no:b}) => a-b).map(obj => {
        return {
          ...obj,
          name: <div className='row-label'>{obj.display_label}</div>,
          selector: row => row[obj.name],
        };
      })
      .concat({
        name: <div className='row-label'>Actions</div>,
        cell: (row) => <div>
          <EditVexa row={row} metaAttributes={metaAttributes} token={token} org={selectedOrganization?.slug} vexaSlug={vexaSlug} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} handleLogout={handleLogout} refreshVexaList={refreshVexaList} setRefreshVexaList={setRefreshVexaList} />

          <DeleteVexa row={row} metaAttributes={metaAttributes} token={token} org={selectedOrganization?.slug} vexaSlug={vexaSlug} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} handleLogout={handleLogout} refreshVexaList={refreshVexaList} setRefreshVexaList={setRefreshVexaList} />
        </div> ,
        button: true,
      }));
    } else {
      setColumns([]);
    }
  }, [metaAttributes]);

  console.log('columns', columns, metaAttributes);

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-mapmetadata mt-4">
      <div className="flex items-center justify-between space-x-4 text-black">
          <div>
          <h1 className='font-semibold text-2xl'>{metaEntity?.display_name}</h1>
          </div>
          <div className="flex items-center space-x-4 text-black">
            {tableData?.length > 0 && <>
              <div class="flex items-center gap-12 mt-4">
                <div class="relative">
                  <input type="text" placeholder="Search" class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input" />
                  <div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                    <i class=" text-sm fas fa-search"></i>
                  </div>
                </div>
              </div>
            </>}
            <CreateVexa metaAttributes={metaAttributes} token={token} org={selectedOrganization?.slug} vexaSlug={vexaSlug} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} handleLogout={handleLogout} refreshVexaList={refreshVexaList} setRefreshVexaList={setRefreshVexaList} />
          </div>
        </div>

        {columns?.length > 0 && tableData?.length > 0 ? <DataTable
          columns={columns}
          data={tableData}
          responsive={true}
          pagination={true}
          customStyles={{
            rows: {
              rows: {
                className: 'custom-hover', // Apply custom hover styles here
              },
              style: {
                minHeight: '48px',
                border: '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            cells: {
              className: 'p-6',
            },
          }}
        /> : <div className='flex flex-col h-3/4 items-center justify-center'>
          <div className='mb-8'><img src={require('../../Asset/Images/no-records.png')} alt="No records" /></div>
          <p><b>This {metaEntity?.type} does not have any records.</b></p>
          <p className='text-sm'>Create first record for {metaEntity?.display_name}</p>
          <div className='mt-5'>
            <CreateVexa metaAttributes={metaAttributes} token={token} org={selectedOrganization?.slug} vexaSlug={vexaSlug} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} handleLogout={handleLogout} refreshVexaList={refreshVexaList} setRefreshVexaList={setRefreshVexaList} />
          </div>
        </div>}

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}

      </div>
    </div>
  );
}

export default Table;