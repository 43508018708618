import React, { useState, useEffect, useContext, useRef } from "react";
import { Context } from "../../contextApi/context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const serverConfig = require("./../../config/server.js");

function AddTimePopup({
  setAddTime,
  token,
  selectedOrganization,
  handleLogout,
  refreshTimesheet,
  handleCloseAlertModal,
  handleShowAlertModal,
}) {
  const navigate = useNavigate();
  const { setActiveOption } = useContext(Context);

  const [formData, setFormData] = useState({
    start_time: localTimeMinusTwoHours(new Date()),
    end_time: localTimeMinusOneHours(new Date()),
  });
  const [groupListLookup, setGroupListLookup] = useState([]);
  const [taskListLookup, setTaskListLookup] = useState([]);
  const [reasonLookup, setReasonLookup] = useState([]);

  const startTimeInputRef = useRef(null);
  const endTimeInputRef = useRef(null);

  useEffect(() => {
    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);

      //group_list_get
      axios
        .post(serverConfig.api_base_url + "group_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("group_list_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              let arr = [];
              response.data.items.forEach((el) => {
                arr.push({
                  value: el.grp,
                  label: el.name,
                });
              });
              setGroupListLookup(arr);
            } else {
              setGroupListLookup([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      axios
        .post(serverConfig.api_base_url + "lookup_list", {
          token: token,
          name: "time_entry_reason",
        })
        .then((response) => {
          console.log("time_entry_reason lookup:", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              response.data.items.sort(function (a, b) {
                return a.seq_no - b.seq_no;
              });
              let arr = [];
              response.data.items.forEach((el) => {
                arr.push({
                  value: el.value,
                  label: el.value,
                });
              });
              setReasonLookup(arr);
            } else {
              setReasonLookup([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization]);

  const handleAddTime = (event) => {
    event.preventDefault();
    console.log(formData);
    let time1 = new Date(
      new Date(formData.start_time).toUTCString()
    ).toISOString();
    let startTime =
      time1.split("T")[0] + " " + time1.split("T")[1].substring(0, 8);
    //console.log(startTime);
    let time2 = new Date(
      new Date(formData.end_time).toUTCString()
    ).toISOString();
    let endTime =
      time2.split("T")[0] + " " + time2.split("T")[1].substring(0, 8);
    //console.log(endTime);
    let formInputs = {
      ...formData,
      start_time: startTime,
      end_time: endTime,
      manual: 100,
      /* reason: [{
        name: formData.reason?.name,
        details: formData.reason?.details
      }],
      note: [formData.note] */
    };
    console.log(formInputs);

    axios
      .post(serverConfig.api_base_url + "time_log_create", {
        token: token,
        org: selectedOrganization?.slug,
        tsk: formData.tsk,
        items: formInputs,
      })
      .then((response) => {
        console.log("time_log_create response", response);
        if (response.data.status === 200) {
          setFormData({});
          refreshTimesheet();
          handleClosePopup();
          handleShowAlertModal("Time logged successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleClosePopup = () => {
    setAddTime(false);
    setFormData({});
  };

  const getTaskList = (grp) => {
    console.log(grp);
    axios
      .post(serverConfig.api_base_url + "my_task_list_get", {
        token: token,
        org: selectedOrganization?.slug,
        grp: grp,
      })
      .then((response) => {
        console.log("my_task_list_get response", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log(response.data.items);
            let relatedTasks = response.data.items.filter(
              (el) =>
                el.assignee !== null &&
                el.assignee.some((el1) => el1.usr === el.usr)
            ); //filter tasks assigned to logged in user (user is task member of the task)
            console.log(relatedTasks);
            let arr = [];
            //relatedTasks.forEach(el => {//format array in react select list format
            response.data.items.forEach((el) => {
              //format array in react select list format
              arr.push({
                value: el.tsk,
                label: el.name,
              });
            });
            setTaskListLookup(arr);
          } else {
            setTaskListLookup([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  function localeIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(Math.abs(tzo) % 60)
    );
  }

  function localTimeMinusOneHours(date) {
    var now = date;
    now.setHours(now.getHours() - 1); // minus 1 hours
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset()); // convert to local timezone
    return now.toISOString().slice(0, 16);
  }

  function localTimeMinusTwoHours(date) {
    var now = date;
    now.setHours(now.getHours() - 2); // minus 2 hours
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset()); // convert to local timezone
    return now.toISOString().slice(0, 16);
  }

  return (
    <div
      className="memberPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex flex-col overflow-hidden"
      style={{ height: "95vh" }}
    >
      <div className="flex justify-between items-center gap-5">
        <div style={{ fontSize: 16 }}>Add Time</div>
      </div>
      <div className="flex-1 flex">
        <form
          className="flex flex-col justify-between w-full"
          onSubmit={handleAddTime}
        >
          <div className="scrollable mt-5 mb-5">
            <div className="flex flex-col gap-1 text-xs">
              <label>
                Group
                <span className="text-red-500">*</span>
                {groupListLookup.length === 0 && (
                  <span className="text-red-500 text-xs font-normal ml-4">
                    Currently there are no groups. Please create one{" "}
                    <span
                      className="underline cursor-pointer"
                      onClick={() => {
                        navigate("/groups");
                        setActiveOption("Groups");
                      }}
                    >
                      here
                    </span>
                  </span>
                )}
              </label>
              <Select
                /* value={group || ""} */ classNamePrefix="select"
                placeholder="Select Group"
                options={groupListLookup}
                onChange={(option) => {
                  setFormData({ ...formData, grp: option.value });
                  /* setDbType(option); */ getTaskList(option.value);
                }}
                required
              />
            </div>
            <div className="flex flex-col gap-1 mt-4 text-xs">
              <label>
                Task
                <span className="text-red-500">*</span>
                {formData.grp ? (
                  taskListLookup.length === 0 && (
                    <span className="text-red-500 text-xs font-normal ml-4">
                      Currently there are no tasks assigned to you under this
                      group. Please assign one{" "}
                      <span
                        className="underline cursor-pointer"
                        onClick={() => {
                          navigate("/task");
                          setActiveOption("Tasks");
                        }}
                      >
                        here
                      </span>
                    </span>
                  )
                ) : (
                  <span className="text-red-500 text-xs font-normal ml-4">
                    Select group first
                  </span>
                )}
              </label>
              <Select
                /* value={group || ""} */ classNamePrefix="select"
                placeholder="Select Task"
                options={taskListLookup}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    tsk: option.value,
                  }); /* setDbType(option); */
                }}
                /* isDisabled={taskListLookup.length === 0} */ required
              />
            </div>
            <div className="flex flex-col gap-1 mt-4 text-xs">
              <label>Work Break</label>
              <div className="flex flex-row mt-2">
                No
                <label class="checkbox-toggle-simple mx-3">
                  <input
                    type="checkbox"
                    defaultChecked={false}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        is_work_break: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                  <span></span>
                </label>
                Yes
              </div>
            </div>
            <div className="mt-5">
              <label>Time span</label>
            </div>
            <div className="flex gap-4">
              <div className="flex items-center gap-2 w-2/4">
                <label>
                  From<span className="text-red-500">*</span>
                </label>
                <div
                  onClick={() => startTimeInputRef.current.showPicker()}
                  style={{ display: "inline-block" }}
                >
                  <input
                    ref={startTimeInputRef}
                    type="datetime-local"
                    value={formData.start_time.replace(" ", "T")}
                    onChange={(e) => {
                      const newStartTime = e.target.value;
                      setFormData((prevData) => ({
                        ...prevData,
                        start_time:
                          newStartTime.split("T")[0] +
                          " " +
                          newStartTime.split("T")[1],
                        end_time:
                          prevData.end_time < newStartTime
                            ? newStartTime.split("T")[0] +
                              " " +
                              newStartTime.split("T")[1]
                            : prevData.end_time,
                      }));
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                    className="custom-input py-2 px-2 text-left mt-1"
                    max={
                      localeIsoString(new Date()).split("T")[0] +
                      "T" +
                      localeIsoString(new Date()).split("T")[1]
                    }
                    required
                    style={{ pointerEvents: "none" }}
                  />
                </div>
              </div>
              <div className="flex items-center gap-2 w-2/4">
                <label>
                  To<span className="text-red-500">*</span>
                </label>
                <div
                  onClick={() => endTimeInputRef.current.showPicker()}
                  style={{ display: "inline-block" }}
                >
                  <input
                    ref={endTimeInputRef}
                    type="datetime-local"
                    value={formData.end_time.replace(" ", "T")}
                    onChange={(e) => {
                      const newEndTime = e.target.value;
                      const newEndDate = newEndTime.split("T")[0];
                      const newEndDateTime = new Date(newEndTime);
                      const startDateTime = new Date(
                        formData.start_time.replace(" ", "T")
                      );
                      setFormData((prevData) => ({
                        ...prevData,
                        end_time:
                          newEndTime.split("T")[0] +
                          " " +
                          newEndTime.split("T")[1],
                      }));

                      if (newEndDateTime < startDateTime) {
                        e.target.setCustomValidity(
                          "End time cannot be earlier than start time."
                        );
                      } else if (
                        newEndDate > formData.start_time.split(" ")[0]
                      ) {
                        e.target.setCustomValidity(
                          "End date cannot be later than start date."
                        );
                      } else {
                        e.target.setCustomValidity("");
                      }
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                    className="custom-input py-2 px-2 text-left mt-1"
                    min={formData.start_time.replace(" ", "T")}
                    max={formData.start_time.split(" ")[0] + "T23:59"}
                    required
                    style={{ pointerEvents: "none" }}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-1 mt-4 text-xs">
              <label>Billable</label>
              <div className="flex flex-row mt-2">
                No
                <label class="checkbox-toggle-simple mx-3">
                  <input
                    type="checkbox"
                    defaultChecked={false}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        is_billable: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                  <span></span>
                </label>
                Yes
              </div>
            </div>
            {/* put Time bar here */}
            <div className="flex flex-col gap-1 mt-4">
              <label>
                Reason<span className="text-red-500">*</span>
              </label>
              <Select
                classNamePrefix="select"
                placeholder="Why are you adding this time entry? (ex. Forgot to start timer)"
                options={reasonLookup}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    reason: [{ name: option.value }],
                  });
                }}
                required
              />
              {formData?.reason?.length > 0 &&
                formData.reason[0].name === "Other" && (
                  <textarea
                    value={formData.reason[0].details}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        reason: [
                          {
                            name: formData.reason[0].name,
                            details: e.target.value,
                          },
                        ],
                      })
                    }
                    placeholder="Please specify"
                    className="custom-input py-2 px-2 text-left mt-1"
                    required
                  ></textarea>
                )}
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <label>Add a Note</label>
              <textarea
                value={formData.note}
                onChange={(e) =>
                  setFormData({ ...formData, note: e.target.value })
                }
                placeholder="What are you working for? (ex. Responding to customers)"
                className="custom-input py-2 px-2 text-left mt-1 w-[480px]"
              ></textarea>
            </div>
          </div>
          {/* bottom buttom */}
          <div className="ml-auto">
            <button
              type="button"
              className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
              onClick={handleClosePopup}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddTimePopup;
