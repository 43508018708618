import React, { useState, useEffect } from 'react';
import Search from './Search';
import axios from 'axios';

const serverConfig = require("./../../config/server.js");

function AssignessSide({setFormData, formData, assignee, grpSlug, token, selectedOrganization, handleShowAlertModal, handleCloseAlertModal}) {
  const [popup, setPopup] = useState(false);

  const [searchList, setSearchList] = useState([]);
  const [srchList, setSrchList] = useState([]);
  const [checklistMemberList, setChecklistMemberList] = useState([]);
  const [checklistMemberSlugList, setChecklistMemberSlugList] = useState([]);

  useEffect(() => {
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null && grpSlug !== null && grpSlug !== undefined){
      axios.post(serverConfig.api_base_url + "group_member_list_get",{token: token, org:selectedOrganization?.slug, grp: grpSlug})
      .then(response=>{
        console.log('group_member_list_get response', response);
        console.log('group_member_list_get response items',response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            setSearchList(response.data.items);
            setSrchList(response.data.items);
          } else {
            setSearchList([]);
            setSrchList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          setSearchList([]);
          setSrchList([]);
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));

      //getSubtaskList();
    }
  }, [token, selectedOrganization, grpSlug]);
  
    useEffect(() => {
      setChecklistMemberList([]);
      setChecklistMemberSlugList([]);
      setFormData({...formData, assignee: [{usr: []}], watcher: [{usr: []}]});
    }, [grpSlug]);

  useEffect(() => {
    console.log(formData);
    setFormData({...formData, assignee: [{usr: checklistMemberSlugList}]});
  }, [checklistMemberList]);

  useEffect(() => {
    if(assignee){
      console.log(checklistMemberSlugList);
      console.log(assignee);
      let arr = []
      assignee.forEach(el => {
        arr.push(el.usr);
      });
      console.log(arr);
      setChecklistMemberList(assignee);
      setChecklistMemberSlugList(arr);
    } else {
      setChecklistMemberList([]);
      setChecklistMemberSlugList([]);
    }
  }, [assignee]);

  const handleAddMember = (data) => {
    console.log(data);
    setChecklistMemberList([...checklistMemberList, {username: data.name, icon: data.icon, usr: data.slug}]);
    setChecklistMemberSlugList([...checklistMemberSlugList, data.slug]);
    setPopup(false);
  }

  const handleRemoveMember = (userSlug) => {
    setChecklistMemberList(checklistMemberList.filter(el => el.usr !== userSlug));
    setChecklistMemberSlugList(checklistMemberSlugList.filter(el => el !== userSlug));
    console.log(checklistMemberSlugList);
    setPopup(false);
  }

  return (

    <div className='mt-7'>
      <div className='flex items-center justify-start'>
        <h2 className="sum-title text-custom">Assignees</h2>
        <label className="cursor-pointer">
          <button type="button" className="font-medium inline-block text-gray-500 text-lg px-2" onClick={() => { setPopup(!popup) }} disabled={searchList.length === 0} title={searchList.length === 0 ? 'Select group or add members to selected group in order to add assignees' : 'Add Assignees'}>+</button>
        </label>
      </div>

      {popup && <div className='organisationDropdownCard mt-2' style={{width: '100%', left: 0, position: 'unset'}}>
        <div className='px-2 mt-4 w-full'><Search srchList={srchList} setSearchList={setSearchList} /></div>
        <div className='p-2 mb-2 max-h-40 overflow-y-auto flex-auto w-full' style={{borderBottom: 'none'}}>
          {searchList && searchList.length > 0 && <ul className='px-1 text-sm'>
            {searchList.map((data, index)=>{
              return(<li key={index} className={index === searchList.length-1 ? "flex items-center oragnisationHover" : "flex items-center mb-2 oragnisationHover"} onClick={() => checklistMemberList && checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? handleRemoveMember(data.slug) : handleAddMember(data)} title={checklistMemberList && checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? `Remove ${data.name}` : `Add ${data.name}`}>
                <div>{!data.icon ? <img src={`https://ui-avatars.com/api/?name=${data?.name.split(" ")[0]}+${data?.name.split(" ").length > 1 ? data?.name.split(" ")[data?.name.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`} alt={data?.name} className='rounded-full' width="40" /> : <img src={data.icon} alt={data?.name} className='rounded-full' width="40" />}</div>
                <div className="ml-4">{data.name}</div>
                {checklistMemberList.length > 0 && checklistMemberList.find(el => el.usr === data.slug) ? <span className="ml-auto mr-3"><i className="fa-solid fa-xmark text-slate-400"></i></span> : <span className="ml-auto mr-3"><i className="fa-solid fa-user-plus text-slate-400"></i></span>}
              </li>)
            })}
          </ul>}
        </div>
      </div>}

      {checklistMemberList && checklistMemberList.length > 0 && <div className='flex assignee-list mt-3'>
        {checklistMemberList.map((item, index) => {
          return(<div title={item?.username}>{!item.icon ? <img src={`https://ui-avatars.com/api/?name=${item?.username.split(" ")[0]}+${item?.username.split(" ").length > 1 ? item?.username.split(" ")[item?.username.split(" ").length - 1] : ""} &rounded=false&length=2&background=6C9BF6&format=svg&color=fff`} alt={item?.username} className='rounded-full' width="30" /> : <img src={item.icon} alt={item?.username} className='rounded-full' width="30" />}</div>)
        })}  
      </div>}
    </div>
  )
}

export default AssignessSide