import React,{useState, useContext} from 'react'
import { Context } from '../contextApi/context';
import TeamAccessPopup from './popups/TeamAccessPopup';
import MyActionsTable from './tables/MyActionsTable';

function MyActions() {
  
    const {collapse} = useContext(Context)
    const [teamPopup , setTeamPopup]= useState(false)
  return (
    <div>
    {teamPopup &&<div className='overlay'></div>}
    <div className={collapse?'content-body':'content-body-collapse'} >
          
          <div class="container-fluid">
            <MyActionsTable />
          </div>
         
          </div>
    </div>
  )
}

export default MyActions;