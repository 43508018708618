import React from 'react'
import { useEffect,useState, useContext } from 'react';
import {useNavigate} from "react-router-dom";
import { Context } from '../../../contextApi/context';
import axios from 'axios';
import UserService from "../../../services/UserService";
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import '../../css/settings.css';
import Notification from '../../../common/notification/Notification.js';
//import IconPicker from 'react-icons-picker';
import IssueStatus from '../tables/IssueStatus.js';
import * as FaIcons from 'react-icons/fa';
import IssueCategory from '../tables/IssueCategory.js';
import GroupStatus from '../tables/GroupStatus.js';
import IssueRelations from '../components/IssueRelations.js';

const serverConfig = require("../../../config/server.js");

function AllSettings() {
    
    const {selectedOrganization, isDarkTheme, activeOption} = useContext(Context);
    const [selectedRows, setSelectedRows] = useState([]);
    const[tab, setTab]= useState('User Profile');
    const [formData, setFormData] = useState({});
    const [orgFormData, setOrgFormData] = useState({});
    const [memberRowClicked , setMemberRowClicked]= useState('');
    const [projectRowClicked , setProjectRowClicked]= useState('');
    const [nameRowClicked, setNameRowClicked] = useState('');
    const [memberTab, setMemberTab]=useState(0)
    const navigate = useNavigate(); 
    const [teamInfoPopup , setTeamInfoPopup]= useState(false);
    const [isAlertDropdown, setIsAlertDropdown] = useState(false);
    const [token, setToken] = useState(null); 
    const [editUser, setEditUser] = useState(false);
    const [cancelEdit, setCancelEdit] = useState(false);
    const [createShift, setCreateShift] = useState(false);
    const [editShift, setEditShift] = useState(false);
    const [shiftData, setShiftData] = useState({});
    const [weekdayLookup, setWeekdayLookup] = useState([]); 
    const [timeOptions, setTimeOptions] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [daySelected, setDaySelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);

    const handleCloseAlertModal = () => {
      setAlertMessage("");
      setShowOkButton(false);
      setAlertModalShow(false);
    }

    const handleShowAlertModal = (alert_msg, show_ok_button) => {
      setAlertMessage(alert_msg);
      setShowOkButton(show_ok_button);
      setAlertModalShow(true);
    }
    
  const getBase64 = (file, e) => {
    console.log(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      if(e.target.name === 'logo') {
        setFormData({ ...formData, logo: file.name, profile_picture: reader.result});
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const getOrgBase64 = (file, e) => {
    console.log(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      if(e.target.name === 'logo') {
        setOrgFormData({ ...orgFormData, logo: reader.result});
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

    const handleLogout = () => {
        sessionStorage.clear();
        UserService.doLogout();
      }
      
      const getToken = () => {
        console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
        .then(response=>{
          console.log('user_get_token response', response);
          if(response.data.status === 200){
            sessionStorage.setItem('token', response.data.token);
            setToken(response.data.token);
          } else if(response.data.status >= 400 && response.data.status <= 499){
            alert(response.data.message);
          } else if(response.data.jwt_validation === 'fail') {
            handleLogout();
          }
        })
        .catch(err=>console.log("error is",err));
      }
    
      useEffect(() => {
        console.log(sessionStorage.getItem('token'));
        if(sessionStorage.getItem('token') === null){
          getToken();
        } else {
          setToken(sessionStorage.getItem('token'));
        }
      }, []);

      const shiftListGet = () => {
        axios.post(serverConfig.api_base_url + "shift_list_get", {
          token: token,
          org: selectedOrganization?.slug
        }).then(response=> {
          if(response.data.status === 200) {
            setTableData(response.data.items);
          }
        }).catch(err=> {
          console.log(err);
        })
      }

      const setUserInfo = () => {
        setIsLoading(true);
        axios.post(serverConfig.api_base_url + "member_profile_set", {
            token : token,
            org : selectedOrganization?.slug,
            phone_number : formData.phone_number,
            profile_picture : formData.profile_picture,
            name: formData.name
        }).then(response=> {
            console.log(response.data, "member_profile_set");
            if(response.data.status === 200) {
              setEditUser(!editUser);
              setIsLoading(false);
              handleShowAlertModal("Settings updated successfully");
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
        })
      }

      const setOrgInfo = () => {
        setIsLoading(true);
        axios.post(serverConfig.api_base_url + "organization_profile_set", {
            token : token,
            org : selectedOrganization?.slug,
            name : orgFormData?.name,
            logo : orgFormData?.logo
        }).then(response=> {
            console.log(response.data, "org_profile_set");
            if(response.data.status === 200) {
                setEditUser(!editUser);
                setIsLoading(false);
                handleShowAlertModal("Settings updated successfully");
                setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
        })
      }  

      function formatTime(date) {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutesStr} ${ampm}`;
      }
      
      // Function to generate time values
      function generateTimeOptions(start, end, interval) {
        const times = [];
        const startTime = new Date(`1970-01-01T${start}:00`);
        const endTime = new Date(`1970-01-01T${end}:00`);
        const intervalMs = interval * 60 * 1000; // Convert interval to milliseconds
      
        for (let time = startTime; time <= endTime; time = new Date(time.getTime() + intervalMs)) {
          times.push(formatTime(time));
        }
      
        return times;
      }
      
        useEffect(() => {
          const options = generateTimeOptions('00:00', '23:30', 30); // 30-minute interval
          setTimeOptions(options);
        }, []);

      const data = [
        {"key" : "Morning Shift 9:00 am - 6:00 pm"}
      ];

      const columns = [
        {
          name: <div className='row-label'>Key</div>,
          cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold">
           {row?.name}  
           {row?.start_time !== null && row?.end_time !== null ?
           <div className='font-semibold ml-2'> 
            : {row?.start_time} to {row?.end_time}
           </div> : <div className='font-semibold ml-2'>: Variable Timings</div>}
           </div>,
        },
        {
          name: <div className='row-label'></div>,
          cell: (row) =>  <div className="flex-row flex items-center py-2 font-semibold">
           <Button variant='text' className='mr-2 ml-2' onClick={() => {setEditShift(true); setShiftData(row); setWeekdayLookup(row?.working_days_data)}}><i class="fa-solid fa-pencil"></i></Button>
           </div>,
          width: '6%'
        },
      ]

        useEffect(() => {
            
            if(token !== null && token !== undefined) {
                axios.post(serverConfig.api_base_url + "member_profile_get", {
                    token:token,
                    org:selectedOrganization?.slug
                }).then(response=> {
                    setFormData(response.data.item);
                }).catch(err=> {
                    console.log(err);
                })

                axios.post(serverConfig.api_base_url + "organization_profile_get", {
                    token:token,
                    org:selectedOrganization?.slug
                }).then(response=> {
                    console.log(response.data.item, 'org_profile_get');
                    setOrgFormData(response.data.item);
                }).catch(err=> {
                    console.log(err);
                })

                if(weekdayLookup.length === 0) {
                axios.post(serverConfig.api_base_url + "lookup_list", {
                  token: token,
                  name: 'weekday'
                }).then(response=> {
                  console.log(response.data.item);
                  response.data.items.sort(function(a,b){
                    return a.seq_no - b.seq_no;
                  });
                  let arr = [];
                  (response.data.items).forEach(el => {
                    arr.push({
                      is_enabled : 0,
                      value: el.value,
                      start_time : '',
                      end_time: ''
                    });
                  }); 
                  setWeekdayLookup(arr);
                }).catch(err=> {
                  console.log(err);
                })
              }

                shiftListGet();
            }
            
        }, [token, editShift, createShift, editUser, cancelEdit, selectedOrganization]);
    
      const handleDaySelect = (value) => {
          var upadateWeekdayLookup = weekdayLookup.map(item => {
            if(item?.value === value) {
              return {...item, is_enabled : 1}
            }
            return item;
          });
          setWeekdayLookup(upadateWeekdayLookup);
          setShiftData({...shiftData, working_days_data: upadateWeekdayLookup});
          setErrorMessage('');
      }

      const handleDayUnSelect = (value) => {
        var upadateWeekdayLookup = weekdayLookup.map(item => {
          if(item?.value === value) {
            return {...item, is_enabled : 0, start_time: "", end_time: ""}
          }
          return item;
        });
        setWeekdayLookup(upadateWeekdayLookup);
          setShiftData({...shiftData, working_days_data: upadateWeekdayLookup})
    }

    const handleSetStartTime = (e, value) => {
      var upadateWeekdayLookup = weekdayLookup.map(item => {
        if(item?.value === value) {
          return {...item, start_time: e.target.value}
        }
        return item;
      });
      setWeekdayLookup(upadateWeekdayLookup);
      setShiftData({...shiftData, working_days_data: upadateWeekdayLookup});
    }

    const handleSetEndTime = (e, value) => {
      var upadateWeekdayLookup = weekdayLookup.map(item => {
        if(item?.value === value) {
          return {...item, end_time: e.target.value}
        }
        return item;
      });
      setWeekdayLookup(upadateWeekdayLookup);
      setShiftData({...shiftData, working_days_data: upadateWeekdayLookup})
    }

    useEffect(()=>{
      setDaySelected(shiftData?.working_days_data?.some(item=>  item?.is_enabled === 1 ));
    }, [shiftData])

    const handleShiftEdit = (e) => {
      e.preventDefault();

      if(!daySelected) {
        setErrorMessage('Select atleast one day')
      }
      else {
      if(token !== null && token !== undefined) {
        axios.post(serverConfig.api_base_url + "shift_update", {
          token: token,
          org: selectedOrganization?.slug, 
          items : shiftData,
          shf: shiftData?.shf
        }).then(response => {
          if(response.data.status === 200){
           setShiftData({});
           setWeekdayLookup([]);
           setEditShift(false);
           setErrorMessage('');
           handleShowAlertModal('Shift Updated successfully');
           setTimeout(() => {
           handleCloseAlertModal();
         }, 6000);
         } else if(response.data.status === 301){
           handleLogout();
         } else {
           handleShowAlertModal(response.data.message, true);
           setTimeout(() => {
             handleCloseAlertModal();
           }, 6000);
         }
        }).catch(err=> {
          console.log(err);
          handleShowAlertModal(err, true);
           setTimeout(() => {
             handleCloseAlertModal();
           }, 6000);
        })
      }
    }
    }

    const handleShiftCreate = (e) => {
      e.preventDefault();

      if(!daySelected) {
        setErrorMessage('Select atleast one day')
      }
      else {
      if(token !== null && token !== undefined) {
        axios.post(serverConfig.api_base_url + "shift_create", {
          token: token,
          org: selectedOrganization?.slug, 
          items : shiftData
        }).then(response => {
          if(response.data.status === 200){
           setShiftData({});
           setCreateShift(false);
           handleShowAlertModal('Shift Created successfully');
           setErrorMessage('');
           setTimeout(() => {
           handleCloseAlertModal();
         }, 6000);
         } else if(response.data.status === 301){
           handleLogout();
         } else {
           handleShowAlertModal(response.data.message, true);
           setTimeout(() => {
             handleCloseAlertModal();
           }, 6000);
         }
        }).catch(err=> {
          console.log(err);
          handleShowAlertModal(err, true);
           setTimeout(() => {
             handleCloseAlertModal();
           }, 6000);
        })
      }
    }
    }
       
       const sortDown=<i class="fa-solid fa-arrow-down-wide-short"></i>;
       const sortUp=<i class="fa-solid fa-arrow-up-short-wide"></i>;       
            
              return (
                <div className="overflow-x-auto">
                  <div className="rounded-3xl py-2 table-custom mt-4">
                    
                  <div className=' rounded-3xl relative  ml-8  my-5 text-custom text-sm flex flex-col'>
      <div className='flex-grow overflow-y-auto '>
      <div className='flex'>
        
      {(tab==='User Profile') ?
      <div className='flex flex-row gap-6'>
      <div className="flex flex-col mr-8 options-container" >
           <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===0 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('User Profile'); setMemberTab(0);}}>User Profile</button>
           <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===1 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Organization Profile'); setMemberTab(1);}}>Organization Profile</button>
           {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===2 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Shift Timings'); setMemberTab(2);}}>Shift Timings</button> */}
           {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===4 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Groups'); setMemberTab(4);}}>Groups and Subgroups</button> */}
           {/* <button className={`px-6 py-3 cursor-pointer ${memberTab===2 ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Policies'); setMemberTab(2);}}>Policies</button> */}
      </div>
      <div className='flex flex-row mt-8'>
            <div>
            <div className='flex flex-row gap-1 mt-4 text-xs'>
                <div className="flex items-center flex-col">
                  {/* {formData?.logo_base64 && */}
                  <img src={formData?.profile_picture || require("../../../Asset/Images/userprofile.png")} alt="logo" height={200} width={200} />
                  {/* 
                           */}
                  <div className={formData?.profile_picture ? "flex flex-col ml-3" : "flex flex-col"}>
                    <label className="custom-logo-upload">
                      <input  type='file' value="" onChange={(e)=>  {getBase64(e.target.files[0], e)}} name="logo" accept='.jpg, .jpeg, .png' className='custom-input py-2 px-2 text-left mt-3'/>
                     <>Change Photo</>
                    </label>
                  </div>
                  <button className='red-btn px-3 py-2 mt-4' onClick={()=>{setAddUser(true)}} >
                    Deactivate Account
                    </button>
                </div>

                <div className='flex flex-col mt-6 ml-16'>
                    <div className='edit-user-profile'><b>Edit Account</b></div>

                    <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Name<span className=' text-red-500'>*</span></label>
                  <input type="text" value={formData?.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>

                <div className='flex flex-col gap-1 mt-4 text-xs'>
                  <label>Email<span className=' text-red-500'>*</span></label>
                  <input type="text" value={formData?.email || ""}  placeholder='Email' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>

                <div className='flex flex-col gap-1 mt-4 text-xs'>
                  <label>Phone Number</label>
                  <input type="tel" value={formData?.phone_number || ""} onChange={(e)=>  setFormData({ ...formData, phone_number: e.target.value})} placeholder='Phone Number' className='custom-input py-2 px-2 text-left mt-1'/>
                </div>
                </div>
              </div>
           
              <div className='flex flex-row justify-end mt-6'>
              <button type="submit"  className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setCancelEdit(!cancelEdit)}}>Cancel</button>
              <button type="submit"  className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={setUserInfo}>
              {isLoading ? <div className="signup-loader" style={{ marginLeft: '15px' }}></div> : "Save Changes"}
              </button>
              </div>
            </div>
            </div>
      </div> :
           
      (tab==='Organization Profile') ? 
      <div className='flex flex-row gap-6'>
      <div className="flex flex-col mr-8 options-container">
      <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===0 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('User Profile'); setMemberTab(0);}}>User Profile</button>
      <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===1 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Organization Profile'); setMemberTab(1);}}>Organization Profile</button>
      {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===2 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Shift Timings'); setMemberTab(2);}}>Shift Timings</button> */}
      {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===4 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Groups'); setMemberTab(4);}}>Groups and Subgroups</button> */}
           {/* <button className={`px-6 py-3 cursor-pointer ${memberTab===2 ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Policies'); setMemberTab(2);}}>Policies</button> */}
      </div>

      <div className='flex flex-row mt-8'>
            <div>
            <div className='flex flex-row gap-1 mt-4 text-xs'>
                <div className="flex items-center flex-col">
                  {/* {formData?.logo_base64 && */}
                  <img src={orgFormData?.logo || require("../../../Asset/Images/userprofile.png")} alt="logo" height={200} width={200} />
                  {/* 
                           */}
                  <div className={orgFormData?.logo ? "flex flex-col ml-3" : "flex flex-col"}>
                    <label className="custom-logo-upload">
                      <input  type='file' value="" onChange={(e)=>  {getOrgBase64(e.target.files[0], e)}} name="logo" accept='.jpg, .jpeg, .png' className='custom-input py-2 px-2 text-left mt-1'/>
                     <>Change Logo</>
                    </label>
                  </div>
                </div>

                <div className='flex flex-col mt-6 ml-16'>
                    <div className='edit-user-profile'><b>Edit Organization Settings</b></div>

                    <div className='flex flex-col gap-1 mt-8 text-xs'>
                  <label>Organization Name<span className=' text-red-500'>*</span></label>
                  <input type="text" value={orgFormData?.name || ""} onChange={(e)=>  setOrgFormData({ ...orgFormData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                </div>
                </div>
              </div>
           
              <div className='flex flex-row justify-end mt-6'>
              <button type="submit"  className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setCancelEdit(!cancelEdit)}}>Cancel</button>
              <button type="submit"  className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={setOrgInfo}>
              {isLoading ? <div className="signup-loader" style={{ marginLeft: '15px' }}></div> : "Save Changes"}
              </button>
              </div>
            </div>
            </div>

      </div> :

      
      (tab==='Shift Timings') ?
      <div className='flex flex-row gap-6'>
      <div className="flex flex-col mr-8 w-full options-container">
          <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===0 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('User Profile'); setMemberTab(0);}}>User Profile</button>
          <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===1 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Organization Profile'); setMemberTab(1);}}>Organization Profile</button>
          {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===2 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Shift Timings'); setMemberTab(2);}}>Shift Timings</button> */}
          {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===4 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Groups'); setMemberTab(4);}}>Groups and Subgroups</button> */}
          {/* <button className={`px-6 py-3 cursor-pointer ${memberTab===2 ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Policies'); setMemberTab(2);}}>Policies</button> */}
      </div>


      <div className='' style={{"width" : "55vw"}}>
      {tableData?.length > 0 ?
      <DataTable 
      title={
        <div className=" table-title rounded">
          <div className="flex justify-between items-center">
            {/* Left side: Three tabs flexed together */}
            <div className="flex space-x-4">
              <h4 className='font-bold text-base'>Shift Timings</h4>
            </div>
            <span className="text-right">
              <Button type='button' className='sub-grp px-3 py-2' title="Create Table" onClick={()=> {setCreateShift(true);}}>Add Shift Time</Button>
            </span>               
          </div>
        </div>}
        className='shift-table'
        columns={columns}
        data={tableData}
        highlightOnHover
        customStyles={{
          table: {
            style: {
              //border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
              borderRadius: '16px 16px 10px 10px',
            },
          },
          rows: {
            style: {
              minHeight: '40px',
              border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
              borderRadius: '10px',
              marginTop: '10px',
              padding: '5px',
              borderBottom : isDarkTheme ? '2px solid var(--neutral-dark-400) !important' : '2px solid var(--neutral-400) !important'
            },
            highlightOnHoverStyle: {
              color: isDarkTheme ? '#ffffff' : '#000000',
              backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
              outline: 'none'
            }
          },
        }}
      /> : <div className='flex flex-col h-full items-center justify-center mt-4' style={{"height": "74vh"}}>
      <div className='mb-8'><img src={require('./../../assets/no-groups-subgroups.png')} alt="No shift" height={150} width={150}/></div>
      <p className='text-center'><b>This organization has no shifts<br/> for now.</b></p>
      <p className='text-sm'>Add shift for {selectedOrganization?.name}</p>
      <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={() => { setCreateShift(true); }} >Create Shift</button></div>
    </div>}
      {createShift && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Create Shift</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleShiftCreate}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={shiftData.name || ""} onChange={(e)=>  setShiftData({ ...shiftData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' style={{"width" : "auto !important"}} required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label className='text-xs'>Description</label>
                    <textarea value={shiftData.description || ""} onChange={(e)=>  setShiftData({ ...shiftData, description: e.target.value})} placeholder='Description' className='custom-textarea py-2 px-2 text-left mt-1 mr-2'></textarea>
                  </div>
                </div>
 
                {errorMessage ? <p className='mt-6 mb-2' style={{"color" : "red", "fontSize" : "11px"}}>{errorMessage}</p> : <div className='mt-6 mb-2'></div>}
                <label className=' text-xs'>Working Days<span className=' text-red-500'>*</span></label>
                <div className='flex flex-row'>
                  {weekdayLookup.map((item) => {
                    return (
                     item?.is_enabled === 0 ? 
                          <div className='day-box ml-2' onClick={()=>{handleDaySelect(item?.value)}}>{item?.value.slice(0,2)}</div> 
                          : 
                          <div className='selected-day-box ml-2' onClick={()=>{handleDayUnSelect(item?.value)}}>{item?.value.slice(0,2)}</div>
                    )
                  })
                  }
                </div>

                <label className='mt-6 mb-2 text-xs'>Working Hours<span className=' text-red-500'>*</span></label>
                <div className='flex flex-col'>
                  {weekdayLookup.map((item) => {
                    return (
                      item?.is_enabled === 1 ? 
                      <div className='flex flex-row'>
                    <div className='text-sm font-semibold mt-4 weekday-container'>{item?.value}</div>
                    <div className='weektime-shift'>
                    <select name="column_type" defaultValue='' className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => {handleSetStartTime(e, item?.value)}} required>
                    <option value="">Start Time</option>
                    {timeOptions.map((time, index) => (
                        <option key={index} value={time}>{time}</option>
                      ))}                    
                  </select>
                  </div>
                  <div className='ml-4 mr-4 mt-4 text-xs font-semibold'>to</div>
                  <div className='weektime-shift'>
                    <select name="column_type" defaultValue='' className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => {handleSetEndTime(e, item?.value)}} required>
                    <option value="">End Time</option>
                    {timeOptions.map((time, index) => (
                        <option key={index} value={time}>{time}</option>
                      ))}                    
                  </select>
                  </div>
                      </div> : <></>
                    )

                  
                  })
                  }
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setCreateShift(false); setShiftData({}); setWeekdayLookup([]); setErrorMessage('');}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}

        
        {editShift && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Shift</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleShiftEdit}>
              <div className='scrollable mt-4'>
                <div>
                  <div className='flex flex-col gap-1 mt-2 text-xs'>
                    <label className='text-xs'>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={shiftData.name || ""} onChange={(e)=>  setShiftData({ ...shiftData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1 mr-2' style={{"width" : "auto !important"}} required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label className='text-xs'>Description</label>
                    <textarea value={shiftData.description || ""} onChange={(e)=>  setShiftData({ ...shiftData, description: e.target.value})} placeholder='Description' className='custom-textarea py-2 px-2 text-left mt-1 mr-2'></textarea>
                  </div>
                </div>

                {errorMessage ? <p className='mt-6 mb-2' style={{"color" : "red", "fontSize" : "11px"}}>{errorMessage}</p> : <div className='mt-6 mb-2'></div>}
                <label className='text-xs'>Working Days<span className=' text-red-500'>*</span></label>
                <div className='flex flex-row'>
                  {shiftData?.working_days_data?.map((item) => {
                    return (
                     item?.is_enabled === 0 ? 
                          <div className='day-box ml-2' onClick={()=>{handleDaySelect(item?.value)}}>{item?.value.slice(0,2)}</div> 
                          : 
                          <div className='selected-day-box ml-2' onClick={()=>{handleDayUnSelect(item?.value)}}>{item?.value.slice(0,2)}</div>
                    )
                  })
                  }
                </div>

                <label className='mt-6 mb-2 text-xs'>Working Hours<span className=' text-red-500'>*</span></label>
                <div className='flex flex-col'>
                  {shiftData?.working_days_data?.map((item) => {
                    return (
                      item?.is_enabled === 1 ? 
                      <div className='flex flex-row'>
                    <div className='text-sm font-semibold mt-4 weekday-container'>{item?.value}</div>
                    <div className='weektime-shift'>
                    <select name="column_type" defaultValue={item?.start_time} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => {handleSetStartTime(e, item?.value)}} required>
                    <option value="">Start Time</option>
                    {timeOptions.map((time, index) => (
                        <option key={index} value={time}>{time}</option>
                      ))}                    
                  </select>
                  </div>
                  <div className='ml-4 mr-4 mt-4 text-xs font-semibold'>to</div>
                  <div className='weektime-shift'>
                    <select name="column_type" defaultValue={item?.end_time} className='custom-input py-2 px-2 text-left mt-1' onChange={(e) => {handleSetEndTime(e, item?.value)}} required>
                    <option value="">End Time</option>
                    {timeOptions.map((time, index) => (
                        <option key={index} value={time}>{time}</option>
                      ))}                    
                  </select>
                  </div>
                      </div> : <></>
                    )

                  
                  })
                  }
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div mt-4'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setWeekdayLookup([]);
                  
                  setEditShift(false); setShiftData({}); setErrorMessage('');}}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save Changes</button>
              </div>
            </form>
          </div>
        </div>}


      </div>
 
      
      </div> :  
      
      (tab==='Groups') ?
      <div className='flex flex-row gap-6'>
      <div className="flex flex-col mr-8 w-full options-container" >
          <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===0 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('User Profile'); setMemberTab(0);}}>User Profile</button>
          <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===1 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Organization Profile'); setMemberTab(1);}}>Organization Profile</button>
          {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===2 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Shift Timings'); setMemberTab(2);}}>Shift Timings</button> */}
          {/* <button className={`px-6 py-3 mb-2 cursor-pointer ${memberTab===4 ? 'settings-tab clicked' : 'settings-tab-unactive'}`} onClick={()=>{setTab('Groups'); setMemberTab(4);}}>Groups and Subgroups</button> */}
          {/* <button className={`px-6 py-3 cursor-pointer ${memberTab===2 ? 'tab form' : 'tab-unactive'}`} onClick={()=>{setTab('Policies'); setMemberTab(2);}}>Policies</button> */}
      </div>
      <div className='flex flex-col table-container'>
    
    <GroupStatus token={token} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} handleLogout={handleLogout} />

    </div>
      </div> :

      <></>}
      </div> 
    </div>
    </div>

    {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
  </>}
  
                
                  </div>
                </div>
              );
}

export default AllSettings;