import React from "react";
import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Context } from "../../contextApi/context";
import NewTaskPopup from "../taskpopup/NewTaskPopup.js";
import EditTaskPopup from "../taskpopup/EditTaskPopup.js";
import { data } from "../../Asset/Data/taskData";
import ProgressBarUpdate from "../components/ProgressBarUpdate";
import axios from "axios";
import ProgressbarReadOnly from "../components/ProgressbarReadOnly";
import Kanban from "../components/Kanban.js";
import RecursiveLinkedIssues from "../components/RecursiveLinkedIssues.js";
import * as FaIcons from 'react-icons/fa';
import DeleteTaskPopup from "../taskpopup/DeleteTaskPopup.js";

const serverConfig = require("./../../config/server.js");

const TableMyTasks = ({
  token,
  selectedOrganization,
  handleLogout,
  newTaskPopup,
  setPopup,
  handleCloseAlertModal,
  handleShowAlertModal,
  grp,
  isTeamTask,
}) => {
  const { isDarkTheme } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [viewPrivilege, setViewPrivilege] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [formData, setFormData] = useState({});

  const { openTaskPopup } = useContext(Context);
  const [showTask, setShowTask] = useState(false);
  const [status, setStatus] = useState({});
  const [showEditTask, setShowEditTask] = useState(false);
  const [showDeleteTask, setShowDeleteTask] = useState(false);

  const [view, setView] = useState("list");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;

  function Mapme(data) {
    return (
      data && (
        <div className="gap-2 overflow-hidden h-5 flex line-clamp-1 ">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="status"
                style={{
                  backgroundColor: `${item.color_code}30`,
                  borderColor: `${item.color_code}90`,
                }}
              >
                <div className="line-clamp-1">{item.name}</div>
              </div>
            );
          })}
        </div>
      )
    );
  }

  useEffect(() => {
    console.log(data, "data_taskData");
  }, []);

  const [dropdownItems, setDropdownItems] = useState(["All"]);
  useEffect(()=>{
    if (token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null) {

      axios.post(serverConfig.api_base_url + "issue_status_get_list", { token: token, org: selectedOrganization?.slug, grp: grp })
      .then(response => {
        console.log(response.data.items, 'issue_status_get_list_res')
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("status data:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });

            let statusArr = response.data.items.map((item, index) => {
              return(
                item.name
              )
            });

            console.log(statusArr, dropdownItems, [...dropdownItems, ...statusArr]);

            setDropdownItems([...dropdownItems, ...statusArr].filter((value, index, array) => array.indexOf(value) === index));//get unique status
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  },[token, selectedOrganization]);

  const handleStatusChange = (event) => {
    console.log(event.target.value);
    setSelectedStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTasks = tableData.filter((task) => {
  // Check if the task matches the selected status, if provided
  const statusMatch =
    (selectedStatus === "All" && task.status?.name !== "Deleted" && task.status?.name !== "Duplicate") ||
    task.status?.name === selectedStatus;

  // Check if the task name or group name includes the search query, if provided
  const searchMatch =
    !searchQuery ||
    task.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.group?.name?.toLowerCase().includes(searchQuery.toLowerCase());

  // Return true if the task matches the selected status (if provided) and search query (if provided)
  return statusMatch && searchMatch;
});

  const sortingIcons = [sortDown, sortUp];
  const columns = [
    {
      name: "",
      cell: (row) => (
        <div
          className="border rounded-lg flex items-center bg-white"
          style={{ width: "40px", height: "40px" }}
        >
          {row?.group.logo_base64 ? (
            <img
              src={row?.group.logo_base64}
              alt={row?.group.name}
              width="40"
              height="40"
            />
          ) : (
            <img
              className="rounded-lg image-logo"
              height={40}
              width={40}
              src={
                row?.group && row?.group.logo
                  ? row?.group.logo
                  : `https://ui-avatars.com/api/?name=${
                      row?.group.name
                    }&length=1&background=${row?.group.default_color.replace(
                      "#",
                      ""
                    )}&format=svg&color=fff`
              }
              alt={row?.group.name}
            />
          )}
        </div>
      ),
      grow: 0,
      sortable: false,
    },
    {
      selector: (row) => row?.name,
      name: <div className="row-label pl-1">To do</div>,
      cell: (row) => (
        <div className="flex-column  py-2 ">
          <div className="text-heading line-clamp-2">{row?.name}</div>
          <div className="text-detail pt-1 line-clamp-2">
            {row?.group?.name}
          </div>
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row) => row?.issue_type?.name,
      name: <div className="row-label pl-2">Type</div>,
      cell: (row) => (
        <div className="select-icon ml-4" title={row?.issue_type?.name}>
          {row.issue_type ? <span className='icn' style={{color:row.issue_type.color, backgroundColor: row.issue_type.color+'20', borderColor: row.issue_type.color+'90'}}>{React.createElement(FaIcons[row.issue_type?.icon])}</span> : <></>}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row) => row?.task_assignee.map(assignee => assignee.username).join(', '),
      name: <div className="row-label">Assignee</div>,
      cell: (row) => (
        <div className="pl-2">
          {row?.task_assignee.map(assignee => assignee.username).join(', ')}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label pl-2">Status</div>,
      selector: (row) => row?.status,
      cell: (row) => (
        <div
          className="status"
          style={{
            color: row.status?.color && row.status?.color,
            backgroundColor:
              row.status?.color && row.status?.color + "20",
            borderColor:
              row.status?.color && row.status?.color + "90",
          }}
        >
          <div className="brightness-[0.6]">
            {React.createElement(FaIcons[row?.status?.icon])}
          </div>
          <div className="brightness-[0.6]">{row?.status?.name}</div>
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label">Started/Ended</div>,
      cell: (row) => (
        <div className="table-text-dark">
          {row?.estimated_start_date}
          {row?.end_date && <> &#8212; {row?.end_date}</>}
        </div>
      ),
      selector: (row) => row?.start_date,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label">Due Date</div>,
      cell: (row) => {
        let todayDate = new Date().toISOString().split("T")[0];
        return (
          <div
            className={
              row?.due_date === "Today"
                ? "dueto-today"
                : row.due_date < todayDate
                ? "dueto-past"
                : ""
            }
          >
            <div className="table-text-dark">
              {row?.due_date}{" "}
              {row?.due_date === "Today" || row.due_date < todayDate ? (
                <i className="fa-solid fa-triangle-exclamation ml-1"></i>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
      selector: (row) => row?.due_date,
      sortable: true,
      wrap: true,
    },
    {
      name: <div className="row-label pl-8">Progress Percent</div>,
      cell: (row) => (
        <div style={{ fontSize: 10 }} className="w-full">
          {/* <ProgressbarReadOnly progress_value={row.progress_percentage ? row.progress_percentage : 0}/> */}
          <ProgressBarUpdate
            progress_value={
              row.progress_percentage ? row.progress_percentage : 0
            }
            token={token}
            data={row}
            selectedOrganization={selectedOrganization}
            handleLogout={handleLogout}
            refreshTable={refreshTable}
            handleShowAlertModal={handleShowAlertModal}
            handleCloseAlertModal={handleCloseAlertModal}
          />
        </div>
      ),
      selector: (row) =>
        row.progress_percentage ? row.progress_percentage : 0,
      sortable: true,
      wrap: true,
      grow: 1.8,
    },
    {
      name: <div className="row-label">Labels</div>,
      cell: (row) => (
        <div className="flex overflow-hidden pl-6">{Mapme(row?.labels)}</div>
      ),
      selector: (row) => row.labels,
      sortable: true,
      wrap: true,
    },
    {
      cell: (row) => (
        <>
          {/* <div className="text-right more-options-wrapper">
          <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>
          <div className="more-options" style={{display: "none"}}>
            <button type="button" onClick={() => handleShowEditModal(row)}><i className="fa-solid fa-pencil" aria-hidden="true"></i> <span>Edit Task</span></button>
          </div>
        </div> */}
          <div className="flex items-center justify-end gap-3">
            <button
              title="Edit Task"
              type="button"
              onClick={() => handleShowEditModal(row)}
            >
              <i className="fa-solid fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              title="Edit Task"
              type="button"
              onClick={() => handleDeleteTask(row)}
            >
              <i className="fas fa-trash-can text-red-500" aria-hidden="true"></i>
            </button>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      grow: 0,
    },
  ];

  useEffect(() => {
    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      //setIsLoading(true);
      if (kanbanViewEdit) {
        setTableData([]);
      }

      console.log("selectedOrganization", selectedOrganization);

      var formParams;

      if (grp !== null && grp !== undefined && grp !== "") {
        formParams = {
          token: token,
          org: selectedOrganization?.slug,
          grp: grp,
        };
      } else {
        formParams = { token: token, org: selectedOrganization?.slug };
      }

      axios
        .post(serverConfig.api_base_url + "my_task_list_get", formParams)
        .then((response) => {
          console.log("my_task_list_get response", response);
          if (response.data.status === 200) {
            setViewPrivilege(true);
            if (response.data.items !== null) {
              console.log(response.data.items);
              setKanbanViewEdit(false);
              const updatedData = response.data.items.map(item => ({
                ...item,
                estimated_start_date: item.estimated_start_date,
                estimated_end_date: item.estimated_end_date,
                start_date : item.start_date,
                end_date : item.end_date
              }));
              setTableData(updatedData);
            } else {
              setTableData([]);
            }
            //setIsLoading(false);
          } else if (response.data.status === 301) {
            handleLogout();
          } else if (response.data.status === 401) {
            setViewPrivilege(false);
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
            //setIsLoading(false);
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
            //setIsLoading(false);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization, refreshList]);

  useEffect(() => {
    if (newTaskPopup) {
      setShowTask(true);
    }
  }, [newTaskPopup]);

  useEffect(() => {
    if (showTask) {
    } else {
      setShowTask(false);
      setPopup(false);
      setStatus({});
    }
  }, [showTask]);

  const refreshTable = () => {
    setRefreshList(!refreshList);
  };

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  //Edit
  const handleShowEditModal = (details) => {
    setFormData(details);
    setShowEditTask(true);

    let el = document.querySelectorAll(".more-options-wrapper .more-options");
    for (let i = 0; i < el.length; i++) {
      el[i].style.display = "none";
    }
  };
  
  const handleDeleteTask = (details) => {
    setFormData(details);
    setShowDeleteTask(true);
  };

  const [kanbanViewEdit, setKanbanViewEdit] = useState(false);
  //Edit
  const handleShowEditModalKanban = (taskSlug) => {
    console.log(taskSlug);

    axios
      .post(serverConfig.api_base_url + "my_task_list_get", {
        token: token,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log("my_task_list_get response", response);
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            console.log(response.data.items);
            let details = response.data.items.filter(
              (el) => el.tsk === taskSlug
            )[0];
            console.log(details);
            //setFormData({...details, grp: details.group.grp});
            setFormData(details);
            setShowEditTask(true);
            setKanbanViewEdit(true);
            let el = document.querySelectorAll(
              ".more-options-wrapper .more-options"
            );
            for (let i = 0; i < el.length; i++) {
              el[i].style.display = "none";
            }
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          //setIsLoading(false);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div>
      {viewPrivilege && (
        <div
          className={
            view === "list"
              ? //"p-3 table-custom mt-3 flex flex-col list-view table-expandable"
              "p-3 table-custom table-mapmetadata table-dbs-sub mt-3 flex flex-col list-view"
              : "p-3 table-custom mt-3 flex flex-col kanban-view"
          }
        >
          {isLoading && (
            <div className="loading absolute">
              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            </div>
          )}
          {/* <SortingDropdown /> */}

          {view === "list" && (
            <>
              {tableData.length > 0 ? (
                <DataTable
                  title={
                    <div className=" table-title rounded">
                      <div className=" mx-auto flex justify-between items-center">
                        <div className="task px-1">My Tasks</div>
                        {/* Right side: Search input and two dropdowns */}
                        <div className="flex items-center space-x-4 text-black">
                          <div className="flex items-center gap-12 mt-4">
                            <div className="relative">
                              <input
                                type="text"
                                onChange={handleSearchChange}
                                placeholder="Search"
                                className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
                              />
                              <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                                <i className=" text-sm fas fa-search"></i>
                              </div>
                            </div>
                          </div>
                          <div className="relative inline-block">
                            <select
                              value={selectedStatus}
                              onChange={handleStatusChange}
                              className="w-48 h-8 md:h-10 px-4 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style dark-optimise"
                            >
                              {dropdownItems.map((item, index) => (
                                <option
                                  key={index}
                                  value={item}
                                  className="dark-optimise"
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                              <i className="fa-solid fa-chevron-down text-sm "></i>
                            </div>
                          </div>
                          <div>
                            <div
                              className=" h-8 md:h-10 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none view-wrapper text-style flex items-center"
                              style={{ padding: 0 }}
                            >
                              <button
                                className={
                                  view === "list"
                                    ? "px-4 h-full text-lg selected"
                                    : "px-4 h-full text-lg"
                                }
                                onClick={() => {
                                  setView("list");
                                  setRefreshList(!refreshList);
                                }}
                                title="List View"
                              >
                                <i className="fa-solid fa-table-list"></i>
                              </button>
                              {/* <button
                                className={
                                  view === "kanban"
                                    ? "px-4 h-full text-lg selected"
                                    : "px-4 h-full text-lg"
                                }
                                onClick={() => {
                                  setView("kanban");
                                  setRefreshList(!refreshList);
                                }}
                                title="Kanban View"
                              >
                                <i className="fa-solid fa-square-poll-vertical"></i>
                              </button> */}
                            </div>
                          </div>
                          {/* <div className="relative inline-block">
                    <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center" >
                      <div className='pr-5'>
                        <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <i className="fa-solid fa-chevron-down text-sm"></i>
                    </div>
                  </div> */}
                          <button
                            type="button"
                            className="sub-grp px-3 py-2"
                            onClick={() => {
                              setShowTask(true);
                            }}
                          >
                            New Task
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                  columns={columns}
                  data={filteredTasks}
                  expandableRows
                  expandableRowsComponent={({ data }) => <RecursiveLinkedIssues formData={{...data,
                    grp: data.group.grp,
                    status: data.status.name,
                    issue_type: data?.issue_type?.cat,
                    issuetsk: data?.parent_issue?.tsk}} taskSlug={data.tsk} selectedGrp={data.group} token={token} selectedOrganization={selectedOrganization} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} handleLogout={handleLogout} refreshTable={refreshTable} />}
                  responsive={true}
                  pagination={tableData.length > 10 ? true : false}
                  highlightOnHover
                  //pointerOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="100%"
                  customStyles={{
                    rows: {
                      style: {
                        minHeight: '48px',
                        border: '2px solid var(--neutral-400)',
                        borderRadius: '10px',
                        marginTop: '10px',
                      },
                      highlightOnHoverStyle: {
                        color: isDarkTheme ? '#ffffff' : '#000000',
                        backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                        outline: 'none'
                      }
                    },
                    expanderButton: {
                      style: {
                        borderRadius: '8px',
                      }
                    }
                  }}
                  /* customStyles={{
                    rows: {
                      style: {
                        minHeight: '48px',
                        border: '2px solid var(--neutral-400)',
                        borderRadius: '10px',
                        marginTop: '10px',
                      },
                      highlightOnHoverStyle: {
                        color: isDarkTheme ? '#ffffff' : '#000000',
                        backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                        outline: 'none'
                      }
                    },
                    expanderButton: {
                      style: {
                        borderRadius: '8px',
                      }
                    }
                  }}
                  customStyles={{
                    rows: {
                      highlightOnHoverStyle: {
                        color: isDarkTheme ? "#ffffff" : "#000000",
                        backgroundColor: isDarkTheme ? "#222222" : "#EFF3F9",
                        borderRadius: "14px",
                        outline: "none",
                      },
                    },
                  }} */
                  //onRowClicked={(row) => { openTaskPopup(row.id) }}
                  //subHeader={TableHeader}
                />
              ) : (
                <div className="flex flex-col h-full items-center justify-center">
                  <div className="mb-8">
                    <img
                      src={require("./../images/no-groups-subgroups.png")}
                      alt="No records"
                    />
                  </div>
                  <p>
                    <b>This Organisation has no Tasks</b>
                  </p>
                  <p className="text-sm">
                    Create first Task for {selectedOrganization?.name}
                  </p>
                  <div className="mt-5">
                    <button
                      type="button"
                      className="sub-grp px-3 py-2"
                      onClick={() => {
                        setShowTask(true);
                      }}
                    >
                      New Task
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {view === "kanban" && (
            <>
              <div className="eEletC">
                <div className="jbrMhe">
                  <div className="table-title rounded">
                    <div className=" mx-auto flex justify-between items-center">
                      <div className="task px-1">My Tasks</div>
                      {/* Right side: Search input and two dropdowns */}
                      <div className="flex items-center space-x-4 text-black">
                        <div className="flex items-center gap-12 mt-4">
                          <div className="relative">
                            <input
                              type="text"
                              onChange={handleSearchChange}
                              placeholder="Search"
                              className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
                            />
                            <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                              <i className=" text-sm fas fa-search"></i>
                            </div>
                          </div>
                        </div>
                        {/* <div className="relative inline-block">
                          <select className="w-48 h-8 md:h-10 px-4 bg-white border border-gray-300 rounded-md focus:outline-none   appearance-none search-input text-style dark-optimise">
                            {dropdownItems.map((item, index) => (
                              <option
                                key={index}
                                value={item}
                                className="dark-optimise"
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                            <i className="fa-solid fa-chevron-down text-sm "></i>
                          </div>
                        </div> */}
                        <div>
                          <div
                            className=" h-8 md:h-10 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none view-wrapper text-style flex items-center"
                            style={{ padding: 0 }}
                          >
                            <button
                              className={
                                view === "list"
                                  ? "px-4 h-full text-lg selected"
                                  : "px-4 h-full text-lg"
                              }
                              onClick={() => {
                                setView("list");
                                setRefreshList(!refreshList);
                              }}
                              title="List View"
                            >
                              <i className="fa-solid fa-table-list"></i>
                            </button>
                            {/* <button
                              className={
                                view === "kanban"
                                  ? "px-4 h-full text-lg selected"
                                  : "px-4 h-full text-lg"
                              }
                              onClick={() => {
                                setView("kanban");
                                setRefreshList(!refreshList);
                              }}
                              title="Kanban View"
                            >
                              <i className="fa-solid fa-square-poll-vertical"></i>
                            </button> */}
                          </div>
                        </div>
                        {/* <div className="relative inline-block">
                      <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center" >
                        <div className='pr-5'>
                          <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>
                        </div>
                      </div>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                        <i className="fa-solid fa-chevron-down text-sm"></i>
                      </div>
                    </div> */}
                        <button
                          type="button"
                          className="sub-grp px-3 py-2"
                          onClick={() => {
                            setShowTask(true);
                          }}
                        >
                          New Task
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Kanban
                token={token}
                data={filteredTasks}
                setTableData={setTableData}
                Mapme={Mapme}
                selectedOrganization={selectedOrganization}
                handleLogout={handleLogout}
                refreshTable={refreshTable}
                handleShowAlertModal={handleShowAlertModal}
                handleCloseAlertModal={handleCloseAlertModal}
                setShowTask={setShowTask}
                setStatus={setStatus}
                handleShowMoreOptions={handleShowMoreOptions}
                handleShowEditModalKanban={handleShowEditModalKanban}
                isMyTask={true}
              />
            </>
          )}

          {showTask && (
            <div className="overlay">
              <NewTaskPopup
                setShowTask={setShowTask}
                token={token}
                selectedOrganization={selectedOrganization}
                handleLogout={handleLogout}
                refreshTable={refreshTable}
                handleShowAlertModal={handleShowAlertModal}
                handleCloseAlertModal={handleCloseAlertModal}
                statusSelected={status}
              />
            </div>
          )}

          {showEditTask && (
            <div className="overlay">
              <EditTaskPopup
                setShowEditTask={setShowEditTask}
                token={token}
                data={formData}
                selectedOrganization={selectedOrganization}
                handleLogout={handleLogout}
                refreshTable={refreshTable}
                handleShowAlertModal={handleShowAlertModal}
                handleCloseAlertModal={handleCloseAlertModal}
              />
            </div>
          )}

          {showDeleteTask && (
            <div className="overlay">
              <DeleteTaskPopup
                setShowDeleteTask={setShowDeleteTask}
                token={token}
                data={formData}
                selectedOrganization={selectedOrganization}
                handleLogout={handleLogout}
                refreshTable={refreshTable}
                handleShowAlertModal={handleShowAlertModal}
                handleCloseAlertModal={handleCloseAlertModal}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TableMyTasks;
