import React, {useState, useContext} from "react";
import { Context } from "../contextApi/context";

const ComingSoon = () => {

    const {collapse, tab,taskPopup} = useContext(Context)
  const[newGroupPopup , setNewGroupPopup]= useState(false)

    return (
        <>

    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
      <div class="container-fluid">
      <div className="overflow-x-auto">
                <div className="rounded-3xl p-3 coming-soon mt-4 flex flex-col justify-center">
          <img src={require("../Asset/Images/comingsoon.jpg")} className="under-dev-img"/>
          <h3 className="under-dev"><b>This feature is under development.</b></h3>
          </div>
          </div>
    </div>
     
      </div>
    </div>

        </>
    )
}

export default ComingSoon;