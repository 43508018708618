import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from "../services/UserService";
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { usePasswordValidation } from './hooks/userPasswordValidation.js';

const serverConfig = require("../config/server.js");

const RegisterUser = () => {

    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const [password, setPassword] = useState({
        firstPassword: "",
        secondPassword: "",
      });   
    const[confirmationPage, setConfirmationPage] = useState(false);
    const[confirm, setConfirm] = useState('');
    const[passwordError, setPasswordError] = useState('');
    const[confirmError, setConfirmError] = useState('');
    const[tokenStatus, setTokenStatus] = useState('');
    const[passwordMismatchError, setPasswordMismatchError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const[loading, setLoading] = useState(false);
    const ref_id = searchParams.get('ref_id');

    const [logoDarkBase64Text, setLogoDarkBase64Text] = useState('');
    const [disclaimerText, setDisclaimerText] = useState('');
    const [bannerBase64Text, setBannerBase64Text] = useState('');
    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
        match,
        specialChar,
      ] = usePasswordValidation({
        firstPassword: password.firstPassword,
        secondPassword: password.secondPassword,
      });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    const togglePasswordVisibility2 = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
      };


      const setFirst = (event) => {
        setPassword({ ...password, firstPassword: event.target.value });
      };
      const setSecond = (event) => {
        setPassword({ ...password, secondPassword: event.target.value });
      };
      useEffect(() => {
        if(validLength && hasNumber && upperCase && lowerCase && match && specialChar) {
          setButtonDisabled(false);
        } else {
          setButtonDisabled(true);
        }
      },[validLength, hasNumber, upperCase, lowerCase, match, specialChar]);


    const checkValidity = () => {
        axios.post(serverConfig.api_base_url + "user_signup_validate", 
        {
           ref_token: ref_id
        }
        ).then(response=> {
          console.log(response.data);
          if(response.data.status === 502) {
            setTokenStatus('Invalid');
          }
          else if(response.data.status === 200) {
            setTokenStatus('Valid');
          }
        }).catch(err=> {
            console.log(err);
        })
    }

    const validateConfirmPassword = (password, confirm) => {
        if (confirm === '') {
          setConfirmError('Confirm password is required.');
          return false;
        } else if (password !== confirm) {
          setConfirmError('Passwords do not match.');
          return false;
        } else {
          setConfirmError('');
          return true;
        }
      };

    const userSignUpComplete = () => {
      setLoading(true);
        axios.post(serverConfig.api_base_url + "user_signup_complete", {
            url: window.location.host,
            password: password?.firstPassword,
            ref_token: ref_id

        }).then(response=> {
           console.log(response.data);
           if(response.data.status === 200) {
            setLoading(false);
            setConfirmationPage(true);
            setTimeout(() => {
                //UserService.doLogin();
                navigate("/");
                //setConfirmationPage(false);
            }, 3000);
           }
           else 
           {
            setPasswordError(response?.data?.message);
           }
        }).catch(err=> {
           console.log(err);
           setLoading(false);
        })
    }

    useEffect(() => {

        axios.post(serverConfig.api_base_url + "domain_details_get",{domainName:window.location.host})
        .then(response=>{
        console.log('domain_details_get response', response);
        if(response.data.status === 200) {
            setLogoDarkBase64Text(response.data.items.logo_dark_image);
            setDisclaimerText(response.data.items.disclaimer_sso);
            setBannerBase64Text(response.data.items.banner_image);
        } else if(response.data.status >= 400 && response.data.status <= 499){
            alert(response.data.message);
        }
        })
        .catch(err=>console.log("error is",err));

        checkValidity();

    }, []);


    return(
        <>

        <div className='login'>
          <div className='login-container'>
    
            <div className='login-left'>
    
            <div class="logo"><img src={logoDarkBase64Text} alt="Logo" /></div>
    
            <div class="content">
                        {/* <h2>Welcome!</h2>
                        <p>Let's make every project a masterpiece!</p> */}
                        <div class="banner"><img src={bannerBase64Text} alt="Banner" /></div>
            </div>
    
            </div>
            
        {confirmationPage !== true ?
            <div className='form-container'>
    
            <div className='form-wrapper'>
            {tokenStatus==='Valid' ? <>

            <header>
              <h1 id="kc-page-title">        
              Set Password
              </h1>
            </header>

            {passwordMismatchError !== '' ? <p className="error-text-msg">{passwordMismatchError}</p> : <></>}

            <div className="kc-content">
                            <div className="kc-content-wrapper">
    
        <div className="kc-form">
          <div>
                 <div>
                    <div className='flex flex-col'>
                        <label for="username" className="mb-2 font-semibold" >Password</label>
    
                        <div className='relative'>
                            <input
                            placeholder="Enter password"
                            type={showPassword ? "text" : "password"}
                            value={password?.firstPassword}
                            onChange={setFirst}
                            className={passwordError ? 'error-validaion password-input pr-10': 'password-input pr-10'}
                            />
                            <span
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-15 right-80 pr-3 flex items-center cursor-pointer"
                            >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
    
                    </div>
                   
    
                    <div className='flex flex-col'>
                        <label for="email" className="mb-2 font-semibold" >Confirm Password</label>
    
                        <div className='relative'>
                            <input
                            placeholder="Enter password"
                            type='password'
                            value={password?.secondPassword}
                            onChange={setSecond}
                            className={confirmError ? 'error-validaion password-input pr-10': 'password-input pr-10'}
                            />
                            {/* <span
                            onClick={togglePasswordVisibility2}
                            className="absolute inset-y-15 right-80 pr-3 flex items-center cursor-pointer"
                            >
                            {showPasswordConfirm ? <FaEyeSlash /> : <FaEye />}
                            </span> */}
                        </div>
                    </div>

                    <ul className='validation-check mt-4 mb-4'>
                        <li className={validLength ? 'yes' : 'no'}>Minimum 8 characters required</li>
                        <li className={hasNumber ? 'yes' : 'no'}>Must have at least 1 number</li>
                        <li className={upperCase ? 'yes' : 'no'}>Must have at least 1 uppercase</li>
                        <li className={lowerCase ? 'yes' : 'no'}>Must have at least 1 lowercase</li>
                        <li className={specialChar ? 'yes' : 'no'}>Must have at least 1 special character</li>
                        <li className={match ? 'yes' : 'no'}>Password and Confirm Password must match</li>
                    </ul>

                       
    
                    <div className={`kc-form-buttons mt-4 mb-4 ${loading || buttonDisabled ? 'disabled' : ''}`} style={{ cursor: loading ? "not-allowed" : "pointer" }} onClick={!loading ? userSignUpComplete : null}>
                        <input type="hidden" />
                        <input tabIndex="4" className="kc-form-buttons" type="submit" value="Sign Up" disabled={loading || buttonDisabled} style={{ display: 'none' }} />
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            Submit
                            {loading && <div className="signup-loader" style={{ marginLeft: '15px' }}></div>}
                        </span>
                    </div>
                </div> 
            </div> 
    
    
        </div>
    
    
                            </div>
                        </div>
    
                        <div id="disclaimer">{disclaimerText}</div>
                        </>
                        : <>
                        <header>
                            <h1 id="kc-page-title">        
                            Set Password
                            </h1>
                            </header>
                        <div className='error-page'>This link is expired!</div></>}  
            </div>
    
            </div> : 
          <div className='form-container'>

          <div className='form-wrapper'>
         <div className='loading-div flex flex-col'>
              <img src={require(`./assets/uploadSuccess.png`)} alt='' className='loading-img' style={{marginTop: "0px !important"}} />
              <h1 className='invite-text'>Your password has been successfully set.</h1>
         </div>
       </div>
       </div> 
      }
    
          </div>
        </div>
    
        </>
    );
};

export default RegisterUser;