import React, { useState, useContext } from 'react';
import { Context } from '../contextApi/context';
import ManResTable from './tables/ManResTable';

const ManageResource = () => {

    const { collapse} = useContext(Context)

    return (
        <>
            <div>
                {/* <HeaderSecond linkName={'New Group'} link={'#'} popup={newGroupPopup} setPopup={setNewGroupPopup}/> */}
                {/* {newGroupPopup &&<div className='overlay'> <NewGroupPopup setNewGroupPopup={setNewGroupPopup}/></div>} */}
                <div className={collapse ? 'content-body' : 'content-body-collapse'} >
                    <div class="container-fluid">
                        <ManResTable />
                        {/* subgroup  */}



                    </div>

                </div>
            </div>
        </>
    );
}

export default ManageResource;