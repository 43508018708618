import React from 'react'
import { useEffect,useState, useContext, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import { Context } from '../../contextApi/context';
// import EditUser from '../../kz-usermanagement/forms/EditUser';
import axios from 'axios';
import UserService from '../../services/UserService';
import { CSVLink } from "react-csv";
// import CreateUser from '../../kz-usermanagement/forms/CreateUser';
import "../css/dataman.css";
import ShowError from '../popups/ShowErrors';
import ImportData from '../popups/ImportData';
import DeleteRecord from '../popups/DeleteRecord';
import DeleteInfo from '../popups/DeleteInfo';
import { useNavigate } from 'react-router';

const serverConfig = require("../../config/server.js");

const DataManagement = () => {

    const navigate = useNavigate(); 
    const [selectedRows, setSelectedRows] = useState([]);
    const {tab , setTab}=useContext(Context);
    const [appDataGridRender, setAppDataGridRender] = useState();
    const [tableData, setTableData] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const {selectedOrganization} = useContext(Context);
    const [showImportData, setShowImportData] = useState(false);
    const [deleteInfoRow, setDeleteInfoRow] = useState('');
    const [viewLogModal, setViewLogModal] = useState(false);

    /* token - start */
    const [token, setToken] = useState(null);
    const [organizationList, setOrganizationList] = useState([]);
    const [userPrivileged, setUserPrivileged] = useState(false);
    const [rowClicked, setRowClicked] = useState('');
    const [editRowClicked, setEditRowClicked] = useState('');
    const [deleteRowClicked, setDeleteRowClicked] = useState('');
    const [showErrorRowClicked, setShowErrorRowClicked] = useState('');
    const [dltmodalClosed, setDltModalClosed] = useState(false);
    const [actions, setActions] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [addUser, setAddUser] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);
    const [uploadErrorList, setUploadErrorList] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [viewLog, setViewLog] = useState('');
    const [displayNone, setDisplayNone] = useState('none');
    const [disableImport, setDisableImport] = useState(false);
    const [templatePresent, setTemplatePresent] = useState(false);
    const dropdownRef = useRef(null);
  
    const handleCloseErrors = () => setShowErrors(false);
    const handleShowErrors = () => setShowErrors(true);

const toggleTooltip = () => {
  setShowTooltip(!showTooltip);
};

const handleLogout = () => {
  sessionStorage.clear();
  UserService.doLogout();
}

  const getToken = () => {
  console.log(sessionStorage.getItem('token'));
  let jwt = UserService.getToken();
  axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
  .then(response=>{
    console.log('user_get_token response', response);
    if(response.data.status === 200){
      sessionStorage.setItem('token', response.data.token);
      setToken(response.data.token);
    } else if(response.data.status >= 400 && response.data.status <= 499){
      alert(response.data.message);
    } else if(response.data.jwt_validation === 'fail') {
      handleLogout();
    }
  })
  .catch(err=>console.log("error is",err));
}

const handleClick = (importid) => {
  console.log(importid);
  //import_get_status
  axios.post(serverConfig.cstar_api + "import_get_status",{token:'JJVOGquC1FGQD1DyifLvD6Z4NbZPyHiOAUbFae6fklbgcBRUoCnqpb7vxDZrEldubSeZFLfpFzeS7nWYv26Pf9q5Batjv13CkdwlDw9C3RyzDTYBwe1RBRXByoKVRVu5', importId:importid})
  .then(response=>{
    console.log('import_get_status response', response);
    if(response.status === 200){
      if(response.data.items !== null){
        console.log(JSON.parse(response.data.items));
        const parsedResponse = JSON.parse(response.data.items);
        setUploadErrorList(parsedResponse?.import_messages);
        handleShowErrors();
      } else if(response.data.items === null && response.data.message === 'success') {
        setUploadErrorList([]);
      }
    } else if(response.data.status === 301){
      handleLogout();
    } else {
      alert(response.data.message);
    }
  })
  .catch(err=>console.log("error is",err));
}


useEffect(() => {
  console.log(sessionStorage.getItem('token'));
  if(sessionStorage.getItem('token') === null){
    getToken();
  } else {
    console.log('user_get_token', sessionStorage.getItem('token'));
    setToken(sessionStorage.getItem('token'));
  }
}, []);


useEffect(() => {
  if(token !== null && token !== undefined) {
  axios.post(serverConfig.api_base_url + "dwh_import_get_list",{token:token, org:selectedOrganization?.slug})
  .then(response=>{
    console.log('import_get_list response', response);
    if(response.data.status === 200){
      if(response.data.items !== null){
        setBtnDisabled(false);
        console.log(response.data.items);
        let items = response.data.items;
        setTableData(response.data.items);
        setTableData((stateAppDataGrid) => {
          let appDataArray = stateAppDataGrid;
          let appData = [];

          console.log(appDataArray);
          appDataArray && appDataArray.reverse().map((item, index) => {
            appData.push(item);
          });
          console.log(appData, 'app data')
          setTableData(appData);
          return stateAppDataGrid;
        })
      }
      else if(response.data.items === null) {
        setBtnDisabled(true);
        setTableData([]);
      }
    }
      else if(response.data.status === 301){
        handleLogout();
      }
    })
  .catch(err=>console.log("error is",err));

}},[dltmodalClosed, token, selectedOrganization, showImportData]);

useEffect(() => {
  console.log('token', token);
  console.log(sessionStorage.getItem('token'));

  
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('cToken', token);
      /* user_get_list */
      axios.post(serverConfig.api_base_url + "user_get_list",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("user_get_list response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            let appData = [];
            items.reverse().map((item, index) => {
              let curRole = [];
              curRole = item.roles;
            });
            console.log(items, 'user_get_list');
            console.log(appData);
            //setTableData(items);
            setAppDataGridRender(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      
      axios.post(serverConfig.api_base_url + "dwh_template_get_group",{token:token, parentGroupId:null,org:selectedOrganization?.slug})
      .then(response=>{
        console.log('template_get_group response', response);
        if(response.data.items === null){
          setDisableImport(true);
        }
      }).catch(err=>{
        console.log(err, 'err');
      })

      /* role_get_list */
      axios.post(serverConfig.api_base_url + "role_list_get",{token: token, level:'Organization'})
      .then(response=>{
        console.log('role_get_list response', response);
        console.log('role_get_list response items',response);
        if(response.data.status === 200){
         
          console.log(selectedOrganization?.slug, 'selectedOrg')
          console.log(response.data.items, 'role_options');
          setRolesList(response.data.items);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  
}, [token, actions, selectedOrganization]);

useEffect(() => {

  if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
    console.log('selectedOrganization', selectedOrganization)
    console.log('token', token);
    /* dwh_template_list_get */
    axios.post(serverConfig.api_base_url + "dwh_template_list_get",{token:token, org:selectedOrganization?.slug})
    .then(response=>{
      console.log("dwh_template_list_get response:", response);
      if(response.data.status === 200){
        if(response.data.items === null) {
          setTemplatePresent(false);
        }
        else {
          setTemplatePresent(true);
        }
      }
    })
    .catch(err=> {
      console.log(err);
    })
  }

}, [token])

const handleShowMoreOptions = (event) => {
  let el = document.querySelectorAll('.more-options-wrapper .more-options');
  var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');

  if (x.style.display === "none") {
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
    x.style.display = "block";
    setDisplayNone('hello');
  } else {
    x.style.display = "none";
  }
}

const [showDropdown, setShowDropdown] = useState(false);

useEffect(() => {
  const handleMouseDown = (event) => {
    console.log(event.target.classList, !event.target.classList.contains('btn-more-options'), 'classlist')
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains("action-option")) {
      setDisplayNone('none');
    }
  };
  document.addEventListener('mousedown', handleMouseDown);

  return () => {
    document.removeEventListener('mousedown', handleMouseDown);
  };
}, [displayNone]);



const data = {
  columns: [
    { name: 'Template name', field: 'template_name'},
    { name: 'File name', field: 'file_name'},
    { name: 'Status', field: 'status'},
    { name: 'Uploaded by', field: 'imported_by'},
    { name: 'Uploaded time', field: 'last_updated_at'},
    { name: 'Upload Comments', field: 'comments'},
    { name: 'Actions', field: 'actions' },
  ],
  rows: tableData,
};



     
     const sortDown=<i class="fa-solid fa-arrow-down-wide-short"></i>;
     const sortUp=<i class="fa-solid fa-arrow-up-short-wide"></i>;
    
     
      const dropdownItems = ['Updated', 'Option 2', 'Option 3']
      const sortingIcons= [sortDown,sortUp]
      const columns = [
      
              {
                  name: <div className='row-label'>Template Name</div>,
                 
                  cell: (row) => <div className="flex-column  py-2 " >
                  <div className='text-heading'>{row.template_name}</div>
                  {/* <div className='text-detail pt-1'>{row.detail}</div> */}
                   </div>,
                  grow:1,
                  sortable: false,
                 
              },
              {
                name: <div className='row-label'>File Name</div>,
                selector:row=>row.type,
                cell: (row) => <div className='text-heading'>
                          {row.file_name}
                        </div>,
                sortable: true,
             
                wrap:true
             },
             {
              name: <div className='row-label'>Status</div>,
              selector:row=>row.type,
              cell: (row) => row.status==='failed' ?
                                <div className={'type-department'} onClick={() => {handleClick(row?.import_id); if(showErrorRowClicked===row){setShowErrorRowClicked('')}else{setShowErrorRowClicked(row); setShowErrors(true)}} }>
                                <div>Failed</div>
                                {row===showErrorRowClicked && showErrors && <div className='overlay'><ShowError uploadErrorList={uploadErrorList} setModalClosed={setShowErrors} modalClosed={showErrors} /></div>}
                                </div> :
                                row.status==='initiated' ?
                                <div className={'type-initiated'} onClick={() => {if(deleteInfoRow===row){setDeleteInfoRow('')}else{setDeleteInfoRow(row);}} }>
                                <div>Initiated</div>
                                {row===deleteInfoRow && <div className='overlay'><DeleteInfo modalClosed={dltmodalClosed} setModalClosed={setDltModalClosed} setDeleteInfoRow={setDeleteInfoRow} importId={row?.import_id}/></div>}
                                 </div>:
                                row.status==='deleted' ?
                                <div className={'type-department'} onClick={() => {if(deleteInfoRow===row){setDeleteInfoRow('')}else{setDeleteInfoRow(row);}} }>
                                <div>Deleted</div>
                                {row===deleteInfoRow && <div className='overlay'><DeleteInfo modalClosed={dltmodalClosed} setModalClosed={setDltModalClosed} setDeleteInfoRow={setDeleteInfoRow} importId={row?.import_id}/></div>}
                                 </div>:
                                 
                                row.status==='success' ?
                                <div className={'type-Client'} onClick={() => {}}>
                                <div>Success</div>
                                </div>:
                                <div className={'type-project'} onClick={() => {}}>
                                <div>{row?.status}</div>
                                </div>
                             ,
              sortable: true,
           
              wrap:true
           },
              {
                  name: <div className='row-label'>Imported by</div>,
                  selector:row=>row.type,
                  cell: (row) => <div className='text-heading'>
                                {row.imported_by}
                              </div>
                                  ,
                  sortable: true,
               
                  wrap:true
              },
              {
                name: <div className='row-label'>Uploaded time</div>,
                selector:row=>row.type,
                cell: (row) =><div className='text-heading'>
                                {row.start_time}
                              </div>
                                ,
                sortable: true,
             
                wrap:true
            },
            {
              name: <div className='row-label'>Uploaded comments</div>,
              selector:row=>row.type,
              cell: (row) =><div className='text-heading'>
                              {row.comments}
                            </div>
                              ,
              sortable: true,
           
              wrap:true
          },
          {                   
                  name: <div className='row-label'>Action</div>,
                  cell: (row) =>  
                 // <div className="text-right more-options-wrapper">
               
                   <div>
                  <Button variant='text' className='mr-2' onClick={() => {setViewLog(row); setViewLogModal(!viewLogModal);}}><i class="fa-solid fa-eye"></i></Button>
                  {row?.delete_flag === 1 ? <Button variant='text' onClick={() => {deleteRowClicked===row?setDeleteRowClicked(''):setDeleteRowClicked(row);}}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> </Button> : <></>}
                  {row===viewLog && viewLogModal && <div className='overlay'><ShowError uploadErrorList={''} modalClosed={viewLogModal} setModalClosed={setViewLogModal}/></div>}
                  {row===deleteRowClicked && <div className='overlay'><DeleteRecord modalClosed={dltmodalClosed} setModalClosed={setDltModalClosed} setDeleteRowClicked={setDeleteRowClicked} importSlug={row?.imp} templateSlug={row?.temp} /></div>} 
                 </div>
                //</div>

                  // <div onClick={()=>{console.log(row?.delete_flag === 1)}}> {row?.delete_flag === 1 ?<button className='text-heading' onClick={()=>{deleteRowClicked===row?setDeleteRowClicked(''):setDeleteRowClicked(row); console.log(row?.delete_flag, 'delete')}}><i class="fa fa-trash"></i></button> : <></> }
                  // {row===deleteRowClicked && <div className='overlay'><DeleteRecord modalClosed={dltmodalClosed} setModalClosed={setDltModalClosed} setDeleteRowClicked={setDeleteRowClicked} importSlug={row?.imp} templateSlug={row?.temp} /></div>}</div>
                   ,
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                 
            },              
          ]           

            return (
              <div className="overflow-x-auto">
                <div className="p-3 table-custom mt-4 ">
                {templatePresent===false || tableData.length < 0 && <h1 className='font-semibold text-2xl mt-4 mb-4'>Data Ingestion Details</h1>}
                  {tableData.length > 0 && templatePresent===true ? 
                   <DataTable
                   title={ <div className=" table-title rounded">
                   <div className=" mx-auto flex justify-between items-center">
                     {/* Left side: Three tabs flexed together */}
                     <div className="flex space-x-4">
                      <h5 className='font-semibold theader'>Data Ingestion Details</h5>
                     </div>               
                     {/* Right side: Search input and two dropdowns */}
                     <div className="flex items-center space-x-4 text-black">
                     {/* <div class="flex items-center gap-12 mt-4">
<div class="relative">
  <input
    type="text"
    placeholder="Search"
    class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
  /> 

  <div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
    <i class=" text-sm fas fa-search"></i>
  </div>
</div>

</div>

<div className="relative inline-block">
<div
      className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center"
    >
     
       <div className='pr-5'>
       <i class="fa-solid fa-arrow-down-short-wide text-sm" ></i>
      
       </div>
       
     
    </div>
    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
    <i class="fa-solid fa-chevron-down text-sm"></i> 
    </div>
</div> */}

<CSVLink data={tableData} columns={columns} className={btnDisabled ? 'download-disabled' : 'download'} filename="import-log.csv">Download Log as CSV</CSVLink>

{/* <button className='sub-grp px-3 py-2' onClick={()=>{}} >
Download Log as CSV
 </button> */}
<button className={disableImport ? 'download-disabled' : 'sub-grp px-3 py-2'} onClick={()=>{setShowImportData(true);}} >
Import New Data
 </button>
 {showImportData && <div className='overlay'><ImportData setShowImportData={setShowImportData} templatePresent={templatePresent} /></div>}                     
                     </div>
                   </div>
                 </div>}
                    columns={columns}
                    data={tableData}
                    responsive={true}
                    pagination={true}
                    customStyles={{
                      rows: {
                        rows: {
                          className: 'custom-hover',
                        }
                      },
                      cells: {
                        className: 'p-6',
                      },
                    }}     
                  /> 
                   :
                    <div className='flex flex-col h-3/4 items-center justify-center'>
                <div className='mb-8'><img src={require('../../Asset/Images/no-records.png')} alt="No records" /></div>
                <p><b>This Organisation does not have any data ingested yet.</b></p>
                <p className='text-sm'>Import Data</p>
                <div className='mt-5'><button className={'sub-grp px-3 py-2'} onClick={()=>{setShowImportData(true);}}>Import Data</button></div>
                {showImportData && <div className='overlay'><ImportData setShowImportData={setShowImportData} templatePresent={templatePresent} /></div>}
              </div>
              }
              
                </div>
              </div>
            );
}

export default DataManagement;