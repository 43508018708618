// import React from 'react';
// import PropTypes from 'prop-types';

// const ProgressBar = ({ value1, value2 }) => {
//   const width = `${(value1 / value2) * 100}%`;
// const widthtwo=(value1 / value2) * 100;
//   return (
//     <div className="relative pt-1 w-full h-3" data-tag="allowRowEvents">
//      <div className='flex items-center justify-between table-text-dark'  data-tag="allowRowEvents">
//     <div data-tag="allowRowEvents">{value1}h/{value2}h</div>
//     <div data-tag="allowRowEvents">{parseInt(width)}%</div>
//      </div>
//       <div className="flex h-2 mb-4 overflow-hidden text-xs bg-slate-200 rounded-lg mt-2" data-tag="allowRowEvents">
//         <div
//           style={{ width }}
//           className={ widthtwo===100?' bg-green-400 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded-lg':'bg-teal-400 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded-lg'}

//           data-tag="allowRowEvents"></div>
//       </div>
//     </div>
//   );
// };

// ProgressBar.propTypes = {
//   value1: PropTypes.number.isRequired,
//   value2: PropTypes.number.isRequired,
// };

// export default ProgressBar;


import React, { useState } from "react";
import './../css/slider.css';
import { useEffect } from "react";


const ProgressBar = ({ progress_value, formData, setFormData }) => {

  const [progress, setProgress] = useState(progress_value);

  useEffect(() => {
    console.log(progress_value, 'progress')
  }, []);

  useEffect(() => {

    document.getElementById("myinput").oninput = function () {

      var value = (progress - 0) / (100 - 0) * 100;
      console.log(value, 'dragging value')
      this.style.background = 'linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) ' + value + '%, #fff ' + value + '%, white 100%)'
    };

  }, []);

  const handleChange = (e) => {
    document.getElementById("myinput").oninput = function () {
      var value = (this.value - this.min) / (this.max - this.min) * 100;
      console.log(value, 'dragging value')
      this.style.background = 'linear-gradient(to right,var(--secondary-300) 0%,var(--secondary-300) ' + value + '%, #fff ' + value + '%, white 100%)'
    };
    console.log(e.target.value);
    setProgress(e.target.value);
    setFormData({ ...formData, progress_percentage: e.target.value })
  }

  return (
    <>
      <div class="slidecontainer">
        <div className="progress-value">{progress}%/100%</div>
        <input type="range" id="myinput" className="custom-input-range" min="0" max="100" value={progress} onChange={handleChange} style={{background: `linear-gradient(to right, var(--secondary-200) 0%, var(--secondary-200) ${progress}%, rgb(255, 255, 255) ${progress}%, white 100%)`}} />
      </div>
    </>
  )
};

export default ProgressBar;

