import React,{useState, useContext} from 'react'
import { Context } from '../contextApi/context';
import TeamMemberAccessTable from './tables/TeamMemberAccessTable';

function TeamMemberAccess() {
  
    const {collapse} = useContext(Context)
    const [teamPopup , setTeamPopup]= useState(false)
  return (
    <div>
    {teamPopup &&<div className='overlay'></div>}
    <div className={collapse?'content-body':'content-body-collapse'} >
          
          <div class="container-fluid">
            <TeamMemberAccessTable />
          </div>
         
          </div>
    </div>
  )
}

export default TeamMemberAccess;