import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserService from '../../services/UserService.js';
import { useContext } from 'react';
import { Context } from '../../contextApi/context';
import { Button } from 'react-bootstrap';
import '../css/cat.css';
import Notification from '../../common/notification/Notification.js';

const serverConfig = require('../../config/server.js');

function EditSubCat({ setEditCategory, rowToEdit, onEditSuccess }) {
    const { selectedOrganization } = useContext(Context);
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
    const [editedCategory, setEditedCategory] = useState(rowToEdit.child_category_name);
    const [editedDescription, setEditedDescription] = useState(rowToEdit.description);
    const [formMessage, setFormMessage] = useState({ type: '', message: '' });
    const [tab, setTab] = useState('Edit SubCategory');
    const [memberTab, setMemberTab] = useState(0);
    const [editedAttributes, setEditedAttributes] = useState([]);
    const [showOptionsIndex, setShowOptionsIndex] = useState(null);
    const [subcategoryResources, setSubcategoryResources] = useState([]);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);

    useEffect(() => {
        //console.log("Alerrttt",alertMessage);
        if (alertMessage !== '') {
            setShowAlertModal(true);
        } else {
            setShowAlertModal(false);
        }
    }, [alertMessage]);

    const handleCloseAlertModal = () => {
        //console.log("closeeee modal")
        setAlertMessage('');
        setShowOkButton(false);
        setShowAlertModal(false);
    };


    const getToken = () => {
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt })
            .then(response => {
                console.log('user_get_token response', response);
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setCToken(response.data.token);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log('error is', err));
    };

    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
        console.log("Rowww", rowToEdit);
    }, []);


    useEffect(() => {
        if (rowToEdit && rowToEdit.child_category_attributes) {
            let existingAttributeNames = [];
            if (rowToEdit.resources && Array.isArray(rowToEdit.resources) && rowToEdit.resources.length !== 0) {
                console.log("resss", rowToEdit.resources);
                existingAttributeNames = Array.from(new Set(rowToEdit.resources.flatMap(resource => (
                    resource.attributes ? resource.attributes.map(attribute => attribute.attribute_name) : []
                ))));
            } else {
                existingAttributeNames = rowToEdit.child_category_attributes.map(attribute => attribute.attribute_name);
            }
    
            setEditedAttributes(existingAttributeNames.map(name => {
                const matchingItem = rowToEdit.child_category_attributes.find(item => item.attribute_name === name);
                return matchingItem ? { AttributeName: matchingItem.attribute_name, Description: matchingItem.attribute_description, Slug: matchingItem.attribute_slug } : { AttributeName: name, Description: '', Slug: '' };
            }));
        }
    }, [rowToEdit]);
    



    useEffect(() => {
        axios.post(serverConfig.api_base_url + 'get_subcategory_resources_list', {
            token: cToken,
            cat: rowToEdit.child_category_slug,
            org: selectedOrganization.slug,
        })
            .then(response => {
                const { status, message, items } = response.data;
                if (status === 200) {
                    console.log('Items:', items);
                    setSubcategoryResources(items);
                } else {
                    showMessage(message, 'error');
                }
            })
            .catch(error => {
                console.error('Error in get_subcategory_resources:', error);
                showMessage('Error fetching subcategory resources', 'error');
            });
    }, [rowToEdit, cToken, selectedOrganization]);

    const showMessage = (message, type = 'info') => {
        setFormMessage({ type, message });
        console.log(`${type.toUpperCase()}: ${message}`);
    };

    const handleEditCategory = async () => {
        console.log('Edited Category:', editedCategory);
        console.log('Edited Description:', editedDescription);
        console.log('Edited Attributes:', editedAttributes);

        if (editedCategory || editedDescription) {
            try {
                const response = await axios.post(serverConfig.api_base_url + 'resource_category_update', {
                    token: cToken,
                    cat: rowToEdit.child_category_slug,
                    org: selectedOrganization.slug,
                    new_name: editedCategory,
                    new_description: editedDescription,
                });

                const { status, message } = response.data;
                setAlertMessage('Category updated successfully');
                setShowOkButton(true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    onEditSuccess();
                }, 500);
                //showMessage('Updated', "success");
                if (status !== 200) {
                    console.error("Error updating ", message);
                    setAlertMessage('Error updating category');
                    setTimeout(() => {
                        handleCloseAlertModal();
                    }, 3000);
                }
            } catch (error) {
                console.error(`Error updating category ${attribute.AttributeName}:`, error);
                setAlertMessage('Error updating category');
                setTimeout(() => {
                    handleCloseAlertModal();
                }, 3000);
            }
        }

        const createAttribute = async (attribute) => {
            try {
                const response = await axios.post(serverConfig.api_base_url + 'resource_category_attribute_create', {
                    token: cToken,
                    cat: rowToEdit.child_category_slug,
                    org: selectedOrganization.slug,
                    attribute_name: attribute.AttributeName,
                    attribute_description: attribute.Description,
                });

                const { status, message } = response.data;
                setAlertMessage('Attribute created successfully');
                setShowOkButton(true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    onEditSuccess();
                }, 500);
                //showMessage('Updated', "success");
                if (status !== 200) {
                    console.error("Error creating ", message);
                    setAlertMessage('Error creating attribute ');
                    setTimeout(() => {
                        handleCloseAlertModal();
                    }, 3000);
                }
            } catch (error) {
                console.error(`Error creating attribute ${attribute.AttributeName}:`, error);
                setAlertMessage('Error creating attribute ');
                setTimeout(() => {
                    handleCloseAlertModal();
                }, 3000);
            }
        };

        const updateAttribute = async (attribute) => {
            console.log("attribute", attribute);
            try {
                const nameChangePayload = {
                    token: cToken,
                    cat: rowToEdit.child_category_slug,
                    org: selectedOrganization.slug,
                    attr: attribute.Slug,
                    ...(attribute.isNameChanged && {
                        new_name: attribute.AttributeName,
                    }),
                    ...(attribute.isDescriptionChanged && {
                        new_description: attribute.Description,
                    }),
                };
                console.log("attributepayload", nameChangePayload);
                const response = await axios.post(serverConfig.api_base_url + 'resource_category_attribute_update', nameChangePayload);

                const { status, message } = response.data;
                setAlertMessage('Attribute updated successfully');
                setShowOkButton(true);
                setTimeout(() => {
                    handleCloseAlertModal();
                    onEditSuccess();
                }, 500);
                //showMessage('Updated', "success");
                if (status !== 200) {
                    console.log(`Error updating attribute ${attribute.AttributeName}: ${message}`);
                    setAlertMessage('Error updating attribute');
                    setTimeout(() => {
                        handleCloseAlertModal();
                    }, 3000);
                }
            } catch (error) {
                console.error(`Error updating attribute ${attribute.AttributeName}:`, error);
                setAlertMessage('Error updating attribute');
                setTimeout(() => {
                    handleCloseAlertModal();
                }, 3000);
            }
        };

        for (const attribute of editedAttributes) {
            if (attribute.isNewAttribute) {
                await createAttribute(attribute);
            } else if (attribute.isNameChanged || attribute.isDescriptionChanged) {
                await updateAttribute(attribute);
            }
        }


    };

    const handleAttributeChange = (index, field, value) => {
        setEditedAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes];
            const updatedAttribute = { ...updatedAttributes[index], [field]: value };

            if (field === 'AttributeName' && !updatedAttribute.oldAttributeName) {
                updatedAttribute.oldAttributeName = updatedAttributes[index].oldAttributeName || updatedAttributes[index].AttributeName;
            }

            if (field === 'AttributeName') {
                updatedAttribute.isNameChanged = updatedAttribute.oldAttributeName !== value;
            } else if (field === 'Description') {
                updatedAttribute.isDescriptionChanged = updatedAttributes[index].Description !== value;
            }

            updatedAttributes[index] = updatedAttribute;
            console.log('Attribute changes:', updatedAttributes);

            return updatedAttributes;
        });
    };

    const deleteAttribute = async (attr) => {
        try {
            const response = await axios.post(serverConfig.api_base_url + 'resource_category_attribute_delete', {
                token: cToken,
                org: selectedOrganization.slug,
                cat: rowToEdit.child_category_slug,
                attr: attr.Slug,
            });

            const { status, message } = response.data;
            setAlertMessage('Attribute deleted successfully');
            setShowOkButton(true);
            setTimeout(() => {
                handleCloseAlertModal();
                onEditSuccess();
            }, 3000);
            if (status !== 200) {
                console.log(`Error deleting attribute `, message);
                setAlertMessage('Error deleting attribute');
                setTimeout(() => {
                    handleCloseAlertModal();
                }, 3000);
            }
        } catch (error) {
            console.error(`Error deleting attribute ${attr.AttributeName}:`, error);
            setAlertMessage('Error deleting attribute error');
            setTimeout(() => {
                handleCloseAlertModal();
            }, 3000);
        }
    };


    const handleDeleteAttribute = async (index) => {
        const attributeToDelete = editedAttributes[index];
        if (!attributeToDelete.isNewAttribute) {
            console.log("attr del", attributeToDelete);
            await deleteAttribute(attributeToDelete);
        }

        const newAttributes = [...editedAttributes];
        newAttributes.splice(index, 1);
        setEditedAttributes(newAttributes);
        setShowOptionsIndex(null);
    };


    const addAttributeRow = () => {
        setEditedAttributes([...editedAttributes, { AttributeName: '', Description: '', isNewAttribute: true }]);
    };


    const handleShowOptions = (index) => {
        setShowOptionsIndex(showOptionsIndex === index ? null : index);
        console.log("index", editedAttributes);
    };


    return (
        <div className='add-subuser rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'>
            <div className='flex items-center gap-2'>
                <div className='flex flex-col team-label'>
                    <div className="flex space-x-4">
                        <button className={`px-6 py-3 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Add SubCategory'); setMemberTab(0); }}>Edit SubCategory</button>
                    </div>
                    <h5 className='mt-4 font-semibold'>Edit Sub-Category</h5>
                    <div className='flex flex-row gap-2'>

                        <div className='flex flex-col gap-1 input-field mt-1'>
                            <label>Sub Category<span className='text-red-500'>*</span></label>
                            <input
                                type='text'
                                name='category'
                                value={editedCategory}
                                onChange={(e) => setEditedCategory(e.target.value)}
                                placeholder='Category'
                                className='invite-field custom-input py-2 px-2 text-left'
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-1 input-field mt-1'>
                            <label>Sub Category Description</label>
                            <input
                                type='text'
                                name='description'
                                value={editedDescription}
                                onChange={(e) => setEditedDescription(e.target.value)}
                                placeholder='Description'
                                className='invite-field custom-input py-2 px-2 text-left'
                                required
                            />
                        </div>
                    </div>

                    <div className="w-full mb-2 mt-3">
                        <h6 className="text-gray-600 font-bold">Attributes</h6>
                    </div>
                    {editedAttributes.length > 0 ? (
                        <>
                            <div className='flex flex-wrap w-full'>
                                <table className='attribute-table mt-2'>
                                    <thead>
                                        <tr>
                                            <th>Attribute Name</th>
                                            <th>Attribute Description</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {editedAttributes.map((attribute, index) => (
                                            <tr key={index} className='attribute-row text-xs'>
                                                <td>
                                                    <input
                                                        type='text'
                                                        value={attribute.AttributeName}
                                                        onChange={(e) => handleAttributeChange(index, 'AttributeName', e.target.value)}
                                                        placeholder='Attribute Name'
                                                        className='invite-field custom-input py-2 px-2 text-left'
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type='text'
                                                        value={attribute.Description}
                                                        onChange={(e) => handleAttributeChange(index, 'Description', e.target.value)}
                                                        placeholder='Attribute Description'
                                                        className='invite-field custom-input py-2 px-2 text-left'
                                                    />
                                                </td>
                                                <td>
                                                    <div className="more-options-wrapper">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleShowOptions(index)}
                                                            title="More"
                                                            className='btn-more-options'
                                                        >
                                                            <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                                                        </button>
                                                        {showOptionsIndex === index && (
                                                            <div className="more-options">
                                                                <Button variant='text' onClick={() => handleDeleteAttribute(index)}>
                                                                    <i className="fa-solid fa-trash not-0" aria-hidden="true"></i>
                                                                    <span>Delete</span>
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={addAttributeRow}>
                                    <i className="fa-solid fa-plus not-0" aria-hidden="true"></i> Add Attribute
                                </button>
                            </div>
                        </>
                    ) : (
                        <button type="button" className='btn-simple px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={addAttributeRow}>
                            <i className="fa-solid fa-plus not-0" aria-hidden="true"></i> Add Attribute
                        </button>
                    )}
                    <div className='mt-4 flex justify-end'>
                        <button
                            type="button"
                            className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                            onClick={() => setEditCategory(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                            onClick={handleEditCategory}
                        >
                            Update
                        </button>
                    </div>
                    <div className='mt-4 flex justify-end'>
                        {showAlertModal && (
                            <Notification
                                message={alertMessage}
                                notificationType={showOkButton ? 'Success' : 'Error'}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditSubCat;
