import React, { useEffect, useState, useRef } from "react";
import ReactFlow, { Handle, Position, useReactFlow, Controls } from "reactflow";
import * as XLSX from "xlsx";
import * as htmlToImage from "html-to-image";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "reactflow/dist/style.css";

const CustomNode = ({ data, id }) => {
  const specialNodeIds = ["0", "5"];
  const categoryNodeIds = [
    'nii',
    'noi',
    "aea_aa",
    "nim_te",
    "noi_aa",
    "noe_aa",
    "deposit_service_charges",
    "fiduciary_activities",
    "trading_revenue",
    "securities_bkr",
    "gains",
    "others",
    "personnel",
    "occupancy",
    "other_noe",
    "int_loans",
    "int_investment",
    "int_other",
    "deposits",
    "cost_ffp",
    "cost_other",
    "cost_debt",
    "yield_ib",
    "yield_ffs",
    "yield_foreign",
    "yield_ag",
    "yield_credit",
    "yield_0",
    "yield_ci",
    "yield_re",
    "yield_sec",
    "yield_mbs",
    "yield_ust",
    "transact_accts",
    "cost_of_other_svgs",
    "cost_of_time_deps",
    "cost_of_fod",
    "cost_of_other_borrow",
    "other_interest_expense",
  ];

  const isSpecialNode = specialNodeIds.includes(id);
  const isCategoryNode = categoryNodeIds.includes(id);

  const nodeStyle = {
    padding: isSpecialNode ? "5px" : "5px",
    borderRadius: "8px",
    boxShadow: isSpecialNode ? "none" : "0 4px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: isSpecialNode ? "transparent" : data.color,
    color: data.textColor || "black",
    minWidth: isSpecialNode ? "80px" : "120px",
    textAlign: "center",
    width: '140px',
    height: '60px'
  };

  const labelStyle = {
    fontSize: isSpecialNode ? "10px" : "10px",
    whiteSpace: "pre-line",
    textAlign: "center",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  const valueStyle = {
    fontWeight: "bold",
    fontSize: isSpecialNode ? "20px" : "14px",
    overflow: 'hidden'
  };

  const categoryContainerStyle = {
    backgroundColor: "#b7b7b7",
    padding: "5px",
  };

  return (
    <div style={isCategoryNode ? categoryContainerStyle : {}}>
      <div style={nodeStyle}>
        <Handle
          type="target"
          position={Position.Left}
          style={{ border: "none", background: "transparent" }}
        />
        <div style={labelStyle}>{data.label}</div>
        <div style={valueStyle}>{data.value}</div>
        <Handle
          type="source"
          position={Position.Right}
          style={{ border: "none", background: "transparent" }}
        />
      </div>
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const NewMindmap = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState("");
  const [data, setData] = useState([]);
  const reactFlowWrapper = useRef(null);
  const { fitView } = useReactFlow();

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      setData(apiData?.data);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  useEffect(() => {
    if (item) {
      try {
        const { data, nodes: nodesStr, edges: edgesStr } = item;

        // Fix the formatting of nodes and edges JSON strings
        const formatJsonString = (jsonStr) => {
          try {
            // Replace single quotes with double quotes and remove trailing commas
            return jsonStr
              .replace(/'/g, '"') // Replace single quotes with double quotes
              .replace(/,\s*([}\]])/g, "$1"); // Remove trailing commas before closing braces and brackets
          } catch (error) {
            console.error("Error formatting JSON string:", error);
            return jsonStr; // Return the original string if formatting fails
          }
        };

        const formattedNodesStr = formatJsonStringQuote(formatJsonString(nodesStr));
        const formattedEdgesStr = formatJsonStringQuote(formatJsonString(edgesStr));

        // Parse the corrected JSON strings
        const parsedNodes = JSON.parse(formattedNodesStr);
        const parsedEdges = JSON.parse(formattedEdgesStr);

        // Clear values of all nodes before setting new ones
        const clearedNodes = parsedNodes.map((node) => ({
          ...node,
          data: { ...node.data, value: null }, // Set value to null or empty to clear previous data
        }));

        // Map values from the data array to the corresponding nodes
        const values = data[0]; // Assuming data is an array with one object

        // Update nodes with new values
        const updatedNodes = clearedNodes.map((node) => {
          // Match node id with the keys in values
          const value = values[node.id];
          if (value !== undefined && value !== null) {
            node.data.value = value;
          }
          return node;
        });

        setNodes(updatedNodes);
        setEdges(parsedEdges);
      } catch (error) {
        console.error("Error processing mind map data:", error);
      }
    }
  }, [item]);


  function formatJsonStringQuote(jsonString) {
    jsonString = jsonString.replace(/'/g, '"');
    jsonString = jsonString.replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3');

    return jsonString;
  }

  useEffect(() => {
    if (reactFlowWrapper.current) {
      fitView({ padding: 0.2 });
    }
  }, [fitView, nodes, edges, apiData]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const handleFitView = () => {
    // Fit the view with custom options
    fitView({
      padding: 0.2, // 20% padding around the graph
      minZoom: 0.3, // Minimum zoom level
      maxZoom: 1, // Maximum zoom level
      duration: 500, // Animation duration of 500ms
    });
  };

  const optionsExport = ["export", "image"];

  const UpdateType = (menu) => {
    if (menu === "export") {
      // export to excel functionlity
      exportToExcel("Export", data);
    } else if (menu === "image") {
      // Download as Image
      OnDownloadClick("react-flow__pane");
    }
  };

  // method - download chart as an Image (.png)
  const OnDownloadClick = async (tagName) => {
    await htmlToImage
      .toPng(document.getElementsByClassName(tagName)[0])
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = "Chart";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        // nothing
      });
  };

  const exportToExcel = (filename, data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const date = new Date();

    const formattedFileName = `${filename}_${date.getMonth() + 1
      }_${date.getDate()}_${date.getFullYear()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${formattedFileName}.xlsx`);
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title d-flex flex justify-between items-center">{item?.title}
              {currPage === "canvas" && optionsExport?.length && (
                <div>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Body>
                          {optionsExport?.map((menu, index) => (
                            <div
                              key={menu}
                              className={`${optionsExport.length !== index + 1
                                  ? "border-bottom chart-type-options"
                                  : ""
                                }`}
                            >
                              <button
                                className="pb-1 pt-1"
                                onClick={() =>
                                  UpdateType(menu?.trim()?.toLowerCase())
                                }
                                style={{
                                  textTransform: "capitalize",
                                  border: "none",
                                  backgroundColor: "transparent",
                                }}
                                key={menu}
                              >
                                {menu}
                              </button>
                            </div>
                          ))}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span
                      className="pe-2"
                      title="Filters"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fa-solid fa-ellipsis-vertical not-0"
                        style={{
                          color: "rgba(9, 8, 38, 0.6)",
                          fontSize: "20px",
                          marginBottom: "13px",
                        }}
                      ></i>
                      {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                    </span>
                  </OverlayTrigger>
                </div>
              )}
            </div>
          ) : (
            <div className="no-chart-title d-flex flex justify-between items-center">
              {currPage === "canvas" && optionsExport?.length && (
                <div>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Body>
                          {optionsExport?.map((menu, index) => (
                            <div
                              key={menu}
                              className={`${optionsExport.length !== index + 1
                                  ? "border-bottom chart-type-options"
                                  : ""
                                }`}
                            >
                              <button
                                className="pb-1 pt-1"
                                onClick={() =>
                                  UpdateType(menu?.trim()?.toLowerCase())
                                }
                                style={{
                                  textTransform: "capitalize",
                                  border: "none",
                                  backgroundColor: "transparent",
                                }}
                                key={menu}
                              >
                                {menu}
                              </button>
                            </div>
                          ))}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span
                      className="pe-2"
                      title="Filters"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fa-solid fa-ellipsis-vertical not-0"
                        style={{
                          color: "rgba(9, 8, 38, 0.6)",
                          fontSize: "20px",
                          marginBottom: "13px",
                        }}
                      ></i>
                      {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                    </span>
                  </OverlayTrigger>
                </div>
              )}
            </div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <>
          <div
            style={{ width: "100%", height: "50vh", overflow: "hidden" }}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              fitView
              zoomOnScroll={false}
              panOnScroll={false}
              //panOnDrag={false}
              proOptions={{ hideAttribution: true }}
            >
              <Controls showInteractive={false}>
                {/* Custom "Fit View" Button */}
                <button onClick={handleFitView} className="react-flow__controls-button" title="fit view">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 30"><path d="M3.692 4.63c0-.53.4-.938.939-.938h5.215V0H4.708C2.13 0 0 2.054 0 4.63v5.216h3.692V4.631zM27.354 0h-5.2v3.692h5.17c.53 0 .984.4.984.939v5.215H32V4.631A4.624 4.624 0 0027.354 0zm.954 24.83c0 .532-.4.94-.939.94h-5.215v3.768h5.215c2.577 0 4.631-2.13 4.631-4.707v-5.139h-3.692v5.139zm-23.677.94c-.531 0-.939-.4-.939-.94v-5.138H0v5.139c0 2.577 2.13 4.707 4.708 4.707h5.138V25.77H4.631z"></path></svg>
                </button>
              </Controls>
            </ReactFlow>
          </div>
          <div
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              marginTop: "10px", // Adds spacing from the ReactFlow container
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              fontSize: "14px",
              textAlign: `${item?.legend_align}`,
              color: "#666"
            }}
            dangerouslySetInnerHTML={{ __html: item?.legend}}
          >
          </div>
        </>
      )}{" "}
    </>
  );
};

export default NewMindmap;
