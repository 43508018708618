import React, {useState, useEffect} from 'react';
import UserService from "../services/UserService";
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './Signup.css';

const serverConfig = require("../config/server.js");

const Signup = () => {

  //const [inputs, setInputs] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [errorText, setErrorText] = useState('');
  const [signupDone, setSignupDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleNameChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value, 'Name');
    setName(value)
  }

  const handleEmailChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value, 'Email');
    setEmail(value)
  }

  const validateEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      return { isValid: false, error: 'Email is required' };
    } else if (!emailRegex.test(email)) {
      return { isValid: false, error: 'Enter a valid email' };
    }
    return { isValid: true, error: '' };
  };
  
  const validateName = () => {
    if (name === '') {
      return { isValid: false, error: 'Name is required' };
    }
    return { isValid: true, error: '' };
  };

  const handleSignup = (event) => {
  const emailValidation = validateEmail();
  const nameValidation = validateName();

  setEmailError(emailValidation.error);
  setNameError(nameValidation.error);
    if(emailValidation.isValid && nameValidation.isValid) {
      setLoading(true);
     event.preventDefault();
    axios.post(serverConfig.api_base_url + "user_signup",
      {
        name:name,
        email_id: email,
        url: window.location.host
      })
    .then(response=>{
      console.log("user_signup response", response);
      if (response.data.status === 200) {
        setLoading(false);
        setSignupDone(true);
        //setErrorText('Invitation sent. Please check your email.')
        console.log(response.data);
        setName('');
        setEmail('');
      }
      if(response.data.message !== 'Success') {
      setLoading(false);
      setEmailError(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  }

  useEffect(()=>{

    //window.history.replaceState( {} , '', '/' );

  },[])

  return (

    <>
    <div className='login'>
      <div className='login-container'>

        <div className='login-left'>

        <div class="logo"><img src={require("../Asset/Images/logo.png")} alt="Logo" /></div>

        <div class="content">
                    <h2>Welcome!</h2>
                    <p>Secure your enterprise, empower your people, redefine standards</p>
        </div>

        </div>

        {signupDone === false ? 
        <div className='form-container'>

        <div className='form-wrapper'>
        <header>
          <h1 id="kc-page-title">        
          Sign Up
          </h1>
          <div className='flex flex-row'>
          <div className='font-semibold'>Already Registered?</div> <button className='login-btn' onClick={() => navigate("/")}>LOG IN</button>
          </div>
        </header>

        {errorText !== '' ? <p className="error-display">{errorText}</p> : <></>}

        <div className="kc-content">
                        <div className="kc-content-wrapper">

    <div className="kc-form">
      <div>
             <div>
                <div className='flex flex-col'>
                    <label for="username" className="mb-2 font-semibold" >Name</label>

                        <input className={nameError ? 'error-validaion': ''} placeholder="Enter your Name" value={name} type="text" onChange={handleNameChange} />

                </div>
                {nameError !== '' ? <p className="error-text-msg">{nameError}</p> : <></>}

                <div className='flex flex-col'>
                    <label for="email" className="mb-2 font-semibold" >Email ID</label>

                    <input className={emailError ? 'error-validaion': ''} placeholder="Enter your Email" type="text" value={email} onChange={handleEmailChange} />
                </div>
                {emailError !== '' ? <p className="error-text-msg">{emailError}</p> : <></>}
                   

                <div className={`kc-form-buttons mt-4 mb-4 ${loading ? 'disabled' : ''}`} style={{ cursor: loading ? "not-allowed" : "pointer" }} onClick={!loading ? handleSignup : null}>
      <input type="hidden" />
      <input tabIndex="4" className="kc-form-buttons" type="submit" value="Sign Up" disabled={loading} style={{ display: 'none' }} />
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        Sign Up
        {loading && <div className="signup-loader" style={{ marginLeft: '15px' }}></div>}
      </span>
    </div>

                  {/* <div className="kc-form-buttons" style={{cursor: "pointer"}} className="mt-4 mb-4" onClick={handleSignup}>
                      <input type="hidden" />
                      <input tabindex="4" className="kc-form-buttons" type="submit" value="Sign Up" />
                  </div> */}
            </div> 
        </div>


    </div>


                        </div>
                    </div>

                    <div id="disclaimer">This computer network belongs to DBTEZ Technologies Private Limited and may be used only by DBTEZ's employees and Customers only for work-related purposes. DBTEZ reserves the right to monitor use of this network to ensure network security and to respond to specific allegations of user misuse. Use of this network shall constitute consent to monitoring for such purposes</div>


        </div>

        </div> : 
          <div className='form-container'>

          <div className='form-wrapper'>
         <div className='loading-div flex flex-col'>
              <img src={require(`./assets/uploadSuccess.png`)} alt='' className='loading-img' style={{marginTop: "0px !important"}} />
              <h1 className='invite-text'>Thank you for signing up! An invitation has been sent to your email. Please check your inbox and follow the instructions to complete the signup process. If you don’t see the email, be sure to check your spam or junk folder.</h1>
         </div>
       </div>
       </div> }

      </div>
    </div> 
    

    </>
  
  );
}

export default Signup;