import React, { useState, useEffect } from 'react';
import Document from '../../Asset/Images/Document.svg'
import { array } from 'prop-types';
const FileUploader = ({setFormData, formData}) => {
  const [files, setFiles] = useState(formData.attachments ? formData.attachments : []);
 

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    console.log(e.target.files.length);
    /* const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFiles([...files, {name:selectedFile.name, size:selectedFile.size, blob: URL.createObjectURL(selectedFile)}]);
      //setFormData({...formData, attachments:[...files, {name:selectedFile.name, size:selectedFile.size, blob: URL.createObjectURL(selectedFile)}]});
      e.target.value = null; // Clear the input field to allow reselection of the same file
    } */
    let filesArr = e.target.files;
    if (filesArr.length > 0) {
      let arr = [];
      for (var i = 0; i < filesArr.length; i++){
        arr.push({name:filesArr[i].name, size:filesArr[i].size, blob: URL.createObjectURL(filesArr[i])});
      }
      console.log(arr);
      console.log(files);
      console.log([...files, ...arr]);
      setFiles([...files, ...arr]);
      e.target.value = null; // Clear the input field to allow reselection of the same file
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  
  const getFileExtension = (fileName) => {
    // Split the file name into an array using the dot as a separator
    const parts = fileName.split('.');
  
    // Get the last part of the array, which should be the file extension
    const extension = parts[parts.length - 1];
  
    return extension;
  };

  useEffect(() => {
    console.log(files);
    setFormData({...formData, attachments:files});
  }, [files]);

  return (
    <div>
      <div className='flex items-center justify-start'>
        <label>Attachments</label>
        <label htmlFor="file-input" className="cursor-pointer">
          <div className="font-medium inline-block text-gray-500 text-lg mx-2">
            <i className="fa-solid fa-plus font-normal"></i>
          </div>
          <input
            type="file"
            id="file-input"
            accept=".pdf, .doc, .docx, .txt, .jpg, .png, .gif"
            onChange={handleFileChange}
            className="hidden"
            multiple
          />
        </label>
      </div>


      <div className="flex flex-wrap ">
        {files.length > 0 && files.map((file, index) => (
          <div key={index} className="p-4 uploadedFile mt-2 mr-2 rounded-2xl">
            <button type='button' className="btn-remove" title="Remove" onClick={() => setFiles(files.filter(el => el.name !== file.name))}><i className="fa-solid fa-xmark "></i></button>
            <div className='flex justify-between items-center'>
              <div className='relative mr-4'>
                <img src={Document} alt='document' className='document-svg' />

                <div className={getFileExtension(file.name) === 'pdf' ? 'pdf w-full text-xs absolute top-2' : getFileExtension(file.name) === 'png' ? 'png w-full absolute text-xs top-2' : getFileExtension(file.name) === 'SVG' ? 'svg w-full absolute text-xs top-2' : 'default w-full absolute text-xs top-2'}>
                  <div className='uppercase'>{getFileExtension(file.name)}</div>
                </div>
              </div>
              <div className='mr-4'>
                <div className="filename line-clamp-1 w-20">{file.name}</div>
                <div className="fileDetail">{formatBytes(file.size)}</div>
              </div>
              <div>
                <a href={file.blob} className="text-blue-500 hover:underline" download={file.name}>
                  <i class="fa-solid fa-download icon-color"></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploader;
