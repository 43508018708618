import React, { useState, useContext } from 'react';
import { Context } from '../contextApi/context';
import MyResTable from './tables/myResTable';

const MyResource = () => {

  const { collapse, tab, taskPopup } = useContext(Context)
  const [newGroupPopup, setNewGroupPopup] = useState(false)
  console.log("In my-resource page")
  return (
    <>
      <div>
        {/* <HeaderSecond linkName={'New Group'} link={'#'} popup={newGroupPopup} setPopup={setNewGroupPopup}/> */}
        {/* {newGroupPopup &&<div className='overlay'> <NewGroupPopup setNewGroupPopup={setNewGroupPopup}/></div>} */}
        <div className={collapse ? 'content-body' : 'content-body-collapse'} >
          <div class="container-fluid">
            <MyResTable />
          </div>

        </div>
      </div>
    </>
  );
}

export default MyResource;