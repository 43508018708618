import React, { useEffect, useState } from "react";
import axios from "axios";
import UserService from '../../services/UserService';

const serverConfig = require("../../config/server.js");
const DeleteInfo = ({setDeleteInfoRow, importId, modalClosed, setModalClosed}) => {


    const [deleteReason, setDeleteReason] = useState('');
    const [date, setDate] = useState('');
    const [reason, setReason] = useState('');
    const [deleted, setDeleted] = useState('');

    //   const getToken = () => {
    
    
    //     console.log(sessionStorage.getItem('token'));
    //     let jwt = UserService.getToken();
    //     axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    //     .then(response=>{
    //       console.log('user_get_token response', response);
    //       if(response.data.status === 200){
    //         sessionStorage.setItem('token', response.data.token);
    //         setCToken(response.data.token);
    //       } else if(response.data.jwt_validation === 'fail') {
    //         handleLogout();
    //       }
    //     })
    //     .catch(err=>console.log("error is",err));
    //   }
      
    //   useEffect(() => {
    //     console.log(sessionStorage.getItem('token'));
    //     if(sessionStorage.getItem('token') === null){
    //       getToken();
    //     } else {
    //       setCToken(sessionStorage.getItem('token'));
    //     }
    //   }, []);


    useEffect(() => {

        handleDeleteInfo();

    },[]);


    const handleDeleteInfo = () => {
        axios.post(serverConfig.cstar_api + "import_delete_status", {

            token: "JJVOGquC1FGQD1DyifLvD6Z4NbZPyHiOAUbFae6fklbgcBRUoCnqpb7vxDZrEldubSeZFLfpFzeS7nWYv26Pf9q5Batjv13CkdwlDw9C3RyzDTYBwe1RBRXByoKVRVu5",
            importId: importId,

        }).then(response=> {
            console.log("import_delete_info_response", response);
            if(response.status === 200) {
                const res = response.data.items;
                console.log(JSON.parse(res));
                const info = JSON.parse(res);
                console.log(info[0], 'delete_reason');
                setDate(info[0].date);
                setReason(info[0].reason);
                setDeleted(info[0].deleted);
            }
        }).catch(err=>{
            console.log(err);
        })
    }


    return(
        <>
        <div className='delete-user  rounded-3xl py-4 px-9 relative  mx-28  my-5  text-custom text-sm flex justify-between flex-col'>
        <div className='overflow-y-auto mt-2'>
        <div className='flex flex-col mb-4'>

            <div className="dlt-heading mb-6">Delete Info</div>

            <div className="info-header mb-1">
                Deleted by
            </div>

            <div className="flex flex-row mb-6">
                <img src={require("../assets/userprofile.png")} className="profile-dlt-img mr-4" />
                <div>{deleted}</div>
            </div>

            <div className="info-header mb-1">
                Date
            </div>
            <div className="mb-6">
                {date}
            </div>

            <div className="info-header mb-1">
                Reason
            </div>
            <div className="mb-2">
                {reason}
            </div>
            

        </div>

        </div>
        <button className='absolute right-6 top-4' onClick={()=>{setDeleteInfoRow(''); setModalClosed(!modalClosed)}}>
        <i class="fa-solid fa-xmark"></i>
        </button>
        </div>        
        </>
    )
}

export default DeleteInfo;