import React, { useContext } from "react";
import { Context } from "../contextApi/context";
import TemplateWizardManagement from "./tables/TemplateWizardManagement";

const TemplateWizard = () => {
  const { collapse } = useContext(Context);

  return (
    <>
      <div>
        <div className={collapse ? "content-body" : "content-body-collapse"}>
          <div class="container-fluid">
            <TemplateWizardManagement />
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateWizard;
