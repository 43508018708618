import React, { useState } from 'react';

const DropdownTwo = ({ options, setSelectedOption, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    console.log(option, 'selected access');
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full">
      <button
        className="rounded-xl border-2 dropdown
         px-2 py-1 w-full  text-left dropdown-access"
        onClick={toggleDropdown}
      >
<div className='flex justify-between items-center'>
    <div className='text-sm
    '>
    {selectedOption?.label || <div className='text-gray-400 text-sm '>Select an option</div>}
    </div>
    <div>
    <i class="fa-solid fa-chevron-down text-gray-400"></i>
    </div>
</div>
       

      </button>

      {isOpen && (
        <div className=" top-12 right-0 rounded-lg border-1 bg-custom dropdown-menu w-full">
          <ul>
            {
              options?.length <=0 ?
              
              <li
                onClick={() => {}}
                className="py-2 text-sm px-4 cursor-pointer dropdown-options m-2 mx-1 text-custom hover:rounded-lg  hover:bg-slate-100"
              >
                No options to display
              </li>
              
              :
            options.map((option, index) => (
              <li
                key={index}
                onClick={() => selectOption(option)}
                className="py-2 text-sm px-4 cursor-pointer dropdown-options m-2 mx-1 text-custom hover:rounded-lg  hover:bg-slate-100"
              >
                {option.label}
              </li>
            ))
            }
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownTwo;
