import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { Context } from '../../contextApi/context.js';
import '../css/cat.css';
import Notification from '../../common/notification/Notification.js';

const serverConfig = require('../../config/server.js');

function EditRes({ setEditResource, rowToEdit, onEditSuccess, dynamicColumns, catSlug }) {
    const { selectedOrganization } = useContext(Context);
    const [cToken, setCToken] = useState(sessionStorage.getItem('token'));

    const [editedValues, setEditedValues] = useState({ resource_description: rowToEdit.resource_description });
    const [editedName, setEditedName] = useState(rowToEdit.resource_name);
    const [editedDescription, setEditedDescription] = useState(rowToEdit.resource_description);
    const [isDescriptionEdited, setIsDescriptionEdited] = useState(false);
    const [modifiedColumns, setModifiedColumns] = useState([]);

    const [tab, setTab] = useState('Edit Resource');
    const [memberTab, setMemberTab] = useState(0)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    //const [attributeToDelete, setAttributeToDelete] = useState('');

    useEffect(() => {
        //console.log("Alerrttt",alertMessage);
        if (alertMessage !== '') {
            setShowAlertModal(true);
        } else {
            setShowAlertModal(false);
        }
    }, [alertMessage]);

    const handleCloseAlertModal = () => {
        //console.log("closeeee modal")
        setAlertMessage('');
        setShowOkButton(false);
        setShowAlertModal(false);
    };

    const getToken = () => {
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + 'user_get_token', { jwt: jwt })
            .then(response => {
                console.log('user_get_token response', response);
                if (response.data.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    setCToken(response.data.token);
                } else if (response.data.jwt_validation === 'fail') {
                    handleLogout();
                }
            })
            .catch(err => console.log('error is', err));
    }

    const handleDescriptionChange = (e) => {
        setEditedDescription(e.target.value);
        setIsDescriptionEdited(true);
    };

    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            getToken();
        }
        // console.log("rowtoedit", rowToEdit);
    }, []);


    const handleEditResource = () => {
        if (editedName !== rowToEdit.resource_name || (editedDescription !== rowToEdit.resource_description && isDescriptionEdited)) {
            const resourceUpdatePayload = {
                token: cToken,
                org: selectedOrganization?.slug,
                res: rowToEdit.resource_slug,
                new_description: editedDescription, // Use edited description
                new_name: editedName,
            };

            axios.post(serverConfig.api_base_url + 'resource_update', resourceUpdatePayload)
                .then(resourceUpdateResponse => {
                    if (resourceUpdateResponse.data.status === 200) {
                        //showMessage('Resource updated successfully', 'success');
                        setAlertMessage('Resource updated successfully');
                        setShowOkButton(true);
                        setTimeout(() => {
                            handleCloseAlertModal();
                            onEditSuccess();
                        }, 500);
                    } else if (resourceUpdateResponse.data.status === 301) {
                        handleLogout();
                    } else {
                        setAlertMessage(resourceUpdateResponse.data.message);
                        setTimeout(() => {
                            handleCloseAlertModal();
                        }, 3000);
                    }
                })
                .catch(resourceUpdateError => {
                    console.log('Error updating resource:', resourceUpdateError);
                    setAlertMessage('An error occurred while updating the resource');
                    setTimeout(() => {
                        handleCloseAlertModal();
                    }, 3000);
                });
        }

        dynamicColumns.forEach(column => {
            const initialValue = rowToEdit.attributes.find(attr => attr.attribute_name === column.name)?.value;
            const editedValue = editedValues[column.selector];

            if (editedValue !== null && editedValue !== undefined && editedValue !== initialValue) {
                const attributeUpdatePayload = {
                    token: cToken,
                    org: selectedOrganization?.slug,
                    res: rowToEdit.resource_slug,
                    attribute_name: column.name,
                    new_value: editedValue,
                };

                axios.post(serverConfig.api_base_url + 'resource_attribute_update', attributeUpdatePayload)
                    .then(response => {
                        if (response.data.status === 200) {
                            setAlertMessage('Attribute updated successfully');
                            setShowOkButton(true);
                            setTimeout(() => {
                                handleCloseAlertModal();
                                onEditSuccess();
                            }, 500);
                            console.log(`Attribute "${column.name}" updated successfully`);
                            setModifiedColumns(prevColumns => [...prevColumns, column.name]);
                        } else if (response.data.status === 301) {
                            handleLogout();
                        } else {
                            setAlertMessage('Error updating attribute');
                            setTimeout(() => {
                                handleCloseAlertModal();
                            }, 3000);
                        }
                    })
                    .catch(err => {
                        console.log(`Error updating attribute "${column.name}":`, err);
                        setAlertMessage('An error occurred while updating attribute');
                        setTimeout(() => {
                            handleCloseAlertModal();
                        }, 3000);
                    });
            }
        });



    };


    return (

        <div className='edit-res rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'>
            <div className='flex-grow overflow-y-auto'>
                <div className="flex space-x-4">
                    <button className={`px-6 py-3 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Edit Resource'); setMemberTab(0); }}>Edit Resource</button>
                </div>
                <div className='flex justify-between items-center gap-5 mt-2'>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>Edit Attributes of {rowToEdit.resource_description}</div>
                </div>
                <div className='mt-4 flex flex-wrap'>
                    <div key='resource_name' className="mb-4 w-full pr-4 flex items-center">
                        <div className="flex-1">
                            <label className="text-gray-600" style={{ fontWeight: 'bold' }}>Name </label>
                            <input
                                //style={{ width: '100%' }}
                                type="text"
                                value={editedName}
                                onChange={(e) => setEditedName(e.target.value)}
                                className="border border-gray-300 rounded-md p-2 w-full mt-1"
                            />
                        </div>
                    </div>
                    <div key='resource_description' className="mb-4 w-full pr-4 flex items-center">
                        <div className="flex-1">
                            <label className="text-gray-600" style={{ fontWeight: 'bold' }}>Description</label>
                            <input
                                //style={{ width: '100%' }}
                                type="text"
                                value={editedDescription}
                                onChange={handleDescriptionChange}
                                className="border border-gray-300 rounded-md p-2 w-full mt-1"
                            />
                        </div>
                    </div>
                    {dynamicColumns.map((column) => {
                        const initialValue = rowToEdit.attributes.find(attr => attr.attribute_name === column.name)?.value;
                        const editedValue = editedValues[column.selector] !== undefined ? editedValues[column.selector] : initialValue;

                        return (
                            <div key={column.name} className="mb-4 w-1/3 pr-4 flex items-center">
                                <div className="flex-1">
                                    <label className="text-gray-600" style={{ fontWeight: 'bold' }}>{column.name}</label>
                                    <input
                                        style={{ width: '100%' }}
                                        type="text"
                                        value={editedValue || ''}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            setEditedValues(prevValues => ({
                                                ...prevValues,
                                                [column.selector]: newValue,
                                            }));
                                        }}
                                        className="border border-gray-300 rounded-md p-2 w-full mt-1"
                                    />
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
            <div className='mt-4 flex justify-end'>
                <button
                    type="button"
                    className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                    onClick={() => setEditResource(false)}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'
                    onClick={handleEditResource}
                >
                    Update
                </button>
            </div>
            <div className='mt-4 flex justify-end'>
                {showAlertModal && (
                    <Notification
                        message={alertMessage}
                        notificationType={showOkButton ? 'Success' : 'Error'}
                    />
                )}
            </div>
        </div>
    );
}

export default EditRes;
