import React, { useState } from "react";
import * as FaIcons from 'react-icons/fa';
import './../../css/settings.css';

const IconPicker = ({onSelect, setIsPickerOpen, setErrorMessage}) =>{

    const [selectedIcon, setSelectedIcon] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const iconList = Object.keys(FaIcons);

    const handleIconClick = (icon) => {
        setSelectedIcon(icon);
        onSelect(icon);
        setIsPickerOpen(false);
        setErrorMessage('');
    };

    const filteredIcons = iconList.filter((iconKey) =>
    iconKey.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
     <div className="icon-picker">
         <input
        type="text"
        className="searching"
        placeholder="Search icons"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="icon-picker-container">
      {filteredIcons.map((iconKey) => {
        const IconComponent = FaIcons[iconKey];
        return (
          <div
            key={iconKey}
            className={`icon-item ${selectedIcon === iconKey ? 'selected' : ''}`}
            onClick={() => handleIconClick(iconKey)}
          >
            <IconComponent />
          </div>
        );
      })}
      </div>
    </div> 
        </>
    )
};

export default IconPicker;