import React, { useEffect, useState, useContext } from "react";
import { Context } from "../contextApi/context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";
import UserService from "./../services/UserService";
import Notification from "../common/notification/Notification.js";
import AddTimePopup from "./timesheetPopup/AddTimePopup.js";
import EditTimePopup from "./timesheetPopup/EditTimePopup.js";
import Daily from "./timesheet/Daily.js";
import Filters from "./timesheet/Filters.js";
import Weekly from "./timesheet/Weekly.js";
import CalendarView from "./timesheet/Calendar.js";
import SplitTimePopup from "./timesheetPopup/SpliTimePopup.js";

const serverConfig = require("./../config/server.js");

function Timesheet() {
  const { collapse } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState("Daily");
  const [tableData, setTableData] = useState([]);
  const [addTime, setAddTime] = useState(false);
  const [editTime, setEditTime] = useState(false);
  const [splitTime, setSplitTime] = useState(false);
  const [newGroupPopup, setNewGroupPopup] = useState(false);
  const [timeLog, setTimeLog] = useState([]);
  const [timeLogWeekly, setTimeLogWeekly] = useState([]);
  const [timeLogWeeklySummary, setTimeLogWeeklySummary] = useState([]);
  const [timeLogCalendar, setTimeLogCalendar] = useState([]);
  const [timeLogDownloadable, setTimeLogDownloadable] = useState([]);
  const [refreshTimesheet, setRefreshTimesheet] = useState(false);
  const [viewMemberTimesheetPrivileged, setViewMemberTimesheetPrivileged] =
    useState(false);

  const { selectedOrganization } = useContext(Context);
  const [token, setToken] = useState(null);
  const [memberSlugArr, setMemberSlugArr] = useState([]);
  const [filterData, setFilterData] = useState({});

  const [weeklySelectedStartDate, setWeeklySelectedStartDate] = useState(null);
  const [weeklySelectedEndDate, setWeeklySelectedEndDate] = useState(null);
  const [calendarSelectedStartDate, setCalendarSelectedStartDate] =
    useState(null);
  const [calendarSelectedEndDate, setCalendarSelectedEndDate] = useState(null);

  useEffect(() => {
    // Function to get the start and end dates of the current week
    const getCurrentWeekDates = () => {
      const today = new Date();
      const startOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      const endOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay() + 6)
      );
      return { startOfWeek, endOfWeek };
    };

    // Initialize the state with the start and end dates of the current week
    const { startOfWeek, endOfWeek } = getCurrentWeekDates();
    setWeeklySelectedStartDate(startOfWeek);
    setWeeklySelectedEndDate(endOfWeek);
    setCalendarSelectedStartDate(startOfWeek);
    setCalendarSelectedEndDate(endOfWeek);
  }, []);

  // Function to handle date change in the weekly tab
  const handleWeeklyTabDateChange = (date) => {
    const { startOfWeek, endOfWeek } = getWeekRange(date);
    setWeeklySelectedStartDate(startOfWeek);
    setWeeklySelectedEndDate(endOfWeek);
  };

  // Function to handle week click in the weekly tab
  const handleWeeklyTabWeekClick = (date) => {
    const { startOfWeek, endOfWeek } = getWeekRange(date);
    setWeeklySelectedStartDate(startOfWeek);
    setWeeklySelectedEndDate(endOfWeek);
  };

  useEffect(() => {
    if(tab === "Calendar"){
      const getCurrentWeekDates = () => {
        const today = new Date();
        const startOfWeek = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        const endOfWeek = new Date(
          today.setDate(today.getDate() - today.getDay() + 6)
        );
        return { startOfWeek, endOfWeek };
      };

      const { startOfWeek, endOfWeek } = getCurrentWeekDates();
      setCalendarSelectedStartDate(startOfWeek);
      setCalendarSelectedEndDate(endOfWeek);

      if (endOfWeek !== null) {
        setIsLoading(true);

        let start_date = new Date(startOfWeek).toISOString().split("T")[0];
        let end_date = new Date(endOfWeek).toISOString().split("T")[0];
        console.log(start_date, end_date);

        console.log(filterData); // filtered data coming from filters - if filters are set
        let groupSlugArr = filterData.group_input ? filterData.group_input.map(el => el.value) : [];
        console.log('groupSlugArr', groupSlugArr);

        let memberSlugArr1;
        if(memberSlugArr.length > 0){
          memberSlugArr1 = memberSlugArr;
        } else {
          memberSlugArr1 = filterData.member_input ? filterData.member_input.map(el => el.value) : [];
        }
        console.log('memberSlugArr', memberSlugArr1);

        axios
          .post(serverConfig.api_base_url + "time_log_get", {
            token: token,
            org: selectedOrganization?.slug,
            grp: groupSlugArr.length > 0 ? groupSlugArr : undefined,
            timeType: filterData.time_type,
            members: memberSlugArr1.length > 0 ? memberSlugArr1 : undefined,
            startDate: start_date,
            endDate: end_date,
          })
          .then((response) => {
            console.log("time_log_get response", response);
            if (response.data.status === 200) {
              if (response.data.items !== null) {
                console.log(response.data.items);
                setTimeLogCalendar(response.data.items);
              } else {
                setTimeLogCalendar([]);
              }
              setIsLoading(false);
            } else if (response.data.status === 301) {
              handleLogout();
            } else {
              handleShowAlertModal(response.data.message, true);
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
              setIsLoading(false);
            }
          })
          .catch((err) => console.log("error is", err));
      }
    }
  }, [tab, selectedOrganization]);

  // Function to handle date change in the calendar tab
  const handleCalendarTabDateChange = (date) => {
    const { startOfWeek, endOfWeek } = getWeekRange(date);
    setCalendarSelectedStartDate(startOfWeek);
    setCalendarSelectedEndDate(endOfWeek);

    if (endOfWeek !== null) {
      setIsLoading(true);

      let start_date = new Date(new Date(startOfWeek).toUTCString())
        .toISOString()
        .split("T")[0];
      let end_date = new Date(new Date(endOfWeek).toUTCString())
        .toISOString()
        .split("T")[0];
      console.log(start_date, end_date);
      
      console.log(filterData); // filtered data coming from filters - if filters are set
      let groupSlugArr = filterData.group_input ? filterData.group_input.map(el => el.value) : [];
      console.log('groupSlugArr', groupSlugArr);
      let memberSlugArr = filterData.member_input ? filterData.member_input.map(el => el.value) : [];
      console.log('memberSlugArr', memberSlugArr);

      axios
        .post(serverConfig.api_base_url + "time_log_get", {
          token: token,
          org: selectedOrganization?.slug,
          grp: groupSlugArr.length > 0 ? groupSlugArr : undefined,
          timeType: filterData.time_type,
          members: memberSlugArr.length > 0 ? memberSlugArr : undefined,
          startDate: start_date,
          endDate: end_date,
        })
        .then((response) => {
          console.log("time_log_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              console.log(response.data.items);
              setTimeLogCalendar(response.data.items);
            } else {
              setTimeLogCalendar([]);
            }
            setIsLoading(false);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  };

  // Function to handle week click in the calendar tab
  const handleCalendarTabWeekClick = (date) => {
    const { startOfWeek, endOfWeek } = getWeekRange(date);
    setCalendarSelectedStartDate(startOfWeek);
    setCalendarSelectedEndDate(endOfWeek);
  };

  // Function to get the start and end dates of the week based on a given date
  const getWeekRange = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    const endOfWeek = new Date(date);
    endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 6);
    return { startOfWeek, endOfWeek };
  };

  console.log("weeklySelectedStartDate : ", weeklySelectedStartDate);
  console.log("weeklySelectedEndDate : ", weeklySelectedEndDate);
  console.log("calendarSelectedStartDate : ", calendarSelectedStartDate);
  console.log("calendarSelectedEndDate : ", calendarSelectedEndDate);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const pickDateRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (end !== null) {
      setIsLoading(true);

      let start_date = new Date(new Date(start).toUTCString())
        .toISOString()
        .split("T")[0];
      let end_date = new Date(new Date(end).toUTCString())
        .toISOString()
        .split("T")[0];
      console.log(start_date, end_date);

      console.log(filterData);
      let groupSlugArr = filterData.group_input ? filterData.group_input.map(el => el.value) : [];
      console.log('groupSlugArr', groupSlugArr);
      let memberSlugArr = filterData.member_input ? filterData.member_input.map(el => el.value) : [];
      console.log('memberSlugArr', memberSlugArr);

      axios
        .post(serverConfig.api_base_url + "time_log_get", {
          token: token,
          org: selectedOrganization?.slug, grp: groupSlugArr.length > 0 ? groupSlugArr : undefined, timeType: filterData.time_type, members: memberSlugArr.length > 0 ? memberSlugArr : undefined,
          startDate: start_date,
          endDate: end_date,
        })
        .then((response) => {
          console.log("time_log_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              console.log(response.data.items);
              setTimeLog(response.data.items);
            } else {
              setTimeLog([]);
            }
            setIsLoading(false);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log("error is", err));
    }
    //{startDate < endDate ? 'less' : 'greater'}
    //console.log(is_before_date(new Date(startDate), new Date(endDate)));
  };
  const is_before_date = (date1, date2) => date1 < date2;

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", { jwt: jwt, url:window.location.host })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);

      //reset filters
      setStartDate(new Date());
      setEndDate(new Date());
      setFilterData({});

      axios
        .post(serverConfig.api_base_url + "user_check_privilege", {
          token: token,
          org: selectedOrganization?.slug,
          privilege: "view_org_members_timesheet",
        })
        .then((response) => {
          console.log("user_check_privilege view_org_members_timesheet response", response);
          if (response.data.status === 200) {
            if (response.data.is_privileged === 0) {
              setViewMemberTimesheetPrivileged(false);
            } else if (response.data.is_privileged === 1) {
              setViewMemberTimesheetPrivileged(true);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      /* loggedin_user_details_get */
      axios.post(serverConfig.api_base_url + "loggedin_user_details_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("loggedin_user_details_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(items);
            console.log(items[0].slug);
            console.log([items[0].slug]);
            setMemberSlugArr([items[0].slug]);
          } else {
            setMemberSlugArr([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, selectedOrganization]);

  useEffect(() => {
    console.log('memberSlugArr', memberSlugArr);
    console.log('filterData', filterData);
    console.log('startDate', startDate);
    console.log('endDate', endDate);
    let start_date = new Date(new Date(startDate).toUTCString()).toISOString().split('T')[0];
    let end_date = new Date(new Date(endDate).toUTCString()).toISOString().split('T')[0];

    let groupSlugArr = filterData.group_input ? filterData.group_input.map(el => el.value) : [];
    console.log(groupSlugArr);
    let memberSlugArr1;
    if(memberSlugArr.length > 0){
      memberSlugArr1 = memberSlugArr;
    } else {
      memberSlugArr1 = filterData.member_input ? filterData.member_input.map(el => el.value) : [];
    }
    console.log(memberSlugArr1);
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      //time_log_get
      axios
        .post(serverConfig.api_base_url + "time_log_get", {
          token: token,
          org: selectedOrganization?.slug, grp: groupSlugArr.length > 0 ? groupSlugArr : undefined, timeType: filterData.time_type, members: memberSlugArr1.length > 0 ? memberSlugArr1 : undefined, startDate: start_date, endDate: end_date,
        })
        .then((response) => {
          setIsLoading(true);
          console.log("time_log_get response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              console.log(response.data.items);
              setTimeLog(response.data.items);
            } else {
              setTimeLog([]);
            }
            setIsLoading(false);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization, refreshTimesheet, memberSlugArr, splitTime]);

  //time_log_get_weekly
  useEffect(() => {
    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null &&
      weeklySelectedStartDate !== null &&
      weeklySelectedEndDate !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);
      //time_log_get weekly
      axios
        .post(serverConfig.api_base_url + "time_log_get_weekly", {
          token: token,
          org: selectedOrganization?.slug,
          startDate: weeklySelectedStartDate.toISOString().split("T")[0],
          endDate: weeklySelectedEndDate.toISOString().split("T")[0],
        })
        .then((response) => {
          setIsLoading(true);
          console.log("time_log_get_weekly response", response);
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              console.log(response.data.items);
              setTimeLogWeekly(response.data.items[0]);
              setTimeLogWeeklySummary(response.data.items[1])
            } else {
              setTimeLogWeekly([]);
              setTimeLogWeeklySummary([]);
            }
            setIsLoading(false);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
            setIsLoading(false);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization, weeklySelectedStartDate, weeklySelectedEndDate, refreshTimesheet]);
  

  useEffect(() => {
    console.log("filterData", filterData);
    console.log("timeLog", timeLog);
    let arr = [];
    timeLog.forEach((el1) => {
      el1.project.forEach((el2) => {
        arr.push({
          //member: UserService.getWelcomeName(),
          organization: el2.org_name,
          //timezone: "+08",
          group: el2.group.name,
          task: el2.task_name,
          workbreak: el2.is_work_break === 1 ? "Yes" : "No",
          startTime: new Date(el2.start_time + ' UTC').toLocaleString(),
          endTime: new Date(el2.end_time + ' UTC').toLocaleString(),
          duration: el2.duration,
          activity: el2.activity ? el2.activity + "%" : "0%",
          idle: el2.idle ? el2.idle + "%" : "0%",
          manual: el2.manual ? el2.manual + "%" : "0%",
          billable: el2.is_billable === 1 ? "Yes" : "No",
          reason: el2.reason,
          note: el2.note,
        });
      });
    });
    console.log(arr);
    setTimeLogDownloadable(arr);
  }, [timeLog]);

  //Edit Time entry
  const [timeEntryDetails, setTimeEntryDetails] = useState({});
  const handleShowEditModal = (details) => {
    console.log(details);
    setEditTime(true);
    setTimeEntryDetails(details);
  };

  //Split Time entry
  // const [splitTimeEntryDetails, setSplitTimeEntryDetails] = useState({});
  const handleShowSplitModal = (details) =>{
    console.log(details);
    setSplitTime(true);
    setTimeEntryDetails(details);
  }

  //Delete Time entry
  const [deleteTimeEntryModalShow, setDeleteTimeEntryModalShow] =
    useState(false);
  const handleCloseDeleteTimeEntryModal = () => {
    setDeleteTimeEntryModalShow(false);
    setTimeEntryDetails({});
  };

  const handleShowDeleteModal = (
    timelogSlug,
    timelogDate,
    timelogStartTime,
    timelogEndTime
  ) => {
    console.log(timelogSlug);
    setDeleteTimeEntryModalShow(true);
    console.log({
      timelog: timelogSlug,
      date: timelogDate,
      startTime: timelogStartTime,
      endTime: timelogEndTime,
    });
    setTimeEntryDetails({
      timelog: timelogSlug,
      date: timelogDate,
      startTime: timelogStartTime,
      endTime: timelogEndTime,
    });
  };

  const handleDeleteTimeEntry = (event) => {
    event.preventDefault();
    axios
      .post(serverConfig.api_base_url + "time_log_delete", {
        token: token,
        org: selectedOrganization?.slug,
        timelog: timeEntryDetails.timelog,
      })
      .then((response) => {
        console.log("time_log_delete:", response);
        if (response.data.status === 200) {
          setRefreshTimesheet(!refreshTimesheet);
          handleCloseDeleteTimeEntryModal();
          handleShowAlertModal("Time entry deleted successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          setFormMessage({});
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const [showFilter, setShowFilter] = useState(false);

  return (
    <div>
      <div className={collapse ? "content-body" : "content-body-collapse"}>
        <div className="container-fluid">
          <div
            className="rounded-3xl p-3 table-custom mt-4 relative flex flex-col"
            style={{ overflow: "hidden" }}
          >
            {isLoading && (
              <div className="loading absolute">
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
              </div>
            )}

            {/* Left side: Three tabs flexed together */}
            <div className="flex space-x-3 justify-between">
              <div className="flex">
                <button
                  className={`px-6 py-3 cursor-pointer ${
                    tab === "Daily" ? "tab" : "tab-unactive"
                  }`}
                  onClick={() => setTab("Daily")}
                >
                  Daily
                </button>
                <div
                  className={`px-6 py-3 cursor-pointer ${
                    tab === "Weekly" ? "tab" : "tab-unactive"
                  }`}
                  onClick={() => setTab("Weekly")}
                >
                  Weekly
                </div>
                <div
                  className={`px-6 py-3 cursor-pointer ${
                    tab === "Calendar" ? "tab" : "tab-unactive"
                  }`}
                  onClick={() => setTab("Calendar")}
                >
                  Calendar
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <div>
                  {tab == "Daily" && (
                    <DatePicker
                      //id={startDateId}
                      selected={startDate}
                      showIcon
                      toggleCalendarOnIconClick
                      selectsRange
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Select date range"
                      //dateFormat="M dd, yyyy" //M dd, yyyy, Tue, Sep 2, 2023 — Tue, Sep 9, 2023
                      onChange={pickDateRange}
                      //locale={selectLocale(locale)}
                      maxDate={new Date()}
                      customInput={
                        <input
                          value={`${startDate} - ${endDate}`}
                          className="custom-input calendar-input"
                        />
                      }
                    />
                  )}
                  {tab === "Weekly" && (
                    <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    selectsRange
                    selected={weeklySelectedStartDate}
                    onChange={handleWeeklyTabDateChange}
                    onSelect={handleWeeklyTabWeekClick}
                    startDate={weeklySelectedStartDate}
                    endDate={weeklySelectedEndDate}
                    placeholderText="Select date"
                    maxDate={new Date()} 
                    customInput={
                      <input
                        value={`${startDate} - ${endDate}`}
                        className="custom-input calendar-input"
                      />
                    }
                  />                  
                  )}
                  {tab === "Calendar" && (
                    <DatePicker
                      showIcon
                      toggleCalendarOnIconClick
                      selectsRange
                      selected={calendarSelectedStartDate}
                      onChange={handleCalendarTabDateChange}
                      onSelect={handleCalendarTabWeekClick}
                      startDate={calendarSelectedStartDate}
                      endDate={calendarSelectedEndDate}
                      maxDate={new Date()}
                      placeholderText="Select date"
                      customInput={
                        <input
                          value={`${startDate} - ${endDate}`}
                          className="custom-input calendar-input"
                        />
                      }
                    />
                  )}
                </div>
                {/* <div className="relative inline-block">
                  <select className="w-20 h-8 md:h-10 px-4 bg-white border border-gray-300 rounded-md focus:outline-none   appearance-none search-input text-style dark-optimise">
                    <option value="+08" className="dark-optimise">
                      +08
                    </option>
                    <option value="+09" className="dark-optimise">
                      +09
                    </option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <i className="fa-solid fa-chevron-down text-sm "></i>
                  </div>
                </div> */}
                <div>
                  <CSVLink
                    headers={[
                      //{ label: "Member", key: "member" },
                      { label: "Organization", key: "organization" },
                      //{ label: "Timezone", key: "timezone" },
                      { label: "Group", key: "group" },
                      { label: "Task", key: "task" },
                      { label: "Workbreak", key: "workbreak" },
                      { label: "StartTime", key: "startTime" },
                      { label: "EndTime", key: "endTime" },
                      { label: "Duration", key: "duration" },
                      { label: "Activity", key: "activity" },
                      { label: "Idle", key: "idle" },
                      { label: "Manual", key: "manual" },
                      { label: "Billable", key: "billable" },
                      { label: "Reason", key: "reason" },
                      { label: "Note", key: "note" },
                    ]}
                    data={timeLogDownloadable}
                    filename={`${UserService.getWelcomeName()}_timesheet_report_${
                      new Date(startDate).toISOString().split("T")[0]
                    }_to_${new Date(endDate).toISOString().split("T")[0]}`}
                  >
                    <button
                      type="button"
                      class="cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium"
                      disabled={timeLog.length === 0}
                      title={
                        timeLog.length === 0
                          ? "There is no data to export"
                          : "Export to CSV"
                      }
                    >
                      <i className="fa-solid fa-download"></i>
                    </button>
                  </CSVLink>
                </div>
                <div>
                  <button
                    type="button"
                    class="cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium"
                    onClick={() => setShowFilter(true)}
                    /* disabled={timeLog.length === 0} */ title={
                      timeLog.length === 0
                        ? "There is no data to filter"
                        : "Filter"
                    }
                  >
                    <i className="fa-solid fa-filter"></i>
                  </button>
                </div>
                <button
                  className="sub-grp px-3 py-2 h-10"
                  onClick={() => {
                    setAddTime(true);
                  }}
                >
                  Add Time
                </button>
              </div>
            </div>

            {tab === "Daily" && (
              <Daily
                timeLog={timeLog}
                selectedOrganization={selectedOrganization}
                setAddTime={setAddTime}
                handleShowDeleteModal={handleShowDeleteModal}
                handleShowEditModal={handleShowEditModal}
                handleShowSplitModal={handleShowSplitModal}
              />
            )}

            {tab === "Weekly" && (
              <Weekly
                timeLogWeekly={timeLogWeekly}
                summaryData={timeLogWeeklySummary}
                selectedOrganization={selectedOrganization}
                setAddTime={setAddTime}
                handleShowDeleteModal={handleShowDeleteModal}
                handleShowEditModal={handleShowEditModal}
                startDate={weeklySelectedStartDate}
                endDate={weeklySelectedEndDate}
              />
            )}

            {tab === "Calendar" && (
              <CalendarView
                timeLogCalendar={timeLogCalendar}
                selectedOrganization={selectedOrganization}
                setAddTime={setAddTime}
                handleShowDeleteModal={handleShowDeleteModal}
                handleShowEditModal={handleShowEditModal}
                startDate={calendarSelectedStartDate}
                endDate={calendarSelectedEndDate}
              />
            )}
          </div>
        </div>

        {alertModalShow && (
          <>
            {showOkButton ? (
              <Notification message={alertMessage} notificationType={"Error"} />
            ) : (
              <Notification
                message={alertMessage}
                notificationType={"Success"}
              />
            )}
          </>
        )}

        {/* Add Time Entry */}
        {addTime === true ? (
          <div className="overlay">
            <AddTimePopup
              setAddTime={setAddTime}
              token={token}
              selectedOrganization={selectedOrganization}
              handleLogout={handleLogout}
              refreshTimesheet={() => setRefreshTimesheet(!refreshTimesheet)}
              handleCloseAlertModal={handleCloseAlertModal}
              handleShowAlertModal={handleShowAlertModal}
            />
          </div>
        ) : (
          <></>
        )}

        {/* Edit Time Entry */}
        {editTime === true ? (
          <div className="overlay">
            <EditTimePopup
              setEditTime={setEditTime}
              token={token}
              selectedOrganization={selectedOrganization}
              handleLogout={handleLogout}
              refreshTimesheet={() => setRefreshTimesheet(!refreshTimesheet)}
              handleCloseAlertModal={handleCloseAlertModal}
              handleShowAlertModal={handleShowAlertModal}
              timeEntryDetails={timeEntryDetails}
            />
          </div>
        ) : (
          <></>
        )}

        {/* Split Time Entry */}
        {splitTime === true ? (
          <div className="overlay">
            <SplitTimePopup
              setSplitTime={setSplitTime}
              token={token}
              selectedOrganization={selectedOrganization}
              handleLogout={handleLogout}
              refreshTimesheet={() => setRefreshTimesheet(!refreshTimesheet)}
              handleCloseAlertModal={handleCloseAlertModal}
              handleShowAlertModal={handleShowAlertModal}
              timeEntryDetails={timeEntryDetails}
            />
          </div>
        ) : (
          <></>
        )}

        {/* Delete Time Entry */}
        {deleteTimeEntryModalShow && (
          <div className="overlay">
            <form
              className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2"
              onSubmit={handleDeleteTimeEntry}
            >
              <div className=" flex-grow overflow-y-auto ">
                <div className="flex justify-between items-center gap-5">
                  <div style={{ fontSize: 16 }}>Delete Time Entry</div>
                </div>

                <div className="mt-4">
                  <p>
                    Are you sure you want to delete this Time Entry on{" "}
                    <b>{timeEntryDetails.date}</b> from{" "}
                    <b>{timeEntryDetails.startTime}</b> to{" "}
                    <b>{timeEntryDetails.endTime}</b>?
                  </p>
                </div>
              </div>
              {/* bottom buttom */}
              <div className="spacing-div">
                <button
                  type="button"
                  className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  onClick={handleCloseDeleteTimeEntryModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        )}
      </div>

      <Filters
        token={token}
        selectedOrganization={selectedOrganization}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        timeLog={timeLog}
        setTimeLog={setTimeLog}
        startDate={startDate}
        endDate={endDate}
        handleCloseAlertModal={handleCloseAlertModal}
        handleShowAlertModal={handleShowAlertModal}
        viewMemberTimesheetPrivileged={viewMemberTimesheetPrivileged}
        setIsLoading={setIsLoading} setMemberSlugArr={setMemberSlugArr} setFilterData={setFilterData}
      />
    </div>
  );
}

export default Timesheet;
