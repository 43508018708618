import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Context } from '../contextApi/context';
import './css/dashboard.css';
import axios from 'axios';
import UserService from './../services/UserService';
import Notification from '../common/notification/Notification.js';
import NumberCard from './charts/NumberCard.js';
import TableChart from './charts/TableChart.js';
import VectorMap from './charts/VectorMap.js';
import Map from './charts/Map.js';
import RCMultiLineChart from './charts/RCMultiLineChart.js';
import { MultiSelect } from 'react-multi-select-component';
import SubTitle from './charts/SubTitle.js';
import RCStackedBarChart from './charts/RCStackedBarChart.js';
import RCPercentAreaChart from './charts/RCPercentAreaChart.js';
import { useNavigate } from 'react-router';
import { Box, Slider, TextField, Typography, Switch, FormControlLabel } from '@mui/material';
import AdjustableChart from './charts/AdjustableChart.js';
import { useLocation } from 'react-router-dom';
import RCPieChart from './charts/RCPieChart.js';
import Legend from './charts/Legend.js';
import RCMultipleBarChart from './charts/RCMultipleBarChart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import Select, { components } from 'react-select';
import MindMap from './charts/MindMap.js';
import { ReactFlowProvider } from 'reactflow';
import { debounce, update } from 'lodash';
import { FaEdit, FaTrash } from 'react-icons/fa';
import GoogleMapWithBoundaries from './charts/GoogleMapWithBoundaries.js';
import SummaryChart from './charts/SummaryChart.js';
import { string } from 'prop-types';
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import StatumVectorMap from './charts/StatumVectorMap.js';
import StatumRCMultiLineChart from './charts/StatumRCMultilineChart.js';

const serverConfig = require('./../config/server.js');

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className='custom-option' title={props.label}>
        {props.label}
      </div>
    </components.Option>
  );
};

const Dashboard = () => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [notInThreshold, setNotInThreshold] = useState(1);

  const MAX = 100;
  const MIN = 0;
  const marks = [
    {
      value: MIN,
      label: '',
    },
    {
      value: MAX,
      label: '',
    },
  ];

  const baseUrl = window.location.origin;

  const scaleValue = (value, scaleFactor) => value / scaleFactor;
  const unscaleValue = (value, scaleFactor) => value * scaleFactor;
  const scaleFactor = 1000000;
  const [val, setVal] = useState([
    scaleValue(200, scaleFactor),
    scaleValue(3510540000, scaleFactor),
  ]);

  // Utility function to convert from slider value (0-100) to scaled value
  const sliderToScaledValue = (sliderValue) => {
    return customScale(sliderValue);
  };

  // Utility function to convert from scaled value to slider value (0-100)
  const scaledToSliderValue = (scaledValue, segments) => {
    for (const segment of segments) {
      const [rangeStart, rangeEnd] = segment.range;
      const [scaleStart, scaleEnd] = segment.scale;

      if (scaledValue >= scaleStart && scaledValue <= scaleEnd) {
        // **Prevent division by zero error**
        if (scaleEnd === scaleStart) return rangeStart;

        // Correct normalization by reversing the original scale function
        const normalized = (scaledValue - scaleStart) / (scaleEnd - scaleStart);
        const computedValue = rangeStart + normalized * (rangeEnd - rangeStart);

        return computedValue;
      }
    }
    // **Ensure it stays within range (handle out-of-bounds values)**
    if (scaledValue < segments[0].scale[0]) return segments[0].range[0];
    if (scaledValue > segments[segments.length - 1].scale[1]) return segments[segments.length - 1].range[1];

    return scaledValue; // Default return
  };

  const handleChange = (item, event, newValue, customScale) => {
    const [sliderMin, sliderMax] = newValue;

    // Convert slider values to scaled values and round them
    const scaledMin = Math.round(customScale(sliderMin));
    const scaledMax = Math.round(customScale(sliderMax));

    // Update quick filters map with rounded values
    setQuickFiltersMap((prevValues) => ({
      ...prevValues,
      [item?.label]: {
        min: scaledMin,
        max: scaledMax,
      },
    }));

    // Find the 'between' operator
    let operatorSlug = item?.operators.filter((el) => el?.value === 'between');

    // Prepare new filter data with rounded values
    let newData = {
      fltr: item?.fil,
      filter_label: item?.label,
      type: item?.type,
      optr: operatorSlug[0]?.optr,
      filter_operator: operatorSlug[0]?.label,
      array_value: [scaledMin, scaledMax],
      value: null,
    };

    // Indicate dragging is in progress
    setIsDragging(true);

    // Update dynamic filter data
    setDynamicFilterData((prevState) => {
      // Ensure prevState is an array
      const updatedState = Array.isArray(prevState) ? [...prevState] : [];

      // Find existing filter in the state
      const existingIndex = updatedState.findIndex(
        (el) => el.fltr === item?.fil
      );

      // Update or add the filter
      if (existingIndex !== -1) {
        updatedState[existingIndex].array_value = [scaledMin, scaledMax];
      } else {
        updatedState.push(newData);
      }

      return updatedState;
    });
  };

  const handleMinInputChange = (event, item, customScale) => {
    setSliderInputValueChange(true);
    const newMin = customScale(event.target.value);
    const currentMax = quickFiltersMap[item?.label]?.max;

    if ((parseInt(newMin) || 0) < currentMax) {
      setQuickFiltersMap((prevValues) => ({
        ...prevValues,
        [item?.label]: {
          min: newMin,
          max: currentMax,
        },
      }));
      debouncedUpdateDynamicFilter(item, [newMin, currentMax], filterData);
      // updateDynamicFilterData(item, [newMin, currentMax], filterData);
    } else {
      setQuickFiltersMap((prevValues) => ({
        ...prevValues,
        [item?.label]: {
          min: newMin,
          max: newMin,
        },
      }));
      debouncedUpdateDynamicFilter(item, [newMin, newMin], filterData);
      // updateDynamicFilterData(item, [currentMax, currentMax], filterData);
    }
  };

  const handleMaxInputChange = (event, item, customScale) => {
    setSliderInputValueChange(true);
    const newMax = customScale(event.target.value);
    const currentMin = quickFiltersMap[item?.label]?.min;

    setQuickFiltersMap((prevValues) => ({
      ...prevValues,
      [item?.label]: {
        min: currentMin,
        max: newMax,
      },
    }));
    debouncedUpdateDynamicFilter(item, [currentMin, newMax], filterData);
  };

  const updateDynamicFilterData = (item, arrayValue, currentFilterData) => {
    // Ensure currentFilterData is an array
    const safeCurrentFilterData = Array.isArray(currentFilterData) ? currentFilterData : [];

    let newData = {
      fltr: item?.fil,
      filter_label: item?.label,
      type: item?.type,
      optr: item?.operators[0]?.optr,
      filter_operator: item?.operators[0]?.label,
      array_value: arrayValue,
      value: null,
    };

    setDynamicFilterData((prevState) => {
      // Ensure prevState is an array
      const updatedState = Array.isArray(prevState) ? [...prevState] : [];
      const existingIndex = updatedState.findIndex((el) => el.fltr === item?.fil);

      if (existingIndex !== -1) {
        updatedState[existingIndex].array_value = arrayValue;
      } else {
        updatedState.push(newData);
      }

      // Create a new merged array starting with the safe current filter data
      const mergedData = [...safeCurrentFilterData];

      // Update or add items from updatedState
      updatedState.forEach((updatedItem) => {
        const index = mergedData.findIndex((d) => d.fltr === updatedItem.fltr);
        if (index !== -1) {
          mergedData[index] = updatedItem;
        } else {
          mergedData.push(updatedItem);
        }
      });

      handleSubmit(mergedData, null);
      return updatedState;
    });
  };

  const handleFromDateChange = (event, item) => {
    const fromDate = event.target.value;
    setQuickFiltersMap((prevValues) => ({
      ...prevValues,
      [item?.label]: {
        min: fromDate,
        max: prevValues[item?.label]?.max,
      },
    }));
  };

  const handleToDateChange = (event, item) => {
    const toDate = event.target.value;
    setQuickFiltersMap((prevValues) => ({
      ...prevValues,
      [item?.label]: {
        min: prevValues[item?.label]?.min,
        max: toDate,
      },
    }));
  };

  const debouncedUpdateDynamicFilter = useRef(
    debounce((item, arrayValue, currentFilterData) => {
      updateDynamicFilterData(item, arrayValue, currentFilterData);
    }, 2000)
  ).current;

  const navigate = useNavigate();
  const { collapse } = useContext(Context);
  const { selectedOrganization } = useContext(Context);
  const searchParams = new URLSearchParams(window.location.search);
  const dash = searchParams.get('slug');
  const [dashboardList, setDashboardList] = useState();
  const [dashboardLoader, setDashboardLoader] = useState(false);
  const [token, setToken] = useState(null);
  const [filterLookup, setFilterLookup] = useState([]);
  const [quickFilterLookup, setQuickFilterLookup] = useState([]);
  const [savedFilterLookup, setSavedFilterLookup] = useState([]);
  const [saveFilterValue, setSaveFilterValue] = useState(null);
  const [filterApply, setFilterApply] = useState(false);
  const [filter, setFilter] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [taggedFilterData, setTaggedFilterData] = useState([]);
  const [filterOperator, setFilterOperator] = useState();
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [nextId, setNextId] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [dashboardTitle, setDashboardTitle] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterSave, setFilterSave] = useState(false);
  const [filterEdit, setFilterEdit] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [filterDelete, setFilterDelete] = useState(false);
  const [filterEditData, setFilterEditData] = useState({});
  const [lookupShow, setLookupShow] = useState(false);
  const [lookupOptions, setLookupOptions] = useState([]);
  const [lookupValue, setLookupValue] = useState([]);
  const [staticLookupOptions, setStaticLookupOptions] = useState([]);
  const [currPage, setCurrPage] = useState('canvas');
  const [filterClose, setFilterClose] = useState(true);
  const [dynamicFilterData, setDynamicFilterData] = useState([]);
  const [optionsMap, setOptionsMap] = useState({});
  const [originalOptionsMap, setOriginalOptionsMap] = useState({});
  const [quickFiltersMap, setQuickFiltersMap] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const location = useLocation();
  const [previousSlug, setPreviousSlug] = useState('cnsdjcnsd cjnsdc sdjncs');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [sliderInputValueChange, setSliderInputValueChange] = useState(false);
  const [publishToAllDashboards, setPublishToAllDashboards] = useState(false);
  const [publishToAllUsers, setPublishToAllUsers] = useState(false);
  const [isPublishPrivileged, setIsPublishPrivileged] = useState(0);
  const [currentFilterLabel, setCurrentFilterLabel] = useState('');
  const [allItems, setAllItems] = useState({});
  const [countMessage, setCountMessage] = useState({});
  const [checked, setChecked] = useState(false);
  const [toggleValue, setToggleValue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [segmentsData, setSegmentsData] = useState([
    { range: [0, 50], scale: [0, 6000] },
    { range: [50, 75], scale: [6000, 100000] },
    { range: [75, 100], scale: [100000, 3510540] },
  ]);
  const [popupDashboardTitle, setPopupDashboardTitle] = useState('');
  const [popupFilterData, setPopupFilterData] = useState([]);
  const [popupChartData, setPopupChartData] = useState([]);

  //const customScale = createCustomScale(segmentsData);

  function createCustomScale(segments) {
    return function scale(value) {
      for (const segment of segments) {
        const [rangeStart, rangeEnd] = segment.range;
        const [scaleStart, scaleEnd] = segment.scale;

        if (value >= rangeStart && value <= rangeEnd) {
          // **Prevent division by zero errors**if (rangeEnd === rangeStart) return scaleStart;

          // Correct normalization
          const normalized = (value - rangeStart) / (rangeEnd - rangeStart);

          // Map to the segment's scale correctlyreturn scaleStart + normalized * (scaleEnd - scaleStart);
        }
      }
      return value; // Default return if out of range
    };
  }

  const handleToggle = (item) => {
    const newChecked = !toggleValue[item?.label];

    setToggleValue((prev) => ({
      ...prev,
      [item?.label]: newChecked,
    }));

    const newFilter = {
      filter_name: item?.operators[0]?.value,
      filter_label: item?.label,
      optr: item?.operators[0]?.optr,
      filter_operator: item?.operators[0]?.label,
      array_value: null,
      fltr: item?.fil,
      value: newChecked ? 'true' : 'false',
      type: item?.type,
    };

    const updateFilters = (filters) => {
      const existingFilterIndex = filters.findIndex(
        (filter) => filter.filter_label === item?.label
      );

      if (existingFilterIndex !== -1) {
        const updatedFilters = [...filters];
        updatedFilters[existingFilterIndex].value = newChecked ? 'true' : 'false';
        return updatedFilters;
      } else {
        return [...filters, newFilter];
      }
    };

    setFilterData((prevFilters) => updateFilters(prevFilters));
    setTaggedFilterData((prevTaggedFilters) => updateFilters(prevTaggedFilters));

    handleSubmit(newFilter, null);

  };


  const dropdownRefs = useRef({});

  const currentLabelRef = useRef(null);
  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };
  let abortController;
  const [alertMessage, setAlertMessage] = useState('');
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage('');
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  const getToken = () => {
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + 'user_get_token', {
        jwt: jwt,
        url: window.location.host,
      })
      .then((response) => {
        if (response.data.status === 200) {
          sessionStorage.setItem('token', response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => console.log('error is', err));
  };

  useEffect(() => {
    if (sessionStorage.getItem('token') === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  let mergedFilterData;
  const filterListGet = () => {
    if (JSON.stringify(mergedFilterData) !== JSON.stringify(filterData)) {
      axios
        .post(serverConfig.api_base_url + 'canvas_filter_get_list', {
          token: token,
          org: selectedOrganization?.slug,
          dash: dash,
          filter: filterData,
        })
        .then((response) => {
          if (response.data.status === 200) {
            let staticFilters = response.data.items.filter(
              (el) =>
                el?.is_static === 1 && el?.sequence_no != undefined
            )
              .sort((a, b) => a.sequence_no - b.sequence_no);
            const dynamicFilters = response.data.items.filter(
              (el) => el?.is_static === 0
            );
            const quickFilters = response.data.items.filter(
              (el) =>
                el?.is_static === 1 &&
                (el?.type === 'numeric' || el?.type === 'date')
            );

            const initialState = {};

            quickFilters.forEach((filter) => {
              const operator = filter.operators[0]; // Assuming first operator has min and max

              if (operator.options) {
                initialState[filter.label] = {
                  min: operator.options.selected_min,
                  max: operator.options.selected_max,
                };
              }
            });
            setQuickFiltersMap(initialState);

            if (response.data.pinned_filter?.length > 0) {
              let pinnedFiltersMap = new window.Map(
                response.data.pinned_filter.map((filter) => [filter.fltr, true])
              );

              // Add `is_pinned: 1` to matching filters
              staticFilters = staticFilters.map((filter) => ({
                ...filter,
                is_pinned: pinnedFiltersMap.has(filter.fil) ? 1 : undefined,
              }));

            }
            setStaticLookupOptions(staticFilters);
            setFilterLookup(dynamicFilters);
            setQuickFilterLookup(quickFilters);

            if (response.data.pinned_filter?.length > 0) {
              const lookupValues = {};
              const pinnedRecords = {};
              let toggleFilters = {};

              response.data.pinned_filter.forEach((filter) => {
                lookupValues[filter?.filter_label] = filter?.array_value?.map(
                  (item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  }
                );
              });

              response.data.pinned_filter.forEach((filter) => {

                if (filter?.type === 'switch') {
                  let currentToggleFilters = { ...toggleFilters }; // Copy the current toggle filters
                  const filterLabel = filter?.filter_label;

                  // Check if the value already exists in toggleFilters
                  if (currentToggleFilters[filterLabel] !== undefined) {
                    // Value already exists, ensure it's consistent
                    currentToggleFilters[filterLabel] = filter?.value === 'true' ? true : false;
                  } else {
                    // Append new value
                    currentToggleFilters[filterLabel] = filter?.value === 'true' ? true : false;
                  }

                  toggleFilters = currentToggleFilters; // Update the toggleFilters object
                }
                else {
                  pinnedRecords[filter?.filter_label] = filter?.array_value?.map(
                    (item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    }
                  );
                }
              });

              setToggleValue((prevToggleValue) => {
                // Merge existing state with new toggleFilters, ensuring no duplicates
                const updatedToggleValue = {
                  ...prevToggleValue,
                  ...toggleFilters,
                };
                return updatedToggleValue;
              });
              setLookupValue(lookupValues);

              //setAllItems({...allItems, pinnedRecords});
              mergedFilterData = [...filterData];

              response.data.pinned_filter.forEach((pinnedFilter) => {
                const existingFilterIndex = filterData.findIndex(
                  (existingFilter) =>
                    existingFilter.filter_label === pinnedFilter.filter_label
                );

                if (existingFilterIndex !== -1) {
                  mergedFilterData[existingFilterIndex] = {
                    ...filterData[existingFilterIndex],
                    ...pinnedFilter,
                    array_value: [
                      ...new Set([
                        ...(filterData[existingFilterIndex].array_value || []),
                        ...(pinnedFilter.array_value || []),
                      ])
                    ],
                  };
                } else {
                  mergedFilterData.push(pinnedFilter);
                }
              });

              handleSubmit(mergedFilterData, null);

            }

            else if (response.data.default_filter?.length > 0 && response?.data?.pinned_filter?.length === 0) {
              const lookupValues = {};
              const defaultFilters = {};
              const toggleFilters = {};

              response.data.default_filter.forEach((filter) => {
                if (filter?.type === 'switch') {
                  toggleFilters[filter?.filter_label] = filter?.value === 'true' ? true : false;
                }
                else {
                  lookupValues[filter?.filter_label] = filter?.array_value?.map(
                    (item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    }
                  );
                }
              });

              response.data.default_filter.forEach((filter) => {
                defaultFilters[filter?.filter_label] = filter?.array_value?.map(
                  (item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  }
                );
              });

              setLookupValue(lookupValues);
              setToggleValue(toggleFilters);
              setAllItems(defaultFilters);
              mergedFilterData = [...filterData];

              response.data.default_filter.forEach((defaultFilter) => {
                const existingFilterIndex = filterData.findIndex(
                  (existingFilter) =>
                    existingFilter.filter_label === defaultFilter.filter_label
                );

                if (existingFilterIndex !== -1) {
                  mergedFilterData[existingFilterIndex] = {
                    ...filterData[existingFilterIndex],
                    ...defaultFilter,
                    array_value: [
                      ...new Set([
                        ...(filterData[existingFilterIndex].array_value || []),
                        ...(defaultFilter.array_value || []),
                      ])
                    ],
                  };
                } else {
                  mergedFilterData.push(defaultFilter);
                }
              });

              handleSubmit(mergedFilterData, null);
            }
            else {
              handleSubmit(filterData, null);
            }
          }
          setSliderInputValueChange(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeCommitted = (event, newValue) => {
    setIsDragging(false);
    const mergedData = [...filterData];
    dynamicFilterData?.forEach((item) => {
      const index = mergedData.findIndex((d) => d.fltr === item.fltr);
      if (index !== -1) {
        mergedData[index] = item;
      } else {
        mergedData.push(item);
      }
    });
    handleSubmit(mergedData, null);
  };

  const savedFilterListGet = () => {
    axios
      .post(serverConfig.api_base_url + 'canvas_user_filterset_get_list', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setSavedFilterLookup(response.data.items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnpinFilter = (item) => {
    const filterDataPinnedItem = filterData.find((item1) =>
      taggedFilterData.some((item2) => item1.label === item2.label)
    );
    axios
      .post(serverConfig.api_base_url + 'canvas_user_filter_unpin', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        pinfltr: item?.pinned_slug,
      })
      .then((response) => {
        if (response.data.status === 200) {

          // Update taggedFilterData to unpin the filter
          const updatedFilterData = taggedFilterData.map((value) => {
            if (value.pinned_slug === item?.pinned_slug) {
              value.pinned_slug = null; // Unpinning the filter
            }
            return value;
          });

          // Update staticLookupOptions to remove 'is_pinned' for the given filter
          const updatedStaticLookupOptions = staticLookupOptions.map((filter) => {
            if (filter?.fil === item?.fltr) {
              const { is_pinned, ...updatedFilter } = filter; // Remove 'is_pinned'
              return updatedFilter;
            }
            return filter;
          });

          // Update lookup options if needed
          filterData.forEach((filterItem) => {
            if (filterItem?.filter_operator === 'LIST') {
              setLookupOptions((prevOptions) => [...prevOptions, filterItem]);
            }
          });

          // Update state with modified data
          setStaticLookupOptions(updatedStaticLookupOptions); // Update staticLookupOptions
          setTaggedFilterData(updatedFilterData); // Update taggedFilterData
          handleShowAlertModal('Filter unpinned');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const handlePinFilter = (item) => {

    const pinData = [item];
    axios
      .post(serverConfig.api_base_url + 'canvas_user_filter_pin', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        filter: pinData,
      })
      .then((response) => {
        if (response.data.status === 200) {
          // Update taggedFilterData to pin the filter
          const updatedFilterData = taggedFilterData.map((value) => {
            if (value.filter_label === item?.filter_label) {
              value.pinned_slug = response.data.slug; // Add pinned_slug
            }
            return value;
          });

          // Update staticLookupOptions to add 'is_pinned' with value 1
          const updatedStaticLookupOptions = staticLookupOptions.map((filter) => {
            if (filter?.fil === item?.fltr) {
              return { ...filter, is_pinned: 1 }; // Set 'is_pinned' to 1
            }
            return filter;
          });

          // Update state with modified data
          setStaticLookupOptions(updatedStaticLookupOptions); // Update staticLookupOptions
          setTaggedFilterData(updatedFilterData); // Update taggedFilterData
          handleShowAlertModal('Filter pinned');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });

  };

  useEffect(() => {
    setFilterData([]);
    setQuickFiltersMap({});
    setFilterLookup([]);
    setDynamicFilterData([]);
    setCountMessage({});
  }, [dash]);

  useEffect(() => {
    savedFilterListGet();
  }, [token, filterSave, dash]);

  useEffect(() => {
    if (isDragging === false) {
      setChartData([]);
      setFilter();
      setFilterApply(false);
      if (token !== null && token !== undefined) {
        // setDashboardLoader(true);

        axios
          .post(serverConfig.api_base_url + 'canvas_dashboard_get_details', {
            token: token,
            org: selectedOrganization?.slug,
            dash: dash,
          })
          .then((response) => {
            setDashboardList(response.data.items);
            setDashboardTitle(response.data.items.name);
            // setDashboardLoader(false);
          })
          .catch((err) => {
            console.log(err);
          });

        // Clear filterData when the slug changes
        const currentSlug = new URLSearchParams(location.search).get('slug');
        if (previousSlug) {
          if (previousSlug !== currentSlug) {
            setLookupValue({});
            setAllItems({});
            setSaveFilterValue(null);
          }
        }
        setPreviousSlug(currentSlug);
        if (
          token !== null &&
          token !== undefined &&
          dash !== null &&
          dash !== undefined &&
          dashboardTitle !== null &&
          dashboardTitle !== undefined &&
          isDragging === false
        ) {
          filterListGet();
        }
      }
    }
  }, [token, filterData, dynamicFilterData, isDragging]);

  const handleSaveFilter = () => {
    const publishToAllDashboardsValue = publishToAllDashboards ? '1' : '0';
    const publishToAllUsersValue = publishToAllUsers ? '1' : '0';

    axios
      .post(serverConfig.api_base_url + 'canvas_save_filterset', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        filter: filterData,
        filter_name: filterName,
        publish_to_all_dashboards: publishToAllDashboardsValue,
        publish_to_all_users: publishToAllUsersValue,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setFilterSave(false);
          handleShowAlertModal('Filterset saved');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function stringIsArray(str) {//check whether string is an array or not
    try {
      return new Function(`return Array.isArray(${str})`)();
    } catch {
      return false;
    }
  }

  const convertToArray = (data) => {
    return data?.map((item) => item?.value);
  };

  const controllerRef = useRef(null);

  const handleSubmit = (filter, values) => {

    // console.log(lookupOptions, lookupValue);

    // if (controllerRef.current) {
    //   controllerRef.current.abort();
    // }

    // controllerRef.current = new AbortController();
    // const controller = controllerRef.current;

    // let updatedData = [...filterData];

    // Create a local cancellation flag
    let isCancelled = false;

    // Existing abort controller logic
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const controller = controllerRef.current;

    // Wrap the entire logic in a try-catch with an early return mechanism
    try {
      // Create a wrapper function to check cancellation before critical operations
      const checkCancellation = () => {
        if (controller.signal.aborted) {
          isCancelled = true;
          return true;
        }
        return false;
      };

      // Early cancellation check before main logic
      if (checkCancellation()) {
        console.log('Operation cancelled before execution');
        return;
      }

      // Existing pre-axios logic remains unchanged
      let updatedData = [...filterData];

      // Add cancellation checks at critical points
      if (checkCancellation()) return;

      if (filter && filter?.type === 'lookup' && values && typeof (values) !== string) {
        var result = convertToArray(values);

        let fullArr = convertToArray(originalOptionsMap[currentFilterLabel]);

        if (filter?.operators[0]?.is_multiselect === 0) {
        }

        else if (values?.length < (filter.not_in_threshold / 100 * filter?.max_count)) {
        }
        else if (values?.length > (filter.not_in_threshold / 100 * filter?.max_count)) {
          result = fullArr?.filter(x => !result.includes(x)).concat(result.filter(x => !fullArr?.includes(x)));
        }

        const existingFilterIndex = updatedData.findIndex(
          (f) => f.filter_label === filter?.label
        );
        if (existingFilterIndex >= 0) {
          updatedData[existingFilterIndex].array_value = result?.length === 0 ? updatedData[existingFilterIndex]?.array_value : result;

          // updatedData1[existingFilterIndex].array_value =
          //   result1 === null
          //     ? updatedData1[existingFilterIndex]?.array_value
          //     : result1;

          //     console.log(updatedData1);

          if (filter?.operators[0]?.is_multiselect === 0) {
            //for single select dropdown use IN operator
            filterOptr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
            filterOperator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
          }
          else if (values?.length < (filter?.not_in_threshold / 100 * filter?.max_count)) {
            updatedData[existingFilterIndex].optr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
            updatedData[existingFilterIndex].filter_operator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
          } else if (values?.length > (filter?.not_in_threshold / 100 * filter?.max_count)) {
            updatedData[existingFilterIndex].optr = filter?.operators.filter(el => el.label === "NOT IN")[0]?.optr;
            updatedData[existingFilterIndex].filter_operator = filter?.operators.filter(el => el.label === "NOT IN")[0]?.label;
          }
        } else {
          var filterOptr, filterOperator;
          if (filter?.operators[0]?.is_multiselect === 0) {
            //for single select dropdown use IN operator
            filterOptr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
            filterOperator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
          }
          else if (values?.length < (filter?.not_in_threshold / 100 * filter?.max_count)) {
            //use IN operator
            filterOptr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
            filterOperator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
          } else if (values?.length > (filter?.not_in_threshold / 100 * filter?.max_count)) {
            //use NOT IN operator
            filterOptr = filter?.operators.filter(el => el.label === "NOT IN")[0]?.optr;
            filterOperator = filter?.operators.filter(el => el.label === "NOT IN")[0]?.label;
          }
          const newFilter = {
            id: nextId,
            filter_name: filter?.operators?.value,
            filter_label: filter?.label,
            optr: filterOptr,
            filter_operator: filterOperator,
            array_value: result,
            fltr: filter?.fil,
            value: null,
            sequence_no: filter?.sequence_no
          };
          updatedData.push(newFilter);
        }
        setFilterData(updatedData);
        setTaggedFilterData(updatedData);

      } else if (filter?.type === 'numeric' || filter?.type === 'text') {
        updatedData.push(filter);
        if (JSON.stringify(filterData) !== JSON.stringify(updatedData)) {
          setFilterData(updatedData);
          setTaggedFilterData(updatedData);
        }

        else if (filter?.type === 'switch') {
          updatedData.push(filter);
        }

      }
      else if (values === 'null_selected') {
        let updatedFilter = filter.filter((el) => el?.array_value?.length !== 0);
        if (JSON.stringify(filterData) !== JSON.stringify(updatedFilter)) {
          setFilterData(updatedFilter);
          setTaggedFilterData(updatedFilter);
        }
      }
      else if (filter?.type === 'switch') {
      }
      else if (filter?.type === 'data_limit') {
        // Handle 'data_limit' type
        const dataLimitValue = values?.data_limit || 0; // Assuming the `values` object contains a `data_limit` property

        // Check if a filter with the same label already exists
        const existingFilterIndex = updatedData.findIndex((el) => el.filter_label === filter?.label);

        if (existingFilterIndex >= 0) {
          // Update the existing filter
          updatedData[existingFilterIndex] = {
            ...updatedData[existingFilterIndex],
            value: dataLimitValue, // Update the value
          };
        } else {
          // Add a new filter if it doesn't exist
          const newFilter = {
            id: nextId,
            filter_name: filter?.label,
            filter_label: filter?.label,
            array_value: [], // No array values for data_limit
            fltr: filter?.fil,
            value: dataLimitValue,
            sequence_no: item?.sequence_no
          };
          updatedData.push(newFilter);
        }

        // Update the state
        setFilterData(updatedData);
        setTaggedFilterData(updatedData);
      } else {
        updatedData = filter;
        let selectedValues = {};
        updatedData?.forEach((filter) => {
          selectedValues[filter?.filter_label] = filter?.array_value?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
        if (JSON.stringify(filterData) !== JSON.stringify(updatedData)) {
          setFilterData(updatedData);
          setTaggedFilterData(updatedData);
          setAllItems(selectedValues);
        }
        //setAllItems(selectedValues);
      }

      const lookupValues = {};
      const lookupValues1 = {};

      if ((values?.length - 1) < (filter?.not_in_threshold / 100 * filter?.max_count)) {
        updatedData?.forEach((filter) => {
          lookupValues[filter?.filter_label] = filter?.array_value?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
        setLookupValue(lookupValues);
        //setAllItems(lookupValues);
      } else if ((values?.length - 1) > (filter?.not_in_threshold / 100 * filter?.max_count)) {
        updatedData?.forEach((filter) => {
          lookupValues[filter?.filter_label] = filter?.array_value?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
        //setAllItems(lookupValues);
        // updatedData1?.forEach((filter) => {
        //   lookupValues1[filter?.filter_label] = filter?.array_value?.map((item) => {
        //     return {
        //       label: item,
        //       value: item,
        //     };
        //   });
        // });
        // console.log(lookupValues1);
        // setLookupValue(lookupValues1);
      }
      else {
        updatedData?.forEach((filter) => {
          lookupValues[filter?.filter_label] = filter?.array_value?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
      }


      let selectedValues = {};
      updatedData?.forEach((filter) => {
        selectedValues[filter?.filter_label] = filter?.array_value?.map((item) => {
          return {
            label: item,
            value: stringIsArray(item) ? JSON.parse(item) : item,
          };
        });

        filter?.array_value?.length > 0 ? filter.array_value = stringIsArray(filter.array_value[0]) ? JSON.parse(filter.array_value[0]) : filter.array_value : '';
      });

      //setAllItems(selectedValues);

      axios
        .post(serverConfig.api_base_url + 'canvas_dataset_get_list', {
          token: sessionStorage.getItem('token'),
          org: selectedOrganization?.slug,

          dash: searchParams.get('slug'),
          filter: updatedData,
        },
          { signal: controller.signal, timeout: 10000 })
        .then((response) => {
          if (isCancelled || controller.signal.aborted) {
            console.log('Operation cancelled during response processing');
            return;
          }
          if (response.data.items !== null) {
            setChartData(response?.data?.items);
          }
          if (response.data.status === 200) {
            if (response.data.items !== null) {
              response.data.items?.map((item) => {
                axios
                  .post(item?.url, {
                    fetch_instruction: item?.fetch_instruction,
                  }, { signal: controller.signal })
                  .then((res) => {
                    const updatedData = res.data.items;
                    setChartData((prevData) => {
                      const existingItemIndex = prevData?.findIndex(
                        (dataItem) => dataItem?.cht === item?.cht
                      );
                      return existingItemIndex !== -1
                        ? prevData?.map((dataItem, index) =>
                          index === existingItemIndex ? updatedData : dataItem
                        )
                        : prevData;
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
          } else if (response.data.status >= 400 && response.data.status <= 499) {
          }
          setFilterApply(false);
        })
        .catch((err) => {
          // if (controller.signal.aborted) {
          //   console.log("Request aborted");
          // } else {
          //   console.log(err);
          // }
          if (axios.isCancel(err) || err.code === 'ECONNABORTED') {
            console.log('Request cancelled or timed out');
          } else {
            // Handle other errors
            console.error('Error in API call:', err);
          }
        });
      setNextId(nextId + 1);
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    }
  };


  const handleRemoveFilter = (fltr) => {
    setChartData([]);
    const updatedData = filterData.filter((el) => el?.fltr !== fltr);
    const updatedDynamicFilterData = filterData.filter((el) => el?.fltr !== fltr);
    setSaveFilterValue(null);
    const lookupValues = {};

    updatedData?.forEach((filter) => {
      lookupValues[filter?.filter_label] = filter?.array_value?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    });
    setDynamicFilterData(updatedDynamicFilterData);
    setLookupValue(lookupValues);
    setAllItems(lookupValues);
    setFilterData(updatedData);
    setTaggedFilterData(updatedData);
    axios
      .post(serverConfig.api_base_url + 'canvas_dataset_get_list', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        filter: updatedData,
      })
      .then((response) => {
        setChartData(response.data.items);
        if (response.data.status === 200) {
          response.data.items?.map((item) => {
            axios
              .post(item?.url, {
                fetch_instruction: item?.fetch_instruction,
              })
              .then((res) => {
                const updatedData = res.data.items;
                setChartData((prevData) => {
                  const existingItemIndex = prevData?.findIndex(
                    (dataItem) => dataItem?.cht === item?.cht
                  );
                  return existingItemIndex !== -1
                    ? prevData.map((dataItem, index) =>
                      index === existingItemIndex ? updatedData : dataItem
                    )
                    : prevData;
                });
                setValue('');
                setFilterOperator();
              })
              .catch((err) => {
                console.log(err);
              })
              .finally((fun) => { });
          });
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
        setFilterApply(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setNextId(nextId + 1);
  };

  const handleCallDBFetcher = (url, payload) => {
    axios
      .post(url, {
        fetch_instruction: payload,
      })
      .then((response) => {
        setLookupOptions(response.data.items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCallDBFetcherDynamic = async (url, payload, label, selectAll) => {
    try {
      if (currentLabelRef.current !== label) {
        setLoadingOptions(true);
        currentLabelRef.current = label;
      }

      const response = await axios.post(url, {
        fetch_instruction: payload,
      });

      const formattedOptions = response.data.items?.map((item) => ({
        ...item,
        label: item.label.toString(),
        value: item.value,
      }));

      setOptionsMap((prevOptionsMap) => ({
        ...prevOptionsMap,
        [label]: formattedOptions,
      }));

      if (formattedOptions?.length > 0) {
        setOriginalOptionsMap((prevOptionsMap) => {
          const existingOptions = prevOptionsMap[label] || [];
          const mergedOptions = [
            ...existingOptions,
            ...formattedOptions?.filter(
              (newOption) => !existingOptions.some((existingOption) => existingOption.value === newOption.value)
            ),
          ];
          return {
            ...prevOptionsMap,
            [label]: mergedOptions,
          };
        });
      }
      setCurrentFilterLabel(label);
      const filterExists = filterData?.some(el => el?.filter_label === label) ? 1 : 0;
      if (!filterExists) {
        if (allItems[label] === undefined) {
          if (selectAll === 1) {
            setAllItems({ ...allItems, [label]: formattedOptions });
          }
        }
      }


      setLoadingOptions(false);
    } catch (error) {
      setOptionsMap((prevOptionsMap) => ({
        ...prevOptionsMap,
        [label]: [],
      }));
      setLoadingOptions(false);
    }
  };

  useEffect(() => {
    if (saveFilterValue !== null && token !== null && token !== undefined) {
      axios
        .post(serverConfig.api_base_url + 'canvas_user_filterset_get_value', {
          token: token,
          org: selectedOrganization?.slug,
          dash: dash,
          sfltr: saveFilterValue?.sfltr,
        })
        .then((response) => {
          setFilterData(response?.data?.items);
          setTaggedFilterData(response?.data?.items);
          const lookupValues = {};
          response?.data?.items.forEach((filter) => {
            lookupValues[filter.filter_label] = filter?.array_value?.map(
              (item) => {
                return {
                  label: item,
                  value: item,
                };
              }
            );
          });
          setLookupValue(lookupValues);
          handleSubmit(response?.data?.items, null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [saveFilterValue, token]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '315px',
      cursor: 'pointer',
      pointerEvents: 'auto',
    }),
  };

  const sortOptions = (options, selectedOptions) => {
    const selectedValues = selectedOptions.map((opt) => opt.value);
    return [...options].sort((a, b) => {
      const isSelectedA = selectedValues.includes(a.value);
      const isSelectedB = selectedValues.includes(b.value);
      return isSelectedB - isSelectedA; // Move selected options to the top
    });
  };

  const handleDropdownChange = (label, e, item) => {
    setCurrentFilterLabel(label);
    setMaxCount(item.max_count);
    setNotInThreshold((item.not_in_threshold / 100));

    const originalOptions = originalOptionsMap[label] || []; // Use the unfiltered original options
    if (
      originalOptions.length > 0 &&
      (originalOptions.length === e.length || e.length === 0) // Check against the original options
    ) {
      if (originalOptions.length === e.length) {
        // Select All

        setTimeout(() => {
          setFilterData((prevFilterData) =>
            prevFilterData.filter((filter) => filter.filter_label !== label)
          );
          setTaggedFilterData((prevFilterData) =>
            prevFilterData.filter((filter) => filter.filter_label !== label)
          );
        }, 2000);

        // Ensure full reset and sync
        setAllItems((prev) => ({
          ...prev,
          [label]: originalOptions
        }));

        // Update lookup value to match all options
        setLookupValue((prev) => ({
          ...prev,
          [label]: originalOptions.map(option => ({
            label: option,
            value: option
          }))
        }));
        setSelectedCount(item.max_count);
      } else if (e.length === 0) {
        // Unselect All

        setTimeout(() => {
          setFilterData((prevFilterData) =>
            prevFilterData.filter((filter) => filter.filter_label !== label)
          );
          setTaggedFilterData((prevFilterData) =>
            prevFilterData.filter((filter) => filter.filter_label !== label)
          );
        }, 2000);

        setAllItems((prev) => ({
          ...prev,
          [label]: []
        }));

        setLookupValue((prev) => ({
          ...prev,
          [label]: []
        }));
        setSelectedCount(0);
        const selectedFilter = {
          filter_name: item?.operators[0]?.value,
          filter_label: item?.label,
          optr: item?.operators[0]?.optr,
          filter_operator: item?.operators[0]?.label,
          array_value: [],
          fltr: item?.fil,
          value: null,
          sequence_no: item?.sequence_no
        };
        const mergedFilterData = filterData.filter((el) => el?.fltr !== item?.fil);;
        mergedFilterData.push(selectedFilter);
        handleFetchDataDebounce(mergedFilterData, 'null_selected');
      }
    } else if (originalOptions.length < e.length) {
      // Selected options exceed original length
      setFilterData((prevFilterData) =>
        prevFilterData.filter((filter) => filter.filter_label !== label)
      );
      setTaggedFilterData((prevFilterData) =>
        prevFilterData.filter((filter) => filter.filter_label !== label)
      );

      // Update both allItems and lookupValue
      setAllItems((prev) => ({
        ...prev,
        [label]: e
      }));

      setLookupValue((prev) => ({
        ...prev,
        [label]: e.map(option => ({
          label: option.label,
          value: option.value
        }))
      }));

      setSelectedCount(item.max_count);
    } else {
      // Handle partial selections
      setSelectedCount(e.length);

      setAllItems((prev) => ({
        ...prev,
        [label]: e
      }));

      setLookupValue((prev) => ({
        ...prev,
        [label]: e.map(option => ({
          label: option.label,
          value: option.value
        }))
      }));

      if (e.length === 0) {
        const updatedArray = filterData.filter((el) => el?.fltr !== item?.fil);
        setFilterData((prev) => prev.filter((el) => el?.fltr !== item?.fil));
        setTaggedFilterData((prev) => prev.filter((el) => el?.fltr !== item?.fil));
        setLookupValue((prevValues) => ({
          ...prevValues,
          [label]: e,
        }));
        handleFetchDataDebounce(updatedArray, null);
      } else {
        setLookupValue((prevValues) => ({
          ...prevValues,
          [label]: e,
        }));
        handleFetchDataDebounce(item, e);
      }
    }
  };


  const handleDropdownChangeSingleSelect = (label, e, item) => {
    let records = [e];
    if (records?.length === 0) {
      const updatedArray = filterData.filter((el) => el?.fltr !== item?.fil);
      setFilterData(updatedArray);
      setTaggedFilterData(updatedArray);
      handleSubmit(updatedArray, null);
    } else {
      setLookupValue((prevValues) => ({
        ...prevValues,
        [label]: records,
      }));
      handleSubmit(item, records);
    }

  };

  const handleDropdownChangeSingleSelectUpdate = (label, e, item) => {
    // Extract the selected value as records
    let records = [e];

    if (!e || records?.length === 0) {
      // If no value is selected, remove the filter
      const updatedArray = filterData.filter((el) => el?.filter_label !== label);
      setFilterData(updatedArray);
      setTaggedFilterData(updatedArray);
      handleSubmit(updatedArray, null);
      return;
    }

    // Extract the actual value from the selected record
    const selectedValue = e?.value;

    if (selectedValue === undefined) {
      console.error("Selected value is undefined");
      return;
    }

    // Check if the filter already exists in filterData
    const existingFilterIndex = filterData.findIndex((el) => el?.filter_label === label);

    // Clone filterData to avoid mutating state directly
    let updatedFilterData = [...filterData];

    if (existingFilterIndex >= 0) {
      // Update the existing filter with the new value
      updatedFilterData[existingFilterIndex] = {
        ...updatedFilterData[existingFilterIndex],
        array_value: [selectedValue], // Replace with the new selected value
      };
    } else {
      // Add a new filter if it doesn't exist
      updatedFilterData.push({
        id: item?.id || Math.random(), // Use item's ID or generate a random one
        filter_label: label,
        array_value: [selectedValue], // Set the selected value
        fltr: item?.fil,
        value: null,
        sequence_no: item?.sequence_no
      });
    }

    // Update the state and call handleSubmit
    setFilterData(updatedFilterData);
    setTaggedFilterData(updatedFilterData);
    setLookupValue((prevValues) => ({
      ...prevValues,
      [label]: records,
    }));

    // Pass the selected value to handleSubmit
    handleSubmit(item, { data_limit: selectedValue });
  };


  useEffect(() => {
    function handleClickOutside(event) {
      for (let key in dropdownRefs.current) {
        if (
          dropdownRefs.current[key] &&
          !dropdownRefs.current[key].contains(event.target)
        ) {
          setDropdownsOpen((prev) => ({ ...prev, [key]: false })); // Close specific dropdown
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRefs]);

  const handleDropdownClick = (label) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [label]: true,
    }));
  };

  const loadingOptionShow = [
    { label: 'Loading...', value: 'loading', disabled: true },
  ];

  const [hoveredOption, setHoveredOption] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const lastSearchTermRef = useRef('');

  // const debouncedFetchAdditionalOptions = useRef(
  //   debounce((searchTerm, item, token, time) => {
  //     fetchAdditionalOptions(searchTerm, item, token);
  //   }, time * 1000)
  // ).current;

  const getDebouncedFunctionHandleSubmit = () => {
    return debounce((filterObj, entries) => {
      handleSubmit(filterObj, entries);
    }, 2000);
  };

  const getDebouncedFunction = (time) => {
    return debounce((searchTerm, item, token) => {
      fetchAdditionalOptions(searchTerm, item, token);
    }, time * 1000);
  };

  const handleFetchWithDebounce = (searchTerm, item, token, time) => {
    const debouncedFunction = getDebouncedFunction(time);
    debouncedFunction(searchTerm, item, token);
  };

  const handleFetchDataDebounce = (filterObj, entries) => {
    const debouncedFunction = getDebouncedFunctionHandleSubmit();
    debouncedFunction(filterObj, entries);
  }

  const fetchAdditionalOptions = (searchTerm, item, userToken) => {
    const filter = {
      filter_label: item?.label,
      fltr: item?.fil,
      optr: item?.filter_search_operator[0]?.optr,
      type: item?.type,
      filter_operator: item?.filter_search_operator[0]?.label,
      value: searchTerm
    }

    const updatedFilterData = [...filterData, filter];

    axios.post(serverConfig.api_base_url + "canvas_search_filter_get_list", {
      dash: dash,
      org: selectedOrganization?.slug,
      token: userToken,
      filter: updatedFilterData
    }).then(response => {

      response.data.items.map(record => {
        if (record?.fil === item.fil) {
          handleCallDBFetcherDynamic(
            record.operators[0]?.url,
            record.operators[0]
              ?.fetch_instruction,
            record.label
          );
        }
      })

    }).catch(err => {
      console.log(err);
    })
  }

  const CustomSingleValue = (props) => {
    const { data, selectProps } = props;
    const itemLabel = selectProps.customLabel;

    return (
      <components.SingleValue {...props}>
        {`${itemLabel} : ${data.label}`}
      </components.SingleValue>
    );
  };

  const CustomOption = (props) => {
    // Log the props to inspect what is being passe

    const handleEditClick = (event) => {
      event.stopPropagation();
      props.onEdit(props.data); // Use the passed `onEdit` function
    };

    const handleDeleteClick = (event) => {
      event.stopPropagation();
      props.onDelete(props.data); // Use the passed `onDelete` function
    };

    const toggleMenu = (event) => {
      event.stopPropagation();
      setShowMenu(!showMenu); // Assuming `setShowMenu` is defined elsewhere
    };



    return (
      <components.Option {...props}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '3px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '20px',
              }}
            >
              {props.data.publish_to_all_dashboards === 1 && (
                <i className="fa-solid fa-thumbtack"
                  style={{
                    fontSize: '10px',
                    marginRight: '3px',
                    color: '#b5b5b5'
                  }}
                  title="Published to all dashboards"></i>
              )}
              {props.data.publish_to_all_users === 1 && (
                <i className="fa-solid fa-user-group"
                  style={{ fontSize: '10px', color: '#b5b5b5' }}
                  title="Published to all users"></i>
              )}
            </div>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '250px',
                textAlign: 'left',
                marginLeft: '2px',
              }}
              title={props.label}
            >
              {props.label}
            </span>
          </div>
          {props.data.allow_edit_delete === 1 && (
            <div className="flex">
              <div
                style={{ marginRight: '10px', cursor: 'pointer', fontSize: '12px', color: '#828282' }}
                onClick={handleEditClick}
                title='Edit Filter'
              >
                <i className="fa-solid fa-pencil"></i>
              </div>
              <div
                style={{ cursor: 'pointer', fontSize: '12px', color: '#828282' }}
                onClick={handleDeleteClick}
                title='Delete Filter'
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </components.Option>
    );
  };

  const handleEdit = (data) => {
    // Implement the edit functionality here 
    setFilterEditData(data);
    setFilterName(data.label);
    setPublishToAllDashboards(data.publish_to_all_dashboards === 1);
    setPublishToAllUsers(data.publish_to_all_users === 1);

    axios
      .post(serverConfig.api_base_url + 'user_check_privilege', {
        token: token,
        org: selectedOrganization?.slug,
        privilege: 'filter_publish_all_user',
      })
      .then((response) => {
        if (response.data.status === 200) {
          setIsPublishPrivileged(response.data.is_privileged);
        };
      })
      .catch((err) => {
        console.log(err);
      });
    setFilterEdit(true);
  };

  const handleSavedFilterUpdate = () => {
    let publish_to_all_dashboards = publishToAllDashboards === true ? 1 : 0;
    let publish_to_all_users = publishToAllUsers === true ? 1 : 0;
    axios
      .post(serverConfig.api_base_url + 'canvas_update_filterset', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        fltrst: filterEditData.sfltr,
        publish_to_all_users: publish_to_all_users,
        publish_to_all_dashboards: publish_to_all_dashboards,
        filter_name: filterName,
      })
      .then((response) => {
        if (response.data.status === 200) {
          savedFilterListGet();
          setFilterName('');
          setFilterEdit(false);
          setIsPublishPrivileged(0);
          setPublishToAllDashboards(false);
          setPublishToAllUsers(false);
          setFilterEditData({});
        };
      })
      .catch((err) => {
        console.log(err);
      });

  }

  const handleDelete = (data) => {
    // Implement the delete functionality here 
    setFilterName(data.label);
    setFilterEditData(data);
    setFilterDelete(true);
  };

  const handleSavedFilterDelete = () => {
    axios
      .post(serverConfig.api_base_url + 'canvas_delete_filterset', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        fltrst: filterEditData.sfltr,
      })
      .then((response) => {
        if (response.data.status === 200) {
          savedFilterListGet();
          setFilterName('');
          setFilterDelete(false);
          setFilterEditData({});
        };
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const customPlaceholder = (props) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px', // Adds spacing between the icon and text
          color: '#aaa', // Placeholder color
        }}
      >
        <i
          className="fa-solid fa-thumbtack"
          style={{
            fontSize: '14px', // Adjust icon size to match text
          }}
        ></i>
        <span style={{ fontSize: '14px' }}>{props.children}</span>
      </div>
    );
  };

  const extractSlug = (urlString) => {
    try {
      const url = new URL(urlString.includes('://') ? urlString : `http://example.com/${urlString}`);
      return url.searchParams.get('slug');
    } catch (error) {
      const slugIndex = urlString.indexOf('slug=');
      if (slugIndex !== -1) {
        return urlString.substring(slugIndex + 5);
      }
      return null;
    }
  };

  const handleShowPopupDashboard = (dashboardSlug) => {

    axios
      .post(serverConfig.api_base_url + 'canvas_dashboard_get_details', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dashboardSlug,
      })
      .then((response) => {
        setPopupDashboardTitle(response.data.items.name);
        axios
          .post(serverConfig.api_base_url + 'canvas_filter_get_list', {
            token: token,
            org: selectedOrganization?.slug,
            dash: dashboardSlug,
            filter: [],
          })
          .then((res) => {
            if (res.data.status === 200) {
              setPopupFilterData(res.data.default_filter);
              axios
                .post(serverConfig.api_base_url + 'canvas_dataset_get_list', {
                  token: sessionStorage.getItem('token'),
                  org: selectedOrganization?.slug,
                  dash: dashboardSlug,
                  filter: res.data.default_filter,
                }).then((response) => {
                  if (response.data.items !== null) {
                    setPopupChartData(response?.data?.items);
                  }
                  if (response.data.status === 200) {
                    if (response.data.items !== null) {
                      response.data.items?.map((item) => {
                        axios
                          .post(item?.url, {
                            fetch_instruction: item?.fetch_instruction,
                          },)
                          .then((res) => {
                            const updatedData = res.data.items;
                            setPopupChartData((prevData) => {
                              const existingItemIndex = prevData?.findIndex(
                                (dataItem) => dataItem?.cht === item?.cht
                              );
                              return existingItemIndex !== -1
                                ? prevData?.map((dataItem, index) =>
                                  index === existingItemIndex ? updatedData : dataItem
                                )
                                : prevData;
                            });
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      });
                    }
                  } else if (response.data.status >= 400 && response.data.status <= 499) {
                  }
                })
                .catch((err) => {

                })
            }
          }).then(err => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div>
        <div className={collapse ? 'content-body' : 'content-body-collapse'}>
          <div class='container-fluid'>
            <div className=''>
              {/* -- */}
              {!dashboardLoader ? (
                dashboardList !== null ? (
                  <div
                    className={`${filterClose
                      ? `table-custom-dashboard`
                      : `table-custom-dashboard-closed`
                      } p-3 mt-4 flex flex-row`}
                    style={{ overflow: 'hidden' }}
                  >
                    <div style={filterClose ? {} : { width: '103%' }}>
                      {' '}
                      {/* Width gap for filter button when closed */}
                      <div className='flex flex-row justify-between'>
                        <div
                          className='dashboard-heading'
                          style={{ textAlign: 'left', margin: '10px' }}
                        >
                          {dashboardTitle}
                        </div>
                      </div>
                      <div
                        className='flex flex-row flex-wrap'
                        style={{ gap: '5px' }}
                      >
                        {filterData?.length > 0 ? (
                          <>
                            {taggedFilterData
                              .slice() // Create a shallow copy to avoid mutating the original array
                              .sort((a, b) => a.sequence_no - b.sequence_no) // Sort in ascending order
                              .map((item, index) => {
                                return (
                                  <div className="flex flex-wrap ml-4 mt-2" style={{ maxWidth: '100%' }}>
                                    {item?.filter_operator !== 'Between' &&
                                      (item?.value !== null || item?.array_value?.length > 0) ? (
                                      <div
                                        className="status mx-1 relative"
                                        style={{
                                          backgroundColor: 'var(--primary-opactiy-30)',
                                          borderColor: 'var(--primary-opactiy-90)',
                                          maxWidth: '350px',
                                          position: 'relative',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                        title={`${item?.filter_label} ${item?.filter_operator} : ${item?.value === null ? item?.array_value?.join(', ') : item?.value
                                          }`}
                                      >
                                        {!item?.pinned_slug && (
                                          <button
                                            type="button"
                                            className="btn-remove"
                                            title="Remove"
                                            onClick={() => handleRemoveFilter(item?.fltr)}
                                          >
                                            <i className="fa-solid fa-xmark"></i>
                                          </button>
                                        )}

                                        <span
                                          style={{
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            flexGrow: 1,
                                            marginLeft: '5px',
                                          }}
                                        >
                                          {item?.filter_label} {item?.filter_operator} :{' '}
                                          {item?.value === null ? item?.array_value?.join(', ') : item?.value}
                                        </span>

                                        <button
                                          type="button"
                                          className="pin-btn"
                                          title="Pin"
                                          style={{
                                            color: item?.pinned_slug ? 'black' : 'gray',
                                            transform: item?.pinned_slug ? 'rotate(0deg)' : 'rotate(60deg)',
                                          }}
                                          onClick={() =>
                                            !item?.pinned_slug ? handlePinFilter(item) : handleUnpinFilter(item)
                                          }
                                        >
                                          <i className="fa-solid fa-thumbtack"></i>
                                        </button>
                                      </div>
                                    ) : item?.value !== null || item?.array_value?.length > 0 ? (
                                      <div
                                        className="status mx-1 relative"
                                        style={{
                                          backgroundColor: 'var(--primary-opactiy-30)',
                                          borderColor: 'var(--primary-opactiy-90)',
                                          maxWidth: '350px',
                                          position: 'relative',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                        title={`${item?.filter_label} ${item?.filter_operator} : ${item?.value === null ? item?.array_value?.join(', ') : item?.value
                                          }`}
                                      >
                                        {!item?.pinned_slug && (
                                          <button
                                            type="button"
                                            className="btn-remove"
                                            title="Remove"
                                            onClick={() => handleRemoveFilter(item?.fltr)}
                                          >
                                            <i className="fa-solid fa-xmark"></i>
                                          </button>
                                        )}
                                        <span
                                          style={{
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            flexGrow: 1,
                                            marginLeft: '5px',
                                          }}
                                        >
                                          {item?.filter_label} {item?.filter_operator} :{' '}
                                          {`${item?.array_value[0]} and ${item?.array_value[1]}`}
                                        </span>

                                        <button
                                          type="button"
                                          className="pin-btn"
                                          title="Pin"
                                          style={{
                                            color: item?.pinned_slug ? 'black' : 'gray',
                                            transform: item?.pinned_slug ? 'rotate(0deg)' : 'rotate(60deg)',
                                          }}
                                          onClick={() =>
                                            !item?.pinned_slug ? handlePinFilter(item) : handleUnpinFilter(item)
                                          }
                                        >
                                          <i className="fa-solid fa-thumbtack"></i>
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className='grid-container overflow-y-scroll overflow-x-scroll'
                        style={{ height: '81vh', marginRight: '18px' }}
                      >
                        {chartData?.map((item) => {
                          return (
                            <>
                              <div
                                className='grid-item'
                                style={item?.opacity === "0" ? {
                                  padding: '0px',
                                  fontSize: '30px',
                                  //height: "500px",
                                  textAlign: 'center',
                                  gridRow: `${item?.grid_row}`,
                                  gridColumn: `${item?.grid_column}`,
                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                  color: 'black',
                                } : item?.type === "number-1" ? {
                                  padding: '0px',
                                  fontSize: '30px',
                                  //height: "500px",
                                  textAlign: 'center',
                                  gridRow: `${item?.grid_row}`,
                                  gridColumn: `${item?.grid_column}`,
                                  opacity: '100',
                                  color: 'black',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                } :
                                  {
                                    padding: '0px',
                                    fontSize: '30px',
                                    //height: "500px",
                                    textAlign: 'center',
                                    gridRow: `${item?.grid_row}`,
                                    gridColumn: `${item?.grid_column}`,
                                    opacity: '100',
                                    color: 'black'
                                  }}
                              >
                                {item?.type === 'bar' ? (
                                  <AdjustableChart
                                    filterData={filterData}
                                    handleSubmit={handleSubmit}
                                    apiData={item}
                                    item={item}
                                    setOpenDeleteChartModal={''}
                                    setOpenEditChartModal={''}
                                    currPage={currPage}
                                    defaultType='bar'
                                  />
                                ) : item?.type === 'line' ? (
                                  <AdjustableChart
                                    apiData={item}
                                    item={item}
                                    setOpenDeleteChartModal={''}
                                    setOpenEditChartModal={''}
                                    currPage={currPage}
                                    defaultType='line'
                                  />
                                ) : item?.type === 'pie' ? (
                                  <RCPieChart
                                    filterData={filterData}
                                    handleSubmit={handleSubmit}
                                    item={item}
                                    apiData={item}
                                    currPage={currPage}
                                    setOpenDeleteChartModal={''}
                                    setOpenEditChartModal={''}
                                  />
                                ) : item?.type === 'number-1' ? (
                                  <NumberCard
                                    item={item}
                                    data={item?.data}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'table' ? (
                                  <TableChart
                                    data={item?.data}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'vector_map' ? (
                                  <VectorMap
                                    data={item?.data}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'statum_vector_map' ? (
                                  <StatumVectorMap
                                    data={item?.data}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'multiple_line_bank_performance' ? (
                                  <StatumRCMultiLineChart
                                    apiData={item}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'map' ? (
                                  <Map
                                    data={item?.data}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'google_map_with_boundaries' ? (
                                  <GoogleMapWithBoundaries
                                    data={item?.data}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'multiple_bar' ? (
                                  <RCMultipleBarChart
                                    apiData={item}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'multiple_line' ? (
                                  <RCMultiLineChart
                                    apiData={item}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'stacked_bar' ? (
                                  <RCStackedBarChart
                                    apiData={item}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'percent_area' ? (
                                  <RCPercentAreaChart
                                    apiData={item}
                                    item={item}
                                    currPage={currPage}
                                  />
                                ) : item?.type === 'sub_title' ? (
                                  <SubTitle item={item} currPage={currPage} />
                                ) : item?.type === 'legend' ? (
                                  <Legend item={item} currPage={currPage} />
                                ) : item?.type === 'html_from_sql' ? (
                                  <SummaryChart item={item} data={item?.data} currPage={currPage} />
                                ) : item?.type === 'mind_map' ? (
                                  <ReactFlowProvider>
                                    <MindMap
                                      apiData={item}
                                      item={item}
                                      currPage={currPage}
                                    />
                                  </ReactFlowProvider>
                                ) : //put conditions for other types of charts
                                  null}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>

                    {/* filters side bar */}
                    {filterClose === true ? (
                      <div className='grid'>
                        {/* Main content */}
                        <div
                          className={`filter-content ${filterClose ? 'open' : ''
                            } `}
                        >
                          <div className='content-view-filters'>
                            <div className='flex flex-row justify-between items-center gap-1 ml-2'>


                              <h1 className='font-bold text-lg ml-2 mb-2'>
                                Filters
                              </h1>
                              <div>
                                {/* Info Icon */}
                                <i
                                  className="fa-solid fa-circle-info"
                                  style={{ cursor: "pointer", color: "var(--primary)", marginBottom: "12px" }}
                                  onClick={() => setIsOpen(true)} // Open popup on click
                                ></i>


                              </div>
                              <div className='flex-grow'></div>
                              {/* This will take up the remaining space */}
                              {savedFilterLookup?.length > 0 && (
                                <div className='mt-1 mb-2 ml-1'>
                                  <Select
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        width: '190px',
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        width: '350px',
                                        left: '-80px',
                                      }),
                                    }}
                                    components={{
                                      Option: (props) => <CustomOption {...props} onEdit={handleEdit} onDelete={handleDelete} />
                                    }}
                                    classNamePrefix='custom-select'
                                    placeholder='Saved Filters'
                                    value={saveFilterValue}
                                    options={savedFilterLookup}
                                    onChange={(e) => { setSaveFilterValue(e); }}
                                    required
                                  />
                                </div>
                              )}
                              <div className='flex justify-center gap-2 mb-1'>
                                <button
                                  type='submit'
                                  className='ml-2 mt-1'
                                  onClick={() => {
                                    setFilterSave(true);
                                    axios
                                      .post(serverConfig.api_base_url + 'user_check_privilege', {
                                        token: token,
                                        org: selectedOrganization?.slug,
                                        privilege: 'filter_publish_all_user',
                                      })
                                      .then((response) => {
                                        if (response.data.status === 200) {
                                          setIsPublishPrivileged(response.data.is_privileged);
                                        };
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }}
                                  disabled={filterData?.length > 0 ? false : true}
                                  title='Save Filters'
                                >
                                  <FontAwesomeIcon
                                    icon={faSave}
                                    style={{ fontSize: '26px' }}
                                  />
                                </button>
                              </div>
                              <div className='flex justify-center gap-2 mb-1'>
                                <button
                                  type='submit'
                                  className='ml-2 mt-1'
                                  onClick={() => {
                                    setFilterData([]);
                                    setTaggedFilterData([]);
                                    setLookupValue([]);
                                    setSaveFilterValue(null);
                                    setDynamicFilterData([]);
                                    setAllItems({});
                                    setOriginalOptionsMap({});
                                    setOptionsMap({});
                                  }}
                                  disabled={filterData?.length > 0 ? false : true}
                                  title='Reset Filters'
                                >
                                  <FontAwesomeIcon
                                    icon={faUndo}
                                    style={{ fontSize: '24px' }}
                                  />
                                </button>
                              </div>
                            </div>

                            <button
                              className='button-toggle-filter'
                              onClick={() => {
                                setFilterClose(false);
                              }}
                            >
                              <div
                                className='rounded-full circle bg-white h-5 w-5'
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {/* {collapse? */}
                                <div>
                                  <button
                                    type='button'
                                    class='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium'
                                    onClick={() => { }}
                                    title='filter data for dashboard'
                                    style={{ margin: '10px' }}
                                  >
                                    <div className='flex flex-col'>
                                      <i className='fa-solid fa-filter'></i>
                                      <i class='fa-solid fa-arrow-right text-xs'></i>
                                    </div>
                                  </button>
                                </div>
                                {/* <i class="fa-solid fa-arrow-left text-xs"></i> */}
                                {/* :   <i class="fa-solid fa-arrow-right text-xs"></i>} */}
                              </div>
                            </button>
                            <div className='filter-top-container-bottom-border'></div>

                            <div
                              className='log-section'
                              style={{ height: '68vh', overflowY: 'auto' }}
                            >
                              {staticLookupOptions?.length > 0 &&
                                staticLookupOptions?.map((item, index) => {
                                  let customScale = createCustomScale(item?.segmentation);
                                  return (
                                    <>
                                      {item?.type === 'lookup' && item?.operators[0]?.is_multiselect === 1 ? (
                                        <div
                                          key={index}
                                          className='mt-4 mb-2 ml-4 mr-24'
                                          ref={(el) =>
                                          (dropdownRefs.current[item.label] =
                                            el)
                                          } // Use refs for each dropdown
                                          onClick={() => {
                                            handleCallDBFetcherDynamic(
                                              item.operators[0]?.url,
                                              item.operators[0]
                                                ?.fetch_instruction,
                                              item.label, item?.select_all_default
                                            );
                                            setSelectedCount(item.max_count);
                                            handleDropdownClick(item.label);
                                            setLookupValue((prevValues) => ({
                                              ...prevValues,
                                              [item?.label]: [],
                                            }));
                                          }}
                                        >

                                          <div key={item.label} style={{ marginBottom: "10px", position: "relative" }}>
                                            {/* Error message ABOVE the MultiSelect dropdown */}
                                            {countMessage[item.label] && (
                                              <div style={{
                                                color: "red",
                                                fontSize: "12px",
                                                marginBottom: "5px",
                                                marginTop: "5px",
                                                position: "absolute",
                                                top: "-22px" // Moves the message above the dropdown
                                              }}>
                                                {countMessage[item.label]}
                                              </div>
                                            )}

                                            <MultiSelect
                                              closeMenuOnSelect={false}
                                              hasSelectAll={!item.multiselect_max_selectable}
                                              value={(allItems && allItems[item.label]?.length > 0) ? allItems[item.label] : lookupValue[item.label] || []}
                                              options={
                                                loadingOptions
                                                  ? loadingOptionShow
                                                  : sortOptions(optionsMap[item.label] || [], allItems[item.label] || [])
                                              }
                                              onChange={(e) => {
                                                if (!item.is_pinned) {
                                                  const maxSelection = item.multiselect_max_selectable || Infinity;
                                                  if (e.length <= maxSelection) {
                                                    handleDropdownChange(item?.label, e, item);
                                                    setCountMessage((prev) => ({ ...prev, [item.label]: "" })); // Clear error when valid
                                                  } else {
                                                    setCountMessage((prev) => ({
                                                      ...prev,
                                                      [item.label]: `Maximum ${item?.multiselect_max_selectable} selections allowed.`,
                                                    }));
                                                  }
                                                }
                                              }}
                                              isOpen={!item.is_pinned && (dropdownsOpen[item.label] || false)}
                                              onMenuOpen={() => {
                                                if (item.is_pinned) {
                                                  return;
                                                }
                                              }}
                                              className={`custom-multiselect-branch ${item.is_pinned ? "pinned-dropdown" : ""}`}
                                              valueRenderer={(selected, _options) => {
                                                const pinIcon = item.is_pinned ? (
                                                  <span style={{ marginRight: "8px", color: "black" }}>
                                                    <i className="fa-solid fa-thumbtack"></i>
                                                  </span>
                                                ) : null;

                                                if (selected?.length === 0) {
                                                  return (
                                                    <>
                                                      {pinIcon}
                                                      {item.multiselect_max_selectable
                                                        ? `${item.label} (Max ${item.multiselect_max_selectable} selections)`
                                                        : item.label}
                                                    </>
                                                  );
                                                } else {
                                                  const selectedLabels = selected.map((option) => option.label).join(", ");
                                                  return (
                                                    <>
                                                      {pinIcon}
                                                      {`${item?.label} : ${selectedLabels}`}
                                                    </>
                                                  );
                                                }
                                              }}
                                            />
                                          </div>

                                          {/* <MultiSelect
                                            closeMenuOnSelect={false}
                                            //hasSelectAll={true}
                                            hasSelectAll={!item.multiselect_max_selectable}
                                            value={(allItems && allItems[item.label]?.length > 0) ? allItems[item.label] : lookupValue[item.label] || []}
                                            options={
                                              loadingOptions
                                                ? loadingOptionShow
                                                : sortOptions(optionsMap[item.label] || [], allItems[item.label] || [])
                                            }
                                            onChange={(e) => {
                                              if (!item.is_pinned) {
                                                const maxSelection = item.multiselect_max_selectable || Infinity;
                                                if (e.length <= maxSelection) {
                                                  handleDropdownChange(item?.label, e, item);
                                                }
                                                //handleDropdownChange(item?.label, e, item);
                                              }
                                            }}
                                            isOpen={!item.is_pinned && (dropdownsOpen[item.label] || false)} // Prevent opening if pinned
                                            onMenuOpen={() => {
                                              if (item.is_pinned) {
                                                return; // Prevent menu from opening
                                              }
                                            }}
                                            // filterOptions={(options, searchTerm) => {
                                            //   if (!item.is_pinned) {
                                            //     if (searchTerm.length === item?.search_char_length && searchTerm !== lastSearchTermRef.current) {
                                            //       fetchAdditionalOptions(searchTerm, item, token);
                                            //       lastSearchTermRef.current = searchTerm;
                                            //     } else if (searchTerm.length >= item?.search_char_length && searchTerm !== lastSearchTermRef.current) {
                                            //       handleFetchWithDebounce(searchTerm, item, token, item?.search_delay_time);
                                            //       lastSearchTermRef.current = searchTerm;
                                            //     }
                                            //   }
                                            //   return options;
                                            // }}
                                            className={`custom-multiselect-branch ${item.is_pinned ? 'pinned-dropdown' : ''}`}
                                            valueRenderer={(selected, _options) => {
                                              const pinIcon = item.is_pinned ? (
                                                <span style={{ marginRight: '8px', color: 'black' }}><i className='fa-solid fa-thumbtack'></i></span> // Pin icon
                                              ) : null;
                                              if (selected?.length === 0) {
                                                return (
                                                  <>
                                                    {pinIcon}
                                                    {item?.label}
                                                  </>
                                                );
                                              } else {
                                                const selectedLabels = selected
                                                  .map((option) => option.label)
                                                  .join(', ');
                                                return (
                                                  <>
                                                    {pinIcon}
                                                    {`${item?.label} : ${selectedLabels}`}
                                                  </>
                                                );
                                              }
                                            }}
                                            overrideStrings={{
                                              selectSomeItems: item.multiselect_max_selectable 
                                                ? `Select up to ${item.multiselect_max_selectable} ${item?.label}` 
                                                : `${item?.label}`, // Dynamic text for selection limit
                                            }}
                                            // overrideStrings={{
                                            //   selectSomeItems: `${item?.label}`,
                                            // }}
                                          // onInputChange={(searchTerm) => {
                                          //   if (!item.is_pinned && searchTerm.length >= item?.search_char_length && searchTerm !== lastSearchTermRef.current) {
                                          //     fetchAdditionalOptions(searchTerm, item, token);
                                          //     lastSearchTermRef.current = searchTerm;
                                          //   }
                                          // }}
                                          /> */}



                                        </div>
                                      ) :

                                        item?.type === 'lookup' && item?.operators[0]?.is_multiselect === 0 ? (
                                          <>
                                            <div
                                              key={index}
                                              className='mt-4 mb-2 ml-4 flex flex-row'
                                              ref={(el) => (dropdownRefs.current[item.label] = el)} // Use refs for each dropdown
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                handleCallDBFetcherDynamic(item.operators[0]?.url, item.operators[0]?.fetch_instruction, item.label);
                                                handleDropdownClick(item.label);
                                              }}
                                            >
                                              {item?.is_pinned && <span style={{ marginRight: '10px', marginTop: "9px", color: 'black' }}><i className='fa-solid fa-thumbtack'></i></span>} {/* Pin Icon */}
                                              <Select
                                                styles={customStyles}
                                                classNamePrefix='select'
                                                className='filter-dropdown-single-select'
                                                placeholder={item?.label}
                                                components={{ SingleValue: CustomSingleValue }}
                                                customLabel={item?.label}
                                                value={lookupValue[item.label] || []}
                                                options={loadingOptions ? loadingOptionShow : optionsMap[item.label] || []}
                                                onChange={(e) => handleDropdownChangeSingleSelect(item?.label, e, item)}
                                                // Disable if isPinned is true
                                                isDisabled={item?.is_pinned}
                                              />

                                            </div>
                                          </>
                                        ) : item?.type === 'numeric' && item?.operators[0]?.value === 'between' ? (
                                          <div className="mt-6 mb-6 ml-5 mr-24">
                                            <Typography
                                              id="range-slider"
                                              sx={{
                                                fontWeight: "600 !important",
                                                fontFamily: "Inter !important",
                                                fontSize: "12px",
                                              }}
                                              gutterBottom
                                            >
                                              {item?.label}
                                              {item?.is_pinned && (
                                                <span style={{ marginLeft: "8px", color: "black" }}>
                                                  <i className="fa-solid fa-thumbtack"></i>
                                                </span>
                                              )}
                                            </Typography>
                                            <Box sx={{ width: 280 }}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  marginBottom: "10px",
                                                  width: "296px",
                                                }}
                                              >
                                                <TextField
                                                  className="slider-text-field mr-2"
                                                  type="number"
                                                  value={
                                                    sliderInputValueChange
                                                      ? quickFiltersMap[item?.label]?.min
                                                      : customScale(item?.operators[0]?.options?.selected_min)
                                                  }

                                                  // value={
                                                  //    quickFiltersMap[item?.label]?.min ||
                                                  //    customScale(item?.operators[0]?.options?.selected_min)
                                                  // }
                                                  onChange={(event) => handleMinInputChange(event, item, customScale)}
                                                  variant="outlined"
                                                  size="small"
                                                  sx={{
                                                    marginLeft: "10px",
                                                    "& .MuiOutlinedInput-root": {
                                                      "& fieldset": {
                                                        border: "2px solid #E3E9F4 !important",
                                                        borderRadius: "9px !important",
                                                      },
                                                    },
                                                  }}
                                                  disabled={item?.is_pinned}
                                                />
                                                <div className="text-sm m-2 ml-4 mr-4">to</div>
                                                <TextField
                                                  className="slider-text-field"
                                                  type="number"
                                                  value={
                                                    sliderInputValueChange
                                                      ? quickFiltersMap[item?.label]?.max
                                                      : customScale(item?.operators[0]?.options?.selected_max)
                                                  }
                                                  onChange={(event) => handleMaxInputChange(event, item, customScale)}
                                                  variant="outlined"
                                                  size="small"
                                                  sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                      "& fieldset": {
                                                        border: "2px solid #E3E9F4 !important",
                                                        borderRadius: "9px !important",
                                                      },
                                                    },
                                                  }}
                                                  disabled={item?.is_pinned}
                                                />
                                              </div>
                                              <Slider value={
                                                [scaledToSliderValue(quickFiltersMap[item?.label]?.min || item?.operators[0]?.options?.selected_min, item?.segmentation),
                                                scaledToSliderValue(quickFiltersMap[item?.label]?.max || item?.operators[0]?.options?.selected_max, item?.segmentation),
                                                ]}
                                                onChange={(event, newValue) => {
                                                  console.log(`Slider Dragged: ${newValue}`);

                                                  // Directly use the slider values to create scaled values
                                                  const scaledValues = newValue.map(sliderValue => {
                                                    // Find the appropriate segment for the slider value
                                                    for (const segment of item?.segmentation) {
                                                      const [rangeStart, rangeEnd] = segment.range;
                                                      const [scaleStart, scaleEnd] = segment.scale;

                                                      if (sliderValue >= rangeStart && sliderValue <= rangeEnd) {
                                                        // Normalize within the segment's range
                                                        const normalized = (sliderValue - rangeStart) / (rangeEnd - rangeStart);

                                                        // Map to the segment's scale
                                                        return scaleStart + normalized * (scaleEnd - scaleStart);
                                                      }
                                                    }

                                                    // Fallback if no segment matches
                                                    return sliderValue;
                                                  });

                                                  handleChange(item, event, scaledValues, customScale);
                                                }}
                                                onChangeCommitted={handleChangeCommitted} min={item?.segmentation[0].range[0]}
                                                max={item?.segmentation[item?.segmentation?.length - 1].range[1]}
                                                scale={customScale} // Ensure it applies correctly    
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => {

                                                  // Find the appropriate segment
                                                  const segment = item?.segmentation.find(seg =>
                                                    value >= seg.range[0] && value <= seg.range[1]
                                                  );

                                                  if (segment) {
                                                    const [rangeStart, rangeEnd] = segment.range;
                                                    const [scaleStart, scaleEnd] = segment.scale;

                                                    // Linear interpolation
                                                    const normalized = (value - rangeStart) / (rangeEnd - rangeStart);
                                                    const scaledValue = scaleStart + normalized * (scaleEnd - scaleStart);

                                                    return Math.round(scaledValue);
                                                  }

                                                  return Math.round(value);
                                                }}
                                                //valueLabelFormat={(value) => Math.round(customScale(value))}
                                                sx={{
                                                  mx: 2,
                                                  flexGrow: 1,
                                                  color: "primary.main",
                                                  "& .MuiSlider-thumb": {
                                                    backgroundColor: "var(--primary)",
                                                    height: 20,
                                                    width: 10,
                                                    borderRadius: 2,
                                                  },
                                                  "& .MuiSlider-track": { backgroundColor: "var(--primary)", border: "1px solid var(--primary)" },
                                                  "& .MuiSlider-rail": { backgroundColor: "grey.400" },
                                                }}
                                                disabled={item?.is_pinned}
                                              />

                                            </Box>
                                          </div>

                                        ) : item?.type === 'switch' ? (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* {item?.filter_info && <i class="fa-solid fa-eye"></i>} */}


                                              {item?.filter_info && item?.filter_info?.show_info && (
                                                <div style={{ marginRight: '12px' }}>
                                                  <i class="fa-solid fa-circle-info"
                                                    data-tooltip-id="filterTooltip"
                                                    data-tooltip-place="top"
                                                    style={{ cursor: "pointer", color: "var(--primary)" }}
                                                  ></i>
                                                  <Tooltip
                                                    id="filterTooltip"
                                                    interactive={true}
                                                    delayHide={1000}
                                                    className="solid-tooltip"
                                                    classNameArrow="solid-tooltip-arrow"
                                                    style={{
                                                      backgroundColor: "white",
                                                      color: "black",
                                                      padding: "10px",
                                                      borderRadius: "5px",
                                                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                      opacity: "1",
                                                      pointerEvents: "auto"
                                                    }}
                                                  >
                                                    <div>{item?.filter_info?.description}</div>
                                                    <button
                                                      onClick={() => { setIsPopupOpen(true); handleShowPopupDashboard(extractSlug(item?.filter_info?.value)); }}
                                                      style={{
                                                        textDecoration: "underline", color: "blue", cursor: "pointer",
                                                        display: "block",
                                                        marginTop: "5px"
                                                      }}
                                                    >
                                                      Click here to know more
                                                    </button>

                                                  </Tooltip>
                                                </div>
                                              )}

                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "space-between",
                                                  width: "310px",
                                                  padding: "10px",
                                                  borderRadius: "8px",
                                                  backgroundColor: "white",
                                                  opacity: item?.is_pinned ? 0.6 : 1, // Dim the component when disabled
                                                  pointerEvents: item?.is_pinned ? "none" : "auto", // Disable interaction when pinned
                                                }}
                                              >
                                                <span style={{ display: "flex", alignItems: "center" }}>
                                                  {item?.is_pinned && (
                                                    <span style={{ marginRight: "8px", marginLeft: "-15px", color: "black" }}>
                                                      <i className="fa-solid fa-thumbtack"></i>
                                                    </span>
                                                  )}
                                                  {item?.label}
                                                </span>
                                                <div
                                                  onClick={(e) => {
                                                    if (!item?.is_pinned) {
                                                      handleToggle(item);
                                                    }
                                                  }}
                                                  style={{
                                                    position: "relative",
                                                    width: "50px",
                                                    height: "24px",
                                                    backgroundColor: toggleValue[item?.label] ? "var(--primary)" : "#ccc",
                                                    borderRadius: "24px",
                                                    cursor: item?.is_pinned ? "not-allowed" : "pointer", // Show disabled cursor
                                                    transition: "background-color 0.3s",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      top: "2px",
                                                      left: toggleValue[item?.label] ? "26px" : "2px",
                                                      width: "20px",
                                                      height: "20px",
                                                      backgroundColor: "white",
                                                      borderRadius: "50%",
                                                      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                                      transition: "left 0.3s",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      fontSize: "8px",
                                                      color: toggleValue[item?.label] ? "black" : "#ccc",
                                                    }}
                                                  >
                                                    {toggleValue[item?.label] ? "YES" : "NO"}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) :
                                          item?.type === 'divider' && item?.operators[0]?.is_multiselect === 0 ? (
                                            <div key={index} className="divider-section mt-4 mb-4 pl-4 pr-2">
                                              <hr className="border-t border-gray-900 w-full" />
                                            </div>
                                          ) : item?.type === 'divider_text' && item?.operators[0]?.is_multiselect === 0 ? (
                                            <div key={index} className="divider-section mt-4 mb-4">
                                              <h4 className="font-bold text-md text-gray-700 ml-4">
                                                {item.label}
                                                {item?.is_pinned && <span style={{ marginLeft: '8px', color: 'black' }}><i className='fa-solid fa-thumbtack'></i></span>} {/* Pin Icon */}
                                              </h4>
                                            </div>
                                          ) : item?.type === 'data_limit' && item?.operators[0]?.is_multiselect === 0 ? (
                                            <>
                                              <div
                                                key={index}
                                                className='mt-4 mb-2 ml-4 flex flex-row'
                                                ref={(el) => (dropdownRefs.current[item.label] = el)} // Use refs for each dropdown
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  handleCallDBFetcherDynamic(item.operators[0]?.url, item.operators[0]?.fetch_instruction, item.label);
                                                  handleDropdownClick(item.label);
                                                }}
                                              >
                                                {item?.is_pinned && <span style={{ marginRight: '10px', marginTop: '9px' }}><i className='fa-solid fa-thumbtack'></i></span>} {/* Pin Icon */}
                                                <Select
                                                  styles={customStyles}
                                                  classNamePrefix='select'
                                                  className='filter-dropdown-single-select'
                                                  placeholder={item?.label}
                                                  customLabel={item?.label}
                                                  value={lookupValue[item.label] || []}
                                                  options={loadingOptions ? loadingOptionShow : optionsMap[item.label] || []}
                                                  onChange={(e) => handleDropdownChangeSingleSelectUpdate(item?.label, e, item)}
                                                  // Disable if isPinned is true
                                                  isDisabled={item?.is_pinned}
                                                />

                                              </div>
                                            </>
                                          ) :
                                            <></>}
                                    </>
                                  );
                                })}
                              {quickFilterLookup?.length > 0 &&
                                quickFilterLookup?.map((item) => {
                                  return item?.type === 'date' ? (
                                    <div className='mt-2 mb-5 ml-4 mr-24'>
                                      <Typography
                                        id='range-slider'
                                        sx={{
                                          fontWeight: '600 !important',
                                          fontFamily: 'Inter !important',
                                          fontSize: '12px',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          marginTop: '1px',
                                        }}
                                        gutterBottom
                                      >
                                        {item?.label} between
                                      </Typography>
                                      <Box sx={{ width: 280 }}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginBottom: '10px',
                                            width: '315px',
                                          }}
                                        >
                                          <input
                                            type='date'
                                            className='w-full rounded-lg link-txt mt-1 text-xs'
                                            value={
                                              quickFiltersMap[item?.label]?.min ||
                                              item?.operators[0]?.options
                                                ?.selected_min
                                            }
                                            min={item?.operators[0]?.options?.min}
                                            max={item?.operators[0]?.options?.max}
                                            onChange={(e) => {
                                              handleFromDateChange(e, item);
                                            }}
                                          />
                                          <div className='text-sm m-3 ml-4 mr-4'>
                                            &
                                          </div>
                                          <input
                                            type='date'
                                            className='w-full rounded-lg link-txt mt-1 text-xs'
                                            value={
                                              quickFiltersMap[item?.label]?.max ||
                                              item?.operators[0]?.options
                                                ?.selected_max
                                            }
                                            min={item?.operators[0]?.options?.min}
                                            max={item?.operators[0]?.options?.max}
                                            onChange={(e) => {
                                              handleToDateChange(e, item);
                                            }}
                                          />
                                        </div>
                                      </Box>
                                      <button
                                        className='btn-popup px-4 py-2 rounded-lg text-xs ml-60'
                                        onClick={() => {
                                          updateDynamicFilterData(item, [
                                            quickFiltersMap[item?.label]?.min,
                                            quickFiltersMap[item?.label]?.max,
                                          ],
                                            filterData);
                                        }}
                                      >
                                        Apply
                                      </button>
                                      {/* <Box sx={{ width: 280 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: "296px" }}>
              <TextField
                className='slider-text-field mr-2'
                value={item?.operators[0]?.options?.selected_min}
                onChange={(event) => {handleMinInputChange(event, item);}}
                variant="outlined"
                size="small"
                type="date"
                sx={{
                  marginLeft: "10px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                    '&:hover fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                    '&.Mui-focused fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                  },
                }}
              />
              <div className='text-sm m-2 ml-4 mr-4'>&</div>
              <TextField
                className='slider-text-field'
                value={item?.operators[0]?.options?.selected_min}
                onChange={(event) => {handleMaxInputChange(event, item)}}
                variant="outlined"
                size="small"
                type="date"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important",
                    },
                    '&:hover fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                    '&.Mui-focused fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                  },
                }}
              />
            </div>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              onChange={(event, newValue) => handleChange(item, event, newValue)}
              onChangeCommitted={handleChangeCommitted}
              value={[
                item?.operators[0]?.options?.selected_min ,  
                item?.operators[0]?.options?.selected_max 
              ]}
              min={quickFiltersMap[item?.label]?.min}
              max={quickFiltersMap[item?.label]?.max}
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => unscaleValue(val, scaleFactor)}
              sx={{
                mx: 2,
                flexGrow: 1,
                color: 'primary.main', 
                '& .MuiSlider-thumb': {
                  backgroundColor: 'var(--primary)',
                  height: 20,
                  width: 10,
                  borderRadius: 2,
                },
                '& .MuiSlider-track': {
                  backgroundColor: 'var(--primary)',
                  color: 'var(--primary)'
                },
                '& .MuiSlider-rail': {
                  backgroundColor: 'grey.400', 
                },
              }}
            />
          </Box> */}
                                    </div>
                                  ) : (
                                    <></>
                                  );
                                })}

                              {filterLookup?.length > 0 && (
                                <div className='mt-1 mb-5 ml-4'>
                                  <Select
                                    styles={customStyles}
                                    classNamePrefix='select'
                                    className='select-icon'
                                    placeholder='Add Filter'
                                    value={filterApply}
                                    options={filterLookup}
                                    onChange={(e) => {
                                      if (e.type === 'lookup') {
                                        setLookupShow(true);
                                        handleCallDBFetcher(
                                          e.operators.url,
                                          e.operators.fetch_instruction
                                        );
                                        setFilter(e);
                                      } else {
                                        setFilterApply(true);
                                        setDynamicFilterData({
                                          ...dynamicFilterData,
                                          fltr: e.fil,
                                          filter_label: e.label,
                                          type: e.type,
                                        });
                                      }
                                      setFilter(e);
                                    }}
                                    required
                                  />

                                  {filterApply && (
                                    <div className='modalPopup relative  my-5 text-custom text-sm flex justify-between flex-col h-auto'>
                                      <div className=' flex-grow overflow-y-auto '>
                                        {/* <div className='flex justify-between items-center gap-5'>                
              </div> */}
                                        <div
                                          style={{
                                            fontSize: 14,
                                            marginBottom: '10px',
                                            fontWeight: '600',
                                          }}
                                        >
                                          {filter?.label}
                                        </div>
                                        <div className='flex flex-col'>
                                          <div className='mt-1 mb-5'>
                                            <Select
                                              styles={customStyles}
                                              classNamePrefix='select'
                                              className='filter-dropdown'
                                              placeholder='Select Operator'
                                              options={filter?.operators}
                                              onChange={(e) => {
                                                setFilterOperator(e);
                                                setDynamicFilterData({
                                                  ...dynamicFilterData,
                                                  optr: e.optr,
                                                  filter_operator: e.label,
                                                });
                                              }}
                                              required
                                            />
                                          </div>

                                          {filter?.type === 'text' &&
                                            filterOperator ? (
                                            <div>
                                              <div className='flex flex-col gap-1 text-xs'>
                                                <input
                                                  type='text'
                                                  style={{
                                                    width: 'auto',
                                                    padding:
                                                      '12px 14px !important',
                                                  }}
                                                  value={value || ''}
                                                  onChange={(e) => {
                                                    setValue(e.target.value);
                                                    setDynamicFilterData({
                                                      ...dynamicFilterData,
                                                      value: e.target.value,
                                                    });
                                                  }}
                                                  placeholder='Enter a Value'
                                                  className='custom-input-filter py-2 px-2 text-left mt-1 mr-4'
                                                  required
                                                />
                                              </div>
                                            </div>
                                          ) : filter?.type === 'numeric' &&
                                            filterOperator?.number_of_operands ===
                                            1 ? (
                                            <div>
                                              <div className='flex flex-col gap-1 text-xs'>
                                                <input
                                                  type='number'
                                                  style={{
                                                    width: 'auto',
                                                    padding:
                                                      '12px 14px !important',
                                                  }}
                                                  value={value || ''}
                                                  onChange={(e) => {
                                                    setValue(e.target.value);
                                                    setDynamicFilterData({
                                                      ...dynamicFilterData,
                                                      value: e.target.value,
                                                    });
                                                  }}
                                                  placeholder='Enter a Value'
                                                  className='custom-input-filter py-2 px-2 text-left mt-1 mr-4'
                                                  required
                                                />
                                              </div>
                                            </div>
                                          ) : filter?.type === 'numeric' &&
                                            filterOperator?.number_of_operands ===
                                            2 ? (
                                            <div className='flex flex-col'>
                                              <div className='flex flex-col gap-1 ml-6 text-xs'>
                                                <input
                                                  type='number'
                                                  style={{ width: 'auto' }}
                                                  value={value || ''}
                                                  onChange={(e) => {
                                                    setValue(e.target.value);
                                                    setDynamicFilterData({
                                                      ...dynamicFilterData,
                                                      array_value: [
                                                        e.target.value,
                                                      ],
                                                    });
                                                  }}
                                                  placeholder='Enter a Value'
                                                  className='custom-input-filter py-2 px-2 text-left mt-1 mr-2'
                                                  required
                                                />
                                              </div>{' '}
                                              <div className='ml-4 mt-4'>and</div>
                                              <div className='flex flex-col gap-1 ml-6 text-xs'>
                                                <input
                                                  type='number'
                                                  style={{ width: 'auto' }}
                                                  value={value2 || ''}
                                                  onChange={(e) => {
                                                    setValue2(e.target.value);
                                                    setDynamicFilterData({
                                                      ...dynamicFilterData,
                                                      array_value: [
                                                        value,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  }}
                                                  placeholder='Enter a Value'
                                                  className='custom-input-filter py-2 px-2 text-left mt-1 mr-2'
                                                  required
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                      {/* bottom buttom */}
                                      <div className='spacing-div'>
                                        <button
                                          type='button'
                                          className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs'
                                          onClick={() => {
                                            setFilterApply(false);
                                            setValue('');
                                            setFilterOperator();
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type='submit'
                                          className='btn-popup px-4 py-2 rounded-lg mt-2 text-xs'
                                          onClick={() => {
                                            handleSubmit(dynamicFilterData, null);
                                          }}
                                        >
                                          OK
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {/* Popup for filter save */}
                            {filterSave && (
                              <>
                                <div className='overlay-filter-save-popup'>
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      if (filterName.trim() === '') {
                                        alert('Filter name is required.');
                                        return;
                                      }
                                      handleSaveFilter();
                                    }}
                                    style={{ width: '25vw' }}
                                    className='modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'
                                  >
                                    <div className='filter-popup-content'>
                                      <label>
                                        Name of filter
                                        <span className=' text-red-500 '>*</span>
                                      </label>
                                      <input
                                        type='text'
                                        style={{
                                          width: '100%',
                                          height: '40px',
                                          padding: '8px',
                                        }}
                                        value={filterName}
                                        onChange={(e) => {
                                          setFilterName(e.target.value);
                                        }}
                                        placeholder='Name of filter'
                                        className='custom-input-filter py-2 px-2 text-left mt-4 mr-4'
                                        required
                                      />
                                      {isPublishPrivileged === 1 && (
                                        <div className='checkbox-group mt-4'>
                                          <label className='flex items-center mt-2'>
                                            <input
                                              type='checkbox'
                                              onChange={(e) => setPublishToAllDashboards(e.target.checked)}
                                              className='mr-1'
                                            />
                                            Publish to all dashboards<i class="fa-solid fa-thumbtack ml-1" style={{ color: '#828282' }}></i>
                                          </label>
                                          <label className='flex items-center mt-2'>
                                            <input
                                              type='checkbox'
                                              onChange={(e) => setPublishToAllUsers(e.target.checked)}
                                              className='mr-1'
                                            />
                                            Publish to all users <i class="fa-solid fa-user-group ml-1" style={{ color: '#828282' }}></i>
                                          </label>
                                        </div>
                                      )}
                                      <div className='button-container flex justify-end mt-4'>
                                        <button
                                          type='button'
                                          className='cancel-btn-popup px-4 py-2 rounded-lg mr-2 text-xs'
                                          onClick={() => {
                                            setFilterName('');
                                            setFilterSave(false);
                                            setIsPublishPrivileged(0);
                                            setPublishToAllDashboards(false);
                                            setPublishToAllUsers(false);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type='submit'
                                          className='btn-popup px-4 py-2 rounded-lg text-xs'
                                          disabled={filterName.trim() === ''}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}
                            {/* Popup for filter edit */}
                            {filterEdit && (
                              <>
                                <div className='overlay-filter-save-popup'>
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      if (filterName.trim() === '') {
                                        alert('Filter name is required.');
                                        return;
                                      }
                                      handleSavedFilterUpdate();
                                    }}
                                    style={{ width: '25vw' }}
                                    className='modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'
                                  >
                                    <div className='filter-popup-content'>
                                      <label>
                                        Name of filter
                                        <span className=' text-red-500 '>*</span>
                                      </label>
                                      <input
                                        type='text'
                                        style={{
                                          width: '100%',
                                          height: '40px',
                                          padding: '8px',
                                        }}
                                        value={filterName}
                                        onChange={(e) => {
                                          setFilterName(e.target.value);
                                        }}
                                        placeholder='Name of filter'
                                        className='custom-input-filter py-2 px-2 text-left mt-4 mr-4'
                                        required
                                      />
                                      {isPublishPrivileged === 1 && (
                                        <div className='checkbox-group mt-4'>
                                          <label className='flex items-center mt-2'>
                                            <input
                                              type='checkbox'
                                              checked={publishToAllDashboards}
                                              onChange={(e) => setPublishToAllDashboards(e.target.checked)}
                                              className='mr-1'
                                            />
                                            Publish to all dashboards <i class="fa-solid fa-thumbtack ml-1" style={{ color: '#828282' }}></i>
                                          </label>
                                          <label className='flex items-center mt-2'>
                                            <input
                                              type='checkbox'
                                              checked={publishToAllUsers}
                                              onChange={(e) => setPublishToAllUsers(e.target.checked)}
                                              className='mr-1'
                                            />
                                            Publish to all users <i class="fa-solid fa-user-group ml-1" style={{ color: '#828282' }}></i>
                                          </label>
                                        </div>
                                      )}
                                      <div className='button-container flex justify-end mt-4'>
                                        <button
                                          type='button'
                                          className='cancel-btn-popup px-4 py-2 rounded-lg mr-2 text-xs'
                                          onClick={() => {
                                            setFilterName('');
                                            setFilterEdit(false);
                                            setIsPublishPrivileged(0);
                                            setPublishToAllDashboards(false);
                                            setPublishToAllUsers(false);
                                            setFilterEditData({});
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type='submit'
                                          className='btn-popup px-4 py-2 rounded-lg text-xs'
                                          disabled={filterName.trim() === ''}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}
                            {/* Popup for filter delete */}
                            {filterDelete && (
                              <>
                                <div className='overlay-filter-save-popup'>
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      handleSavedFilterDelete();
                                    }}
                                    style={{ width: '25vw' }}
                                    className='modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'
                                  >
                                    <div className='filter-popup-content'>
                                      <p>Are you sure you want to delete <b>{filterName}</b>?</p>
                                      <div className='button-container flex justify-end mt-4'>
                                        <button
                                          type='button'
                                          className='cancel-btn-popup px-4 py-2 rounded-lg mr-2 text-xs'
                                          onClick={() => {
                                            setFilterName('');
                                            setFilterDelete(false);
                                            setFilterEditData({});
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type='submit'
                                          className='btn-popup px-4 py-2 rounded-lg text-xs'
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className='h-40'>
                          <button
                            type='button'
                            class='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium'
                            onClick={() => {
                              setFilterClose(true);
                            }}
                            title='filter data for dashboard'
                            style={{ margin: '10px' }}
                          >
                            <div className='flex flex-col'>
                              <i className='fa-solid fa-filter'></i>
                              <i class='fa-solid fa-arrow-left text-xs'></i>
                            </div>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <div className='flex flex-col h-3/4 items-center justify-center mt-32'>
                      <div className='mb-8'></div>
                      <p>
                        <b>This Organisation has no Dashboards</b>
                      </p>
                      <p className='text-sm'>
                        Create first Dashboard for {selectedOrganization?.name}
                      </p>
                      <div className='mt-5'>
                        <button
                          className='sub-grp px-3 py-2'
                          onClick={() => {
                            navigate('/dashboard_manage');
                          }}
                        >
                          New Dashboard
                        </button>
                      </div>
                    </div>
                  </>
                )) : (

                <div className='flex items-center justify-center h-full mt-80'>
                  <div className="spinner-border" role="status">
                    <i className="fa-solid fa-spinner fa-spin-pulse"
                      style={{ marginTop: "20px", fontSize: "48px" }}
                    ></i>
                  </div>
                </div>
              )}

              {/* -- */}
            </div>
          </div>
        </div>
        {/* Popup Modal */}
        {isOpen && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "25px",
              borderRadius: "8px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
              zIndex: 1000, // Ensure it's above other elements
            }}
          >
            {/* Close Button */}
            <button
              onClick={() => setIsOpen(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              ✖
            </button>

            {/* Image */}
            <img
              src={require("./img/instructions.jpg")}
              alt="Popup Image"
              style={{ width: "750px", height: "88vh", borderRadius: "5px" }}
            />


          </div>
        )}

        {isOpen && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "25px",
              borderRadius: "8px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
              zIndex: 1000, // Ensure it's above other elements
            }}
          >
            {/* Close Button */}
            <button
              onClick={() => setIsOpen(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              ✖
            </button>

            {/* Image */}
            <img
              src={require("./img/instructions.jpg")}
              alt="Popup Image"
              style={{ width: "750px", height: "88vh", borderRadius: "5px" }}
            />


          </div>
        )}

        {isPopupOpen && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "25px",
              borderRadius: "8px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
              zIndex: 1000, // Ensure it's above other elements
            }}
          >
            {/* Close Button */}
            <button
              onClick={() => setIsPopupOpen(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              ✖
            </button>

            {/* Image */}
            <div
              className='dashboard-heading'
              style={{ textAlign: 'left', margin: '10px' }}
            >
              {popupDashboardTitle}
            </div>
            <div
              className='grid-container overflow-y-scroll overflow-x-scroll'
              style={{ height: '81vh', marginRight: '18px' }}
            >
              {popupChartData?.map((item) => {
                return (
                  <>
                    <div
                      className='grid-item'
                      style={item?.opacity === "0" ? {
                        padding: '0px',
                        fontSize: '30px',
                        //height: "500px",
                        textAlign: 'center',
                        gridRow: `${item?.grid_row}`,
                        gridColumn: `${item?.grid_column}`,
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        color: 'black',
                      } : item?.type === "number-1" ? {
                        padding: '0px',
                        fontSize: '30px',
                        //height: "500px",
                        textAlign: 'center',
                        gridRow: `${item?.grid_row}`,
                        gridColumn: `${item?.grid_column}`,
                        opacity: '100',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      } :
                        {
                          padding: '0px',
                          fontSize: '30px',
                          //height: "500px",
                          textAlign: 'center',
                          gridRow: `${item?.grid_row}`,
                          gridColumn: `${item?.grid_column}`,
                          opacity: '100',
                          color: 'black'
                        }}
                    >
                      {item?.type === 'table' ? (
                        <TableChart
                          data={item?.data}
                          item={item}
                          currPage={currPage}
                        />
                      ) : null}
                    </div>
                  </>
                );
              })}
            </div>


          </div>
        )}

        {/* Background Overlay (Clicking outside closes modal) */}
        {isPopupOpen && (
          <div
            onClick={() => setIsPopupOpen(false)}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              zIndex: 999,
            }}
          />
        )}

        {/* Background Overlay (Clicking outside closes modal) */}
        {isOpen && (
          <div
            onClick={() => setIsOpen(false)}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              zIndex: 999,
            }}
          />
        )}
        {alertModalShow && (
          <>
            {showOkButton ? (
              <Notification message={alertMessage} notificationType={'Error'} />
            ) : (
              <Notification
                message={alertMessage}
                notificationType={'Success'}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
