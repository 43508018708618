import React,{useState, useContext} from 'react'
import { Context } from '../contextApi/context'
import MapAccessTable from './tables/MapAccessTable'

function AccessMapMetadata() {
  
    const {collapse} = useContext(Context)
const [teamPopup , setTeamPopup]= useState(false)
  return (
    <div>
    <div className={collapse?'content-body':'content-body-collapse'} >
          
          <div class="container-fluid">
       
          <MapAccessTable />
        
          </div>
         
          </div>
    </div>
  )
}

export default AccessMapMetadata;