import React from "react";
import UserService from "../../services/UserService.js";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../contextApi/context.js";
import "../css/okr.css";
import { useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Notification from "../../common/notification/Notification.js";
import CreateSubOKR from "../components/CreateSubOKR.js";
import ProgressBar from "../components/ProgressBar.js";
import CreateKeyResult from "../components/CreateKeyResult.js";
import ChecklistTable from "./ChecklistTable.js";
import EditKeyResult from "../components/EditKeyResult.js";
import EditOKRPopup from "../components/EditOKRPopup.js";

const serverConfig = require("../../config/server.js");

const RecursiveOKR = ({okrData, okrSlug, convertDate, refreshKRList, setRefreshKRList }) => {

    const {selectedOrganization, isDarkTheme} = useContext(Context);
    const [token, setToken] = useState(null);
    const [createObjTable, setCreateObjTable] = useState(false);
    const [refreshObjList, setRefreshObjList] = useState(false);
    //const [refreshKRList, setRefreshKRList] = useState(false);
    const [editObjModal, setEditObjModal] = useState(false);
    const [deleteObjModal, setDeleteObjModal] = useState(false);    
    const [formData, setFormData] = useState({});
    const [formMessage, setFormMessage] = useState({});
    const [tableData, setTableData] = useState([]);
    const [keyResultTableData, setKeyResultTableData] = useState([])
    const [objName, setObjName] = useState([]);
    const [objSlug, setObjSlug] = useState([]);
    const [createSubOKR, setCreateSubOKR] = useState(false);
    const [editSubOKR, setEditSubOKR] = useState(false);
    const [createKeyResult, setCreateKeyResult] = useState(false);
    const [tooltipShow, setShowTooltip] = useState(false);
    const [rowHovered, setRowHovered] = useState(false);
    const [editRow, setEditRow] = useState('');
    const [expandedRows, setExpandedRows] = useState({});
    const [tooltipStates, setTooltipStates] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [labelTooltip, setLabelTooltip] = useState(false);
  const [ownerTooltip, setOwnerTooltip] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [showOkButton, setShowOkButton] = useState(false);
    const [alertModalShow, setAlertModalShow] = useState(false);
    const handleCloseAlertModal = () => {
      setAlertMessage("");
      setShowOkButton(false);
      setAlertModalShow(false);
    }
    const handleShowAlertModal = (alert_msg, show_ok_button) => {
      setAlertMessage(alert_msg);
      setShowOkButton(show_ok_button);
      setAlertModalShow(true);
    }

    const handleShowCreateSubOKR = () =>{
      setCreateSubOKR(true);
    }

    const handleCloseCreateSubOKR = () => {
      setCreateSubOKR(false);
      setRefreshKRList(!refreshKRList);
    }

    const handleShowCreateKeyResult = () =>{
        setCreateKeyResult(true);
      }
  
      const handleCloseCreateKeyResult = () => {
        setCreateKeyResult(false);
        setRefreshKRList(!refreshKRList);
      }

      const handleKeyResultTableDeleteShow = (row) => {
        setObjName(row?.name);
        setObjSlug(row?.slug);
          setDeleteObjModal(true);
      }
      const handleKeyResultTableDeleteClose = () => {
          setDeleteObjModal(false);
          setRefreshObjList(!refreshObjList);
      }

    const handleEditSubOKRShow = (details) => {
      setFormData(details);
      setEditSubOKR(true);
    }

    const handleEditSubOKRClose = (details) => {
      setEditSubOKR(false);
      setRefreshKRList(!refreshKRList);
    }

      
    const handleKeyResultEditShow = (details) => {
      setFormData(details);
      setEditObjModal(true);
  }
  const handleKeyResultEditClose = () => {
      setEditObjModal(false);
      setRefreshObjList(!refreshObjList);
  }
  

    const toggleTooltip = () => {     
      setOwnerTooltip(!ownerTooltip);
      setLabelTooltip(false);
    }

    const toggleTooltipLabel = (index) => {
      const newTooltipStates = [...tooltipStates];
      newTooltipStates[index] = !newTooltipStates[index];
      setTooltipIndex(index);
      setLabelTooltip(!labelTooltip);
      setOwnerTooltip(false);
      setTooltipStates(newTooltipStates);
    };
    
    function Mapme(data, row) {
      return (data && <div className='gap-2 overflow-hidden h-5 flex line-clamp-1 '>
        {data.map((item, index) => {
          return (
  
            <>
            
          <div key={index} className='status' style={{backgroundColor: `${item.color_code}30`,
          borderColor: `${item.color_code}90`}} onMouseEnter={()=>{toggleTooltipLabel(index); setRowHovered(row);}} onMouseLeave={()=>{setRowHovered('')}} >
            <div className='line-clamp-1'>{item.name}</div></div>
  
            {tooltipIndex === index && row===rowHovered && labelTooltip && (
                        <div className="role-tooltip">
                          {item.name}
                        </div>
                      )}
            
            </>
            )
        })}
      </div>)
    }

    // const handleExpandRow = async (row) => {
    //     if (!row.childrenLoaded) {
    //       //const nestedData = await fetchData(row.id); // Fetch nested data for the row
    //       console.log('nested 1');
    //       //setNestedData([]);
    //       row.childrenLoaded = true;
    //     }
    //   };

    // const handleExpandRow = async (row) => {
    //     if (!expandedRows[row.id]) {
    //       const nestedData = await fetchData(row.id); // Fetch nested data for the row
    //       setNestedData(nestedData);
    //       setExpandedRows({ ...expandedRows, [row.id]: true });
    //     }
    //   };

    const handleExpandRow = (row) => {
        if (!expandedRows[row.id]) {
          //const nestedData = await fetchData(row.id); // Fetch nested data for the row
          //setNestedData(nestedData);
          setExpandedRows({ ...expandedRows, [row.id]: true });
        }
      };

      const columnsKeyResults = [
        {
          name: <div className='row-label'>Key</div>,
          cell: (row) =>  <div className="flex-row flex items-center py-2">
          <img src={`https://ui-avatars.com/api/?name=KeyResults
                        &rounded=false&length=1
                        &background=9D6EF8&format=svg&color=fff`} alt='image'
                        height={40} width={40} className='rounded-xl okr-avatar'/>
           <div className='flex flex-col'>
           <div className='text-heading ml-2.5'>{row?.kr_id}</div>
           </div>
           </div>,
                        width: '15%'
          
        },
        {
          name: <div className='row-label'>Key Result</div>,
          selector: row => row.name,
          cell: (row) =>
          <div className="flex-row flex items-center py-2 mr-4">
                           <div className='flex flex-col'>
                           <div className='text-heading '>{row.name}</div>
                           <div className=' desc-box'>{row.description}</div>
                           </div>
                           </div>,
           width: '26%'
        },
      //   {
      //     name: <div className='row-label'>Start Date</div>,
      //     selector: row => row.start_date,
      //     cell: (row) =>  <div className='text-heading'>
      //                     {convertDate(row.start_date)}
      //                     </div>,
       
      // },
      // {
      //     name: <div className='row-label'>End Date</div>,
      //     selector: row => row.end_date,
      //     cell: (row) =>  <div className='text-heading'>
      //                     {convertDate(row.end_date)}
      //                     </div>,
          
      // },
      {
        name: <div className='row-label'>Owner</div>,
        cell: (row) => <div>{row?.owner?.username !== null ? <div className='ml-2' onMouseEnter={()=> {toggleTooltip(); setRowHovered(row);}} onMouseLeave={()=>{toggleTooltip(); setRowHovered('');}}>{row?.logo === null ?
        <img src={`https://ui-avatars.com/api/?name=${row?.owner?.username}
        &rounded=true&length=1
        &background=6ADA00&format=svg&color=fff`} alt='image'
         height={40} width={40} className='rounded-full'/> 
        : <img src={row?.logo} className='rounded-full w-12 h-12 object-cover p-1'/> }
          {rowHovered===row && ownerTooltip ? <div className="role-tooltip">
                       {row?.owner?.username}
            </div> : <></> }
        </div> : <></> } </div>,
      
      },
      //   {
      //     name: <div className='row-label'>Group</div>,
      //     selector: row => row.group.name,
      //     cell: (row) => <div> {row?.group?.name === null ? <></> :
      //      <div className={'type-okr'} onClick={() => {}}>
      //     <div>{row?.group.name}</div>
      //     </div>} </div>,
         
      //  },
        {
          name: <div className='row-label'>Labels</div>,
          cell: (row) =>
            <div className="flex overflow-hidden ml-2">
              {Mapme(row?.labels, row)}
            </div>,
          selector: row => row.labels,
          sortable: true,
          wrap: true,
        },
        {
          name: <div className='row-label'>Progress Percent</div>,
          cell: (row) => <div style={{ fontSize: 10 }} className='w-full ml-6'>
             {/* <ProgressbarReadOnly progress_value={row.progress_percentage ? row.progress_percentage : 0}/> */} 
             <ProgressBar isDraggable={false} progress_value={row?.progress_percentage === null ? 0 : row?.progress_percentage} formData={formData} setFormData={setFormData} />
            </div>,
          selector: row => row.progress_percentage ? row.progress_percentage : 0,
          sortable: true,
          wrap: true,
          width: '18%'
        },
        {
          name: <div className='row-label ml-6'>Actions</div>,
          selector: row => row.actions,
          cell: (row) =>  
    
          <div className='flex flex-row ml-10'>
          <Button variant='text' className='mr-2 ml-2' onClick={() => {handleKeyResultEditShow(); setEditRow(row);}}><i class="fa-solid fa-pencil"></i></Button>
          <Button variant='text' onClick={() => {handleKeyResultTableDeleteShow(row);}}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> </Button> 
          {editObjModal && row===editRow && <div className='overlay'><EditKeyResult setCloseEditKR={handleKeyResultEditClose} token={token} refreshKRList={()=>{setRefreshKRList(!refreshKRList)}} data={row} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} /></div>}
          </div>
          ,
       
        },
      ];

    const columnsObjectives = [
      {
        name: <div className='row-label'>Key</div>,
        cell: (row) =>  <div className="flex-row flex items-center py-2">
        <img src={`https://ui-avatars.com/api/?name=Objectives
        &rounded=false&length=1
        &background=F6B637&format=svg&color=fff`} alt='image'
         height={40} width={40} className='rounded-xl okr-avatar'/>
         <div className='flex flex-col'>
         <div className='text-heading ml-2.5'>{row?.okr_id}</div>
         </div>
         </div>,
                      width: '15%'
        
      },
        {
            name: <div className='row-label'>Objective</div>,
            cell: (row) => <div className="flex-row flex items-center py-2">
                           <div className='flex flex-col'>
                           <div className='text-heading'>{row.name}</div>
                           <div className='desc-box'>{row.description}</div>
                           </div>
                           </div>,
             width: '26%'
            
          },

      //   {
      //     name: <div className='row-label'>Start Date</div>,
      //     selector: row => row.start_date,
      //     cell: (row) =>  <div className='text-heading'>
      //                     {convertDate(row.start_date)}
      //                     </div>,
       
      // },
      // {
      //     name: <div className='row-label'>End Date</div>,
      //     selector: row => row.end_date,
      //     cell: (row) =>  <div className='text-heading'>
      //                     {convertDate(row.end_date)}
      //                     </div>,
          
      // },
         {
          name: <div className='row-label'>Owner</div>,
          cell: (row) => <div>{row?.owner?.username !== null ? <div className='ml-2' onMouseEnter={()=> {toggleTooltip(); setRowHovered(row);}} onMouseLeave={()=>{toggleTooltip(); setRowHovered('');}}>{row?.logo === null ?
          <img src={`https://ui-avatars.com/api/?name=${row?.owner?.username}
          &rounded=true&length=1
          &background=6ADA00&format=svg&color=fff`} alt='image'
           height={40} width={40} className='rounded-full'/> 
          : <img src={row?.logo} className='rounded-full w-12 h-12 object-cover p-1'/> }
            {rowHovered===row && ownerTooltip ? <div className="role-tooltip">
                         {row?.owner?.username}
              </div> : <></> }
          </div> : <></> } </div>,
        
        },

      //   {
      //     name: <div className='row-label'>Group</div>,
      //     selector: row => row.group.name,
      //     cell: (row) => <div> {row?.group?.name === null ? <></> :
      //      <div className={'type-okr'} onClick={() => {}}>
      //     <div>{row?.group.name}</div>
      //     </div>} </div>,
         
      //  },
        {
          name: <div className='row-label'>Labels</div>,
          cell: (row) =>
            <div className="flex overflow-hidden ml-2">
              {Mapme(row?.labels, row)}
            </div>,
          selector: row => row.labels,
          sortable: true,
          wrap: true,
        },
        {
          name: <div className='row-label'>Progress Percent</div>,
          selector: row => row.progress_percentage,
          cell: (row) => <div style={{ fontSize: 10 }} className='w-full p-2 ml-4'>
             <ProgressBar isDraggable={false} progress_value={row?.progress_percentage === null ? 0 : row?.progress_percentage} formData={formData} setFormData={setFormData} />
            </div>,
          selector: row => row.progress_percentage ? row.progress_percentage : 0,
          sortable: true,
          wrap: true,
          width: '18%'
        },
        {
          name: <div className='row-label ml-6'>Actions</div>,
          selector: row => row.actions,
          cell: (row) =>  
    
          <div className='flex flex-row ml-10'>
          <Button variant='text' className='mr-2 ml-2' onClick={() => {handleEditSubOKRShow(); setEditRow(row);}}><i class="fa-solid fa-pencil"></i></Button>
          <Button variant='text' onClick={() => {handleSubObjTableDeleteShow(row);}}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> </Button> 
          {editSubOKR && row===editRow && <div className='overlay'><EditOKRPopup setCloseEditOKR={handleEditSubOKRClose} token={token} refreshKRList={()=>{setRefreshKRList(!refreshKRList)}} data={row} selectedOrganization={selectedOrganization} handleLogout={handleLogout} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal} /></div>}
          </div>
          ,
       
        },
      ];
    const [deleteSubObj, setDeleteSubObj] = useState(false);

    const handleObjTableCreateShow = () => {
        setCreateObjTable(true);
    }
    const handleObjTableCreateClose = () => {
        setFormData({});
        setCreateObjTable(false);
        setRefreshObjList(!refreshObjList);
    }
    const handleSubObjTableDeleteShow = (row) => {
      setObjName(row?.name);
      setObjSlug(row?.slug);
        setDeleteSubObj(true);
    }
    const handleSubObjTableDeleteClose = (row) => {
      setObjName(row?.name);
      setObjSlug(row?.slug);
        setDeleteSubObj(false);
    }

    const handleObjTableDeleteShow = (row) => {
      setObjName(row?.name);
      setObjSlug(row?.slug);
        setDeleteObjModal(true);
    }
    const handleObjTableDeleteClose = () => {
        setDeleteObjModal(false);
        setRefreshObjList(!refreshObjList);
    }

    const handleLogout = () => {
        sessionStorage.clear();
        UserService.doLogout();
      }

    const getToken = () => {
        console.log(sessionStorage.getItem('token'));
        let jwt = UserService.getToken();
        axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
        .then(response=>{
          console.log('user_get_token response', response);
          if(response.data.status === 200){
            sessionStorage.setItem('token', response.data.token);
            setToken(response.data.token);
          } else if(response.data.status >= 400 && response.data.status <= 499){
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          } else if(response.data.jwt_validation === 'fail') {
            handleLogout();
          }
        })
        .catch(err=>console.log("error is",err));
      }
    
      useEffect(() => {
        console.log(sessionStorage.getItem('token'));
        if(sessionStorage.getItem('token') === null){
          getToken();
        } else {
          setToken(sessionStorage.getItem('token'));
        }
      }, []);

      useEffect(()=> {
        if(token !== null && token !== undefined) {
            objectveListGet();
            keyResultsListGet();
        }
      }, [token, refreshKRList])

      const keyResultsListGet = () => {
        axios.post(serverConfig.api_base_url + "okr_key_result_list_get", {
            token: token,
            org: selectedOrganization?.slug,
            okr: okrSlug
        }).then(response=>{
            if(response.data.status === 200) {
            console.log(response.data.items);
            setKeyResultTableData(response.data.items);
            }
            else if(response.data.status === 301){
              handleLogout();
            } else {
              handleShowAlertModal(response.data.message, true);
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
          }).catch(err=> {
            console.log(err);
        })
      }

      const handleDeleteSubObj = (event) => {
        event.preventDefault();
        axios.post(serverConfig.api_base_url + "okr_delete", {
            token: token,
            okr: objSlug,
            org: selectedOrganization?.slug
        }).then(response=> {
            console.log("okr_delete", response.data.items);
            if(response.data.status === 200){
              handleSubObjTableDeleteClose();
              handleShowAlertModal('Objective deleted successfully');
              setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
              setRefreshKRList(!refreshKRList);
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              handleShowAlertModal(response.data.message, true);
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
    
          }).catch(err=> {
            console.log(err);
        })
      }

      const handleDeleteObj = (event) => {
        event.preventDefault();
        axios.post(serverConfig.api_base_url + "okr_key_result_delete", {
            org: selectedOrganization?.slug,
            token: token,
            okr: okrSlug,
            kyr: objSlug
        }).then(response=> {
            console.log("okr_key_result_delete", response.data.items);
            if(response.data.status === 200){
              handleObjTableDeleteClose();
              handleShowAlertModal('Key Result deleted successfully');
              setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
              setRefreshKRList(!refreshKRList);
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              handleShowAlertModal(response.data.message, true);
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
    
          }).catch(err=> {
            console.log(err);
        })
      }

      const objectveListGet = () => {
        axios.post(serverConfig.api_base_url + "sub_okr_list_get", {
            token: token,
            org: selectedOrganization?.slug,
            okr: okrSlug
        }).then(response=>{
            if(response.data.status === 200) {
            console.log(response.data.items);
            setTableData(response.data.items);
            }
            else if(response.data.status === 301){
              handleLogout();
            } else {
              handleShowAlertModal(response.data.message, true);
              setTimeout(() => {
                handleCloseAlertModal();
              }, 6000);
            }
          }).catch(err=> {
            console.log(err);
        })
      }

    return (
        <>
        <div className="overflow-x-auto px-4 ms-2 mt-4 pb-3">

        <div className="flex flex-col">

        <div className="table-dbs-sub">
        <DataTable
           title={
            <div className=" table-title rounded">
            <div className="flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h4 className='font-bold text-base'>Key Result Areas</h4>
              </div>
              <span className="text-right">
                <Button type='button' className='sub-grp px-3 py-2' title="Create Key Results" onClick={handleShowCreateKeyResult}><i className="fa-solid fa-plus" aria-hidden="true" style={{fontSize: "16px"}}></i></Button>
              </span>               
            </div>
          </div>
          }        
            customStyles={{
              table: {
                style: {
                  border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                  borderRadius: '16px 16px 10px 10px',
                },
              },
              rows: {
                style: {
                  minHeight: '48px',
                  border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                  borderRadius: '10px',
                  marginTop: '10px',
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? '#ffffff' : '#000000',
                  backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                  outline: 'none'
                }
              },
              expanderButton: {
                style: {
                  borderRadius: '8px',
                }
              }
            }}
          columns={columnsKeyResults}
          data={keyResultTableData === null ? "" : keyResultTableData}
          expandableRows
          //expandableRowsComponent={({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>}
          expandableRowsComponent={({ data }) => <ChecklistTable kyrSlug={data?.slug}/>}
          responsive={true}
          pagination={keyResultTableData?.length > 10 ? true : false}
          highlightOnHover
          noDataComponent={<div className='mb-3 text-xs mt-2 mb-2'>You don't have any key result areas for this OKR</div>}
        />
        </div>

        <div className="table-dbs-sub">
        
          <DataTable
           title={
            <div className=" table-title rounded">
            <div className="flex justify-between items-center">
              {/* Left side: Three tabs flexed together */}
              <div className="flex space-x-4">
                <h4 className='font-bold text-base'>Objectives</h4>
              </div>
              <span className="text-right">
                <Button type='button' className='sub-grp px-3 py-2' title="Create Objective" onClick={handleShowCreateSubOKR}><i className="fa-solid fa-plus" aria-hidden="true" style={{fontSize: "16px"}}></i></Button>
              </span>               
            </div>
          </div>
          }        
            customStyles={{
              table: {
                style: {
                  border: isDarkTheme ? '2px solid #494B4B' : '2px solid var(--neutral-400)',
                  borderRadius: '16px 16px 10px 10px',
                },
              },
              rows: {
                style: {
                  minHeight: '48px',
                  border: isDarkTheme ? '2px solid var(--neutral-dark-400)' : '2px solid var(--neutral-400)',
                  borderRadius: '10px',
                  marginTop: '10px',
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? '#ffffff' : '#000000',
                  backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                  outline: 'none'
                }
              },
              expanderButton: {
                style: {
                  borderRadius: '8px',
                }
              }
            }}
          columns={columnsObjectives}
          data={tableData === null ? "" : tableData}
          expandableRows
          onRowExpandToggled={handleExpandRow}
          //expandableRowsComponent={({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>}
          expandableRowsComponent={({ data }) => <RecursiveOKR okrSlug={data?.slug} convertDate={convertDate} refreshKRList={refreshKRList} setRefreshKRList={setRefreshKRList} />}
          responsive={true}
          pagination={tableData?.length > 10 ? true : false}
          highlightOnHover
          noDataComponent={<div className='mb-3 text-xs mt-2 mb-2'>You don't have any sub objectives for this OKR</div>}
        />
         {createKeyResult && <div className='overlay'> <CreateKeyResult okrData={okrData} okrSlug={okrSlug} token={token} selectedOrganization={selectedOrganization} handleCloseKeyResult={handleCloseCreateKeyResult} handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal}/></div>}
        {createSubOKR && <div className='overlay'> <CreateSubOKR okrData={okrData} token={token} okrSlug={okrSlug} selectedOrganization={selectedOrganization} handleCreateOKR={handleCloseCreateSubOKR} handleShowAlertModal={handleShowAlertModal} handleCloseAlertModal={handleCloseAlertModal}/></div>
        }

        {deleteObjModal && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={handleDeleteObj}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Delete Key Result</div>
                <button className='my-2 title-text-small-color' onClick={handleObjTableDeleteClose}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Deleting the Key Result means you no longer have access to this key result. It will delete all checklists inside this key result. Are you sure you want to delete <b>{objName}</b> ?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={handleKeyResultTableDeleteClose}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs'>Delete Key Result</button>
            </div>
          </form>
        </div>}


        {deleteSubObj && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={handleDeleteSubObj}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Delete Objective</div>
                <button className='my-2 title-text-small-color' onClick={handleSubObjTableDeleteClose}><i className="fa-solid fa-xmark "></i> </button>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Deleting the objective means you no longer have access to this objective. It will delete all objectives and key results inside this objective. Are you sure you want to delete <b>{objName}</b> ?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={handleSubObjTableDeleteClose}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs'>Delete Objective</button>
            </div>
          </form>
        </div>}

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
        </div>
        </div>

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
    </div>
        </>
    )
};

export default RecursiveOKR;