import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';

const serverConfig = require("../../config/server.js");

const DeleteVexa = ({row, metaAttributes, token, org, vexaSlug, handleShowAlertModal, handleCloseAlertModal, handleLogout, refreshVexaList, setRefreshVexaList}) => {

  const [recordSlug, setRecordSlug] = useState("");
  const [recordName, setRecordName] =useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [formData, setFormData] = useState({});

  const handleCloseDeleteModal = () => {
    setDeleteModalShow(false);
    setRecordSlug("");
    setRecordName("");
    setFormData({});
  }
  const handleShowDeleteModal = (slug, name) => {
    setDeleteModalShow(true);
    setRecordSlug(slug);
    setRecordName(name);
  }

  const handleDelete = (event) => {
    event.preventDefault();
    console.log({
      token: token,
      org: org,
      entity: vexaSlug,
      identity_values: {slug: recordSlug}
    });
    axios.post(serverConfig.api_base_url + "vexa_delete",{
      token: token,
      org: org,
      entity: vexaSlug,
      identity_values: {slug: recordSlug}
    })
    .then(response=>{
      console.log("vexa_delete:", response);
      if (response.data.status === 200) {
        setRefreshVexaList(!refreshVexaList);
        handleCloseDeleteModal();
        handleShowAlertModal('Record Deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (<>
    <Button variant='text' title="Delete" onClick={() => handleShowDeleteModal(row?.slug, row?.name)}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i></Button>

    {deleteModalShow && <div className='overlay'>
      <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleDelete}>
        <div className=' flex-grow overflow-y-auto '>
          <div className='flex justify-between items-center gap-5'>
            <div style={{fontSize:16}}>Delete Record</div>
            <button className='my-2 title-text-small-color' onClick={handleCloseDeleteModal}><i className="fa-solid fa-xmark "></i> </button>
          </div>
  
          <div className='mt-4'>
            <p>Are you sure you want to delete Record <b>{recordName}</b> ?</p>
          </div>
        </div>
        {/* bottom buttom */}
        <div className='spacing-div'>
          <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteModal}>Cancel</button>
          <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
        </div>
      </form>
    </div>}
  </>);
}

export default DeleteVexa;