import companyOne from '../Images/companyOne.png'
import companyTwo from '../Images/companyTwo.png'
import companythree from '../Images/companythree.png'
import DBtezbig from '../Images/DBtezbig.png'
export const notificationData =[
    {
    img:DBtezbig,
    name:'DBTEZ',
    alert:'Dyuti Ghosh updated Subgroup Name'
    },
    {
        img:companyTwo,
        name:'Bowman-Nguyen',
        alert:'Dyuti Ghosh added 12 Tasks '
    },
    {
        img:companythree,
        name:'Edwards Ltd',
        alert:'Margarita Goretskaya updated Subgroup Name and added 2 Tasks'
    },
    {
        img:companyOne,
        name:'Edwards Ltd',
        alert:'Margarita Goretskaya updated Subgroup Name and added 2 Tasks'
    },
  
]