import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Context } from "../../../contextApi/context.js";
import axios from "axios";
import { useNavigate } from "react-router";
import UserService from "../../../services/UserService.js";
import "../css/scrumreport.css";
import Notification from "../../../common/notification/Notification.js";
import ScrumReportComment from "../ScrumReportComment.js";
import DatePicker from "react-datepicker";

const serverConfig = require("../../../config/server.js");

const ScrumReportTable = () => {
  const navigate = useNavigate();

  const { selectedOrganization, isDarkTheme, setActiveSubmenuOption } =
    useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [formData, setFormData] = useState({});
  const [existingRecordDates, setExistingRecordDates] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [dateNotToDisable, setDateNotToDisable] = useState();
  const [templateGroupList, setTemplateGroupList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [refreshTemplateGroupList, setRefreshTemplateGroupList] =
    useState(false);
  const [reportCommsnts, setReportComments] = useState([]);
  const [refreshCommsntList, setRefreshCommentList] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", {
        jwt: jwt,
        url: window.location.host,
      })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startDateFilter, setStartDateFilter] = useState();
  const [endDateFilter, setEndDateFilter] = useState();

  const [refreshFilterDates, setRefreshFilterDates] = useState(false);

  const pickDateRange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (end !== null) {
      // No need to check startDateFilter/endDateFilter here, since useEffect will handle it
      const start_date = new Date(start).toISOString().split("T")[0];
      const end_date = new Date(end).toISOString().split("T")[0];

      setStartDateFilter(start_date);
      setEndDateFilter(end_date);

      // Trigger the refresh for the effect that sends the API request
      setRefreshFilterDates((prev) => !prev);
    }
  };

  useEffect(() => {
    console.log("token", token);
    console.log(sessionStorage.getItem("token"));

    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);
      console.log("token", token);

      console.log(startDateFilter, "startDateFilter");
      console.log(endDateFilter, "endDateFilter");

      // Convert startDateFilter and endDateFilter if they are strings or invalid Date objects
      const parsedStartDate =
        typeof startDateFilter === "string"
          ? new Date(startDateFilter)
          : startDateFilter;

      const parsedEndDate =
        typeof endDateFilter === "string"
          ? new Date(endDateFilter)
          : endDateFilter;

      // Log parsed dates to verify correctness
      console.log("parsedStartDate", parsedStartDate);
      console.log("parsedEndDate", parsedEndDate);

      const start_date =
        parsedStartDate instanceof Date && !isNaN(parsedStartDate)
          ? parsedStartDate.toISOString().split("T")[0]
          : null; // Set to null if invalid

      const end_date =
        parsedEndDate instanceof Date && !isNaN(parsedEndDate)
          ? parsedEndDate.toISOString().split("T")[0]
          : null; // Set to null if invalid

      console.log("Final start_date:", start_date);
      console.log("Final end_date:", end_date);

      // Ensure default values only if start_date or end_date is null
      const finalStartDate =
        start_date ||
        new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .split("T")[0];
      const finalEndDate = end_date || new Date().toISOString().split("T")[0];

      console.log("API Payload start_date:", finalStartDate);
      console.log("API Payload end_date:", finalEndDate);

      axios
        .post(serverConfig.api_base_url + "scrum_report_list", {
          token: token,
          org: selectedOrganization?.slug,
          end_date: finalEndDate,
          start_date: finalStartDate,
        })
        .then((response) => {
          console.log("scrum_report_list response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            if (items !== null && items !== undefined) {
              console.log(response.data.items);
              setTemplateGroupList(response.data.items);

              const reportDates = items.map((item) => item.report_date);
              setExistingRecordDates(reportDates);

              let appDataArray = response.data.items;
              let appData = [];

              console.log(appDataArray);
              appDataArray &&
                appDataArray.reverse().map((item, index) => {
                  item.enabled = item.status;

                  item.status = (
                    <div className="flex flex-row">
                      {item.status === 0 ? (
                        <div className="bg-bdr bg-bdr-peach">
                          <div>
                            <i className="fa-regular fa-user"></i>
                          </div>
                          <div>Disabled</div>
                        </div>
                      ) : (
                        <div className="bg-bdr bg-bdr-green">
                          <div>
                            <i className="fa-regular fa-user"></i>
                          </div>
                          <div>Enabled</div>
                        </div>
                      )}
                    </div>
                  );

                  let noOfActions = 1 + 1;
                  const Actions = () => (
                    <>
                      <Button
                        title={noOfActions > 2 ? "" : "Edit"}
                        variant="text"
                        onClick={() => handleShowEditTemplateModal(item)}
                      >
                        <i
                          className="fa-solid fa-pencil not-0"
                          aria-hidden="true"
                        ></i>{" "}
                        {noOfActions > 2 && <span>Edit</span>}
                      </Button>
                      <Button
                        title={noOfActions > 2 ? "" : "Delete"}
                        variant="text"
                        onClick={() =>
                          handleShowDeleteTemplateModal(
                            item?.templateGrp,
                            item?.display_name
                          )
                        }
                      >
                        <i
                          className="fa-solid fa-trash-can text-red-500"
                          aria-hidden="true"
                        ></i>{" "}
                        {noOfActions > 2 && <span>Delete</span>}
                      </Button>
                    </>
                  );

                  item.actions =
                    noOfActions > 2 ? (
                      <div className="text-right more-options-wrapper">
                        <button
                          type="button"
                          onClick={handleShowMoreOptions}
                          title="More"
                          className="btn-more-options"
                        >
                          <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                        </button>
                        <div
                          className="more-options"
                          style={{ display: "none" }}
                        >
                          <Actions />
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-end gap-3">
                        <Actions />
                      </div>
                    );

                  appData.push(item);
                });

              console.log(appData);
              setTableData(appData);
            } else {
              setTableData([]);
              setTemplateGroupList([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [
    token,
    selectedOrganization,
    refreshTemplateGroupList,
    refreshCommsntList,
    refreshFilterDates,
  ]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;
  const sortingIcons = [sortDown, sortUp];

  const columns = [
    {
      name: <div className="font-bold text-sm">Achievement</div>,
      selector: (row) => <div className="p-2">{row?.achieviement}</div>,
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm">Plan</div>,
      selector: (row) => <div className="p-2">{row?.plan}</div>,
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm">Report Date</div>,
      selector: (row) => {
        const date = new Date(row?.report_date);
        const formattedDate = date.toLocaleDateString("en-GB"); // This formats the date as DD-MM-YYYY
        return <div className="p-2">{formattedDate}</div>;
      },
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm pr-2">Actions</div>,
      cell: (row) => (
        <div className="flex justify-end space-x-3 mr-2">
          <button
            type="button"
            onClick={() => handleShowEditTemplateModal(row)}
            title="Edit"
            className="btn-edit"
          >
            <i className="fa-solid fa-pencil"></i>
          </button>
          {/* <button
          type="button"
          onClick={() => handleShowDeleteTemplateModal(row)}
          title="Delete"
          className="btn-delete"
        >
          <i className="fas fa-trash-can text-red-500"></i>
        </button> */}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  const [createChildGroupTypeOption, setCreateChildGroupTypeOption] =
    useState("");

  //Create
  const [createDashboardModalShow, setCreateDashboardModalShow] =
    useState(false);
  const handleCloseDas = () => {
    setCreateDashboardModalShow(false);
    setFormData({});
    setCreateChildGroupTypeOption("");
  };
  const handleShowCreateTemplateModal = () => {
    setCreateDashboardModalShow(true);
  };

  const handleCreateTemplateSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    axios
      .post(serverConfig.api_base_url + "scrum_report_update", {
        token: token,
        org: selectedOrganization?.slug,
        report_date: formData.report_date,
        achievement: formData.achieviement,
        plan: formData.plan,
        blocker: formData.blocker,
      })
      .then((response) => {
        console.log("scrum_report_update :", response);
        if (response.data.status === 200) {
          setFormData({});
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
          handleCloseDas();
          handleShowAlertModal("Report added successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleUpdateReport = (event) => {
    event.preventDefault();
    console.log(formData);
    axios
      .post(serverConfig.api_base_url + "scrum_report_update", {
        token: token,
        org: selectedOrganization?.slug,
        report_date: formData.report_date,
        achievement: formData.achieviement,
        plan: formData.plan,
        blocker: formData.blocker,
      })
      .then((response) => {
        console.log("scrum_report_update :", response);
        if (response.data.status === 200) {
          setFormData({});
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
          handleCloseEditTemplateModal();
          handleShowAlertModal("Report updated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  //Edit
  const [templateSlug, setTemplateSlug] = useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [editTemplateModalShow, setEditTemplateModalShow] = useState(false);
  const handleCloseEditTemplateModal = () => {
    setEditTemplateModalShow(false);
    setTemplateSlug("");
    setDashboardName("");
    //setFormMessage({});
    setFormData({});
    setCreateChildGroupTypeOption("");
    setRefreshCommentList(!refreshCommsntList);
  };
  const handleShowEditTemplateModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditTemplateModalShow(true);
    setDateNotToDisable(details.report_date);
    setReportComments(details.comments);
  };

  //Delete
  const [deleteTemplateModalShow, setDeleteTemplateModalShow] = useState(false);
  const handleCloseDeleteTemplateModal = () => {
    setDeleteTemplateModalShow(false);
    setTemplateSlug("");
    setDashboardName("");
    setFormData({});
  };
  const handleShowDeleteTemplateModal = (details) => {
    setDeleteTemplateModalShow(true);
    console.log(details);
    setFormData(details);
    setDashboardName(details.subscription_name);
  };

  const handleDeleteTemplate = (event) => {
    event.preventDefault();

    const { subscription_name, description } = formData;

    const payload = {
      token: token,
      org: selectedOrganization?.slug,
      subs: formData.subs,
      items: {
        name: subscription_name,
        description: description,
      },
    };
    axios
      .post(serverConfig.api_base_url + "canvas_dashboard_delete", payload)
      .then((response) => {
        console.log("dwh_template_group_delete:", response);
        if (response.data.status === 200) {
          handleShowAlertModal("Template Category deleted successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          handleCloseDeleteTemplateModal();
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    const defaultDate = new Date().toISOString().split("T")[0];
    setFormData((prevData) => ({
      ...prevData,
      report_date: defaultDate,
    }));
  }, [createDashboardModalShow]);

  const isDateDisabled = (date) => {
    return existingRecordDates.includes(date);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    // Update the formData with the selected date
    setFormData({
      ...formData,
      report_date: selectedDate,
    });

    // Check if the selected date exists in existingRecordDates
    if (isDateDisabled(selectedDate)) {
      alert("This date has already been reported.");
      setIsSaveDisabled(true); // Disable the save button
    } else {
      setIsSaveDisabled(false); // Enable the save button
    }
  };

  const handleEditDateChange = (e) => {
    const selectedDate = e.target.value;

    // Update the formData with the selected date
    setFormData({
      ...formData,
      report_date: selectedDate,
    });

    // Check if the selected date exists in existingRecordDates
    if (isDateDisabled(selectedDate) && selectedDate != dateNotToDisable) {
      alert("This date has already been reported.");
      setIsSaveDisabled(true); // Disable the save button
    } else {
      setIsSaveDisabled(false); // Enable the save button
    }
  };

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-mapmetadata mt-4">
        <div className="flex justify-between items-center space-x-4">
          <h1 className="font-semibold text-2xl mt-4 mb-4">Scrum Report</h1>
          <div className="flex items-center space-x-4 text-black">
            <div>
              <DatePicker
                selected={startDate}
                showIcon
                toggleCalendarOnIconClick
                selectsRange
                startDate={startDate}
                endDate={endDate}
                placeholderText="Select date range"
                onChange={pickDateRange}
                maxDate={new Date()}
                customInput={
                  <input
                    value={`${startDate} - ${endDate}`}
                    className="custom-input calendar-input"
                  />
                }
              />
            </div>
            <button
              type="button"
              className="sub-grp px-3 py-2"
              onClick={handleShowCreateTemplateModal}
            >
              <i className="fa-solid fa-plus mr-2" aria-hidden="true"></i> Add
              Report
            </button>
          </div>
        </div>
        {tableData.length > 0 ? (
          <DataTable
            columns={columns}
            data={tableData}
            responsive={true}
            pagination={tableData.length > 10 ? true : false}
            highlightOnHover
            customStyles={{
              rows: {
                style: {
                  minHeight: "48px",
                  border: "2px solid var(--neutral-400)",
                  borderRadius: "10px",
                  marginTop: "10px",
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? "#ffffff" : "#000000",
                  backgroundColor: isDarkTheme ? "#222222" : "#EFF3F9",
                  outline: "none",
                },
              },
            }}
            style={{
              height: "100%",
            }}
          />
        ) : (
          <div className="flex flex-col h-3/4 items-center justify-center">
            No Records for the selected date range
          </div>
        )}

        {createDashboardModalShow && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Add Report</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleCreateTemplateSubmit}
              >
                <div className="mt-5 mb-5">
                  <div>
                    <div className="flex flex-col pt-1 mb-4">
                      <label>Date :</label>
                      <input
                        type="date"
                        onChange={handleDateChange}
                        value={formData.report_date}
                        min={
                          new Date(new Date().setDate(new Date().getDate() - 7))
                            .toISOString()
                            .split("T")[0]
                        }
                        max={new Date().toISOString().split("T")[0]}
                        style={{ width: "150px" }}
                        className="custom-input py-2 px-2 mt-2"
                        required
                      />
                    </div>
                    {/* Flex container for Achievements, Plan, Blockers */}
                    <div className="flex justify-between gap-5">
                      <div className="flex flex-col w-1/3">
                        <label>
                          Achievements <span className="text-red-500">*</span>
                        </label>
                        <textarea
                          type="text"
                          value={formData.achieviement}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              achieviement: e.target.value,
                            })
                          }
                          placeholder="Achievements"
                          className="custom-input-scrum-textarea py-2 px-2 mt-1 h-40"
                          required
                        />
                      </div>

                      <div className="flex flex-col w-1/3">
                        <label>
                          Plan <span className="text-red-500">*</span>
                        </label>
                        <textarea
                          type="text"
                          value={formData.plan}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              plan: e.target.value,
                            })
                          }
                          placeholder="Next Plans"
                          className="custom-input-scrum-textarea py-2 px-2 mt-1 h-40"
                          required
                        ></textarea>
                      </div>

                      <div className="flex flex-col w-1/3">
                        <label>
                          Blockers <span className="text-red-500">*</span>
                        </label>
                        <textarea
                          type="text"
                          value={formData.blocker}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              blocker: e.target.value,
                            })
                          }
                          placeholder="Blockers"
                          className="custom-input-scrum-textarea py-2 px-2 mt-1 h-40"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Bottom buttons */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseDas}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    disabled={isSaveDisabled}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {editTemplateModalShow && (
          <div className="overlay">
            <form
              className="modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col w-1/2"
              onSubmit={handleUpdateReport}
            >
              <div className="flex-grow overflow-y-auto">
                <div className="flex justify-between items-center gap-5">
                  <div style={{ fontSize: 16 }}>Edit Scrum Report</div>
                </div>

                <div className="mt-4">
                  <div className="flex flex-col gap-1 mt-3 mb-5 text-xs">
                    <div className="flex flex-col pt-1 mb-4">
                      <label>Date :</label>
                      <input
                        type="date"
                        onChange={handleEditDateChange}
                        value={formData.report_date}
                        style={{ width: "150px" }}
                        className="custom-input py-2 px-2 mt-2"
                        disabled
                      />
                    </div>

                    {/* Flex container for Achievements, Plans, Blockers */}
                    <div className="flex justify-between gap-5">
                      <div className="flex flex-col w-1/3">
                        <label>
                          Achievements <span className="text-red-500">*</span>
                        </label>
                        <textarea
                          type="text"
                          value={formData.achieviement || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              achieviement: e.target.value,
                            })
                          }
                          placeholder="Achievements"
                          className="custom-input-scrum-textarea py-2 px-2 mt-1 h-40"
                          required
                        />
                      </div>

                      <div className="flex flex-col w-1/3">
                        <label>
                          Plans <span className="text-red-500">*</span>
                        </label>
                        <textarea
                          type="text"
                          value={formData.plan || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              plan: e.target.value,
                            })
                          }
                          placeholder="Next Plans"
                          className="custom-input-scrum-textarea py-2 px-2 mt-1 h-40"
                          required
                        ></textarea>
                      </div>

                      <div className="flex flex-col w-1/3">
                        <label>
                          Blockers <span className="text-red-500">*</span>
                        </label>
                        <textarea
                          type="text"
                          value={formData.blocker || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              blocker: e.target.value,
                            })
                          }
                          placeholder="Blockers"
                          className="custom-input-scrum-textarea py-2 px-2 mt-1 h-40"
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div>
                      <ScrumReportComment
                        token={token}
                        selectedOrganization={selectedOrganization}
                        handleShowAlertModal={handleShowAlertModal}
                        handleCloseAlertModal={handleCloseAlertModal}
                        reportDate={formData.report_date}
                        reportComments={reportCommsnts}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="spacing-div">
                <button
                  type="button"
                  className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  onClick={handleCloseEditTemplateModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  disabled={isSaveDisabled}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        )}

        {deleteTemplateModalShow && (
          <>
            <div className="overlay">
              <form
                style={{ width: "35vw" }}
                className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2"
                onSubmit={handleDeleteTemplate}
              >
                <div className=" flex-grow overflow-y-auto ">
                  <div className="flex justify-between items-center gap-5">
                    <div style={{ fontSize: 14 }}>
                      <b>Delete Dashboard</b>
                    </div>
                  </div>
                  <div className="mt-4 text-xs mb-4">
                    <p>
                      Are you sure you want to delete <b>{dashboardName}</b> ?
                    </p>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs"
                    onClick={handleCloseDeleteTemplateModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="delete-obj px-4 py-2 rounded-lg mt-2 text-xs"
                  >
                    Delete Chart
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {alertModalShow && (
          <>
            {showOkButton ? (
              <Notification message={alertMessage} notificationType={"Error"} />
            ) : (
              <Notification
                message={alertMessage}
                notificationType={"Success"}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ScrumReportTable;
