import React, { useState, useEffect } from 'react';

function Notification({ message, subMessage, notificationType, buttonText, handleFunction }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
   
    setIsVisible(true);

  
   const timer = setTimeout(() => {
      setIsVisible(false);
    }, 6000);

    return () => clearTimeout(timer);
     // Cleanup the timer on component unmount
  }, [message, buttonText, notificationType]); 

  return (
    <div className={`notification ${isVisible ? 'show' : ''}`}>
        <div className='flex flex-row'>
            <div className='notification-icon' style={notificationType === 'Info' ? {"background" : "#E3E9F4"} : 
                                                     notificationType === 'Success' ? {"background" : "#6ADA0033"} : 
                                                     notificationType === 'Error' ? {"background" : "#FF696933"} : {"background" : "#E3E9F4"}}>
                {

                    notificationType === 'Info' ? 
                                        <img src={require('../../Asset/Images/notification-info.png')}/> :
                    notificationType === 'Success' ? 
                                        <img src={require('../../Asset/Images/notification-success.png')}/> :
                    notificationType === 'Error' ? 
                                        <img src={require('../../Asset/Images/notification-error.png')}/> : 
                    
                                        <img src={require('../../Asset/Images/notification-info.png')}/>

                }
            <div></div>

            </div>
            <div className='msg-container flex flex-row justify-center'>
                <div className='flex flex-col'>
      <span className="notification-message">{message}</span>
      {(subMessage !== null && subMessage !== undefined && subMessage !== '') ? <span className='text-sm p-1.5'>{subMessage}</span> : <></> }

      {(buttonText !== null && buttonText !== undefined && buttonText !== '') ? <span className='text-sm p-1.5'><button onClick={handleFunction} className='font-semibold' style={notificationType === 'Info' ? {"color" : "var(--secondary-500)"} : 
                                                                               notificationType === 'Success' ? {"color" : "#3A7800"} : 
                                                                               notificationType === 'Error' ? {"color" : "#8C3A3A"} : {"color" : "var(--secondary-500)"}}>{buttonText}</button></span> : <></> }
      </div> 

      <div style={{"lineHeight" : "0 rem !important", "cursor" : "pointer"}} onClick={()=>{setIsVisible(false); }} className='ml-16 text-base'>
      <i className="fa-solid fa-xmark "></i>
      </div>
      </div>
     
      </div>
    </div>
  );
}

export default Notification;