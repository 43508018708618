// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox:checked {
  background-color: var(--primary) !important;
  border-color: transparent !important;
  /* padding: 0 !important;
    font-size: 1 !important;
    border: 0 !important; */
}

.custom-checkbox {
  border: 2px solid var(--primary) !important;
  padding: 1px !important;
}

.type-control-confidential {
  display: flex;
  padding: 4px 16px 4px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  border: 2px solid rgba(204, 82, 82, 0.5);
  background: rgba(255, 105, 105, 0.2);
  color: #ff6969;
}

.type-control-private {
  display: flex;
  padding: 4px 16px 4px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  border: 2px solid rgba(157, 110, 248, 0.5);
  background: rgba(157, 110, 248, 0.2);
  color: var(--foundation-purple-purple-800, #563d88);
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
}

.button-margin {
  margin-right: 8px;
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/kz-projects/policies-management/css/policymanagement.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,oCAAoC;EACpC;;2BAEyB;AAC3B;;AAEA;EACE,2CAA2C;EAC3C,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,wCAAwC;EACxC,oCAAoC;EACpC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,0CAA0C;EAC1C,oCAAoC;EACpC,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".custom-checkbox:checked {\n  background-color: var(--primary) !important;\n  border-color: transparent !important;\n  /* padding: 0 !important;\n    font-size: 1 !important;\n    border: 0 !important; */\n}\n\n.custom-checkbox {\n  border: 2px solid var(--primary) !important;\n  padding: 1px !important;\n}\n\n.type-control-confidential {\n  display: flex;\n  padding: 4px 16px 4px 12px;\n  align-items: center;\n  gap: 8px;\n  border-radius: 54px;\n  border: 2px solid rgba(204, 82, 82, 0.5);\n  background: rgba(255, 105, 105, 0.2);\n  color: #ff6969;\n}\n\n.type-control-private {\n  display: flex;\n  padding: 4px 16px 4px 12px;\n  align-items: center;\n  gap: 8px;\n  border-radius: 54px;\n  border: 2px solid rgba(157, 110, 248, 0.5);\n  background: rgba(157, 110, 248, 0.2);\n  color: var(--foundation-purple-purple-800, #563d88);\n}\n\n.flex-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end !important;\n}\n\n.button-margin {\n  margin-right: 8px;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
