import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import geoJsonDataUS from "./state-boundries/us-state-boundries.json";
import Select from 'react-select';
import empowermentZoneGeoJsonUS from "./empowerment-zones/Empowerment_Zones.json";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places"];

const GoogleMapComponent = ({
  data,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBafPK5TmCxMIAXCroevkGly1srtn6WB8s",
    libraries: libraries,
  });

  const [arrData, setArrData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filterSelected, setFilterSelected] = useState("");

  const [noData, setNoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showMarkers, setShowMarkers] = useState(true);
  const [showAreaPolygons, setShowAreaPolygons] = useState(true);
  const [showSelectedStates, setShowSelectedStates] = useState(true);
  const [showSelectedGeometry, setShowSelectedGeometry] = useState(true);
  const [showEmpowermentZones, setShowEmpowermentZones] = useState(true);

  const [hasEmpowermentZones, setHasEmpowermentZones] = useState(false);
  const [hasSelectedStates, setHasSelectedStates] = useState(false);
  const [hasSelectedGeometry, setHasSelectedGeometry] = useState(false);
  const [hasAreaPolygons, setHasAreaPolygons] = useState(false);
  const [hasMarkers, setHasMarkers] = useState(false);

  const markersRef = useRef([]);
  const polygonsRef = useRef([]);
  const geoJsonLayerRef = useRef(null);
  const markerClusterRef = useRef(null);
  // const empowermentZoneLayerRef = useRef(null);
  const stateBoundaryLayerRef = useRef(null);
  const stateGeometryLayerRef = useRef(null);

  const infoWindowRef = useRef(null);

  const areaPolygons = item?.areaPolygons || [];

  const stateAbbreviationMap = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
    PR: "Puerto Rico",
    GU: "Guam",
    VI: "Virgin Islands",
    AS: "American Samoa",
    MP: "Northern Mariana Islands",
    AE: "Armed Forces Europe",
    AP: "Armed Forces Pacific",
    AA: "Armed Forces Americas",
    FI: "Foreign Institution",
  };

  const selectedStates = Array.isArray(arrData)
    ? arrData
      .map((entry) => stateAbbreviationMap[entry.borrowerstate]) // Convert abbreviation to full name
      .filter(Boolean) // Remove any undefined values
    : [];

  // const areaPolygons = [
  //   {
  //     name: "Los Angeles Region",
  //     description: "This region covers major areas in Los Angeles.",
  //     coordinates: [
  //       { lat: 34.0522, lng: -118.2437 },
  //       { lat: 34.0736, lng: -118.4004 },
  //       { lat: 33.985, lng: -118.4695 },
  //       { lat: 34.0522, lng: -118.2437 },
  //     ],
  //   },
  //   {
  //     name: "Houston Area",
  //     description: "This region covers parts of Houston.",
  //     coordinates: [
  //       { lat: 29.7604, lng: -95.3698 },
  //       { lat: 29.771, lng: -95.3585 },
  //       { lat: 29.7499, lng: -95.3585 },
  //       { lat: 29.7604, lng: -95.3698 },
  //     ],
  //   },
  // ];

  // const selectedStates = [
  //   { name: "California", description: "The Golden State" },
  //   { name: "Texas", description: "The Lone Star State" },
  //   { name: "New York", description: "The Empire State" },
  // ];

  useEffect(() => {
    if (data && data.length > 0) {
      setNoData("");
      setIsLoading(false);

      if (Array.isArray(data)) {
        //console.log(data);

        let filterArr = [...new Set(data.filter(dataItem => dataItem[item.group] !== undefined).map(dataItem => dataItem[item.group]))];
        const dropdownOptions = filterArr.map((item) => {
          return {
            value: item,
            label: item
          }
        })
        setFilterList(dropdownOptions);

        if (filterArr.length > 0) {
          //console.log(filterArr[0])
          //console.log(data.filter((dataItem) => dataItem[item.group] === filterArr[0]));
          setArrData(data.filter((dataItem) => dataItem[item.group] === filterArr[0]));
          setFilterSelected({value : filterArr[0], label: filterArr[0]});
        } else {
          setArrData(data);
          setFilterSelected("");
        }

        /* let filter = data.find(obj => obj.hasOwnProperty(`${item.group}`));
        if(filter){
          console.log(filter[item.group]);
          console.log(data.filter((dataItem) => dataItem[item.group] === filter[item.group]));
          setArrData(data.filter((dataItem) => dataItem[item.group] === filter[item.group]));
        } */
      }
    } else {
      setNoData("No data available");
    }
  }, [data]);

  const handleFilterChange = (event) => {
    setArrData(data.filter((dataItem) => dataItem[item.group] === event?.value));
    setFilterSelected({value: event?.value, label: event?.value});
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '315px',
      cursor: 'pointer',
      pointerEvents: 'auto',
      marginRight: '12px',
      textAlign: 'left'
    }),
  };

  useEffect(() => {
    if (item.data === "fetching") {
      setIsLoading(true);
      return;
    }
  }, [data]);

  useEffect(() => {
    if (isLoaded && mapRef.current && !mapInstance.current && !isLoading && item?.data !== "fetching") {
      renderMap();
    }
  }, [isLoaded, mapRef.current, isLoading, item?.data]);

  useEffect(() => {
    const divs = document.querySelectorAll('div.map-filters');
    // Loop through each div
    divs.forEach(div => {
      // Check if the div has only 1 child
      if (div.children.length === 1) {
        // Hide the div
        div.style.display = 'none';
      }
    });
  }, [hasMarkers, hasAreaPolygons, hasSelectedStates, hasSelectedGeometry]);

  // useEffect(() => {
  //   if (mapInstance.current) {
  //     updateEmpowermentZones();
  //   }
  // }, [showEmpowermentZones]);

  useEffect(() => {
    if (mapInstance.current) {
      updateMarkers();
      updateAreaPolygons();
      updateSelectedStates();
      updateSelectedGeometry();
      // updateEmpowermentZones();
    }
  }, [showMarkers, showAreaPolygons, showSelectedStates, showSelectedGeometry, arrData]);

  const renderMap = () => {
    if (!mapRef.current) return; // Safeguard against null

    const customStyle = [
      {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"  // Hide all POI labels
          }
        ]
      },
      {
        "featureType": "poi.business",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"  // Hide business-related POI labels
          }
        ]
      }
    ];

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 39.8283, lng: -98.5795 },
      zoom: 2.8,
      mapId: "YOUR_MAP_ID",
    });

    mapInstance.current = map;
    // empowermentZoneLayerRef.current = new window.google.maps.Data();
    stateBoundaryLayerRef.current = new window.google.maps.Data();
    stateGeometryLayerRef.current = new window.google.maps.Data();

    updateMarkers();
    updateAreaPolygons();
    updateSelectedStates();
    updateSelectedGeometry();
    // updateEmpowermentZones();

    //remove Point of Interests - like business etc. markers
    const styledMapType = new google.maps.StyledMapType(customStyle, {
      name: 'Styled Map'
    });
    // Associate the styled map with the map
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  };

  const updateMarkers = () => {
    //return; //comment this to enable markers
    if (!arrData || arrData.length === 0) {
      setHasMarkers(false);
      return;
    }

    console.log(item?.data, 'item?.data', arrData);
    // Filter out entries with invalid lat/lng values
    const validMarkers = arrData.filter((dataItem) => {
      const lat = parseFloat(dataItem[item.latitude]);
      const lng = parseFloat(dataItem[item.longitude]);
      return !isNaN(lat) && !isNaN(lng) && dataItem[item.is_enabled_marker] === 1; // Ensure lat/lng are valid numbers and is_enabled_marker is 1
    });

    console.log(validMarkers);

    // If no valid markers, hide the checkbox
    if (validMarkers.length === 0) {
      setHasMarkers(false);
      return;
    }

    // We have valid markers, set the checkbox visibility to true
    setHasMarkers(true);

    // Clear existing markers
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    // Clear existing clusters
    if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers();
      markerClusterRef.current = null; // Reset the cluster reference
    }

    if (!showMarkers || !arrData) return;

    // Create new markers
    //const newMarkers = arrData.map((dataItem) => {
    const newMarkers = validMarkers.map((dataItem) => {
      const markerShapes = {
        circle: window.google.maps.SymbolPath.CIRCLE,
        square: "M -1 -1 L 1 -1 L 1 1 L -1 1 Z",
        triangle: "M 0 -1 L 1 1 L -1 1 Z",
        default: undefined
      };

      const markerShape = dataItem[item.marker_shape] ? markerShapes[`${dataItem[item.marker_shape]}`] : markerShapes.default;

      //console.log(markerShape);

      const markerIcon = {
        path: markerShape,
        fillColor: dataItem[item.marker_color] || "#FF0000",
        fillOpacity: dataItem[item.marker_opacity] || 0.35,
        scale: parseFloat(dataItem[item.marker_size]) || 10,
        strokeColor: dataItem[item.marker_color] || "#FF0000",
        strokeWeight: 2,
      };

      const marker = new window.google.maps.Marker({
        position: {
          lat: parseFloat(dataItem[item.latitude]),
          lng: parseFloat(dataItem[item.longitude]),
        },
        icon: markerIcon,
        //title: dataItem[item.marker_label],
        //label:"test"
        isCluster: dataItem[item.is_cluster],
      });

      const infoWindowContent = `
        <div style="font-size: 14px;">
          ${dataItem[item?.marker_label] ? `<h3>${dataItem[item?.marker_label]}</h3>` : ""}
          ${dataItem[item?.value] ? `<p>${dataItem[item?.value]}</p>` : ""}
        </div>
      `;

      const infoWindow = new window.google.maps.InfoWindow({
        content: infoWindowContent,
      });

      marker.addListener("mouseover", (event) => {
        if (infoWindowRef.current) {
          infoWindowRef.current.close();
        }

        infoWindow.open(mapInstance.current, marker);
        infoWindowRef.current = infoWindow;
        return false; // Prevent further propagation of this event
      });

      marker.addListener("mouseout", () => {
        infoWindow.close();
      });

      return marker;
    });

    const clusterableMarkers = newMarkers.filter((el) => el.isCluster === 1);
    const unclusterableMarkers = newMarkers.filter((el) => el.isCluster === 0);

    //console.log(clusterableMarkers, unclusterableMarkers);

    // Add new markers to cluster
    // markerClusterRef.current = new MarkerClusterer({
    //   markers: newMarkers,
    //   map: mapInstance.current,
    //   styles: [
    //     {
    //       textColor: "white",
    //       url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
    //       height: 50,
    //       width: 50,
    //       textSize: 14,
    //     },
    //   ],
    // });

    markerClusterRef.current = new MarkerClusterer({
      markers: newMarkers,
      map: mapInstance.current,
      renderer: {
        render: ({ count, position }) => {
          // Calculate size based on count
          const size = 2;
          const scale = Math.max(35 * size, 35);

          return new google.maps.Marker({
            position,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: '#3233EB',  // Google Maps blue
              fillOpacity: 0.8,
              strokeWeight: 2,
              strokeColor: '#3233EB',
              scale: scale / 5,
            },
            label: {
              text: String(count),
              color: 'white',
              fontSize: `11px`,
              fontWeight: 'bold'
            },
            zIndex: 1000
          });
        }
      }
    });

    unclusterableMarkers.forEach(element => {
      markerClusterRef.current.removeMarker(element);
      element.setMap(mapInstance.current);
    });

    markersRef.current = newMarkers;
  };

  const updateAreaPolygons = () => {
    polygonsRef.current.forEach((polygon) => polygon.setMap(null));
    polygonsRef.current = [];

    if (areaPolygons.length > 0) {
      setHasAreaPolygons(true);
    } else {
      setHasAreaPolygons(false);
    }

    if (!showAreaPolygons || areaPolygons.length === 0) return;

    const newPolygons = areaPolygons.map((region) => {
      const polygon = new window.google.maps.Polygon({
        paths: region.coordinates,
        strokeColor: "#FF5733",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FFC300",
        fillOpacity: 0.35,
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div><strong>${region.name}</strong><br>${region.description}</div>`,
      });

      polygon.addListener("mouseover", (event) => {
        infoWindow.setPosition(event.latLng);
        infoWindow.open(mapInstance.current);
        return false; // Prevent further propagation of this event
      });

      polygon.addListener("mouseout", () => {
        infoWindow.close();
      });

      polygon.setMap(mapInstance.current);
      return polygon;
    });

    polygonsRef.current = newPolygons;
  };

  const updateSelectedStates = () => {
    if (!mapInstance.current || !stateBoundaryLayerRef.current) return;

    // Clear previous state boundaries
    stateBoundaryLayerRef.current.forEach((feature) =>
      stateBoundaryLayerRef.current.remove(feature)
    );

    if (
      !showSelectedStates ||
      !Array.isArray(arrData) ||
      arrData.length === 0
    )
      return;

    const stateRevenueMap = {};
    let minRevenue = Infinity;
    let maxRevenue = -Infinity;

    arrData.forEach((entry) => {
      const stateName = stateAbbreviationMap[entry.borrowerstate];
      if (stateName) {
        const revenue = parseFloat(entry.revenue.replace(/[$,]/g, ""));
        stateRevenueMap[stateName] = revenue;
        if (revenue < minRevenue) minRevenue = revenue;
        if (revenue > maxRevenue) maxRevenue = revenue;
      }
    });

    const filteredFeatures = {
      type: "FeatureCollection",
      features: geoJsonDataUS.features.filter(
        (feature) => stateRevenueMap[feature.properties.NAME]
      ),
    };

    stateBoundaryLayerRef.current.addGeoJson(filteredFeatures);

    // Check if state boundaries exist before showing checkbox
    if (filteredFeatures.features.length > 0) {
      setHasSelectedStates(true);
    } else {
      setHasSelectedStates(false);
    }

    stateBoundaryLayerRef.current.setStyle((feature) => {
      const stateName = feature.getProperty("NAME");
      const revenue = stateRevenueMap[stateName];

      // const normalizedOpacity =
      //   minRevenue === maxRevenue
      //     ? 0.5
      //     : 0.1 + (0.9 * (revenue - minRevenue)) / (maxRevenue - minRevenue);

      return {
        fillColor: "#1f28fd", // Keep it BLUE
        fillOpacity: 0.25, //normalizedOpacity,
        strokeColor: "#0000FF", // Keep it BLUE
        strokeWeight: 2,
      };
    });

    stateBoundaryLayerRef.current.setMap(mapInstance.current);

    // **Attach InfoWindow for Tooltips**
    const infoWindow = new window.google.maps.InfoWindow();

    stateBoundaryLayerRef.current.addListener("mouseover", (event) => {
      const stateName = event.feature.getProperty("NAME");
      const revenue = stateRevenueMap[stateName];

      if (revenue !== undefined) {
        infoWindow.setContent(
          `<div><strong>${stateName}</strong><br>Revenue: $${revenue.toLocaleString()}</div>`
        );
        infoWindow.setPosition(event.latLng);
        infoWindow.open(mapInstance.current);
      }
    });

    stateBoundaryLayerRef.current.addListener("mouseout", () => {
      infoWindow.close();
    });
  };

  const updateSelectedGeometry = () => {
    if (!mapInstance.current || !stateGeometryLayerRef.current) return;

    // Clear previous state boundaries
    stateGeometryLayerRef.current.forEach((feature) =>
      stateGeometryLayerRef.current.remove(feature)
    );

    if (
      !showSelectedGeometry ||
      !Array.isArray(arrData) ||
      arrData.length === 0
    )
      return;

    //const finalItems = arrData.filter(itm => itm.hasOwnProperty('geometry') && itm.hasOwnProperty('is_enabled_geometry') && itm.is_enabled_geometry === 1);

    const finalItems = arrData.filter(dataItem => dataItem[item.geometry_coordinates] && dataItem[item.is_enabled_geometry] && dataItem[item.is_enabled_geometry] === 1);
    console.log(finalItems);

    //debugger;
    const filteredGeometry = {
      type: "FeatureCollection",
      features: finalItems.map(el => {
        //console.log(el.properties, el[item.geometry_properties]);
        //console.log(el.geometry_color, el[item.geometry_color]);
        //console.log(el.branch, el[item.geometry_label]);
        //console.log(el.geometry, el[item.geometry_coordinates]);
        return (
          {
            type: "Feature",
            properties: {
              ...JSON.parse(el[item.geometry_properties]),
              COLOR: el[item.geometry_color],
              BRANCH: el[item.geometry_label],
              OPACITY: el[item.geometry_opacity],
              ZINDEX: el[item.geometry_z_index],
            },
            geometry: JSON.parse(el[item.geometry_coordinates])
          }
        );
      }
      )
    };

    //console.log(arrData.map(el => el.geometry));
    //console.log(filteredGeometry);
    //console.log(filteredGeometry?.features?.length);
    //debugger;

    stateGeometryLayerRef.current.addGeoJson(filteredGeometry);

    // Check if state boundaries exist before showing checkbox
    if (filteredGeometry?.features?.length > 0) {
      setHasSelectedGeometry(true);
    } else {
      setHasSelectedGeometry(false);
    }

    stateGeometryLayerRef.current.setStyle((feature) => {
      return {
        fillColor: feature.getProperty("COLOR") || "purple",
        fillOpacity: feature.getProperty("OPACITY") || 0.25, //normalizedOpacity,
        strokeColor: feature.getProperty("COLOR") || "purple",
        strokeWeight: 2,
        zIndex: feature.getProperty("ZINDEX") || 0
      };
    });

    stateGeometryLayerRef.current.setMap(mapInstance.current);

    // **Attach InfoWindow for Tooltips**
    const infoWindow = new window.google.maps.InfoWindow();

    stateGeometryLayerRef.current.addListener("mouseover", (event) => {
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }

      const stateLabel = event.feature.getProperty("BRANCH");
      //console.log(stateLabel);

      if (stateLabel !== undefined) {
        infoWindow.setContent(
          `<div>${stateLabel}</div>`
        );
        infoWindow.setPosition(event.latLng);
        infoWindow.open(mapInstance.current);
        infoWindowRef.current = infoWindow;
      }
    });

    stateGeometryLayerRef.current.addListener("mouseout", () => {
      infoWindow.close();
    });
  };

  // const updateEmpowermentZones = () => {
  //   if (!mapInstance.current || !empowermentZoneLayerRef.current) return;

  //   // Remove the layer if the checkbox is unchecked
  //   if (!showEmpowermentZones) {
  //     empowermentZoneLayerRef.current.setMap(null);
  //     return;
  //   }

  //   try {
  //     // Clear any existing features from the layer
  //     empowermentZoneLayerRef.current.forEach((feature) =>
  //       empowermentZoneLayerRef.current.remove(feature)
  //     );

  //     let geoJsonData =
  //       typeof empowermentZoneGeoJsonUS === "string"
  //         ? JSON.parse(empowermentZoneGeoJsonUS)
  //         : JSON.parse(JSON.stringify(empowermentZoneGeoJsonUS));

  //     if (geoJsonData.crs) delete geoJsonData.crs;

  //     // Check if there are features before enabling checkbox
  //     if (geoJsonData.features && geoJsonData.features.length > 0) {
  //       setHasEmpowermentZones(true);
  //     } else {
  //       setHasEmpowermentZones(false);
  //     }

  //     empowermentZoneLayerRef.current.addGeoJson(geoJsonData);

  //     // Apply styles for Empowerment Zones
  //     empowermentZoneLayerRef.current.setStyle({
  //       fillColor: "#FFA500", // Orange
  //       fillOpacity: 0.5,
  //       strokeColor: "#FF4500", // Red
  //       strokeWeight: 2,
  //     });

  //     // Attach the layer to the map
  //     empowermentZoneLayerRef.current.setMap(mapInstance.current);

  //     // **Attach InfoWindow for Tooltips**
  //     const infoWindow = new window.google.maps.InfoWindow();

  //     empowermentZoneLayerRef.current.addListener("mouseover", (event) => {
  //       const feature = event.feature;

  //       // Extract tooltip information from GeoJSON properties
  //       const name = feature.getProperty("NAME") || "Unknown Zone";
  //       const type = feature.getProperty("TYPE") || "Unknown Type";
  //       const fullName =
  //         feature.getProperty("FULLNAME") || "No Description Available";
  //       const contact =
  //         feature.getProperty("CONTFSTNM") +
  //         " " +
  //         feature.getProperty("CONTLSTNM");

  //       // Construct InfoWindow content
  //       const infoContent = `
  //         <div>
  //           <strong>${name}</strong><br>
  //           ${type}<br>
  //           ${fullName}<br>
  //           ${contact}
  //         </div>
  //       `;

  //       infoWindow.setContent(infoContent);
  //       infoWindow.setPosition(event.latLng);
  //       infoWindow.open(mapInstance.current);
  //     });

  //     empowermentZoneLayerRef.current.addListener("mouseout", () => {
  //       infoWindow.close();
  //     });
  //   } catch (error) {
  //     console.error("Error parsing GeoJSON", error);
  //   }
  // };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <div>
      <div className="flex justify-end px-2 map-filters" style={{ marginTop: "10px", fontSize: "14px" }}>
        {filterList.length > 0 &&
          // <select value={filterSelected} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" style={{ marginRight: "15px" }} onChange={handleFilterChange}>
          //   <option value={item?.chart_filter_label} disabled>Select {item?.chart_filter_label}</option>
          //   {filterList.map((el) => {
          //     return(<option value={el}>{el}</option>)
          //   })}
          // </select>
          <Select
            styles={customStyles}
            classNamePrefix='select'
            className='filter-dropdown-single-select'
            placeholder={`Select ${item?.chart_filter_label}`}
            value={filterSelected}
            options={filterList}
            onChange={(e) => {handleFilterChange(e);}}
            // Disable if isPinned is true
            isDisabled={item?.is_pinned}
          />
        }

        {hasMarkers && (
          <label>
            <input
              type="checkbox"
              checked={showMarkers}
              onChange={(e) => setShowMarkers(e.target.checked)}
            />
            Markers
          </label>
        )}

        {hasAreaPolygons && (
          <label style={{ marginLeft: "15px" }}>
            <input
              type="checkbox"
              checked={showAreaPolygons}
              onChange={(e) => setShowAreaPolygons(e.target.checked)}
            />
            Area Polygons
          </label>
        )}

        {hasSelectedStates && (
          <label style={{ marginLeft: "15px" }}>
            <input
              type="checkbox"
              checked={showSelectedStates}
              onChange={(e) => setShowSelectedStates(e.target.checked)}
            />
            State Boundaries
          </label>
        )}

        {hasSelectedGeometry && (
          <label style={{ marginLeft: "15px" }}>
            <input
              type="checkbox"
              checked={showSelectedGeometry}
              onChange={(e) => setShowSelectedGeometry(e.target.checked)}
            />
            CBG
          </label>
        )}

        {/* {hasEmpowermentZones && (
          <label style={{ marginLeft: "15px" }}>
            <input
              type="checkbox"
              checked={showEmpowermentZones}
              onChange={(e) => setShowEmpowermentZones(e.target.checked)}
            />
            Empowerment Zones
          </label>
        )} */}
      </div>

      {noData ? (
        <>
          <div className="chart-title">{noData}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div className="p-2">
          <div ref={mapRef} style={containerStyle}></div>
        </div>
      )}
    </div>
  );
};

export default GoogleMapComponent;
