import React, {useContext} from "react";
import { Context } from "../../contextApi/context";
import PricePlanTable from "./tables/PricePlanTable";

const PricePlan = () => {
  const {collapse} = useContext(Context);

  return (<>
    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
        <div className="container-fluid metadata">
            <PricePlanTable/>
        </div>
      </div>
    </div>
  </>);
}

export default PricePlan;