import React, { useContext, useState, useEffect } from "react";

const ViewReason = ({setEditRowClicked, reason}) => {


    return(
        <>
        <div className='delete-user  rounded-3xl py-2 px-2 relative  text-custom text-sm flex flex-col items-center'>
        <div className='overflow-y-auto mt-2'>
        <div className='flex flex-col gap-8 mb-4'>

            <div className="dlt-heading">Reason :</div>

            <p><b>{reason}</b></p>

        </div>

        <div className='flex flex-row justify-end'>
            
      <button type="submit"  className='delete-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => {setEditRowClicked('');}}>Close</button>
      </div>

        </div>
        </div>        
        </>
    )
}

export default ViewReason;