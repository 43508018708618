import React, {useState, useContext} from "react";
import { Context } from "../contextApi/context";
import LeadCaptureForm from "./form/LeadCaptureForm";

const LeadCapture = () => {

    const {collapse, tab,taskPopup} = useContext(Context)
  const[newGroupPopup , setNewGroupPopup]= useState(false)

    return (
        <>

    <div>
      <div className={collapse?'content-body':'content-body-collapse'} >
      <div class="container-fluid">
        <LeadCaptureForm />
    </div>
     
      </div>
    </div>

        </>
    )
}

export default LeadCapture;