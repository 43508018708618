import React,{useState, useEffect, useContext} from 'react';
import { Context } from '../contextApi/context.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const serverConfig = require("../config/server.js");

function NewOrganizationPopup({setNewOrganizationPopup, handleShowAlertModal, handleCloseAlertModal, token, handleLogout}) {
  const [industryLookup, setIndustryLookup] = useState([]);
  const [numOfEmpLookup, setNumOfEmpLookup] = useState([]);
  const [weekdayLookup, setWeekdayLookup] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const {setRefreshOrgList, refreshOrgList} = useContext(Context);
  const navigate = useNavigate();
  const inputClass = (field) => errors[field] ? 'input-error' : 'custom-input';
  const selectClass = (field) => errors[field] ? 'select-error' : 'select-custom-input';

  const validateField  = (name, value) => {

    switch (name) {
      case 'name':
          return value ? null : 'Name of Organization is required';

      case 'industry':
          return value ? null : 'Industry is required';

      case 'no_of_employees':
          return value ? null : 'Number of employees is required';

      case 'timezone':
          return value ? null : 'Timezone is required';

      case 'week_start_day':
          return value ? null : 'Week start day is required';

      case 'default_currency':
          return value ? null : 'Default currency is required';

      default:
          return null;
  }
};

  useEffect(() => {
    console.log('token', token);
		if(token !== null && token !== undefined){
			//set industry dropdown
			axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"industry"})
			.then(response=>{
				console.log("Industry lookup:", response);
				if (response.data.status === 200) {
					if(response.data.items !== null){
						console.log("Industry data:", response.data.items);
						response.data.items.sort(function(a,b){
							return a.seq_no - b.seq_no;
						});
						setIndustryLookup(response.data.items);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
      //set number of employees dropdown
			axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"number_of_employees"})
			.then(response=>{
				console.log("number_of_employees lookup:", response);
				if (response.data.status === 200) {
					if(response.data.items !== null){
						console.log("number_of_employees data:", response.data.items);
						response.data.items.sort(function(a,b){
							return a.seq_no - b.seq_no;
						});
						setNumOfEmpLookup(response.data.items);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
      //set weekdays dropdown
			axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"weekday"})
			.then(response=>{
				console.log("weekday lookup:", response);
				if (response.data.status === 200) {
					if(response.data.items !== null){
						console.log("weekday data:", response.data.items);
						response.data.items.sort(function(a,b){
							return a.seq_no - b.seq_no;
						});
						setWeekdayLookup(response.data.items);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
		}
	},[token]);

  const getBase64 = (file, e) => {
    console.log(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      if(e.target.name === 'logo') {
        setFormData({ ...formData, logo: file.name, logo_base64: reader.result});
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });

    // Validate the changed field
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };
 

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {
      name: validateField('name', formData.name),
      industry: validateField('industry', formData.industry),
      no_of_employees: validateField('no_of_employees', formData.no_of_employees),
      timezone: validateField('timezone', formData.timezone),
      week_start_day: validateField('week_start_day', formData.week_start_day),
      default_currency: validateField('default_currency', formData.default_currency),
  };

  setErrors(newErrors);
  if (Object.values(newErrors).every(error => error === null)){
    setLoading(true);
    console.log(formData);
    axios.post(serverConfig.api_base_url + "organization_create",{
      token:token,
      name: formData.name,
      industry: formData.industry,
      address: formData.address,
      logo: formData.logo,
      logo_base64: formData.logo_base64,
      gstn: formData.gstn,
      no_of_employees: formData.no_of_employees,
      timezone: formData.timezone,
      default_currency: formData.default_currency,
      week_start_day: formData.week_start_day
    })
    .then(response=>{
      if (response.data.status === 200) {
        setFormData({});
        handleShowAlertModal('Organization created successfully');
        //setNewOrganizationPopup(false);
        setRefreshOrgList(!refreshOrgList);
        setFormSubmitted(true);
        setLoading(false);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        setLoading(false);
      } 
    })
    .catch(err=>console.log("error is",err));
    setLoading(false);
  }
  }

  return (
    <>
    {!formSubmitted ?
    <form className='memberPopup orgCreate newOrg rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={handleSubmit}>
        <div className=' flex-grow overflow-y-auto newOrg' style={{padding: "15px"}}>
          <div className='newOrg flex justify-between items-center gap-5'>
            <div style={{fontSize:16}}>New Organization</div>
            <button className='my-2 title-text-small-color' onClick={()=>{setNewOrganizationPopup(false)}}><i className="fa-solid fa-xmark "></i> </button>
          </div>
  
          <div className='newOrg mt-4'>
            <div className='newOrg flex flex-col gap-1 mt-8 text-xs'>
              <label className='newOrg'>Name of Organization<span className=' text-red-500'>*</span></label>
              <input type="text" value={formData.name || ""} onChange={handleChange} name='name' placeholder='Name of Organization' 
              //className='custom-input py-2 px-2 text-left mt-1'
              className={inputClass('name')} 
               //required
               />
               {errors.name && <small className='error-msg'>{errors.name}</small>}
            </div>

            <div className=' newOrg flex flex-col gap-1 mt-3 text-xs'>
              <label  className='newOrg'>GSTN</label>
              <input type="text" value={formData.gstn || ""} onChange={handleChange} name='gstn' placeholder='GSTN' className='custom-input py-2 px-2 text-left mt-1'/>
            </div>

            <div className='newOrg flex flex-col gap-1 mt-3 text-xs'>
              <label  className='newOrg'>Industry<span className='text-red-500'>*</span></label>
              <select value={formData.industry || ""} name='industry'  className={selectClass('industry')}  onChange={handleChange}>
                <option value="">Industry</option>
                {industryLookup.length > 0 &&
                  industryLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                }
              </select>
              {errors.industry && <small className='error-msg'>{errors.industry}</small>}
            </div>

            <div className='newOrg flex item-center'>
              <div className='flex flex-col gap-1 mt-4 text-xs'>
                <label  className='newOrg'><b>Logo</b></label>
                <div className="newOrg flex items-center">
                  {formData.logo_base64 && <img src={formData.logo_base64} alt="logo" width="70" className='w-auto max-w-full rounded-md' style={{maxHeight: "60px"}} />}
                  <div className={formData.logo_base64 ? "flex flex-col ml-3" : "flex flex-col"}>
                    <label className="newOrg custom-file-upload">
                      <input name='logo'  type='file' value="" onChange={(e)=>  {getBase64(e.target.files[0], e)}} accept='.jpg, .jpeg, .png' className='custom-input py-2 px-2 text-left mt-1'/>
                      {formData.logo_base64 ? <>Choose Another File</> : <>Choose File</>}
                    </label>
                    <small className='newOrg text-slate-400 mt-2'>File Size Limit: 1Mb</small>
                  </div>
                </div>
              </div>
            </div>

            <div className='newOrg flex flex-col gap-1 mt-3 text-xs'>
              <label  className='newOrg'>Address</label>
              <textarea name='address'  value={formData.address || ""} onChange={handleChange} placeholder='Address' className='custom-input py-2 px-2 text-left mt-1'/>
            </div>

            <div className='newOrg flex flex-col gap-1 mt-3 text-xs'>
              <label  className='newOrg'>Number of employees<span className='text-red-500'>*</span></label>
              <select  name='no_of_employees' value={formData.no_of_employees || ""} className={selectClass('no_of_employees')}  onChange={handleChange}>
                <option value="">Number of employees</option>
                {numOfEmpLookup.length > 0 &&
                  numOfEmpLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                }
              </select>
              {errors.no_of_employees && <small className='error-msg'>{errors.no_of_employees}</small>}
            </div>

            <div className='newOrg flex flex-col gap-1 mt-3 text-xs'>
              <label  className='newOrg'>Time Zone<span className=' text-red-500'>*</span></label>
              <select  name='timezone' value={formData.timezone || ""} className={selectClass('timezone')} onChange={handleChange}>
                <option value="">Time Zone</option>
                <option value="Indian Standard Time UTC+05:30">Indian Standard Time UTC+05:30</option>
              </select>
              {errors.timezone && <small className='error-msg'>{errors.timezone}</small>}
            </div>

            <div className='newOrg flex flex-col gap-1 mt-3 text-xs'>
              <label  className='newOrg'>Default Currency<span className='text-red-500'>*</span></label>
              <select name='default_currency' value={formData.default_currency || ""} className={selectClass('default_currency')} onChange={handleChange}>
                <option value="">Default Currency</option>
                <option value="INR">INR</option>
              </select>
              {errors.default_currency && <small className='error-msg'>{errors.default_currency}</small>}
            </div>

            <div className='newOrg flex flex-col gap-1 mt-3 text-xs'>
              <label  className='newOrg'>Start week on<span className='text-red-500'>*</span></label>
              <select name='week_start_day'  value={formData.week_start_day || ""} className={selectClass('week_start_day')} onChange={handleChange}>
                <option value="">Weekday</option>
                {weekdayLookup.length > 0 &&
                  weekdayLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                }
              </select>
              {errors.week_start_day && <small className='error-msg'>{errors.week_start_day}</small>}
            </div>

            <div className={formMessage.type==="success" ? "mt-2 text-green-500" : formMessage.type==="fail" && "mt-2 text-red-500"}>{formMessage.message ? formMessage.message : <>&nbsp;</>}</div>
          </div>
        </div>
        {/* bottom buttom */}
        <div style={{ marginLeft: "17.5rem"}} className='flex flex-row'>
          <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={()=>{setNewOrganizationPopup(false)}}>Cancel</button>
          <button type="submit" disabled={loading} className='btn-popup newOrg px-4 py-2 rounded-lg mt-2 text-sm text-medium' style={{display: "flex"}}>Save {loading && <div className="signup-loader" style={{ marginLeft: '15px' }}></div>}</button>
        </div>
    </form> : <> 
    <form className='memberPopup orgCreate newOrg rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col' onSubmit={(e)=>{e.preventDefault(); setNewOrganizationPopup(false); setFormSubmitted(false);}}>
        <div className=' flex-grow overflow-y-auto newOrg' style={{padding: "5px"}}>
         <div className='loading-div flex flex-col'>
              <img src={require(`./assets/uploadSuccess.png`)} alt='' className='loading-img' style={{marginTop: "0px !important"}} />
              <h1 className='invite-text'>Organization created successfully.</h1>
         </div>
       </div>
       <div style={{ marginLeft: "21.5rem"}}>
          <button type="submit" className='btn-popup newOrg px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Done</button>
        </div>
    </form></>}
    </>
  )
}

export default NewOrganizationPopup;